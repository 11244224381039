import React from "react";
import { Dialog, DialogContent, Button, Slide } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function CheckinDialog({
  open,
  handleOpen,
  handleCheckin,
  guestNbrOfCheck,
  handleDetailsDialogOpen,
}) {
  return (
    <Dialog
      open={open}
      onClose={() => handleOpen(false)}
      aria-labelledby="alert-dialog-checkin"
      aria-describedby="alert-dialog-description-checkin"
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          minWidth: "300px",
          maxWidth: "450px",
          width: "450px",
        },
      }}
    >
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", gap: "1.7rem" }}
      >
        <Button
          variant="contained"
          onClick={() => handleCheckin(true)}
          sx={{
            borderRadius: "20px",
            background:
              "linear-gradient(90deg, rgba(214, 43, 90, 1) 0%, rgba(143, 42, 86, 1) 100%)",
            fontWeight: "bold",
          }}
        >
          CHECKIN
        </Button>
        {guestNbrOfCheck > 0 ? (
          <Button
            variant="outlined"
            onClick={() => handleCheckin(false)}
            sx={{
              border: "solid 1px rgba(214, 43, 90, 1)",
              color: "rgba(214, 43, 90, 1)",
              backgroundColor: "#fff",
              fontWeight: "bold",
              borderRadius: "20px",
              ":hover": {
                backgroundColor: "#fff",
                border: "solid 1px rgba(214, 43, 90, 1)",
                color: "rgba(214, 43, 90, 1)",
                boxShadow:
                  "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
              },
            }}
          >
            ANNULER CHECKIN
          </Button>
        ) : null}
        <Button
          variant="contained"
          onClick={() => handleDetailsDialogOpen(true)}
          sx={{
            borderRadius: "20px",
            backgroundColor: "#454545",
            marginTop: "1.3rem",
            ":hover": {
              backgroundColor: "#454545",
            },
          }}
        >
          DETAILS
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default CheckinDialog;
