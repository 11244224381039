import React from "react";
import ApiService from "../services/ApiService";
import { Beforeunload } from "react-beforeunload";
import {
  MDBInput,
  MDBFormInline,
  MDBNavbar,
  MDBNavItem,
  MDBCollapse,
  MDBNavbarNav,
  Modal,
  ModalBody,
  ModalHeader,
} from "mdbreact";
import Loader from "./Loader";
import { HashLink as Link } from "react-router-hash-link";
import "./WebPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faCheck, faBars } from "@fortawesome/free-solid-svg-icons";
import WebPageFollowersModal from "./WebPageFollowersModal";
import CGVModal from "./CGVModal";
import WebPageCheckout from "./WebPageCheckout";
import {
  webPageTranslations,
  formattedDays,
  formattedMonths,
} from "./Translations";
import FullCalendar from "@fullcalendar/react";
import timegridPlugin from "@fullcalendar/timegrid";
import "@fullcalendar/core/main.min.css";
import "@fullcalendar/timegrid/main.css";
import ReactDOM from "react-dom";
import {
  checkNotAccess,
  returnErrorHtml,
  isEventPaymentConfigOk,
} from "../services/Utils";
import { IconButton, CircularProgress, Dialog } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import PhoneNumberInput from "../admin/_modules/components/PhoneNumberInput";

class WebPage extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.match.params.accessToken) {
      localStorage.setItem(
        "invitation_token",
        this.props.match.params.accessToken
      );
    }
    localStorage.setItem("showCalendar", "true");

    this.IlimitedQuota = 1000000;

    this.state = {
      calendarEventPlaces: null,
      calendarEventThematiques: null,
      calendarEventIconUrl: null,
      calendarEventComponent: null,
      calendarEventBackgroundColor: null,
      showCalendarEventDetail: false,
      tags: [],
      delegataires: [],
      lastInvoice: null,
      guestInvoices: [],
      isValidated: true,
      stripeKey: "",
      stripeSecretKey: "",
      modal: false,
      line: [],
      showCheckoutPage: false,
      isLoading: true,
      componentsResponses: [],
      code: this.props.contact
        ? this.props.contact.languages
          ? this.props.contact.languages
          : "FR"
        : "FR",
      eventId: this.props.eventId,
      event: this.props.event,
      activeGuestKey: 0,
      guest: null,
      itemsMainGuest: [],
      eventComponents: [],
      itemsSideGuest: [],
      itemsMainInvitation: [],
      itemsSideInvitation: [],
      contact: this.props.contact,
      eventResponses: [],
      followersToDelete: [],
      componentEntrance: null,
      componentInvitation: null,
      packageGuest: {},
      packageInvitation: {},
      showMenu: false,
      multiForms: false,
      showGuestContainer: false,
      showEventFormMessage: false,
      messageToShow: "",
      existingReponse: false,
      isModifiable: this.props.isModifiable,
      calendars: [],
      initialComponentsResponses: [],
      invitedByContactName: null,
      allowAddFollower: true,
      cgv: null,
      formModified: false,
      mainContainerHeight: "",
      isShortPage: false,
      errorsWorkspaces: [],
      hasAccess: {
        access: true,
        reason: "",
      },
      buttonLoading: false,
      isDelegatairePackageFull: false,
      componentToDisable: null,
      windowWidth: window.innerWidth,
      removedGuestsResponses: [],
      newGuestsResponses: [],
      totalTTC: 0,
      isInvoiceProcessing: false,
      phoneNumberError: false,
    };
    this.componentRef = React.createRef();

    this.customEventRender = this.customEventRender.bind(this);
    this.handleReponseChange = this.handleReponseChange.bind(this);
    this.initiatePreFilledAndRequestElements =
      this.initiatePreFilledAndRequestElements.bind(this);
    this.checkFormComplete = this.checkFormComplete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.createFollower = this.createFollower.bind(this);
    this.sendResponses = this.sendResponses.bind(this);
    this.sendResponses2 = this.sendResponses2.bind(this);
    this.chargeMoney = this.chargeMoney.bind(this);
    this.removeFollower = this.removeFollower.bind(this);
    this.deleteFollowers = this.deleteFollowers.bind(this);
    this.sendConfirmationEmail = this.sendConfirmationEmail.bind(this);
    this.clearGuestResponse = this.clearGuestResponse.bind(this);
    this.switchMenu = this.switchMenu.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.modifyForm = this.modifyForm.bind(this);
    this.findRelatedContacts = this.findRelatedContacts.bind(this);
    this.buildCalendar = this.buildCalendar.bind(this);
    this.handleCalendarClick = this.handleCalendarClick.bind(this);
    this.getBackgroundImageUrl = this.getBackgroundImageUrl.bind(this);

    this.checkComponentInitialReponse =
      this.checkComponentInitialReponse.bind(this);
    this.checkComponentAvailableSpace =
      this.checkComponentAvailableSpace.bind(this);
    this.replaceShortCode = this.replaceShortCode.bind(this);
    this.generateHtmlComponent = this.generateHtmlComponent.bind(this);
    this.toggle = this.toggle.bind(this);
    this.setGuest = this.setGuest.bind(this);
    this.checkAllComponentsAvailableSpace =
      this.checkAllComponentsAvailableSpace.bind(this);
    this.toggleCalendar = this.toggleCalendar.bind(this);
    this.handleFollowerDialogClose = this.handleFollowerDialogClose.bind(this);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.checkComponentPackageInvitation =
      this.checkComponentPackageInvitation.bind(this);
    this.completevalidateForm = this.completevalidateForm.bind(this);
    this.updateEventFormMessage = this.updateEventFormMessage.bind(this);
    this.getBackgroundImageUrl = this.getBackgroundImageUrl.bind(this);
    this.setComponentsResponses = this.setComponentsResponses.bind(this);
    this.getComponentResponseLabel = this.getComponentResponseLabel.bind(this);
    this.updateNewAndRemovedGuestsResponsesList =
      this.updateNewAndRemovedGuestsResponsesList.bind(this);
  }

  toggleCalendar(
    calendarEventComponent,
    calendarEventBackgroundColor,
    calendarEventIconUrl,
    calendarEventThematiques,
    calendarEventPlaces
  ) {
    this.setState({
      showCalendarEventDetail: !this.state.showCalendarEventDetail,
      calendarEventComponent,
      calendarEventBackgroundColor,
      calendarEventIconUrl,
      calendarEventThematiques,
      calendarEventPlaces,
    });
  }

  handleFollowerDialogClose() {
    this.setState({ modal: false });
  }

  checkComponentInitialReponse(component, reponseId) {
    const { initialComponentsResponses } = this.state;

    const initialResponse = initialComponentsResponses.find(
      (userReponse) => userReponse.reponseId === reponseId
    );

    if (initialResponse) {
      const initialComponentsResponse = initialResponse.reponses.find(
        (componentReponse) => componentReponse.id === component.id
      );

      if (initialComponentsResponse) {
        if (
          (component.data.reponse.htmlType === "radio" &&
            initialComponentsResponse.key !== 1) ||
          component.data.reponse.htmlType === "checkbox"
        ) {
          return initialComponentsResponse;
        }
      }
    }

    return null;
  }

  updateNewAndRemovedGuestsResponsesList(
    removedGuestsResponses,
    newGuestsResponses,
    totalTTC
  ) {
    this.setState({
      removedGuestsResponses,
      newGuestsResponses,
      totalTTC,
    });
  }

  async checkAllComponentsAvailableSpace() {
    const { code, componentEntrance, componentsResponses } = this.state;

    const eventResponses = await ApiService.awaitRequest(
      {},
      "component_responses/byevenement/" + this.state.form.id,
      "get"
    );

    const eventComponents = await ApiService.awaitRequest(
      {},
      "components/byevenement/" + this.state.eventId,
      "get"
    );

    let displayWarning = false;
    let messageToShow = "";
    let componentToDisable = null;
    let updatedComponentsResponses = JSON.parse(
      JSON.stringify(componentsResponses)
    );
    if (eventComponents && eventResponses) {
      componentsResponses.forEach((userReponse, key) => {
        if (
          userReponse &&
          userReponse.reponses &&
          userReponse.reponses.length > 0
        ) {
          userReponse.reponses.forEach((componentReponse, responseIndex) => {
            const component = eventComponents.find(
              (eventComponent) => eventComponent.id === componentReponse.id
            );
            const availableSpaces = this.checkComponentAvailableSpace(
              component,
              code,
              componentReponse.id === componentEntrance.id,
              key,
              eventResponses
            );

            if (
              !availableSpaces.available &&
              availableSpaces.displayWarning &&
              componentReponse.id !== componentEntrance.id
            ) {
              updatedComponentsResponses = updatedComponentsResponses.map(
                (userRep) => {
                  const newUserRep = { ...userRep };
                  if (userRep.reponses && userRep.reponses.length > 0) {
                    newUserRep.reponses = userRep.reponses.filter(
                      (rep) => rep.id !== component.id
                    );
                  }
                  return newUserRep;
                }
              );
              componentToDisable = component;
              displayWarning = true;
            } else if (
              !availableSpaces.available &&
              availableSpaces.displayWarning
            ) {
              if (
                key === 0 &&
                componentReponse.id === componentEntrance.id &&
                !this.state.guest.participate
              ) {
                messageToShow = this.replaceShortCode(
                  this.state.event.data.eventFullText.find(
                    (message) => message.code === this.state.code
                  ).value
                );
                componentToDisable = component;
                displayWarning = true;
              }
            }
          });
        }
      });

      if (
        // !isEquivalent(componentsResponses, updatedComponentsResponses) &&
        displayWarning
      ) {
        alert(
          webPageTranslations[code ? code : "FR"]
            .no_more_space_for_some_components
        );
        this.setState({
          componentsResponses: updatedComponentsResponses,
          showCheckoutPage: false,
          activeGuestKey: 0,
          messageToShow,
          showEventFormMessage: messageToShow && messageToShow.length > 0,
          eventResponses,
          eventComponents,
        });
      }
    }

    if (!displayWarning) {
      return true;
    } else {
      this.setState({ componentToDisable: componentToDisable.id });
      return false;
    }
  }

  checkComponentAvailableSpace(
    component,
    code,
    componentEntranceBoolean,
    activeGuestKey,
    eventResponses
  ) {
    const {
      initialComponentsResponses,
      componentEntrance,
      componentInvitation,
      guest,
      delegataires,
    } = this.state;

    const componentsResponses = JSON.parse(
      JSON.stringify(this.state.componentsResponses)
    );

    let message = "";
    let available = true;
    let displayWarning = false;
    let totalBooked = 0;
    let alreadyBooked = 0;
    let guestResponse = null;

    const componentEventResponsesAlreadyBooked = [];
    const componentEventResponseTotalBooked = [];

    const guestDelegataire = delegataires.find(
      (delegataire) =>
        Number(delegataire.user_id) === Number(guest.user.id) &&
        delegataire.package_ids &&
        delegataire.package_ids.includes(guest.package_id)
    );

    let quota = 0;
    console.log('🤓 ~ component ~', component);
    const quotaDelegataires =
      component.data.quotas && component.data.quotas.delegataires
        ? component.data.quotas.delegataires
        : [];
    const totalComponentQuota = component.data.quotas
      ? component.data.quotas.total
      : 0;
    if (guest.user) {
      const delegataireQuotaFound = quotaDelegataires.find(
        (delegataire) =>
          Number(delegataire.userId) === Number(guest.user.id) &&
          guestDelegataire
      );

      if (delegataireQuotaFound) {
        // TODO: find inside delegataire component quota
        quota = delegataireQuotaFound.quota;
      } else {
        if (
          component.data.reponse &&
          (component.data.reponse.role === "entrance" ||
            component.data.reponse.role === "invitation")
        ) {
          quota = 0; // null => when no quota is affected to the entry component
        } else quota = 100000; //unlimited  => when no quota is affected to the component
      }
    } else {
      const totalDelegatairesQuotas = quotaDelegataires.reduce((acc, curr) => {
        if (curr.quota) return curr.quota + acc;
        return acc;
      }, 0);

      quota = totalComponentQuota - totalDelegatairesQuotas; //? if activate this then: don't add delega guests responses to admin responses
      // quota = totalComponentQuota;
    }

    if (
      component.data.componentType === "logistic" ||
      component.data.componentType === "moment"
    ) {
      eventResponses.forEach((usersResponses) => {
        if (
          usersResponses &&
          usersResponses.responses &&
          usersResponses.responses.length > 0
        ) {
          usersResponses.responses.forEach((componentReponse) => {
            if (usersResponses.guest) {
              if (Number(componentReponse.id) === Number(component.id)) {
                if (
                  (guestDelegataire && guest.user
                    ? usersResponses.guest.user_id === guest.user.id &&
                      componentReponse.key === 0
                    : false) ||
                  (!guestDelegataire &&
                    guest.user_id === null &&
                    usersResponses.guest.user_id === null &&
                    componentReponse.key === 0)
                ) {
                  componentEventResponsesAlreadyBooked.push({
                    ...componentReponse,
                    responseId: usersResponses.id,
                  });
                  componentEventResponseTotalBooked.push({
                    ...componentReponse,
                    responseId: usersResponses.id,
                  });
                  if (usersResponses.guest.id === guest.id)
                    guestResponse = componentReponse;
                }
              }
            }
          });
        }
        // }
      });

      componentsResponses.forEach((userResponse) => {
        if (
          userResponse &&
          userResponse.reponses &&
          userResponse.reponses.length > 0
        ) {
          userResponse.reponses.forEach((componentReponse) => {
            if (Number(componentReponse.id) === Number(component.id)) {
              //TODO: find existing response and update it
              const index = componentEventResponseTotalBooked.findIndex(
                (cmpResponse) =>
                  cmpResponse.responseId === userResponse.reponseId &&
                  cmpResponse.id === Number(component.id)
              );
              if (index > -1) {
                componentEventResponseTotalBooked[index] = {
                  ...componentReponse,
                  guestId: userResponse.guestId,
                  responseId: Number(userResponse.reponseId),
                };
              } else {
                componentEventResponseTotalBooked.push({
                  ...componentReponse,
                  guestId: userResponse.guestId,
                  responseId: Number(userResponse.reponseId),
                });
              }
            }
          });
        }
      });

      switch (component.data.reponse.htmlType) {
        case "radio":
          alreadyBooked = componentEventResponsesAlreadyBooked.filter(
            (reponse) => reponse.key !== 1 && reponse.key !== null
          ).length;
          totalBooked = componentEventResponseTotalBooked.filter(
            (reponse) => reponse.key !== 1 && reponse.key !== null
          ).length;
          break;
        case "checkbox":
          alreadyBooked = componentEventResponsesAlreadyBooked.length;
          totalBooked = componentEventResponseTotalBooked.length;

          break;
        default:
          break;
      }
      if (
        component.data.quotaWarning &&
        component.data.quotaWarning.length > 0
      ) {
        if (
          Number(quota) - totalBooked <=
          Number(component.data.quotaWarning)
        ) {
          message = component.data.quotaWarningLabel.find(
            (label) => label.code === code
          ).value;

          if (Number(quota) - totalBooked === 0) {
            message = "";
          } else {
            const parts = message.split("%");
            if (parts.length === 3) {
              message =
                parts[0] + " " + (Number(quota) - totalBooked) + " " + parts[2];
            }
          }
        }
      }
      if (totalBooked === Number(quota)) {
        // Used when a guest has a follower(s) which have a package
        // It prevents from getting the quota exceed by a guest or a follower, if one of them already reached the quota in the form, the component become unavailable when we switch to an another form
        if (
          alreadyBooked < Number(quota) &&
          guest.invitation_package_id !== null
        ) {
          available = componentsResponses[activeGuestKey]
            ? componentsResponses[activeGuestKey].reponses.some(
                (componentReponse) =>
                  Number(componentReponse.id) === Number(component.id) &&
                  componentReponse.key === 0 &&
                  (!componentInvitation ||
                    Number(componentReponse.id) !== componentInvitation.id) // We need to verify this condition only if componentInvitation exist
              )
            : false;
        }

        // It makes unavailable component which are already full
        if (alreadyBooked >= Number(quota)) {
          available = initialComponentsResponses[activeGuestKey]
            ? initialComponentsResponses[activeGuestKey].reponses.some(
                (componentReponse) =>
                  componentReponse.id === component.id &&
                  componentReponse.key !== 1 &&
                  (!componentInvitation ||
                    componentReponse.id !== componentInvitation.id)
              )
            : false;
        } else if (componentEntranceBoolean) {
          available = false;
        }
        // Used when a guest has a follower(s) without a package
      } else if (
        guest.max_invitation > 0 &&
        guest.invitation_package_id === null &&
        componentsResponses[0].reponses.find(
          (reponse) =>
            reponse.key === 0 &&
            (!componentInvitation || reponse.id === componentInvitation.id) // verify this condition only if componentInvitation exist
        ) &&
        componentsResponses.length > 1
      ) {
        if (totalBooked === Number(quota)) {
          if (alreadyBooked >= Number(quota)) {
            // It displays unavailable when the number of guest + follower(s) will exceed the quota with the already booked components responses. Before it was unavailable only after you clicked on "Yes" to the component
            available = componentsResponses[0]
              ? componentsResponses[0].reponses.some(
                  (componentReponse) =>
                    componentReponse.id === component.id &&
                    componentReponse.key !== 1 &&
                    componentReponse.key !== null &&
                    (!componentInvitation ||
                      componentReponse.id !== componentInvitation.id)
                )
              : false;

            // When the guest choose "No" before to add a follower and it's exceeding the quota with that follower added, it deletes the answer, so it renders without seeing "No" checked
            if (!available) {
              componentsResponses.forEach((userReponse) =>
                userReponse.reponses.some((componentReponse) =>
                  componentReponse.id === component.id &&
                  componentReponse.key === 1 &&
                  (!componentInvitation ||
                    componentReponse.id !== componentInvitation.id)
                    ? (userReponse.reponses = userReponse.reponses.filter(
                        (userReponses) =>
                          userReponses.id !== componentReponse.id
                      ))
                    : ""
                )
              );
            }
          } else if (componentEntranceBoolean) {
            available = false;
          }
        } else if (totalBooked > Number(quota)) {
          // When the guest choose "Yes" before to add a follower and it's exceeding the quota with that follower added, it deletes the answer, so it renders without seeing "Yes" checked. Same for Calendar component.
          componentsResponses.forEach((userReponse) =>
            userReponse.reponses.some((componentReponse) =>
              componentReponse.id !== componentEntrance.id &&
              (!componentInvitation ||
                componentReponse.id !== componentInvitation.id) &&
              componentReponse.id === component.id
                ? component.data.reponse.displayType !== "agenda"
                  ? (userReponse.reponses = userReponse.reponses.filter(
                      (userReponses) => userReponses.id !== componentReponse.id
                    ))
                  : component.data.reponse.displayType === "agenda"
                  ? (userReponse.reponses = userReponse.reponses.filter(
                      (userReponses) => userReponses.id !== componentReponse.id
                    ))
                  : ""
                : ""
            )
          );

          displayWarning = true;
          available = false;
        }
      } else if (totalBooked > Number(quota)) {
        displayWarning = true;
        available = false;
      }
    }

    console.log("🚀 ~ WebPage ~ quota:", {
      available,
      quota,
      totalBooked,
      alreadyBooked,
      component: component,
      componentEventResponsesAlreadyBooked,
      componentEventResponseTotalBooked,
      componentEntrance,
    });

    return {
      available,
      message,
      displayWarning,
      quota,
      totalBooked,
      alreadyBooked,
      component,
      guestResponse,
    };
  }

  generateHtmlComponent(packageItems, layoutComposant, location, componentKey) {
    const {
      event,
      code,
      activeGuestKey,
      eventComponents,
      form,
      guest,
      componentsResponses,
      componentEntrance,
      eventResponses,
      isDelegatairePackageFull,
    } = this.state;

    const componentResponses = componentsResponses[activeGuestKey].reponses;

    const composant = eventComponents.find(
      (component) => component.id === layoutComposant.id
    );

    // console.log(eventComponents);

    const options = composant.data.reponse.options.find(
      (option) => option.code === code
    ).value;

    let htmlElementForm = null;

    const description =
      form.layout.documentType === "website"
        ? composant.data.reponse.websiteDescription
        : composant.data.reponse.reponseDescription;

    const updatedDescription = this.replaceShortCode(
      description.find((description) => description.code === code).value
    );
    let showComponentInvitation = true;

    if (activeGuestKey === 0) {
      showComponentInvitation = true;
    } else {
      if (composant.data.reponse.role === "invitation")
        showComponentInvitation = false;
      else showComponentInvitation = true;
    }

    const willNotJoin = componentsResponses[0].reponses.some(
      (reponse) => reponse.id === componentEntrance.id && reponse.key === 1
    );

    if (
      composant.data.reponse.role === "invitation" &&
      guest.max_invitation === 0
    ) {
      return null;
    }

    if (composant.data.reponse.role === "delegataire" && guest.user) {
      htmlElementForm = (
        <div
          className="delegataire-contact"
          dangerouslySetInnerHTML={{
            __html: guest.user.contact_informations,
          }}
        />
      );
    } else if (form.layout.documentType !== "website") {
      switch (composant.data.reponse.displayType) {
        case "agenda":
          const showCalendar = localStorage.getItem("showCalendar");
          if (showCalendar === "false") {
            return null;
          } else {
            const calendars = this.buildCalendar(packageItems);
            localStorage.setItem("showCalendar", "false");
            // TODO : ICI AGENDA
            return calendars.map((item) => (
              <div className="form-element calendars col-md-12">
                <div
                  className="form-group calendar-day mt-3 col-md-12"
                  style={{
                    color:
                      location === "side"
                        ? form.layout.titleColorSide
                        : form.layout.titleColorMain,
                    backgroundColor:
                      location === "side"
                        ? form.layout.titleBackgroundColorSide
                        : form.layout.titleBackgroundColorMain,
                    fontWeight: "bold",
                    padding: "5px",
                  }}
                >
                  {item.label}
                </div>
                <div className="form-group col-md-12 calendar-content">
                  {item.calendar}
                </div>
              </div>
            ));
          }

        case "list":
          switch (composant.data.reponse.htmlType) {
            case "text":
              if (composant.data.reponse.guestFieldRequired === "phone") {
                this.countryDetails = React.createRef(null);

                htmlElementForm = (
                  <PhoneNumberInput
                    parentPhoneNumber={guest.mobile || ""}
                    setParentPhoneNumber={(value) => {
                      console.log(
                        "🚀 ~ generateHtmlComponent ~ composant.id:",
                        composant.id,
                        value
                      );
                      this.handleReponseChange(
                        composant.id,
                        "",
                        value,
                        "",
                        true,
                        true,
                        null
                      );
                    }}
                    setParentPhoneNumberError={(value) =>
                      this.setState({ phoneNumberError: value })
                    }
                    countryDetails={this.countryDetails}
                  />
                );
              } else {
                htmlElementForm = (
                  <input
                    value={
                      (componentResponses.find(
                        (response) => response.id === composant.id
                      )
                        ? componentResponses.find(
                            (response) => response.id === composant.id
                          ).label
                        : "") || ""
                    }
                    className="form-control"
                    onChange={(e) =>
                      this.handleReponseChange(
                        composant.id,
                        "",
                        e.target.value.trimStart(),
                        "",
                        true,
                        true,
                        null
                      )
                    }
                  />
                );
              }

              break;

            case "radio":
              if (options) {
                const optionsArray = options.split(";");

                htmlElementForm = (
                  <MDBFormInline>
                    {optionsArray.map((option, key) => (
                      <MDBInput
                        id={composant.id + "-" + key}
                        key={key}
                        onClick={() =>
                          this.handleReponseChange(
                            composant.id,
                            key,
                            option,
                            "",
                            true,
                            true,
                            null
                          )
                        }
                        checked={componentResponses.some(
                          (response) =>
                            response.id === composant.id && response.key === key
                        )}
                        label={option}
                        type="radio"
                      />
                    ))}
                  </MDBFormInline>
                );
              }
              break;

            case "checkbox":
              htmlElementForm = (
                <MDBFormInline>
                  {options ? (
                    options
                      .split(";")
                      .map((option, key) => (
                        <MDBInput
                          onClick={(e) =>
                            this.handleReponseChange(
                              composant.id,
                              key,
                              option,
                              e.target.checked,
                              false,
                              true,
                              null
                            )
                          }
                          id={composant.id + "-" + key}
                          key={key}
                          label={option}
                          filled
                          checked={componentResponses.some(
                            (response) =>
                              response.id === composant.id &&
                              response.key === key
                          )}
                          type="checkbox"
                        />
                      ))
                  ) : (
                    <MDBInput
                      onClick={(e) =>
                        this.handleReponseChange(
                          composant.id,
                          "",
                          this.renderLabel(composant),
                          e.checked,
                          true,
                          true,
                          null
                        )
                      }
                      id={composant.id}
                      key={composant.id}
                      checked={componentResponses.some(
                        (response) => response.id === composant.id
                      )}
                      label={this.renderLabel(composant)}
                      filled
                      type="checkbox"
                    />
                  )}
                </MDBFormInline>
              );

              break;

            default:
              break;
          }
          break;
        default:
          break;
      }
    }

    const availableSpaces = this.checkComponentAvailableSpace(
      composant,
      code,
      false,
      activeGuestKey,
      eventResponses
    );

    if (!availableSpaces.available && this.state.componentToDisable) {
      if (composant.id === this.state.componentToDisable) {
        // if (
        //   availableSpaces.guestResponse &&
        //   availableSpaces.guestResponse.key === 0
        // ) {
        //   availableSpaces.available = true;
        //   availableSpaces.displayWarning = false;
        // } else {
        //   availableSpaces.available = false;
        //   availableSpaces.displayWarning = true;
        // }

        availableSpaces.available = false;
        availableSpaces.displayWarning = true;
      }
    }

    return (
      <div
        key={componentKey}
        ref={this.componentRef}
        id={composant.id}
        className={`composant-item form-element col-md-${
          composant.data.reponse.formWidth
        }  ${
          form.layout.documentType !== "website"
            ? !availableSpaces.available
              ? "disabled"
              : applyRules(
                  packageItems,
                  event.component_rules,
                  composant.id,
                  componentResponses,
                  willNotJoin,
                  componentEntrance,
                  eventComponents,
                  isDelegatairePackageFull
                )
            : null
        }`}
        style={{
          backgroundColor: "transparent",
        }}
      >
        {layoutComposant.displayTitle && showComponentInvitation ? (
          <div className={"composant-label-container form-group"}>
            <span
              className={"composant-label"}
              style={{
                color:
                  location === "side"
                    ? form.layout.titleColorSide
                    : form.layout.titleColorMain,
                backgroundColor:
                  location === "side"
                    ? form.layout.titleBackgroundColorSide
                    : form.layout.titleBackgroundColorMain,
                fontWeight: "bold",
                padding: "5px",
              }}
            >
              {this.renderLabel(composant)}
              {form.layout.documentType !== "website" &&
              !availableSpaces.available
                ? webPageTranslations[code ? code : "FR"].fullEventText
                : null}
              {form.layout.documentType !== "website" &&
              composant.data.endDate &&
              new Date(composant.data.endDate) < new Date() ? (
                <span className="text-danger">{" (Terminé)"}</span>
              ) : null}
              <span className="error"> *</span>
            </span>

            {form.layout.documentType !== "website" &&
            availableSpaces.available &&
            availableSpaces.message.length > 0 ? (
              <span>
                <br />
                <span
                  dangerouslySetInnerHTML={{
                    __html: availableSpaces.message,
                  }}
                />
              </span>
            ) : null}
          </div>
        ) : null}
        <div
          className={"composant-description-container form-group  col-md-12"}
          style={{
            color:
              location === "side"
                ? form.layout.textColorSide
                : form.layout.textColorMain,

            backgroundColor:
              location === "side"
                ? form.layout.backgroundColorSide
                : "transparent",
          }}
        >
          {showComponentInvitation ? htmlElementForm : null}

          {composant.data.reponse.role === "invitation"
            ? this.renderInvitationsTable()
            : null}

          {layoutComposant.displayDescription ? (
            <div
              className={"form-group composant-description"}
              dangerouslySetInnerHTML={{
                __html: updatedDescription,
              }}
            />
          ) : null}
        </div>
      </div>
    );
  }

  handleCalendarClick(componentId, title, checked, available) {
    if (available || (!available && !checked)) {
      this.handleReponseChange(
        componentId,
        0,
        title,
        checked,
        true,
        true,
        null
      );
    }
  }

  getBackgroundImageUrl = (imageType) => {
    const { form, code } = this.state;
    const image = form.layout[imageType]; // Utilisez la variable imageType pour accéder à headerImage ou footerImage

    if (!image) {
      return ""; // Retourne une chaîne vide si l'image est undefined
    }

    if (typeof image === "string") {
      // Si l'image est une chaîne, retournez-la directement
      return image;
    }

    // Si l'image est un tableau d'objets, recherchez l'objet correspondant à la langue sélectionnée
    const languageObject = image.find((item) => Object.keys(item)[0] === code);

    // Retournez l'URL associée à la langue, ou la FR ou une chaine vide
    return languageObject
      ? languageObject[code]
      : image[0]
      ? image[0]["FR"]
      : "";
  };

  withLeadingZero(string) {
    return string > 10 ? string : "0" + string;
  }

  replaceShortCode(description) {
    const { guest, event, code } = this.state;

    if (description) {
      const reponsePath = this.props.location.pathname
        .replace("programme", "reponse")
        .substr(1);
      const websitePath = this.props.location.pathname
        .replace("reponse", "programme")
        .substr(1);
      let updatedDescription = description;
      const textWithWebsite = description.replace("[[website]]", websitePath);
      updatedDescription = textWithWebsite;
      const textWithForm = updatedDescription.replace("[[form]]", reponsePath);
      updatedDescription = textWithForm;
      const textWithContactInformations = updatedDescription.replace(
        "[[contact]]",
        (guest.user
          ? guest.user.contact_informations
          : event.data.contactInformations.find((lang) => lang.code === code)
              .value) || ""
      );
      updatedDescription = textWithContactInformations;
      const textWithGuestLastname = updatedDescription.replace(
        "[[guest.lastname]]",
        guest.lastname
      );
      updatedDescription = textWithGuestLastname;
      const textWithGuestFirstname = updatedDescription.replace(
        "[[guest.firstname]]",
        guest.firstname
      );
      updatedDescription = textWithGuestFirstname;

      return updatedDescription;
    } else return "";
  }

  handleWindowSizeChange() {
    this.setState({ windowWidth: window.innerWidth });
  }

  customEventRender({ event, el }) {
    const { code, activeGuestKey, eventResponses, windowWidth } = this.state;
    const eventDiv = document.createElement("div");

    const classes = Array.from(el.classList);

    eventDiv.classList.add(...classes);

    const { willJoin, component, iconUrl, rulesFormatted, thematiques } =
      event.extendedProps;

    eventDiv.id = component.id;
    const availableSpaces = this.checkComponentAvailableSpace(
      component,
      code,
      false,
      activeGuestKey,
      eventResponses
    );

    console.log("🔥 3 ~ availableSpaces:", availableSpaces);

    if (availableSpaces.totalBooked > availableSpaces.quota) {
      availableSpaces.available = false;
    }
    eventDiv.classList.remove(
      "required",
      "hidden",
      "displayed",
      "enabled",
      "disabled"
    );

    if (rulesFormatted.length > 0 && rulesFormatted[0].length > 0)
      eventDiv.classList.add(...rulesFormatted);

    var cssIcon = "";
    if (iconUrl) {
      cssIcon = " haveIcon";
    }

    const matchDevice1 = windowWidth <= 990;
    const matchDevice2 = windowWidth <= 580;

    const content = (
      <div
        onClick={(eventClick) => {
          return eventClick.target.className &&
            eventClick.target.className.includes("calendar-button")
            ? console.log("click sur le bouton")
            : this.handleCalendarClick(
                component.id,
                event.title,
                !willJoin,
                availableSpaces.available
              );
        }}
        className={"calendar-item" + cssIcon}
        style={{
          backgroundColor: willJoin
            ? "#ff0000"
            : availableSpaces.available
            ? event.backgroundColor
            : "#c2c2c2",

          color: event.textColor,
          height: "100%",
        }}
      >
        {willJoin ? (
          <div
            className="border-top-block"
            style={{ height: "7px", backgroundColor: event.backgroundColor }}
          ></div>
        ) : null}
        <div
          className="pt-2 ml-2 item-calendar-title font-weight-bold"
          title={event.title}
        >
          {event.title}
        </div>
        <div
          className="calendar-spaces ml-2"
          style={{ marginBottom: matchDevice2 ? "1.8rem" : null }}
        >
          {availableSpaces.available ? (
            availableSpaces.quota === this.IlimitedQuota ? (
              availableSpaces.totalBooked + " / ..."
            ) : (
              availableSpaces.totalBooked + " / " + availableSpaces.quota
            )
          ) : (
            <span className="text-light">
              {webPageTranslations[code ? code : "FR"].fullEventText}
            </span>
          )}
        </div>

        {iconUrl ? (
          <div className="calendar-icon">
            <img
              src={`https://res.cloudinary.com/kanguroo-event/image/upload/w_50/${iconUrl}`}
              className="img-fluid"
              alt=""
            />
          </div>
        ) : (
          ""
        )}

        <div
          id="calendar-button"
          className="calendar-button calendar-event-detail"
          onClick={() =>
            this.toggleCalendar(
              component,
              event.backgroundColor,
              iconUrl,
              thematiques,
              availableSpaces.quota === this.IlimitedQuota
                ? availableSpaces.totalBooked + " / ..."
                : availableSpaces.totalBooked + " / " + availableSpaces.quota
            )
          }
        >
          {" "}
          {matchDevice1
            ? "..."
            : webPageTranslations[code ? code : "FR"].see_more}
        </div>
      </div>
    );

    ReactDOM.render(content, eventDiv);

    return eventDiv;
  }

  buildCalendar(packageItems) {
    const {
      eventComponents,
      code,
      form,
      componentsResponses,
      activeGuestKey,
      tags,
      componentEntrance,
      isDelegatairePackageFull,
    } = this.state;

    const itemsAgenda = [];
    packageItems.forEach((layoutComponent) => {
      const component = eventComponents.find(
        (component) =>
          component.id === layoutComponent.id &&
          component.data.reponse.displayType === "agenda"
      );
      if (component) itemsAgenda.push(component);
    });

    const itemsAgendaByOrder = itemsAgenda.sort(function (a, b) {
      return (
        new Date(a.data.startDate).getTime() -
        new Date(b.data.startDate).getTime()
      );
    });

    let currentDate = "";
    let calendarBeginDate = new Date("2025", "02", "02");
    let calendarEndDate = new Date();
    let calendarEvents = [];

    const calendars = [];

    itemsAgendaByOrder.forEach((itemAgenda, key) => {
      const dateToDisplay = new Date(
        itemsAgendaByOrder[key + 1]
          ? itemsAgendaByOrder[key + 1].data.startDate
          : itemAgenda.data.startDate
      );
      const start = new Date(itemAgenda.data.startDate);
      const end = new Date(itemAgenda.data.endDate);

      if (start.getTime() < calendarBeginDate.getTime()) {
        calendarBeginDate = start;
      }

      if (end.getTime() > calendarEndDate.getTime()) {
        calendarEndDate = end;
      }

      const nextDate =
        formattedDays.find((lang) => lang.lang === code).labels[
          dateToDisplay.getDay()
        ] +
        " " +
        dateToDisplay.getDate() +
        " " +
        formattedMonths.find((lang) => lang.lang === code).labels[
          dateToDisplay.getMonth()
        ];

      if (key === 0) {
        currentDate = nextDate;
      }

      const color = tags.find(
        (tag) =>
          tag.color &&
          itemAgenda.data.reponse.tags.some((item) => item.id === tag.id)
      );
      const backgroundColor = color ? color.color : form.layout.mainColor;

      const thematiques = tags
        .filter((tag) =>
          itemAgenda.data.reponse.tags.some((item) => item.id === tag.id)
        )
        .map((tag) => tag.names)
        .join(",");

      const iconUrl = tags.find(
        (tag) =>
          tag.icon_url &&
          itemAgenda.data.reponse.tags.some((item) => item.id === tag.id)
      );

      const textColor = backgroundColor ? "white" : form.layout.secondaryColor;

      const willJoin = componentsResponses[activeGuestKey].reponses.some(
        (componentReponse) => componentReponse.id === itemAgenda.id
      );

      const willNotJoin = componentsResponses[0].reponses.some(
        (reponse) => reponse.id === componentEntrance.id && reponse.key === 1
      );

      const componentResponses = componentsResponses[activeGuestKey].reponses;

      const rule = applyRules(
        packageItems,
        this.state.event.component_rules,
        itemAgenda.id,
        componentResponses,
        willNotJoin,
        componentEntrance,
        eventComponents,
        isDelegatairePackageFull
      );

      const rulesFormatted = rule.trim().split(" ");

      if (!rulesFormatted.includes("hidden")) {
        calendarEvents.push({
          title: itemAgenda.data.reponse.reponseLabel.find(
            (label) => label.code === code
          ).value,
          extendedProps: {
            rulesFormatted,
            packageItems,
            willJoin,
            component: itemAgenda,
            iconUrl: iconUrl ? iconUrl.icon_url : null,
            thematiques,
          },
          start,
          end,
          id: itemAgenda.id,
          backgroundColor: backgroundColor,
          textColor: willJoin ? "white" : textColor,
        });
      }

      if (
        calendarEvents.length >= 1 &&
        (nextDate !== currentDate ||
          calendarEvents.length === itemsAgendaByOrder.length ||
          key === itemsAgendaByOrder.length - 1)
      ) {
        const events = calendarEvents;
        calendarEvents = [];

        const minTimethirty = new Date(
          events[0].start.getTime() /*- 30 * 60000*/
        );
        const maxTimethirty = new Date(
          events[events.length - 1].end.getTime() /*+ 30 * 60000*/
        );

        const minTime =
          this.withLeadingZero(minTimethirty.getHours()) +
          ":" +
          this.withLeadingZero(minTimethirty.getMinutes()) +
          ":" +
          this.withLeadingZero(minTimethirty.getSeconds());

        const maxTime =
          this.withLeadingZero(maxTimethirty.getHours()) +
          ":" +
          this.withLeadingZero(maxTimethirty.getMinutes()) +
          ":" +
          this.withLeadingZero(maxTimethirty.getSeconds());

        calendars.push({
          calendar: (
            <FullCalendar
              eventRender={(info) => this.customEventRender(info)}
              slotEventOverlap={false}
              slotDuration={"00:15:00"}
              slotLabelInterval={"01:00"}
              minTime={minTime}
              maxTime={maxTime}
              displayEventTime={false}
              defaultView="timeGridDay"
              plugins={[timegridPlugin]}
              contentHeight={"auto"}
              events={events}
              allDayText={""}
              start={calendarBeginDate}
              end={calendarEndDate}
              validRange={{ start: calendarBeginDate, end: calendarEndDate }}
              locale={code.toLowerCase()}
              header={{
                left: "",
                center: "",
                right: "",
              }}
            />
          ),
          label: currentDate,
        });

        calendarBeginDate = new Date("2025", "02", "02");
        calendarEndDate = new Date();
      }

      currentDate = nextDate;
    });

    return calendars;
  }

  async toggle() {
    const { componentEntrance, code, activeGuestKey, form } = this.state;
    const self = this;
    // When a guest updates his responses, deleting one or all follower(s), he can now add a new follower without validating the form first
    // even if the quota is reached because in the database followers are not deleted yet
    var eventR;
    if (this.state.followersToDelete.length === 0) {
      eventR = await ApiService.awaitRequest(
        {},
        "component_responses/byevenement/" + form.id,
        "get"
      );
    } else {
      eventR = this.state.eventResponses;
    }
    const eventResponses = eventR;

    this.setState(
      {
        eventResponses,
        modal: true,
      },
      () => {
        self.setState({
          allowAddFollower: self.checkComponentAvailableSpace(
            componentEntrance,
            code,
            true,
            null,
            eventResponses
          ).available,
          activeGuestKey,
        });
      }
    );
  }

  modifyForm() {
    this.setState({ showCheckoutPage: false });
  }

  async completevalidateForm(willJoin) {
    const self = this;
    this.setState({ buttonLoading: true });
    const webpageData = await self.checkAllComponentsAvailableSpace();
    if (webpageData) {
      this.setState({ buttonLoading: false });
      if (
        self.state.event.data.free === "false" ||
        self.state.event.data.free === false
      ) {
        self.setState({ showCheckoutPage: true });
      } else {
        await self.sendResponses(true);
      }
    } else {
      this.setState({
        buttonLoading: false,
      });
    }
  }
  async validateForm() {
    const { componentsResponses, lastInvoice } = this.state;
    //console.log(this.state, "state");
    //console.log(this.props, "props");
    const willJoin = componentsResponses[0].reponses.some(
      (reponse) =>
        reponse.id === this.state.componentEntrance.id && reponse.key === 0
    );

    if (!willJoin && lastInvoice) {
      // let confirmButton = window.confirm(
      //   `${
      //     webPageTranslations[this.state.code ? this.state.code : "FR"]
      //       .cancelMessageConfirmation +
      //     " " +
      //     componentsResponses[0].firstname +
      //     " " +
      //     componentsResponses[0].lastname +
      //     " " +
      //     webPageTranslations[this.state.code ? this.state.code : "FR"]
      //       .validationCancelMessage
      //   }`
      // );
      // if (confirmButton) {
      this.completevalidateForm(willJoin);
      // } else return;
    } else {
      this.completevalidateForm(willJoin);
    }
  }

  switchMenu() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  async sendConfirmationEmail(willJoin) {
    const { existingReponse, eventId, event, code, isValidated, guest } =
      this.state;

    const self = this;

    if (!guest.user_id) {
      const templates = await ApiService.awaitRequest(
        {},
        "mailtemplates/byevenement/" + eventId,
        "get"
      );

      let template_id = "";

      if (willJoin) {
        if (!isValidated) {
          template_id = event.data.pendingConfirmationTemplate.find(
            (template) => template.code === code
          ).value;
        } else {
          if (existingReponse) {
            template_id = event.data.modificationConfirmationTemplate.find(
              (template) => template.code === code
            ).value;
          } else {
            template_id = event.data.registrationConfirmationTemplate.find(
              (template) => template.code === code
            ).value;
          }
        }
      } else {
        template_id = event.data.willNotJoinAndRefundTemplate.find(
          (template) => template.code === code
        ).value;
      }

      const subject = templates.find(
        (template) => template.id === Number(template_id)
      );

      if (subject) {
        await ApiService.awaitRequest(
          {
            template_id,
            subject: subject.description,
            guest_ids: [self.state.guest.id],
            user_ids: null,
          },
          "mailtemplates/send",
          "post"
        );
      }
    } else if (guest.created_by_delegataire && guest.user_id) {
      const templates = await ApiService.awaitRequest(
        {},
        "mailtemplates/byevenement/" + eventId,
        "get"
      );

      let template_id = "";

      if (willJoin) {
        if (!isValidated) {
          template_id = event.data.pendingConfirmationDelegataireTemplate.find(
            (template) => template.code === code
          ).value;
        } else {
          if (existingReponse) {
            template_id =
              event.data.modificationConfirmationDelegataireTemplate.find(
                (template) => template.code === code
              ).value;
          } else {
            template_id =
              event.data.registrationConfirmationDelegataireTemplate.find(
                (template) => template.code === code
              ).value;
          }
        }
      } else {
        template_id = event.data.willNotJoinAndRefundDelegataireTemplate.find(
          (template) => template.code === code
        ).value;
      }

      const subject = templates.find(
        (template) => template.id === Number(template_id)
      );

      if (subject) {
        await ApiService.awaitRequest(
          {
            template_id,
            subject: subject.description,
            guest_ids: [self.state.guest.id],
            user_ids: null,
          },
          "mailtemplates/send",
          "post"
        );
      }
    }
  }

  async checkComponentPackageInvitation(
    guest,
    packageInvitation,
    eventComponents
  ) {
    const { form, code } = this.state;
    const packageComponents = packageInvitation.components;
    packageComponents.forEach((packageComponent) => {
      const composant = eventComponents.find(
        (eventComponent) => packageComponent.id === eventComponent.id
      );
      if (composant) {
        if (form.layout.documentType !== "website") {
          const options = composant.data.reponse.options.find(
            (option) => option.code === code
          ).value;
          switch (composant.data.reponse.htmlType) {
            case "text":
              this.handleReponseChange(
                composant.id,
                "",
                "",
                "",
                true,
                true,
                null
              );
              break;
            case "radio":
              if (options) {
                const optionsArray = options.split(";");
                optionsArray.map((option, key) =>
                  key === 0
                    ? this.handleReponseChange(
                        composant.id,
                        key,
                        option,
                        true,
                        true,
                        true,
                        null
                      )
                    : null
                );
              }
              break;
            case "checkbox":
              options
                ? options
                    .split(";")
                    .map((option, key) =>
                      this.handleReponseChange(
                        composant.id,
                        key,
                        option,
                        true,
                        true,
                        true,
                        null
                      )
                    )
                : this.handleReponseChange(
                    composant.id,
                    "",
                    this.renderLabel(composant),
                    true,
                    true,
                    true,
                    null
                  );
              break;
            default:
              break;
          }
        }
      }
    });
  }

  updateEventFormMessage() {
    const { componentsResponses, componentEntrance, event, code, lastInvoice } =
      this.state;

    const messageToShow = componentsResponses[0].reponses.some(
      (reponse) => reponse.id === componentEntrance.id && reponse.key === 0
    )
      ? this.replaceShortCode(
          event.data.messageWillJoinEvent.find(
            (message) => message.code === code
          ).value
        )
      : lastInvoice
      ? this.replaceShortCode(
          event.data.messageWillNotJoinEventAndGetRefund.find(
            (message) => message.code === code
          ).value
        )
      : this.replaceShortCode(
          event.data.messageWillNotJoinEvent.find(
            (message) => message.code === code
          ).value
        );

    this.setState({
      showEventFormMessage: true,
      messageToShow: messageToShow,
      showCheckoutPage: false,
    });
  }

  //TODO: STOP USING THIS FUNCTION IN ALL CODE BASE
  async sendResponses(isValidated) {
    console.log("A8");
    const {
      componentsResponses,
      componentEntrance,
      componentInvitation,
      guest,
      form,
      existingReponse,
      event,
      code,
      contact,
      eventId,
      packageInvitation,
      lastInvoice,
      guestInvoices,
      removedGuestsResponses,
      newGuestsResponses,
    } = this.state;

    const { componentsRules } = this.state.event.component_rules;
    this.setState({ isLoading: true });

    const eventDidRefresh = await ApiService.awaitRequest(
      this.state,
      "evenements/" + eventId,
      "get"
    );

    this.setState({ event: eventDidRefresh });
    const self = this;

    this.deleteFollowers(); //!
    const willNotJoin = componentsResponses[0].reponses.some(
      (reponse) => reponse.id === componentEntrance.id && reponse.key === 1
    );

    const willbeAlone = componentInvitation
      ? componentsResponses[0].reponses.some(
          (reponse) =>
            reponse.id === componentInvitation.id && reponse.key === 1
        )
      : true;
    if (!this.props.guests) {
      await ApiService.awaitRequest(
        {
          participate: !willNotJoin,
          is_validated: isValidated,
        },
        "guests/" + guest.id,
        "put"
      );
    } else {
      await ApiService.awaitRequest(
        {
          guests_ids: this.props.guests.map((guest) => guest.id),
          participate: !willNotJoin,
          is_validated: isValidated,
        },
        "guests/update/bygroup",
        "put"
      );
    }

    this.setState({
      showEventFormMessage: true,
      formModified: false,
      isValidated,
      messageToShow: componentsResponses[0].reponses.some(
        (reponse) => reponse.id === componentEntrance.id && reponse.key === 0
      )
        ? this.replaceShortCode(
            event.data.messageWillJoinEvent.find(
              (message) => message.code === code
            ).value
          )
        : lastInvoice
        ? this.replaceShortCode(
            event.data.messageWillNotJoinEventAndGetRefund.find(
              (message) => message.code === code
            ).value
          )
        : this.replaceShortCode(
            event.data.messageWillNotJoinEvent.find(
              (message) => message.code === code
            ).value
          ),
      showCheckoutPage: false,
    });
    if (willNotJoin) {
      console.log("A2");
      if (
        (this.props.isAdmin &&
          this.state.event &&
          !this.state.event.disable_notifications) ||
        !this.props.isAdmin
      )
        this.sendConfirmationEmail(false);

      this.clearGuestResponse(willNotJoin);

      const responses = [
        {
          id: componentEntrance.id,
          key: 1,
          label: this.getComponentResponseLabel(componentEntrance, 1), // this.renderLabel(componentEntrance),
        },
      ];

      /*
       * Ne pas supprimer les réponses liées à la réponse "Non" du composant d'entrée
       */

      // Récupérer les composants associés à celui d'entrée
      const filteredComponents = componentsRules.filter(
        (component) =>
          (component.component2 =
            componentEntrance.id && component.matchKey === "1")
      );

      // Récupérer les réponses des composants associés
      filteredComponents.forEach((component) => {
        const componentValues = componentsResponses[0].reponses.find(
          (componentResponse) =>
            componentResponse.id === parseInt(component.component1)
        );
        if (componentValues) {
          responses.push(componentValues);
        }
      });

      if (lastInvoice && lastInvoice.data.totalTTC_before_balance > 0) {
        let invoicesRefundIsOk = []; // nombre de remboursement qui on reussi
        // Une prommesse de l'initialisation de remboursement  et de la création de la facture
        new Promise(async (resolve, reject) => {
          // Véfication dans la liste des factures de l'invité si la facture stripe_transaction_id afin de rembourser
          for (const element of guestInvoices) {
            if (element.stripe_transaction_id) {
              await ApiService.awaitRequest(
                {
                  entity_id: event.children_entity_id,
                  token: null,
                  description: event.data.name,
                  amount: -Math.abs(element.data.totalTTC),
                  stripe_transaction_id: element.stripe_transaction_id,
                  order_id: null, //! create order
                },
                "contacts/charge",
                "post"
                // eslint-disable-next-line no-loop-func
              ).then((res) => {
                console.log(res, "chargerefund");
                // Véfication des differents cas possible si c'est déjà remboursé ou pas
                if (
                  !res ||
                  (res !== undefined &&
                    res.error_refund &&
                    res.error_refund.includes("has already been refunded."))
                ) {
                  toast.warn(
                    webPageTranslations[code ? code : "FR"]
                      .refund_already_message +
                      " Pour la facture N° " +
                      element.invoice_number,
                    {
                      autoClose: 4000,
                    }
                  );
                } else if (
                  !res ||
                  (res !== undefined &&
                    res.error_refund &&
                    res.error_refund.includes("is greater than charge amount"))
                ) {
                  toast.warn(
                    webPageTranslations[code ? code : "FR"]
                      .refund_message_error,
                    {
                      autoClose: 4000,
                    }
                  );
                } else if (res && res.refund_id) {
                  invoicesRefundIsOk.push({
                    ...element,
                    refund_id: res.refund_id,
                  });
                  toast.success(
                    webPageTranslations[code ? code : "FR"].refund_message,
                    {
                      autoClose: 5000,
                    }
                  );
                }
              });
            }
          }
          // S'il y a des factures qui ont été remboursées
          if (invoicesRefundIsOk.length > 0) {
            console.log("Enter here 8 😄", invoicesRefundIsOk);
            ApiService.awaitRequest(
              {
                children_entity_id: event.children_entity_id,
                evenement_id: event.id,
                data: {
                  ...lastInvoice.data,

                  products: [
                    {
                      quantity: invoicesRefundIsOk.length,
                      vat: 0,
                      priceHT: 0,
                      priceTTC: 0,
                      label: "Remboursement " + event.data.name,
                      totalht: 0,
                    },
                  ],
                  previousAmount: lastInvoice.data.totalTTC_before_balance,
                  total_vat_0_before_balance: 0,
                  total_vat_5_before_balance: 0,
                  total_vat_10_before_balance: 0,
                  total_vat_20_before_balance: 0,
                  totalHT_before_balance: 0,
                  totalTTC_before_balance: 0,
                  total_vat_0: -Math.abs(
                    lastInvoice.data.total_vat_0_before_balance
                  ),
                  total_vat_5: -Math.abs(
                    lastInvoice.data.total_vat_5_before_balance
                  ),
                  total_vat_10: -Math.abs(
                    lastInvoice.data.total_vat_10_before_balance
                  ),
                  total_vat_20: -Math.abs(
                    lastInvoice.data.total_vat_20_before_balance
                  ),
                  totalHT: -Math.abs(lastInvoice.data.totalHT_before_balance),
                  totalTTC: -Math.abs(lastInvoice.data.totalTTC_before_balance),
                },
                payment_mode: lastInvoice.payment_mode,
                contact_id: contact.id,
                refund_id: lastInvoice.data.refund_id,
                stripe_transaction_id: invoicesRefundIsOk[0].refund_id,
                status: "paid",
                entity_id: event.children_entity_id,
                evenement_name: event.data.name,
              },
              "invoices",
              "post"
            );
            self.setState({
              isLoading: false,
              messageToShow: self.replaceShortCode(
                event.data.messageWillNotJoinEventAndGetRefund.find(
                  (message) => message.code === code
                ).value
              ),
            });
          }
          //console.log(invoicesRefundIsOk, ' invoicesRefundIsOk')
        });
      } else if (lastInvoice) {
        console.log(
          "🚀 ~ file: WebPage.js:1808 ~ WebPage ~ sendResponses ~ lastInvoice:",
          lastInvoice
        );
        console.log("A3");

        await ApiService.awaitRequest(
          {
            children_entity_id: event.children_entity_id,
            evenement_id: event.id,
            data: {
              ...lastInvoice.data,

              products: [
                {
                  quantity: 1,
                  vat: 0,
                  priceHT: 0,
                  priceTTC: 0,
                  label: "Remboursement " + event.data.name,
                  totalht: 0,
                },
              ],
              total_vat_0_before_balance: 0,
              total_vat_5_before_balance: 0,
              total_vat_10_before_balance: 0,
              total_vat_20_before_balance: 0,
              totalHT_before_balance: 0,
              totalTTC_before_balance: 0,
              total_vat_0: -Math.abs(
                lastInvoice.data.total_vat_0_before_balance
              ),
              total_vat_5: -Math.abs(
                lastInvoice.data.total_vat_5_before_balance
              ),
              total_vat_10: -Math.abs(
                lastInvoice.data.total_vat_10_before_balance
              ),
              total_vat_20: -Math.abs(
                lastInvoice.data.total_vat_20_before_balance
              ),
              totalHT: -Math.abs(lastInvoice.data.totalHT_before_balance),
              totalTTC: -Math.abs(lastInvoice.data.totalTTC_before_balance),
            },
            payment_mode: lastInvoice.payment_mode,
            contact_id: contact.id,
            refund_id: lastInvoice.data.refund_id,
            stripe_transaction_id: "",
            status: "paid",
            entity_id: event.children_entity_id,
            evenement_name: event.data.name,
          },
          "invoices",
          "post"
        );

        self.setState({
          isLoading: false,
          messageToShow: self.replaceShortCode(
            event.data.messageWillNotJoinEventAndGetRefund.find(
              (message) => message.code === code
            ).value
          ),
        });
      }

      const requestType = existingReponse ? "put" : "post";
      const reponseId = componentsResponses[0].reponseId
        ? componentsResponses[0].reponseId
        : "";

      console.log("componentsResponses", componentsResponses[0]);
      if (!this.props.guests) {
        await ApiService.awaitRequest(
          {
            guests_ids: [guest.id],
            evenement_form_id: form.id,
            component_id: "",
            responses,
          },
          "component_responses/" + reponseId,
          requestType
        );
      } else {
        await ApiService.awaitRequest(
          {
            guests_ids: this.props.guests.map((guest) => guest.id),
            evenement_form_id: form.id,
            component_id: "",
            responses,
          },
          "component_responses/",
          "post"
        );

        toast.success("Réponses sauvegardées", {
          autoClose: 500,
        });
      }
    } else {
      console.log("A9");
      componentsResponses.forEach(async (userReponse, key) => {
        if (key === 0) {
          const allComponents = this.state.itemsMainGuest.concat(
            this.state.itemsSideGuest
          );

          if (!this.props.guests) {
            allComponents.forEach(async (composant) => {
              const updatedComposant = this.state.eventComponents.find(
                (eventComponent) =>
                  eventComponent.id === composant.id &&
                  eventComponent.data.reponse.guestFieldRequired
              );

              if (updatedComposant) {
                const field = userReponse.reponses.find(
                  (reponse) =>
                    Number(reponse.id) === Number(updatedComposant.id)
                );

                if (field) {
                  const data = updatedComposant.data.reponse.guestFieldRequired;

                  await ApiService.awaitRequest(
                    {
                      ...contact,
                      [data]: field.label,
                    },
                    "contacts/" + contact.id,
                    "put"
                  );
                }
              }
            });
          }

          const requestType = existingReponse ? "put" : "post";
          const reponseId = userReponse.reponseId ? userReponse.reponseId : "";
          console.log("userReponse ~", userReponse.reponses);

          /*
           * Supprimer les réponses liées à la réponse "Non" du composant d'entrée
           */

          // Récupérer les composants associés à celui d'entrée
          const filteredComponents = componentsRules.filter(
            (component) =>
              (component.component2 =
                componentEntrance.id && component.matchKey === "1")
          );

          // Récupérer les indexs des composants associés
          filteredComponents.forEach((component) => {
            const associateIndex = userReponse.reponses.findIndex(
              (componentResponse) =>
                componentResponse.id === parseInt(component.component1)
            );
            if (associateIndex !== -1) {
              userReponse.reponses.splice(associateIndex, 1);
            }
          });

          if (!this.props.guests) {
            await ApiService.awaitRequest(
              {
                guests_ids: [guest.id],
                evenement_form_id: form.id,
                component_id: "",
                responses: userReponse.reponses,
              },
              "component_responses/" + reponseId,
              requestType
            );
          } else {
            await ApiService.awaitRequest(
              {
                guests_ids: this.props.guests.map((guest) => guest.id),
                evenement_form_id: form.id,
                component_id: "",
                responses: userReponse.reponses,
              },
              "component_responses/",
              "post"
            );

            toast.success("Réponses sauvegardées", {
              autoClose: 2000,
            });
          }
        } else if (!this.props.guests) {
          if (!userReponse.guestId) {
            if (!willbeAlone) {
              const guestsids = await ApiService.awaitRequest(
                {
                  evenement_id: eventId,
                  package_id: packageInvitation.id,
                  checked_in: false,
                  firstname: userReponse.firstname ? userReponse.firstname : "",
                  lastname: userReponse.lastname ? userReponse.lastname : "",
                  email: userReponse.email ? userReponse.email : "",
                  phone: userReponse.phone ? userReponse.phone : null,
                  contact_id: userReponse.contact_id
                    ? userReponse.contact_id
                    : null,
                  max_invitation: 0,
                  type: userReponse.type ? userReponse.type : "",
                  invited_by: guest.id,
                  invitation_package_id: "",
                  user_id: guest.user_id,
                  is_followers: true,
                  participate: true,
                  is_validated: true,
                },
                "guests",
                "post"
              );
              console.log("Here is the problem 🔥🔥🔥🔥");
              if (guestsids) {
                const guestComponentResponses = await ApiService.awaitRequest(
                  //!
                  {
                    guests_ids: [guestsids.id],
                    evenement_form_id: form.id,
                    component_id: "",
                    responses: userReponse.reponses,
                  },
                  "component_responses/",
                  "post"
                );
                console.log(
                  "🚀 ~ file: WebPage.js:2229 ~ WebPage ~ componentsResponses.forEach ~ guestComponentResponses:",
                  guestComponentResponses
                );
                console.log(
                  "🌺 ~ file: WebPage.js:2202 ~ WebPage ~ componentsResponses.forEach ~ guestsids:",
                  guestsids
                );
                //TODO: if guest is created update invoices
                if (
                  removedGuestsResponses.length > 0 &&
                  newGuestsResponses.length > 0 &&
                  guestComponentResponses &&
                  guestComponentResponses.length > 0
                ) {
                  console.log("🧨🧨🧨 INTERCHANGING GUEST INFO 🧨🧨🧨");
                  for (let i = 0; i < removedGuestsResponses.length; i++) {
                    const guestResponse = removedGuestsResponses[i];
                    const newInvoice = { ...guestResponse.invoice };
                    newInvoice.data.componentsResponses =
                      newInvoice.data.componentsResponses.map((rep) => {
                        if (rep.guestId === guestResponse.userReponse.guestId)
                          return {
                            ...newGuestsResponses[i].userReponse,
                            guestId: guestsids.id,
                            contact_id: guestsids.contact_id,
                            isFollower: guestsids.is_followers,
                            reponseId: guestComponentResponses[0].id,
                            reponses: guestComponentResponses[0].responses,
                            // reponseId: guestResponse.userReponse.reponseId,
                            // reponses: guestResponse.userReponse.reponses,
                          };
                        return rep;
                      });
                    await ApiService.awaitRequest(
                      //!
                      {
                        ...newInvoice,
                      },
                      "invoices/" + guestResponse.invoice.id,
                      "put"
                    );
                  }
                }
              } else {
                alert("Erreur lors de l'enregistrement");
              }
            }
          } else {
            if (willbeAlone) {
              await ApiService.awaitRequest(
                {},
                "guests/" + userReponse.guestId,
                "delete"
              );
            } else
              await ApiService.awaitRequest(
                {
                  guests_ids: [userReponse.guestId],
                  evenement_form_id: form.id,
                  component_id: "",
                  responses: userReponse.reponses,
                },
                "component_responses/" + userReponse.reponseId,
                "put"
              );
          }
        }
      });
      if (guest.email && guest.email.length !== 0 && !this.props.guests)
        if (
          (this.props.isAdmin &&
            this.state.event &&
            !this.state.event.disable_notifications) ||
          !this.props.isAdmin
        )
          this.sendConfirmationEmail(true); //!
    }

    this.setState({ isLoading: false }, () => {
      if (self.props.loadData) {
        self.props.loadData();
      }
    });
  }

  async sendResponses2(isValidated) {
    console.log("A6");
    const {
      componentsResponses,
      componentEntrance,
      componentInvitation,
      guest,
      form,
      contact,
      eventId,
      packageInvitation,
    } = this.state;

    const allData = {};

    // this.setState({ isLoading: true });

    const eventDidRefresh = await ApiService.awaitRequest(
      this.state,
      "evenements/" + eventId,
      "get"
    );

    allData.event = eventDidRefresh; //? allData
    allData.evenement_form_id = form.id;

    this.setState({ event: eventDidRefresh });
    const self = this;

    // this.deleteFollowers();
    allData.deleteFollowers = self.state.followersToDelete; //? allData

    const willNotJoin = componentsResponses[0].reponses.some(
      (reponse) => reponse.id === componentEntrance.id && reponse.key === 1
    );

    allData.willNotJoin = willNotJoin; //? allData

    const willbeAlone = componentInvitation
      ? componentsResponses[0].reponses.some(
          (reponse) =>
            reponse.id === componentInvitation.id && reponse.key === 1
        )
      : true;
    allData.willbeAlone = willbeAlone; //? allData

    if (!this.props.guests) {
      //   await this.api.awaitRequest(
      //     {
      //       participate: !willNotJoin,
      //       is_validated: isValidated,
      //     },
      //     "guests/" + guest.id,
      //     "put"
      //   );
      allData.editGuest = {
        //? allData
        participate: !willNotJoin,
        is_validated: isValidated,
        guest_id: guest.id,
      };
    } else {
      //   await this.api.awaitRequest(
      //     {
      //       guests_ids: this.props.guests.map((guest) => guest.id),
      //       participate: !willNotJoin,
      //       is_validated: isValidated,
      //     },
      //     "guests/update/bygroup",
      //     "put"
      //   );
      allData.editGuestByGroup = {
        //? allData
        guests_ids: this.props.guests.map((guest) => guest.id),
        participate: !willNotJoin,
        is_validated: isValidated,
      };
    }

    // this.setState({
    //   showEventFormMessage: true,
    //   formModified: false,
    //   isValidated,
    //   messageToShow: componentsResponses[0].reponses.some(
    //     (reponse) => reponse.id === componentEntrance.id && reponse.key === 0
    //   )
    //     ? this.replaceShortCode(
    //         event.data.messageWillJoinEvent.find(
    //           (message) => message.code === code
    //         ).value
    //       )
    //     : lastInvoice
    //     ? this.replaceShortCode(
    //         event.data.messageWillNotJoinEventAndGetRefund.find(
    //           (message) => message.code === code
    //         ).value
    //       )
    //     : this.replaceShortCode(
    //         event.data.messageWillNotJoinEvent.find(
    //           (message) => message.code === code
    //         ).value
    //       ),
    //   showCheckoutPage: false,
    // });
    if (willNotJoin) {
      console.log("A2");
      if (
        (this.props.isAdmin &&
          this.state.event &&
          !this.state.event.disable_notifications) ||
        !this.props.isAdmin
      ) {
        // this.sendConfirmationEmail(false); //! Don't forget //? allData
        allData.confirmationEmail = {
          willJoin: false,
          existingReponse: this.state.existingReponse,
          eventId: this.state.eventId,
          code: this.state.code,
          isValidated: this.state.isValidated,
          guest: this.state.guest,
        };
      }

      this.clearGuestResponse(willNotJoin);

      const responses = [
        {
          id: componentEntrance.id,
          key: 1,
          label: this.getComponentResponseLabel(componentEntrance, 1), // this.renderLabel(componentEntrance),
        },
      ];

      // if (lastInvoice && lastInvoice.data.totalTTC_before_balance > 0) {
      //   //! DOES IT MAKE SENS TO HAVE THIS HERE?
      //   self.setState({
      //     isLoading: false,
      //     messageToShow: self.replaceShortCode(
      //       event.data.messageWillNotJoinEventAndGetRefund.find(
      //         (message) => message.code === code
      //       ).value
      //     ),
      //   });
      // } else if (lastInvoice) {
      //   self.setState({
      //     isLoading: false,
      //     messageToShow: self.replaceShortCode(
      //       event.data.messageWillNotJoinEventAndGetRefund.find(
      //         (message) => message.code === code
      //       ).value
      //     ),
      //   });
      // }

      const reponseId = componentsResponses[0].reponseId
        ? componentsResponses[0].reponseId
        : "";

      if (!this.props.guests) {
        allData.component_responses_put1 = {
          guests_ids: [guest.id],
          evenement_form_id: form.id,
          component_id: "",
          responses,
          reponse_id: reponseId,
        };
      } else {
        allData.component_responses_post1 = {
          guests_ids: this.props.guests.map((guest) => guest.id),
          evenement_form_id: form.id,
          component_id: "",
          responses,
        };
      }
    } else {
      console.log("A29");
      allData.guestsPost = [];
      allData.guestsDelete = [];
      allData.component_responses_put3 = [];
      componentsResponses.forEach(async (userReponse, key) => {
        if (key === 0) {
          const allComponents = this.state.itemsMainGuest.concat(
            this.state.itemsSideGuest
          );

          if (!this.props.guests) {
            allComponents.forEach(async (composant) => {
              const updatedComposant = this.state.eventComponents.find(
                (eventComponent) =>
                  eventComponent.id === composant.id &&
                  eventComponent.data.reponse.guestFieldRequired
              );

              if (updatedComposant) {
                const field = userReponse.reponses.find(
                  (reponse) =>
                    Number(reponse.id) === Number(updatedComposant.id)
                );

                if (field) {
                  const data = updatedComposant.data.reponse.guestFieldRequired;

                  allData.contactsPut = {
                    ...contact,
                    [data]: field.label,
                    contact_id: contact.id,
                  };
                }
              }
            });
          }

          const reponseId = userReponse.reponseId ? userReponse.reponseId : "";

          if (!this.props.guests) {
            if (reponseId) {
              allData.component_responses_put2 = {
                guests_ids: [guest.id],
                evenement_form_id: form.id,
                component_id: "",
                responses: userReponse.reponses,
                reponse_id: reponseId,
              };
            } else {
              allData.component_responses_post2 = {
                guests_ids: [guest.id],
                evenement_form_id: form.id,
                component_id: "",
                responses: userReponse.reponses,
              };
            }
          } else {
            allData.component_responses_post2 = {
              //? allData
              guests_ids: this.props.guests.map((guest) => guest.id),
              evenement_form_id: form.id,
              component_id: "",
              responses: userReponse.reponses,
            };
          }
        } else if (!this.props.guests) {
          if (!userReponse.guestId) {
            if (!willbeAlone) {
              //   const guestsids = await this.api.awaitRequest(
              //     {
              //       evenement_id: eventId,
              //       package_id: packageInvitation.id,
              //       checked_in: false,
              //       firstname: userReponse.firstname ? userReponse.firstname : "",
              //       lastname: userReponse.lastname ? userReponse.lastname : "",
              //       email: userReponse.email ? userReponse.email : "",
              //       phone: userReponse.phone ? userReponse.phone : null,
              //       contact_id: userReponse.contact_id
              //         ? userReponse.contact_id
              //         : null,
              //       max_invitation: 0,
              //       type: userReponse.type ? userReponse.type : "",
              //       invited_by: guest.id,
              //       invitation_package_id: "",
              //       user_id: guest.user_id,
              //       is_followers: true,
              //       participate: true,
              //       is_validated: true,
              //     },
              //     "guests",
              //     "post"
              //   );
              allData.guestsPost.push({
                //? allData
                evenement_id: eventId,
                package_id: packageInvitation.id,
                checked_in: false,
                firstname: userReponse.firstname ? userReponse.firstname : "",
                lastname: userReponse.lastname ? userReponse.lastname : "",
                email: userReponse.email ? userReponse.email : "",
                phone: userReponse.phone ? userReponse.phone : null,
                contact_id: userReponse.contact_id
                  ? userReponse.contact_id
                  : null,
                max_invitation: 0,
                type: userReponse.type ? userReponse.type : "",
                invited_by: guest.id,
                invitation_package_id: "",
                user_id: guest.user_id,
                is_followers: true,
                participate: true,
                is_validated: true,
                responses: userReponse.reponses,
                form_id: form.id,
              });
              //   if (guestsids) { //! Don't forget //? allData
              //     await self.api.awaitRequest(
              //       {
              //         guests_ids: [guestsids.id],
              //         evenement_form_id: form.id,
              //         component_id: "",
              //         responses: userReponse.reponses,
              //       },
              //       "component_responses/",
              //       "post"
              //     );
              //   } else {
              //     alert("Erreur lors de l'enregistrement");
              //   }
            }
          } else {
            if (willbeAlone) {
              allData.guestsDelete.push({
                guest_id: userReponse.guestId,
              });
            } else
              allData.component_responses_put3.push({
                guests_ids: [userReponse.guestId],
                evenement_form_id: form.id,
                component_id: "",
                responses: userReponse.reponses,
                reponse_id: userReponse.reponseId,
              });
          }
        }
      });
      if (guest.email && guest.email.length !== 0 && !this.props.guests)
        if (
          (this.props.isAdmin &&
            this.state.event &&
            !this.state.event.disable_notifications) ||
          !this.props.isAdmin
        ) {
          allData.confirmationEmail = {
            willJoin: true,
            existingReponse: this.state.existingReponse,
            eventId: this.state.eventId,
            code: this.state.code,
            isValidated: this.state.isValidated,
            guest: this.state.guest,
          };
        }
    }

    console.log(
      "🚀 ~ file: WebPage.js:2712 ~ WebPage ~ sendResponses2 ~ allData:",
      allData
    );
    return allData;
  }

  async chargeMoney(event, lastInvoice, guestInvoices, code) {
    // console.log(
    //   "🚀 ~ file: WebPage.js:2732 ~ WebPage ~ chargeMoney ~ lastInvoice:",
    //   lastInvoice
    // );
    // console.log(
    //   "🚀 ~ file: WebPage.js:2734 ~ WebPage ~ chargeMoney ~ lastInvoice.data.totalTTC_before_balance:",
    //   lastInvoice.data.totalTTC_before_balance
    // );
    if (lastInvoice && lastInvoice.data.totalTTC_before_balance > 0) {
      console.log("🍅ENTER HERE 2222");
      let invoicesRefundIsOk = []; // nombre de remboursement qui on reussi
      // Une prommesse de l'initialisation de remboursement  et de la création de la facture
      new Promise(async (resolve, reject) => {
        // Véfication dans la liste des factures de l'invité si la facture stripe_transaction_id afin de rembourser
        for (const element of guestInvoices) {
          if (element.stripe_transaction_id) {
            console.log("🍅ENTER HERE 3333");
            await ApiService.awaitRequest(
              {
                entity_id: event.children_entity_id,
                token: null,
                description: event.data.name,
                amount: -Math.abs(element.data.totalTTC),
                stripe_transaction_id: element.stripe_transaction_id,
              },
              "contacts/charge",
              "post"
              // eslint-disable-next-line no-loop-func
            ).then((res) => {
              console.log(res, "chargerefund");
              // Véfication des differents cas possible si c'est déjà remboursé ou pas
              if (
                !res ||
                (res !== undefined &&
                  res.error_refund &&
                  res.error_refund.includes("has already been refunded."))
              ) {
                toast.warn(
                  webPageTranslations[code ? code : "FR"]
                    .refund_already_message +
                    " Pour la facture N° " +
                    element.invoice_number,
                  {
                    autoClose: 4000,
                  }
                );
              } else if (
                !res ||
                (res !== undefined &&
                  res.error_refund &&
                  res.error_refund.includes("is greater than charge amount"))
              ) {
                toast.warn(
                  webPageTranslations[code ? code : "FR"].refund_message_error,
                  {
                    autoClose: 4000,
                  }
                );
              } else if (res && res.refund_id) {
                invoicesRefundIsOk.push({
                  ...element,
                  refund_id: res.refund_id,
                });
                toast.success(
                  webPageTranslations[code ? code : "FR"].refund_message,
                  {
                    autoClose: 5000,
                  }
                );
              }
            });
          }
        }
      });
    }
  }

  initiatePreFilledAndRequestElements(items, isFollower) {
    const { code, eventComponents, multiForms, componentsResponses, contact } =
      this.state;

    const guestKey =
      isFollower && multiForms ? componentsResponses.length - 1 : 0;
    const preFilledReponses = [];

    if (items) {
      items.forEach((item) => {
        const composant = eventComponents.find(
          (component) => component.id === item.id
        );

        const options = composant.data.reponse.options.find(
          (option) => option.code === code
        ).value;

        if (
          ((composant.data.reponse.htmlType === "radio" && options) ||
            composant.data.reponse.displayType === "agenda") &&
          composant.data.reponse.isPreFilled
        ) {
          const label = options
            ? options.split(";")[0]
            : composant.data.reponse.reponseDescription
                .find((message) => message.code === code)
                .value.replace(/<(?:.|\n)*?>/gm, "");

          preFilledReponses.push({ id: composant.id, key: 0, label });
        }
        if (
          composant.data.componentType === "request" &&
          composant.data.reponse.htmlType === "text" &&
          !isFollower
        ) {
          preFilledReponses.push({
            id: composant.id,
            key: "",
            label: contact[composant.data.reponse.guestFieldRequired],
          });
        }
      });
    }

    let updatedcomponentsResponses = [];

    updatedcomponentsResponses = componentsResponses.map(
      (userComponentsResponse, key) => {
        if (key !== guestKey) {
          return userComponentsResponse;
        } else {
          const reponseAlreadyExist = userComponentsResponse.reponses.find(
            (rep) => {
              return preFilledReponses.find((pre) => pre.id === rep.id);
            }
          );
          if (reponseAlreadyExist) return userComponentsResponse;
          return {
            ...userComponentsResponse,
            reponses: userComponentsResponse.reponses.concat(preFilledReponses),
          };
        }
      }
    );

    if (!multiForms) {
      const noMultiform = updatedcomponentsResponses.map(
        (userComponentsResponse, key) => {
          if (key === 0) {
            return userComponentsResponse;
          } else {
            return {
              ...userComponentsResponse,
              reponses: updatedcomponentsResponses[0].reponses,
            };
          }
        }
      );

      updatedcomponentsResponses = noMultiform;
    }
    const self = this;

    this.setState(
      {
        modal: false,
        componentsResponses: updatedcomponentsResponses,
        formModified: true,
        activeGuestKey: multiForms ? componentsResponses.length - 1 : 0,
      },

      () => {
        self.checkFormComplete();
      }
    );
  }

  async componentDidMount() {
    try {
      window.addEventListener("resize", this.handleWindowSizeChange);
      const self = this;
      let hasAccess = "";

      const event = await ApiService.awaitRequest(
        {},
        "evenements/" + this.state.eventId,
        "get"
      );
      await ApiService.awaitRequest({}, "errorWorkspaces/", "get").then(
        function (data) {
          self.setState({
            errorsWorkspaces: data,
          });
        }
      );
      const guest = await ApiService.awaitRequest(
        {},
        "guests/" + this.props.guestId,
        "get"
      ).then(function (data) {
        return data;
      });

      if (guest) {
        hasAccess = await checkNotAccess(
          this.state.errorsWorkspaces,
          guest.contact ? guest.contact : null,
          this.state.eventId,
          event,
          guest
        );
        if (!hasAccess.access) {
          return this.setState({ hasAccess, isLoading: false, event });
        }
      }
      // this.setState({ hasAccess: access, isLoading: false });
      //useBeforeunload(() => "You'll lose your data!");
      const webpageData = await ApiService.awaitRequest(
        {},
        "guests/webpage/" +
          self.props.documentType +
          "/" +
          this.state.eventId +
          "/" +
          this.props.guestId,
        "get"
      );
      let messageToShow = "";

      if (!webpageData) {
        this.setState({
          messageToShow: webPageTranslations["FR"].error_occuring,
          showEventFormMessage: true,
        });
      } else {
        const componentEntrance = webpageData.components.find(
          (component) => component.data.reponse.role === "entrance"
        );

        // check if delegataire exist
        const delegataire =
          guest && webpageData.guest && webpageData.guest.user
            ? webpageData.delegataires.find(
                (delegataire) =>
                  delegataire.user_id === webpageData.guest.user.id &&
                  delegataire.package_ids &&
                  delegataire.package_ids.includes(guest.package_id)
              )
            : null;

        // get delegataire guests
        if (delegataire) {
          const delegataireGuests = await ApiService.awaitRequest(
            {},
            "guests/bydelegataire/" +
              delegataire.user_id +
              "/byevenement/" +
              this.state.eventId,
            "get"
          );

          if (delegataireGuests) {
            const packageGuests = delegataireGuests.guests.filter(
              (guest) =>
                delegataire.package_ids &&
                delegataire.package_ids.includes(guest.package_id) &&
                guest.participate &&
                guest.id !== webpageData.guest.id
            );

            if (
              componentEntrance.data &&
              componentEntrance.data.quotas &&
              componentEntrance.data.quotas.delegataires
            ) {
              const entryQuota =
                componentEntrance.data.quotas.delegataires.find(
                  (delega) => delega.userId === delegataire.user_id
                );
              if (entryQuota) {
                if (packageGuests.length >= entryQuota.quota) {
                  this.setState({ isDelegatairePackageFull: true });
                }
              }
            }
          }
        }
        //
        if (webpageData.evenement.data.maintenanceMode) {
          messageToShow = webPageTranslations["FR"].maintenance_mode;
        }

        const componentInvitation = webpageData.components.find(
          (component) => component.data.reponse.role === "invitation"
        );

        window.WebFont.load({
          google: {
            families: [
              webpageData.form
                ? webpageData.form.layout
                  ? webpageData.form.layout.fontFamily
                    ? webpageData.form.layout.fontFamily
                    : ""
                  : ""
                : "",
            ],
          },
        });
        this.setState({
          form: webpageData.form,
          componentEntrance,
          componentInvitation,
          delegataires: webpageData.delegataires.filter(
            (delegataire) =>
              webpageData.guest.user &&
              delegataire.user_id === webpageData.guest.user.id
          ),
          eventComponents: webpageData.components,
          event: webpageData.evenement,
          guest: webpageData.guest,
          contact: webpageData.contact,
          code: webpageData.contact
            ? webpageData.contact.languages
              ? webpageData.contact.languages
              : "FR"
            : "FR",
          tags: webpageData.tags,
          packageGuest: webpageData.packageguest,
          isModifiable:
            self.props.isAdmin ||
            JSON.parse(webpageData.evenement.data.allowModification),
          eventResponses: webpageData.component_responses,
        });

        await ApiService.request(
          {},
          "contacts/" +
            this.state.contact.id +
            "/invited/" +
            this.state.eventId +
            "/" +
            this.props.guestId,
          "get"
        ).then(function (data) {
          self.setState({
            invitedByContactName: data.guest ? data.guest : "",
          });
        });

        await ApiService.request(
          {},
          "contacts/line/" + this.state.eventId + "/1",
          "get"
        ).then(function (data) {
          self.setState({
            line: self.findRelatedContacts(self.state.guest.contact_id, data),
          });
        });

        if (this.state.event.data.free === "false") {
          await ApiService.request(
            {},
            "invoices/settings/" + this.state.event.children_entity_id,
            "get"
          ).then(function (data) {
            self.setState({
              stripeKey: data.stripePublicKey,
              stripeSecretKey: data.stripeSecretPublicKey,
              cgv: data.cgv,
              allowCheckForAdmin: data.allowCheckForAdmin,
              allowCheckForUser: data.allowCheckForUser,
            });
          });

          await ApiService.request(
            {},
            "evenements/" + this.state.eventId + "/guests",
            "get"
          ).then(function (data) {
            self.setState({
              guests: data[0].guests,
            });
          });
        }

        await ApiService.request(
          {},
          "contacts/" +
            this.state.contact.id +
            "/invoice/" +
            this.props.eventId,
          "get"
        ).then(function (data) {
          //console.log(data, "lastInvoice");
          self.setState({
            lastInvoice: data.status && data.status === "paid" ? data : null,
          });
        });

        await ApiService.request(
          {},
          "contacts/invoices/" +
            this.state.contact.id.toString() +
            "/" +
            this.props.eventId,
          "get"
        ).then(function (invoices) {
          const isInvoiceProcessing = invoices.some(
            (inv) => inv.invoice_number === null
          );
          self.setState({
            guestInvoices: invoices,
            isInvoiceProcessing,
          });
        });

        const guestReponse = webpageData.component_responses
          ? webpageData.component_responses.find(
              (response) => response.guest_id === this.state.guest.id
            )
          : null;

        if (guestReponse) {
          const componentsResponses = [];

          componentsResponses.push({
            guestId: self.state.guest.id,
            reponseId: guestReponse.id,
            lastname: self.state.guest.lastname,
            firstname: self.state.guest.firstname,
            formComplete: true,
            reponses: guestReponse.responses,
            type: null,
          });

          this.setState({
            showGuestContainer: this.state.componentInvitation
              ? componentsResponses[0].reponses.some(
                  (reponse) =>
                    reponse.id === this.state.componentInvitation.id &&
                    reponse.key === 0
                )
              : false,
            componentsResponses,
            existingReponse: true,
            showEventFormMessage: !JSON.parse(this.state.isModifiable),
          });

          // Guest's followers
          if (this.state.guest.invitations) {
            this.state.guest.invitations.forEach(async (guestInvited) => {
              const guestInvitedResponse = webpageData.component_responses
                ? webpageData.component_responses.find(
                    (response) => response.guest_id === guestInvited.id
                  )
                : null;

              if (guestInvitedResponse) {
                componentsResponses.push({
                  reponseId: guestInvitedResponse.id,
                  guestId: guestInvited.id,
                  lastname: guestInvited.lastname,
                  firstname: guestInvited.firstname,
                  type: guestInvited.type,
                  formComplete: true,
                  reponses: guestInvitedResponse.responses,
                });

                self.setState({
                  componentsResponses,
                });
              }
            });
          }
        }

        this.setState({
          initialComponentsResponses: JSON.parse(
            JSON.stringify(this.state.componentsResponses)
          ),
        });

        if (!this.state.guest.invitation_package_id) {
          this.setState({
            multiForms: false,
            packageInvitation: this.state.packageGuest,
          });
        } else {
          if (
            this.state.guest.package_id ===
            this.state.guest.invitation_package_id
          ) {
            this.setState({
              multiForms: true,
              packageInvitation: this.state.packageGuest,
            });
          } else {
            await ApiService.request(
              {},
              "packages/" + this.state.guest.invitation_package_id,
              "get"
            ).then(function (data) {
              self.setState({
                multiForms: true,
                packageInvitation: data,
              });
            });
          }
        }
        //msg erreur
        const itemsMainGuest = this.state.form.layout
          ? this.state.form.layout.itemsMain.filter((item) =>
              this.state.packageGuest.components.find(
                (component) => component.id === item.id
              )
            )
          : "";

        const itemsSideGuest = this.state.form.layout
          ? this.state.form.layout.itemsSide.filter((item) =>
              this.state.packageGuest.components.find(
                (component) => component.id === item.id
              )
            )
          : "";

        const itemsMainInvitation = this.state.form.layout
          ? this.state.form.layout.itemsMain.filter(
              (item) =>
                this.state.packageInvitation &&
                this.state.packageInvitation.components.find(
                  (component) => component.id === item.id
                )
            )
          : "";

        const itemsSideInvitation = this.state.form.layout
          ? this.state.form.layout.itemsSide.filter((item) =>
              this.state.packageInvitation.components.find(
                (component) => component.id === item.id
              )
            )
          : "";

        const today = new Date();
        var expiredDay = "";
        var limitDay = "";

        if (
          delegataire &&
          delegataire.selectedDelegataireDateLimit &&
          delegataire.selectedDelegataireDateLimit !== "" &&
          JSON.parse(delegataire.selectedDelegataireDateLimit).date_limit
        ) {
          expiredDay = new Date(
            JSON.parse(delegataire.selectedDelegataireDateLimit).date_limit
          );
        } else {
          expiredDay = new Date(this.state.event.data.registerEndDate);
          limitDay = new Date(this.state.event.data.modificationLimitDate);
        }
        const expirationDay = expiredDay;
        let expired = expirationDay.getTime() < today.getTime();

        const limitedModification = limitDay
          ? limitDay.getTime() < today.getTime()
          : "";

        const { isAdmin } = this.props;

        if (!this.state.existingReponse) {
          this.initiateNewUserReponse(
            this.state.guest.lastname,
            this.state.guest.firstname,
            this.state.guest.email,
            this.state.guest.phone,
            this.state.guest.contact_id,
            this.state.guest.type,
            itemsMainGuest,
            itemsSideGuest,
            false
          );
        } else {
          // Change access if the user wants to change their response and the modification limit date has not been reached
          if (!limitedModification && expired) {
            expired = false;
          }

          // Fill components that have the "Prefilled" attribute
          this.initiatePreFilledAndRequestElements(itemsMainGuest, false);
        }

        const guestParticipate =
          this.state.componentsResponses[0].reponses.some(
            (reponse) =>
              reponse.id === this.state.componentEntrance.id &&
              reponse.key === 0
          );

        const availableSpaces = this.checkComponentAvailableSpace(
          this.state.componentEntrance,
          this.state.code,
          true,
          this.state.activeGuestKey,
          this.state.eventResponses
        );

        if (!availableSpaces.available && !delegataire && !guestParticipate) {
          this.setState({ isDelegatairePackageFull: true });
        }

        if (!this.state.contact.active) {
          messageToShow =
            webPageTranslations[this.state.contact.languages]
              .your_account_is_deactivated;
        } else if (
          !isAdmin && this.state.form.layout
            ? this.state.form.layout.documentType !== "website"
            : null
        ) {
          if (expired) {
            messageToShow = this.replaceShortCode(
              this.state.event.data.registrationExpiredText.find(
                (message) => message.code === this.state.code
              ).value
            );
          }

          if (
            this.state.existingReponse &&
            !JSON.parse(this.state.isModifiable)
          ) {
            messageToShow = this.replaceShortCode(
              this.state.event.data.existingResponseMessage.find(
                (message) => message.code === this.state.code
              ).value
            );
          }

          if (this.state.invitedByContactName.length > 0) {
            messageToShow =
              webPageTranslations[this.state.code].hello +
              " " +
              this.state.guest.firstname +
              " " +
              this.state.guest.lastname +
              ", " +
              this.state.invitedByContactName +
              " " +
              webPageTranslations[this.state.code].already_registred;
          }

          if (
            (!availableSpaces.available && !this.state.existingReponse) ||
            (!availableSpaces.available &&
              this.state.existingReponse &&
              this.state.componentsResponses[0].reponses.some(
                (reponse) =>
                  reponse.id === this.state.componentEntrance.id &&
                  reponse.key === 1
              ))
          ) {
            messageToShow = this.replaceShortCode(
              this.state.event.data.eventFullText.find(
                (message) => message.code === this.state.code
              ).value
            );
          }
        }

        const available =
          (!availableSpaces.available /** pas de place */ &&
            this.state.existingReponse /** a pas de repondu */ &&
            guestParticipate) ||
          availableSpaces.available;

        if (this.props.match.params.shortUrl && !expired) {
          if (available) {
            this.setState({ isShortPage: true });
            if (this.props.match.params.shortUrl === "shortno") {
              this.clearGuestResponse(true);
              this.sendResponses(true);
              window.history.replaceState(
                window.history.state,
                "",
                window.location.href.replace("/shortno", "")
              );
            }
            if (this.props.match.params.shortUrl === "shortyes") {
              const guest = webpageData.guest;
              const eventComponents = webpageData.components;
              const packageInvitation = webpageData.packageguest;
              this.clearGuestResponse(false);
              this.checkComponentPackageInvitation(
                guest,
                packageInvitation,
                eventComponents
              );
              this.sendResponses(true);
              window.history.replaceState(
                window.history.state,
                "",
                window.location.href.replace("/shortyes", "")
              );
            }
          } else {
            this.setState({ isShortPage: false });
            messageToShow = this.replaceShortCode(
              this.state.event.data.eventFullText.find(
                (message) => message.code === this.state.code
              ).value
            );
            this.setState({
              messageToShow,
            });
          }
        }

        this.setState(
          {
            showEventFormMessage: messageToShow.length !== 0,
            messageToShow,
            itemsMainGuest,
            itemsSideGuest,
            itemsMainInvitation,
            itemsSideInvitation,
            availableSpaces: availableSpaces.available,
          },
          () => self.checkFormComplete()
        );

        if (
          this.state.form.layout
            ? this.state.form.layout.menu.items.length > 0
            : null && this.state.form.layout
            ? this.state.form.layout.documentType === "website"
            : null
        ) {
          document.body.classList.add("navigation-on");
        }
      }
      this.setState({
        mainContainerHeight: document.getElementById("row-side-container")
          ? document.getElementById("row-side-container").clientHeight
          : null,
      });
      hasAccess = await checkNotAccess(
        this.state.errorsWorkspaces,
        this.state.contact,
        this.state.eventId,
        this.state.event,
        ""
      );
      await this.checkAllComponentsAvailableSpace();
      this.setState({ hasAccess, isLoading: false });
    } catch (error) {
      console.log(error);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  findRelatedContacts(id, contacts) {
    const contactsFiltered = [];

    contacts.forEach((contact) => {
      if (contact.related_to === id) {
        contactsFiltered.push(contact);
        contactsFiltered.push(
          ...this.findRelatedContacts(contact.id, contacts)
        );
      }
    });

    return contactsFiltered;
  }

  getComponentResponseLabel(component, key) {
    if (component) {
      const optionsByLanguange = component.data.reponse.options.find(
        (option) => option.code === this.state.code
      );
      if (optionsByLanguange && optionsByLanguange.value) {
        const options = optionsByLanguange.value.split(";");
        const label =
          options && options.length > 0 && options.length > key
            ? options[key]
            : "";
        return label;
      }
    }
    return "";
  }

  async clearGuestResponse(willNotJoin) {
    this.state.componentsResponses.forEach(async (reponse) => {
      if (
        reponse.guestId &&
        Number(reponse.guestId) !== Number(this.props.guestId)
      ) {
        await ApiService.request({}, "guests/" + reponse.guestId, "delete");
      }
    });

    const key = willNotJoin ? 1 : 0;
    const label = this.getComponentResponseLabel(
      this.state.componentEntrance,
      key
    );
    const reponses = [
      {
        id: this.state.componentEntrance.id,
        key,
        label,
        checked: true,
        unique: true,
      },
    ];

    const reponseId = this.state.componentsResponses[0].reponseId;

    this.setState({
      activeGuestKey: 0,
      componentsResponses: [
        {
          guestId: this.state.guest.id,
          reponseId,
          lastname: this.state.guest.lastname,
          firstname: this.state.guest.firstname,
          reponses,
          formComplete: true,
        },
      ],
    });
    console.log("UPDATE RESPONSES HERE 4:", [
      {
        guestId: this.state.guest.id,
        reponseId,
        lastname: this.state.guest.lastname,
        firstname: this.state.guest.firstname,
        reponses,
        formComplete: true,
      },
    ]);
  }

  createFollower(lastname, firstname, email, phone, contact_id, type) {
    const { itemsMainInvitation, itemsSideInvitation } = this.state;
    if (
      this.state.guest.max_invitation >= this.state.componentsResponses.length
    ) {
      this.initiateNewUserReponse(
        lastname,
        firstname,
        email,
        phone,
        contact_id,
        type,
        itemsMainInvitation,
        itemsSideInvitation,
        true
      );
    }
  }

  async deleteFollowers() {
    this.state.followersToDelete.forEach(
      async (followerId) =>
        await ApiService.awaitRequest({}, "guests/" + followerId, "delete")
    );
  }

  async removeFollower(userReponse, guestKey) {
    const self = this;

    if (userReponse.guestId) {
      const componentsResponses = this.state.componentsResponses.filter(
        (item) => item.guestId !== userReponse.guestId
      );
      const eventResponses = this.state.eventResponses.filter(
        (userR) => userR.guest_id !== userReponse.guestId
      );
      const followersToDelete = this.state.followersToDelete;
      followersToDelete.push(userReponse.guestId);
      this.setState(
        {
          formModified: true,
          componentsResponses,
          eventResponses,
          followersToDelete,
          activeGuestKey: 0,
        },
        () => {
          self.checkFormComplete();
        }
      );
    } else {
      const componentsResponses = this.state.componentsResponses.filter(
        (userReponse, key) => key !== guestKey
      );
      this.setState(
        {
          formModified: true,
          componentsResponses,
          activeGuestKey: 0,
        },
        () => {
          self.checkFormComplete();
        }
      );
    }
  }

  initiateNewUserReponse(
    lastname,
    firstname,
    email,
    phone,
    contact_id,
    type,
    itemsMain,
    itemsSide,
    isFollower
  ) {
    // console.log(
    //   "nouvel invité ou accompagnant : " + firstname + " " + lastname
    // );
    const {
      componentsResponses,
      componentEntrance,
      componentInvitation,
      multiForms,
    } = this.state;

    let reponses = [];

    if (isFollower) {
      componentsResponses[0].reponses.forEach((reponse) => {
        if (reponse.id === componentEntrance.id) {
          const label = this.getComponentResponseLabel(componentEntrance, 0);
          reponses.push({
            id: componentEntrance.id,
            key: 0,
            label,
            checked: true,
            unique: true,
          });
        } else if (reponse.id === componentInvitation.id) {
          const label = this.getComponentResponseLabel(componentInvitation, 1);
          reponses.push({
            id: componentInvitation.id,
            key: 1,
            label,
            checked: true,
            unique: true,
          });
        } else {
          if (!multiForms) reponses.push(reponse);
        }
      });
    }

    componentsResponses.push({
      reponseId: "",
      lastname,
      firstname,
      email,
      phone,
      contact_id,
      type,
      reponses,
      formComplete: !multiForms,
      isFollower,
    });

    const self = this;
    this.setState(
      {
        componentsResponses,
      },
      () => {
        self.initiatePreFilledAndRequestElements(itemsMain, isFollower);
      }
    );
  }

  checkFormComplete() {
    const { componentsResponses, activeGuestKey, event, componentInvitation } =
      this.state;

    const requiredFields = [...document.getElementsByClassName("required")];

    const ruleRequiredCompleted = event.component_rules.componentsRules.filter(
      (rule) =>
        componentsResponses[activeGuestKey].reponses.find(
          (reponse) =>
            reponse.id === Number(rule.component1) &&
            rule.componentRule === "required" &&
            requiredFields.some(
              (elementRequiredClass) =>
                Number(elementRequiredClass.id) === reponse.id
            ) &&
            // to be sure that we can't accidentally comfirm the form with an empty input field BYME
            reponse.label !== "" &&
            reponse.label !== null &&
            !onlySpaces(reponse.label)
        )
    );

    const componentsResponsesFormComplete = componentsResponses.map(
      (userComponentsResponse, key) => {
        if (key === activeGuestKey) {
          if (key === 0) {
            if (ruleRequiredCompleted.length === requiredFields.length) {
              if (componentInvitation) {
                if (
                  componentsResponses[0].reponses.some(
                    (reponse) =>
                      reponse.id === componentInvitation.id && reponse.key === 0
                  ) &&
                  componentsResponses.length === 1
                ) {
                  return { ...userComponentsResponse, formComplete: false };
                } else {
                  return { ...userComponentsResponse, formComplete: true };
                }
              } else {
                return { ...userComponentsResponse, formComplete: true };
              }
            } else {
              return { ...userComponentsResponse, formComplete: false };
            }
          } else {
            return {
              ...userComponentsResponse,
              formComplete:
                ruleRequiredCompleted.length === requiredFields.length,
            };
          }
        } else {
          return { ...userComponentsResponse };
        }
      }
    );
    console.log(
      "🤓 ~ componentsResponsesFormComplete ~",
      componentsResponsesFormComplete
    );
    this.setState({ componentsResponses: componentsResponsesFormComplete });
  }

  handleReponseChange(
    id,
    elementKey,
    label,
    checked,
    unique,
    checkIfValid,
    guestKey
  ) {
    let componentsResponses = [];
    const activeGuestKey = guestKey ? guestKey : this.state.activeGuestKey;

    const self = this;

    componentsResponses = this.state.componentsResponses.map(
      (userComponentsResponse, key) => {
        if (key !== activeGuestKey) {
          return userComponentsResponse;
        } else {
          let reponses = [];

          if (unique === true) {
            reponses = userComponentsResponse.reponses.filter(
              (reponse) => reponse.id !== id
            );
          } else {
            const othersComponentsResponses =
              userComponentsResponse.reponses.filter(
                (reponse) => reponse.id !== id
              );
            const currentComponentsResponse =
              userComponentsResponse.reponses.filter(
                (reponse) => reponse.id === id && reponse.key !== elementKey
              );

            reponses = othersComponentsResponses.concat(
              currentComponentsResponse
            );
          }

          if (checked !== false) reponses.push({ id, key: elementKey, label });

          return { ...userComponentsResponse, reponses };
        }
      }
    );

    if (this.state.multiForms === false) {
      const noMultiform = componentsResponses.map(
        (userComponentsResponse, key) => {
          if (key === 0) {
            return userComponentsResponse;
          } else {
            return {
              ...userComponentsResponse,
              reponses: componentsResponses[0].reponses,
            };
          }
        }
      );

      componentsResponses = noMultiform;
    }

    this.setState(
      {
        showGuestContainer: this.state.componentInvitation
          ? componentsResponses[0].reponses.some(
              (reponse) =>
                reponse.id === this.state.componentInvitation.id &&
                reponse.key === 0
            )
          : false,
        componentsResponses,
        formModified: true,
      },
      () => {
        if (checkIfValid) self.checkFormComplete();
        // It removes followers and their responses, if the guest changed his mind and won't anymore invite followers. This helps prevent issues with not exceeding quota for a component
        if (this.state.componentInvitation) {
          if (
            this.state.componentsResponses[0].reponses.find(
              (reponse) =>
                reponse.id === this.state.componentInvitation.id &&
                reponse.key === 1
            ) &&
            this.state.componentsResponses.length > 1
          ) {
            this.removeAllFollower();
          }
        }
      }
    );
  }

  async removeAllFollower() {
    const self = this;

    const followersToDelete = this.state.followersToDelete;
    this.state.componentsResponses.forEach((userReponse, key) => {
      if (key !== 0 && userReponse.guestId) {
        followersToDelete.push(userReponse.guestId);
      }
    });

    if (followersToDelete.length > 0) {
      this.setState({
        followersToDelete,
      });
    }

    const componentsResponses = this.state.componentsResponses.filter(
      (item, key) => key === 0
    );

    var eventR = this.state.eventResponses;

    followersToDelete.forEach((item) => {
      eventR = eventR.filter((userR) => userR.guest_id !== item);
    });
    const eventResponses = eventR;
    this.setState(
      {
        formModified: true,
        componentsResponses,
        eventResponses,
        activeGuestKey: 0,
      },
      () => {
        self.checkFormComplete();
      }
    );
  }
  renderInvitationsTable() {
    const showTable = this.state.componentsResponses[0].reponses.find(
      (componentReponse) =>
        componentReponse.id === this.state.componentInvitation.id &&
        componentReponse.key === 0
    );

    const { code } = this.state;

    const self = this;
    return (
      <>
        {" "}
        {this.state.activeGuestKey === 0 && showTable ? (
          <div>
            <div className="form-group col-md-12 guests-container pt-2 pb-2">
              <div className="col-md-12">
                {" "}
                <div className="row table-title">
                  <div className="col-md-5">
                    {webPageTranslations[code ? code : "FR"].lastname}
                  </div>
                  <div className="col-md-4">
                    {webPageTranslations[code ? code : "FR"].firstname}
                  </div>
                </div>
                {this.state.componentsResponses.map((userReponse, key) => {
                  if (key !== 0) {
                    return (
                      <div className="row" key={key}>
                        <div className="col-md-5">{userReponse.lastname}</div>{" "}
                        <div className="col-md-4">{userReponse.firstname}</div>
                        <div className="col-md-3">
                          {" "}
                          <FontAwesomeIcon
                            color={
                              this.state.form
                                ? this.state.form.layout.mainColor
                                : ""
                            }
                            onClick={() =>
                              this.removeFollower(userReponse, key)
                            }
                            icon={faTrashAlt}
                          />
                        </div>
                      </div>
                    );
                  } else return null;
                })}
                {this.state.guest.max_invitation >=
                  this.state.componentsResponses.length &&
                this.state.showGuestContainer === true &&
                this.state.activeGuestKey === 0 ? (
                  <div className="row">
                    <div className="col-md-3 offset-md-3">
                      <button
                        // disabled
                        style={{
                          backgroundColor: this.state.form
                            ? this.state.form.layout.mainColor
                            : "",
                          color: this.state.form
                            ? this.state.form.layout.secondaryColor
                            : " ",
                        }}
                        className="btn"
                        onClick={this.toggle}
                      >
                        {webPageTranslations[code ? code : "FR"].button_text}
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}{" "}
        {this.state.multiForms
          ? this.state.componentsResponses.map((userReponse, key) => {
              return (
                <button
                  key={key}
                  className="btn"
                  onClick={() =>
                    this.setState({ activeGuestKey: key }, () =>
                      self.checkFormComplete()
                    )
                  }
                  style={{
                    backgroundColor:
                      key === this.state.activeGuestKey
                        ? this.state.form.layout.mainColor
                        : "#EEEEEE",
                    color:
                      key === this.state.activeGuestKey
                        ? this.state.form.layout.secondaryColor
                        : "black",
                  }}
                >
                  {userReponse.firstname} {userReponse.lastname}{" "}
                  {userReponse.formComplete === true ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : null}
                </button>
              );
            })
          : null}
      </>
    );
  }

  renderLabel(composant) {
    const { form, code } = this.state;

    return form.layout.documentType === "website"
      ? composant.data.reponse.websiteLabel.find((lang) => lang.code === code)
          .value
      : composant.data.reponse.reponseLabel.find((lang) => lang.code === code)
          .value;
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  setComponentsResponses(newResponses) {
    this.setState({ componentsResponses: newResponses });
  }

  setGuest(newGuest) {
    this.setState({
      guest: newGuest,
    });
  }

  render() {
    const {
      form,
      showEventFormMessage,
      messageToShow,
      event,
      componentsResponses,
      code,
      lastInvoice,
      showCheckoutPage,
      eventComponents,
      contact,
      modal,
      line,
      stripeKey,
      stripeSecretKey,
      eventResponses,
      guest,
      allowAddFollower,
      cgv,
      allowCheckForAdmin,
      allowCheckForUser,
      formModified,
      showCalendarEventDetail,
      calendarEventComponent,
      calendarEventBackgroundColor,
      calendarEventIconUrl,
      calendarEventThematiques,
      calendarEventPlaces,
      hasAccess,
      guestInvoices,
      initialComponentsResponses,
      removedGuestsResponses,
      newGuestsResponses,
      componentEntrance,
      isInvoiceProcessing,
    } = this.state;

    if (this.state.isLoading) {
      return <Loader />;
    }
    if (event.data.maintenanceMode) {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: 200 }}>
            <img alt="" src={process.env.PUBLIC_URL + "/logo-kanguroo.png"} />
          </div>
          <div style={{ textAlign: "center" }}>
            Nous rencontrons actuellement un problème,
            <br /> nous faisons le nécessaire pour que tout soit rétabli dans
            les meilleurs délais.
          </div>
        </div>
      );
    }

    if (
      !isEventPaymentConfigOk(
        event.data.free,
        stripeKey,
        stripeSecretKey,
        eventComponents
      )
    ) {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: 200 }}>
            <img alt="" src={process.env.PUBLIC_URL + "/logo-kanguroo.png"} />
          </div>
          <div style={{ textAlign: "center" }}>
            Nous ne pouvons finaliser votre inscription, notre page de paiement
            étant actuellement indisponible. <br /> Veuillez réessayer
            ultérieurement.
          </div>
        </div>
      );
    }

    if (!hasAccess.access) {
      return returnErrorHtml(hasAccess.content);
    } else {
      localStorage.setItem("showCalendar", "true");

      const itemsMain =
        this.state.activeGuestKey === 0
          ? this.state.itemsMainGuest
          : this.state.itemsMainInvitation;
      const itemsSide =
        this.state.activeGuestKey === 0
          ? this.state.itemsSideGuest
          : this.state.itemsSideInvitation;

      const { isAdmin } = this.props;

      document.title = event.data.name;

      const mapComponent = itemsMain
        ? itemsMain.find((component) => component.data.componentType === "map")
        : "";

      const map = mapComponent
        ? this.state.eventComponents.find(
            (component) => component.id === mapComponent.id
          )
        : null;

      const itemsPage = itemsMain ? itemsMain.concat(itemsSide) : null;
      if (!form.layout) {
        return <div className="centerColumn">Pas de programme</div>;
      }

      return (
        <div
          style={{
            overflow: "auto",
            fontFamily: form.layout ? form.layout.fontFamily : "",
            height: "100%",
            background: form.layout
              ? `url(https://res.cloudinary.com/kanguroo-event/image/upload/w_1920/${form.layout.backgroundImage}) no-repeat top center fixed`
              : "",
            backgroundSize: "cover",
            backgroundColor: form.layout ? form.layout.backgroundColor : "",
          }}
        >
          <ToastContainer />
          {formModified ? (
            <Beforeunload onBeforeunload={() => "You'll lose your data!"} />
          ) : null}
          <Modal
            className="modal-calendar"
            isOpen={showCalendarEventDetail}
            toggle={() => this.toggleCalendar()}
          >
            <ModalHeader
              style={{ backgroundColor: calendarEventBackgroundColor }}
              toggle={() => this.toggleCalendar()}
            >
              <span style={{ color: "white" }}>
                {calendarEventComponent
                  ? calendarEventComponent.data.reponse.reponseLabel.find(
                      (label) => label.code === code
                    ).value
                  : ""}
              </span>
              <span id="calendar-modal-icon">
                {" "}
                {calendarEventIconUrl ? (
                  <img
                    src={`https://res.cloudinary.com/kanguroo-event/image/upload/w_50/${calendarEventIconUrl}`}
                    className="img-fluid"
                    alt=""
                  />
                ) : (
                  ""
                )}
              </span>
            </ModalHeader>
            <ModalBody>
              <div style={{ color: "black", padding: "1rem" }} className="row">
                {calendarEventThematiques ? (
                  <div style={{ width: "100%" }}>
                    <span className="font-weight-bold">Thématique(s)</span> :{" "}
                    {calendarEventThematiques}
                  </div>
                ) : null}

                <div>
                  <span className="font-weight-bold">Places</span> :{" "}
                  {calendarEventPlaces}
                </div>
              </div>

              <div
                dangerouslySetInnerHTML={{
                  __html: calendarEventComponent
                    ? this.replaceShortCode(
                        calendarEventComponent.data.reponse.reponseDescription.find(
                          (message) => message.code === code
                        ).value
                      )
                    : "",
                }}
                style={{ color: "black", padding: "1rem" }}
                className="row"
              ></div>
            </ModalBody>
          </Modal>
          <Dialog
            open={modal}
            onClose={this.handleFollowerDialogClose}
            aria-labelledby="add-follower-dialog-title"
            aria-describedby="add-follower-dialog-description"
          >
            <WebPageFollowersModal
              replaceShortCode={this.replaceShortCode}
              allowAddFollower={allowAddFollower}
              componentsResponses={componentsResponses}
              line={line}
              event={event}
              layout={form.layout}
              code={code}
              checkFormComplete={this.checkFormComplete}
              onCreateFollower={this.createFollower}
              handleFollowerDialogClose={this.handleFollowerDialogClose}
            />
          </Dialog>
          <div>
            <div className="col-md-12 page-navbar">
              {form.layout ? (
                form.layout.menu.items.length > 0 ? (
                  <MDBNavbar
                    style={form.layout ? { ...form.layout.menu.style } : null}
                    className="navbar navbar-expand-lg navbar-center"
                    dark
                    expand="md"
                  >
                    <IconButton
                      className="navbar-toggler-button"
                      style={{
                        color: form.layout
                          ? form.layout.menu.style.color
                          : "#000000",
                        paddingBlock: 0,
                        fontSize: "2rem",
                      }}
                      onClick={this.switchMenu}
                      aria-label="navbar-toggler-button"
                    >
                      <FontAwesomeIcon icon={faBars} />
                    </IconButton>
                    <MDBCollapse
                      id="navbarCollapse3"
                      isOpen={this.state.showMenu}
                      navbar
                    >
                      <MDBNavbarNav left>
                        {form.layout
                          ? form.layout.menu.items.map((item, key) => {
                              return itemsPage.some(
                                (itemPage) =>
                                  Number(itemPage.id) ===
                                  Number(item.componentId)
                              ) ? (
                                <MDBNavItem>
                                  <Link
                                    style={{
                                      color: form.layout
                                        ? form.layout.menu.style.color
                                        : null,
                                    }}
                                    onClick={this.switchMenu}
                                    className="nav-link"
                                    to={
                                      this.props.match.url +
                                      "#" +
                                      item.componentId
                                    }
                                  >
                                    {item.icon && item.icon.length > 0 ? (
                                      <i className={"fa " + item.icon} />
                                    ) : null}
                                    &nbsp;{" "}
                                    {
                                      item.label.find(
                                        (lang) => lang.code === code
                                      ).value
                                    }
                                  </Link>
                                </MDBNavItem>
                              ) : null;
                            })
                          : null}
                      </MDBNavbarNav>
                    </MDBCollapse>
                  </MDBNavbar>
                ) : null
              ) : null}
            </div>
            {isInvoiceProcessing ? (
              <div style={{ margin: "2rem" }}>
                <p>
                  Des factures sont en cours de création veuillez recharger la
                  page dans environ 1 minute.
                </p>
              </div>
            ) : (
              <div
                className={`container page-sections ${
                  form.layout.gabarit === "gabarit1"
                    ? "gabarit1-page-container"
                    : ""
                }`}
              >
                <div className="row">
                  {form.layout ? (
                    form.layout.headerImage ? (
                      <div
                        className={`${
                          form.layout.gabarit === "gabarit1"
                            ? "col-12 m-auto"
                            : "col-md-12"
                        }  img-header`}
                      >
                        <img
                          src={`https://res.cloudinary.com/kanguroo-event/image/upload/w_1140/${this.getBackgroundImageUrl(
                            "headerImage"
                          )}`}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    ) : null
                  ) : null}
                  {/* remove header */}
                  {showEventFormMessage ? (
                    <div className={"col-md-12 px-0"}>
                      <div
                        className="row"
                        style={{
                          padding: "10px",
                          backgroundColor: form.layout
                            ? form.layout.backgroundColorMain
                            : "",
                        }}
                      >
                        <div className="col-md-12">
                          {" "}
                          <div
                            className="testclass"
                            dangerouslySetInnerHTML={{
                              __html: messageToShow,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  ) : showCheckoutPage ? (
                    <WebPageCheckout
                      checkAllComponentsAvailableSpace={
                        this.checkAllComponentsAvailableSpace
                      }
                      allowCheckForAdmin={allowCheckForAdmin}
                      allowCheckForUser={allowCheckForUser}
                      isAdmin={isAdmin}
                      cgv={cgv}
                      lastInvoice={lastInvoice}
                      sendResponses={this.sendResponses}
                      sendResponses2={this.sendResponses2}
                      chargeMoney={this.chargeMoney}
                      stripeKey={stripeKey}
                      event={event}
                      eventResponses={eventResponses}
                      contact={contact}
                      modifyForm={this.modifyForm}
                      eventComponents={eventComponents}
                      form={form}
                      code={code}
                      guest={guest}
                      setGuest={this.setGuest}
                      componentsResponses={componentsResponses}
                      guestInvoices={guestInvoices}
                      loadData={this.props.loadData}
                      updateEventFormMessage={this.updateEventFormMessage}
                      initialComponentsResponses={initialComponentsResponses}
                      updateNewAndRemovedGuestsResponsesList={
                        this.updateNewAndRemovedGuestsResponsesList
                      }
                      removedGuestsResponses={removedGuestsResponses}
                      newGuestsResponses={newGuestsResponses}
                      setComponentsResponses={this.setComponentsResponses}
                      componentEntranceId={
                        componentEntrance ? componentEntrance.id : ""
                      }
                    />
                  ) : this.state.isShortPage ? null : (
                    <div
                      className="row"
                      style={{
                        margin: "auto",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: form.layout.backgroundColorMain,
                          padding: "20px",
                          height: "auto",
                        }}
                        className={
                          form.layout.gabarit === "gabarit1" ||
                          (Number(event.id) === 3 &&
                            form.layout.documentType === "reponse" &&
                            window.location.href.includes(
                              "app.participer-evenements.fr"
                            ))
                            ? "col-12 main-container"
                            : "col-md-7 main-container"
                        }
                        id="row-main-container"
                      >
                        <div
                          className="row"
                          style={{
                            backgroundColor: form.layout.backgroundColorMain,
                          }}
                        >
                          {itemsMain.map((item, index) => {
                            const key = `main-componant-item-key-${index}`;
                            return this.generateHtmlComponent(
                              itemsMain,
                              item,
                              "main",
                              key
                            );
                          })}
                          {form.layout.documentType !== "website" ? (
                            <div className="col-md-12 text-center">
                              <div>
                                {this.state.isDelegatairePackageFull ? (
                                  <p
                                    style={{ color: "red", fontWeight: "bold" }}
                                  >
                                    {"(Évènement Complet)"}
                                  </p>
                                ) : null}
                              </div>
                              <button
                                className="btn"
                                disabled={
                                  this.state.buttonLoading ||
                                  this.state.isDelegatairePackageFull ||
                                  this.state.componentsResponses.some(
                                    (reponse) => reponse.formComplete === false
                                  ) ||
                                  // Prevent to have access to the confirm button before the user answer the componentEntrace BYME
                                  this.state.componentsResponses.some(
                                    (reponse) =>
                                      !reponse.reponses.some(
                                        (e) =>
                                          e.id ===
                                          this.state.componentEntrance.id
                                      )
                                  )
                                }
                                style={{
                                  backgroundColor:
                                    this.state.form.layout.mainColor,
                                  color: this.state.form.layout.secondaryColor,
                                  opacity: this.state.buttonLoading ? 1 : null,
                                  paddingBlock: this.state.buttonLoading
                                    ? "0.5rem"
                                    : null,
                                }}
                                onClick={this.validateForm}
                              >
                                {this.state.buttonLoading ? (
                                  <CircularProgress
                                    sx={{
                                      color: "white",
                                      marginInline: "1.5rem",
                                    }}
                                    size={25}
                                  />
                                ) : (
                                  webPageTranslations[code ? code : "FR"]
                                    .confirm
                                )}
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div
                        style={{
                          // paddingTop: "3px",
                          // paddingRight: "20px",
                          // paddingBottom: "20px",
                          // paddingLeft: "20px",
                          padding: 0,
                          marginTop: window.innerWidth >= 960 ? "0px" : "10px",
                        }}
                        className={
                          form.layout.gabarit === "gabarit1" ||
                          (Number(event.id) === 3 &&
                            form.layout.documentType === "reponse" &&
                            window.location.href.includes(
                              "app.participer-evenements.fr"
                            ))
                            ? "col-9 px-0 mx-auto side-container"
                            : "col-md-4 side-container"
                        }
                      >
                        <div className="row" id="row-side-container">
                          {itemsSide.map((item, index) => {
                            const key = `main-componant-key-${index}`;
                            return this.generateHtmlComponent(
                              itemsSide,
                              item,
                              "side",
                              key
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}

                  {map ? (
                    <iframe
                      title="Carte affichée"
                      src={map.data.reponse.mapIframe}
                      width="100%"
                      height="300"
                      frameborder="0"
                      style={{
                        border: 0,
                        marginBottom: "-6px",
                        marginTop: "15px",
                      }}
                      allowfullscreen
                    />
                  ) : null}
                  {form.layout ? (
                    form.layout.footerImage ? (
                      <div
                        className="col-12 px-0 container kanguroo-page"
                        style={{ marginTop: "20px" }}
                      >
                        <div className="row">
                          <div className="col-md-12  pl-0 pr-0">
                            <div className="row">
                              <img
                                src={`https://res.cloudinary.com/kanguroo-event/image/upload/w_1140/${this.getBackgroundImageUrl(
                                  "footerImage"
                                )}`}
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null
                  ) : null}
                </div>
                {form.layout ? (
                  form.layout.documentType !== "website" &&
                  event.data.free === "false" ? (
                    <div className="text-center">
                      <CGVModal
                        cgv={cgv}
                        layout={form.layout}
                        code={code}
                        color={form.layout.secondaryColor}
                      />
                    </div>
                  ) : null
                ) : null}
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}

export default WebPage;

function applyRules(
  packageItems,
  rules,
  componentId,
  componentsResponses,
  willNotJoin,
  componentEntrance,
  eventComponents,
  isDelegatairePackageFull
) {
  let displayed = "";

  const { componentsRules, singleComponentSelectRule, tagsRules, timeRules } =
    rules;

  if (willNotJoin) {
    if (componentId !== componentEntrance.id) displayed = "hidden";

    // Afficher les composants qui sont associés à la réponse "non" du composant d'entrée
    //! DISABLE THIS RULE BECAUSE IF COMPONENT ENTRANCE IS NEGATIVE => NOTHING DISPLAYED
    // const rulesDisplayed = componentsRules.filter(
    //   (rule) =>
    //     Number(rule.component1) === componentId &&
    //     rule.componentRule === "displayed" &&
    //     packageItems.some(
    //       (component) => Number(component.id) === Number(rule.component2)
    //     )
    // );

    // console.log("applyRules ~ rulesDisplayed ~", rulesDisplayed);

    //! DISABLE THIS RULE BECAUSE IF COMPONENT ENTRANCE IS NEGATIVE => NOTHING DISPLAYED
    // rulesDisplayed.forEach((ruleDisplayed) => {
    //   const condition = componentsResponses.find(
    //     (reponse) =>
    //       reponse.id === Number(ruleDisplayed.component2) &&
    //       (ruleDisplayed.match === "is_selected" ||
    //         (ruleDisplayed.match === "equal" &&
    //           Number(ruleDisplayed.matchKey) === reponse.key))
    //   );

    //   console.log("applyRules ~ condition ~", condition);
    //   if (condition) {
    //     displayed = "";
    //   }
    // });
  } else {
    const rulesDisplayed = componentsRules.filter(
      (rule) =>
        Number(rule.component1) === componentId &&
        rule.componentRule === "displayed" &&
        packageItems.some(
          (component) => Number(component.id) === Number(rule.component2)
        )
    );

    rulesDisplayed.forEach((ruleDisplayed) => {
      const condition = componentsResponses.find(
        (reponse) =>
          reponse.id === Number(ruleDisplayed.component2) &&
          (ruleDisplayed.match === "is_selected" ||
            (ruleDisplayed.match === "equal" &&
              Number(ruleDisplayed.matchKey) === reponse.key))
      );

      if (!condition) {
        displayed = "hidden";
      }
    });
  }

  let active = "";

  const rulesActive = componentsRules.filter(
    (rule) =>
      Number(rule.component1) === componentId &&
      rule.componentRule === "disabled"
  );

  rulesActive.forEach((ruleActive) => {
    const condition = componentsResponses.find(
      (reponse) =>
        reponse.id === Number(ruleActive.component2) &&
        (ruleActive.match === "is_selected" ||
          (ruleActive.match === "equal" &&
            Number(ruleActive.matchKey) === reponse.key))
    );

    if (condition) {
      active = "disabled";
    }
  });

  const rulesIsSelected = componentsRules.filter(
    (rule) =>
      Number(rule.component1) === componentId &&
      rule.componentRule === "is_selected"
  );
  rulesIsSelected.forEach((ruleIsSelected) => {
    const condition = componentsResponses.find(
      (reponse) =>
        reponse.id === Number(ruleIsSelected.component2) &&
        (ruleIsSelected.match === "is_selected" ||
          (ruleIsSelected.match === "equal" &&
            Number(ruleIsSelected.matchKey) === reponse.key))
    );

    if (condition) {
      let targetComponentRespIndex = null;
      const targetComponentResp = componentsResponses.find((reponse, i) => {
        if (reponse.id === Number(ruleIsSelected.component1)) {
          targetComponentRespIndex = i;
          return true;
        }
        return false;
      });

      if (targetComponentRespIndex) {
        componentsResponses[targetComponentRespIndex] = {
          ...condition,
          id: targetComponentResp.id,
        };
      } else {
        componentsResponses.push({ ...condition, id: componentId });
      }
    }
  });

  let required = "";

  const rulesRequired = componentsRules.filter(
    (rule) =>
      Number(rule.component1) === componentId &&
      rule.componentRule === "required"
  );

  rulesRequired.forEach((ruleRequired) => {
    const condition =
      !ruleRequired.component2 ||
      componentsResponses.find(
        (reponse) =>
          reponse.id === Number(ruleRequired.component2) &&
          (ruleRequired.match === "is_selected" ||
            (ruleRequired.match === "equal" &&
              Number(ruleRequired.matchKey) === reponse.key))
      );

    if (condition) {
      required = "required";
    }
  });
  const currentComponent = eventComponents.find(
    (item) => item.id === componentId
  );

  if (singleComponentSelectRule) {
    const componentsOnThesameTimeRange = eventComponents.filter(
      (item) =>
        item.data.reponse.displayType === "agenda" &&
        item.id !== currentComponent.id &&
        item.id !== componentEntrance.id &&
        packageItems.some(
          (component) => Number(component.id) === Number(currentComponent.id)
        ) &&
        ((new Date(item.data.endDate) >=
          new Date(currentComponent.data.startDate) &&
          new Date(item.data.endDate) <=
            new Date(currentComponent.data.endDate)) ||
          (new Date(item.data.startDate) >=
            new Date(currentComponent.data.startDate) &&
            new Date(item.data.startDate) <=
              new Date(currentComponent.data.endDate)))
    );

    if (
      componentsResponses.some((reponse) =>
        componentsOnThesameTimeRange.some(
          (component) => component.id === reponse.id
        )
      )
    ) {
      active = "disabled";
    }
  }

  if (tagsRules) {
    tagsRules.forEach((rule) => {
      if (
        currentComponent.data.reponse.tags.some((item) => item.id === rule.tag)
      ) {
        const componentsOnThesameTag = eventComponents.filter(
          (item) =>
            item.id !== currentComponent.id &&
            item.data.reponse.displayType === "agenda" &&
            packageItems.some(
              (component) =>
                Number(component.id) === Number(currentComponent.id)
            ) &&
            item.data.reponse.tags.some((item) => item.id === rule.tag)
        );

        if (
          componentsResponses.filter((reponse) =>
            componentsOnThesameTag.some(
              (component) => component.id === reponse.id
            )
          ).length >= rule.limit
        ) {
          active = "disabled";
        }
      }
    });
  }

  if (timeRules) {
    timeRules.forEach((rule) => {
      if (
        ((new Date(currentComponent.data.startDate) >=
          new Date(rule.startDate) &&
          new Date(currentComponent.data.startDate) <=
            new Date(rule.endDate)) ||
          (new Date(currentComponent.data.endDate) >=
            new Date(rule.startDate) &&
            new Date(currentComponent.data.endDate) <=
              new Date(rule.endDate))) &&
        currentComponent.data.reponse.displayType === "agenda"
      ) {
        const componentsOnThesameTimeLimit = eventComponents.filter(
          (item) =>
            item.id !== currentComponent.id &&
            item.data.reponse.displayType === "agenda" &&
            packageItems.some(
              (component) =>
                Number(component.id) === Number(currentComponent.id)
            ) &&
            ((new Date(item.data.endDate) >= new Date(rule.startDate) &&
              new Date(item.data.endDate) <= new Date(rule.endDate)) ||
              (new Date(item.data.startDate) >= new Date(rule.startDate) &&
                new Date(item.data.startDate) <= new Date(rule.endDate)))
        );

        if (
          componentsResponses.filter((reponse) =>
            componentsOnThesameTimeLimit.some(
              (component) => component.id === reponse.id
            )
          ).length >= rule.timeLimit
        ) {
          active = "disabled";
        }
      }
    });
  }

  if (currentComponent.data.endDate) {
    if (new Date(currentComponent.data.endDate) < new Date()) {
      active = "disabled";
      required = "";
    }
  }
  if (isDelegatairePackageFull) active = "disabled";

  const result =
    displayed === "hidden"
      ? ` ${displayed}`
      : `  ${active === "disabled" ? "disabled" : ""} ${required} `;

  return result;
}

// function isEquivalent(value, other) {
//   // Get the value type
//   var type = Object.prototype.toString.call(value);

//   // If the two objects are not the same type, return false
//   if (type !== Object.prototype.toString.call(other)) return false;

//   // If items are not an object or array, return false
//   if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

//   // Compare the length of the length of the two items
//   var valueLen =
//     type === "[object Array]" ? value.length : Object.keys(value).length;
//   var otherLen =
//     type === "[object Array]" ? other.length : Object.keys(other).length;
//   if (valueLen !== otherLen) return false;

//   // Compare two items
//   var compare = function (item1, item2) {
//     // Get the object type
//     var itemType = Object.prototype.toString.call(item1);

//     // If an object or array, compare recursively
//     if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
//       if (!isEquivalent(item1, item2)) return false;
//     }

//     // Otherwise, do a simple comparison
//     else {
//       // If the two items are not the same type, return false
//       if (itemType !== Object.prototype.toString.call(item2)) return false;

//       // Else if it's a function, convert to a string and compare
//       // Otherwise, just compare
//       if (itemType === "[object Function]") {
//         if (item1.toString() !== item2.toString()) return false;
//       } else {
//         if (item1 !== item2) return false;
//       }
//     }
//   };

//   // Compare properties
//   if (type === "[object Array]") {
//     for (var i = 0; i < valueLen; i++) {
//       if (compare(value[i], other[i]) === false) return false;
//     }
//   } else {
//     for (var key in value) {
//       if (value.hasOwnProperty(key)) {
//         if (compare(value[key], other[key]) === false) return false;
//       }
//     }
//   }

//   // If nothing failed, return true
//   return true;
// }

function onlySpaces(str) {
  return str.trim().length === 0;
}