import React from "react";
import { Link } from "react-router-dom";
import ApiService from "../../../services/ApiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../../common/Loader";
import { faEdit, faTrashAlt, faCopy } from "@fortawesome/free-solid-svg-icons";

class TemplatesList extends React.Component {
  constructor(props) {
    super(props);
    this.deleteTemplate = this.deleteTemplate.bind(this);
    this.duplicateTemplate = this.duplicateTemplate.bind(this);
    this.state = { templatesList: [], isLoading: true };
  }
  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const self = this;

    await ApiService.request(
      {},
      "mailtemplates/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      self.setState({
        templatesList: data,
        isLoading: false,
      });
    });
  }

  async deleteTemplate(id) {
    const self = this;
    await ApiService.request({}, "mailtemplates/" + id, "delete").then(
      function () {
        self.loadData();
      }
    );
  }

  async duplicateTemplate(template) {
    this.setState({ isLoading: true });

    await ApiService.awaitRequest(
      {
        ...template,

        name: template.name + " Copie",
      },
      "mailtemplates/",
      "post"
    );

    this.loadData();
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <div className="row">
            <div className={"col-md-10"}>
              <h2>Liste des templates</h2>
            </div>

            <div className="col-md-2">
              <Link
                className="btn pink darken-1 float-right"
                to={
                  "/admin/events/edit/" +
                  this.props.match.params.eventid +
                  "/templates/edit/"
                }
              >
                Nouveau template
              </Link>
            </div>
          </div>
          <br />
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th scope="col">Nom</th>

                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.templatesList.map((template) => (
                <tr key={template.id}>
                  <td>{template.name}</td>

                  <td>
                    &nbsp;&nbsp;&nbsp;
                    <Link
                      to={
                        "/admin/events/edit/" +
                        this.props.match.params.eventid +
                        "/templates/edit/" +
                        template.id
                      }
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Link>
                    &nbsp;&nbsp;
                    <FontAwesomeIcon
                      className="pointer"
                      onClick={() => this.duplicateTemplate(template)}
                      icon={faCopy}
                    />
                    &nbsp;&nbsp;
                    <span
                      className="pointer"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Etes vous sûr de supprimer ce template ?"
                          )
                        )
                          this.deleteTemplate(template.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default TemplatesList;
