import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Button, MDBInput } from "mdbreact";
import { languagesList } from "../../../common/Translations";
import Multilingual from "../../../common/Multilingual";

class EventConfigTypes extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleLang = this.handleLang.bind(this);
    this.addGuestType = this.addGuestType.bind(this);

    this.saveTypes = this.saveTypes.bind(this);
    this.deleteGuestItem = this.deleteGuestItem.bind(this);

    this.state = {
      guestsTypes: this.props.event.data.guestsTypes,
      useFirstname: this.props.event.data.useFirstname,
      useLastname: this.props.event.data.useLastname,
      useEmail: this.props.event.data.useEmail,
      usePhone: this.props.event.data.usePhone,
      name: languagesList,
      type: "internal",
    };
  }

  handleLang(newLangSet, label) {
    this.setState({
      [label]: newLangSet,
    });
  }

  saveTypes() {
    const event = this.props.event;

    const { guestsTypes, useFirstname, useEmail, useLastname, usePhone } =
      this.state;

    console.log({
      data: {
        ...event.data,
        guestsTypes: { ...guestsTypes },
        useEmail,
        useFirstname,
        useLastname,
        usePhone,
      },
    });

    this.props.onSave({
      ...event,
      data: {
        ...event.data,
        guestsTypes: { ...guestsTypes },
        useEmail,
        useFirstname,
        useLastname,
        usePhone,
      },
    });
  }

  deleteGuestItem = (key) => {
    const guestsTypes = this.state.guestsTypes.slice(0);
    guestsTypes.splice(key, 1);
    this.setState({ guestsTypes });
  };

  handleChange(e) {
    console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  addGuestType(e) {
    e.preventDefault();
    this.setState({
      name: languagesList,
      guestsTypes: [
        ...this.state.guestsTypes,
        { name: this.state.name, type: this.state.type },
      ],
    });
  }

  render() {
    const {
      type,
      name,
      guestsTypes,
      useFirstname,
      useEmail,
      useLastname,
      usePhone,
    } = this.state;

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1">
          {this.props.subMenu}

          <div className="table-container  mb-4">
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  {languagesList.map((language, index) => (
                    <th key={index} scope="col">
                      Nom ({language.code})
                    </th>
                  ))}
                  <th scope="col">Type</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {guestsTypes.map((item, i) => (
                  <tr key={i}>
                    {item.name.map((language, i) => (
                      <td key={i}>{language.value}</td>
                    ))}
                    <td>{item.type === "internal" ? "Interne" : "Externe"}</td>
                    <td>
                      <span
                        className="pointer"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Etes vous sûr de supprimer ce type ?"
                            )
                          )
                            this.deleteGuestItem(i);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <br />
          <form onSubmit={this.addGuestType} className="form">
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Label d'accompagnant</label>
                <div className="form-row">
                  <Multilingual
                    lang={name}
                    event={this.props.event}
                    type="input"
                    handleLang={(e) => this.handleLang(e, "name")}
                  />{" "}
                </div>
              </div>
              <div className="form-group col-md-6">
                <label>Sélectionner le type d'accompagnant (*)</label>
                <select
                  required
                  name="type"
                  className="form-control browser-default custom-select"
                  onChange={this.handleChange}
                  value={type}
                >
                  <option value="internal">Interne</option>
                  <option value="external">Externe</option>
                </select>
              </div>
              <div className="form-group col-md-6 offset-md-6">
                <input
                  type="submit"
                  value="Ajouter"
                  className="btn pink white-text darken-1 btn-lg float-right "
                />
              </div>
            </div>
          </form>
          <br />
          <div className="row">
            <div className="col-md-12">
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label>
                    Champ à renseigner lors de l'ajout d'un accompagnant externe
                  </label>
                  <div className="row">
                    <div className="col-md-2">
                      <MDBInput
                        onClick={() =>
                          this.setState({ useLastname: !useLastname })
                        }
                        id={"useLastname"}
                        label={"Nom"}
                        filled
                        checked={useLastname}
                        type="checkbox"
                      />
                    </div>
                    <div className="col-md-2">
                      <MDBInput
                        onClick={() =>
                          this.setState({ useFirstname: !useFirstname })
                        }
                        id={"useFirstname"}
                        label={"Prénom"}
                        filled
                        checked={useFirstname}
                        type="checkbox"
                      />
                    </div>
                    <div className="col-md-2">
                      <MDBInput
                        onClick={() => this.setState({ useEmail: !useEmail })}
                        id={"useEmail"}
                        label={"Email"}
                        filled
                        checked={useEmail}
                        type="checkbox"
                      />
                    </div>
                    <div className="col-md-2">
                      <MDBInput
                        onClick={() => this.setState({ usePhone: !usePhone })}
                        id={"usePhone"}
                        label={"Téléphone"}
                        filled
                        checked={usePhone}
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Button
                className="btn pink darken-1 float-right"
                onClick={() => this.saveTypes()}
              >
                Sauvegarder
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventConfigTypes;
