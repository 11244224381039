import React from "react";
import { Button, Modal, ModalBody, ModalFooter, MDBInput } from "mdbreact";
import ApiService from "../../../services/ApiService";
import Duplicate from "../../../common/Duplicate";
import Loader from "../../../common/Loader";
import { languagesList } from "../../../common/Translations";
import { toast } from "react-toastify";

class QuickGuestsEditor extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.createAndInviteContact = this.createAndInviteContact.bind(this);

    this.state = {
      gender: "",
      allowFormConfirm: true,
      modal: false,
      lastname: "",
      firstname: "",
      languages: "FR",
      mail: "",
      mail_second: "",
      name: "",
      packageId: "",
      invitation_package_id: "",
      packagesOptions: [],
      max_invitation: 0,
      duplicateContact: {},
      is_followers: false,
      isLoading: this.props.contacts.length > 0 ? false : true,
    };
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value }, () => {
      const duplicate = this.props.contacts.find(
        (contact) =>
          (contact.firstname
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "") ===
            this.state.firstname
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "") &&
            contact.lastname
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "") ===
              this.state.lastname
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")) ||
          (contact.mail === this.state.mail && contact.mail.length !== 0)
      );
      if (duplicate) {
        this.setState({
          duplicateContact: duplicate,
          allowFormConfirm: !this.props.contacts.some(
            (contact) => contact.mail === this.state.mail
          ),
        });
      } else {
        this.setState({ allowFormConfirm: true });
      }
    });
  }

  async createAndInviteContact(e) {
    e.preventDefault();
    const {
      lastname,
      firstname,
      mail,
      languages,
      mail_second,
      name,
      packageId,
      max_invitation,
      invitation_package_id,
      is_followers,
      gender,
    } = this.state;

    this.setState({ isLoading: true });

    const self = this;

    const company =
      name.length > 0
        ? await ApiService.awaitRequest({ name }, "contact_companies/", "post")
        : null;

    const contact = await ApiService.awaitRequest(
      {
        gender,
        mail,
        languages,
        mail_second,
        active: true,
        related_to: null,
        lastname,
        firstname,
        company_id: company ? company.id : null,
      },
      "contacts/",
      "post"
    );

    if (!contact) {
      toast.error("Erreur lors de l'enregistrement du contact", {
        autoClose: 3000,
      });
    } else {
      const guest = await ApiService.awaitRequest(
        {
          ...contact,
          evenement_id: self.props.eventId,
          package_id: packageId,
          invitation_package_id:
            max_invitation > 0 ? invitation_package_id : "",
          checked_in: false,
          max_invitation: max_invitation,
          contact_id: contact.id,
          is_followers,
          email: contact.mail,
          is_validated: true,
          gender,
        },
        "guests",
        "post"
      );

      if (guest) {
        toast.success("contact sauvegardé", {
          autoClose: 3000,
        });

        self.props.updateContact(contact);
      }
    }
  }

  componentDidMount() {
    const packagesOptions = this.props.packagesList.map((element) => ({
      label: element.name,
      value: element.id,
    }));
    if (packagesOptions.length === 1) {
      this.setState({
        packageId: packagesOptions[0].value,
      });
    }
    this.setState({
      packagesOptions,
    });
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.contacts !== prevProps.contacts &&
      this.props.contacts.length !== 0
    ) {
      const packagesOptions = this.props.packagesList.map((element) => ({
        label: element.name,
        value: element.id,
      }));

      this.setState({
        isLoading: false,
        packagesOptions,
      });
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const {
      lastname,
      firstname,
      mail,
      mail_second,
      name,
      languages,
      max_invitation,
      packageId,
      packagesOptions,
      duplicateContact,
      invitation_package_id,
      isLoading,
      allowFormConfirm,
      gender,
    } = this.state;

    return (
      <div className="button-container">
        {duplicateContact.id ? <Duplicate contact={duplicateContact} /> : null}
        <Button
          className="btn pink darken-1"
          disabled={isLoading}
          onClick={this.toggle}
        >
          Créer et inviter un contact
        </Button>
        <Modal
          isOpen={this.state.modal}
          fullHeight
          position="right"
          toggle={this.toggle}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <form
              onSubmit={(e) => this.createAndInviteContact(e)}
              className="form"
            >
              <ModalBody>
                <div className="row">
                  <div className="col-md-12 font-weight-bold">
                    1- Informations du contact
                  </div>

                  <div className="form-group col-md-12">
                    <label>Langues</label>
                    <br />
                    <select
                      onChange={this.handleChange}
                      name="languages"
                      className="form-control browser-default custom-select"
                      value={languages}
                    >
                      {languagesList.map((lang, index) => (
                        <option key={`langues-key-${index}`} value={lang.code}>
                          {lang.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-12">
                    <MDBInput
                      label="Civilité"
                      type="text"
                      name="gender"
                      value={gender}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <MDBInput
                      label="Nom"
                      type="text"
                      value={lastname}
                      name="lastname"
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <MDBInput
                      label="Prénom"
                      type="text"
                      value={firstname}
                      name="firstname"
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <MDBInput
                      label="Email"
                      type="text"
                      value={mail}
                      name="mail"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <MDBInput
                      label="Email secondaire"
                      type="text"
                      value={mail_second}
                      name="mail_second"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <MDBInput
                      label="Société"
                      type="text"
                      value={name}
                      name="name"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-12 font-weight-bold">
                    2- Paramétrage de l'invitation
                  </div>
                  <br />
                  <div className="col-md-12">
                    <label>Package invité</label>
                    <select
                      onChange={this.handleChange}
                      name="packageId"
                      className="browser-default custom-select"
                      value={packageId}
                      required
                    >
                      <option value={""}>Sélectionnez un package</option>
                      {packagesOptions.map((option, index) => (
                        <option
                          key={`package-selection-key-${index}`}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-12">
                    <MDBInput
                      label="Nombre d'invités"
                      name="max_invitation"
                      value={`${max_invitation}`}
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  {max_invitation > 0 && (
                    <div className="col-md-12">
                      <label>Package accompagnant</label>
                      <select
                        onChange={this.handleChange}
                        name="invitation_package_id"
                        className="browser-default custom-select"
                        value={invitation_package_id}
                        required
                      >
                        <option value="">Sélectionnez un package</option>
                        {packagesOptions.map((option, index) => (
                          <option
                            key={`companying-person-package-selection-key-${index}`}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  className="btn pink darken-1 "
                  disabled={!allowFormConfirm}
                >
                  Valider
                </Button>
                <Button className="btn pink darken-1 " onClick={this.toggle}>
                  Fermer
                </Button>
              </ModalFooter>
            </form>
          )}
        </Modal>
      </div>
    );
  }
}

export default QuickGuestsEditor;
