import React from "react";
import { Link } from "react-router-dom";
import ApiService from "../../../services/ApiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../../common/Loader";
import { faEdit, faTrashAlt, faCheck } from "@fortawesome/free-solid-svg-icons";
import { roles } from "../../../services/Utils";
import { MDBInput } from "mdbreact";
import { toast } from "react-toastify";
import ExportDelegataires from "./ExportDelegataires";

class UsersList extends React.Component {
  constructor(props) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.filter = this.filter.bind(this);
    this.filterUsers = this.filterUsers.bind(this);
    this.isAssociatedToEvent = this.isAssociatedToEvent.bind(this);

    this.state = {
      isLoading: false,
      usersList: [],
      usersListFiltered: [],
      activeDelegatairesIds: [],
      nameFilter: "",
      roleFilter: "",
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async loadData() {
    const self = this;
    await ApiService.request(this.state, "users", "get").then(function (data) {
      const usersList = data;
      self._isMounted &&
        self.setState({ usersListFiltered: usersList, usersList });
    });

    await ApiService.request(this.state, "delegataires", "get").then(function (data) {
      const delegataireEventList = data;
      const activeDelegatairesIds = delegataireEventList.map(del => del.user_id);
      self._isMounted &&
        self.setState({ activeDelegatairesIds });
    });
  }

  async deleteUser(id) {
    const self = this;
    await ApiService.request({}, "users/" + id, "delete")
      .then(function () {
        self.loadData();
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Ce délégataire ne peut pas être supprimé car il est utilisé dans un évènement."
        );
      });
  }
  findUserRole(id) {
    if (!id) {
      return "rôle non defini";
    } else {
      const roleName = roles.find((rol) => rol.id === id);
      if (roleName !== undefined) {
        return roleName.name;
      } else {
        return "non définit";
      }
    }
  }

  isAssociatedToEvent(id, delegataireIds) {

    if (delegataireIds.includes(id)) {
      return <>&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faCheck} /> &nbsp;&nbsp;&nbsp;</>;
    } else {
      return "";
    }
  }

  filter() {
    let usersListFiltered = this.state.usersList;

    if (this.state.nameFilter.length > 0) {
      usersListFiltered = usersListFiltered.filter((user) => {
        return user.firstname
          .toLowerCase()
          .includes(this.state.nameFilter.toLowerCase()) ||
          user.lastname
            .toLowerCase()
            .includes(this.state.nameFilter.toLowerCase())
          ? user
          : null;
      });
    }

    if (this.state.roleFilter.length > 0) {
      switch (this.state.roleFilter) {
        case "hf6dPL9be78!":
          usersListFiltered = usersListFiltered.filter(
            (user) => user.role === "hf6dPL9be78!"
          );
          break;
        case "gP75hje!09":
          usersListFiltered = usersListFiltered.filter(
            (user) => user.role === "gP75hje!09"
          );
          break;
        case "g8uJ?deO98":
          usersListFiltered = usersListFiltered.filter(
            (user) => user.role === "g8uJ?deO98"
          );
          break;
        default:
          break;
      }
    }
    this.setState({
      usersListFiltered,
    });
  }
  filterUsers(event) {
    const self = this;
    const nameFilter = [event.target.name][0];
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        const nameFilterlength = self.state.nameFilter.length;

        const dontFilter =
          nameFilter === "nameFilter" &&
          nameFilterlength < 3 &&
          nameFilterlength > 0;

        if (!dontFilter) {
          self.filter();
        }
      }
    );
  }
  render() {
    const { isLoading, roleFilter, nameFilter, usersListFiltered, usersList, activeDelegatairesIds } =
      this.state;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <div className="row">
            <div className="col-md-8">
              <h2>Liste des utilisateurs</h2>
            </div>
            <div className="col-md-2">
              <Link
                className="btn pink darken-1 float-right"
                to={"/admin/users/edit/"}
              >
                Nouvel utilisateur
              </Link>
            </div>
            <div className="col-md-2">
              <ExportDelegataires
                {...this.props}
                usersList={usersList}
                findUserRole={this.findUserRole}
                roles={roles}
              />
            </div>
          </div>
          <br />
          <div className={"white filters"}>
            <div className="row">
              <div className="col-md-3">
                <MDBInput
                  type="text"
                  value={nameFilter}
                  onChange={this.filterUsers}
                  name="nameFilter"
                  label={"Filtrer par nom"}
                />
              </div>
              <div className="col-md-3">
                <div className="md-form">
                  <select
                    required
                    onChange={this.filterUsers}
                    name="roleFilter"
                    className=" browser-default custom-select"
                    value={roleFilter}
                  >
                    <option value={""}>Filtrer par rôle</option>
                    {roles.map((option, i) => (
                      <option key={i} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />

          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th scope="col">Nom</th>
                <th scope="col">Prénom</th>
                <th scope="col">Email</th>
                <th scope="col">Rôle</th>
                <th scope="col">Utilisé</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {usersListFiltered.map((user) => (
                <tr key={user.id}>
                  <td>{user.lastname}</td>
                  <td>{user.firstname}</td>
                  <td>{user.email}</td>
                  <td>{this.findUserRole(user.role)}</td>
                  <td>{this.isAssociatedToEvent(user.id, activeDelegatairesIds)}</td>
                  <td>
                    &nbsp;&nbsp;&nbsp;
                    <Link to={"/admin/users/edit/" + user.id}>
                      <FontAwesomeIcon icon={faEdit} />
                    </Link>
                    &nbsp;&nbsp;&nbsp;
                    <span
                      className="pointer"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Etes vous sûr de supprimer cet utilisateur ?"
                          )
                        )
                          this.deleteUser(user.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default UsersList;
