import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const EventDeletionDialog = ({ deleteEvent, eventName }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeletion = () => {
    deleteEvent();
    handleClose();
  };

  return (
    <div>
      <button
        className="btn btn-lg cancel-button float-left"
        onClick={handleClickOpen}
      >
        SUPPRIMER CET ÉVÈNEMENT
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="event-deletion-dialog-title"
        aria-describedby="event-deletion-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Supprimer l'évènement : ${eventName}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="event-deletion-dialog-description">
            Tous les invités, composants, packages et templates de cet événement
            seront supprimés. Êtes vous sûr ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: "#5a5a5a" }}>
            Annuler
          </Button>
          <Button onClick={handleDeletion} autoFocus sx={{ color: "#d81b60" }}>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

EventDeletionDialog.prototype = {
  deleteEvent: PropTypes.func.isRequired,
  eventName: PropTypes.string.isRequired,
};

export default EventDeletionDialog;
