import "babel-polyfill";
import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
//import "./toast.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { unregister } from "./serviceWorker";

unregister();

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

const checkIsClientLocal = () => {
  var host = window.location.host;
  if (host.toLowerCase() && host.toLowerCase().indexOf("localhost:") !== -1) {
    return true;
  }
  return false;
};

const getFilePath = (filename) => {
  let folderName = "";
  switch (window.location.pathname) {
    case "/checkin/":
    case "/checkin":
      folderName = "checkin";
      if (filename.includes("manifest")) {
        if (window.location.hostname.includes("lnr")) {
          folderName += "/LNR";
        } else if (window.location.hostname.includes("stellium")) {
          folderName += "/STELLIUM";
        } else if (window.location.hostname.includes("digeek")) {
          folderName += "/DIGEEK";
        } else if (window.location.hostname.includes("epcr")) {
          folderName += "/EPCR";
        }
      }
      break;
    case "/placement/":
    case "/placement":
      folderName = "placement";
      if (filename.includes("manifest")) {
        if (window.location.hostname.includes("lnr")) {
          folderName += "/LNR";
        } else if (window.location.hostname.includes("stellium")) {
          folderName += "/STELLIUM";
        } else if (window.location.hostname.includes("digeek")) {
          folderName += "/DIGEEK";
        } else if (window.location.hostname.includes("epcr")) {
          folderName += "/EPCR";
        }
      }
      break;
    default:
      folderName = "default";
      if (filename.includes("manifest")) {
        if (window.location.hostname.includes("lnr")) {
          folderName += "/LNR";
        } else if (
          window.location.hostname.includes("stellium") ||
          window.location.hostname.includes("participer-evenements")
        ) {
          folderName += "/STELLIUM";
        } else if (window.location.hostname.includes("digeek")) {
          folderName += "/DIGEEK";
        } else if (window.location.hostname.includes("epcr")) {
          folderName += "/EPCR";
        }
      }
      break;
  }

  let url =
    "https://" + window.location.host + "/icons/" + folderName + `/${filename}`;
  if (checkIsClientLocal()) {
    url =
      "http://" +
      window.location.host +
      "/icons/" +
      folderName +
      `/${filename}`;
  }
  return url;
};

const setManifest = () => {
  let url = getFilePath("manifest.json");
  var link = document.createElement("link");
  link.href = url;
  link.rel = "manifest";
  //console.log(url);
  document.getElementsByTagName("head")[0].appendChild(link);
};

const setIcons = () => {
  let url = getFilePath("favicon.ico");
  var link = document.createElement("link");
  link.href = url;
  link.rel = "shortcut icon";
  document.getElementsByTagName("head")[0].appendChild(link);

  url = getFilePath("favicon-32x32.png");
  link = document.createElement("link");
  link.href = url;
  link.sizes = "32x32";
  link.type = "image/png";
  link.rel = "icon";
  document.getElementsByTagName("head")[0].appendChild(link);

  url = getFilePath("favicon-16x16.png");
  link = document.createElement("link");
  link.href = url;
  link.sizes = "16x16";
  link.type = "image/png";
  link.rel = "icon";
  document.getElementsByTagName("head")[0].appendChild(link);

  url = getFilePath("apple-touch-icon.png");
  link = document.createElement("link");
  link.href = url;
  link.sizes = "152x152";
  link.rel = "apple-touch-icon";
  document.getElementsByTagName("head")[0].appendChild(link);
};

const init = () => {
  setManifest();
  setIcons();
};

init();
