import React from "react";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "mdbreact";
import FileUpload from "../../../../common/FileUpload";
import { Image, CloudinaryContext } from "cloudinary-react";
import "./ImgEditor.css";

class ImageEditor extends React.Component {
  constructor(props) {
    super(props);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.deleteImg = this.deleteImg.bind(this);
    this.getImgUrlForLanguage = this.getImgUrlForLanguage.bind(this);

    let image_format_label = "";
    if (props.name === "backgroundImage") {
      image_format_label = "(Format attendu: 1920px x 1200px)";
    }
    if (props.name === "headerImage") {
      image_format_label = "(Format attendu: 1180px x *px)";
    }
    if (props.name === "footerImage") {
      image_format_label = "(Format attendu: 1180px x *px)";
    }

    this.state = {
      modal: false,
      mapIframe: "",
      imgUrl: this.props.imgUrl,
      selectedLanguage: this.props.selectedLanguage,
      default_language: this.props.default_language,
      image_format_label: image_format_label,
    };
  }

  // Actualiser en fonction de la langue sélectionnée
  componentDidUpdate(prevProps) {
    if (this.props.selectedLanguage !== prevProps.selectedLanguage) {
      this.setState({
        selectedLanguage: this.props.selectedLanguage,
      });
    }
  }

  deleteImg(lang = null) {
    const { name, onUpload } = this.props;

    // Si l'image est générale
    if (!lang) {
      this.setState({
        imgUrl: "",
      });
      this.props.onUpload({ [name]: "" });
    } else {
      if (typeof this.state.imgUrl === "string") {
        this.setState({
          imgUrl: "",
        });
        onUpload({ [name]: "" });
      } else {
        // Supprime l'image associée à la langue sélectionnée
        const updatedImage = this.state.imgUrl.filter(
          (item) => Object.keys(item)[0] !== lang
        );

        this.setState({
          imgUrl: updatedImage,
        });

        onUpload({ [name]: updatedImage });
      }
    }
  }

  onFileUpload(id, lang) {
    // Si l'image est générale
    if (!lang) {
      this.setState({
        imgUrl: id,
      });
      this.props.onUpload({ [this.props.name]: this.state.imgUrl });
    } else {
      // Copie des propriétés existantes de imgUrl ou initialisation avec un tableau vide
      const existingData = Array.isArray(this.props.imgUrl)
        ? [...this.props.imgUrl]
        : [];

      // Vérifier si un objet avec la même langue existe déjà
      const existingObjectIndex = existingData.findIndex(
        (item) => Object.keys(item)[0] === lang
      );

      if (existingObjectIndex !== -1) {
        // Mettre à jour l'ID de l'objet existant avec la même langue
        existingData[existingObjectIndex][lang] = id;
      } else {
        // Ajouter un nouvel objet si aucun objet avec la même langue n'existe
        existingData.push({ [lang]: id });
      }

      this.setState({
        imgUrl: existingData,
      });
      // Mettre à jour la propriété imgUrl avec les données mises à jour
      this.props.onUpload({ [this.props.name]: existingData });
    }
  }

  getImgUrlForLanguage = (lang) => {
    const { imgUrl } = this.state;

    if (typeof imgUrl === "string") {
      return imgUrl;
    }

    // Trouver l'image associée à la langue
    const languageObject = imgUrl.find((item) => Object.keys(item)[0] === lang);

    return languageObject ? languageObject[lang] : "";
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const { default_language } = this.state;

    return (
      <div>
        <Button className="btn btn-block" onClick={this.toggle}>
          {this.props.label}
        </Button>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Modifier l'image {this.state.image_format_label}
          </ModalHeader>
          <ModalBody className="d-flex flex-column">
            {default_language ? (
              default_language.map((lang) => (
                <div key={lang} className="col-12 d-flex flex-wrap">
                  <p className="col-12">{lang}</p>

                  <div className="col-5">
                    <FileUpload
                      buttonLabel={"Charger un visuel"}
                      name={lang}
                      onFileUpload={this.onFileUpload}
                    />
                    <Button
                      className="w-100 m-0 mt-2"
                      color="default"
                      onClick={() => this.deleteImg(lang)}
                    >
                      Supprimer le visuel
                    </Button>
                  </div>
                  {this.state.imgUrl.length > 0 ? (
                    <div className="col-7">
                      <CloudinaryContext cloudName="kanguroo-event">
                        <Image publicId={this.getImgUrlForLanguage(lang)} />
                      </CloudinaryContext>
                    </div>
                  ) : null}
                </div>
              ))
            ) : (
              <CloudinaryContext cloudName="kanguroo-event">
                <Image publicId={this.state.imgUrl} />
              </CloudinaryContext>
            )}
          </ModalBody>
          {/* Si l'image est associée à aucune langue */}
          {!default_language ? (
            <ModalFooter>
              <FileUpload
                buttonLabel={"Charger un visuel"}
                onFileUpload={this.onFileUpload}
              />
              <Button color="default" onClick={this.deleteImg}>
                Supprimer le visuel
              </Button>
            </ModalFooter>
          ) : null}
        </Modal>
      </div>
    );
  }
}

export default ImageEditor;
