import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function AutocompleteSelect({ options, selectedValue, handleSelectedValue }) {
  const [inputValue, setInputValue] = React.useState("");

  const getValue = React.useMemo(() => {
    const res = options.find((opt) => opt.id === selectedValue) || null;
    return res;
  }, [options, selectedValue]);

  return (
    <div>
      <Autocomplete
        options={options}
        isOptionEqualToValue={(option, newValue) => {
          return option.id === newValue.id;
        }}
        value={getValue}
        onChange={(event, opt) => {
          if (opt && opt.id) handleSelectedValue(opt.id);
          else handleSelectedValue(null);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.label}
          </li>
        )}
        sx={{
          minWidth: "250px",
          width: "fit-content",
          "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
            padding: "8.5px 14px",
          },
          "& .MuiOutlinedInput-root": {
            padding: 0,
          },
        }}
        componentsProps={{
          paper: {
            sx: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
              marginTop: "1rem",
              backgroundColor: "#e1dfdf",
              border: "1px solid #d81b60",
            },
          },
          popper: { placement: "bottom" },
        }}
        renderInput={(params) => (
          <TextField
            placeholder="Nom ou Prénom..."
            size="small"
            sx={{
              m: 1,
              height: "fit-content",
              minWidth: "250px",
              width: "auto",
              backgroundColor: "#d81b60",
              borderRadius: "0.7rem",
              color: "green",
              border: "1px solid #000000 !important",
              "& .MuiOutlinedInput-input": {
                color: "#FFFFFF",
                ":focus": {
                  borderRadius: "0.7rem",
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& ::placeholder": {
                color: "white",
                fontStyle: "italic",
                opacity: 1,
              },
              "& :focus": {
                borderBottom: 0,
                boxShadow: "none",
              },
            }}
            id="input-outlined-autocomplete-segment"
            variant="outlined"
            {...params}
          />
        )}
      />
    </div>
  );
}

export default AutocompleteSelect;
