import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "mdbreact";
import ApiService from "../../../services/ApiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader";

class CheckinMessage extends React.Component {
  constructor(props) {
    super(props);
    this.updateCheckinMessage = this.updateCheckinMessage.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      modal: this.props.modal || false,
      check_in_message: this.props.guest
        ? this.props.guest.check_in_message
        : "",
    };
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.guest) {
      if (this.props.guest !== prevProps.guest) {
        this.setState({
          check_in_message: this.props.guest.check_in_message,
        });
      }
    }
  }

  async updateCheckinMessage() {
    const guests = this.props.guests;
    const guest = this.props.guest;
    const message = this.state.check_in_message;
    const self = this;

    if (this.props.multiMessage) {
      try {
        if (this.props.followers) {
          const guestsChecked = this.props.guestsChecked;
          const guestsRequest = await ApiService.request(
            {},
            "guests/byevenement/" + this.props.eventId,
            "get"
          );
          const followers = guestsRequest.filter((guest) => guest.is_followers);
          guestsChecked.map(async (guest) => {
            const followersOfGuest = followers.filter(
              (follower) => follower.invited_by.contact_id === guest.contact_id
            );
            await ApiService.request(
              {
                check_in_message: message,
              },
              "guests/" + guest.id,
              "put"
            ).then(function () {
              if (guests) {
                const findGuest = guests.find((g) => g.id === guest.id);
                findGuest.check_in_message = message;
                self.props.setGuests(guests);
              }
            });
            followersOfGuest.map(async (follower) => {
              await ApiService.request(
                {
                  check_in_message: message,
                },
                "guests/" + follower.id,
                "put"
              );
            });
          });
        } else {
          this.props.guestsChecked.map(async (guest, index) => {
            await ApiService.request(
              {
                check_in_message: message,
              },
              "guests/" + guest.id,
              "put"
            ).then(function (data) {
              if (guests) {
                const findGuest = guests.find((g) => g.id === guest.id);
                findGuest.check_in_message = message;
                self.props.setGuests(guests);
              }
              toast.success("Message mis à jour.");
            });
          });
        }

        this.props.loadData(false);
      } catch (e) {
        toast.error("Erreur.");
      }
    } else {
      await ApiService.request(
        {
          check_in_message: message,
        },
        "guests/" + guest.id,
        "put"
      ).then(function () {
        if (guests) {
          const findGuest = guests.find((g) => g.id === guest.id);
          findGuest.check_in_message = message;
          self.props.setGuests(guests);
        }
        toast.success("Message mis à jour.");
      });
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const { isLoading, modal, check_in_message } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <>
        <FontAwesomeIcon
          title={"Message au checkin"}
          onClick={this.toggle}
          icon={faCommentAlt}
        />
        <Modal size="lg" isOpen={modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle} />
          <ModalBody>
            <div className="form-row">
              <div className="col-md-12">
                <label style={{ color: "black" }}>Message</label>
                <textarea
                  type="text"
                  name="check_in_message"
                  onChange={this.handleChange}
                  className="form-control"
                  rows="3"
                  value={check_in_message}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn pink darken-1 white-text "
              onClick={this.updateCheckinMessage}
            >
              Valider
            </Button>
            <btn className="btn cancel-button" onClick={this.toggle}>
              Fermer
            </btn>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default CheckinMessage;
