import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  Box,
} from "@mui/material";

import CheckinDialog from "./dialogs/CheckinDialog";
import PersonIcon from "@mui/icons-material/Person";
import DetailDialog from "./dialogs/DetailDialog";
import QuickGuestsEditor from "./QuickGuestsEditor";
import { getGuestNbrOfCheck, updateGuestCheckinData } from "./utils";

function GuestsList({
  guests,
  checkinPointId,
  setGuests,
  eventId,
  placements,
  formId,
  eventResponses,
  components,
}) {
  const [openCheckinDialog, setOpenCheckinDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [reponse, setReponse] = useState("");

  const handleCheckinDialogOpen = (value) => {
    setOpenCheckinDialog(value);
  };

  const handleDetailsDialogOpen = (value) => {
    if (value) setOpenCheckinDialog(false);
    setOpenDetailDialog(value);
  };

  const handleCheckin = (status) => {
    const audio = new Audio();

    audio.src = process.env.PUBLIC_URL + "/bip.mp3";
    audio.play();

    updateGuestCheckinData(
      selectedGuest,
      status,
      checkinPointId,
      setGuests,
      setSelectedGuest,
      handleDetailsDialogOpen
    );
    handleCheckinDialogOpen(false);
  };

  const updateGuestListAndCheckin = async (guest) => {
    const newGuestList = [...guests, guest];

    setGuests(() => {
      return newGuestList.sort(function (a, b) {
        if (a.lastname.toLowerCase() < b.lastname.toLowerCase()) {
          return -1;
        }
        if (a.lastname.toLowerCase() > b.lastname.toLowerCase()) {
          return 1;
        }
        // Si les noms de famille sont identiques, comparer par prénom
        if (a.firstname.toLowerCase() < b.firstname.toLowerCase()) {
          return -1;
        }
        if (a.firstname.toLowerCase() > b.firstname.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    });

    updateGuestCheckinData(
      guest,
      true,
      checkinPointId,
      setGuests,
      setSelectedGuest,
      handleDetailsDialogOpen
    );
  };

  const componentEntrance =
    components && components.length > 0
      ? components.find(
          (cmp) => cmp.data.reponse && cmp.data.reponse.role === "entrance"
        )
      : null;

  return (
    <>
      <Box>
        <CheckinDialog
          open={openCheckinDialog}
          handleOpen={handleCheckinDialogOpen}
          handleCheckin={handleCheckin}
          handleDetailsDialogOpen={handleDetailsDialogOpen}
          guestNbrOfCheck={getGuestNbrOfCheck(selectedGuest, checkinPointId)}
        />
        <DetailDialog
          handleOpen={handleDetailsDialogOpen}
          open={openDetailDialog}
          guest={selectedGuest}
          scanError={false}
          placements={placements}
          reponse={reponse}
          components={components}
        />

        <TableContainer
          component={Paper}
          sx={{ marginBottom: "4.2rem", borderRadius: 0 }}
        >
          <Table sx={{}} aria-label="simple table">
            <TableBody>
              {guests.map((guest) => {
                //TODO: Create a field called nbrOfCheck inside guests checkin_points
                //TODO: Get nbrOfCheck from guest
                const guestNbrOfCheck = getGuestNbrOfCheck(
                  guest,
                  checkinPointId
                );
                return (
                  <TableRow
                    key={guest.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => {
                      setSelectedGuest(guest);
                      setReponse(
                        eventResponses.find(
                          (rep) =>
                            rep.guest_id === guest.id &&
                            rep.evenement_form_id === formId
                        )
                      );
                      handleCheckinDialogOpen(true);
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Box>
                        <Typography
                          fontWeight={600}
                        >{`${guest.firstname} ${guest.lastname}`}</Typography>
                        <Typography
                          sx={{ color: "#777777", fontSize: "0.9rem" }}
                        >
                          {guest && guest.contact && guest.contact.company
                            ? guest.contact.company.name
                            : ""}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      {guestNbrOfCheck > 0 ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                        >
                          <PersonIcon sx={{ color: "#d81b60" }} />
                          <Typography
                            sx={{ marginLeft: "0.7rem", color: "#d81b60" }}
                          >
                            ({guestNbrOfCheck})
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                        >
                          <PersonIcon sx={{ color: "#bfbfbf" }} />
                          <Typography
                            sx={{ marginLeft: "0.7rem", color: "#929090" }}
                          >
                            {"(0)"}
                          </Typography>
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <QuickGuestsEditor
          eventId={eventId}
          component={componentEntrance}
          updateGuestListAndCheckin={updateGuestListAndCheckin}
          formId={formId}
        />
      </Box>
    </>
  );
}

export default GuestsList;
