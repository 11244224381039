import React from "react";
import Multilingual from "../../../common/Multilingual";

class EventConfigMessages extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLang = this.handleLang.bind(this);
    this.state = { event: { ...this.props.event } };
  }

  handleLang(newLangSet, label) {
    this.setState({
      event: {
        ...this.state.event,
        data: {
          ...this.state.event.data,
          [label]: newLangSet,
        },
      },
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.props.onSave({ ...this.state.event });

    e.preventDefault();
  }

  handleChange(event) {
    this.setState({
      event: {
        ...this.state.event,
        [event.target.name]: event.target.value,
      },
    });
  }

  render() {
    const {
      messageWillJoinEvent,
      messageWillNotJoinEvent,
      existingResponseMessage,
      registrationExpiredText,
      messageWillNotJoinEventAndGetRefund,
      eventFullText,
      contactInformations,
    } = this.state.event.data;
    return (
      <div className="row">
        <div className="col-md-10 offset-md-1">
          {this.props.subMenu}
          <form onSubmit={this.handleSubmit} className="form">
            <label>Informations de contact</label>
            <div className="form-row">
              <div className="form-group col-md-12">
                <div className="form-row">
                  <Multilingual
                    lang={contactInformations}
                    event={this.props.event}
                    type="textarea"
                    handleLang={(e) =>
                      this.handleLang(e, "contactInformations")
                    }
                  />
                </div>
              </div>
            </div>
            <label>Message à afficher une fois le formulaire complété</label>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Si l'invité se joint à l'événement</label>
                <div className="form-row">
                  <Multilingual
                    lang={messageWillJoinEvent}
                    event={this.props.event}
                    type="textarea"
                    handleLang={(e) =>
                      this.handleLang(e, "messageWillJoinEvent")
                    }
                  />
                </div>
              </div>
              <div className="form-group col-md-12">
                <label>Si l'invité ne se joint pas à l'événement</label>
                <div className="form-row">
                  <Multilingual
                    lang={messageWillNotJoinEvent}
                    event={this.props.event}
                    type="textarea"
                    handleLang={(e) =>
                      this.handleLang(e, "messageWillNotJoinEvent")
                    }
                  />{" "}
                </div>
              </div>
              <div className="form-group col-md-12">
                <label>
                  Si l'invité a déjà répondu et que la modification est
                  désactivée
                </label>{" "}
                <div className="form-row">
                  <Multilingual
                    lang={existingResponseMessage}
                    event={this.props.event}
                    type="textarea"
                    handleLang={(e) =>
                      this.handleLang(e, "existingResponseMessage")
                    }
                  />{" "}
                </div>
              </div>
            </div>
            <br />
            <label>Message à afficher en cas de limite de date atteinte</label>
            <div className="form-row">
              <Multilingual
                lang={registrationExpiredText}
                event={this.props.event}
                type="textarea"
                handleLang={(e) =>
                  this.handleLang(e, "registrationExpiredText")
                }
              />
            </div>
            <br />
            <label>
              Message à afficher en cas de limite de places atteinte
            </label>
            <div className="form-row">
              <Multilingual
                lang={eventFullText}
                event={this.props.event}
                type="textarea"
                handleLang={(e) => this.handleLang(e, "eventFullText")}
              />
            </div>
            <br />
            <label>Message à afficher en cas d'annulation</label>
            <div className="form-row">
              <Multilingual
                lang={messageWillNotJoinEventAndGetRefund}
                event={this.props.event}
                type="textarea"
                handleLang={(e) =>
                  this.handleLang(e, "messageWillNotJoinEventAndGetRefund")
                }
              />
            </div>
            <div className="form-row" />
            <input
              type="submit"
              value="Enregistrer"
              className="btn pink darken-1 btn-lg float-right "
            />
          </form>
        </div>
      </div>
    );
  }
}

export default EventConfigMessages;
