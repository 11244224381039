import React from "react";
import { Link } from "react-router-dom";
import ApiService from "../../../services/ApiService";
import Loader from "../../../common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Sortable from "../../../common/Sortable";
import { MDBSpinner } from "mdbreact";
import {
  faClone,
  faCog,
  faTachometerAlt,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import "../../../common/loader.css";
import { toast } from "react-toastify";
import moment from "moment";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


class EventsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      events: [],
      selectedTable: "current",
      duplicatePreviousLoading: false,
      duplicateOnGoingLoading: false,
      duplicateArchivedLoading: false,
    };
    this._isMounted = false;
  }

  async componentDidMount() {
    const self = this;
    this._isMounted = true;
    this._isMounted && self.setState({ isLoading: true });

    await ApiService.request({}, "evenements/stats/all", "get").then(function (
      events
    ) {
      self._isMounted && self.setState({ events, isLoading: false });
    });
  }

  sortDataWithStartDate = (events) => {

    //modif ici
    return events.sort(function (a, b) {
      const dateA = a.start_date;
      const dateB = b.start_date;

      if (dateA > dateB) {
        return -1;
      }
      if (dateA < dateB) {
        return 1;
      }
      return 0;
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  async deleteEvent(index, id) {
    const list = this.state.data;
    list.splice(index, 1);
    const self = this;
    self.setState({ isLoading: true });

    await ApiService.request({}, "evenements/" + id, "delete").then(
      function () {
        self.setState({ data: list, isLoading: false });
      }
    );
  }

  renderContent(props) {
    const { selectedTable } = this.state;

    const {
      names,
      ongoingEventssortable,
      duplicateOnGoingLoading,
      previousEventsortable,
      duplicatePreviousLoading,
      archivedEventsortable,
      duplicateArchivedLoading,
    } = props

    switch (selectedTable) {
      case 'current':
        return (
          <div>
            {duplicateOnGoingLoading ? (
              <>
                <br />
                <div className="text-center">
                  <br />
                  <MDBSpinner big red />
                </div>
              </>
            ) : null}
            <br />
            <p>Événements en cours ou à venir</p>
            <Sortable names={names} sortable={ongoingEventssortable} />
          </div>
        );
      case 'passed':
        return (
          <div>
            {duplicatePreviousLoading ? (
              <>
                <br />
                <div className="text-center">
                  <br />
                  <MDBSpinner big red />
                </div>
              </>
            ) : null}
            <br />
            <p>Événements passés</p>
            <Sortable names={names} sortable={previousEventsortable} />
          </div>);
      case 'archived':
        return (
          <div>
            {duplicateArchivedLoading ? (
              <>
                <br />
                <div className="text-center">
                  <br />
                  <MDBSpinner big red />
                </div>
              </>
            ) : null}
            <br />
            <p>Événements archivés</p>
            <Sortable names={names} sortable={archivedEventsortable} />
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    const {
      events,
      isLoading,
      selectedTable,
      duplicatePreviousLoading,
      duplicateOnGoingLoading,
      duplicateArchivedLoading,
    } = this.state;
    if (isLoading) {
      return <Loader />;
    }

    const names = [
      { key: "eventid", value: "Id", width: "5%" },
      { key: "name", value: "Nom", width: "30%" },
      { key: "startDate", value: "Date de début", width: "15%" },
      { key: "endDate", value: "Date de fin", width: "15%" },
      { key: "participants", value: "Participants", width: "10%" },
      { key: "guests", value: "Invités", width: "10%" },
      { key: "actions", value: "Actions", width: "20%" },
    ];

    const now = new Date().getTime();
    const ongoingEvents = events.filter((event) => {
      const endDate = new Date(event.end_date).getTime();
      return now < endDate && event.delegataire_archived === false;
    });

    let eventSorted = this.sortDataWithStartDate(events);
    const previousEvents = eventSorted.filter((event) => {
      const endDate = new Date(event.end_date).getTime();
      return now > endDate && event.delegataire_archived === false; //modif ici
    });

    const archivedEvents = events.filter((event) => {
      return event.delegataire_archived === true;
    });

    const duplicateEvents = async (eventId, type) => {
      if (window.confirm("Êtes vous sûre de vouloir dupliquer l'évenement ?")) {
        switch (type) {
          case "ongoing":
            this.setState({ duplicateOnGoingLoading: true });
            await makeDuplication(this, eventId);
            this.setState({ duplicateOnGoingLoading: false });
            break;
          case "previous":
            this.setState({ duplicatePreviousLoading: true });
            await makeDuplication(this, eventId);
            this.setState({ duplicatePreviousLoading: false });
            break;
          case "archived":
            this.setState({ duplicateArchivedLoading: true });
            await makeDuplication(this, eventId);
            this.setState({ duplicateArchivedLoading: false });
            break;
          default:
            break;
        }
      } else {
        return;
      }
    };

    const makeDuplication = async (self, eventId) => {
      try {
        await ApiService.request(
          {},
          "evenements/duplicate/" + eventId,
          "post"
        ).then(function (copyEvent) {
          if (copyEvent) {
            self.setState({ events: [copyEvent, ...events] });
            toast.success("Évenement dupliqué", {
              autoClose: 3000,
            });
          } else {
            toast.error("Erreur dans la duplication", {
              autoClose: 3000,
            });
          }
        });
      } catch (e) {
        console.log(e);
        toast.error("Erreur dans la duplication", {
          autoClose: 3000,
        });
      }
    };
    const previousEventsortable = previousEvents.map((event) => ({
      eventid: event.id,
      name: event.name,
      startDate: event.start_date,
      endDate: event.end_date,
      participants: event.participants,
      guests: event.guests,

      actions: (
        <>
          <Link to={"/admin/events/edit/" + event.id + "/dashboard"}>
            <FontAwesomeIcon icon={faTachometerAlt} />
          </Link>
          &nbsp;&nbsp;
          <Link to={"/admin/events/edit/" + event.id + "/guests"}>
            <FontAwesomeIcon icon={faUsers} />
          </Link>
          &nbsp;&nbsp;
          <Link to={"/admin/events/edit/" + event.id}>
            <FontAwesomeIcon icon={faCog} />
          </Link>
          &nbsp;&nbsp;
          <Link to={"#"}>
            <FontAwesomeIcon
              icon={faClone}
              onClick={() => duplicateEvents(event.id, "previous")}
            />
          </Link>
        </>
      ),
    }));

    const archivedEventsortable = archivedEvents.map((event) => ({
      eventid: event.id,
      name: event.name,
      startDate: event.start_date,
      endDate: event.end_date,
      participants: event.participants,
      guests: event.guests,

      actions: (
        <>
          <Link to={"/admin/events/edit/" + event.id + "/dashboard"}>
            <FontAwesomeIcon icon={faTachometerAlt} />
          </Link>
          &nbsp;&nbsp;
          <Link to={"/admin/events/edit/" + event.id + "/guests"}>
            <FontAwesomeIcon icon={faUsers} />
          </Link>
          &nbsp;&nbsp;
          <Link to={"/admin/events/edit/" + event.id}>
            <FontAwesomeIcon icon={faCog} />
          </Link>
          &nbsp;&nbsp;
          <Link to={"#"}>
            <FontAwesomeIcon
              icon={faClone}
              onClick={() => duplicateEvents(event.id, "archived")}
            />
          </Link>
        </>
      ),
    }));

    const ongoingEventssortable = ongoingEvents.map((event) => ({
      eventid: event.id,
      name: event.name,
      startDate: moment.utc(event.start_date).format("DD-MM-YYYY à H:mm"),
      endDate: moment.utc(event.end_date).format("DD-MM-YYYY à H:mm"),
      participants: event.participants,
      guests: event.guests,

      actions: (
        <>
          <Link to={"/admin/events/edit/" + event.id + "/dashboard"}>
            <FontAwesomeIcon icon={faTachometerAlt} />
          </Link>
          &nbsp;&nbsp;
          <Link to={"/admin/events/edit/" + event.id + "/guests"}>
            <FontAwesomeIcon icon={faUsers} />
          </Link>
          &nbsp;&nbsp;
          <Link to={"/admin/events/edit/" + event.id}>
            <FontAwesomeIcon icon={faCog} />
          </Link>
          &nbsp;&nbsp;
          <Link to={"#"}>
            <FontAwesomeIcon
              icon={faClone}
              onClick={() => duplicateEvents(event.id, "ongoing")}
            />
          </Link>
        </>
      ),
    }));

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <div className="row">
            <div className="col-md-12">
              <Link
                className="btn pink darken-1 float-right"
                to="/admin/events/edit"
              >
                Nouvel événement
              </Link>
            </div>
          </div>
          <br />
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={selectedTable}
              onChange={(e, value) => this.setState({ selectedTable: value })}
              indicatorColor="primary"
              sx={{
                '& .MuiTab-root.Mui-selected': {
                  color: '#d81b60',
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: '#d81b60',
                },
              }}
            >
              <Tab value="current" label="Événements en cours" />
              <Tab value="passed" label="Événements passés" />
              <Tab value="archived" label="Événements archivés" />
            </Tabs>
          </Box>

          {this.renderContent({
            names,
            ongoingEventssortable,
            duplicateOnGoingLoading,
            previousEventsortable,
            duplicatePreviousLoading,
            archivedEventsortable,
            duplicateArchivedLoading,
          })}


        </div>
      </div>
    );
  }
}

export default EventsList;
