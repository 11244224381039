import React from "react";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "mdbreact";
import { applyDrag } from "./utils";
import { Container, Draggable } from "react-smooth-dnd";
import { languagesList } from "../../../../common/Translations";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faPinterest,
  faStripe,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
// import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faAddressBook,
  faAddressCard,
  faBed,
  faBookmark,
  faBus,
  faBusAlt,
  faCalendarCheck,
  faCar,
  faCarSide,
  faCoffee,
  faCreditCard,
  faEuroSign,
  faGlassMartiniAlt,
  faHandPaper,
  faInfo,
  faInfoCircle,
  faMap,
  faMapMarkedAlt,
  faPhone,
  faPlane,
  faQuestionCircle,
  faShip,
  faTrain,
  faTrash,
  faUserFriends,
  faWheelchair,
} from "@fortawesome/free-solid-svg-icons";
import Multilingual from "../../../../common/Multilingual";
import "./MenuEditor.css";
// import { Fragment } from "react";
import SelectComponent from "../../../../inputComponent/SelectComponent";
import { SelectIconComponent } from "../../../../inputComponent/SelectIconComponent";

library.add(faCar, faTrash);

const itemIcon = [
  {
    value: "",
    label: "Aucune Icone",
  },
  {
    value: "fa-car",
    label: <SelectIconComponent icon={faCar} title={"Voiture"} />,
  },
  {
    value: "fa-train",
    label: <SelectIconComponent icon={faTrain} title={"Train"} />,
  },
  {
    value: "fa-bus",
    label: <SelectIconComponent icon={faBus} title={"Bus"} />,
  },
  {
    value: "fa-ship",
    label: <SelectIconComponent icon={faShip} title={"Bateau"} />,
  },
  {
    value: "fa-car-side",
    label: <SelectIconComponent icon={faCarSide} title={"Automobile"} />,
  },
  {
    value: "fa-plane",
    label: <SelectIconComponent icon={faPlane} title={"Avion"} />,
  },
  {
    value: "fa-credit-card",
    label: (
      <SelectIconComponent icon={faCreditCard} title={"Carte de crédit"} />
    ),
  },
  {
    value: "fa-stripe",
    label: <SelectIconComponent icon={faStripe} title={"Stripe"} />,
  },
  {
    value: "fa-map-marked-alt",
    label: <SelectIconComponent icon={faMapMarkedAlt} title={"Lieux"} />,
  },
  {
    value: "fa-map",
    label: <SelectIconComponent icon={faMap} title={"Carte"} />,
  },
  {
    value: "fa-coffee",
    label: <SelectIconComponent icon={faCoffee} title={"Café"} />,
  },
  {
    value: "fa-address-book",
    label: <SelectIconComponent icon={faAddressBook} title={"Contact"} />,
  },
  {
    value: "fa-address-card",
    label: <SelectIconComponent icon={faAddressCard} title={"Badge"} />,
  },
  {
    value: "fa-phone",
    label: <SelectIconComponent icon={faPhone} title={"Téléphone"} />,
  },
  {
    value: "fa-info",
    label: <SelectIconComponent icon={faInfo} title={"Infos"} />,
  },
  {
    value: "fa-bookmark",
    label: <SelectIconComponent icon={faBookmark} title={"Marque-page"} />,
  },
  {
    value: "fa-linkedin",
    label: <SelectIconComponent icon={faLinkedin} title={"Linkedin"} />,
  },
  {
    value: "fa-twitter-square",
    label: <SelectIconComponent icon={faTwitter} title={"Twitter"} />,
  },
  {
    value: "fa-facebook",
    label: <SelectIconComponent icon={faFacebook} title={"Facebook"} />,
  },
  {
    value: "fa-pinterest-square",
    label: <SelectIconComponent icon={faPinterest} title={"Pinterest"} />,
  },
  {
    value: "fa-youtube",
    label: <SelectIconComponent icon={faYoutube} title={"Youtube"} />,
  },
  {
    value: "fa-euro-sign",
    label: <SelectIconComponent icon={faEuroSign} title={"Paiements"} />,
  },
  {
    value: "fa-bus-alt",
    label: <SelectIconComponent icon={faBusAlt} title={"Transports"} />,
  },
  {
    value: "fa-map-marker-alt",
    label: <SelectIconComponent icon={faMapMarkedAlt} title={"Localisation"} />,
  },
  {
    value: "fa-wheelchair",
    label: <SelectIconComponent icon={faWheelchair} title={"Handicap"} />,
  },
  {
    value: "fa-bed",
    label: <SelectIconComponent icon={faBed} title={"Lit"} />,
  },
  {
    value: "fa-glass-martini-alt",
    label: <SelectIconComponent icon={faGlassMartiniAlt} title={"Verre"} />,
  },
  {
    value: "fa-info-circle",
    label: (
      <SelectIconComponent icon={faInfoCircle} title={"Infos (cercle) "} />
    ),
  },
  {
    value: "fa-calendar-check",
    label: <SelectIconComponent icon={faCalendarCheck} title={"Présence "} />,
  },
  {
    value: "fa-question-circle",
    label: <SelectIconComponent icon={faQuestionCircle} title={"Aide"} />,
  },
  {
    value: "fa-hand-paper",
    label: <SelectIconComponent icon={faHandPaper} title={"Attente"} />,
  },
  {
    value: "fa-users",
    label: (
      <SelectIconComponent icon={faUserFriends} title={"Réseaux sociaux"} />
    ),
  },
];
class MenuEditor extends React.Component {
  constructor(props) {
    super(props);
    this.addButton = this.addButton.bind(this);
    this.deleteitem = this.deleteitem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.minimizeFirstLetter = this.minimizeFirstLetter.bind(this);

    this.state = {
      modal: false,
      menu: this.props.menu.items,
      selectedIcon: "",
      componentListCopy: this.props.composantsList,
    };
  }

  toggle = () => {
    if (this.state.modal) {
      this.props.onSaveMenu({
        menu: { ...this.props.menu, items: { ...this.state.menu } },
      });
    }
    this.setState({
      modal: !this.state.modal,
    });
  };
  minimizeFirstLetter = (string) => {
    return string.charAt(0).toLowerCase() + string.slice(1);
  };
  handleChange = (id, e, label, type) => {
    const menu = this.state.menu.map((item) => {
      if (item.id === id) {
        switch (type) {
          case "icon":
            item.icon = e.value;
            break;
          case "lang":
            item.label = e;
            break;
          case "componentId":
            item.componentId = e.value;
            break;
          default:
            item.icon = e.value;
            break;
        }
        return item;
      } else {
        return { ...item };
      }
    });
    this.setState({
      menu,
    });
  };
  componentDidMount() {
    //Add value and label in each component object
    this.state.componentListCopy.map((component) => {
      component.label = component.data.name;
      component.value = component.id;
      return component;
    });
    const componentCopy = {
      label: "Lien vers composant",
      value: "",
      id: "",
      componentId: "",
      icon: "",
      data: {},
      type: "copy",
    };
    var copyState = [componentCopy, ...this.state.componentListCopy];
    this.setState({ componentListCopy: copyState });
  }
  componentDidUpdate() {}
  deleteitem = (key) => {
    const menu = this.state.menu.slice(0);
    menu.splice(key, 1);
    this.setState({ ...this.state, menu });
  };

  addButton = () => {
    this.setState({
      menu: [
        ...this.state.menu,
        {
          icon: "",
          label: languagesList,
          componentId: "",
          id: Date.now(),
        },
      ],
    });
  };

  render() {
    return (
      <div id="MenuEditor">
        <Button className="btn btn-block" onClick={this.toggle}>
          Modifier le menu
        </Button>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Modifier le menu</ModalHeader>
          <ModalBody>
            {" "}
            <Container
              dragHandleSelector=".column-drag-handle"
              groupName="0"
              getChildPayload={(i) => this.state.menu[i]}
              onDrop={(e) =>
                this.setState({ menu: applyDrag(this.state.menu, e) })
              }
            >
              {this.state.menu.map((item, key) => {
                return (
                  <>
                    <Draggable key={key}>
                      <div className="draggable-item">
                        <div className="row">
                          <div className="col-md-1 mt-1">
                            <span
                              className="column-drag-handle"
                              style={{ float: "left", padding: "0 10px" }}
                            >
                              &#x2630;
                            </span>
                          </div>
                          <div className="col-md-3 mt-1">
                            <div className="md-form">
                              <SelectComponent
                                // react select need value and label in object for the options
                                name="icon"
                                className="form-control browser-default custom-select select-component"
                                onChange={(e) =>
                                  this.handleChange(item.id, e, "", "icon")
                                }
                                options={itemIcon}
                                value={itemIcon.filter(
                                  ({ value }) => value === item.icon
                                )}
                                style={{ fontFamily: "FontAwesome" }}
                                maxMenuHeight={100}
                                placeHolder={"Aucune Icone"}
                                defaultValue={itemIcon[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-3 mt-1">
                            <Multilingual
                              lang={item.label}
                              type="input"
                              lg="12"
                              handleLang={(e) =>
                                this.handleChange(item.id, e, "label", "lang")
                              }
                            />
                          </div>
                          <div className="col-md-4 mt-1">
                            <div className="md-form">
                              <SelectComponent
                                name="componentId"
                                className="form-control browser-default custom-select select-component"
                                onChange={(e) =>
                                  this.handleChange(
                                    item.id,
                                    e,
                                    "",
                                    "componentId"
                                  )
                                }
                                options={this.state.componentListCopy}
                                value={this.state.componentListCopy.filter(
                                  ({ value }) => value === item.componentId
                                )}
                                maxMenuHeight={100}
                                placeHolder={"Lien vers composant"}
                                defaultValue={this.state.componentListCopy[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-1">
                            <div className="md-form">
                              <FontAwesomeIcon
                                onClick={() => this.deleteitem(key)}
                                icon={faTrash}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Draggable>
                  </>
                );
              })}
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.addButton}>
              Ajouter un bouton
            </Button>
            <Button color="secondary" onClick={this.toggle}>
              FERMER
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default MenuEditor;
