import React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./DelegatairePage.css";

function DelegateComponentTable({ delegataireComponents, getTotals }) {
  let { totalGuestsWithoutEntrance, totalQuotaWithoutEntrance } = getTotals(
    delegataireComponents
  );

  return (
    <TableContainer
      className="px-4 pb-5"
      component={Paper}
      sx={{ marginTop: "0.3rem", marginBottom: "2.3rem" }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Composants</TableCell>
            <TableCell align="left">Quotas</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {delegataireComponents.map((component) => {
            if (component.role === "entrance") return null;
            return (
              <TableRow
                key={component.id}
                className="bg-white"
                sx={{ "td, th": { border: 0, padding: "8px 5px 5px 16px" } }}
              >
                <TableCell component="th" scope="row">
                  {component.label}
                </TableCell>
                <TableCell align="left">{`${component.guestNumber} / ${component.quota}`}</TableCell>
              </TableRow>
            );
          })}

          {delegataireComponents.length > 0 && (
            <TableRow
              className="bg-white"
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left" component="th" scope="row">
                <span
                  className={
                    totalGuestsWithoutEntrance === 0
                      ? "quota-state quota-state-empty rounded font-weight-bold py-2 px-3"
                      : totalGuestsWithoutEntrance === totalQuotaWithoutEntrance
                      ? "quota-state quota-state-full rounded font-weight-bold text-white py-2 px-3"
                      : "quota-state quota-state-partial rounded font-weight-bold text-white py-2 px-3"
                  }
                >
                  {totalGuestsWithoutEntrance === 0
                    ? "Vide"
                    : totalGuestsWithoutEntrance === totalQuotaWithoutEntrance
                    ? "Complet"
                    : "Partiel"}
                </span>
              </TableCell>
              <TableCell className="font-weight-bold">
                {totalGuestsWithoutEntrance}/{totalQuotaWithoutEntrance}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

DelegateComponentTable.prototype = {
  componentsObject: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      max_invitation: PropTypes.number,
      guests_nbr: PropTypes.number,
    })
  ),
};

export default DelegateComponentTable;
