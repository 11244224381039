import React from "react";
import { Button, Modal, ModalBody, ModalFooter, MDBInput } from "mdbreact";
import Switch from "../../../../common/Switch";
import ApiService from "../../../../services/ApiService";
import {
  INITIAL_VALUE,
  // TOOL_NONE,
  // TOOL_NONE,
  fitSelection,
  fitToViewer,
} from "react-svg-pan-zoom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserMinus, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@mui/material/CircularProgress";
import Print from "./Print";
import {
  convertStylesStringToObject,
  filterElements,
  sortByLastName,
} from "../../../../services/Utils";
import Loader from "../../../../common/Loader";
import Pagination from "../../../../common/Pagination";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import ExportPlanExcel from "./ExportPlanExcel";
import ExportGuestsPlacement from "./ExportGuestsPlacement";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

class SvgContent extends React.Component {
  Viewer = null;

  constructor(props) {
    super(props);
    this.removeGuest = this.removeGuest.bind(this);
    this.addUser = this.addUser.bind(this);
    this.downLoadPdf = this.downLoadPdf.bind(this);
    this.filter = this.filter.bind(this);
    this.filterPackage = this.filterPackage.bind(this);
    this.filterDelegataire = this.filterDelegataire.bind(this);
    this.verifySeatAvailability = this.verifySeatAvailability.bind(this);
    this.changePage = this.changePage.bind(this);
    this.save = this.save.bind(this);
    this.svgContainerRef = React.createRef();
    this.parentWidth = 0;
    this.scaleSvg = 1;
    this.windowsWidh = window.innerWidth;

    this.handleChange = this.handleChange.bind(this);

    this.state = {
      currentPage: 0,
      guestSearchedId: null,

      totalPages: Math.ceil(
        this.props.guests.filter((guest) =>
          this.props.placement.data.seat
            ? !this.props.placement.data.seat.some(
                (item) => item.guestId === guest.id
              )
            : null
        ).length / numberPerPage
      ),
      checkinMessage: "",
      modal: false,
      guestsFiltered: this.props.guests,
      tables: [],
      isLoading: false,
      isLoadingModal: false,
      fakeLoader: false,
      data: this.props.placement.data,
      idsSelected: this.props.placement.data.seat,
      // tool: TOOL_NONE,
      value: INITIAL_VALUE,
      pdfToRender: null,
      companyFilter: "",
      nameFilter: "",
      componentFilter: "",
      tagsFilter: "",
      packagesFilter: "",
      delegatairesFilter: "",
      delegatairesList: [],
      delegatairesProps: this.filterDelegataireProps(),
      selectedSeats: [],
      selectedGuests: [],
      multiPlacement: false,
      actualScale: 1,
    };
  }

  handleScaleChange = (newScale) => {
    this.setState({ actualScale: newScale.state.scale });
  };

  handleChange(event) {
    const name = "guestSearchedId";
    const value = event ? event.value : null;

    const guest = this.state.idsSelected
      ? this.state.idsSelected.find((i) => i.guestId === value)
      : null;

    const html = guest ? document.getElementById(guest.seatId) : null;
    // console.log("guest : ", guest);
    // console.log("html : ", html);

    this.setState({
      [name]: value,
      value: html
        ? fitSelection(
            this.state.value,
            -200 + Number(html.getAttribute("x")),
            -100 + Number(html.getAttribute("y")),
            200,
            200
          )
        : fitToViewer(this.state.value),
    });
    if (!guest && value) {
      toast.warn("Invité non placé", { theme: "colored" });
    }
  }

  removeGuest(guestid) {
    if (window.confirm("Etes vous sûr de supprimer ce siège ?")) {
      const seatToDeleteIndex = this.state.idsSelected.findIndex(
        (item) => item.guestId === guestid
      );

      //! Add a isLoading
      if (seatToDeleteIndex >= 0) {
        const self = this;
        const seatToDelete = this.state.idsSelected[seatToDeleteIndex];
        ApiService.awaitRequest(
          {
            seats: [seatToDelete],
          },
          `placements/removeSeats/${this.props.placement.id}`,
          "put"
        )
          .then((data) => {
            if (!data.status) {
              alert(
                "Suppression non effectué car votre plan n'était pas à jour. Un autre invité différent du votre est placé sur ce siège."
              );
              self.setState({
                fakeLoader: true,
                idsSelected: data.seats,
              });
              setTimeout(() => {
                self.setState({
                  fakeLoader: false,
                });
              }, 1500);
            } else {
              self.setState({
                idsSelected: data.seats,
              });
            }
          })
          .catch((err) => {
            toast.error("Oups! Une erreur est survenue lors de la suppression");
            console.log(err);
          });
      }
    }
  }

  save(seats) {
    const self = this;

    this.setState({
      addUserButtonLoader: true,
    });
    ApiService.awaitRequest(
      {
        seats,
      },
      `placements/updateseats/${this.props.placement.id}`,
      "put",
      true
    )
      .then(function (data) {
        if (data && data.updateStatus) {
          self.setState({
            data: data.placementData,
            idsSelected: data.placementData.seat,
            //! isLoading: false,
          });
        } else {
          if (data && data.message) {
            if (data.placementData) {
              self.setState({
                data: data.placementData,
                idsSelected: data.placementData.seat,
              });
              alert("Placement non effectué car votre plan n'était pas à jour");
              self.setState({
                fakeLoader: true,
              });
              setTimeout(() => {
                self.setState({
                  fakeLoader: false,
                });
              }, 1500);
            }
          }
        }
      })
      .catch((err) => {
        toast.error("Oups! Une erreur c'est lors de l'affection des sièges");
        console.log(err);
      })
      .finally(() => {
        this.setState({
          modal: false,
          addUserButtonLoader: false,
          selectedSeats: [],
          selectedGuests: [],
        });
      });
  }

  changePage(page) {
    this.setState({ currentPage: page });
  }
  uniqByKey(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }
  filterDelegataireProps() {
    return this.props.delegatairesList
      ? JSON.parse(
          JSON.stringify(
            this.uniqByKey(this.props.delegatairesList, (el) => el.user_id)
          )
        ).sort(sortByLastName())
      : [];
  }

  filter() {
    const { nameFilter, companyFilter, tagsFilter, componentFilter } =
      this.state;

    const { guests, contacts, reponses, components } = this.props;

    let guestsFiltered = guests;

    if (nameFilter.length > 0) {
      guestsFiltered = guestsFiltered.filter((guest) => {
        const delegataire = this.state.delegatairesProps.find(
          (delegataire) => delegataire.user_id === guest.user_id
        );

        return (
          guest.firstname.toLowerCase().includes(nameFilter.toLowerCase()) ||
          guest.lastname.toLowerCase().includes(nameFilter.toLowerCase()) ||
          (delegataire
            ? delegataire.user.lastname
                .toLowerCase()
                .includes(nameFilter.toLowerCase()) ||
              delegataire.user.firstname
                .toLowerCase()
                .includes(nameFilter.toLowerCase())
            : false)
        );
      });
    }

    if (companyFilter.length > 0) {
      guestsFiltered = guestsFiltered.filter((guest) => {
        const contact = contacts.find(
          (contact) => contact.id === guest.contact_id
        );

        if (contact && contact.company) {
          return contact.company.name
            .toLowerCase()
            .includes(companyFilter.toLowerCase());
        } else return false;
      });
    }

    if (tagsFilter.length > 0) {
      guestsFiltered = guestsFiltered.filter((guest) => {
        const contact = this.props.contacts.find(
          (contact) => contact.id === guest.contact_id
        );

        return contact
          ? contact.tags.some((tag) =>
              tagsFilter.find((tagFilter) => tagFilter.value === tag.id)
            )
            ? guest
            : null
          : null;
      });
    }

    const component = components.find(
      (component) => component.id === Number(componentFilter)
    );

    if (componentFilter.length > 0 && component) {
      guestsFiltered = guestsFiltered.filter((guest) => {
        const reponse = reponses.find(
          (reponse) => reponse.guest_id === guest.id
        );

        const componentReponse =
          reponse && reponse.responses && reponse.responses.length > 0
            ? reponse.responses.find((item) => item.id === component.id)
            : false;

        if (componentReponse) {
          return (
            (component.data.reponse.htmlType === "radio" &&
              componentReponse.key !== 1) ||
            component.data.reponse.htmlType === "checkbox"
          );
        } else return false;
      });
    }

    this.setState({
      guestsFiltered,
      totalPages: Math.ceil(guestsFiltered.length / numberPerPage),
      currentPage: 0,
    });
  }

  downLoadPdf(pagesToPrint) {
    this.setState({
      pdfToRender: (
        <Print
          nameEvent={this.props.event.data.name}
          namePlacement={this.props.placement.name}
          pagesToPrint={pagesToPrint}
          mainPicture={this.props.placement.main_picture}
          setPdfToRender={() => this.setState({ pdfToRender: null })}
        />
      ),
    });
  }

  addUser() {
    try {
      const { selectedSeats, selectedGuests } = this.state;
      const seatsToAllocate = [];
      for (let i = 0; i < selectedSeats.length; i++) {
        if (selectedGuests[i] && selectedSeats[i].idSeat) {
          seatsToAllocate.push({
            guestId: selectedGuests[i],
            seatId: selectedSeats[i].idSeat,
            checkinMessage: selectedSeats[i].checkinMessage,
          });
        }
      }

      // idsSelected.push({ guestId, seatId: idSelected, checkinMessage });
      this.save(seatsToAllocate);
    } catch (error) {
      console.log(error);
    }
  }

  async verifySeatAvailability(seatId) {
    this.setState({ isLoadingModal: true });
    const self = this;
    await ApiService.awaitRequest(
      {},
      `placements/available/${this.props.placement.id}/${seatId}`,
      "get"
    )
      .then((data) => {
        self.setState({
          isLoadingModal: false,
        });
        if (data && !data.isSeatAvailable) {
          self.setState({
            modal: false,
            data: data.placementData,
            idsSelected: data.placementData.seat,
            selectedSeats: [],
            selectedGuests: [],
          });

          alert("Placement non effectué car votre plan n'était pas à jour");
          self.setState({
            fakeLoader: true,
          });
          setTimeout(() => {
            self.setState({
              fakeLoader: false,
            });
          }, 1500);
        }
      })
      .catch((e) => {
        this.setState({
          isLoadingModal: false,
          selectedSeats: [],
          selectedGuests: [],
        });
        console.log(e);
      });
  }

  // changeTool(nextTool) {
  //   this.setState({ tool: nextTool });
  // }

  // changeValue(nextValue) {
  //   this.setState({ value: nextValue });
  // }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      selectedGuests: [],
      selectedSeats: [],
    });
  };

  handleSwitch = () => {
    this.setState({
      multiPlacement: !this.state.multiPlacement,
      selectedGuests: [],
      selectedSeats: [],
    });
  };

  filterPackage = (e) => {
    var guestsFiltered = "";
    if (e.target.value !== "") {
      guestsFiltered = this.props.guests.filter((guest) => {
        return guest.package_id
          ? guest.package_id === Number(e.target.value)
            ? guest
            : ""
          : "";
      });
    } else {
      guestsFiltered = this.props.guests;
    }
    this.setState({ packagesFilter: e.target.value, guestsFiltered });
  };

  filterDelegataire = (e) => {
    var guestsFiltered = "";
    if (e.target.value !== "") {
      guestsFiltered = this.props.guests.filter((guest) => {
        if (guest.user_id) {
          return this.state.delegatairesProps.find(
            (delegataire) =>
              delegataire.user_id === guest.user_id &&
              delegataire.user_id === Number(e.target.value)
          );
        } else return false;
      });
    } else {
      guestsFiltered = this.props.guests;
    }
    this.setState({ delegatairesFilter: e.target.value, guestsFiltered });
  };

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  componentDidMount() {
    if (this.svgContainerRef.current) {
      this.parentWidth = this.svgContainerRef.current.clientWidth;
    }
  }

  render() {
    const div = document.createElement("div");
    const {
      placement,
      contacts,
      tags,
      components,
      guests,
      packagesList,
      delegatairesList,
    } = this.props;
    const {
      data,
      idsSelected,
      modal,
      // tool,
      // value,
      pdfToRender,
      nameFilter,
      componentFilter,
      packagesFilter,
      delegatairesFilter,
      guestsFiltered,
      companyFilter,
      currentPage,
      totalPages,
      isLoading,
      guestSearchedId,
      selectedSeats,
      selectedGuests,
      multiPlacement,
      isLoadingModal,
      fakeLoader,
      addUserButtonLoader,
      actualScale,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const { svg_content, available_seat, name: planName } = placement;
    div.innerHTML = svg_content;
    let viewBoxMatch = "";
    let widthMatch = "";
    let heightMatch = "";

    if (svg_content) {
      viewBoxMatch = svg_content.match(/viewBox="([^"]+)"/);
      widthMatch = svg_content.match(/width="([^"]+)"/);
      heightMatch = svg_content.match(/height="([^"]+)"/);
    }

    let width;
    // let height;

    if (viewBoxMatch) {
      const viewBox = viewBoxMatch[1].split(" ");
      width = viewBox[2];
      // height = viewBox[3];
    } else if (widthMatch && heightMatch) {
      width = widthMatch[1];
      // height = heightMatch[1];
    } else {
      console.log("Impossible de récupérer la largeur et la hauteur.");
    }

    const plans = div.querySelectorAll("[id^=plan]");
    // console.log(plans)
    const pagesToPrint = [];

    let rectangles = [];
    let idSplitRanger = "";
    let idSplitPlace = "";
    let idSplitZone = "";

    // let fontSize;

    plans.forEach((plan, keyplan) => {
      const table = Array.prototype.slice.call(plan.querySelectorAll("text"));

      var title = table.length > 0 ? table[0].textContent : plan.id;
      title = isNaN(title) ? title : "";
      const planRectangles = Array.prototype.slice.call(
        plan.querySelectorAll("rect,text")
      );
      let highx = 0;
      let highy = 0;
      let lowx = 100000;
      let lowy = 100000;

      const elements = planRectangles.map((rectangle, key) => {
        let delegataire = "";
        let companyName = "";

        const attributes = Array.prototype.slice.call(rectangle.attributes);
        if (!attributes.find((attribute) => attribute.name === "id")) {
          if (rectangle.nodeName === "rect") {
            return "";
          }
        }
        var replaceIdText =
          rectangle.nodeName === "text"
            ? rectangle.textContent.replace(" ", "_")
            : null;
        const id =
          rectangle.nodeName === "rect"
            ? attributes.find((attribute) => attribute.name === "id")
              ? attributes.find((attribute) => attribute.name === "id")
                  .nodeValue
              : ""
            : "Text_" + replaceIdText;

        const disable =
          rectangle.nodeName === "rect"
            ? attributes.some((attribute) => attribute.name === "data-disabled")
            : null;

        const seat = idsSelected
          ? idsSelected.find((item) => item.seatId === id)
          : null;

        const guest = seat
          ? guests.find((guest) => guest.id === seat.guestId)
          : null;

        const x =
          rectangle.nodeName === "rect"
            ? attributes.find((attribute) => attribute.name === "x")
              ? Number(
                  attributes.find((attribute) => attribute.name === "x")
                    .nodeValue
                )
              : null
            : Number(
                attributes
                  .find((attribute) => attribute.name === "transform")
                  .nodeValue.match(/matrix.*\((.+)\)/)[1]
                  .split(" ")[4]
              );
        const y =
          rectangle.nodeName === "rect"
            ? attributes.find((attribute) => attribute.name === "y")
              ? Number(
                  attributes.find((attribute) => attribute.name === "y")
                    .nodeValue
                )
              : null
            : Number(
                attributes
                  .find((attribute) => attribute.name === "transform")
                  .nodeValue.match(/matrix.*\((.+)\)/)[1]
                  .split(" ")[5]
              );

        var style =
          rectangle.nodeName === "rect"
            ? attributes.find((attribute) => attribute.name === "style")
              ? attributes.find((attribute) => attribute.name === "style")
                  .nodeValue
              : null
            : convertStylesStringToObject(
                attributes.find((attribute) => attribute.name === "style")
                  .nodeValue
              );

        const dataMessage =
          rectangle.nodeName === "rect"
            ? attributes.some((attribute) => attribute.name === "data-message")
              ? attributes.find(
                  (attribute) => attribute.name === "data-message"
                ).nodeValue
              : null
            : null;

        if (x > highx) {
          highx = x;
        }

        if (x < lowx) {
          lowx = x;
        }

        if (y > highy) {
          highy = y;
        }

        if (y < lowy) {
          lowy = y;
        }

        const width =
          rectangle.nodeName === "rect"
            ? attributes.find((attribute) => attribute.name === "width")
              ? Number(
                  attributes.find((attribute) => attribute.name === "width")
                    .nodeValue
                )
              : null
            : "";
        const height =
          rectangle.nodeName === "rect"
            ? attributes.find((attribute) => attribute.name === "height")
              ? Number(
                  attributes.find((attribute) => attribute.name === "height")
                    .nodeValue
                )
              : null
            : "";

        if (rectangle.nodeName === "rect") {
          if (id) {
            idSplitZone = id.substr(0, id.indexOf("_r"));
            idSplitRanger = id.substring(
              id.lastIndexOf("r") + 1,
              id.lastIndexOf("_p")
            );
            idSplitPlace = id.split("_")[3] // Permet de supprimer le _i à l'affichage s'il existe
              ? id.substring(id.lastIndexOf("p") + 1, id.lastIndexOf("_"))
              : id.substring(id.lastIndexOf("p") + 1, id.lastIndexOf(""));
          }

          if (guest && guest.user_id) {
            const delegataireOption = delegatairesList.find(
              (option) => option.user_id === guest.user_id
            );
            if (delegataireOption) {
              delegataire =
                "<br/> Délégataire : " +
                delegataireOption.user.firstname +
                " " +
                delegataireOption.user.lastname;
            }
          }

          if (guest && guest.contact_id) {
            const companyNameOption = contacts.find(
              (option) => option.id === guest.contact_id
            );
            if (companyNameOption && companyNameOption.company) {
              companyName = "<br/> Société : " + companyNameOption.company.name;
            }
          }
        }
        return rectangle.nodeName === "rect" ? (
          <rect
            key={String(keyplan) + String(key)}
            style={{ zIndex: 1 }}
            lastname={guest ? guest.lastname : ""}
            firstname={guest ? guest.firstname : ""}
            data-for="tooltip"
            data-html="true"
            data-tip={
              guest
                ? `${guest.lastname} ${guest.firstname} ${delegataire} ${companyName} <br/> Zone : ${idSplitZone} <br/> Rangée : ${idSplitRanger} <br/> Place : ${idSplitPlace}`
                : `Zone : ${idSplitZone} <br/> Rangée : ${idSplitRanger} <br/> Place : ${idSplitPlace}`
            }
            fill={
              disable
                ? "#454546"
                : guests.some((guest) => guest.seatId === id)
                ? "#FF0000" // Rouge si le siège est associé à un invité dans les données de l'API
                : selectedSeats.some((seat) => seat.idSeat === id)
                ? "#2bbbad" // Vert si le siège est sélectionné localement
                : guest
                ? seat
                  ? guestSearchedId === guest.id
                    ? "#FFD700" // Jaune si le siège correspond à l'invité recherché
                    : "#dc2077" // Autre couleur si le siège est invité, mais pas sélectionné
                  : "#7A7B7E" // Autre couleur si le siège est invité, mais pas sélectionné
                : String(style.slice(5, 12)) === "#FFFFFF"
                ? "#7A7B7E" // Autre couleur si le style correspond à une tranche de couleur
                : String(style.slice(5, 12))
            }
            stroke={"#616264"}
            strokeWidth={"1px"}
            onDoubleClick={() => {
              if (!disable) {
                if (guest) {
                  this.removeGuest(guest.id);
                } else {
                  if (multiPlacement) {
                    // Quand le placement multiple est activé

                    const isSeatAlreadySelected = selectedSeats.some(
                      (seat) => seat.idSeat === id
                    );

                    if (!isSeatAlreadySelected) {
                      // Si le siège n'est pas déjà sélectionné
                      selectedSeats.push({
                        idSeat: id,
                        checkinMessage: dataMessage,
                      });
                      this.setState({ modal: false });
                    } else {
                      const index = selectedSeats.findIndex(
                        (seat) => seat.idSeat === id
                      );
                      if (index !== -1) {
                        selectedSeats.splice(index, 1); // Retire un élément à l'index trouvé
                        this.setState({ modal: false });
                      }
                    }
                  } else {
                    selectedSeats.push({
                      idSeat: id,
                      checkinMessage: dataMessage,
                    });
                    this.setState({ modal: true });
                    this.verifySeatAvailability(id);
                  }
                }
              }
            }}
            id={id}
            data-message={dataMessage}
            x={x}
            y={y}
            width={width}
            height={height}
          />
        ) : (
          <text
            id={replaceIdText}
            x={x}
            y={y}
            style={style}
            text={rectangle.textContent}
          >
            {rectangle.textContent}
          </text>
        );
      });

      let fontSize;
      elements.map((rectangle, key) => {
        if (rectangle.type === "rect") {
          if (rectangle.props.lastname !== "") {
            // Fonction pour calculer la taille de la police en fonction de la longueur du texte
            const calculateFontSize = (lastname, firstname, width, height) => {
              let scaleFactor = 1;
              const textLength = Math.max(lastname.length, firstname.length);
              let maxWidthFontSize = width / textLength;
              if (maxWidthFontSize > 4) return 4;
              if (maxWidthFontSize > scaleFactor) return maxWidthFontSize + 1;
              return scaleFactor;
            };

            fontSize = calculateFontSize(
              rectangle.props.lastname,
              rectangle.props.firstname,
              rectangle.props.width,
              rectangle.props.height
            );
            let styleDisplay = "none";
            if (actualScale > 3) {
              styleDisplay = "block";
            }

            elements.push(
              <text
                key={`nomInRectangle_${key}`}
                y={
                  rectangle.props.y +
                  rectangle.props.height / 2 -
                  fontSize * 1.7
                }
                x={rectangle.props.x + rectangle.props.width / 2}
                style={{
                  fontSize: `${fontSize}px`,
                  fontWeight: "bold",
                  display: `${styleDisplay}`,
                }}
                textAnchor="middle"
                dominantBaseline="middle"
                pointerEvents={"none"}
              >
                <tspan
                  x={rectangle.props.x + rectangle.props.width / 2}
                  dy="1.2em"
                >
                  {rectangle.props.lastname}
                </tspan>
                <tspan
                  x={rectangle.props.x + rectangle.props.width / 2}
                  dy="1.2em"
                >
                  {rectangle.props.firstname}
                </tspan>
              </text>
            );
          }
        }
        return null;
      });

      rectangles = rectangles.concat(elements);

      pagesToPrint.push({
        elements,
        highx,
        highy,
        lowx,
        lowy,
        id: title,
      });
    });
    const maxY = Math.max(...rectangles.map((rectangle) => rectangle.props.y));

    let tailleWidth;
    let tailleHeigth = maxY;
    if (this.windowsWidh !== 0) {
      tailleWidth = (this.windowsWidh / 12) * 10;
      this.scaleSvg = ((this.windowsWidh / 12) * 10) / width;
      tailleHeigth = tailleHeigth * this.scaleSvg + 50;
    }

    const svg = (
      <svg
        id="svgdocument"
        width={tailleWidth}
        height={tailleHeigth}
        x={"0px"}
        y="0px"
        // style={{
        //  position: "relative",
        //   top: "50%",
        //   left: "50%",
        //   margin: "-25px 0 0 -25px",
        // }}
      >
        {rectangles.map((rectangle) => rectangle)}
      </svg>
    );

    return (
      <div id="svg-container" ref={this.svgContainerRef}>
        <ReactTooltip id="tooltip" getContent={(dataTip) => dataTip} />
        {pdfToRender}
        <Modal isOpen={modal} toggle={this.toggle} size="lg">
          {isLoadingModal ? (
            <Loader />
          ) : (
            <ModalBody style={{ padding: "10px 15px" }}>
              <div style={{ backgroundColor: "#F2F2F2" }}>
                <div
                  className="row ml-0 mr-0"
                  style={{ backgroundColor: "white", padding: "0px 10px" }}
                >
                  <div className="col-md-12" style={{ fontWeight: "bold" }}>
                    <p className="text-center m-0">
                      Filtrer les participants à placer
                    </p>
                  </div>
                  <div className="col-md-6">
                    <MDBInput
                      type="text"
                      value={nameFilter}
                      name="nameFilter"
                      label={"Filtrer par nom"}
                      onChange={(e) =>
                        filterElements(e, "nameFilter", this, false)
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <MDBInput
                      type="text"
                      value={companyFilter}
                      name="companyFilter"
                      label={"Filtrer par société"}
                      onChange={(e) =>
                        filterElements(e, "companyFilter", this, true)
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <Select
                      placeholder={"Filtrer par tags"}
                      onChange={(e) =>
                        filterElements(e, "tagsFilter", this, true)
                      }
                      options={tags.map((tag) => ({
                        label: tag.tag_name,
                        value: tag.id,
                      }))}
                      isMulti={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <select
                      required
                      onChange={(e) =>
                        filterElements(e, "componentFilter", this, true)
                      }
                      name="componentFilter"
                      className="form-control browser-default custom-select"
                      value={componentFilter}
                    >
                      <option value={null}>Filtrer par composant</option>
                      {components.map((option, key) => (
                        <option key={key} value={option.id}>
                          {option.data.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <br></br>
                  <div className="col-md-6" style={{ marginTop: "10px" }}>
                    <select
                      required
                      onChange={(e) => this.filterPackage(e)}
                      name="packagesFilter"
                      className="form-control browser-default custom-select"
                      value={packagesFilter}
                    >
                      <option value="">Filtrer par package</option>
                      {packagesList.map((option, key) => (
                        <option key={key} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6" style={{ marginTop: "10px" }}>
                    <select
                      required
                      onChange={(e) => this.filterDelegataire(e)}
                      name="packagesFilter"
                      className="form-control browser-default custom-select"
                      value={delegatairesFilter}
                    >
                      <option value="">Filtrer par délégataire</option>
                      {this.state.delegatairesProps.map((delegataire, key) => (
                        <option key={key} value={delegataire.user_id}>
                          {delegataire.user.lastname}{" "}
                          {delegataire.user.firstname}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* Compteur invité/nombre places */}
                  <div className="col-md-12" style={{ marginTop: "10px" }}>
                    <p className="text-center m-0">
                      Nombre de participants à placer : <br />
                      <span
                        className="font-weight-bold"
                        style={{ fontSize: "20px" }}
                      >
                        {selectedGuests.length}/{selectedSeats.length}
                      </span>
                    </p>
                  </div>
                </div>
                <table className="table table-sm table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Nom</th>

                      <th scope="col">Société</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {guestsFiltered
                      .filter((guest) =>
                        idsSelected
                          ? !idsSelected.some(
                              (item) => item.guestId === guest.id
                            )
                          : null
                      )
                      .map((guest, i) => {
                        if (
                          i < numberPerPage * (currentPage + 1) &&
                          i >= numberPerPage * currentPage
                        ) {
                          const contact = contacts.find(
                            (contact) => contact.id === guest.contact_id
                          );
                          return (
                            <tr
                              key={i}
                              className={
                                selectedGuests.includes(guest.id)
                                  ? "bg-default"
                                  : ""
                              }
                            >
                              <td>
                                {guest.lastname} {guest.firstname}{" "}
                                <span
                                  style={{
                                    fontStyle: "italic",
                                    fontSize: "12px",
                                  }}
                                >
                                  <br />
                                  {guest.user_id &&
                                  this.state.delegatairesProps.some(
                                    (delegataire) =>
                                      delegataire.user_id === guest.user_id &&
                                      delegataire.user.firstname != null
                                  )
                                    ? "( invité par le délégataire " +
                                      this.state.delegatairesProps.find(
                                        (delegataire) =>
                                          delegataire.user_id === guest.user_id
                                      ).user.firstname +
                                      " " +
                                      this.state.delegatairesProps.find(
                                        (delegataire) =>
                                          delegataire.user_id === guest.user_id
                                      ).user.lastname +
                                      " )"
                                    : !guest.invited_by
                                    ? guest.email
                                    : "( Accompagnant de " +
                                      guest.invited_by.firstname +
                                      " " +
                                      guest.invited_by.lastname +
                                      ")"}
                                </span>
                              </td>
                              <td>
                                {contact && contact.company
                                  ? contact.company.name
                                  : ""}
                              </td>
                              <td>
                                {selectedGuests.length < selectedSeats.length &&
                                  !selectedGuests.includes(guest.id) && (
                                    <FontAwesomeIcon
                                      onClick={() => {
                                        selectedGuests.push(guest.id);
                                        this.setState({ selectedGuests });
                                        if (!multiPlacement) {
                                          this.addUser();
                                        }
                                      }}
                                      icon={faUserPlus}
                                    />
                                  )}
                                {selectedGuests.includes(guest.id) && (
                                  <FontAwesomeIcon
                                    onClick={() => {
                                      const updatedSelectedGuests =
                                        selectedGuests.filter(
                                          (id) => id !== guest.id
                                        );
                                      this.setState({
                                        selectedGuests: updatedSelectedGuests,
                                      });
                                    }}
                                    icon={faUserMinus}
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        } else return null;
                      })}
                  </tbody>
                </table>
                <Pagination
                  changePage={this.changePage}
                  currentPage={currentPage}
                  totalPages={totalPages}
                />
              </div>
            </ModalBody>
          )}
          <ModalFooter>
            {multiPlacement && !isLoadingModal ? (
              <>
                {addUserButtonLoader ? (
                  <CircularProgress
                    size={30}
                    sx={{ color: "#d81b60", marginRight: "2rem" }}
                  />
                ) : (
                  <Button
                    className="btn white-text"
                    onClick={this.addUser}
                    disabled={selectedGuests.length === 0 || isLoadingModal}
                  >
                    Valider
                  </Button>
                )}

                <Button
                  className="btn pink darken-1 white-text"
                  onClick={this.toggle}
                  disabled={isLoadingModal || addUserButtonLoader}
                >
                  Annuler
                </Button>
              </>
            ) : null}
          </ModalFooter>
        </Modal>
        <div className="row justify-content-end" style={{ marginRight: 0 }}>
          <div className="col-md-3">
            <div
              className="form-group"
              style={{ position: "relative", zIndex: "2", width: "90%" }}
            >
              <label>Rechercher un invité</label>
              <Select
                isClearable
                options={guests.map((guest) => ({
                  label: guest.lastname + " " + guest.firstname,
                  value: guest.id,
                }))}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className={"col-md-3"}>
            <p className={"white metric-panel"}>
              NOMBRE DE PLACES
              <br />
              <span className="font-weight-bold" style={{ fontSize: "30px" }}>
                {available_seat}
              </span>
            </p>
          </div>
          <div className={"col-md-3"}>
            <p className={"white metric-panel"}>
              PLACES RESTANTES
              <br />
              <span className="font-weight-bold" style={{ fontSize: "30px" }}>
                {idsSelected
                  ? available_seat - idsSelected.length
                  : available_seat}
              </span>
            </p>
          </div>
          <div className={"col-md-3"}>
            <p className={"white metric-panel"}>
              PARTICIPANTS RESTANTS À PLACER
              <br />
              <span className="font-weight-bold" style={{ fontSize: "30px" }}>
                {
                  guests.filter((guest) =>
                    data.seat
                      ? !data.seat.some((item) => item.guestId === guest.id)
                      : null
                  ).length
                }
              </span>
            </p>
          </div>
          <div
            className="col-md-12"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div className={"d-flex align-items-center"}>
              <Switch
                onChange={this.handleSwitch}
                checked={this.state.multiPlacement}
                id="activate-multi-placement"
                label="Activer le placement multiple ?"
              />
            </div>
            {multiPlacement === true && (
              <div className={""}>
                <Button
                  onClick={() =>
                    selectedSeats.length > 0
                      ? this.setState({
                          modal: true,
                        })
                      : null
                  }
                >
                  {`Placer`}
                </Button>
              </div>
            )}
            <div className={""}>
              <Button
                onClick={() => this.downLoadPdf(pagesToPrint)}
                className="btn pink darken-1 "
              >
                Télécharger
              </Button>
            </div>
            <div className={""}>
              <ExportGuestsPlacement
                nameEvent={this.props.event.data.name}
                namePlacement={this.props.placement.name}
                matchId={this.props.match.params.eventid}
                guests={guests.filter((guest) =>
                  data.seat
                    ? data.seat.some((item) => item.guestId === guest.id)
                    : null
                )}
                data={data}
                contacts={contacts}
              />
            </div>

            <div className={""}>
              <ExportPlanExcel
                rectangles={rectangles}
                nameEvent={this.props.event.data.name}
                namePlacement={this.props.placement.name}
                planName={planName}
              />
            </div>
          </div>
        </div>

        {this.props.placement.svg_content ? (
          fakeLoader ? (
            <Loader />
          ) : (
            <>
              <TransformWrapper
                initialScale={this.scaleSvg}
                initialPositionX={0}
                initialPositionY={0}
                doubleClick={{ disabled: true }}
                minScale={this.scaleSvg - 0.3}
                maxScale={25}
                onZoom={this.handleScaleChange}
              >
                {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
                  <React.Fragment>
                    <div
                      className="tools"
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        padding: "10px",
                        backgroundColor: "white",
                      }}
                    >
                      <button
                        onClick={() => zoomIn()}
                        style={{
                          width: "45px",
                          height: "30px",
                          border: "none",
                          borderRadius: "5px",
                          marginBottom: "5px",
                          marginRight: "10px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        +
                      </button>
                      <button
                        onClick={() => zoomOut()}
                        style={{
                          width: "45px",
                          height: "30px",
                          border: "none",
                          borderRadius: "5px",
                          marginBottom: "5px",
                          marginRight: "10px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        -
                      </button>
                      <button
                        onClick={() => resetTransform()}
                        style={{
                          width: "100px",
                          height: "30px",
                          border: "none",
                          borderRadius: "5px",
                          marginBottom: "5px",
                          marginRight: "10px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        Réinitialiser
                      </button>
                    </div>
                    <div
                      style={{
                        backgroundColor: "white",
                        justifyContent: "center",
                        display: "flex",
                        position: "relative",
                      }}
                    >
                      <TransformComponent>{svg}</TransformComponent>
                    </div>
                  </React.Fragment>
                )}
              </TransformWrapper>
            </>
          )
        ) : (
          <h4
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20%",
            }}
          >
            Aucun plan importé
          </h4>
        )}
      </div>
    );
  }
}
export default SvgContent;

const numberPerPage = 8;
