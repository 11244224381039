import { Paper } from "@mui/material";
import React from "react";

export function StatBoxWithTitle({ title, stat, elevation = 0 }) {
  return (
    <Paper
      elevation={elevation}
      sx={{
        bgcolor: "#ffffff",
        padding: "1rem 1rem 0rem 1rem",
        borderRadius: "0.875rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "space-between",
        maxWidth: "220px",
        // maxWidth: "250px",
        width: "100%",
      }}
    >
      <p style={{ fontSize: "0.875rem" }}>{title}</p>
      <p
        style={{
          textAlign: "end",
          fontSize: "1.625rem",
          fontWeight: 500,
        }}
      >
        {stat}
      </p>
    </Paper>
  );
}
