import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "mdbreact";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { generateInvoice } from "./generateInvoicePdf";
import { MDBSpinner } from "mdbreact";

class InvoicesPdfsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      isDownloaded: false,
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  formatName = (name) => {
    if (!name) return "";
    return name.toLowerCase().replace(/\s+/g, " ").trim().replaceAll(" ", "-");
  };

  getInvoices = async (contactInvoice, zip) => {
    const promRes = await Promise.all(
      contactInvoice.map(async (invoice, i) => {
        const invoiceFolder = zip.folder(this.formatName(`factures`));
        const pdf = await generateInvoice(invoice);
        const invoiceName =
          invoice.invoice_number + "-" + invoice.data.lastname ||
          `${invoice.data.firstname}-${invoice.data.lastname}-${i}`;
        if (pdf) {
          invoiceFolder.file(
            this.formatName(invoiceName + ".pdf"),
            pdf.output("blob")
          );
        }
      })
    );
    return promRes;
  };

  exportInvoicesPDF = async (contactInvoice, part) => {
    const zip = new JSZip();
    this.setState({
      isDownloaded: true,
    });
    try {
      this.getInvoices(contactInvoice, zip)
        .then(() => {
          zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(
              content,
              `factures-contacts-event-${contactInvoice[0].evenement_id}-part-${part}.zip`
            );
          });
        })
        .then(() => {
          this.setState({
            isDownloaded: false,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { invoices, eventFilter } = this.props;
    const sousTableauxIds = [];

    for (let i = 0; i < invoices.length; i += 500) {
      const sousTableau = invoices.slice(i, i + 500);
      const tableautest = [];
      sousTableau.forEach((element) => {
        if (element.invoice_number.slice(-8) !== "PROFORMA") {
          tableautest.push(element);
        }
      });
      sousTableauxIds.push(tableautest);
    }

    return (
      <span>
        <Button
          className="btn pink darken-1"
          onClick={this.toggle}
          disabled={
            eventFilter !== "" && eventFilter !== null && invoices.length !== 0
              ? false
              : true
          }
        >
          {invoices.length > 0
            ? eventFilter !== "" && eventFilter !== null
              ? "Télécharger les factures (Pdf)"
              : "Sélectionner un évènement (Facture Pdf)"
            : "Aucune facture (Pdf)"}
        </Button>

        {this.state.modal && (
          <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>Liste des factures</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md-12 text-center">
                  <span>Téléchargement en plusieurs parties :</span>
                </div>
                {!this.state.isDownloaded ? (
                  sousTableauxIds.map((e, i) => (
                    <div className="col-md-12 text-center" key={i}>
                      <Button
                        disabled={this.state.isDownloaded}
                        className="w-50"
                        onClick={() => this.exportInvoicesPDF(e, i + 1)}
                      >
                        {e.length} PDF - Partie {i + 1}
                      </Button>
                    </div>
                  ))
                ) : (
                  <div className="col-md-12 text-center mt-2">
                    <MDBSpinner big red />
                  </div>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="default" onClick={this.toggle}>
                Fermer
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </span>
    );
  }
}

export default InvoicesPdfsModal;
