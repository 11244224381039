import React from "react";
import ReactTags from "react-tag-autocomplete";
import "./ComposantForm.css";
import Multilingual from "../../../common/Multilingual";
import ApiService from "../../../services/ApiService";
import { MDBInput } from "mdbreact";
import Switch from "../../../common/Switch";
import { componentsTypesTranslation } from "../../../common/Translations";
import { languagesList } from "../../../common/Translations";

class ComposantForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeReponse = this.handleChangeReponse.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleLang = this.handleLang.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.resetEdits = this.resetEdits.bind(this);
    this._isMounted = false;

    const { data } = this.props;

    this.state = {
      componentsList: [],
      ...data,
      activateInCheckin: data.activateInCheckin || false,
    };
  }

  async componentDidMount() {
    const self = this;
    this._isMounted = true;
    if (self.props.match.params.eventid) {
      const eventsDetails = await ApiService.awaitRequest(
        {},
        "evenements/details/" + self.props.match.params.eventid,
        "get"
      );
      const componentsList = eventsDetails.components;
      self._isMounted &&
        self.setState({
          componentsList,
        });
    }

    await ApiService.request("", "component_tag/", "get").then(function (data) {
      const tags = data.map((tag) => {
        return {
          id: tag.id,
          name: tag.names,
          color: tag.color,
          icon_url: tag.icon_url,
        };
      });
      self._isMounted &&
        self.setState({
          reponse: {
            ...self.state.reponse,
            suggestions: tags,
          },
        });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleDelete(i) {
    const tags = this.state.reponse.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ reponse: { ...this.state.reponse, tags } });
  }

  handleLang(newLangSet, label) {
    this.setState({
      reponse: {
        ...this.state.reponse,
        [label]: newLangSet,
      },
    });
  }

  handleAddition(tag) {
    const tags = [].concat(this.state.reponse.tags, tag);
    this.setState({
      reponse: {
        ...this.state.reponse,
        tags,
      },
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { componentsList, ...data } = this.state;
    this.props.onSave({
      ...this.props.data,
      ...data,
    });
  }

  handleChangeReponse(event) {
    if (event.target.name === "htmlType") {
      this.setState({
        reponse: {
          ...this.state.reponse,
          [event.target.name]: event.target.value,
          options: languagesList,
        },
      });
      return;
    }

    this.setState({
      reponse: {
        ...this.state.reponse,
        [event.target.name]: event.target.value,
      },
    });
  }

  handleChange(event) {
    if (event.target.name === "componentType") {
      if (
        this.state.componentType !== "logistic" &&
        this.state.componentType !== "moment"
      ) {
        this.setState({
          // ...this.props.data,
          // ...this.props.initialComponentData,
          sartDate: this.props.initialComponentData.sartDate,
          endDate: this.props.initialComponentData.endDate,
          reponse: {
            ...this.state.reponse,
            options: languagesList,
            htmlType: "radio",
            mapIframe: "",
            isPreFilled: false,
            role: "standard",
            displayType: "list",
          },

          [event.target.name]: event.target.value,
        });
      } else {
        this.setState({
          ...this.props.data,
          [event.target.name]: event.target.value,
        });
      }
      return;
    }
    this.setState({ [event.target.name]: event.target.value });
  }

  resetEdits() {
    this.setState({
      ...this.state,
      ...this.props.data,
    });
  }

  handleDescriptionChange(description) {
    this.setState({
      reponse: {
        ...this.state.reponse,
        description,
      },
    });
  }

  handleSwitch(e) {
    this.setState({ activateInCheckin: e.target.checked });
  }

  render() {
    const {
      displayType,
      role,
      guestFieldRequired,
      htmlType,
      options,
      mapIframe,
      formWidth,
      tags,
      suggestions,
    } = this.state.reponse;
    const existingEntranceComponent = this.state.componentsList.find(
      (component) =>
        component.data.reponse.role === "entrance" &&
        component.id !== this.props.id
    );

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <br />
          <br />
          <form className="form" onSubmit={this.handleSubmit}>
            {/* zdc */}
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Nom</label>
                <input
                  name="name"
                  className="form-control"
                  value={this.state.name}
                  onChange={this.handleChange}
                  required
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Type de composant</label>
                <select
                  name="componentType"
                  className="form-control browser-default custom-select"
                  onChange={this.handleChange}
                  value={this.state.componentType}
                  required
                >
                  <option value="display">
                    {componentsTypesTranslation["FR"].display}
                  </option>
                  <option value="request">
                    {componentsTypesTranslation["FR"].request}
                  </option>
                  <option value="logistic">
                    {componentsTypesTranslation["FR"].logistic}
                  </option>
                  <option value="moment">
                    {componentsTypesTranslation["FR"].moment}
                  </option>
                  <option value="map">
                    {componentsTypesTranslation["FR"].map}
                  </option>
                </select>
              </div>
            </div>

            {this.state.componentType !== "map" ? (
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label>Rôle spécifique</label>
                  <select
                    name="role"
                    className="form-control browser-default custom-select"
                    onChange={this.handleChangeReponse}
                    value={role || ""}
                  >
                    <option value="standard">Standard</option>
                    <option
                      disabled={existingEntranceComponent}
                      value="entrance"
                    >
                      Entrée{" "}
                      {existingEntranceComponent
                        ? "(Composant actuel : " +
                          existingEntranceComponent.data.name +
                          ")"
                        : null}
                    </option>
                    <option value="invitation">Ajout d'accompagnants</option>
                    <option value="delegataire">Contact délégataire</option>
                  </select>
                </div>
              </div>
            ) : null}

            {role !== "invitation" && this.state.componentType !== "map" ? (
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label>Type d'affichage</label>
                  <select
                    name="displayType"
                    className="form-control browser-default custom-select"
                    onChange={this.handleChangeReponse}
                    value={displayType || ""}
                  >
                    <option value="list">Liste</option>
                    <option value="agenda">Calendrier</option>
                    <option value="modal">Pop-in</option>
                  </select>
                </div>
              </div>
            ) : null}
            {displayType === "list" ? (
              <>
                <div className="form-row">
                  {role !== "invitation" &&
                  this.state.componentType !== "map" ? (
                    <div className="form-group col-md-12">
                      <label>Type d'information demandée</label>
                      <select
                        name="guestFieldRequired"
                        className="form-control browser-default custom-select"
                        onChange={this.handleChangeReponse}
                        value={guestFieldRequired || ""}
                      >
                        <option value="">
                          Sélectionnez le type d'information
                        </option>
                        <option value="phone">Téléphone</option>
                      </select>
                    </div>
                  ) : null}
                </div>

                {this.props.type !== "display" ? (
                  <div className="form-row">
                    {this.state.componentType !== "map" ? (
                      <>
                        <div className="form-group col-md-12">
                          <label>Forme de réponse </label>
                          <select
                            name="htmlType"
                            onChange={this.handleChangeReponse}
                            className="form-control browser-default custom-select"
                            value={htmlType || ""}
                          >
                            <option value="radio">Option à choix unique</option>
                            {role !== "invitation" ? (
                              <>
                                <option value="checkbox">
                                  Case à cocher unique ou multiple
                                </option>
                                <option value="text">Saisie libre</option>
                              </>
                            ) : null}
                          </select>
                        </div>
                        {htmlType !== "text" ? (
                          <div className="form-group col-md-12">
                            <label>
                              Entrez ici les différentes options séparées par
                              des points virgules (ex : Option 1;Option 2;Option
                              3)
                            </label>
                            <Multilingual
                              eventId={this.props.match.params.eventid}
                              lang={options}
                              type="input"
                              handleLang={(e) => this.handleLang(e, "options")}
                              isOptionResponse = {true}
                            />
                          </div>
                        ) : null}
                      </>
                    ) : null}

                    {this.state.componentType === "map" ? (
                      <div className="form-group col-md-12">
                        <label>Source de l'Iframe pour la carte</label>
                        <textarea
                          type="text"
                          name="mapIframe"
                          onChange={this.handleChangeReponse}
                          className="form-control"
                          rows="3"
                          value={mapIframe || ""}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null}

                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label>Largeur de colonne dans le support</label>
                    <select
                      name="formWidth"
                      onChange={this.handleChangeReponse}
                      className="form-control browser-default custom-select"
                      value={formWidth || ""}
                    >
                      <option value="4">33%</option>
                      <option value="6">50%</option>
                      <option value="12">100%</option>
                    </select>
                  </div>
                </div>
              </>
            ) : null}
            {displayType !== "modal" && this.state.componentType !== "map" ? (
              <div className="form-row">
                <div className="form-group col-md-12">
                  <MDBInput
                    onClick={(e) =>
                      this.setState({
                        reponse: {
                          ...this.state.reponse,
                          isPreFilled: e.target.checked,
                        },
                      })
                    }
                    id={"isPreFilled"}
                    key={"isPreFilled"}
                    checked={this.state.reponse.isPreFilled}
                    label={"Options avec préselection par défaut"}
                    filled
                    type="checkbox"
                  />
                </div>
              </div>
            ) : null}

            {this.state.componentType === "moment" ||
            this.state.componentType === "logistic" ? (
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label>Thématiques</label>
                  <ReactTags
                    tags={tags}
                    placeholder={"Ajouter une thématique"}
                    suggestions={suggestions}
                    autofocus={false}
                    handleDelete={this.handleDelete.bind(this)}
                    handleAddition={this.handleAddition.bind(this)}
                  />
                </div>
              </div>
            ) : null}

            {this.props.canActivateComponentInCheckin &&
              role !== "invitation" &&
              (this.state.componentType === "logistic" ||
                this.state.componentType === "moment" ||
                this.state.componentType === "request") && (
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <Switch
                      onChange={this.handleSwitch}
                      checked={this.state.activateInCheckin}
                      id="activate-component-in-checkin"
                      label="Activer dans checkin ?"
                    />
                  </div>
                </div>
              )}

            <input
              type="submit"
              value="Enregistrer"
              className="btn pink darken-1 float-right white-text"
            />
            <button
              type="button"
              onClick={this.resetEdits}
              className="btn grey white-text"
            >
              Annuler les modifications
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default ComposantForm;
