import React from "react";
import { Button, Modal, ModalBody, ModalFooter, MDBInput } from "mdbreact";
import Loader from "../../../common/Loader";

class PriceRulesEditor extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      modal: false,
      rule: {
        lowRange: "",
        highRange: "",
        priceHT: "",
        priceTTC: "",
        tax: 20,
        followerType: "internal",
        followerSubType: "",
        componentId: "",
      },
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.mode === "follower"
      ? this.props.addFollowerPriceRule(this.state.rule)
      : this.props.addGuestPriceRule(this.state.rule);
    this.setState({
      modal: false,
      rule: {
        lowRange: "",
        highRange: "",
        priceHT: "",
        priceTTC: "",
        tax: 20,
        followerType: "internal",
        followerSubType: "",
        componentId: "",
      },
    });
    e.preventDefault();
  }

  handleChange(e) {
    let priceTTC = this.state.rule.priceTTC;

    if (e.target.name === "priceHT") {
      priceTTC = e.target.value * (1 + this.state.rule.tax / 100);
    } else if (e.target.name === "tax") {
      priceTTC = (1 + e.target.value / 100) * this.state.rule.priceHT;
    }

    //console.log("prix ttc", priceTTC);

    this.setState({
      rule: {
        ...this.state.rule,
        priceTTC: priceTTC ? parseFloat(priceTTC).toFixed(2) : null,
        [e.target.name]: e.target.value.replace(",", "."),
      },
    });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const {
      lowRange,
      highRange,
      priceHT,
      priceTTC,
      followerType,
      followerSubType,
      componentId,
      tax,
    } = this.state.rule;
    const { mode, event, dontUseSubTypePriceRule } = this.props;

    if (this.state.isLoading) {
      return <Loader />;
    }

    if (!dontUseSubTypePriceRule && followerType === "externalOrInternal") {
      // Si on avait sélectionné "externe ou interne" avant de cocher la case, on reset la variable followerType sinon cela gardait en mémoire externalOrInternal
      this.setState({
        rule: {
          ...this.state.rule,
          followerType: "internal",
        },
      });
    }

    return (
      <div>
        <Button className="btn pink darken-1 " onClick={this.toggle}>
          {mode === "guest"
            ? "Nouvelle règle invité"
            : "Nouvelle règle accompagnant"}
        </Button>
        <Modal
          isOpen={this.state.modal}
          fullHeight
          position="right"
          toggle={this.toggle}
        >
          <form className="form" onSubmit={this.handleSubmit}>
            <ModalBody>
              <div className="row">
                <div className="col-md-12 font-weight-bold">
                  {mode === "guest" ? "Règle invité" : "Règle accompagnant"}
                </div>
                <div className="col-md-6">
                  <MDBInput
                    label="Début tranche"
                    type="number"
                    value={lowRange}
                    name="lowRange"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <MDBInput
                    label="Fin tranche"
                    type="number"
                    value={highRange}
                    name="highRange"
                    onChange={this.handleChange}
                  />
                </div>
                {mode === "follower" ? (
                  <>
                    <div className="col-md-12">
                      <label>Type accompagnant</label>
                      <select
                        onChange={this.handleChange}
                        name="followerType"
                        className="form-control browser-default custom-select"
                        value={followerType}
                        required
                      >
                        {dontUseSubTypePriceRule ? (
                          <option value="externalOrInternal">
                            Interne ou externe
                          </option>
                        ) : (
                          ""
                        )}
                        <option value="internal">Interne</option>
                        <option value="external">Externe</option>
                      </select>
                    </div>
                    {dontUseSubTypePriceRule ||
                    followerType === "externalOrInternal" ? null : (
                      <div className="col-md-12">
                        <select
                          onChange={this.handleChange}
                          name="followerSubType"
                          className="form-control browser-default custom-select"
                          value={followerSubType}
                          required
                        >
                          <option
                            key="price-rules-editor-campanion-selection"
                            value=""
                          >
                            Sélectionner un type d'accompagnant
                          </option>
                          {event.data.guestsTypes.map((guestType, key) =>
                            followerType === guestType.type ? (
                              <option key={key} value={key}>
                                {guestType.name[0].value}
                              </option>
                            ) : null
                          )}
                        </select>
                      </div>
                    )}
                  </>
                ) : null}
                <div className="col-md-6">
                  <MDBInput
                    label="Prix(HT)"
                    type="number"
                    step="0.01"
                    value={priceHT}
                    name="priceHT"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <MDBInput
                    label="prix(TTC)"
                    type="number"
                    step="0.01"
                    value={priceTTC}
                    name="priceTTC"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label>Taux de TVA</label>
                  <select
                    onChange={this.handleChange}
                    name="tax"
                    className="form-control browser-default custom-select"
                    value={tax}
                  >
                    <option value="0">0%</option>
                    <option value="5.5">5,5%</option>
                    <option value="10">10%</option>
                    <option value="20">20%</option>
                  </select>
                </div>
                <div className="col-md-12">
                  <label>Composant</label>
                  <select
                    onChange={this.handleChange}
                    name="componentId"
                    className="form-control browser-default custom-select"
                    value={componentId}
                    required
                  >
                    <option
                      key="price-rules-editor-campanion-selection2"
                      value=""
                    >
                      Sélectionner un composant
                    </option>
                    {this.props.eventComponents.map((composant, key) => (
                      <option key={key} value={composant.id}>
                        {composant.data.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                className="btn pink darken-1 "
                onClick={this.create}
              >
                Valider
              </Button>
              <Button className="btn pink darken-1 " onClick={this.toggle}>
                Fermer
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}

export default PriceRulesEditor;
