import React from "react";
import ApiService from "../services/ApiService";
import { adminId } from "../services/Utils";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBBtn,
} from "mdbreact";
import { toast } from "react-toastify";
import ForgotPassord from "./ForgotPassord";
import "./LoginForm.css";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleForgotPwdDisplay = this.handleForgotPwdDisplay.bind(this);
    this.state = {
      isForgotPassword: false,
      disableSubmitButton: false,
    };
  }

  handleForgotPwdDisplay = (value) => {
    this.setState({ isForgotPassword: value });
  };

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const self = this;
    this.setState({ disableSubmitButton: true });
    ApiService.request(
      { email: this.state.username, password: this.state.password },
      "user/authenticate",
      "post"
    )
      .then(function (data) {
        toast.success("Authentification réussie", {
          autoClose: 3000,
        });
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", JSON.stringify(data.user));
        localStorage.setItem("user_id", data.user.id);
        localStorage.setItem("user_logo", data.user.logo);
        localStorage.setItem(
          "user_role",
          data.user.is_owner ? adminId : data.user.role
        );
        self.props.doLogin(data.user.id);
      })
      .catch(function () {
        self.setState({ disableSubmitButton: false });
        toast.error("Authentification échouée", {
          autoClose: 3000,
        });
      });
  }

  render() {
    return (
      <MDBContainer className="mt-5">
        <MDBRow center>
          <MDBCol xl="6" lg="7" md="10">
            <MDBCard>
              <MDBCardBody className="mx-4">
                {this.state.isForgotPassword ? (
                  <ForgotPassord
                    handleForgotPwdDisplay={this.handleForgotPwdDisplay}
                  />
                ) : (
                  <>
                    <div className="text-center">
                      <h3 className="dark-grey-text mb-5">
                        <strong>Connexion</strong>
                      </h3>
                    </div>
                    <form
                      onSubmit={(e) => this.handleSubmit(e)}
                      autoComplete="on"
                    >
                      <MDBInput
                        onChange={this.handleChange}
                        name="username"
                        value={this.state.email}
                        label="Adresse email"
                        group
                        type="email"
                        validate
                        error="wrong"
                        success="right"
                      />
                      <MDBInput
                        label="Mot de passe"
                        group
                        type="password"
                        validate
                        containerClass="mb-0"
                        onChange={this.handleChange}
                        value={this.state.password}
                        name="password"
                        className="logout-pwd-field"
                        style={{ marginBotttom: "0px" }}
                      />
                      <div className="d-flex flex-row-reverse forgot-pwd-link-container">
                        <p
                          className="forgot-pwd-link"
                          onClick={() => this.handleForgotPwdDisplay(true)}
                        >
                          Mot de passe oublié ?
                        </p>
                      </div>
                      <div className="text-center mb-3">
                        <MDBBtn
                          rounded
                          type="submit"
                          className="btn-block z-depth-1a pink darken-1"
                          disabled={this.state.disableSubmitButton}
                        >
                          {this.props.btnLabel
                            ? this.props.btnLabel
                            : "SUIVANT"}
                        </MDBBtn>
                      </div>
                    </form>
                  </>
                )}
              </MDBCardBody>
              <br />
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default LoginForm;
