import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Select } from "../../components/Select";
import { Input } from "../../components/Input";

const CheckinTableFilter = ({
  checkinPoints,
  componentsList,
  placementsList,
  setFilteredCheckinPoints,
}) => {


  const [nameFilter, setNameFilter] = React.useState("");
  const [componentFilter, setComponentFilter] = React.useState("");
  const [placementFilter, setPlacementFilter] = React.useState("");


  React.useEffect(() => {
    filterCheckin();
  }, [
    nameFilter,
    componentFilter,
    placementFilter,
  ]);

  const filterCheckin = () => {
    let checkinPointsFiltered = checkinPoints;

    if (nameFilter.trim()) {
      checkinPointsFiltered = checkinPointsFiltered.filter((checkin) => {
        const checkinName =
          checkin.name
            .trim()
            .toLowerCase();
        return checkinName.includes(nameFilter.trim().toLowerCase())
          ? checkin
          : false;
      });
    }

    if (componentFilter) {
      checkinPointsFiltered = checkinPointsFiltered.filter(
        (checkin) =>
          checkin.components &&
          checkin.components.find(
            (check) => check.id === componentFilter
          )
      );
    }

    if (placementFilter) {
      checkinPointsFiltered = checkinPointsFiltered.filter(
        (checkin) =>
          checkin.placements &&
          checkin.placements.find(
            (check) => check.id === placementFilter
          )
      );
    }

    setFilteredCheckinPoints(checkinPointsFiltered);

    // if (dateLimitFilter) {
    //   delegatairesFiltered = delegatairesFiltered.filter(
    //     (delegataire) =>
    //       delegataire.expiration_date &&
    //       new Date(delegataire.expiration_date.date_limit).toLocaleDateString(
    //         "fr-FR",
    //         options
    //       ) === dateLimitFilter
    //   );
    // }

    // if (componentFilter) {
    //   delegatairesFiltered = delegatairesFiltered.filter((delegataire) => {
    //     const delegataireWithComponent = delegataireComponents.find(
    //       (delCmp) => delCmp.id === delegataire.id
    //     );
    //     if (delegataireWithComponent) {
    //       if (
    //         delegataireWithComponent.components &&
    //         delegataireWithComponent.components.length
    //       ) {
    //         return delegataireWithComponent.components.some(
    //           (cmp) => Number(cmp.id) === Number(componentFilter)
    //         );
    //       }
    //     }
    //     return false;
    //   });
    // }

    // if (statusFilter) {
    //   delegatairesFiltered = delegatairesFiltered.filter((delegataire) => {
    //     const delegataireWithComponent = delegataireComponents.find(
    //       (delCmp) => delCmp.id === delegataire.id
    //     );
    //     if (
    //       delegataireWithComponent &&
    //       delegataireWithComponent.status === statusFilter
    //     ) {
    //       return true;
    //     }
    //     return false;
    //   });
    // }

    // setFilteredDelegataires(delegatairesFiltered);
  };

  const componentsOptions = componentsList.map((cmp) => ({
    label: cmp.name,
    value: cmp.id,
  }));

  componentsOptions.unshift({
    value: "",
    label: "Filtrer par composant",
  });

  const placementsOptions = placementsList.map((p) => ({
    label: p.name,
    value: p.id,
  }));

  placementsOptions.unshift({
    value: "",
    label: "Filtrer par placement",
  });

  return (
    <div className="d-flex bg-white p-3 mb-3">
      <div className="check-filter-items">
        <Input
          bgColor="#ffffff"
          borderColor="#000000"
          textColor="rgba(0, 0, 0, 0.87)"
          placeholder="Nom"
          // id="input-outlined-basic-delegataire-filter"
          value={nameFilter}
          handleValue={({ target: { value } }) => setNameFilter(value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          className="check-filter-inputs"
        />
      </div>
      <div className="check-filter-items">
        <Select
          selectedValue={componentFilter || ""}
          handleSelectedValue={setComponentFilter}
          options={componentsOptions}
          id="filter-delegataire-select-users"
          displayArrowIcon={true}
          placeholder="FILTRER PAR COMPOSANT"
          className="check-filter-inputs"
          margin={1}
        />
      </div>
      <div className="check-filter-items">
        <Select
          selectedValue={placementFilter || ""}
          handleSelectedValue={setPlacementFilter}
          options={placementsOptions}
          id="filter-delegataire-select-users"
          displayArrowIcon={true}
          placeholder="FILTRER PAR PLACEMENT"
          className="check-filter-inputs"
          margin={1}
        />
      </div>
    </div>
  );
}

export default CheckinTableFilter;

