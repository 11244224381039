import React, { useState } from "react";
import PropTypes from "prop-types";
import { MDBIcon, MDBInput, MDBBtn } from "mdbreact";
import { toast } from "react-toastify";

import ApiService from "../services/ApiService";

function ForgotPassord({ handleForgotPwdDisplay }) {
  const [email, setEmail] = useState("");
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const handleOnchange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisableSubmitButton(true);
    if (!email) return;
    try {
      toast.success(
        "Si un compte existe avec l’email fourni, alors vous recevrez un email (pensez à consulter vos spams)",
        {
          autoClose: 4000,
          onClose: () => {
            handleForgotPwdDisplay(false);
          },
        }
      );
      await ApiService.request(
        { email, url: window.location.origin },
        "users/lostpassword",
        "post"
      );
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data === "User not found"
      )
        return;
      toast.error("Réinitialisation échouée", {
        autoClose: 3000,
      });
    }
  };

  return (
    <div>
      <div
        style={{
          cursor: "pointer",
          fontSize: "1.5rem",
        }}
      >
        <MDBIcon
          onClick={() => handleForgotPwdDisplay(false)}
          icon="arrow-left"
        />
      </div>
      <h3 style={{ width: "90%", textAlign: "center", marginInline: "auto" }}>
        Entrer votre email pour réinitialiser votre mot de passe
      </h3>
      <form onSubmit={handleSubmit} autoComplete="on">
        <div style={{ width: "78%", marginInline: "auto" }}>
          <MDBInput
            onChange={handleOnchange}
            name="email"
            value={email}
            label="Adresse email"
            group
            type="email"
            validate
            error="wrong"
            success="right"
          />
        </div>

        <div className="text-center mb-3">
          <MDBBtn
            rounded
            type="submit"
            style={{ width: "80%", marginInline: "auto" }}
            className="btn-block z-depth-1a pink darken-1"
            disabled={disableSubmitButton}
          >
            Confirmer
          </MDBBtn>
        </div>
      </form>
      <div>
        <p
          style={{
            marginBottom: 0,
            cursor: "pointer",
            width: "fit-content",
            marginInline: "auto",
          }}
          onClick={() => handleForgotPwdDisplay(false)}
        >
          Annuler
        </p>
      </div>
    </div>
  );
}

ForgotPassord.propTypes = {
  handleForgotPwdDisplay: PropTypes.func,
};

export default ForgotPassord;
