import React from "react";
import { Switch, Route } from "react-router-dom";
import ComponentsList from "./ComponentsList";
import ComponentContainer from "./ComponentContainer";

class Composants extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path="/admin/components/edit/:id?"
          component={ComponentContainer}
        />
        <Route
          exact
          path="/admin/components/config/:id?"
          component={ComponentContainer}
        />
        <Route
          exact
          path="/admin/components/form/:id?"
          component={ComponentContainer}
        />
        <Route exact path="/admin/components" component={ComponentsList} />
      </Switch>
    );
  }
}

export default Composants;
