import React from "react";
import { SelectIconComponent } from "../inputComponent/SelectIconComponent";

export function translateComponentType(value) {
  switch (value) {
    case "display":
      return "Diffusion d’information";
    case "map":
      return "Carte";
    case "request":
      return "Demande d’information invité";
    case "logistic":
      return "Logistique";
    case "moment":
      return "Moment";
    default:
      return null;
  }
}
export const componentsRolesTranslation = {
  FR: {
    standard: "Standard",
    entrance: "Entrée",
    invitation: "Ajout d'accompagnants",
    delegataire: "Contact délégataire",
  },
};

export const componentsTypesTranslation = {
  FR: {
    display: "Diffusion d’information",
    request: "Demande d’information invité",
    logistic: "Logistique",
    moment: "Moment",
    map: "Carte",
  },
};

export const languagesList = [
  { id: 1, code: "FR", label: "Français", value: "", color: "#000" },
  { id: 2, code: "EN", label: "English", value: "", color: "#000" },
  { id: 3, code: "ES", label: "Español", value: "", color: "#000" },
];

// Initialiser les réponses aux composants
export const initComponentResponsesOptions = () => {
  return languagesList.map((lang) => {
    switch (lang.code) {
      case "FR":
        return { ...lang, value: "Oui;Non" };
      case "EN":
        return { ...lang, value: "Yes;No" };
      case "ES":
        return { ...lang, value: "Si;No" };
      default:
        return { ...lang, value: "Oui;Non" };
    }
  });
};

//TODO modifier texte pour langues Espagnol
export const webPageTranslations = {
  FR: {
    error_occuring:
      "Suite à un incident technique, cette page est temporairement indisponible. Nous faisons le nécessaire pour la rétablir rapidement.Veuillez réessayer ultérieurement.",
    maintenance_mode:
      "Cette page est en maintenance, veuillez réessayer ultérieurement.",
    see_more: "En savoir plus",
    export: "Exporter",
    no_more_space_for_some_components:
      "Une ou plusieurs sélections ne sont plus disponibles. Merci de vérifier et de valider à nouveau votre participation.",
    your_account_is_deactivated:
      "Votre compte est désactivé, merci de vous rapprocher de l'organisateur de l'événement",
    external: "Externe",
    internal: "Interne",
    externalOrInternal: "Externe ou interne",
    back_to: "Retour à ",
    back_to_my_line: "Retour à ma lignée",
    not_answered: "Non répondu",
    hyphen: "-",
    pending: "En attente",
    yes: "Oui",
    no: "Non",
    guests_number: "Nb d'accompagnants",
    will_join: "Participera",
    team_members: "Nombre de filleuls directs",
    team_member_name: "Nom/Prénom",
    select_event: "Sélectionnez un événement",
    select_event2: "Sélectionner un événement",
    event: "Evenement",
    my_team: "Mon équipe",
    my_invoices: "Mes factures",
    fullEventText: " (Complet)",
    payment_processing:
      "Transaction en cours de finalisation, merci de patienter et de ne pas fermer la fenêtre.",
    already_paid: "Montant déja réglé",
    my_events: "Mes événements",
    personal_space_title: "Espace personnel",
    logout: "Déconnexion",
    goBack: "Retour",
    refound_amount_message: "Votre commande présente un trop perçu de",
    refund_message_error:
      "Une erreur est survenue lors de votre remboursement veuillez contacter l'équipe d'organisation de l'événement",
    refund_message:
      "Votre remboursement a bien été émis et sera effectif sous un délais de 5 à 10 jours en fonction de votre banque.",
    refund_already_message: "Votre remboursement a déja été émis.",
    refund_pending:
      "Le remboursement est en attente, consultez votre compte Stripe pour vérifier votre solde.",
    hello: "Bonjour",
    already_registred:
      "vous a déjà inscrit à cet événement. Pour modifier votre participation veuillez contacter cette personne.",
    payment_error: "Transaction bancaire refusée",
    card_number: "Numéro de carte",
    expiration_date: "Date d'expiration",
    cvc: "Cryptogramme",
    check: "Chèque",
    payment_card: "Carte de paiement",
    payment_success: "Paiement effectué avec succès !",
    validate_order: "Valider ma commande",
    cancel_registration: "Annuler et modifier mon inscription",
    terms: "J'ai lu et j'accepte les ",
    personal_informations:
      "Vos données personnelles seront utilisées pour le traitement de votre commande, vous accompagner au cours de votre visite du site web, et pour d'autres raisons décrites dans notre politique de confidentialité",
    sub_total: "Sous total",
    already_invited: "déjà inscrit",
    search_by_lastname: "Rechercher par nom",
    select_option: "Veuillez sélectionner le type d'invité",
    email: "Email (*)",
    phone: "Téléphone (*)",
    white_space: "Les espaces seuls ne sont pas acceptés",
    quantity: "Quantité",
    unit_price_vat: "PU HT",
    unit_price: "PU TTC",
    total_vat: "Total HT",
    date: "Date",
    actions: "Actions",
    sub_total_vat: "Sous total HT",
    vat: "TVA",
    total: "Total TTC ",
    your_order: "Votre commande",
    refund_order: "Commande à rembourser",
    product: "Produit",
    billing_details: "Détails de facturation",
    modify: "Modifier",
    summary_title: "Récapitulatif programmes",
    button_text: "Ajouter un accompagnant",
    lastname: "Nom",
    firstname: "Prénom",
    address: "Adresse",
    address_more: "Complément d'adresse",
    zip_code: "Code postal",
    city: "Ville",
    country: "Pays",
    company: "Société",
    title: "Accompagnants",
    lastnameField: "Nom de l'accompagnant (*)",
    firstnameField: "Prénom de l'accompagnant (*)",
    label: "Merci de renseigner les informations de votre accompagnant",
    confirm: "Confirmer",
    cancel: "Annuler",
    close: "Fermer",
    cgv: "Conditions générales de vente",
    payment_methods: "Méthode de paiement",
    paymentInvoice: "Payer la facture",
    cancelMessageConfirmation:
      "Annulation de votre participation à l'évènement. ",
    validationCancelMessage: "Êtes-vous sûr de vouloir valider cette action ?",
  },
  EN: {
    error_occuring:
      "Oops, there is something wrong on this page, please contact us.",
    maintenance_mode:
      "This page is under maintenance, we will be back shortly.",
    see_more: "See more",
    export: "Export",
    no_more_space_for_some_components:
      "One or more selections are no longer available. Please check and validate your participation again.",
    your_account_is_deactivated:
      "Your account is deactivated, please contact the organizer of the event",
    external: "External",
    internal: "Internal",
    externalOrInternal: "External or internal",
    back_to: "Back to ",
    back_to_my_line: "Back to my line",
    not_answered: "Not answered",
    hyphen: "-",
    pending: "pending",
    yes: "yes",
    no: "No",
    guests_number: "Guests",
    will_join: "Will participate",
    team_members: "Team members",
    team_member_name: "Team member name",
    select_event: "Select an event",
    event: "Event",
    date: "Date",
    actions: "Actions",
    my_team: "My team",
    my_invoices: "My invoices",
    fullEventText: " (Full)",
    payment_processing:
      "Transaction being finalized, please wait and don't close this window.",
    already_paid: "Amount already paid",
    my_events: "My events",
    personal_space_title: "Private account",
    logout: "Log out",
    goBack: " Go Back",
    refound_amount_message: "Your order presents an overpayment of",
    refund_message_error:
      "An error has occurred during your refund, please contact the event organisation team.",
    refund_message:
      "Your refund has been issued and will be effective within 5 to 10 days depending on your bank.",
    refund_already_message: "Your refund has been already issued.",
    refund_pending:
      "Your refund is in waiting, consult your Stripe account to check your fund .",
    hello: "Hello",
    already_registred:
      "has already registred you on this event. To modify your registration please contact that person.",
    payment_error: "Invalid transaction",
    card_number: "Card Number",
    expiration_date: "Expiration date",
    cvc: "CVC",
    check: "Check",
    payment_card: "Payment card",
    payment_success: "Payment succeeded!",
    validate_order: "Confirm my order",
    cancel_registration: "Cancel and change my registration",
    terms: "I have read and accept the ",
    personal_informations:
      "Your personal data will be used to process your order, accompany you during your visit to the website, and for other reasons described in our privacy policy",
    sub_total: "Sub total",
    already_invited: "already registered",
    search_by_lastname: "Search by lastname",
    select_option: "Please select the guest title",
    email: "Email (*)",
    phone: "Phone (*)",
    white_space: "Spaces alone are not accepted",
    quantity: "Quantity",
    unit_price_vat: "Unit Price Excl. VAT",
    unit_price: "Unit Price Incl. VAT",
    total_vat: "Total Excl. VAT",
    sub_total_vat: "Subtotal Excl. VAT",
    vat: "VAT",
    total: "Total Incl. VAT",
    your_order: "Your order",
    refund_order: "Order to refund",
    billing_details: "Billing details",
    modify: "Modify",
    summary_title: "Program summary",
    button_text: "Add a guest",
    lastname: "Lastname",
    firstname: "Firstname",
    address: "Address",
    address_more: "Additional address details",
    zip_code: "Zip code",
    city: "City",
    country: "Country",
    company: "Company",
    title: "Guests",
    lastnameField: "Guest lastname (*)",
    firstnameField: "Guest firstname (*)",
    label: "please give us your guest informations",
    confirm: "Confirm",
    cancel: "Cancel",
    close: "Close",
    cgv: "Terms of Sales",
    payment_methods: "Payment methods",
    paymentInvoice: "Pay the invoice",
    cancelMessageConfirmation:
      "Cancellation of the participation to the event ",
    validationCancelMessage: "Are you sure you want to validate this action ? ",
  },
  ES: {
    error_occuring:
      "Debido a un problema técnico, esta página no está disponible temporalmente. Vuelva a intentarlo más tarde",
    maintenance_mode:
      "Esta página está en mantenimiento, por favor inténtelo más tarde",
    see_more: "Leer más",
    export: "Exportar",
    no_more_space_for_some_components:
      "Una o más selecciones ya no están disponibles. Por favor, compruebe y revalide su entrada.",
    your_account_is_deactivated:
      "Su cuenta está desactivada, póngase en contacto con el organizador del evento",
    external: "Exterior",
    internal: "Interno",
    externalOrInternal: "Exterior o interno",
    back_to: "Volver a ",
    back_to_my_line: "Volver a mi linaje",
    not_answered: "Sin respuesta",
    hyphen: "-",
    pending: "En espera",
    yes: "Si",
    no: "No",
    guests_number: "Número de acompañantes",
    will_join: "Participará",
    team_members: "Número de remisiones directas",
    team_member_name: "Nombre/Apellidos",
    select_event: "Seleccione un evento",
    select_event2: "Seleccione un evento",
    event: "Evento",
    my_team: "Mi equipo",
    my_invoices: "Mis facturas",
    fullEventText: " (Completo)",
    payment_processing:
      "La transacción se está finalizando, por favor sea paciente y no cierre la ventana.",
    already_paid: "Importe ya abonado",
    my_events: "Mis eventos",
    personal_space_title: "Espacio personal",
    logout: "Desconexión",
    goBack: "Volver",
    refound_amount_message: "Su pedido tiene un sobrepago de",
    refund_message_error:
      "Se ha producido un error durante el reembolso, póngase en contacto con el equipo de organización del evento.",
    refund_message:
      "Su reembolso ha sido emitido y se hará efectivo en un plazo de 5 a 10 días dependiendo de su banco.",
    refund_already_message: "Su reembolso ya ha sido emitido.",
    refund_pending:
      "El reembolso está pendiente, comprueba tu cuenta de Stripe para verificar tu saldo.",
    hello: "Hola",
    already_registred:
      "ya le ha inscrito en este evento. Para cambiar su participación, póngase en contacto con esta persona.",
    payment_error: "Transacción bancaria denegada",
    card_number: "Número de tarjeta",
    expiration_date: "Fecha de caducidad",
    cvc: "Criptograma",
    check: "Consulte",
    payment_card: "Tarjeta de pago",
    payment_success: "¡Pago efectuado!",
    validate_order: "Confirmar mi pedido",
    cancel_registration: "Cancelar y modificar mi inscripción",
    terms: "He leído y acepto el ",
    personal_informations:
      "Sus datos personales se utilizarán para procesar su pedido, para ayudarle durante su visita al sitio web y para otros fines descritos en nuestra política de privacidad.",
    sub_total: "Subtotal",
    already_invited: "ya registrado",
    search_by_lastname: "Buscar por nombre",
    select_option: "Seleccione el tipo de huésped",
    email: "Correo electrónico (*)",
    phone: "Teléfono (*)",
    white_space: "No se aceptan espacios individuales",
    quantity: "Cantidad",
    unit_price_vat: "PU SI",
    unit_price: "PU II",
    total_vat: "Total SI",
    date: "Fecha",
    actions: "Acciones",
    sub_total_vat: "Subtotal SI",
    vat: "IVA",
    total: "Total II ",
    your_order: "Su pedido",
    refund_order: "Pedido que debe reembolsarse",
    product: "Producto",
    billing_details: "Datos de facturación",
    modify: "Editar",
    summary_title: "Resumen de los programas",
    button_text: "Añadir un acompañante",
    lastname: "Nombre",
    firstname: "Apellido",
    address: "Dirección",
    address_more: "Dirección adicional",
    zip_code: "Código postal",
    city: "Ciudad",
    country: "País",
    company: "Empresa",
    title: "Compañeros",
    lastnameField: "Nombre del acompañante (*)",
    firstnameField: "Apellido del acompañante (*)",
    label: "Rellene los datos de su acompañante",
    confirm: "Confirme",
    cancel: "Cancelar",
    close: "Fermer",
    cgv: "Condiciones generales de venta",
    payment_methods: "Forma de pago",
    paymentInvoice: "Pagar la factura",
    cancelMessageConfirmation: "Cancelación de su participación en el evento.",
    validationCancelMessage: "¿Está seguro de que desea validar esta acción?",
  },
};

export const formattedDays = [
  {
    lang: "FR",
    labels: [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ],
  },
  {
    lang: "EN",
    labels: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
  },
  {
    lang: "ES",
    labels: [
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sàbada",
      "Domingo",
    ],
  },
];

export const formattedMonths = [
  {
    lang: "FR",
    labels: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Aout",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
  },
  {
    lang: "EN",
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  },
  {
    lang: "ES",
    labels: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
  },
];

export const quotaWarningLabel = [
  {
    code: "FR",
    label: "Français",
    value: "Il ne reste que %quota% places",
  },
  {
    code: "EN",
    label: "English",
    value: "%quota% places left",
  },
  {
    code: "ES",
    label: "Español",
    value: "Sólo quedan %quota% plazas",
  },
];

export const messageWillJoinEvent = [
  {
    code: "FR",
    label: "Français",
    value:
      '<p style="text-align: center;" data-mce-style="text-align: center;">Bonjour,</p><p style="text-align: center;" data-mce-style="text-align: center;"> Nous avons bien pris en compte votre réponse positive à notre invitation.</p>',
  },
  {
    code: "EN",
    label: "English",
    value:
      '<p style="text-align: center;" data-mce-style="text-align: center;">Dear Ms, Sir, </p><p style="text-align: center;" data-mce-style="text-align: center;">Your positive answer to the invitation has been well taken into account.</p>',
  },
  {
    code: "ES",
    label: "Español",
    value:
      '<p style="text-align: center;" data-mce-style="text-align: center;">Estimada Sra, Sr, </p><p style="text-align: center;" data-mce-style="text-align: center;">Su respuesta positiva a la convocatoria ha sido bien tenida en cuenta.</p>',
  },
];

export const messageWillNotJoinEvent = [
  {
    code: "FR",
    label: "Français",
    value:
      '<p style="text-align: center;" data-mce-style="text-align: center;">Bonjour,</p><p style="text-align: center;" data-mce-style="text-align: center;"> Nous avons bien pris en compte votre réponse négative à notre invitation.</p>',
  },
  {
    code: "EN",
    label: "English",
    value:
      '<p style="text-align: center;" data-mce-style="text-align: center;">Dear Ms, Sir, </p><p style="text-align: center;" data-mce-style="text-align: center;">Your negative answer to the invitation has been well taken into account.</p>',
  },
  {
    code: "ES",
    label: "Español",
    value:
      '<p style="text-align: center;" data-mce-style="text-align: center;">Estimada Sra, Sr, </p><p style="text-align: center;" data-mce-style="text-align: center;">Su respuesta negativa a la invitación ha sido bien tenida en cuenta.</p>',
  },
];

export const existingResponseMessage = [
  {
    code: "FR",
    label: "Français",
    value:
      '<p style="text-align: center;" data-mce-style="text-align: center;">Bonjour, </p>Bonjour, Vous avez déjà répondu à l\'invitation.<p>',
  },
  {
    code: "EN",
    label: "English",
    value:
      '<p style="text-align: center;" data-mce-style="text-align: center;">Dear Ms, Sir,</p>You have already responded to the invitation.<p>',
  },
  {
    code: "ES",
    label: "Español",
    value:
      '<p style="text-align: center;" data-mce-style="text-align: center;">Estimada Sra, Sr,</p>Ya ha respondido a la invitación.<p>',
  },
];

export const registrationExpiredText = [
  {
    code: "FR",
    label: "Français",
    value:
      '<p style="text-align: center;" data-mce-style="text-align: center;">Nous ne pouvons finaliser votre inscription, notre page de paiement étant actuellement indisponible. <br /> Veuillez réessayer ultérieurement.</p>',
  },
  {
    code: "EN",
    label: "English",
    value:
      '<p style="text-align: center;" data-mce-style="text-align: center;">The response time has expired.Please contact the organizer directly.</p>',
  },
  {
    code: "ES",
    label: "Español",
    value:
      '<p style="text-align: center;" data-mce-style="text-align: center;">El tiempo de respuesta ha expirado. Póngase en contacto directamente con el organizador.</p>',
  },
];

//Si a l'avenir des icons doivent être affichés avec les langues
export const generateListLanguage = () => {
  const array = [{ label: "Toutes les langues", value: "" }];
  //const array = [];

  languagesList.forEach((language) => {
    switch (language.code) {
      case "FR":
        array.push({
          value: language.code,
          label: <SelectIconComponent icon={""} title={language.label} />,
        });
        break;
      case "EN":
        array.push({
          value: language.code,
          label: <SelectIconComponent icon={""} title={language.label} />,
        });
        break;
      case "ES":
        array.push({
          value: language.code,
          label: <SelectIconComponent icon={""} title={language.label} />,
        });
        break;
      default:
        break;
    }
  });
  return array;
};
