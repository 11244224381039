import React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function DeleguatePackageTable({ packages }) {
  return (
    <TableContainer
      className="px-4 pb-5"
      component={Paper}
      sx={{ marginTop: "0.3rem", marginBottom: "2.3rem" }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Packages</TableCell>
            <TableCell align="left">Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {packages.map((pckg) => (
            <TableRow
              key={pckg.value}
              className="bg-white"
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="left">
                {pckg.label}
              </TableCell>
              <TableCell align="left">{pckg.description || "-"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

DeleguatePackageTable.prototype = {
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
      description: PropTypes.string,
      guests_nbr: PropTypes.number,
    })
  ),
};

export default DeleguatePackageTable;
