import React from "react";
import { Button, Modal, ModalBody, ModalFooter, MDBInput } from "mdbreact";
import ApiService from "../../services/ApiService";
import { languagesList } from "../../common/Translations";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";
import { matchPath } from "react-router-dom";
import "../DelegataireMain.css";
import { CustomMuiButton } from "../../admin/_modules/components/CustomMuiButton";

class GuestEditor extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.createGuest = this.createGuest.bind(this);
    this.modifyGuest = this.modifyGuest.bind(this);

    this.state = {
      guest: {
        gender: "",
        lastname: "",
        firstname: "",
        languages: "FR",
        mail: "",
        companyName: "",
        company_function: "",
        max_invitation: 0,
        package_id:
          this.props.packagesOptions.length === 1
            ? this.props.packagesOptions[0].value
            : "",
        company_id: null,
        id: null,
        contact_id: null,
        // ...this.props.modifyGuestData,
      },
      modal: this.props.modifyGuest || false,
      isLoading: false,
      match: matchPath(window.location.pathname, {
        path: "/delegataires/:userId",
        strict: false,
      }),
      checkParticipant: false,
    };
  }

  componentDidUpdate(prevProps) {
    // Vérifiez si la prop modifyGuest a changé
    if (this.props.modifyGuest !== prevProps.modifyGuest) {
      // Mettez à jour l'état du composant en conséquence
      const { modifyGuestData } = this.props;
      this.setState({
        modal: this.props.modifyGuest || false,
        guest: {
          gender: modifyGuestData.gender,
          lastname: modifyGuestData.lastname,
          firstname: modifyGuestData.firstname,
          languages: modifyGuestData.contact.languages || "FR",
          mail: modifyGuestData.email,
          companyName: modifyGuestData.contact.company
            ? modifyGuestData.contact.company.name
            : "",
          company_function: modifyGuestData.contact
            ? modifyGuestData.contact.company_function
            : "",
          max_invitation: modifyGuestData.max_invitation,
          package_id: modifyGuestData.package_id || "",
          id: modifyGuestData.id,
          contact_id: modifyGuestData.contact_id,
          company_id: modifyGuestData.contact
            ? modifyGuestData.contact.company_id
            : "",
        },
      });
    }
  }

  // Fonction pour fermer la modal et mettre à jour modifyGuest dans le parent
  closeModalAndUpdateParent = (newModifyGuestValue, guest, makeParticipant) => {
    this.setState({ modal: false, guest: "", isLoading: false }, () => {
      if (this.state.checkParticipant) {
        if (this.props.canRegisterAllGuests(1, guest.package_id)) {
          makeParticipant(guest);
        } else {
          toast.warning(
            "Quota de places disponibles insuffisant pour inscrire votre contact"
          );
        }
      } else {
        this.props.updateGuestList();
      }
      // Appeler la fonction de rappel pour mettre à jour modifyGuest
      this.props.updateModifyGuest(newModifyGuestValue);
    });
  };

  async createGuest(e) {
    e.preventDefault();
    const { checkParticipant } = this.state;
    const { makeParticipant } = this.props;
    const {
      lastname,
      firstname,
      languages,
      mail,
      package_id,
      max_invitation,
      company_function,
      companyName,
      gender,
    } = this.state.guest;
    const self = this;

    this.setState({ isLoading: true });
    if (companyName.length > 0) {
      await ApiService.request(
        { name: companyName, company_function },
        "contact_companies/",
        "post"
      ).then(function (data) {
        self.setState({
          guest: { ...self.state.guest, company_id: data.id },
        });
      });
    }

    await ApiService.request(
      {
        gender,
        languages,
        mail,
        created_on_checkin: false,
        active: true,
        related_to: null,
        lastname,
        firstname,
        company_id: this.state.guest.company_id,
      },
      "contacts/",
      "post"
    ).then(async function (data) {
      console.clear();
      const userId = self.props.userId ? self.props.userId : null;
      await ApiService.request(
        {
          max_invitation,
          email: data.mail,
          firstname: data.firstname,
          lastname: data.lastname,
          created_on_checkin: false,
          evenement_id: self.props.eventId,
          invitation_package_id: null,
          created_by_delegataire: true,
          package_id,
          checked_in: false,
          contact_id: data.id,
          is_validated: true,
          user_id: userId ? userId : localStorage.getItem("user_id"),
          gender,
        },
        "guests",
        "post"
      ).then(function (guest) {
        self.setState({ modal: false, isLoading: false }, () => {
          if (checkParticipant) {
            makeParticipant(guest);
          } else {
            self.props.updateGuestList();
          }
        });
      });
    });
  }

  async modifyGuest(e) {
    try {
      e.preventDefault();
      this.setState({ isLoading: true });
      const { makeParticipant, modifyGuestData } = this.props;

      const {
        lastname,
        firstname,
        languages,
        mail,
        package_id,
        max_invitation,
        company_function,
        companyName,
        company_id,
        gender,
        id,
        contact_id,
      } = this.state.guest;

      const self = this;

      const oldCompanyName =
        modifyGuestData.contact.company && modifyGuestData.contact.company.name;
      if (companyName !== oldCompanyName) {
        const method = company_id ? "put" : "post";
        await ApiService.request(
          { name: companyName, company_function },
          "contact_companies/" + (company_id || ""),
          method
        ).then(function (data) {
          self.setState({
            guest: { ...self.state.guest, company_id: data.id },
          });
        });
      }

      await ApiService.request(
        {
          gender,
          languages,
          mail,
          created_on_checkin: false,
          active: true,
          related_to: null,
          lastname,
          firstname,
          company_id: this.state.guest.company_id,
          company_function,
        },
        "contacts/" + contact_id,
        "put"
      ).then(async function (data) {
        const userId = self.props.userId ? self.props.userId : null;
        await ApiService.request(
          {
            max_invitation,
            email: data.mail,
            firstname: data.firstname,
            lastname: data.lastname,
            created_on_checkin: false,
            evenement_id: self.props.eventId,
            invitation_package_id: null,
            created_by_delegataire: true,
            package_id,
            checked_in: false,
            contact_id: data.id,
            is_validated: true,
            user_id: userId ? userId : localStorage.getItem("user_id"),
            gender,
          },
          "guests/" + id,
          "put"
        ).then(function (guest) {
          // Mettez à jour la modal et modifyGuest dans le composant parent
          self.closeModalAndUpdateParent(false, guest, makeParticipant);
        });
      });
    } catch (error) {
      console.log(error);
      toast.error("Une erreur s'est produite durant l'enregistrement");
      this.setState({ isLoading: false });
    }
  }

  handleChange(event) {
    let value = event.target.value;
    const name = event.target.name;

    if (name === "max_invitation") value = Number(value);
    this.setState({
      guest: { ...this.state.guest, [name]: value },
    });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
    this.props.updateModifyGuest(false);
  };

  render() {
    const { packagesOptions, modifyGuest, disableButton } = this.props;
    const { guest, isLoading, modal, checkParticipant } = this.state;

    return (
      <div className="button-container modal-delegataire">
        <CustomMuiButton
          type="button"
          text="SAISIR UN INVITÉ OU INSCRIRE UN PARTICIPANT"
          onClick={this.toggle}
          minHeight={50}
          textColor="#ffffff"
          buttonBgColor="#d81b60"
          buttonHoverBgColor="#AF1242"
          buttonHoverBorderColor="#0062cc"
          buttonActiveBgColor="#d81b60"
          disable={disableButton}
          className={this.props.disableModifyGuest ? "d-none" : ""}
        />
        <Modal isOpen={modal} fullHeight position="right" toggle={this.toggle}>
          {isLoading ? (
            <Loader />
          ) : (
            <form
              onSubmit={
                modifyGuest
                  ? (e) => this.modifyGuest(e)
                  : (e) => this.createGuest(e)
              }
              className="form"
            >
              <ModalBody>
                <div className="row">
                  <div className="col-md-12 font-weight-bold">
                    1- Informations de l'invité
                  </div>

                  <div className="form-group col-md-12">
                    <label>Langues</label>
                    <br />
                    <select
                      onChange={this.handleChange}
                      name="languages"
                      className="form-control browser-default custom-select"
                      value={guest.languages}
                    >
                      {languagesList.map((lang, k) => (
                        <option value={lang.code} key={k}>
                          {lang.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-12">
                    <MDBInput
                      label="Civilité"
                      type="text"
                      name="gender"
                      value={guest.gender}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <MDBInput
                      label="Nom"
                      type="text"
                      value={guest.lastname}
                      name="lastname"
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <MDBInput
                      label="Prénom"
                      type="text"
                      value={guest.firstname}
                      name="firstname"
                      onChange={this.handleChange}
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <MDBInput
                      label="Email"
                      type="text"
                      value={guest.mail ? guest.mail : guest.email}
                      name="mail"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="col-md-12">
                    <MDBInput
                      label="Société"
                      type="text"
                      value={guest.companyName}
                      name="companyName"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <MDBInput
                      label="Fonction"
                      type="text"
                      value={guest.company_function || ""}
                      name="company_function"
                      onChange={this.handleChange}
                    />
                  </div>
                  {this.props.disableModifyGuest ? ("") : ((
                    <>
                      <div className="col-md-12 font-weight-bold">
                        2- Paramétrage de l'invitation
                      </div>
                      <br />
                      <div className="col-md-12">
                        <label>Package invité</label>
                        <select
                          onChange={this.handleChange}
                          name="package_id"
                          className="browser-default custom-select"
                          value={guest.package_id}
                          required
                        >
                          <option key="package-invite-key" value="">
                            Sélectionnez un package
                          </option>
                          {packagesOptions.map((item, index) => (
                            <option
                              key={`package-invite-key-${index}`}
                              value={item.value}
                            >
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-12">
                        <MDBInput
                          /* max={
                            guest.package_id
                              ? packagesOptions.find(
                                  item => item.value === Number(guest.package_id)
                                ).max_invitation -
                                guests.filter(
                                  item =>
                                    item.package_id === Number(guest.package_id)
                                ).length -
                                1
                              : 0
                          }*/
                          label="Nombre d'accompagnants"
                          type="number"
                          value={`${guest.max_invitation}`}
                          name="max_invitation"
                          onChange={this.handleChange}
                          min="0"
                          required
                        />
                        <div className="form-item-desc">
                          Invitation pour 1 personne = saisir 0 accompagnant
                          <br />
                          Invitation pour 2 personnes = saisir 1 accompagnant
                        </div>
                      </div>
                      <div className="col-md-12" style={{ marginTop: 10 }}>
                        <input
                          type="checkbox"
                          checked={checkParticipant}
                          onChange={(e) =>
                            this.setState({ checkParticipant: !checkParticipant })
                          }
                          style={{
                            opacity: 1,
                            pointerEvents: "auto",
                            position: "initial",
                            marginRight: 10,
                          }}
                        />
                        <label>
                          <strong>Confirmer directement la participation</strong>
                        </label>
                      </div>
                    </>
                  ))}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  // disabled={!guest.package_id}
                  className="btn pink darken-1 "
                >
                  Valider
                </Button>
                <Button className="btn pink darken-1 " onClick={this.toggle}>
                  Fermer
                </Button>
              </ModalFooter>
            </form>
          )}
        </Modal>
      </div>
    );
  }
}

export default GuestEditor;
