import { toast } from "react-toastify";
import React from "react";
import { CSVLink } from "react-csv";

export function ExportContacts({ contacts }) {
  const [data, setData] = React.useState([]);

  const csvLinkRef = React.useRef();

  const titles = [
    [
      "gender",
      "firstname",
      "lastname",
      "address",
      "address_more",
      "zip_code",
      "city",
      "country",
      "mail",
      "mail_second",
      "phone",
      "phone_second",
      "secretary_name",
      "languages",
      "company_name",
      "company_adress",
      "company_zip_code",
      "company_city",
      "company_country",
      "company_function",
      "company_service",
      "active",
      "expiration_date",
      "more_details",
      "category_name",
      "affected_to",
      "tags",
      "external_id",
    ],
  ];

  // React.useEffect(() => {

  //   if (tmpData.length) setData([...titles, ...tmpData]);
  // }, [contacts]);

  const createRow = (contact) => {
    const manager = contacts.find(
      (manager) => manager.id === contact.related_to
    );
    const contactData = [
      contact.gender,
      contact.firstname,
      contact.lastname,
      contact.address,
      contact.address_more,
      contact.zip_code,
      contact.city,
      contact.country,
      contact.mail,
      contact.mail_second,
      contact.phone,
      contact.phone_second,
      contact.secretary_name,
      contact.languages,
      contact.company ? contact.company.name : "",
      contact.company ? contact.company.address : "",
      contact.company ? contact.company.zip_code : "",
      contact.company ? contact.company.city : "",
      contact.company ? contact.company.country : "",
      contact.company_function,
      contact.company_service,
      contact.active ? 1 : 0,
      contact.expiration_date,
      contact.more_details,
      contact.category ? contact.category.name : "",
      manager ? manager.mail : "",
      contact.tags.map((tag) => tag.tag_name).join(";"),
      contact.external_id,
    ];

    return contactData;
  };

  const tmpData = [];
  contacts.forEach((contact) => {
    tmpData.push(createRow(contact));
  });

  console.log("tmpData: ", tmpData, contacts);

  const getData = () => {
    setData([...titles, ...tmpData]);
    if (tmpData.length) {
      setTimeout(() => {
        csvLinkRef.current.link.click();
      }, 1000);
    } else {
      toast.warning("Aucun contact à exporter. Veuillez créer des contacts.");
    }
  };

  return (
    <>
      <button className="btn pink darken-1" onClick={getData}>
        Export contacts
      </button>
      <CSVLink
        separator={";"}
        data={data}
        filename={"export_contacts.csv"}
        ref={csvLinkRef}
        // className="btn pink darken-1"
        className="hidden"
        target="_blank"
      />
    </>
  );
}
