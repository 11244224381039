import React from "react";
import { MDBSpinner } from "mdbreact";
import "./loader.css";

export const SmallLoader = () => {
  return (
    <span style={{ textAlign: "center", display: "block" }}>
      <MDBSpinner small red />
    </span>
  );
};

export default SmallLoader;
