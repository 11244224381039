import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

export function SegmentSelect({
  options,
  selectedValue,
  handleSelectedValue,
  id,
  segmentsList,
  activateDisabledOptions = false,
}) {
  return (
    <FormControl
      sx={{ m: 1, minWidth: 120, height: "fit-content" }}
      size="small"
    >
      <TextField
        id={`segment-select-small-${id}`}
        value={selectedValue}
        onChange={(event) => handleSelectedValue(event.target.value)}
        select
        size="small"
        SelectProps={{
          IconComponent: () => null,
          displayEmpty: true,
          MenuProps: {
            sx: {
              height: 250,
              marginTop: "1rem",
            },
            PaperProps: {
              sx: {
                backgroundColor: "#e1dfdf",
                border: "1px solid #d81b60",
              },
            },
          },
        }}
        InputLabelProps={{
          style: {
            color: "#FFFFFF",
          },
        }}
        sx={{
          minWidth: "250px",
          backgroundColor: "#d81b60",
          borderRadius: "0.7rem",
          border: "1px solid #000000 !important",
          "& .MuiSelect-select": {
            color: "#FFFFFF",
            ":focus": {
              borderRadius: "0.7rem",
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
        {options &&
          options.map((opt, i) => {
            opt.value =
              typeof opt.value === "string"
                ? opt.value.toLowerCase()
                : opt.value;

            const isDisabled = activateDisabledOptions
              ? segmentsList.some((seg) => seg.type === opt.value)
              : false;
            return i !== 0 ? (
              <MenuItem
                key={`segment-select-${opt.value}-${i}`}
                value={opt.value}
                disabled={isDisabled}
              >
                {opt.label}
              </MenuItem>
            ) : (
              <MenuItem
                key={`segment-select-${opt.value}-${i}`}
                value={opt.value}
                disabled={isDisabled}
              >
                <em>{opt.label}</em>
              </MenuItem>
            );
          })}
      </TextField>
    </FormControl>
  );
}

SegmentSelect.prototype = {
  handleSelectedValue: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  segmentsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string,
      condition: PropTypes.string,
    })
  ).isRequired,
};
