import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoiceDollar,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

import "./GuestsContainer.css";

class InvoicesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      invoiceInCreationProcess: false,
    };
  }

  componentDidMount() {
    const { invoices } = this.props;
    if (invoices && invoices.length) {
      if (invoices.some((inv) => !inv.invoice_number)) {
        this.setState({ invoiceInCreationProcess: true });
      }
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const { guestId, eventId, downLoadInvoice, invoices } = this.props;
    return (
      <span>
        <FontAwesomeIcon
          onClick={this.toggle}
          icon={faFileInvoiceDollar}
          title={"Télécharger les factures"}
        />

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Liste des factures</ModalHeader>
          <ModalBody>
            <div className="invoice-modal-container">
              <div className="row invoice-modal-dowloads">
                {this.state.invoiceInCreationProcess ? (
                  <p className="text-danger px-5">
                    *Facture(s) en cours de création veuillez{" "}
                    <span className="font-weight-bold">
                      rafraichir la page dans 1 min...
                    </span>
                  </p>
                ) : null}
                {invoices.map((invoice, index) => (
                  <div
                    className="col-md-12 invoice-modal-dowload-item"
                    key={`invoice-key-${index}`}
                  >
                    {invoice.invoice_number ? (
                      <>
                        {invoice.invoice_number} &nbsp; &nbsp;
                        <FontAwesomeIcon
                          onClick={() =>
                            downLoadInvoice(guestId, eventId, invoice)
                          }
                          icon={faDownload}
                          title={"Télécharger "}
                          className="invoice-modal-dowload-button"
                        />
                      </>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="default" onClick={this.toggle}>
              Fermer
            </Button>
          </ModalFooter>
        </Modal>
      </span>
    );
  }
}

export default InvoicesModal;
