import React from "react";
import ApiService from "../../../services/ApiService";
import Loader from "../../../common/Loader";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "../../../common/Pagination";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { makeUniqArrayByKey, sortByLastName } from "../../../services/Utils";
import { CampaignFilter } from "../components/CampaignFilter";
import { checkIfinvited, filter } from "./utils";
//import { setMinutes, setHours } from "date-fns";

registerLocale("fr", fr);

class CampaignForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.filterElement = this.filterElement.bind(this);
    this.filterByTag = this.filterByTag.bind(this);
    this.changePage = this.changePage.bind(this);
    this.change = this.change.bind(this);
    // this.checkSenderDomainEmail = this.checkSenderDomainEmail.bind(this);
    // this.checkSenderDomainEmail = this.checkSenderDomainEmail.bind(this);
    this.upadteTotalPages = this.upadteTotalPages.bind(this);
    this.upadteCurrentPages = this.upadteCurrentPages.bind(this);
    this.upadteGuestsFiltered = this.upadteGuestsFiltered.bind(this);

    let coeff = 1000 * 60 * 5;
    let date = new Date();
    this.today = new Date(Math.round(date.getTime() / coeff) * coeff);
    this.state = {
      campaign: {
        guest_ids: [],
        sender_name: "",
        sender_email: "",
        evenement_id: this.props.match.params.eventid,
        subject: "",
        title: "",
        mail_template_id: "",
        schedule_date: this.today,
        draft: true,
        nbContact: 0,
      },
      tagOptions: [],
      tagsFilter: "",
      currentPage: 0,
      numberPerPage: 15,
      totalPages: 0,
      guests: [],
      guestsFiltered: [],
      guestsChecked: [],
      packagesList: [],
      delegatairesList: [],
      reponses: [],
      documents: [],
      nameFilter: "",
      companyFilter: "",
      packageIdFilter: "",
      delegataireFilter: "not_invited",
      reponseFilter: "",
      documentFilter: "",
      langueFilter: "",
      guestCheckedFilter: "",
      isLoading: false,
      minTime: this.calculateMinTime(this.today),
      // mailData: {
      //   exist: true,
      //   available: [],
      //   error: false,
      //   message: "",
      //   sender_domain: "",
      // },
      followerFilter: "",
      followerMaxInvitationList: [],
      followerMaxInvitationOptions: [],
      maxFollowerFilter: 0,
    };
  }

  calculateMinTime = (selectedDate) => {
    if (moment(selectedDate).isSame(moment(this.date), "day")) {
      return moment(this.today).toDate();
    }
    return moment(this.date).startOf("day").toDate();
  };

  changePage(page) {
    this.setState({ currentPage: page });
  }

  async componentDidMount() {
    const self = this;
    this.setState({ isLoading: true });
    const evenement = await ApiService.request(
      {},
      "evenements/" + this.props.match.params.eventid,
      "get"
    );
    await ApiService.request(
      {},
      "guests/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      const dataFilters = data.filter((guest) => {
        if (guest.is_followers) return false;
        return guest.contact_id &&
          checkIfinvited(data, guest) &&
          guest.created_by_delegataire &&
          !guest.participate
          ? false
          : true;
      });

      self.setState({
        guests: dataFilters,
        totalPages: Math.ceil(dataFilters.length / self.state.numberPerPage),
        guestsFiltered: dataFilters,
      });
    });

    await ApiService.request(this.state, "tags", "get").then(function (data) {
      const tagOptions = data.map((tag) => ({
        label: tag.tag_name,
        value: tag.id,
      }));

      self.setState({ tagOptions });
    });

    await ApiService.request(
      {},
      "packages/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      self.setState({
        packagesList: data,
      });
    });

    await ApiService.request(
      this.state,
      "guests/followers/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      self.setState({
        followerMaxInvitationList: data,
      });
    });

    const followerMaxInvitationOptions =
      this.state.followerMaxInvitationList.map((element) => ({
        number_max_followers: element.max_invitation,
      }));

    await ApiService.request(
      {},
      "mailtemplates/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      self.setState({
        templatesList: data,
      });
    });

    await ApiService.request(
      {},
      "delegataires/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (delegatairesList) {
      const uniqDelegatairesList = makeUniqArrayByKey(
        delegatairesList,
        "user_id"
      );
      self.setState({
        delegatairesList: uniqDelegatairesList.sort(sortByLastName()),
      });
    });

    const dataForm = await ApiService.request(
      this.state,
      "evenement_forms/byevenement/" + this.props.match.params.eventid,
      "get"
    );
    const form = dataForm.find(
      (form) => form.layout.documentType === "reponse"
    );
    if (form) {
      await ApiService.request(
        {},
        "component_responses/byevenement/" + form.id,
        "get"
      ).then(function (data) {
        self.setState({ reponses: data });
      });
    }
    if (this.props.match.params.campaignid) {
      await ApiService.request(
        this.state,
        "campaigns/" + this.props.match.params.campaignid,
        "get"
      ).then(function (data) {
        self.setState({
          campaign: {
            ...data,
            schedule_date: new Date(data.schedule_date),
          },
          minTime: self.calculateMinTime(new Date(data.schedule_date)),
          guestsChecked: data.guests,
        });
        // if (data.sent === false) {
        //   self.checkSenderDomainEmail(data.sender_email);
        // }
      });
      // if (data.sent === false) {
      //   self.checkSenderDomainEmail(data.sender_email);
      // }
    } else {
      const { mail_sender_address, mail_sender_name } = evenement;
      this.setState({
        campaign: {
          ...this.state.campaign,
          sender_email: mail_sender_address,
          sender_name: mail_sender_name,
        },
      });
    }

    const templatesOptions = this.state.templatesList.map((template) => ({
      label: template.name,
      value: template.id,
    }));

    this.setState({
      templatesOptions,
      followerMaxInvitationOptions,
      isLoading: false,
    });
  }

  handleChange(event) {
    this.setState({
      campaign: {
        ...this.state.campaign,
        [event.target.name]: this.change(event),
      },
    });
  }

  change(e) {
    switch (e.target.name) {
      case "send_attachments":
        return !this.state.campaign.send_attachments;
      case "not_send_guests_mail_attachments_empty":
        return !this.state.campaign.not_send_guests_mail_attachments_empty;
      case "draft":
        return !this.state.campaign.draft;
      case "sender_email":
        // this.checkSenderDomainEmail(e.target.value);
        return e.target.value;
      default:
        return e.target.value;
    }
  }

  checkAll(event) {
    if (event.target.checked) {
      this.setState({
        guestsChecked: this.state.guestsFiltered,
      });
    } else {
      this.setState({
        guestsChecked: [],
      });
    }
  }

  async handleSubmit(e) {
    e.preventDefault();

    const timeDiff = moment(this.state.campaign.schedule_date).diff(
      moment(new Date()),
      "minutes"
    );

    if (!this.state.campaign.draft) {
      if (timeDiff <= 0) {
        toast.warning(
          "Sélectionnez une heure future pour la programmation.\nImpossible de planifier une campagne dans le passé."
        );
        return;
      } else if (timeDiff <= 4) {
        toast.warning(
          "Choisissez une heure de programmation au moins 5 minutes après l'heure actuelle."
        );
        return;
      }
    }

    const { campaign, guestsChecked } = this.state;
    let method = this.state.campaign.id ? "put" : "post";
    let id = this.state.campaign.id ? this.state.campaign.id : "";

    const guest_ids = [];
    this.state.guestsChecked.map((guest) => guest_ids.push(guest.id));
    if (guest_ids.length === 0) {
      toast.error("Sélectionner au moins 1 invité");
      return;
    }

    this.setState({ isLoading: true });
    const schedule_date = moment(
      new Date(this.state.campaign.schedule_date)
    ).format();

    const self = this;
    const filters = campaign.not_send_guests_mail_attachments_empty
      ? guestsChecked.filter((guest) => guest.has_documents)
      : guestsChecked;
    const nbContact = filters.length;
    await ApiService.request(
      {
        ...this.state.campaign,
        guest_ids,
        schedule_date,
        nbContact,
      },
      "campaigns/" + id,
      method
    )
      .then(function (data) {
        toast.success("Campagne sauvegardée");
        self.props.history.push(
          "/admin/events/edit/" +
            self.props.match.params.eventid +
            "/campaigns/"
        );
        self.setState({ isLoading: false });
      })
      .catch(function (e) {
        console.log(e);
        toast.error("Echec lors de la sauvegarde");
        self.setState({ isLoading: false });
      });
  }

  checkOne(event, newGuest) {
    if (event.target.checked) {
      const guestsChecked = this.state.guestsChecked;
      guestsChecked.push(newGuest);
      this.setState({
        guestsChecked,
      });
    } else {
      this.setState({
        guestsChecked: this.state.guestsChecked.filter(
          (guest) => guest.id !== newGuest.id
        ),
      });
    }
  }

  upadteTotalPages(totalPages) {
    this.setState({
      totalPages,
    });
  }
  upadteCurrentPages(index) {
    this.setState({
      currentPage: index,
    });
  }
  upadteGuestsFiltered(guestsFiltered) {
    this.setState({
      guestsFiltered,
    });
  }

  filterElement(event) {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        filter({
          guests: this.state.guests,
          nameFilter: this.state.nameFilter,
          companyFilter: this.state.companyFilter,
          tagsFilter: this.state.tagsFilter,
          numberPerPage: this.state.numberPerPage,
          packageIdFilter: this.state.packageIdFilter,
          delegataireFilter: this.state.delegataireFilter,
          delegatairesList: this.state.delegatairesList,
          reponseFilter: this.state.reponseFilter,
          reponses: this.state.reponses,
          documentFilter: this.state.documentFilter,
          followerFilter: this.state.followerFilter,
          maxFollowerFilter: this.state.maxFollowerFilter,
          contacts: this.props.contacts,
          langueFilter: this.state.langueFilter,
          guestCheckedFilter: this.state.guestCheckedFilter,
          upadteTotalPages: this.upadteTotalPages,
          upadteCurrentPages: this.upadteCurrentPages,
          upadteGuestsFiltered: this.upadteGuestsFiltered,
        });
      }
    );
  }

  filterByTag(event) {
    this.setState(
      {
        tagsFilter: event ? event : "",
      },
      () => {
        filter({
          guests: this.state.guests,
          nameFilter: this.state.nameFilter,
          companyFilter: this.state.companyFilter,
          tagsFilter: this.state.tagsFilter,
          numberPerPage: this.state.numberPerPage,
          packageIdFilter: this.state.packageIdFilter,
          delegataireFilter: this.state.delegataireFilter,
          delegatairesList: this.state.delegatairesList,
          reponseFilter: this.state.reponseFilter,
          reponses: this.state.reponses,
          documentFilter: this.state.documentFilter,
          followerFilter: this.state.followerFilter,
          maxFollowerFilter: this.state.maxFollowerFilter,
          contacts: this.props.contacts,
          langueFilter: this.state.langueFilter,
          guestCheckedFilter: this.state.guestCheckedFilter,
          upadteTotalPages: this.upadteTotalPages,
          upadteCurrentPages: this.upadteCurrentPages,
          upadteGuestsFiltered: this.upadteGuestsFiltered,
        });
      }
    );
  }

  render() {
    const {
      campaign,
      isLoading,
      templatesOptions,
      templatesList,
      guestsFiltered,
      guestsChecked,
      nameFilter,
      companyFilter,
      langueFilter,
      delegatairesList,
      delegataireFilter,
      packagesList,
      packageIdFilter,
      reponseFilter,
      documentFilter,
      tagOptions,
      currentPage,
      totalPages,
      numberPerPage,
      // mailData,
      followerFilter,
      followerMaxInvitationOptions,
      maxFollowerFilter,
      guestCheckedFilter,
    } = this.state;

    const { contacts } = this.props;
    if (isLoading || !templatesOptions) {
      return <Loader />;
    }

    const packagesOptions = packagesList.map((element) => ({
      label: element.name,
      value: element.id,
    }));

    const template = campaign.mail_template_id
      ? templatesList.find(
          (temp) => temp.id === Number(campaign.mail_template_id)
        )
      : null;
    const filters = template
      ? template.not_send_guests_mail_attachments_empty
        ? guestsChecked.filter((guest) => guest.has_documents)
        : guestsChecked
      : guestsChecked;
    const nbInvtés = filters.length;

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <form onSubmit={this.handleSubmit} className="form">
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Nom de la campagne</label>
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  value={campaign.title}
                  onChange={this.handleChange}
                  required
                  disabled={campaign.sent ? true : false}
                />
              </div>
              <div className="form-group col-md-6">
                <label>Objet de l'email</label>
                <input
                  type="text"
                  name="subject"
                  className="form-control"
                  value={campaign.subject}
                  onChange={this.handleChange}
                  required
                  disabled={campaign.sent ? true : false}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Nom d'expéditeur</label>
                <input
                  type="text"
                  name="sender_name"
                  className="form-control"
                  value={campaign.sender_name}
                  onChange={this.handleChange}
                  required
                  disabled={campaign.sent ? true : false}
                />
              </div>
              <div className="form-group col-md-6">
                <label>Adresse email d'expédition </label>
                {/* {mailData.exist === false ? (
                  <>
                    <br />
                    <label style={{ color: "red" }}>Domaines valides</label>
                    <ul>
                      {mailData.available.map((domain) => {
                        return <li style={{ color: "red" }}>@{domain}</li>;
                      })}
                    </ul>
                  </>
                ) : null} */}
                <input
                  type="email"
                  name="sender_email"
                  // className={`form-control ${
                  //   mailData.exist === false ? "error-input" : ""
                  // }`}
                  className="form-control"
                  value={campaign.sender_email}
                  onChange={this.handleChange}
                  required
                  //disabled={campaign.sent ? true : false}
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-row row-checkbox-pj flex-row">
              <div className="form group col-md-6 flex-column ">
                <label>Template email</label>
                <select
                  required
                  onChange={this.handleChange}
                  name="mail_template_id"
                  className="form-control browser-default custom-select"
                  value={campaign.mail_template_id}
                  disabled={campaign.sent ? true : false}
                >
                  <option value={""}>Sélectionnez un template</option>
                  {templatesOptions.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
                </select>
              </div>
              <div className="form group col-md-6">
                <input
                  name="draft"
                  style={{ opacity: 1, pointerEvents: "auto" }}
                  checked={campaign.draft}
                  onChange={this.handleChange}
                  type="checkbox"
                  disabled={campaign.sent ? true : false}
                  className="checkbox-pj"
                />
                <label>Brouillon</label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Date d'envoi</label>
                <div className="form-control">
                  <DatePicker
                    showTimeSelect
                    timeFormat="HH:mm"
                    selected={campaign.schedule_date}
                    onChange={(e) => {
                      this.setState({
                        campaign: {
                          ...campaign,
                          schedule_date: e,
                        },
                        minTime: this.calculateMinTime(e),
                      });
                    }}
                    className="pointer"
                    locale="fr"
                    placeholderText="Format : dd-MM-yyyy"
                    dateFormat="HH:mm, dd-MM-yyyy"
                    timeIntervals={5}
                    timeCaption="time"
                    disabled={campaign.sent ? true : false}
                    minDate={this.today}
                    minTime={this.state.minTime}
                    maxTime={moment().endOf("day").toDate()}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <h4>Sélectionnez les invités à ajouter à la campagne</h4>
            </div>
            <CampaignFilter
              nameFilter={nameFilter}
              handleNameFilter={this.filterElement}
              companyFilter={companyFilter}
              handleCompanyFilter={this.filterElement}
              langueFilter={langueFilter}
              handleLangueFilter={this.filterElement}
              packageIdFilter={packageIdFilter}
              handlePackageIdFilter={this.filterElement}
              delegataireFilter={delegataireFilter}
              handleDelegataireFilter={this.filterElement}
              reponseFilter={reponseFilter}
              handleReponseFilter={this.filterElement}
              documentFilter={documentFilter}
              handleDocumentFilter={this.filterElement}
              tagOptions={tagOptions}
              handleTagOptions={this.filterByTag}
              followerFilter={followerFilter}
              handleFollowerFilter={this.filterElement}
              maxFollowerFilter={maxFollowerFilter}
              handleMaxFollowerFilter={this.filterElement}
              packagesOptions={packagesOptions}
              delegatairesList={delegatairesList}
              followerMaxInvitationOptions={followerMaxInvitationOptions}
              guestCheckedFilter={guestCheckedFilter}
              handleGuestCheckedFilter={this.filterElement}
            />
            <div className="form-row">
              <div className="form-group col-md-12">
                <br />
                <b>
                  <span
                    className="flex-justify-center"
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    Cette campagne {campaign.sent ? "a été" : "sera"} envoyée à{" "}
                    <span style={{ color: "#a11448" }}>
                      &nbsp;{nbInvtés}&nbsp;
                    </span>
                    contacts
                  </span>
                </b>
                <table
                  className="table table-sm table-striped"
                  aria-disabled={campaign.sent ? true : false}
                >
                  <thead>
                    <tr>
                      <th scope="col">
                        <input
                          type="checkbox"
                          style={{
                            opacity: 1,
                            pointerEvents: "auto",
                            position: "relative",
                          }}
                          onChange={this.checkAll}
                          disabled={campaign.sent ? true : false}
                        />
                      </th>
                      <th scope="col">Nom</th>
                      <th scope="col">Société</th>
                      <th scope="col">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {guestsFiltered.map((guest, i) => {
                      const contact = contacts.filter(
                        (contact) => contact.id === guest.contact_id
                      )[0];
                      const company = contact
                        ? contact.company
                          ? contact.company.name
                          : ""
                        : "";
                      return i < numberPerPage * (currentPage + 1) &&
                        i >= numberPerPage * currentPage ? (
                        <tr key={i}>
                          <td>
                            <input
                              type="checkbox"
                              checked={
                                guestsChecked.filter(
                                  (guestChecked) => guestChecked.id === guest.id
                                ).length > 0
                              }
                              onChange={(e) => this.checkOne(e, guest)}
                              style={{
                                opacity: 1,
                                pointerEvents: "auto",
                                position: "relative",
                              }}
                              disabled={campaign.sent ? true : false}
                            />
                          </td>
                          <td>{guest.firstname + " " + guest.lastname}</td>
                          <td>{company}</td>
                          <td>{guest.email}</td>
                        </tr>
                      ) : null;
                    })}
                  </tbody>
                </table>
                <div className="row" style={{ listStyleType: "none" }}>
                  {
                    <Pagination
                      changePage={this.changePage}
                      currentPage={currentPage}
                      totalPages={totalPages}
                    />
                  }
                </div>
                <div className="flex-justify-center">
                  <b>
                    <span
                      className=""
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                      }}
                    >
                      Cette campagne {campaign.sent ? "a été" : "sera"} envoyée
                      à
                      <span style={{ color: "#a11448" }}>
                        &nbsp;{nbInvtés}&nbsp;
                      </span>
                      contacts
                    </span>
                  </b>
                </div>
              </div>
            </div>

            <br />
            {campaign.sent || campaign.error ? null : (
              <button
                type="submit"
                className="btn pink darken-1 float-right white-text"
                // disabled={mailData.exist === false ? true : false}
              >
                Enregistrer
              </button>
            )}
          </form>
        </div>
      </div>
    );
  }
}

export default CampaignForm;
