import React from "react";
import "./Delegataire.css";
import { Select } from "../../components/Select";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TextField from "@mui/material/TextField";
import { CustomMuiButton } from "../../components/CustomMuiButton";

export function DelegataireAddQuota({
  options,
  selectedOptions,
  placeholder,
  buttonText,
  type = "",
  addOption,
  removeOption,
  handleComponentQuota = () => null,
  getComponentTotalQuota = () => null,
  isOptionAlreadySelected,
  componentsQuotaData = null,
}) {
  const [activateSelect, setActivateSelect] = React.useState(false);

  const getOptionName = (id) => {
    const optFound = options.find((opt) => opt.value === id);
    return optFound ? optFound.label : null;
  };

  const handleAddingOption = (value) => {
    setActivateSelect(false);
    addOption(value);
  };

  const getMinimumQuota = (id) => {
    if (componentsQuotaData) {
      const cmpFound = componentsQuotaData.components.find(
        (cmp) => cmp.id === Number(id)
      );
      if (cmpFound && cmpFound.numberOfGuests > 0)
        return cmpFound.numberOfGuests;
    }
    return 1;
  };

  return (
    <div>
      <div className="quota-rows-container">
        {selectedOptions.map((opt, index) => (
          <div key={index} className="delegataire-quota-row">
            {type === "component" ? (
              <>

                  <div className="row-title">
                    <h4>{getOptionName(opt.id)}</h4>
                  </div>
                  <div className="quota-nbr-and-rmv-btn">
                    <div>
                      <TextField
                        className={options.length === 0 ? "d-none" : "d-flex"}
                        variant="standard"
                        type="number"
                        id="delegataire-quota-input"
                        value={opt.quota}
                        min={getMinimumQuota(opt.id)}
                        max={getComponentTotalQuota(opt.id)}
                        required
                        onChange={(e) => {
                          const min = getMinimumQuota(opt.id);
                          const max = getComponentTotalQuota(opt.id);

                          const value = Number(e.target.value);
                          console.log("🚀 ~ value:", { value, min, max });
                          if (value >= min && value <= max)
                            handleComponentQuota(opt.id, value);
                        }}
                        sx={{
                          width: "100%",
                          maxWidth: "64px",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& ::after": {
                            borderBottom: "2px solid #d81b60 !important",
                          },
                        }}
                      />
                    </div>
                    <div>
                      {opt.role !== 'entrance' ? (
                        <IconButton

                          type="button"
                          onClick={() => removeOption(opt.id)}
                        >
                          <DeleteOutlineOutlinedIcon className="delete-btn" />
                        </IconButton>

                      ) : null}
                    </div>
                  </div>
              </>
            ) : (
              <>
                <div className="row-title">
                  <h4>{getOptionName(opt)}</h4>
                </div>
                <div>
                  <IconButton type="button" onClick={() => removeOption(opt)}>
                    <DeleteOutlineOutlinedIcon className="delete-btn" />
                  </IconButton>
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      <div className="new-row-section">
        {activateSelect && (
          <Select
            handleSelectedValue={handleAddingOption}
            options={options}
            id="delegataire-quota-pck-select"
            displayArrowIcon={true}
            placeholder={placeholder}
            isOptionAlreadySelected={isOptionAlreadySelected}
            className="row-select-input"
            firstOptionItalic={false}
          />
        )}
        <CustomMuiButton
          onClick={() => setActivateSelect(true)}
          type="button"
          text={buttonText}
          minHeight={25}
          textColor="#ffffff"
          buttonBgColor="#d81b60"
          buttonHoverBgColor="#AF1242"
          buttonHoverBorderColor="#0062cc"
          buttonActiveBgColor="#d81b60"
        />
      </div>
    </div>
  );
}
