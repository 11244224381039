import React from "react";
import { Link } from "react-router-dom";
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavItem,
  MDBCollapse,
  MDBNavbarNav,
} from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUnlock,
  faFileInvoiceDollar,
  faUsers,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { webPageTranslations } from "../common/Translations";

import "./Header.css";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showMenu: false };
    this.switchMenu = this.switchMenu.bind(this);
  }

  switchMenu() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  render() {
    const { code, guest, eventId, contact, checkEventFromAdmin } = this.props;
    const { showMenu } = this.state;
    const contactCode = code ? code : "FR";
    return (
      <MDBNavbar
        className="navbar navbar-expand-lg navbar-center fixed-top"
        color="black"
        dark
        expand="md"
      >
        <MDBNavbarToggler onClick={this.switchMenu} />
        <MDBCollapse id="navbarCollapse3" isOpen={showMenu} navbar>
          <MDBNavbarNav left>
            <MDBNavItem>
              <Link className="nav-link white-text" to={"#"}>
                {webPageTranslations[contactCode].personal_space_title +
                  " - " +
                  checkEventFromAdmin ===
                true
                  ? contact
                    ? contact.lastname
                    : null
                  : guest
                  ? guest.lastname
                  : null + " " + checkEventFromAdmin === true
                  ? contact
                    ? contact.lastname
                    : null
                  : guest
                  ? guest.firstname
                  : null}
              </Link>
            </MDBNavItem>
            {/*<MDBNavItem>
              <Link
                className="nav-link white-text"
                to={
                  "/account/" +
                  eventId +
                  "/" +
                  guest.id +
                  "/" +
                  localStorage.getItem("invitation_token") +
                  "/events"
                }
              >
                <FontAwesomeIcon icon={faTachometerAlt} color="white" />
                &nbsp; {webPageTranslations[code].my_events}
              </Link>
            </MDBNavItem>*/}
            <MDBNavItem>
              <Link
                className="nav-link white-text"
                to={
                  checkEventFromAdmin === true && contact
                    ? "/account/admin/" + contact.id + "/invoices"
                    : "/account/" +
                      eventId +
                      "/" +
                      guest.id +
                      "/" +
                      localStorage.getItem("invitation_token") +
                      "/invoices"
                }
              >
                <FontAwesomeIcon icon={faFileInvoiceDollar} color="white" />
                &nbsp; {webPageTranslations[contactCode].my_invoices}
              </Link>
            </MDBNavItem>
            <MDBNavItem>
              <Link
                className="nav-link white-text"
                to={
                  checkEventFromAdmin === true && contact
                    ? "/account/admin/" + contact.id + "/team"
                    : "/account/" +
                      eventId +
                      "/" +
                      guest.id +
                      "/" +
                      localStorage.getItem("invitation_token") +
                      "/team"
                }
              >
                <FontAwesomeIcon icon={faUsers} color="white" />
                &nbsp; {webPageTranslations[contactCode].my_team}
              </Link>
            </MDBNavItem>
            <MDBNavItem>
              <Link
                className="nav-link white-text"
                to={
                  checkEventFromAdmin === true && contact
                    ? "/account/admin/" + contact.id + "/profil"
                    : "/account/" +
                      eventId +
                      "/" +
                      guest.id +
                      "/" +
                      localStorage.getItem("invitation_token") +
                      "/profil"
                }
              >
                <FontAwesomeIcon icon={faUser} color="white" />
                &nbsp; Profil
              </Link>
            </MDBNavItem>
          </MDBNavbarNav>
          <MDBNavbarNav right>
            <MDBNavItem>
              {!checkEventFromAdmin ? (
                <Link className="nav-link white-text" to={"/"}>
                  <FontAwesomeIcon icon={faUnlock} color="white" />
                  &nbsp; {webPageTranslations[contactCode].logout}
                </Link>
              ) : (
                <Link className="nav-link white-text" to={"/admin/contacts"}>
                  <FontAwesomeIcon icon={faUnlock} color="white" />
                  &nbsp; {webPageTranslations[contactCode].goBack}
                </Link>
              )}
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    );
  }
}

export default Header;
