import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const CheckinActions = ({
    checked,
    setChecked,
    deleteCheckin,
}) => {

    const [action, setAction] = React.useState("");

    React.useEffect(() => { }, [checked])

    const onSubmit = (action) => {
        switch (action) {
            case 'delete':
                if (
                    window.confirm("Des invités ont peut-être été validés sur ce point de check-in. Êtes-vous sûr de vouloir le supprimer ?")
                )
                    deleteCheckin(checked);
                break;
            default:
                break;
        }
    }

    return (
        <>
            {checked.length > 0 && (
                <div className="row mr-0 ml-0 actions-container actions-container-fixed justify-content-between align-items-center">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="text-white">
                                {checked.length} checkin{checked.length > 1 ? "s" : ""} sélectionné{checked.length > 1 ? "s" : ""}
                            </label>
                            <select
                                onChange={(e) => setAction(e.target.value)}
                                name="action"
                                className="col-sm-12 browser-default custom-select"
                                required
                            >
                                <option value="">Sélectionner une action groupée</option>
                                <option value="delete">Supprimer</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4 offset-md-3">
                        <button
                            type="submit"
                            disabled={action === ""}
                            onClick={() => onSubmit(action)}
                            className="btn white-text pink darken-1"
                        >
                            Valider
                        </button>
                    </div>
                    <div className="col-md-1 align-self-start" style={{ maxWidth: "fit-content" }}>
                        <FontAwesomeIcon
                            title="Fermer"
                            icon={faTimes}
                            onClick={() => setChecked([])}
                            color="white"
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default CheckinActions;