import React from "react";
import { Switch, Route } from "react-router-dom";
import ContactForm from "./ContactForm";
import ContactsContainer from "./ContactsContainer";

class Contacts extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          path="/admin/contacts/edit/:id?"
          render={(props) => (
            <ContactForm
              {...props}
              updateContacts={this.props.updateContacts}
              contacts={this.props.contacts}
            />
          )}
        />
        <Route
          path="/admin/contacts"
          render={(props) => (
            <ContactsContainer
              {...props}
              updateContacts={this.props.updateContacts}
              contacts={this.props.contacts}
            />
          )}
        />
      </Switch>
    );
  }
}

export default Contacts;
