import React from "react";
import InvoicesPdfsModal from "./InvoicesPdfsModal";
const DownloadPdfsInvoices = ({ invoices, eventFilter }) => {
  return (
    <>
      <InvoicesPdfsModal invoices={invoices} eventFilter={eventFilter} />
    </>
  );
};

export default DownloadPdfsInvoices;
