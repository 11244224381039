import React, { useEffect, useState } from "react";
import ApiService from "../services/ApiService";
/*
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBBtn,
} from "mdbreact";
*/
import {
  Box,
  Button,
  Paper,
  Typography,
  //Select,
  //CircularProgress,
} from "@mui/material";
import Loader from "../common/Loader";
import { webPageTranslations } from "../common/Translations";
import { toast } from "react-toastify";
// import { locationPush } from '../services/Utils';
//import Select from "../checkin/new/components/Select";

import {
  //Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from "@mui/material";

export default function PlacementSettings(props) {
  const [eventIdSelected, setEventIdSelected] = useState(null);
  const [isLoadingSettings, setIsLoadingSettings] = useState(false);
  const [events, setEvents] = useState([]);
  const [planSelected, setPlanSelected] = useState(null);
  const [plans, setPlans] = useState([]);
  const [guests, setGuests] = useState();
  const [reponses, setReponses] = useState();
  const [placement, setPlacement] = useState();
  const [delegatairesList, setDelegatairesList] = useState();
  const [components, setComponents] = useState();
  const [online, setOnline] = useState(window.navigator.onLine);
  useEffect(() => {
    getTodayEvents();
    setOnline(online);
  }, [online]);
  useEffect(() => {
    selectedEventBackFromPlan(props.eventId);
  }, [props.eventId]);

  useEffect(() => {
    if (events && events.length === 1 && !eventIdSelected) {
      handleChange({ target: { name: "eventIdSelected", value: events[0].id } });
    }
  }, [events]);

  var globalState = {
    guests,
    placement,
    components,
    reponses,
    delegatairesList,
  };

  async function getTodayEvents() {
    await ApiService.request({}, "evenements", "get").then(function (data) {
      const today = new Date().getTime();
      const eventsArr = data.filter((event) => {
        const eventDate = new Date(event.end_date.replace(/-/g, "/"));
        return eventDate.getTime() > today;
      });
      const sortDataWithStartDate = eventsArr.sort(function (a, b) {
        const dateA = a.start_date;
        const dateB = b.start_date;

        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }
        return 0;
      });
      setEvents(sortDataWithStartDate);
    });
  }
  async function selectedEventBackFromPlan(id) {
    if (id) {
      setIsLoadingSettings(true);
      setEventIdSelected(id);
      var data = await ApiService.request(
        {},
        "placements/byevenement/" + id,
        "get"
      );
      data.length > 0 ? setPlans(data) : toast.error("Aucun plan ajouté", 3000);
      setIsLoadingSettings(false);
    }
  }
  async function handleChange(event) {
    if (event.target.name === "eventIdSelected") {
      setIsLoadingSettings(true);
      const eventId = event.target.value;
      if (eventId === "") {
        setPlans([]);
        setEventIdSelected("");
      } else {
        setEventIdSelected(eventId);
        var data = await ApiService.request(
          {},
          "placements/byevenement/" + eventId,
          "get"
        );
        data.length > 0
          ? setPlans(data)
          : toast.error("Aucun plan ajouté", 3000);
        if (data.length === 1) {
          const planSelectedObj = data[0];
          //console.log(planSelectedObj);
          setPlanSelected(planSelectedObj);
          await loadData(planSelectedObj);
        }
      }
      setIsLoadingSettings(false);
    } else {
      const planSelectedObj = plans.find(
        (plan) => plan.id === Number(event.target.value)
      );
      console.log(planSelectedObj.id, "ID PLAN");
      setPlanSelected(planSelectedObj);
      setIsLoadingSettings(true);
      await loadData(planSelectedObj);
      setIsLoadingSettings(false);
    }
  }
  async function loadData(placement) {
    var guestsData;
    var componentsData;
    var placementData;
    var reponseData;

    await ApiService.request(
      {},
      "delegataires/byevenement/" + placement.evenement_id,
      "get"
    ).then(function (delegatairesList) {
      setDelegatairesList(delegatairesList);
    });

    await ApiService.request(
      globalState,
      "evenement_forms/byevenement/" + placement.evenement_id,
      "get"
    ).then(async function (data) {
      const form = data.find((form) => form.layout.documentType === "reponse");
      await ApiService.request(
        {},
        "component_responses/byevenement/" + form.id,
        "get"
      ).then(function (data) {
        reponseData = data;
        setReponses(reponseData);
      });
    });
    await ApiService.request(
      {},
      "components/byevenement/" + placement.evenement_id,
      "get"
    ).then(function (data) {
      componentsData = data.filter(
        (component) =>
          component.data.componentType === "logistic" ||
          component.data.componentType === "moment"
      );
      setComponents(componentsData);
    });
    await ApiService.request({}, "placements/" + placement.id, "get").then(
      function (placement) {
        placementData = placement;
        setPlacement(placementData);
      }
    );

    await ApiService.request(
      {},
      "guests/byevenement/" + placement.evenement_id,
      "get"
    ).then(function (guests) {
      guestsData = guests;
      setGuests(guests);
    });
    if (guestsData && componentsData && placementData && reponseData) {
      filter(componentsData, guestsData, placementData, reponseData);
    }
    props.setEventId(placement.evenement_id);
    props.setPlanId(placement.id);
  }
  async function filter(componentsArr, guestsArr, placementObj, reponseArr) {
    const componentFiltered = componentsArr
      ? componentsArr.filter((component) =>
          placementObj
            ? placementObj.data.components.some((item) => item === component.id)
            : null
        )
      : null;
    setComponents(componentFiltered);
    const guestsFiltered = guestsArr
      ? guestsArr.filter((guest) => {
          const reponse = reponseArr
            ? reponseArr.find((reponse) => reponse.guest_id === guest.id)
            : null;

          let willJoin = false;

          if (reponse) {
            if (reponse.responses && reponse.responses.length > 0)
              reponse.responses.forEach((componentReponse) => {
                const component = componentFiltered.find(
                  (component) => component.id === componentReponse.id
                );

                if (component) {
                  if (
                    (component.data.reponse.htmlType === "radio" &&
                      componentReponse.key !== 1) ||
                    component.data.reponse.htmlType === "checkbox"
                  ) {
                    willJoin = true;
                  }
                }
              });
          }
          return willJoin;
        })
      : null;
    setGuests(guestsFiltered);
  }
  const goToPlan = () => {
    props.setGuestsState(guests);
    props.setPlacementState(placement);
    props.setDelegatairesList(delegatairesList);
    props.setSettingsMode(false);
  };
  if (isLoadingSettings) {
    return <Loader />;
  }
  return (
    <Box sx={{ paddingInline: "1rem" }}>
      <Paper
        sx={{
          maxWidth: 600,
          margin: "auto",
          marginTop: "4rem",
          padding: "1.5rem 1.5rem 1rem 1.5rem",
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          color="#4F4F4F"
          component="div"
          textAlign="center"
        >
          Selectionnez votre plan
        </Typography>

        <br />

        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth required>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#d81b60",
                },
              }}
              //id={`select-input-label-${id}`}
            >
              {webPageTranslations["FR"].select_event2}
            </InputLabel>
            <MuiSelect
              onChange={handleChange}
              name="eventIdSelected"
              value={eventIdSelected || (events && events.length === 1 ? events[0].id : '')}
              displayEmpty={false}
              label={webPageTranslations["FR"].select_event2}
              sx={{
                width: "100%",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#d81b60",
                },
              }}
            >
              {events
                ? events.map((option) => (
                    <MenuItem value={option.id}>{option.data.name}</MenuItem>
                  ))
                : null}
            </MuiSelect>
          </FormControl>
        </Box>

        <br />

        {/*
              <select
                required
                onChange={handleChange}
                name="eventIdSelected"
                className="form-control browser-default custom-select"
                value={eventIdSelected}
              >
                <option value="">
                  {webPageTranslations["FR"].select_event2}
                </option>
                {events
                  ? events.map((option) => (
                    <option value={option.id}>{option.data.name}</option>
                  ))
                  : null}
              </select>
              */}

        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth required>
            <InputLabel
              sx={{
                "&.Mui-focused": {
                  color: "#d81b60",
                },
              }}
              //id={`select-input-label-${id}`}
            >
              Sélectionner un plan
            </InputLabel>
            <MuiSelect
              onChange={handleChange}
              name="componentSelected"
              value={planSelected ? planSelected.id : null}
              displayEmpty={false}
              label="Sélectionner un plan"
              sx={{
                width: "100%",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#d81b60",
                },
              }}
            >
              {plans
                ? plans.map((option) => (
                    <MenuItem value={option.id}>{option.name}</MenuItem>
                  ))
                : null}
            </MuiSelect>
          </FormControl>
        </Box>

        <br />

        {/*
              <br />
              <select
                required
                onChange={handleChange}
                name="componentSelected"
                className="form-control browser-default custom-select"
                value={planSelected ? planSelected.id : null}
              >
                <option value="">Sélectionner un plan</option>
                {plans
                  ? plans.map((option) => (
                    <option value={option.id}>{option.name}</option>
                  ))
                  : null}
              </select>
              <br />
              */}

        <div className="text-center mb-3">
          {/*
                <MDBBtn
                  disabled={!eventIdSelected || !planSelected}
                  onClick={() => goToPlan()}
                  rounded
                  type="submit"
                  className="btn-block z-depth-1a pink darken-1"
                >
                  Valider la selection
                </MDBBtn>
                */}

          <Button
            disabled={!eventIdSelected || !planSelected}
            onClick={() => goToPlan()}
            variant="contained"
            sx={{
              backgroundColor: "#d81b60",
              fontWeight: "bold",
              borderRadius: "20px",
              width: "80%",
              ":hover": {
                backgroundColor: "#d81b60",
                opacity: 0.9,
              },
            }}
          >
            Valider la selection
          </Button>
        </div>

        {/* <button onClick={() => locationPush("webapp")} className="btn cancel-button">
              Retour
            </button> */}
        {/*
            <button onClick={props.doLogout} className="btn cancel-button">
              Se déconnecter
            </button>
            */}

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#454545",
            fontWeight: "bold",
            borderRadius: "5px",
            marginTop: "1rem",
            width: "100%",
            ":hover": {
              backgroundColor: "#454545",
              opacity: 0.9,
            },
          }}
          size="large"
          onClick={props.doLogout}
        >
          SE DÉCONNECTER
        </Button>
      </Paper>
    </Box>
  );
}
