import React from "react";
import { webPageTranslations } from "./Translations";

export default function InvoiceDetails({ invoice, code }) {
  const {
    total_vat_0_before_balance,
    total_vat_10_before_balance,
    total_vat_5_before_balance,
    total_vat_20_before_balance,
    totalHT_before_balance,
    totalTTC,
    products,
    previousAmount,
  } = invoice;

  return (
    <div
      className="row"
      style={{
        backgroundColor: "#EEEEEE",
      }}
    >
      <div className="col-md-12">
        <h4>{webPageTranslations[code].your_order}</h4>
        <div
          className="row"
          style={{
            padding: "10px",
            margin: "10px",
            backgroundColor: "white",
          }}
        >
          <table
            className="table table-sm table-striped"
            style={{ textAlign: "center" }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "white",
                }}
              >
                <th scope="col">{webPageTranslations[code].product}</th>
                <th scope="col">{webPageTranslations[code].unit_price_vat}</th>
                <th scope="col">{webPageTranslations[code].unit_price}</th>
                <th scope="col">{webPageTranslations[code].quantity}</th>
                <th scope="col">{webPageTranslations[code].total_vat}</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, i) => (
                <tr key={`invoice-details-product-${i}`}>
                  <td>{product.label}</td>
                  <td>{parseFloat(product.priceHT).toFixed(2)}</td>
                  <td>{parseFloat(product.priceTTC).toFixed(2)}</td>
                  <td>X {product.quantity}</td>
                  <td>{parseFloat(product.totalht).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-auto offset-md-8 font-weight-bold">
            {webPageTranslations[code].sub_total}
          </div>
          <div className="col-md-auto ">
            {parseFloat(totalHT_before_balance).toFixed(2)}
          </div>
        </div>

        {total_vat_0_before_balance !== 0 ? (
          <div className="row">
            <div className="col-md-auto offset-md-8 font-weight-bold">
              {webPageTranslations[code].vat} (0%)
            </div>
            <div className="col-md-auto">
              {parseFloat(total_vat_0_before_balance).toFixed(2)}
            </div>{" "}
          </div>
        ) : null}
        {total_vat_5_before_balance !== 0 ? (
          <div className="row">
            <div className="col-md-auto offset-md-8 font-weight-bold">
              {webPageTranslations[code].vat} (5,5%)
            </div>
            <div className="col-md-auto">
              {parseFloat(total_vat_5_before_balance).toFixed(2)}
            </div>{" "}
          </div>
        ) : null}
        {total_vat_10_before_balance !== 0 ? (
          <div className="row">
            <div className="col-md-auto offset-md-8 font-weight-bold">
              {webPageTranslations[code].vat} (10%)
            </div>
            <div className="col-md-auto">
              {parseFloat(total_vat_10_before_balance).toFixed(2)}
            </div>{" "}
          </div>
        ) : null}
        {total_vat_20_before_balance !== 0 ? (
          <div className="row">
            <div className="col-md-auto offset-md-8 font-weight-bold">
              {webPageTranslations[code].vat} (20%)
            </div>
            <div className="col-md-auto">
              {parseFloat(total_vat_20_before_balance).toFixed(2)}
            </div>{" "}
          </div>
        ) : null}
        {previousAmount ? (
          <div className="row">
            <div className="col-md-auto offset-md-8 font-weight-bold">
              {webPageTranslations[code].already_paid}
            </div>
            <div className="col-md-auto">
              {parseFloat(previousAmount).toFixed(2)}
            </div>{" "}
          </div>
        ) : null}

        <div className="row">
          <div className="col-md-auto offset-md-8 font-weight-bold">
            {webPageTranslations[code].total}
          </div>
          <div className="col-md-auto">{parseFloat(totalTTC).toFixed(2)} €</div>
        </div>
      </div>{" "}
    </div>
  );
}
