import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function SelectComponentImport({
  array,
  rows,
  column,
  selectChange,
  emptySelect,
  disabled,
  columnKey,
  typePage,
  setSelects,
  setSelectChange,
  data,
  setData,
  validate,
  setValidate
}) {
  const [items, setItems] = useState(array)
  const [item, setItem] = useState({ name: "", value: "" });
  const [oldValue, setOldValue] = useState("");
  const [refreshComponent, setRefreshComponent] = useState(false);

  useEffect(() => {
    detectSelectFirstLine()
  }, [typePage])

  useEffect(() => {
    setItems(array)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [array, selectChange]);

  useEffect(() => { }, [disabled, refreshComponent]);

  const detectSelectFirstLine = () => {
    if (typePage.subPage === "config") {
      const find = items.find((select) => select.value.toLowerCase() === column.name.toLowerCase())
      if (find) {
        handleChange("", column.key, true, find.value)
        setRefreshComponent(!refreshComponent)
      }
    }
  }
  const handleChange = (e, columnKey, fromDetect, value) => {
    const val = fromDetect ? value.toLowerCase() : e.target.value.toLowerCase();
    const obj = items.find((element) => element.value.toLowerCase() === val);
    setItem(obj ? obj : { name: "", value: "" });
    setOldValue(val);
    disabledInSelect(val, oldValue);
    createArrayForImport(columnKey, val);
  };

  const disabledInSelect = (itemValue, oldItemValue) => {
    switch (itemValue) {
      case "mail":
        validate.mail = true
        setValidate(validate)
        break;
      case "lastname":
        validate.lastname = true
        setValidate(validate)
        break;
      default:
        break;
    }
    switch (oldItemValue) {
      case "mail":
        validate.mail = false
        setValidate(validate)
        break;
      case "lastname":
        validate.lastname = false
        setValidate(validate)
        break;
      default:
        break;
    }
    const arr = items;
    const obj = arr.find((element) => element.value.toLowerCase() === itemValue);
    const objOld = arr.find((element) => element.value.toLowerCase() === oldItemValue);
    if (objOld) {
      objOld.disabled = false;
    }
    if (obj) {
      obj.disabled = true;
    }
    setSelects(arr);
    setSelectChange(!selectChange);
  };

  const createArrayForImport = (key, value) => {
    if (key > -1) {
      let arr = data;
      if (value === "") {
        const map = arr.map((row) => {
          return row.filter((d) => {
            if (d.key !== key) {
              return d;
            }
            return null;
          })
        })
        arr = map
      } else {
        rows.map((row, index) => {
          const val = row[key]
          const obj = {
            column: value,
            key,
            value: val
          }
          if (Array.isArray(arr[index]) && arr[index].length > 0) {
            const find = arr[index].find((obj) => obj.key === key)
            if (find) {
              find.column = value
              find.value = val
            } else {
              arr[index].push(obj)
            }
          }
          else {
            arr[index] = [];
            arr[index].push(obj)
          }
          return row;
        })
      }
      setData(arr)
      setSelectChange(!selectChange);
    }
  };

  return (
    <FormControl fullWidth>
      <Select
        labelId=""
        className="selectImport"
        id={
          item.value
            ? "selectImportActif"
            : "selectImportEmptyWhite"
        }
        value={item.value}
        name={item.name}
        onChange={(e) => handleChange(e, columnKey, false, "")}
        displayEmpty
        disabled={disabled ? disabled : false}
      >
        <MenuItem value="" className="selectItemEmpty">
          {emptySelect ? emptySelect : ""}
        </MenuItem>
        {items
          ? items.map((element, key) => {
            return (
              <MenuItem
                key={key}
                value={element.value}
                disabled={element.disabled}
                className="selectItem"
              >
                {element.name}
              </MenuItem>
            );
          })
          : null}
      </Select>
    </FormControl>
  );
}
