import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";

function CustomSelect({
  options,
  selectedValue,
  handleSelectedValue,
  id,
  displayArrowIcon = true,
  placeholder,
  optionsContainerColor = undefined,
  optionsContainerBorderColor = undefined,
  selectMarginLeft = undefined,
  selectMarginRight = undefined,
  selectBorderStyle,
  inputAdornmentText = null,
  disable = false,
}) {
  return (
    <FormControl
      sx={{
        m: 1,
        marginRight: selectMarginRight,
        marginLeft: selectMarginLeft,
        minWidth: 120,
        height: "fit-content",
      }}
      size="small"
    >
      <InputLabel disableAnimation sx={{ color: "#A2A2A2" }}>
        {selectedValue ? null : placeholder}
      </InputLabel>
      <TextField
        // labelId={`segment-select-small-label-${id}`}
        id={id}
        value={selectedValue}
        onChange={(event) => handleSelectedValue(event.target.value)}
        select
        size="small"
        disabled={disable}
        SelectProps={{
          IconComponent: displayArrowIcon ? undefined : null,
          //   displayEmpty: true,
          MenuProps: {
            sx: {
              height: 250,
              marginTop: "1rem",
            },
            PaperProps: {
              sx: {
                backgroundColor: optionsContainerColor,
                border: `1px solid ${optionsContainerBorderColor}`,
              },
            },
          },
          startAdornment:
            inputAdornmentText && selectedValue ? (
              <InputAdornment position="start">
                {inputAdornmentText}
              </InputAdornment>
            ) : undefined,
        }}
        sx={{
          minWidth: "250px",
          backgroundColor: "#ffffff",
          ...selectBorderStyle,
          "& .MuiSelect-select": {
            color: "rgba(0, 0, 0, 0.87)",
            ":focus": {
              borderRadius: "0.7rem",
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
        {options &&
          options.map((opt, i) => {
            opt.value =
              typeof opt.value === "string"
                ? opt.value.toLowerCase()
                : opt.value;
            // console.log("🚀 ~ opt.value:", opt.value);

            return i !== 0 ? (
              <MenuItem key={`${id}-${opt.value}-${i}`} value={opt.value}>
                {opt.label}
              </MenuItem>
            ) : (
              <MenuItem key={`${id}-${opt.value}-${i}`} value={opt.value}>
                <em>{opt.label}</em>
              </MenuItem>
            );
          })}
      </TextField>
    </FormControl>
  );
}

export function DoubleSelect({
  firstSelectValue,
  handleFirstSelectValue,
  secondSelectValue,
  handleSecondSelectValue,
  firstSelectOptions,
  secondSelectOptions,
}) {
  return (
    <div>
      <CustomSelect
        selectedValue={firstSelectValue}
        handleSelectedValue={handleFirstSelectValue}
        options={firstSelectOptions}
        id="list-double-select-first"
        displayArrowIcon={true}
        placeholder="Composants"
        selectMarginRight="0px"
        selectBorderStyle={{
          borderRadius: "0.7rem 0px 0px 0.7rem",
          border: "1px solid #000000 !important",
          borderRight: "0px !important",
        }}
      />
      <CustomSelect
        selectedValue={secondSelectValue}
        handleSelectedValue={handleSecondSelectValue}
        disable={firstSelectValue ? false : true}
        options={secondSelectOptions}
        id="list-double-select-first"
        displayArrowIcon={true}
        placeholder="Réponses"
        selectMarginLeft="0px"
        selectBorderStyle={{
          borderRadius: "0px 0.7rem 0.7rem 0px",
          border: "1px solid #000000 !important",
          borderLeft: "0px !important",
        }}
        inputAdornmentText="Réponse ="
      />
    </div>
  );
}
