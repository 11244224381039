import React, { Component } from "react";
import ApiService from "../../../services/ApiService";
import EventConfig from "./EventConfig";
import EventConfigMessages from "./EventConfigMessages";
import EventConfigEmails from "./EventConfigEmails";
import EventConfigPassports from "./EventConfigPassports";
import EventConfigTypes from "./EventConfigTypes";
import FormsList from "./forms/FormsList";
import FormContainer from "./forms/FormContainer";
import ComponentsList from "../components/ComponentsList";
import ComponentContainer from "./ComponentContainer";
import TemplateForm from "./TemplateForm";
import RulesContainer from "./RulesContainer";
import PackagesContainer from "./PackagesContainer";
import DelegatairesContainer from "./delegataires/DelegatairesContainer";
import PriceRulesContainer from "./PriceRulesContainer";
import CampaignForm from "./CampaignForm";
import CampaignsList from "./CampaignsList";
import { SmsList } from "./SmsList";
import SmsForm from "./Sms/SmsForm";
import DashBoardContainer from "./DashBoardContainer";
import Placements from "./placements/Placements";
import TemplatesList from "./TemplatesList";
import GuestsContainer from "./GuestsContainer";
import { MDBSideNavNav, MDBSideNavCat } from "mdbreact";
import { Switch, Route, NavLink } from "react-router-dom";
import {
  existingResponseMessage,
  languagesList,
  messageWillJoinEvent,
  messageWillNotJoinEvent,
  registrationExpiredText,
} from "../../../common/Translations";
import Loader from "../../../common/Loader";
import "./EventContainer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import {
  checkNotAccess,
  formatDate,
  returnErrorHtml,
} from "../../../services/Utils";
import { toast } from "react-toastify";
import CheckinComponentMain from "./checkin_component/CheckinComponentMain";
import LanguageContainer from "./LanguageContainer";
// import LanguageForm from "./LanguageForm";

class EventContainer extends Component {
  constructor(props) {
    super(props);

    this.saveEvent = this.saveEvent.bind(this);
    this.loadData = this.loadData.bind(this);
    this.updateComponents = this.updateComponents.bind(this);
    this.disableMailNotifications = this.disableMailNotifications.bind(this);

    this._isMounted = false;

    this.state = {
      event: {
        children_entity_id: "",
        start_date: new Date(),
        end_date: new Date(),
        mail_sender_address: "contact@kanguroo-event.fr",
        mail_sender_name: "Kanguroo Event",
        data: {
          maintenanceMode: false,
          guestsTypes: [],
          useFirstname: true,
          useLastname: true,
          useEmail: false,
          usePhone: false,
          dontUseSubTypePriceRule: false,
          guestPriceRules: [],
          followerpriceRules: [],
          config: {},
          qrCode: true,
          checkIn: "true",
          audience: "private",
          free: true,
          default_language: [languagesList[0].code], //default language when we create an event is 'FR'
          reponseLabel: languagesList,
          programLabel: languagesList,
          passportLabel: languagesList,
          isPreFilled: false,
          invoiceTemplate: languagesList,
          refundInvoiceTemplate: languagesList,
          refundInvoiceDelegataireTemplate: languagesList,
          invoiceDelegataireTemplate: languagesList,
          pendingConfirmationTemplate: languagesList,
          pendingConfirmationDelegataireTemplate: languagesList,
          registrationConfirmationTemplate: languagesList,
          registrationConfirmationDelegataireTemplate: languagesList,
          modificationConfirmationTemplate: languagesList,
          modificationConfirmationDelegataireTemplate: languagesList,
          willNotJoinAndRefundTemplate: languagesList,
          willNotJoinAndRefundDelegataireTemplate: languagesList,
          messageWillJoinEvent: messageWillJoinEvent,
          messageWillNotJoinEvent: messageWillNotJoinEvent,
          messageWillNotJoinEventAndGetRefund: languagesList,
          passportTextlineone: languagesList,
          passportTextlinetwo: languagesList,
          passportTextlinethree: languagesList,
          passportTextlinefour: languagesList,
          passportTextlinefive: languagesList,
          passportTextlinesix: languagesList,
          passportBoldlineone: false,
          passportBoldlinetwo: false,
          passportBoldlinethree: false,
          passportBoldlinefour: false,
          passportBoldlinefive: false,
          passportBoldlinesix: false,
          passportColorlineone: "#000000",
          passportColorlinetwo: "#000000",
          passportColorlinethree: "#000000",
          passportColorlinefour: "#000000",
          passportColorlinefive: "#000000",
          passportColorlinesix: "#000000",
          passportSizelineone: 12,
          passportSizelinetwo: 12,
          passporSizelinethree: 12,
          passportSizelinefour: 12,
          passportSizelinefive: 12,
          passportSizelinesix: 12,
          enablePassportForGuest: false,
          enablePassportForDelegataireGuest: false,
          contactInformations: languagesList,
          existingResponseMessage: existingResponseMessage,
          name: "Nouvel événement",
          backgroundImgUrl: languagesList,
          allowModification: true,
          registrationExpiredText: registrationExpiredText,
          eventFullText: languagesList,
          passportName: "",
          marketingBeginDate: new Date(),
          preRegisterDate: new Date(),
          modificationLimitDate: new Date(),
          registerDate: new Date(),
          registerEndDate: new Date(),
          marketingEndDate: new Date(),
          logo: null,
          delegataireLimitDate: [{ key: 0, date_limit: new Date() }],
        },
        is_active: true,
        component_rules: {
          componentsRules: [],
          tagsRules: [],
          timeRules: [],
          singleComponentSelectRule: false,
        },
        disable_notifications: false,
        delegataire_archived: false,
        checkin_points: [],
      },
      entities: [],
      templatesList: [],
      isLoading: true,
      hasAccess: {
        access: true,
        reason: "",
      },
    };
  }

  async saveEvent(event, toastMessage = "Évènement sauvegardé.") {
    const self = this;

    this.setState({ isLoading: true }, async () => {
      let method = self.state.event.id ? "put" : "post";
      let id = self.state.event.id ? self.state.event.id : "";

      const newEvent = {
        ...self.state.event,
        ...event,
      };

      const updatedEvent = await ApiService.awaitRequest(
        {
          ...newEvent,
        },
        "evenements/" + id,
        method
      );

      if (updatedEvent) {
        if (method === "post") {
          window.location.replace("/admin/events/edit/" + updatedEvent.id);
        } else {
          toast.success(toastMessage);
          self.loadData();
        }
      } else {
        toast.error("Erreur lors de l'enregistrement");
        self.setState({ isLoading: false });
      }
    });
  }

  async loadData() {
    this._isMounted && this.setState({ isLoading: true });

    const entities = await ApiService.awaitRequest(
      {},
      "children_entities",
      "get"
    );

    if (this.props.match.params.eventid) {
      const event = await ApiService.awaitRequest(
        this.state,
        "evenements/" + this.props.match.params.eventid,
        "get"
      );

      const templatesList = await ApiService.awaitRequest(
        {},
        "mailtemplates/byevenement/" + this.props.match.params.eventid,
        "get"
      );

      const eventComponents = await ApiService.awaitRequest(
        {},
        "components/byevenement/" + this.props.match.params.eventid,
        "get"
      );

      localStorage.setItem("eventId", this.props.match.params.eventid);
      this._isMounted &&
        this.setState({
          isLoading: false,
          entities,
          eventComponents,
          templatesList,
          event: event
            ? {
                ...this.state.event,
                ...event,
                data: {
                  ...this.state.event.data,
                  ...event.data,
                },
              }
            : null,
        });
    } else {
      this._isMounted && this.setState({ isLoading: false, entities });
    }
  }

  async updateComponents(component_ids) {
    await ApiService.request(
      {
        ...this.state.event,
        component_ids: component_ids,
      },
      "evenements/" + this.props.match.params.eventid,
      "put"
    );
    this.loadData();
  }

  async componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.loadData();
  }

  async componentWillUnmount() {
    this._isMounted = false;
    const self = this;
    this.setState({ isLoading: true });
    if (this.props.match.params.eventid) {
      const event = await ApiService.awaitRequest(
        this.state,
        "evenements/" + this.props.match.params.eventid,
        "get"
      );

      const workspaces = await ApiService.awaitRequest(
        {},
        "errorWorkspaces/",
        "get"
      ).then(function (data) {
        self.setState({
          errorsWorkspaces: data,
        });
        return data;
      });

      const access = await checkNotAccess(
        workspaces,
        "",
        this.props.match.params.eventid,
        event,
        ""
      );

      this.setState({ hasAccess: access });
      if (event) {
        this.loadData();
      }
    }

    this.setState({ isLoading: false });
  }

  async disableMailNotifications() {
    const self = this;

    await ApiService.request(
      {},
      "evenements/disablenotifications/" + this.props.match.params.eventid,
      "post"
    ).then((data) => {
      toast.success(
        data.disable_notifications
          ? "Vous venez de désactiver les emails de notification depuis le back office."
          : "Vous venez de ré-activer les emails de notification depuis le back office."
      );
      self.loadData();
    });
  }

  render() {
    const {
      event,
      isLoading,
      eventComponents,
      templatesList,
      entities,
      hasAccess,
    } = this.state;

    let menu = [];
    if (event) {
      menu = [
        {
          label: "Configuration",
          link: "/admin/events/edit/" + event.id + "/",
        },
        {
          label: "Placement",
          link: "/admin/events/edit/" + event.id + "/placement",
        },
        {
          label: "Checkin",
          link: "/admin/events/edit/" + event.id + "/components/checkin",
        },
        {
          label: "Configuration avancée",
          link: "/admin/events/edit/" + event.id + "/advanced",
        },
        {
          label: "Accompagnants",
          link: "/admin/events/edit/" + event.id + "/types",
        },
        {
          label: "Messages",
          link: "/admin/events/edit/" + event.id + "/messages",
        },
        {
          label: "Emails",
          link: "/admin/events/edit/" + event.id + "/emails",
        },
        {
          label: "Passeports",
          link: "/admin/events/edit/" + event.id + "/passports",
        },
        {
          label: "Composants",
          link: "/admin/events/edit/" + event.id + "/components",
        },
        {
          label: "Pages web",
          link: "/admin/events/edit/" + event.id + "/forms",
        },
        { label: "Règles", link: "/admin/events/edit/" + event.id + "/rules" },
        {
          label: "Packages",
          link: "/admin/events/edit/" + event.id + "/packages",
        },
        {
          label: "Délégataires",
          link: "/admin/events/edit/" + event.id + "/delegataires",
        },
        {
          label: "Invitations",
          link: "/admin/events/edit/" + event.id + "/guests",
        },
        {
          label: "Templates",
          link: "/admin/events/edit/" + event.id + "/templates",
        },
        {
          label: "Campagnes",
          link: "/admin/events/edit/" + event.id + "/campaigns",
        },
        {
          label: "Dashboard",
          link: "/admin/events/edit/" + event.id + "/dashboard",
        },
        {
          label: "Campagnes SMS",
          link: "/admin/events/edit/" + event.id + "/sms",
        },
        {
          label: "Tarification",
          link: "/admin/events/edit/" + event.id + "/prices",
        },
        {
          label: "Langues",
          link: "/admin/events/edit/" + event.id + "/languages",
        },
      ];
    }

    const subMenu = (
      <div className="row mb-3 mt-3 custom-nav">
        <NavLink
          className={` col-md-2 ml-2 ${
            this.props.location.pathname ===
            "/admin/events/edit/" + this.props.match.params.eventid + "/"
              ? "active-custom-nav"
              : null
          } nav-link`}
          to={"/admin/events/edit/" + this.props.match.params.eventid + "/"}
        >
          CONFIGURATION
        </NavLink>
        <NavLink
          className={` col-md-2 ml-2 ${
            this.props.location.pathname ===
            "/admin/events/edit/" + this.props.match.params.eventid + "/types"
              ? "active-custom-nav"
              : null
          } nav-link`}
          to={
            "/admin/events/edit/" + this.props.match.params.eventid + "/types"
          }
        >
          ACCOMPAGNANTS
        </NavLink>
        <NavLink
          className={` col-md-2 ml-2 ${
            this.props.location.pathname ===
            "/admin/events/edit/" +
              this.props.match.params.eventid +
              "/messages"
              ? "active-custom-nav"
              : null
          } nav-link`}
          to={
            "/admin/events/edit/" +
            this.props.match.params.eventid +
            "/messages"
          }
        >
          MESSAGES
        </NavLink>
        <NavLink
          className={` col-md-2 ml-2 ${
            this.props.location.pathname ===
            "/admin/events/edit/" + this.props.match.params.eventid + "/emails"
              ? "active-custom-nav"
              : null
          } nav-link`}
          to={
            "/admin/events/edit/" + this.props.match.params.eventid + "/emails"
          }
        >
          EMAILS
        </NavLink>
        <NavLink
          className={` col-md-2 ml-2 ${
            this.props.location.pathname ===
            "/admin/events/edit/" +
              this.props.match.params.eventid +
              "/passports"
              ? "active-custom-nav"
              : null
          } nav-link`}
          to={
            "/admin/events/edit/" +
            this.props.match.params.eventid +
            "/passports"
          }
        >
          PASSEPORTS
        </NavLink>
      </div>
    );

    const label = menu.find(
      (item) => item.link === this.props.location.pathname
    );

    if (isLoading) {
      return <Loader />;
    }
    if (!hasAccess.access) {
      return (
        <div className="row">
          <div className="col-md-10 offset-md-1 main-content">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 500,
              }}
            >
              {returnErrorHtml(hasAccess.content)}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="row breadcrumb">
            <div className="col-md-6 offset-md-2">
              Evénements / {event.data.name} / {label ? label.label : null}
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              {event.id ? (
                <div className="side-nav fixed" style={{ top: "55px" }}>
                  <div
                    className="side-nav-eventcontainer"
                    style={{
                      backgroundSize: "cover",
                      backgroundImage:
                        "url(" + process.env.PUBLIC_URL + "/bg-leftnav.jpg)",
                    }}
                  >
                    {event.data.logo ? (
                      <div className="side-nav-eventlogo">
                        <img
                          alt="logo"
                          src={`https://res.cloudinary.com/kanguroo-event/image/upload/w_150/${event.data.logo}`}
                        />
                      </div>
                    ) : null}
                    <div className="side-nav-eventname">
                      <strong>{event.data.name}</strong>
                      <br />
                      du {formatDate(event.start_date, false)} au{" "}
                      {formatDate(event.end_date, false)}
                    </div>
                  </div>
                  <ul className="custom-scrollbar">
                    <MDBSideNavNav>
                      <NavLink
                        className="nav-link"
                        to={"/admin/events/edit/" + event.id + "/dashboard"}
                      >
                        <FontAwesomeIcon icon={faTachometerAlt} color="white" />
                        &nbsp; Dashboard
                      </NavLink>

                      <MDBSideNavCat
                        icon="cog"
                        name={"Paramétrage"}
                        id="settings"
                      >
                        <NavLink
                          className="nav-link"
                          exact
                          to={"/admin/events/edit/" + event.id + "/"}
                        >
                          Configuration
                        </NavLink>
                        <NavLink
                          className="nav-link"
                          to={
                            "/admin/events/edit/" +
                            event.id +
                            "/components/list"
                          }
                        >
                          Composants
                        </NavLink>
                        <NavLink
                          className="nav-link"
                          to={"/admin/events/edit/" + event.id + "/rules"}
                        >
                          Règles de composants
                        </NavLink>
                        <NavLink
                          className="nav-link"
                          to={"/admin/events/edit/" + event.id + "/packages"}
                        >
                          Packages
                        </NavLink>
                        <NavLink
                          className="nav-link"
                          to={"/admin/events/edit/" + event.id + "/forms"}
                        >
                          Pages web
                        </NavLink>
                      </MDBSideNavCat>
                      <MDBSideNavCat
                        icon="users"
                        name="Invitations"
                        id="invitations"
                      >
                        <NavLink
                          className="nav-link"
                          to={"/admin/events/edit/" + event.id + "/guests"}
                        >
                          Invités
                        </NavLink>
                        <NavLink
                          className="nav-link"
                          to={
                            "/admin/events/edit/" + event.id + "/delegataires"
                          }
                        >
                          Délégataires
                        </NavLink>
                        <NavLink
                          className="nav-link"
                          to={"/admin/events/edit/" + event.id + "/templates"}
                        >
                          Templates
                        </NavLink>
                        <NavLink
                          className="nav-link"
                          to={"/admin/events/edit/" + event.id + "/campaigns"}
                        >
                          Campagnes
                        </NavLink>
                        <NavLink
                          className="nav-link"
                          to={"/admin/events/edit/" + event.id + "/sms"}
                        >
                          Campagnes SMS
                        </NavLink>

                        <NavLink
                          className="nav-link"
                          to={
                            "/admin/events/edit/" +
                            event.id +
                            "/components/checkin"
                          }
                        >
                          Checkin
                        </NavLink>
                      </MDBSideNavCat>
                      <MDBSideNavCat
                        icon="sitemap"
                        name="Placement"
                        id="placement"
                      >
                        <NavLink
                          className="nav-link"
                          to={"/admin/events/edit/" + event.id + "/placement"}
                        >
                          Appli de placement
                        </NavLink>
                      </MDBSideNavCat>
                      <MDBSideNavCat
                        icon="credit-card"
                        name="Ecommerce"
                        id="ecommerce"
                      >
                        <NavLink
                          className="nav-link"
                          to={"/admin/events/edit/" + event.id + "/prices"}
                        >
                          Tarification
                        </NavLink>
                      </MDBSideNavCat>
                      <MDBSideNavCat
                        icon="language"
                        name="Langues"
                        id="languages"
                      >
                        <NavLink
                          className="nav-link"
                          to={"/admin/events/edit/" + event.id + "/languages"}
                        >
                          Langues
                        </NavLink>
                      </MDBSideNavCat>
                    </MDBSideNavNav>
                  </ul>
                  <div
                    style={{
                      margin: "auto",
                      left: 0,
                      right: 0,
                      top: "20px",
                      width: "136px",
                      height: "33px",
                      backgroundImage:
                        "url(" +
                        process.env.PUBLIC_URL +
                        "/logo-kanguroo-leftnav.png)",
                    }}
                  />
                </div>
              ) : null}
            </div>
            <div className="col-md-10">
              {event.data.maintenanceMode ? (
                <div className="alert alert-danger" role="alert">
                  Cet événement est passé en mode maintenance !
                </div>
              ) : null}
              <Switch>
                <Route
                  exact
                  path="/admin/events/edit/:eventid?/"
                  render={(props) => (
                    <EventConfig
                      {...props}
                      entities={entities}
                      subMenu={subMenu}
                      onSave={this.saveEvent}
                      event={event}
                      eventComponents={eventComponents}
                    />
                  )}
                />
                <Route
                  exact
                  path="/admin/events/edit/:eventid?/emails"
                  render={(props) => (
                    <EventConfigEmails
                      {...props}
                      subMenu={subMenu}
                      templatesList={templatesList}
                      onSave={this.saveEvent}
                      event={event}
                      eventComponents={eventComponents}
                    />
                  )}
                />
                <Route
                  path="/admin/events/edit/:eventid?/placement"
                  render={(props) => (
                    <Placements
                      {...props}
                      contacts={this.props.contacts}
                      subMenu={subMenu}
                      event={event}
                    />
                  )}
                />
                <Route
                  path="/admin/events/edit/:eventid?/components/checkin"
                  render={(props) => 
                  <CheckinComponentMain 
                  {...props}
                  event={event}
                  checkinPoints={event.checkin_points}
                  onSave={this.saveEvent}
                  loadEvent={this.loadData}
                  />}
                />
                <Route
                  exact
                  path="/admin/events/edit/:eventid?/passports"
                  render={(props) => (
                    <EventConfigPassports
                      {...props}
                      subMenu={subMenu}
                      onSave={this.saveEvent}
                      event={event}
                      eventComponents={eventComponents}
                    />
                  )}
                />
                <Route
                  exact
                  path="/admin/events/edit/:eventid?/messages"
                  render={(props) => (
                    <EventConfigMessages
                      {...props}
                      subMenu={subMenu}
                      onSave={this.saveEvent}
                      event={event}
                    />
                  )}
                />
                <Route
                  exact
                  path="/admin/events/edit/:eventid?/dashboard"
                  render={(props) => (
                    <DashBoardContainer
                      {...props}
                      event={event}
                      eventComponents={eventComponents}
                    />
                  )}
                />

                <Route
                  path="/admin/events/edit/:eventid?/prices"
                  render={(props) => (
                    <PriceRulesContainer
                      {...props}
                      onSave={this.saveEvent}
                      event={event}
                      eventComponents={eventComponents.filter(
                        (component) =>
                          component.data.componentType !== "map" &&
                          component.data.componentType !== "display" &&
                          component.data.componentType !== "request" &&
                          component.data.priceType === "rules"
                      )}
                    />
                  )}
                />
                <Route
                  path="/admin/events/edit/:eventid?/types"
                  render={(props) => (
                    <EventConfigTypes
                      {...props}
                      subMenu={subMenu}
                      onSave={this.saveEvent}
                      event={event}
                      eventComponents={eventComponents}
                    />
                  )}
                />
                <Route
                  exact
                  path="/admin/events/edit/:eventid?/forms"
                  render={(props) => <FormsList {...props} />}
                />
                <Route
                  path="/admin/events/edit/:eventid?/rules"
                  render={(props) => (
                    <RulesContainer
                      {...props}
                      event={event}
                      onSave={this.saveEvent}
                      composantsList={eventComponents}
                    />
                  )}
                />
                <Route
                  path="/admin/events/edit/:eventid?/forms/edit/:formid?"
                  render={(props) => (
                    <FormContainer
                      {...props}
                      onSave={this.loadData}
                      composantsList={eventComponents}
                      default_language={event.data.default_language}
                    />
                  )}
                />
                <Route
                  exact
                  path="/admin/events/edit/:eventid?/components/list"
                  render={(props) => (
                    <ComponentsList
                      {...props}
                      event={event}
                      onSave={this.saveEvent}
                      componentsList={eventComponents}
                    />
                  )}
                />

                <Route
                  path="/admin/events/edit/:eventid?/components/edit/:componentid?"
                  render={(props) => (
                    <ComponentContainer
                      {...props}
                      componentsList={eventComponents}
                      event={event}
                      onSave={this.saveEvent}
                    />
                  )}
                />
                <Route
                  path="/admin/events/edit/:eventid?/packages"
                  render={(props) => (
                    <PackagesContainer
                      {...props}
                      componentsList={eventComponents}
                    />
                  )}
                />
                <Route
                  path="/admin/events/edit/:eventid?/delegataires"
                  render={(props) => (
                    <DelegatairesContainer {...props} event={event} />
                  )}
                />
                <Route
                  path="/admin/events/edit/:eventid?/guests"
                  render={(props) => (
                    <GuestsContainer
                      {...props}
                      updateContacts={this.props.updateContacts}
                      contacts={this.props.contacts}
                      disableMailNotifications={this.disableMailNotifications}
                      event={event}
                      components={eventComponents}
                    />
                  )}
                />
                <Route
                  exact
                  path="/admin/events/edit/:eventid?/templates"
                  render={(props) => <TemplatesList {...props} />}
                />
                <Route
                  exact
                  path="/admin/events/edit/:eventid?/templates/edit/:templateid?"
                  render={(props) => (
                    <TemplateForm {...props} loadData={this.loadData} />
                  )}
                />
                <Route
                  exact
                  path="/admin/events/edit/:eventid?/campaigns"
                  render={(props) => (
                    <CampaignsList components={eventComponents} {...props} />
                  )}
                />
                <Route
                  exact
                  path="/admin/events/edit/:eventid?/campaigns/edit/:campaignid?"
                  render={(props) => (
                    <CampaignForm
                      {...props}
                      contacts={this.props.contacts}
                      loadData={this.loadData}
                    />
                  )}
                />
                <Route
                  exact
                  path="/admin/events/edit/:eventid?/sms"
                  render={(props) => (
                    <SmsList {...props} components={eventComponents} />
                  )}
                />
                <Route
                  exact
                  path="/admin/events/edit/:eventid?/sms/edit/:smsid?"
                  render={(props) => (
                    <SmsForm
                      {...props}
                      evenement={event}
                      components={eventComponents}
                      contacts={this.props.contacts}
                      loadData={this.loadData}
                    />
                  )}
                />
                {/* <Route
                  exact
                  path="/admin/events/edit/:eventid?/languages/edit/:languageid?"
                  render={(props) => (
                    <LanguageForm
                      {...props}
                      components={eventComponents}
                      loadData={this.loadData}
                    />
                  )}
                /> */}
                <Route
                  exact
                  path="/admin/events/edit/:eventid?/languages"
                  render={(props) => (
                    <LanguageContainer
                      {...props}
                      event={event}
                      components={eventComponents}
                      loadData={this.loadData}
                    />
                  )}
                />
              </Switch>
            </div>
          </div>
        </>
      );
    }
  }
}
export default EventContainer;
