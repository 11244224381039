import React from "react";
import { Switch, Route, matchPath } from "react-router-dom";
import Team from "./_modules/events/Team";
import Invoices from "./_modules/events/Invoices";
import Profil from "./_modules/events/Profil";
import ApiService from "../services/ApiService";
import Header from "./Header";
import Loader from "../common/Loader";
import { checkNotAccess, returnErrorHtml } from "../services/Utils";

class AccountMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      guest: null,
      contact: null,
      eventId: null,
      event: null,
      errorsWorkspaces: [],
      hasAccess: {
        access: true,
        reason: "",
      },
    };
  }

  async componentDidMount() {
    const checkEventFromAdmin =
      this.props.history.location.pathname.includes("/account/admin");
    const match = matchPath(this.props.history.location.pathname, {
      path: checkEventFromAdmin
        ? "/account/admin/:contactId"
        : "/account/:eventId/:guestId/:accessToken",
      strict: false,
    });
    if (match.params.accessToken) {
      localStorage.setItem("invitation_token", match.params.accessToken);
      this.setState({
        eventId: match.params.eventId ? match.params.eventId : null,
      });
    }
    const self = this;
    let fromAdmin = false;
    await ApiService.awaitRequest({}, "errorWorkspaces/", "get").then(function (
      data
    ) {
      self.setState({
        errorsWorkspaces: data,
      });
      return data;
    });

    if (checkEventFromAdmin) {
      if (match.params.contactId) {
        fromAdmin = true;
        await ApiService.request(
          {},
          "contacts/" + match.params.contactId,
          "get"
        ).then(function (data) {
          self.setState({
            contact: data,
          });
        });

        await ApiService.request(
          {},
          "contacts/evenements/" + match.params.contactId,
          "get"
        ).then(function (events) {
          self.setState({
            events,
          });
        });
      }
      this.setState({ isLoading: false });
    } else {
      await ApiService.request(
        {},
        "guests/" + match.params.guestId,
        "get"
      ).then(function (data) {
        self.setState({
          guest: data,
        });
      });

      await ApiService.request(
        {},
        "contacts/" + this.state.guest.contact_id,
        "get"
      ).then(function (data) {
        self.setState({
          contact: data,
        });
      });

      await ApiService.request(
        {},
        "contacts/evenements/" + this.state.guest.contact_id,
        "get"
      ).then(function (events) {
        self.setState({
          events,
        });
      });
    }
    let event = null;
    if (this.state.eventId) {
      event = await ApiService.awaitRequest(
        {},
        "evenements/" + this.state.eventId,
        "get"
      );
    }
    const access = await checkNotAccess(
      this.state.errorsWorkspaces,
      this.state.contact,
      this.state.eventId,
      event,
      "",
      fromAdmin
    );

    // Récupérer uniquement les événements où le contact est invité
    const contactId = this.state.contact.id;
    const events = this.state.events;
    const eventsAssociatedWithContact = [];

    events.forEach((event) => {
      event.guests.forEach((guest) => {
        if (guest.contact_id === contactId) {
          eventsAssociatedWithContact.push(event);
        }
      });
    });

    const currentDate = new Date();

    let oldestRunningStartDate = -Infinity;
    let oldestRunningStartDateId = null;
    let closestStartDate = Infinity;
    let closestStartDateId = null;

    eventsAssociatedWithContact.forEach((event) => {
      const startDate = new Date(event.start_date);
      const endDate = new Date(event.end_date);

      if (startDate <= currentDate && endDate >= currentDate) {
        // L'événement est en cours
        const elapsedMilliseconds = currentDate - startDate;
        if (elapsedMilliseconds > oldestRunningStartDate) {
          oldestRunningStartDate = elapsedMilliseconds;
          oldestRunningStartDateId = event.id;
        }
      } else if (startDate > currentDate) {
        // L'événement est à venir
        const diffMilliseconds = startDate - currentDate;
        if (diffMilliseconds < closestStartDate) {
          closestStartDate = diffMilliseconds;
          closestStartDateId = event.id;
        }
      }
    });

    this.setState({
      hasAccess: access,
      isLoading: false,
      event,
      events: eventsAssociatedWithContact.reverse(),
      eventId:
        oldestRunningStartDateId !== null
          ? oldestRunningStartDateId
          : closestStartDateId,
    });
  }

  render() {
    const { isLoading, contact, guest, events, eventId, hasAccess } =
      this.state;
    const checkEventFromAdmin =
      this.props.history.location.pathname.includes("/account/admin");
    if (isLoading) {
      return <Loader />;
    }

    if (!hasAccess.access) {
      return returnErrorHtml(hasAccess.content);
    } else {
      return (
        <div>
          {checkEventFromAdmin ? (
            <Header
              code={contact.languages}
              contact={contact}
              eventId={eventId}
              checkEventFromAdmin={checkEventFromAdmin}
            />
          ) : (
            <Header
              code={contact.languages}
              guest={guest}
              eventId={eventId}
              checkEventFromAdmin={checkEventFromAdmin}
            />
          )}

          <Switch>
            <Route
              exact
              path={
                checkEventFromAdmin
                  ? "/account/admin/:contactId/team"
                  : "/account/:eventId/:guestId/:accessToken/team"
              }
              render={(props) => (
                <Team
                  {...props}
                  eventId={eventId}
                  contact={contact}
                  events={events}
                  checkEventFromAdmin={checkEventFromAdmin}
                />
              )}
            />

            <Route
              exact
              path={
                checkEventFromAdmin
                  ? "/account/admin/:contactId/profil"
                  : "/account/:eventId/:guestId/:accessToken/profil"
              }
              render={(props) => (
                <Profil
                  {...props}
                  eventId={eventId}
                  contact={contact}
                  events={events}
                  checkEventFromAdmin={checkEventFromAdmin}
                />
              )}
            />

            <Route
              exact
              path={
                checkEventFromAdmin
                  ? "/account/admin/:contactId/invoices"
                  : "/account/:eventId/:guestId/:accessToken/invoices"
              }
              render={(props) => (
                <Invoices
                  {...props}
                  guest={guest}
                  contact={contact}
                  events={events}
                  checkEventFromAdmin={checkEventFromAdmin}
                />
              )}
            />
          </Switch>
        </div>
      );
    }
    // if (!contact.active)
    //   return (
    //     <div className="row">
    //       <div className="col-md-12 text-center mt-5">
    //         {webPageTranslations[contact.languages].your_account_is_deactivated}
    //       </div>
    //     </div>
    //   );
  }
}

export default AccountMain;
