import React from "react";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "mdbreact";

class MapEditor extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);

    this.state = {
      modal: false,
      mapIframe: this.props.mapIframe
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  handleChange(event) {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  }

  validate() {
    this.props.onUpload({ mapIframe: this.state.mapIframe });
    this.toggle();
  }

  render() {
    return (
      <div>
        <Button className="btn btn-block" onClick={this.toggle}>
          Modifier la carte
        </Button>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Modifier la carte</ModalHeader>
          <ModalBody>
            <label htmlFor="defaultFormContactMessageEx" className="grey-text">
              Source de l'Iframe
            </label>
            <textarea
              type="text"
              name="mapIframe"
              onChange={this.handleChange}
              className="form-control"
              rows="3"
              value={this.state.mapIframe}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.validate}>
              VALIDER
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default MapEditor;
