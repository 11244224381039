import React from "react";
import jsPDF from "jspdf";
import { getBase64ImageFromUrl } from "../../../../services/Utils";

class Print extends React.Component {
  constructor(props) {
    super(props);

    this.loadData = this.loadData.bind(this);
  }

  async componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.guestId !== prevProps.guestId) {
      this.loadData();
    }
  }

  async componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const pdf = new jsPDF("l", "mm", "a4");
    const marginX = 2;
    const marginY = 10;
    var hasImg = false;

    if (this.props.mainPicture) {
      try {
        const url = `https://res.cloudinary.com/kanguroo-event/image/upload/${this.props.mainPicture}`;
        const widthImg = pdf.internal.pageSize.getWidth();
        await getBase64ImageFromUrl(url).then(async function (img) {
          if (img) {
            hasImg = true;
            pdf.addImage(img, "jpg", 0, 0, widthImg, 0);
          }
        });
      } catch (error) {
        console.log(error);
        hasImg = false;
      }
    }
    if (hasImg === false) {
      pdf.deletePage(1);
    }
    this.props.pagesToPrint.forEach((page) => {
      const xdiff = page.highx - page.lowx;
      const ydiff = page.highy - page.lowy;

      const portrait = xdiff < ydiff;

      let factorX = 1;
      let factorY = 1;

      factorX = Number(ydiff) / 111;
      factorY = Number(ydiff) / 126;
      pdf.addPage("a4", portrait ? "p" : "l");
      pdf.setFontSize(12);

      const titlePosition = pdf.getTextDimensions(page.id);
      const documentWidth = portrait ? 210 : 297;

      pdf.text(page.id, (documentWidth - titlePosition.w) / 2, 10);
      pdf.setFont("Arial");
      console.log(pdf.getFontList(), "liste des font disponible");
      page.elements.forEach((element) => {
        let { x, y, lastname, firstname, id, text } = element.props;
        const typeRect = element.type === "rect" ? true : false;
        x = marginX + (x - page.lowx) / factorX;
        y = marginY + 4 + (y - page.lowy) / factorY;
        const originalY = y;
        pdf.setFontSize(5);
        if (typeRect) {
          pdf.rect(x, y, 15, 20, "S");
          x = x + 1;
          y = y + 3;

          lastname.split(" ").forEach((part, i) => {
            if (i !== 0) {
              y = y + 3;
            }

            const position = pdf.getTextDimensions(part);
            pdf.text(part, x + (14 - position.w) / 2, y);
          });

          y = y + 1;

          firstname.split(" ").forEach((part) => {
            y = y + 2;
            const position = pdf.getTextDimensions(part);
            pdf.text(part, x + (14 - position.w) / 2, y);
          });

          const temp = id.split("r");
          let seat = "r" + temp[temp.length - 1];
          if (seat.split("_")[2]) {
            // Permet de supprimer le _i à l'affichage s'il existe
            seat = seat.split("_").slice(0, 2).join("_");
          }
          const seatPosition = pdf.getTextDimensions(seat);

          pdf.text(seat, x + (14 - seatPosition.w) / 2, originalY + 18);
        } else if (!typeRect) {
          if (page.id === text) {
            return;
          }
          if (text) {
            pdf.setFontSize(12);
            pdf.text(text, x, y - 3);
          }
        }
      });
    });
    const date = new Date();
    const filename =
      this.props.nameEvent +
      "_planPPF_" +
      this.props.namePlacement +
      "_" +
      date.getDate() +
      "_" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "_" +
      date.getFullYear() +
      ".pdf";
    pdf.save(filename);
  }
  render() {
    return null;
  }
}

export default Print;
