import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "mdbreact";
import ApiService from "../services/ApiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import { toast } from "react-toastify";

class TransacEmailContainer extends React.Component {
  constructor(props) {
    super(props);

    this.sendEmail = this.sendEmail.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkDelegataire = this.checkDelegataire.bind(this);

    this.state = {
      modal: this.props.modal || false,
      template_id: this.props.delegataire
        ? this.props.templatesOptions.length > 0
          ? this.props.templatesOptions.sort((a, b) =>
              a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
            )[0].value
          : null
        : null,
      subject: "",
      isLoading: false,
      templates: this.props.templatesOptions.sort((a, b) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
      ),
    };
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  componentDidMount() {
    this.checkDelegataire();
  }
  checkDelegataire() {
    if (this.props.delegataire) {
      const templatesD = this.props.templatesDelegataire
        ? this.props.templatesDelegataire.filter((template) => {
            return template.delegataire === true;
          })
        : [];

      const templates = this.props.templatesOptions.filter((options) => {
        return templatesD.map((template) => {
          if (template.id === options.value) {
            return options;
          }
          return "";
        });
      });
      this.setState({ templates });
    }
  }

  async sendEmail() {
    const { template_id, subject } = this.state;
    const { callback, delegataire } = this.props;

    const self = this;

    this.setState({ isLoading: true });
    await ApiService.request(
      {
        template_id: Number(template_id),
        subject,
        guest_ids: this.props.guest_ids,
        user_ids: this.props.user_ids || null,
      },
      "mailtemplates/send",
      "post"
    ).then(function (val) {
      console.log(val, "email success");
      if (val.error) {
        toast.error("Erreur lors de l'envoi");
      } else {
        toast.success("Envoi réussi");
      }
      self.setState({ isLoading: false, modal: false });
      if (delegataire && callback) {
        callback();
      }
    });
    // .catch(function (error) {
    //   console.log(error)
    //   toast.error("Erreur lors de l'envoi");
    //   self.setState({ isLoading: false, modal: false });
    // });
  }

  toggle = () => {
    if (this.state.modal && this.props.delegataire && this.props.callback) {
      this.props.callback();
    }
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const { template_id, subject, isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    // if (this.props.templatesOptions.length === 0) {
    //   return <div />;
    // }

    return (
      <>
        {!this.props.modal ? (
          <FontAwesomeIcon
            title={"Renvoyer le mail"}
            onClick={this.toggle}
            icon={faAt}
          />
        ) : null}
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle} />
          <ModalBody>
            <div className="form-row">
              <div className="col-md-12">
                <label>Objet de l'email</label>
                <input
                  type="text"
                  name="subject"
                  className="form-control"
                  value={subject}
                  onChange={this.handleChange}
                  required
                />
              </div>{" "}
            </div>

            <div className="form-row">
              <div className="col-md-6">
                <select
                  onChange={this.handleChange}
                  name="template_id"
                  className="form-control browser-default custom-select email-template"
                  value={template_id}
                  required
                >
                  <option value="">Sélectionnez un template</option>
                  {this.state.templates.map((option, index) => (
                    <option key={`template-key-${index}`} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6">
                <Button
                  className="btn pink  btn-block darken-1 white-text"
                  disabled={template_id === null || subject.length === 0}
                  onClick={this.sendEmail}
                >
                  Envoyer le mail
                </Button>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn pink darken-1 white-text"
              onClick={this.toggle}
            >
              Fermer
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default TransacEmailContainer;
