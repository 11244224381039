import React from "react";
import ApiService from "../../../services/ApiService";
import Loader from "../../../common/Loader";
import ExportInvoices from "./ExportInvoices";
import Sortable from "../../../common/Sortable";
import { MDBInput } from "mdbreact";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import DownloadPdfsInvoices from "./DownloadPdfsInvoices";

registerLocale("fr", fr);

class InvoicesList extends React.Component {
  constructor(props) {
    super(props);
    this.filterInvoices = this.filterInvoices.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.sortDataWithStartDate = this.sortDataWithStartDate.bind(this);
    this.state = {
      invoices: [],
      isLoading: true,
      paymentFilter: "",
      eventFilter: "",
      nameFilter: "",
      endDateFilter: new Date(),
      beginDateFilter: new Date(2019, 1, 1),
      events: [],
      invoicesFiltered: [],
    };
  }

  async componentDidMount() {
    let invoices = await ApiService.awaitRequest({}, "invoices", "get");
    const events = await ApiService.awaitRequest({}, "evenements/all", "get");
    if (invoices === undefined) {
      invoices = await ApiService.awaitRequest({}, "invoices", "get");
    }
    this.setState({
      invoices: invoices ? invoices : [],
      invoicesFiltered: invoices ? invoices : [],
      events: events.filter((event) => event.data.free !== "true"),
      isLoading: false,
    });
  }

  filter() {
    const {
      invoices,
      eventFilter,
      nameFilter,
      paymentFilter,
      beginDateFilter,
      endDateFilter,
    } = this.state;

    let invoicesFiltered = invoices;

    if (nameFilter.length > 0) {
      invoicesFiltered = invoicesFiltered.filter((invoice) => {
        return (
          invoice.data &&
          (invoice.data.firstname
            .toLowerCase()
            .includes(nameFilter.toLowerCase()) ||
            invoice.invoice_number
              .toLowerCase()
              .includes(nameFilter.toLowerCase()) ||
            invoice.data.lastname
              .toLowerCase()
              .includes(nameFilter.toLowerCase()) ||
            (invoice.data.company
              ? invoice.data.company
                  .toLowerCase()
                  .includes(nameFilter.toLowerCase())
              : false) ||
            (invoice.contact
              ? invoice.contact.firstname
                  .toLowerCase()
                  .includes(nameFilter.toLowerCase()) ||
                invoice.contact.lastname
                  .toLowerCase()
                  .includes(nameFilter.toLowerCase())
              : false))
        );
      });
    }

    if (eventFilter.length > 0) {
      invoicesFiltered = invoicesFiltered.filter(
        (invoice) => invoice.evenement_id === Number(eventFilter)
      );
    }

    if (beginDateFilter instanceof Date) {
      invoicesFiltered = invoicesFiltered.filter(
        (invoice) =>
          new Date(invoice.created_at).getTime() >=
          new Date(beginDateFilter).getTime()
      );
    }

    if (endDateFilter instanceof Date) {
      invoicesFiltered = invoicesFiltered.filter(
        (invoice) =>
          new Date(invoice.created_at).getTime() <=
          new Date(endDateFilter).getTime()
      );
    }

    if (paymentFilter.length > 0) {
      invoicesFiltered = invoicesFiltered.filter((invoice) => {
        switch (paymentFilter) {
          case "refund":
            return !invoice.stripe_transaction_id;

          case "card":
            return (
              invoice.data.paymentMode === "card" &&
              invoice.stripe_transaction_id
            );

          case "check":
            return (
              invoice.data.paymentMode !== "card" &&
              !invoice.stripe_transaction_id
            );

          default:
            return false;
        }
      });
    }

    this.setState({
      invoicesFiltered,
    });
  }

  changeDate(date, name) {
    const self = this;
    this.setState({ [name]: new Date(date) }, () => self.filter());
  }

  sortDataWithStartDate(events) {
    return events.sort(function (a, b) {
      const dateA = a.start_date;
      const dateB = b.start_date;

      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    });
  }

  filterInvoices(event) {
    const self = this;

    const filterName = [event.target.name][0];
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        const textFilter = self.state.nameFilter.length;

        const dontFilter =
          filterName === "nameFilter" && textFilter < 3 && textFilter > 0;

        if (!dontFilter) {
          self.filter();
        }
      }
    );
  }

  render() {
    const {
      isLoading,
      invoicesFiltered,
      eventFilter,
      events,
      nameFilter,
      paymentFilter,
      beginDateFilter,
      endDateFilter,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }
    let totalHT =
      invoicesFiltered.length > 0
        ? invoicesFiltered
            .filter((item) => item.invoice_number.slice(-8) !== "PROFORMA")
            .map((item) => item.data.totalHT)
            .reduce((prev, next) => prev + next, 0)
        : 0;

    let totalTTC =
      invoicesFiltered.length > 0
        ? invoicesFiltered
            .filter((item) => item.invoice_number.slice(-8) !== "PROFORMA")
            .map((item) => item.data.totalTTC)
            .reduce((prev, next) => prev + next)
        : 0;

    totalHT = parseFloat(totalHT.toFixed(2));
    totalTTC = parseFloat(totalTTC.toFixed(2));

    const names = [
      { key: "invoice_number", value: "Facture" },
      { key: "stripe_transaction_id", value: "ID Stripe" },
      { key: "event", value: "Evenement" },
      { key: "date", value: "Date" },
      { key: "order_number", value: "REF COM" },
      { key: "company", value: "RS" },
      { key: "order_contact", value: "Contact commande" },
      { key: "invoice_contact", value: "Contact facturation" },
      { key: "montantht", value: "HT" },
      { key: "vat", value: "TVA" },
      { key: "ttc", value: "TTC" },
      { key: "mode", value: "Règlement" },
    ];

    // Sort events list by start date (ex : 2025-2024-2023)
    const eventSortedByDate = this.sortDataWithStartDate(events);
    eventSortedByDate.reverse();

    const sortable = invoicesFiltered
      .filter((invoice) => invoice.invoice_number.slice(-8) !== "PROFORMA")
      .map((invoice) => ({
        invoice_number: invoice.invoice_number,
        stripe_transaction_id:
          invoice.data.refund_id || invoice.stripe_transaction_id || "",
        event: events.find((event) => event.id === invoice.evenement_id)
          ? events.find((event) => event.id === invoice.evenement_id).data.name
          : "Inconnu",
        date: invoice.created_at,
        order_number: invoice.order_number,
        company: invoice.data.company,
        order_contact: invoice.contact
          ? invoice.contact.lastname + " " + invoice.contact.firstname
          : "Anonyme",
        invoice_contact: invoice.data.lastname + " " + invoice.data.firstname,
        montantht: invoice.data.totalHT && (
          <p style={{ whiteSpace: "nowrap" }}>
            {parseFloat(invoice.data.totalHT.toFixed(2))}
          </p>
        ),
        vat: invoice.data.totalHT && invoice.data.totalTTC && (
          <p style={{ whiteSpace: "nowrap" }}>
            {parseFloat(
              (invoice.data.totalTTC - invoice.data.totalHT).toFixed(2)
            )}
          </p>
        ),
        ttc: invoice.data.totalTTC && (
          <p style={{ whiteSpace: "nowrap" }}>
            {parseFloat(invoice.data.totalTTC.toFixed(2))}
          </p>
        ),
        mode:
          invoice.data.paymentMode === "check"
            ? "Chèque"
            : invoice.stripe_transaction_id
            ? "Carte"
            : "Accompte",
      }));

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <div className="row">
            <div className="col-md-8">
              <h2>Liste des factures</h2>
            </div>
            <div className={"col-md-2"}>
              <DownloadPdfsInvoices
                invoices={invoicesFiltered}
                eventFilter={eventFilter}
              />
            </div>
            <div className={"col-md-2"}>
              <ExportInvoices invoices={invoicesFiltered} events={events} />
            </div>
          </div>
          <div className={"white filters"}>
            <div className="row">
              <div className="col-md-3">
                <MDBInput
                  type="text"
                  value={nameFilter}
                  name="nameFilter"
                  label={"Rechercher par nom, société, numéro de facture"}
                  onChange={this.filterInvoices}
                />
              </div>
              <div className="col-md-2">
                <div className="md-form">
                  <select
                    required
                    onChange={this.filterInvoices}
                    name="eventFilter"
                    className="form-control browser-default custom-select"
                    value={eventFilter}
                  >
                    <option key="invoice-list-filter-byevent" value={""}>
                      Filtrer par événement
                    </option>
                    {eventSortedByDate.map((option, index) => (
                      <option
                        key={`invoice-list-filter-byevent-${index}`}
                        value={option.id}
                      >
                        {option.data.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <div className="md-form">
                  <select
                    required
                    onChange={this.filterInvoices}
                    name="paymentFilter"
                    className="form-control browser-default custom-select"
                    value={paymentFilter}
                  >
                    <option value={""}>Filtrer par mode de règlement</option>
                    {/* <option value={"refund"}>Accompte</option> */}
                    <option value={"card"}>Carte</option>
                    <option value={"check"}>Chèque</option>
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <div>
                  <div className="form-group">
                    <label>Début de période</label>
                    <DatePicker
                      selectsStart
                      selected={new Date(beginDateFilter)}
                      onChange={(date) =>
                        this.changeDate(date, "beginDateFilter")
                      }
                      locale="fr"
                      timeIntervals={15}
                      dateFormat="dd/MM/yyyy"
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div>
                  <div className="form-group">
                    <label>Fin de période</label>
                    <DatePicker
                      selectsStart
                      selected={new Date(endDateFilter)}
                      onChange={(date) =>
                        this.changeDate(date, "endDateFilter")
                      }
                      locale="fr"
                      timeIntervals={15}
                      dateFormat="dd/MM/yyyy"
                      required={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <Sortable names={names} sortable={sortable} />
          <div className="row stats-and-actions">
            <div className={"col-md-5 offset-md-7"}>
              <div className="row">
                <div className={"col-md-6"}>
                  <div className={"metric-panel-label"}>
                    TOTAL HT : {totalHT} €
                  </div>
                </div>
                <div className={"col-md-6"}>
                  <div className={"metric-panel-label"}>
                    TOTAL TTC : {totalTTC}€
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvoicesList;
