import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faLock } from "@fortawesome/free-solid-svg-icons";
/*import {
  MDBNavItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
} from "mdbreact";*/
import "./Header.css";
import HeaderContactMenu from "./_modules/components/HeaderContactMenu";
import HeaderComposantsMenu from "./_modules/components/HeaderComposantsMenu";
import HeaderConfigurationMenu from "./_modules/components/HeaderConfigurationMenu";

class Header extends React.Component {
  render() {
    return (
      <nav
        className="navbar navbar-expand-lg navbar-center fixed-top black"
        style={{ padding: 0 }}
      >
        {/*<div  className="header-logo-entite">
          <img alt="logo-entite" src={process.env.PUBLIC_URL + "/logo-entite.png"} />
    </div>*/}
        <div className="containerLogoKanguroo">
          <img
            className="headerLogoKanguroo"
            alt="kanguroo logo"
            src="/logo-kanguroo-leftnav.png"
          />
        </div>
        <div className="row" style={{ margin: "auto" }}>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarColor01"
            aria-controls="navbarColor01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarColor01">
            <ul className="navbar-nav mr-auto" style={{ height: "55px" }}>
              <li
                className={
                  "nav-item " +
                  (window.location.href.includes("events") ? "item-active" : "")
                }
              >
                <Link className="nav-link white-text" to="/admin/events">
                  <FontAwesomeIcon icon={faCalendarAlt} color="white" />
                  &nbsp; Evénements
                </Link>
              </li>
              <li
                className={
                  "nav-item " +
                  (window.location.href.includes("admin/components")
                    ? "item-active"
                    : window.location.href.includes("admin/thematiques")
                    ? "item-active"
                    : "")
                }
              >
                <HeaderComposantsMenu />
              </li>
              <li
                className={
                  "nav-item " +
                  (window.location.href.includes("admin/contacts")
                    ? "item-active"
                    : window.location.href.includes("admin/tags_contacts")
                    ? "item-active"
                    : window.location.href.includes("admin/invoices")
                    ? "item-active"
                    : window.location.href.includes("admin/rgpd")
                    ? "item-active"
                    : "")
                }
              >
                <HeaderContactMenu />
              </li>
              <li
                className={
                  "nav-item " +
                  (window.location.href.includes("admin/users")
                    ? "item-active"
                    : window.location.href.includes("admin/settings")
                    ? "item-active"
                    : window.location.href.includes("admin/entities")
                    ? "item-active"
                    : "")
                }
              >
                <HeaderConfigurationMenu />
              </li>
              <li className={"nav-item"}>
                <span
                  onClick={this.props.doLogout}
                  className="nav-link white-text cursor-pointer"
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faLock} color="white" />
                  &nbsp; Déconnexion
                </span>
              </li>

              {/* <MDBNavItem style={{ position: "fixed", right: "10px" }}>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret style={{ color: "white" }}>
                    <span className="mr-2">
                      <FontAwesomeIcon icon={faUser} color="white" />
                      &nbsp;Compte
                    </span>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem href="#!">Profil</MDBDropdownItem>
                    <MDBDropdownItem href="#!" onClick={this.props.doLogout}>
                      Logout
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>*/}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Header;
