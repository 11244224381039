import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { errorWorkspaceLabelDbArray } from "../../../config";
import Multilingual from "../../../common/Multilingual";
import ApiService from "../../../services/ApiService";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader";
import { languagesList } from "../../../common/Translations";

export default function SettingsWorkspace() {
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [contactExpire, setContactExpire] = useState(languagesList);
  const [contactInactive, setContactInactive] = useState(languagesList);
  const [contactNotInvited, setContactNotInvited] = useState(languagesList);
  const [eventArchived, setEventArchived] = useState(languagesList);
  const changeLang = (label, newLangSet) => {
    switch (label) {
      case "contactExpire":
        setContactExpire(newLangSet);
        break;
      case "contactInactive":
        setContactInactive(newLangSet);
        break;
      case "contactNotInvited":
        setContactNotInvited(newLangSet);
        break;
      case "eventArchived":
        setEventArchived(newLangSet);
        break;
      default:
        break;
    }
  };
  const handleLang = (newLangSet, label) => {
    changeLang(label, newLangSet);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      errorWorkspaceLabelDbArray.map(async (label) => {
        let type = "";
        let content = "";
        const findError = errors.find((error) => error.type === label);
        switch (label) {
          case "contactExpire":
            type = label;
            content = JSON.stringify(contactExpire);
            break;
          case "contactInactive":
            type = label;
            content = JSON.stringify(contactInactive);
            break;
          case "contactNotInvited":
            type = label;
            content = JSON.stringify(contactNotInvited);
            break;
          case "eventArchived":
            type = label;
            content = JSON.stringify(eventArchived);
            break;
          default:
            break;
        }
        if (type) {
          if (findError) {
            await ApiService.request(
              { content, type },
              "errorWorkspaces/" + findError.id,
              "put"
            );
          } else {
            await ApiService.request(
              { content, type },
              "errorWorkspaces/",
              "post"
            );
          }
        }

        return label;
      });
      toast.success("Messages sauvegardées");
    } catch (e) {
      toast.error("Erreur lors de la sauvegarde");
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  const loadData = async () => {
    setIsLoading(true);
    await ApiService.awaitRequest({}, "errorWorkspaces/", "get").then(function (
      data
    ) {
      data.map((obj) => {
        const newLang = JSON.parse(obj.content);
        changeLang(obj.type, newLang);
        return obj;
      });
      setErrors(data);
      console.log(data);
    });
    setIsLoading(false);
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <div className="row" style={{ marginTop: 55 }}>
        <div className="col-md-6 offset-md-2"></div>
      </div>
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <div className="row mb-3 mt-3 custom-nav"></div>
          <form onSubmit={handleSubmit} className="form">
            <label>Contact Expiré</label>
            <div className="form-row">
              <div className="form-group">
                <div className="form-row">
                  <Multilingual
                    lang={contactExpire}
                    type="textarea"
                    handleLang={(e) => handleLang(e, "contactExpire")}
                  />{" "}
                </div>
              </div>
            </div>
            <label>Contact Inactif</label>
            <div className="form-row">
              <div className="form-group">
                <div className="form-row">
                  <Multilingual
                    lang={contactInactive}
                    type="textarea"
                    handleLang={(e) => handleLang(e, "contactInactive")}
                  />{" "}
                </div>
              </div>
            </div>
            <label>Contact Actif mais non invité à un évenement</label>
            <div className="form-row">
              <div className="form-group">
                <div className="form-row">
                  <Multilingual
                    lang={contactNotInvited}
                    type="textarea"
                    handleLang={(e) => handleLang(e, "contactNotInvited")}
                  />{" "}
                </div>
              </div>
            </div>
            <label>Évenement supprimé</label>
            <div className="form-row">
              <div className="form-group">
                <div className="form-row">
                  <Multilingual
                    lang={eventArchived}
                    type="textarea"
                    handleLang={(e) => handleLang(e, "eventArchived")}
                  />{" "}
                </div>
              </div>
            </div>
            <div className="form-row" />
            <input
              type="submit"
              value="Enregistrer"
              className="btn pink darken-1 btn-lg float-right "
            />
          </form>
        </div>
      </div>
    </>
  );
}
