import React from "react";
import ApiService from "../services/ApiService";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBBtn,
  toast,
} from "mdbreact";
import { clearLocalStorage } from "../services/Utils";
//import { Link } from "react-router-dom";

class LostPassword extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = { email: "" };
  }

  componentDidMount() {
    clearLocalStorage();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    console.log("test");

    ApiService.request(
      { email: this.state.email, url: window.location.origin },
      "users/lostpassword",
      "post"
    )
      .then(function () {
        toast.success(
          "Les instructions de renouvellement de mot de passe ont été envoyées",
          {
            autoClose: 3000,
          }
        );
      })
      .catch(function () {
        toast.error("Aucun compte trouvé avec cette adresse email", {
          autoClose: 3000,
        });
      });
  }

  render() {
    return (
      <MDBContainer className="mt-5">
        <MDBRow center>
          <MDBCol md="6">
            <MDBCard>
              <MDBCardBody className="mx-4">
                <div className="text-center">
                  <h3 className="dark-grey-text mb-5">
                    <strong>Renouveller le mot de passe</strong>
                  </h3>
                </div>
                <MDBInput
                  onChange={this.handleChange}
                  name="email"
                  value={this.state.email}
                  label="Adresse email"
                  group
                  type="email"
                  validate
                  error="wrong"
                  success="right"
                />

                <div className="text-center mb-3">
                  <MDBBtn
                    onClick={(e) => this.handleSubmit(e)}
                    rounded
                    type="submit"
                    className="btn-block z-depth-1a pink darken-1"
                  >
                    Valider
                  </MDBBtn>
                </div>
              </MDBCardBody>
              {/*<Link className="text-center" to={"/admin/"}>
                Retour
              </Link>*/}
              <br />
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default LostPassword;
