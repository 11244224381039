import React from "react";
import ApiService from "../../services/ApiService";
import { Button, MDBInput, MDBAlert } from "mdbreact";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import GuestEditor from "./GuestEditor";
import Loader from "../../common/Loader";
import GuestPage from "../../common/GuestPage";
import TransacEmailContainer from "../../common/TransacEmailContainer";
import LoginForm from "../../common/LoginForm";
import DeleguatePackageTable from "./DeleguatePackageTable";
import DelegateComponentTable from "./DeleguateComponentTable";
import InviteDelegataireContacts from "./InviteDelegataireContacts";
import { CustomTable } from "../../common/table_component/CustomTable";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import "../DelegataireMain.css";
import {
  formatDate,
  displayFollowers,
  findLabelAndColor,
  filterElements,
  responsesOptions,
  clearLocalStorage,
  getComponentGuests,
  getComponentNameAssociatedToGuest,
} from "../../services/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faIdCard,
  faAt,
  faEraser,
  faChevronCircleDown,
  faChevronCircleRight,
  faEye,
  faTimes,
  faUserCheck,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { checkRole } from "../../services/Utils";
import EmailPreviewModal from "../../common/EmailPreviewModal";
import ImportContactTableMain from "../../admin/_modules/contacts/ImportContactTableMain";
import ContactParticipate from "./ContactParticipate";
import { toast } from "react-toastify";
import { checkRoleWithoutReplace } from "../../services/Utils";
import { CustomMuiButton } from "../../admin/_modules/components/CustomMuiButton";
import { ExportContactsByDelegataireByEvent } from "./ExportContactsByDelegataireByEvent";

class DelegatairesEvents extends React.Component {
  constructor(props) {
    super(props);
    this.checkAllRef = React.createRef(null);
    this.downLoadPassport = this.downLoadPassport.bind(this);
    this.selectEvent = this.selectEvent.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.loadGuests = this.loadGuests.bind(this);
    this.applyAction = this.applyAction.bind(this);
    this.doLogout = this.doLogout.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.filter = this.filter.bind(this);
    this.deleteGuestResponse = this.deleteGuestResponse.bind(this);
    this.doLogin = this.doLogin.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.navigate = this.navigate.bind(this);
    this.setTypePage = this.setTypePage.bind(this);
    this.loadData = this.loadData.bind(this);
    this.makeParticipant = this.makeParticipant.bind(this);
    this.checkComponentPackageInvitation =
      this.checkComponentPackageInvitation.bind(this);
    this.generateObjectForResponse = this.generateObjectForResponse.bind(this);
    this.handleReponseChange = this.handleReponseChange.bind(this);
    this.renderLabel = this.renderLabel.bind(this);
    this.sendResponses = this.sendResponses.bind(this);
    this.sendConfirmationEmail = this.sendConfirmationEmail.bind(this);
    this.closeEmailModal = this.closeEmailModal.bind(this);
    this.passeportZipGeneration = this.passeportZipGeneration.bind(this);
    this.updateModifyGuest = this.updateModifyGuest.bind(this);
    this.getDelegataireTotalQuota = this.getDelegataireTotalQuota.bind(this);
    this.getDelegataireComponents = this.getDelegataireComponents.bind(this);
    this.setGuestChecked = this.setGuestChecked.bind(this);
    this.canRegisterAllGuests = this.canRegisterAllGuests.bind(this);
    this.getQuotaAndParticipantsTotals =
      this.getQuotaAndParticipantsTotals.bind(this);

    this.state = {
      user: null,
      packageFilter: "",
      componentFilter: "",
      nameFilter: "",
      pdfToRender: "",
      isLogged: this.props.isLogged,
      totalPlaces: 0,
      followers: [],
      packagesList: [],
      evenements: [],
      componentsList: [],
      componentsListForExport: [],
      isLoading: true,
      selectedEvent: null,
      guests: [],
      guestsFiltered: [],
      guestsChecked: [],
      packagesOptions: [],
      reponses: [],
      followersDisplayed: [],
      action: "send",
      showEmailModal: false,
      templates: [],
      reponseFilter: "",
      showMultiReponses: false,
      guestModal: "",
      openModalPreview: false,
      typePage: { page: "default", subPage: "" },
      contacts: this.props.contacts,
      tagsList: this.props.tagsList,
      tagOptions: [],
      startDetect: false,
      componentsResponses: [],
      eventResponses: [],
      modifyGuest: false,
      guestData: "",
      expiration_date: "",
      isPasseportLoading: false,
      guestsLimiteDate: "",
      delegataireComponents: [],
      formResponseLayout: {},
    };
  }

  filter() {
    const {
      followers,
      guests,
      nameFilter,
      packageFilter,
      componentFilter,
      reponseFilter,
      reponses,
    } = this.state;

    let guestsFiltered = guests;

    if (nameFilter.length > 0) {
      guestsFiltered = guestsFiltered.filter((guest) => {
        return guest.firstname
          .toLowerCase()
          .includes(nameFilter.toLowerCase()) ||
          guest.lastname.toLowerCase().includes(nameFilter.toLowerCase()) ||
          followers
            .filter(
              (follower) => follower.invited_by.contact_id === guest.contact_id
            )
            .some(
              (follower) =>
                follower.firstname
                  .toLowerCase()
                  .includes(nameFilter.toLowerCase()) ||
                follower.lastname
                  .toLowerCase()
                  .includes(nameFilter.toLowerCase())
            )
          ? guest
          : null;
      });
    }

    if (packageFilter.length > 0) {
      guestsFiltered = guestsFiltered.filter(
        (guest) => guest.package_id === Number(packageFilter)
      );
    }

    if (componentFilter.length > 0) {
      guestsFiltered = guestsFiltered.filter((guest) => {
        return guest.componentsIds.some((id) => componentFilter.includes(id));
      });
    }

    if (reponseFilter.length > 0) {
      switch (reponseFilter) {
        case "willJoin":
          guestsFiltered = guestsFiltered.filter((guest) => guest.participate);
          break;
        case "willNotJoin":
          guestsFiltered = guestsFiltered.filter(
            (guest) =>
              !guest.participate &&
              reponses.some((reponse) => reponse.guest_id === guest.id)
          );
          break;
        case "waiting":
          guestsFiltered = guestsFiltered.filter(
            (guest) =>
              !guest.participate &&
              !reponses.some((reponse) => reponse.guest_id === guest.id)
          );
          break;
        case "pending":
          guestsFiltered = guestsFiltered.filter(
            (guest) => !guest.is_validated
          );
          break;
        default:
          break;
      }
    }

    this.setState({
      guestsFiltered,
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async handleDownload(guests, event) {
    const participatedGuests = guests.filter(
      (g) =>
        g.participate &&
        event.data.passportName &&
        event.data.passportName.length > 0
    );

    // Keep only guests ids
    const guestsIds = participatedGuests.map((guest) => guest.id);

    try {
      if (guestsIds.length > 0) {
        await this.passeportZipGeneration(guestsIds, event.id);
      } else {
        alert("Aucun invité sélectionné ne participe");
      }
    } catch (error) {
      console.error(
        "Une erreur s'est produite lors du téléchargement :",
        error
      );
    }
  }

  async passeportZipGeneration(guestsIds, eventId) {
    try {
      this.setState({ isPasseportLoading: true });

      const user = JSON.parse(localStorage.getItem("user"));

      await ApiService.awaitRequest(
        {
          evenement_id: eventId,
          guests_list: guestsIds,
          user_id: user.id,
          host_url: window.location.host,
          name: null,
        },
        "passeports_zip",
        "post"
      );
      toast.success(
        "La génération est en cours, vous recevrez un mail dans les minutes qui suivent"
      );
    } catch (error) {
      toast.error("Erreur lors du téléchargement");
      console.log(error);
    } finally {
      this.setState({ isPasseportLoading: false });
    }
  }

  applyAction() {
    const { action, guestsChecked, guestsFiltered, followers } = this.state;

    const self = this;
    switch (action) {
      case "send":
        this.setState({ showEmailModal: true });
        break;
      case "delete":
        this.setState({ isLoading: true });

        guestsChecked.forEach(async (id, i) => {
          const guestData = guestsFiltered.find((g) => g.id === id);

          followers.forEach(async (follower) => {
            if (
              follower.invited_by &&
              Number(follower.invited_by) === Number(guestData.contact_id)
            ) {
              await ApiService.request({}, "guests/" + follower.id, "delete");
            }
          });

          await ApiService.request({}, "guests/" + guestData.id, "delete").then(
            function () {
              self.setState(
                {
                  guestsChecked: [],
                  isLoading: true,
                },
                () => {
                  console.log("ENTER LOAD GUESTS ⚔️");
                  if (i === guestsChecked.length - 1) {
                    self.loadData();
                    //! self.loadGuests();
                  }
                }
              );
            }
          );
        });

        break;
      case "multiReponses":
        this.setState({ showMultiReponses: true });
        break;
      default:
        break;
    }
  }

  checkAll(event) {
    if (event.target.checked) {
      this.setState({
        guestsChecked: this.state.guestsFiltered.filter(
          (guest) => !guest.invited_by
        ),
      });
    } else {
      this.setState({
        guestsChecked: [],
      });
    }
  }

  downLoadPassport = async (guest, selectedEvent) => {
    try {
      this.setState({ isPasseportLoading: true });

      const response = await ApiService.awaitRequest(
        {},
        `guest/${guest.id}/passeport/byevenement/${selectedEvent.id}`,
        "get",
        "",
        "",
        "blob"
      );

      const url = window.URL.createObjectURL(
        new Blob([response], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${selectedEvent.data.passportName}_${guest.lastname}_${guest.firstname}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      toast.success("Document téléchargé");
    } catch (error) {
      toast.error("Erreur lors du téléchargement");
      console.log(error);
    } finally {
      this.setState({ isPasseportLoading: false });
    }
  };

  async deleteGuestResponse(guest) {
    this.setState({ isLoading: true });
    await ApiService.request(
      {
        is_validated: true,
        participate: false,
      },
      "guests/" + guest.id,
      "put"
    );

    this.state.reponses.forEach(async (reponse) => {
      if (Number(reponse.guest_id) === Number(guest.id)) {
        await ApiService.request(
          {},
          "component_responses/" + reponse.id,
          "delete"
        ).then((res) => {
          //! this.setState({ isLoading: true });
          //! this.loadGuests();
          // this.loadData();
        });
      }
    });

    this.state.followers.forEach(async (follower) => {
      if (
        follower.invited_by &&
        Number(follower.invited_by) === Number(guest.id)
      ) {
        await ApiService.request({}, "guests/" + follower.id, "delete").then(
          (res) => {
            //! this.setState({ isLoading: true });
            //! this.loadGuests();
            // this.loadData();
          }
        );
      }
    });
    //! this.loadGuests();
    this.loadData();
  }

  async loadGuests(multi, activateLoading = true) {
    if (this.state.selectedEvent) {
      const self = this;

      // if (activateLoading) if (!multi) this.setState({ isLoading: true });
      const userId = this.props.userId ? this.props.userId : null;

      const url = this.props.userId ? userId : localStorage.getItem("user_id");
      await ApiService.request(
        {},
        "guests/bydelegataire/" +
        url +
        "/byevenement/" +
        self.state.selectedEvent.id,
        "get"
      ).then(function (data) {
        const followers = data.guests.filter((guest) => guest.is_followers);
        const guests = data.guests.filter((guest) => !guest.is_followers);
        // console.log('🤓 ~ self.state ~', self.state);
        const packagesOptions = self.state.packagesOptions.map((pckg) => {
          const someComingFollower = followers.reduce((accumulator, guest) => {
            if (guest.participate && guest.package_id === pckg.value)
              return accumulator + 1;
            return accumulator;
          }, 0);
          const someComingGuest = guests.reduce((accumulator, guest) => {
            if (guest.participate && guest.package_id === pckg.value)
              return accumulator + 1;
            return accumulator;
          }, 0);
          return {
            ...pckg,
            guests_nbr: someComingFollower + someComingGuest,
          };
        });

        const dataTemplates = data.templates.filter(
          (template) => template.delegataire
        );

        const dataTemplatesSorted = dataTemplates.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );

        if (activateLoading) self.setState({ isLoading: false });

        const guestsWithComponentsIds = guests.map((g) => {
          const components = getComponentNameAssociatedToGuest(
            g.id,
            self.state.delegataireComponents,
            self.state.eventResponses
          );
          const componentIds = components.map((component) => component.id);
          return { ...g, componentsIds: componentIds };
        });

        self.setState({
          action: "send",
          showMultiReponses: false,
          showEmailModal: false,
          followers,
          guestsFiltered: guestsWithComponentsIds,
          guests: guestsWithComponentsIds,
          reponses: data && data.responses ? data.responses : [],
          templates: dataTemplatesSorted,
          packagesOptions,
        });
      });
    }
  }

  async toggleModal(key, guest) {
    const open = !this.state[key];
    if (open && guest) {
      this.setState({ guestModal: guest });
    } else {
      this.setState({ guestModal: "" });
    }
    this.setState({ [key]: open });
  }

  updateModifyGuest = (newModifyGuestValue) => {
    this.setState({ modifyGuest: newModifyGuestValue });
  };

  getDelegataireTotalQuota = (components) => {
    if (components.length < 1) return 0;

    const delegataireTotalQuota = components.reduce((acc, component) => {
      return component.quota + acc;
    }, 0);
    return delegataireTotalQuota;
  };

  getDelegataireComponents = (delegataireUserId, components) => {
    const delegataireComponents = [];
    const eventResponses = this.state.eventResponses;

    components.forEach((component) => {
      const quotaDelegataires =
        component.data.quotas && component.data.quotas.delegataires
          ? component.data.quotas.delegataires
          : [];

      if (quotaDelegataires) {
        const delegataireQuotaFound = quotaDelegataires.find(
          (delegataire) =>
            Number(delegataire.userId) === Number(delegataireUserId)
        );

        if (delegataireQuotaFound) {
          delegataireComponents.push({
            id: component.id,
            label: component.data.name || "-",
            totalQuota: component.data.quotas.total,
            quota: delegataireQuotaFound.quota,
            guestNumber: getComponentGuests(
              delegataireUserId,
              component,
              eventResponses
            ),
            role: component.data.reponse ? component.data.reponse.role : "",
            startDate: component.data.startDate,
          });
        }
      }
    });

    // Sort components by startDate
    delegataireComponents.sort(
      (a, b) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    );
    return delegataireComponents;
  };


  async selectEvent(e, activateLoadind = false) {
    const self = this;
    const eventId = e.target ? e.target.value : e;

    const selectedEvent = this.state.evenements.find(
      (evenement) => evenement.id === Number(eventId)
    );

    try {
      if (activateLoadind) {
        this.setState({
          isLoading: true,
          selectedEvent,
        });
      } else {
        this.setState({
          selectedEvent,
        });
      }

      let componentsList = await ApiService.awaitRequest(
        {},
        "components/byevenement/" + eventId,
        "get"
      );

      const componentForExport = componentsList;

      if (componentsList) {
        componentsList = componentsList.filter(
          (cmp) =>
            cmp.data &&
            (cmp.data.componentType === "moment" ||
              cmp.data.componentType === "logistic") &&
            cmp.data.reponse &&
            cmp.data.reponse.role !== "invitation"
        );
        self.setState({ componentsList });
      }

      // let eventResponses = [];
      await ApiService.awaitRequest(
        {},
        "evenement_forms/byevenement/" + eventId,
        "get"
      ).then(async function (data) {
        const form = data.find(
          (form) => form.layout.documentType === "reponse"
        );
        self.setState({ formResponseLayout: form });
        await ApiService.awaitRequest(
          {},
          "component_responses/byevenement/" + form.id,
          "get"
        ).then(function (data) {
          // eventResponses = data;
          self.setState({ eventResponses: data });
        });
      });

      await ApiService.request(
        {},
        "packages/byevenement/" + eventId,
        "get"
      ).then(function (packagesList) {
        self.setState({ packagesList });
      });

      await ApiService.request(
        {},
        "delegataires/byevenement/" + eventId,
        "get"
      ).then(function (data) {
        const delegataire = data.find(
          (delegate) => Number(delegate.user_id) === Number(self.props.userId)
        );

        const packagesOptions = [];
        const id = self.props.userId
          ? self.props.userId
          : Number(localStorage.getItem("user_id"));

        const delegataireComponents = self.getDelegataireComponents(
          id,
          componentsList
        );


        let totalPlaces = self.getDelegataireTotalQuota(delegataireComponents);

        // const guests = self.state.guests.filter(
        //   (guest) =>
        //     delegataire.package_ids &&
        //     delegataire.package_ids.includes(guest.package_id)
        // ).length;


        const filteredComponentsForExport = [];
        if (delegataire.package_ids) {
          //! get delegataire quota total
          delegataire.package_ids.forEach((pck) => {
            const packageFound = self.state.packagesList.find(
              (item) => item.id === pck
            );
            if (packageFound) {
              packagesOptions.push({
                value: packageFound.id,
                label: packageFound.name,
                description: packageFound.description || "",
                guests_nbr: 0,
              });

              // Filtrer les composants des packages délégataire
              packageFound.components.forEach(packageComp => {
                const foundComponent = componentForExport.find(comp => comp.id === packageComp.id);

                if (foundComponent) {
                  filteredComponentsForExport.push(foundComponent);
                }
              });
            }
          });
        }

        // Récupérer la date d'expiration du délégataire
        const expiration_date = delegataire.expiration_date
          ? new Date(
            new Date(JSON.parse(delegataire.expiration_date).date_limit)
          )
          : selectedEvent && selectedEvent.data.registerEndDate
            ? new Date(selectedEvent.data.registerEndDate)
            : "";

        // Récupérer la date limite de réponse des invités
        const guestsLimiteDate = delegataire.selectedDelegataireDateLimit
          ? new Date(
            new Date(
              JSON.parse(delegataire.selectedDelegataireDateLimit).date_limit
            )
          )
          : selectedEvent && selectedEvent.data.registerEndDate
            ? new Date(selectedEvent.data.registerEndDate)
            : "";

        self.setState(
          {
            totalPlaces,
            packagesOptions,
            expiration_date,
            guestsLimiteDate,
            delegataireComponents,
            componentsListForExport: filteredComponentsForExport
          },
          () => {
            self.loadGuests(undefined, activateLoadind);
          }
        );
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      throw new Error(error);
    } finally {
      // if (activateLoadind) {
      // this.setState({
      //   isLoading: false,
      // });
      // }
    }
  }

  doLogin() {
    this.loadData(true);
  }

  doLogout() {
    clearLocalStorage();
    this.setState({ isLogged: false });
  }

  async loadData(isLogged) {
    const self = this;
    try {
      if (localStorage.getItem("user_id")) {
        const userId = this.props.userId
          ? this.props.userId
          : localStorage.getItem("user_id");
        this.setState({ isLoading: true });

        const user = await ApiService.awaitRequest(
          this.state,
          "users/" + userId,
          "get"
        );
        const evenements = await ApiService.awaitRequest(
          {},
          "evenements/bydelegataire/" + userId,
          "get"
        );

        const tagOptions = this.state.tagsList.map((tag) => ({
          label: tag.tag_name,
          value: tag.id,
        }));

        const sortDataWithStartDate = evenements.sort(function (a, b) {
          const dateA = a.start_date;
          const dateB = b.start_date;

          if (dateA > dateB) {
            return -1;
          }
          if (dateA < dateB) {
            return 1;
          }
          return 0;
        });

        let selectedEvent = null;

        // Si aucun événement est sélectionné
        if (!self.state.selectedEvent) {
          const currentDate = new Date();
          let oldestRunningStartDate = -Infinity;
          let closestStartDate = Infinity;

          sortDataWithStartDate.forEach((event) => {
            const startDate = new Date(event.start_date);
            const endDate = new Date(event.end_date);

            if (startDate <= currentDate && endDate >= currentDate) {
              // L'événement est en cours
              const elapsedMilliseconds = currentDate - startDate;
              if (elapsedMilliseconds > oldestRunningStartDate) {
                oldestRunningStartDate = elapsedMilliseconds;
                selectedEvent = event;
              }
            } else if (startDate > currentDate) {
              // L'événement est à venir
              const diffMilliseconds = startDate - currentDate;
              if (diffMilliseconds < closestStartDate) {
                closestStartDate = diffMilliseconds;
                selectedEvent = event;
              }
            }
          });
        } else {
          selectedEvent = self.state.selectedEvent;
        }

        // this.loadGuests();

        this.setState(
          {
            user,
            evenements: sortDataWithStartDate,
            tagOptions,
            isLogged: isLogged ? true : self.state.isLogged,
          },
          () => {
            if (selectedEvent) {
              self.selectEvent(selectedEvent.id, true);
            } else {
              if (evenements && evenements.length > 0) {
                self.selectEvent(evenements[0].id, true);
              } else {
                this.setState({ isLoading: false });
              }
            }
          }
        );
      }
    } catch (error) {
      console.error(error);
      this.setState({ isLoading: false });
    }
  }

  async componentDidMount() {
    const { isLogged } = this.state;
    if (isLogged && !checkRole("delegataire") && !this.props.userId) {
      return null;
    }
    this.loadData(false);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }
  navigate = (e, typePage) => {
    e.preventDefault();
    this.setState({ typePage });
  };
  setTypePage(typePage) {
    this.setState({ typePage });
  }

  async makeParticipant(guest, multi) {
    this.checkComponentPackageInvitation(guest, multi);
  }

  async checkComponentPackageInvitation(guestToParticipate, multi) {
    if (!multi) this.setState({ isLoading: true });
    const self = this;
    try {
      const { selectedEvent, componentsList } = this.state;
      const webpageData = await ApiService.awaitRequest(
        {},
        "guests/webpage/reponse/" +
        selectedEvent.id +
        "/" +
        guestToParticipate.id,
        "get"
      );

      const packageInvitation = webpageData.packageguest;
      const packageComponents = packageInvitation.components;
      const eventComponents = webpageData.components;
      const componentsResponses = [];
      const contact = webpageData.contact;
      const form = webpageData.form;
      const code = contact
        ? contact.languages
          ? contact.languages
          : "FR"
        : "FR";

      const eventResponses = await ApiService.awaitRequest(
        {},
        "component_responses/byevenement/" + form.id,
        "get"
      ).then(function (data) {
        self.setState({ eventResponses: data });
        return data;
      });

      const componentsData = this.getDelegataireComponents(
        guestToParticipate.user_id,
        componentsList
      );

      console.log(
        "🚀 ~ DelegatairesEvents ~ checkComponentPackageInvitation ~ packageInvitation:",
        {
          packageInvitation,
          packageComponents,
          user: this.state.user.id,
          guestToParticipate,
          componentsList,
          componentsData,
        }
      );

      if (packageComponents && packageComponents.length > 0) {
        try {
          packageComponents.forEach((packageComponent) => {
            const composant = eventComponents.find((eventComponent) => {
              return packageComponent.id === eventComponent.id;
            });

            // TODO: check if component quota is exceeded

            if (composant) {
              const cmpQuotaData = componentsData.find(
                (cmp) => cmp.id === composant.id
              );

              if (
                cmpQuotaData &&
                cmpQuotaData.guestNumber >= cmpQuotaData.quota
              ) {
                // console.log('🤓 ~ cmpQuotaData ~', cmpQuotaData);
                throw new Error(
                  "Vous n'avez plus de place sur un de vos composants"
                );
              } else {
                let entranceValidity = true;
                if (
                  !cmpQuotaData &&
                  composant.data.reponse &&
                  composant.data.reponse.role === "entrance"
                ) {
                  const componentParticipants = getComponentGuests(
                    guestToParticipate.user_id,
                    composant,
                    eventResponses
                  );
                  if (
                    composant.data.quotas &&
                    componentParticipants >= composant.data.quotas.total
                  ) {
                    entranceValidity = false;
                  }
                }

                if (
                  entranceValidity &&
                  form.layout.documentType !== "website"
                ) {
                  const options = composant.data.reponse.options.find(
                    (option) => option.code === code
                  ).value;
                  switch (composant.data.reponse.htmlType) {
                    case "text":
                      this.handleReponseChange(
                        componentsResponses,
                        composant.id,
                        "",
                        "",
                        ""
                      );
                      break;
                    case "radio":
                      if (options) {
                        const optionsArray = options.split(";");
                        if (composant.data.reponse.role === "invitation") {
                          optionsArray.map((option, key) =>
                            key === 1
                              ? this.handleReponseChange(
                                componentsResponses,
                                composant.id,
                                key,
                                option,
                                true
                              )
                              : null
                          );
                        } else {
                          optionsArray.map((option, key) =>
                            key === 0
                              ? this.handleReponseChange(
                                componentsResponses,
                                composant.id,
                                key,
                                option,
                                true
                              )
                              : null
                          );
                        }
                      }
                      break;
                    case "checkbox":
                      options
                        ? options
                          .split(";")
                          .map((option, key) =>
                            this.handleReponseChange(
                              componentsResponses,
                              composant.id,
                              key,
                              option,
                              true
                            )
                          )
                        : this.handleReponseChange(
                          componentsResponses,
                          composant.id,
                          "",
                          this.renderLabel(composant, form, code),
                          true
                        );
                      break;
                    default:
                      break;
                  }
                } else if (!entranceValidity) {
                  console.log("HERE:", cmpQuotaData, composant);
                  throw new Error(
                    "Vous n'avez plus de place sur cet évènement"
                  );
                }
              }
            }
          });
        } catch (error) {
          console.log(error);
          console.log(error.message); //! put errror code and compare
          toast.error("Vous n'avez plus de place sur un de vos composants");
          this.setState({ isLoading: false });
          return;
        }
      }

      const stateObj = await this.generateObjectForResponse(
        selectedEvent,
        webpageData,
        componentsResponses,
        multi
      );
      console.log("Enter here 54 🍅");
      await this.sendResponses(stateObj);
      if (!multi) toast.success("Participant crée");
      this.loadData(true);
    } catch (error) {
      console.log(error);
      if (!multi)
        toast.error("Erreur lors de la création du participant", {
          autoClose: 500,
        });
    } finally {
      // if (!multi) this.setState({ isLoading: false });
    }
  }

  handleReponseChange(componentsResponses, id, elementKey, label, checked) {
    try {
      if (
        checked !== false &&
        componentsResponses &&
        Array.isArray(componentsResponses)
      ) {
        componentsResponses.push({ id, key: elementKey, label });
      }
    } catch (error) {
      console.log(error);
    }
  }

  renderLabel(composant, form, code) {
    return form.layout.documentType === "website"
      ? composant.data.reponse.websiteLabel.find((lang) => lang.code === code)
        .value
      : composant.data.reponse.reponseLabel.find((lang) => lang.code === code)
        .value;
  }

  async generateObjectForResponse(
    selectedEvent,
    webpageData,
    componentsResponses,
    multi
  ) {
    try {
      const eventId = selectedEvent.id;
      const guest = webpageData.guest;
      const eventComponents = webpageData.components;
      const form = webpageData.form;
      const contact = webpageData.contact;
      const code = contact
        ? contact.languages
          ? contact.languages
          : "FR"
        : "FR";
      let existingReponse = false;
      const componentEntrance = eventComponents.find(
        (component) => component.data.reponse.role === "entrance"
      );
      const obj = {
        multi,
        componentsResponses,
        componentEntrance,
        guest,
        form,
        existingReponse,
        eventId,
        code,
      };
      return obj;
    } catch (error) {
      console.log(error);
    }
  }

  async sendResponses(stateObj) {
    const { selectedEvent } = this.state;
    const {
      componentsResponses,
      componentEntrance,
      guest,
      form,
      existingReponse,
      eventId,
      code,
      multi,
    } = stateObj;
    try {
      if (!multi) this.setState({ isLoading: true });
      const isValidated = true;
      if (guest) {
        const willNotJoin =
          componentEntrance && componentsResponses
            ? componentsResponses.find(
              (reponse) =>
                reponse.id === componentEntrance.id && reponse.key === 1
            )
            : false;
        await ApiService.awaitRequest(
          {
            participate: !willNotJoin,
            is_validated: isValidated,
          },
          "guests/" + guest.id,
          "put"
        );
        if (
          componentsResponses &&
          componentsResponses.length > 0 &&
          !existingReponse
        ) {
          await ApiService.awaitRequest(
            {
              guests_ids: [guest.id],
              evenement_form_id: form.id,
              component_id: "",
              responses: componentsResponses,
            },
            "component_responses/",
            "post"
          );
          if (guest.email && guest.email.length !== 0 && !this.props.guests) {
            const emailObj = {
              multi,
              willJoin: true,
              existingReponse,
              eventId,
              event: selectedEvent,
              code,
              isValidated,
              guest,
            };
            this.sendConfirmationEmail(emailObj);
          }
          if (!multi) {
            this.loadData();
            //! this.loadGuests(guest, false);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (!multi) this.setState({ isLoading: false });
    }
  }
  async sendConfirmationEmail({
    multi,
    willJoin,
    existingReponse,
    eventId,
    event,
    code,
    isValidated,
    guest,
  }) {
    if (guest.created_by_delegataire && guest.user_id) {
      const templates = await ApiService.awaitRequest(
        {},
        "mailtemplates/byevenement/" + eventId,
        "get"
      );

      let template_id = "";

      if (willJoin) {
        if (!isValidated) {
          template_id = event.data.pendingConfirmationDelegataireTemplate.find(
            (template) => template.code === code
          ).value;
        } else {
          if (existingReponse) {
            template_id =
              event.data.modificationConfirmationDelegataireTemplate.find(
                (template) => template.code === code
              ).value;
          } else {
            template_id =
              event.data.registrationConfirmationDelegataireTemplate.find(
                (template) => template.code === code
              ).value;
          }
        }
      } else {
        template_id = event.data.willNotJoinAndRefundDelegataireTemplate.find(
          (template) => template.code === code
        ).value;
      }

      const subject = templates.find(
        (template) => template.id === Number(template_id)
      );

      if (subject) {
        await ApiService.awaitRequest(
          {
            template_id,
            subject: subject.description,
            guest_ids: [guest.id],
            user_ids: null,
          },
          "mailtemplates/send",
          "post"
        );
        if (!multi) toast.success("Email de confirmation envoyé");
      }
    }
  }
  closeEmailModal() {
    this.setState({ showEmailModal: false });
  }

  // Permit to update date from child component
  updateState = (updateObject) => {
    this.setState(updateObject);
  };

  setGuestChecked = (updateGuestsChecked) => {
    this.setState({ guestsChecked: updateGuestsChecked });
  };

  getQuotaAndParticipantsTotals = (delegataireComponents) => {
    let totalGuests = 0;
    let totalQuota = 0;
    let totalGuestsWithoutEntrance = 0;
    let totalQuotaWithoutEntrance = 0;
    let canRegisterGuest = true;
    let entranceExist = false;

    if (delegataireComponents && delegataireComponents.length) {
      delegataireComponents.forEach((component) => {
        // console.log('🤓 ~ component ~', component);
        if (component.role === 'entrance') {
          entranceExist = true;
        }
        if (component.role !== "entrance") {
          totalGuestsWithoutEntrance += component.guestNumber;
          totalQuotaWithoutEntrance += component.quota;
        }
        totalGuests += component.guestNumber;
        totalQuota += component.quota;
        if (
          component.role === "entrance" &&
          component.guestNumber >= component.quota
        ) {
          canRegisterGuest = false;
        }
      });

      if (canRegisterGuest && entranceExist) {
        canRegisterGuest = totalGuests < totalQuota;
      } else {
        canRegisterGuest = false;
      }
    } else {
      canRegisterGuest = false;
    }
    return {
      totalGuests,
      totalQuota,
      totalGuestsWithoutEntrance,
      totalQuotaWithoutEntrance,
      canRegisterGuest,
    };
  };

  canRegisterAllGuests = (numberOfGuests, packageId) => {
    packageId = Number(packageId);
    const { delegataireComponents, packagesList } = this.state;
    const guestsPackage = packagesList.find((pck) => pck.id === packageId);

    if (
      delegataireComponents &&
      delegataireComponents.length &&
      numberOfGuests &&
      guestsPackage &&
      guestsPackage.components
    ) {
      for (const component of delegataireComponents) {
        const componentAvailablePlace = component.quota - component.guestNumber;
        const isComponentBelongToPck = guestsPackage.components.some(
          (cmp) => cmp.id === component.id
        );

        if (
          isComponentBelongToPck &&
          componentAvailablePlace < numberOfGuests
        ) {
          return false;
        }
      }
    } else {
      return false;
    }
    return true;
  };

  render() {
    const {
      pdfToRender,
      totalPlaces,
      isLoading,
      selectedEvent,
      evenements,
      guests,
      packagesOptions,
      guestsFiltered,
      guestsChecked,
      packagesList,
      reponses,
      followers,
      followersDisplayed,
      isLogged,
      action,
      showEmailModal,
      templates,
      nameFilter,
      packageFilter,
      componentFilter,
      reponseFilter,
      showMultiReponses,
      guestModal,
      openModalPreview,
      user,
      typePage,
      contacts,
      isPasseportLoading,
      modifyGuest,
      guestData,
      expiration_date,
      guestsLimiteDate,
      delegataireComponents,
      eventResponses,
      formResponseLayout,
    } = this.state;

    const current_date = new Date();
    const enableModifyGuest = expiration_date > current_date || checkRoleWithoutReplace("admin")

    // Options for date format
    let options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };

    const headCells = [
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Nom et prénom",
      },
      {
        id: "society",
        numeric: false,
        disablePadding: false,
        label: "Société",
      },
      {
        id: "package",
        numeric: false,
        disablePadding: false,
        label: "Package",
      },
      {
        id: "component",
        numeric: false,
        disablePadding: false,
        label: "Composant",
      },
      {
        id: "follower",
        numeric: false,
        disablePadding: false,
        label: "Accompagnant",
      },
      {
        id: "response",
        numeric: false,
        disablePadding: false,
        label: "Réponse",
      },
      {
        id: "actions",
        numeric: false,
        disablePadding: false,
        label: "Actions",
      },
    ];

    const StyledTableCell = styled(TableCell)(
      ({ theme, textcolor = "black" }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: "red",
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
          color: textcolor,
          // backgroundColor: 'white'
        },
      })
    );

    const StyledTableRow = styled(TableRow)(({ theme, backgroundcolor }) => ({
      "&:nth-of-type(odd)": {
        backgroundColor: backgroundcolor || "white",
      },
      "&:nth-of-type(even)": {
        backgroundColor: backgroundcolor || "white",
      },

      // hide last border
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }));

    const createGuestRow = (data) => {
      return data.map((guest, index) => {
        const backgroundColor = findLabelAndColor(
          { ...guest, guestId: guest.id },
          "FR",
          reponses,
          true
        ).backgroundColor;
        const name = (
          <>
            {guest.lastname + " " + guest.firstname} <br />{" "}
            <small>{guest.email}</small>{" "}
          </>
        );
        const society = guest.contact.company ? guest.contact.company.name : "";
        // Package associated to guest
        const associatedPackage = packagesList.find(
          (pack) => pack.id === guest.package_id
        )
          ? packagesList.find((pack) => pack.id === guest.package_id).name
          : "";
        // Components associated to guest
        const associatedComponent = getComponentNameAssociatedToGuest(
          guest.id,
          delegataireComponents,
          eventResponses
        ).map((comp, key) => {
          return (
            <p className="m-0" key={key}>
              {comp.label}
            </p>
          );
        });
        const followersCount = followers.filter(
          (follower) => follower.invited_by === guest.id
        ).length;
        // Allow to display followers
        const follower = (
          <>
            {followersCount > 0 ? (
              <>
                {followersCount} / {guest.max_invitation}{" "}
                <FontAwesomeIcon
                  onClick={() => displayFollowers(guest, this)}
                  icon={
                    followersDisplayed.some((item) => item === guest.id)
                      ? faChevronCircleDown
                      : faChevronCircleRight
                  }
                />
              </>
            ) : (
              <>
                {followersCount} / {guest.max_invitation}
              </>
            )}
          </>
        );
        const response = findLabelAndColor(
          { ...guest, guestId: guest.id },
          "FR",
          reponses,
          true
        ).label;
        const actions = !guest.is_followers ? (
          <>
            <FontAwesomeIcon
              icon={faEdit}
              title={"Éditer l'invité"}
              onClick={() =>
                this.setState({ modifyGuest: !modifyGuest, guestData: guest })
              }
            />
            <span>
              &nbsp;
              {guest.email ? (
                <TransacEmailContainer
                  delegataire={true}
                  templatesOptions={templates.map((template) => ({
                    label: template.name,
                    value: template.id,
                  }))}
                  guest_ids={[guest.id]}
                  title={"Renvoyer le mail"}
                  icon={faAt}
                  eventId={selectedEvent.id}
                  callback={this.closeEmailModal}
                />
              ) : null}
            </span>
            &nbsp;
            <FontAwesomeIcon
              icon={faEye}
              onClick={() => this.toggleModal("openModalPreview", guest)}
            />
            {reponses.some(
              (reponse) => reponse.guest_id === Number(guest.id)
            ) ? (
              <>
                {enableModifyGuest ? (
                  <>
                    &nbsp;
                    <FontAwesomeIcon
                      onClick={() => {
                        if (
                          window.confirm(
                            "Voulez vous supprimer la réponse de l'invité ?"
                          )
                        )
                          this.deleteGuestResponse(guest);
                      }}
                      icon={faEraser}
                      title={"Supprimer la réponse"}
                    />
                    &nbsp;
                  </>
                ) : null}

                {guest.participate &&
                  selectedEvent.data.passportName &&
                  selectedEvent.data.passportName.length > 0 &&
                  selectedEvent.data.enablePassportForDelegataireGuest ? (
                  <>
                    &nbsp;
                    <FontAwesomeIcon
                      title={"Télécharger le billet"}
                      onClick={() =>
                        this.downLoadPassport(
                          guest,
                          selectedEvent,
                          selectedEvent
                        )
                      }
                      icon={faIdCard}
                    />
                  </>
                ) : null}
              </>
            ) : (
              enableModifyGuest ? (
                <>
                  &nbsp;
                  <FontAwesomeIcon
                    title={"Inscrire"}
                    onClick={() => {
                      if (
                        window.confirm(
                          "Souhaitez-vous faire participer l'invité ?"
                        )
                      ) {
                        this.makeParticipant(guest);
                      }
                    }}
                    icon={faUserCheck}
                  />
                </>
              ) : ""
            )}{" "}
            {enableModifyGuest ? (
              <GuestPage
                {...this.props}
                loadData={this.loadData}
                reponses={reponses}
                guest={guest}
                documentType={"reponse"}
                event={selectedEvent}
              />
            ) : null}
            {guest.participate ? (
              <>
                &nbsp;
                <GuestPage
                  {...this.props}
                  documentType={"website"}
                  guest={guest}
                  event={selectedEvent}
                />
              </>
            ) : null}
          </>
        ) : null;
        const followerList = followers.filter(
          (follower) => follower.invited_by === guest.id
        );
        // console.log(':nerd_face: ~ followerListElement ~', followerListElement);
        return {
          id: guest.id,
          backgroundColor,
          name,
          society,
          associatedPackage,
          associatedComponent,
          follower,
          response,
          actions,
          followerList,
        };
      });
    };

    const populateRows = (isSelected, handleClick, visibleRows) => {
      return visibleRows.map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
          <React.Fragment key={row.id}>
            <StyledTableRow
              backgroundcolor={row.backgroundColor}
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              selected={isItemSelected}
            >
              <StyledTableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={isItemSelected}
                  onClick={(event) => handleClick(event, row.id)}
                  inputProps={{
                    "aria-labelledby": labelId,
                    style: { width: "0px" },
                  }}
                  sx={{
                    color: "#626262",
                    "&.Mui-checked": {
                      color: "#d81b60",
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell component="th" id={labelId} scope="row">
                {row.name}
              </StyledTableCell>

              <StyledTableCell align="left">{row.society}</StyledTableCell>
              <StyledTableCell align="left">
                {row.associatedPackage}
              </StyledTableCell>
              <StyledTableCell align="left">
                {row.associatedComponent}
              </StyledTableCell>
              <StyledTableCell align="left">{row.follower}</StyledTableCell>
              <StyledTableCell align="left">{row.response}</StyledTableCell>
              <StyledTableCell align="left">{row.actions}</StyledTableCell>
            </StyledTableRow>

            {/* Afficher les accompagnants */}
            {followersDisplayed.includes(row.id) ? (
              <React.Fragment key={`${row.id}-followers`}>
                {row.followerList.map((follower, index) => (
                  <StyledTableRow
                    backgroundcolor={"#3E3E3E"}
                    key={follower.id}
                    component="tr"
                  >
                    <StyledTableCell key={0}></StyledTableCell>
                    <StyledTableCell key={1} align="left" textcolor={"white"}>
                      {follower.lastname} {follower.firstname}
                    </StyledTableCell>
                    <StyledTableCell key={2}></StyledTableCell>
                    <StyledTableCell key={3}></StyledTableCell>
                    <StyledTableCell key={4}></StyledTableCell>
                    <StyledTableCell key={5}></StyledTableCell>
                    <StyledTableCell key={6}></StyledTableCell>
                  </StyledTableRow>
                ))}
              </React.Fragment>
            ) : null}
          </React.Fragment>
        );
      });
    };

    if (isLogged) {
      if (!checkRole("delegataire") && !this.props.userId) {
        return null;
      }
    } else {
      return <LoginForm doLogin={this.doLogin} />;
    }

    if (isLoading) {
      return <Loader />;
    }

    const guestInvitationExpiredMsg = selectedEvent
      ? selectedEvent.data.contactInformations.find(
        (lang) => lang.code === "FR"
      ).value
      : "";
    const { totalGuests, totalQuota, canRegisterGuest } =
      this.getQuotaAndParticipantsTotals(delegataireComponents);


    if (evenements && evenements.length > 0) {
      return (
        <div>
          {showMultiReponses ? (
            // TODO: Vérifier avec le quota sur composant
            <GuestPage
              {...this.props}
              loadData={this.loadData}
              documentType={"reponse"}
              modal={true}
              guest={guestsChecked[0]}
              guests={guestsChecked}
              event={selectedEvent}
            />
          ) : null}
          {showEmailModal ? (
            <TransacEmailContainer
              delegataire={true}
              templatesOptions={
                templates.length > 0
                  ? templates.map((template) => ({
                    label: template.name,
                    value: template.id,
                  }))
                  : []
              }
              guest_ids={guestsChecked}
              eventId={selectedEvent.id}
              modal={showEmailModal}
              templatesDelegataire={templates.length > 0 ? templates : []}
              callback={this.closeEmailModal}
            />
          ) : null}
          {/* <Header doLogout={this.doLogout} /> */}
          {typePage.page === "import" ? (
            <ImportContactTableMain
              navigate={this.navigate}
              setTypePage={this.setTypePage}
              typePage={typePage}
              delegataire
              userId={user.id}
              startDetectImport={this.startDetectImport}
            />
          ) : (
            <div
              className="row main-content"
              style={{ alignItems: "center", marginRight: 30, marginLeft: 0 }}
            >
              ;
              <div className={"col-md-9 offset-md-2"}>
                <br />
                <div className="row mx-0">
                  {selectedEvent ? (
                    <div className="col-md-7 event-header">
                      <div className="row">
                        <div className="col-md-2 my-auto">
                          {selectedEvent.data.logo ? (
                            <img
                              alt="logo"
                              src={`https://res.cloudinary.com/kanguroo-event/image/upload/w_80/${selectedEvent.data.logo}`}
                            />
                          ) : null}
                        </div>
                        <div className="col-md-10">
                          <div className="delegataire-event-name">
                            {selectedEvent.data.name.toUpperCase()}
                          </div>
                          <div className="delegataire-event-dates">
                            Du {formatDate(selectedEvent.start_date, false)} au{" "}
                            {formatDate(selectedEvent.end_date, false)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div
                    className={
                      selectedEvent
                        ? "col-md-4 offset-md-1"
                        : "col-md-4 offset-md-9"
                    }
                    style={{ padding: "15px", backgroundColor: "white" }}
                  >
                    <select
                      required
                      onChange={(e) => this.selectEvent(e, true)}
                      name="selectedEvent"
                      className="form-control browser-default custom-select"
                      value={selectedEvent ? selectedEvent.id : ""}
                    >
                      <option disabled value={null}>
                        Sélectionner un événement
                      </option>
                      {evenements.map((option, k) => (
                        <option value={option.id} key={k}>
                          {option.data.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <br />
                {selectedEvent ? (
                  <>
                    <div className="row stats-and-action mx-0">
                      <div className="quota-section col-md-12 d-flex justify-content-between p-0">
                        <div className="col-md-4 m-0 p-0">
                          <DeleguatePackageTable packages={packagesOptions} />
                        </div>
                        <div className="my-components col-md-4 m-0 p-0">
                          <DelegateComponentTable
                            delegataireComponents={delegataireComponents}
                            getTotals={this.getQuotaAndParticipantsTotals}
                          />
                        </div>
                        <div className="col-md-2 d-flex flex-column m-0 p-0">
                          <div className="d-flex flex-column bg-white text-right rounded px-3 py-2 mb-5">
                            <p className="delegataire-date-title">
                              DATE LIMITE DE SAISIE DU DÉLÉGATAIRE
                            </p>
                            <p className="delegataire-date-text m-0">
                              {expiration_date.toLocaleString("fr-FR", options)}
                            </p>
                          </div>
                          <div className="d-flex flex-column bg-white text-right rounded px-3 py-2">
                            <p className="delegataire-date-title">
                              DATE LIMITE DE RÉPONSE DES INVITÉS
                            </p>
                            <p className="delegataire-date-text m-0">
                              {guestsLimiteDate.toLocaleString("fr-FR", options)}
                            </p>
                          </div>
                        </div>
                      </div>
                      {openModalPreview ? (
                        <EmailPreviewModal
                          open={openModalPreview}
                          keyModal="openModalPreview"
                          setModal={() => this.toggleModal("openModalPreview")}
                          user={user}
                          guest={guestModal}
                          contact={guestModal.contact ? guestModal.contact : ""}
                          templates={templates
                            .filter((temp) => temp.delegataire)
                            .sort((a, b) => a.id - b.id)}
                          event={selectedEvent}
                          packages={packagesList}
                        />
                      ) : null}
                    </div>
                    {enableModifyGuest ? (
                      <div className="row mx-0">
                        <div className="col-md-12 d-flex justify-content-between mt-5 p-0">
                          <div className="general-actions">
                            {selectedEvent.data
                              .enablePassportForDelegataireGuest ? (
                              <CustomMuiButton
                                type="button"
                                text="Télécharger les billets"
                                disabled={
                                  guests.length === 0 ||
                                  isPasseportLoading === true
                                }
                                onClick={() =>
                                  this.handleDownload(
                                    guestsFiltered,
                                    selectedEvent
                                  )
                                }
                                minHeight={60}
                                textColor="#ffffff"
                                buttonBgColor="#d81b60"
                                buttonHoverBgColor="#AF1242"
                                buttonHoverBorderColor="#0062cc"
                                buttonActiveBgColor="#d81b60"
                              />
                            ) : null}

                            <ExportContactsByDelegataireByEvent {...this.props}
                              guests={guestsFiltered}
                              componentsListForExport={this.state.componentsListForExport}
                              selectedEvent={selectedEvent}
                              user={this.state.user}
                              eventResponses={eventResponses}
                              followers={followers}
                              formResponseLayout={formResponseLayout}
                            />

                            <GuestEditor
                              guests={guests}
                              // disableButton={remainingPlaces === 0}
                              disableButton={!canRegisterGuest}
                              eventId={selectedEvent.id}
                              updateGuestList={this.loadGuests}
                              packagesOptions={packagesOptions}
                              makeParticipant={this.makeParticipant}
                              userId={user.id}
                              modifyGuest={modifyGuest}
                              modifyGuestData={guestData}
                              updateModifyGuest={this.updateModifyGuest}
                              canRegisterAllGuests={this.canRegisterAllGuests}
                            />

                          </div>

                          <div className="col-md-6 d-flex justify-content-end p-0">

                          <InviteDelegataireContacts
                            eventId={selectedEvent.id}
                            userId={user.id}
                            packagesList={packagesList}
                            loadData={this.loadData}
                            contacts={contacts.filter(
                              (contact) =>
                                !guests.some(
                                  (guest) => guest.contact_id === contact.id
                                ) &&
                                !followers.some(
                                  (follower) =>
                                    follower.contact_id === contact.id
                                ) &&
                                contact.active
                            )}
                            packagesOptions={packagesOptions}
                          />

                          <ContactParticipate
                            eventId={selectedEvent.id}
                            userId={user.id}
                            packagesList={packagesList}
                            loadData={this.loadData}
                            contacts={contacts.filter(
                              (contact) =>
                                !guests.some(
                                  (guest) => guest.contact_id === contact.id
                                ) &&
                                !followers.some(
                                  (follower) =>
                                    follower.contact_id === contact.id
                                ) &&
                                contact.active
                            )}
                            packagesOptions={packagesOptions}
                            makeParticipant={this.makeParticipant}
                            disableButton={!canRegisterGuest}
                            numberOfRegistrableGuests={
                              totalQuota - totalGuests
                            }
                            canRegisterAllGuests={this.canRegisterAllGuests}
                          />
                          {/* <ImportContacts {...this.props} navigate={this.navigate} /> */}

                          </div>
                        </div>
                        <br />
                      </div>
                    ) : (
                      <div style={{ fontWeight: "bold" }}>
                        <p className="text-center">{`Le délai de saisie des participants a expiré. Merci de contacter directement: `}</p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: guestInvitationExpiredMsg,
                          }}
                        />
                        <GuestEditor
                          guests={guests}
                          // disableButton={remainingPlaces === 0}
                          disableButton={!canRegisterGuest}
                          eventId={selectedEvent.id}
                          updateGuestList={this.loadGuests}
                          packagesOptions={packagesOptions}
                          makeParticipant={this.makeParticipant}
                          userId={user.id}
                          modifyGuest={modifyGuest}
                          modifyGuestData={guestData}
                          disableModifyGuest={!enableModifyGuest}
                          updateModifyGuest={this.updateModifyGuest}
                          canRegisterAllGuests={this.canRegisterAllGuests}
                        />
                      </div>
                    )}

                    {isPasseportLoading ? <Loader /> : null}

                    <br />
                    <div className={"white filters"}>
                      <div className="row">
                        <div className="col-md-3">
                          <MDBInput
                            type="text"
                            value={nameFilter}
                            name="nameFilter"
                            label={"Filtrer par nom"}
                            onChange={(e) =>
                              filterElements(e, "nameFilter", this, false)
                            }
                          />
                        </div>
                        <div className="col-md-3">
                          <div className="md-form">
                            <select
                              onChange={(e) =>
                                filterElements(e, "packageFilter", this, true)
                              }
                              name="packageFilter"
                              className="browser-default custom-select"
                              value={packageFilter}
                              required
                            >
                              <option value="">Filtrer par package</option>
                              {packagesOptions.map((option, k) => (
                                <option value={option.value} key={k}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {/* TODO : add component filter */}
                        <div className="col-md-3">
                          <div className="md-form">
                            <select
                              onChange={(e) =>
                                filterElements(e, "componentFilter", this, true)
                              }
                              name="componentFilter"
                              className="browser-default custom-select"
                              value={componentFilter}
                              required
                            >
                              <option value="">Filtrer par composant</option>

                              {delegataireComponents.map((option, k) => (
                                <option value={option.id} key={k}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="md-form">
                            <select
                              onChange={(e) =>
                                filterElements(e, "reponseFilter", this, true)
                              }
                              name="reponseFilter"
                              className="browser-default custom-select"
                              value={reponseFilter}
                              required
                            >
                              <option value="">Filtrer par réponse</option>

                              {responsesOptions.map((option, k) => (
                                <option value={option.value} key={k}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    {guestsChecked.length > 0 ? (
                      <div className="row mx-0 actions-container actions-container-fixed justify-content-between align-items-center">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              {guestsChecked.length} invité
                              {guestsChecked.length > 1 ? "s" : null}&nbsp;
                              sélectionné
                              {guestsChecked.length > 1 ? "s" : null}
                            </label>
                            <select
                              onChange={this.handleChange}
                              name="action"
                              className="col-sm-12 browser-default custom-select"
                              value={action}
                              required
                            >
                              <option value="send">Envoi d'un email</option>
                              <option value="delete" disabled={!enableModifyGuest}>
                                Supprimer l'invitation
                              </option>
                              <option value="multiReponses" disabled={!enableModifyGuest}>
                                Répondre au formulaire
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4 offset-md-3">
                          <div className="form-group">
                            {action === "multiReponses" &&
                              (guestsChecked.some(
                                (guest) =>
                                  guest.package_id !== guestsChecked[0].package_id
                              ) ||
                                guests.filter(
                                  (item) =>
                                    item.package_id ===
                                    guestsChecked[0].package_id &&
                                    item.participate
                                ).length +
                                guestsChecked.length >
                                // packagesOptions.find(
                                //   (item) =>
                                //     item.value === guestsChecked[0].package_id
                                // ).max_invitation
                                totalPlaces) ? (
                              <MDBAlert color="danger">
                                Veuillez sélectionner uniquement des invités avec
                                le même package dans la limite possible (
                                {
                                  // packagesOptions.find(
                                  //   (item) =>
                                  //     item.value === guestsChecked[0].package_id
                                  // ).max_invitation
                                  totalPlaces
                                }{" "}
                                places pour le package "
                                {
                                  packagesOptions.find(
                                    (item) =>
                                      item.value === guestsChecked[0].package_id
                                  ).label
                                }
                                " )
                              </MDBAlert>
                            ) : (
                              <Button
                                className="btn btn-block  pink darken-1"
                                disabled={guestsChecked.length === 0}
                                onClick={this.applyAction}
                              >
                                Appliquer
                              </Button>
                            )}
                          </div>
                        </div>
                        <div
                          className="col-md-1 align-self-start"
                          style={{ maxWidth: "fit-content" }}
                        >
                          <FontAwesomeIcon
                            title={"Fermer"}
                            icon={faTimes}
                            onClick={() => {
                              if (
                                this.checkAllRef.current &&
                                this.checkAllRef.current.checked
                              )
                                this.checkAllRef.current.checked = false;

                              this.setState({ guestsChecked: [] });
                            }}
                            color="white"
                          />
                        </div>
                      </div>
                    ) : null}
                    {pdfToRender}
                  </>
                ) : null}
                <CustomTable
                  data={guestsFiltered}
                  createTableRows={createGuestRow}
                  selected={guestsChecked}
                  setSelected={this.setGuestChecked}
                  populateRows={populateRows}
                  headCells={headCells}
                  activateCheckbox={true}
                />
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <p>Aucun événement n'est associé à ce délégataire.</p>
        </div>
      );

    }
  }
}

export default DelegatairesEvents;
