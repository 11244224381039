import React from "react";
import { MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle } from "mdbreact";
import selecteur1 from "./selecteur1.svg";
import selecteur2 from "./selecteur2.svg";

class GabaritSelector extends React.Component {
  constructor(props) {
    super(props);
    this.handleChoice = this.handleChoice.bind(this);
    this.state = {};
  }

  handleChoice(gabarit) {
    this.props.chooseGabarit(gabarit);
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-2 text-center">
            <MDBCard cascade>
              <MDBCardImage cascade className="img-fluid" src={selecteur1} />
              <MDBCardBody cascade>
                <MDBCardTitle>Template 1 colonne</MDBCardTitle>

                <button
                  className="btn default-color"
                  onClick={() => this.handleChoice("gabarit1")}
                >
                  Choisir
                </button>
              </MDBCardBody>
            </MDBCard>
          </div>
          <div className="col-md-4 text-center">
            <MDBCard cascade>
              <MDBCardImage cascade className="img-fluid" src={selecteur2} />
              <MDBCardBody cascade>
                <MDBCardTitle>Template 2 colonnes</MDBCardTitle>

                <button
                  className="btn default-color"
                  onClick={() => this.handleChoice("gabarit2")}
                >
                  Choisir
                </button>
              </MDBCardBody>
            </MDBCard>
          </div>
        </div>
      </div>
    );
  }
}

export default GabaritSelector;
