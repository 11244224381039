import React from "react";
import Loader from "./Loader";
import "./WebPage.css";
import { webPageTranslations } from "./Translations";
import Select from "react-select";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

class WebPageFollowersModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lastname: "",
      firstname: "",
      email: "",
      phone: "",
      contact_id: null,
      typeSelected: "external",
      optionSelected: "",
      typeOptions: [],
      lineOptions: [],
      hasWhiteSpace: false,
      disableButton: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.createFollower = this.createFollower.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSearch(e) {
    const { line, code, componentsResponses } = this.props;
    if (e.length < 3) {
      this.setState({ lineOptions: [] });
    } else {
      const lineOptions = [];
      line.forEach((contact) => {
        if (
          (contact.firstname.toLowerCase().includes(e.toLowerCase()) ||
            contact.lastname.toLowerCase().includes(e.toLowerCase())) &&
          !componentsResponses.some(
            (componentsResponse) =>
              componentsResponse.contact_id &&
              componentsResponse.contact_id === contact.id
          )
        ) {
          lineOptions.push({
            label: contact.participate
              ? contact.lastname +
                " " +
                contact.firstname +
                " (" +
                webPageTranslations[code ? code : "FR"].already_invited +
                ")"
              : contact.lastname + " " + contact.firstname,
            value: contact.id,
            isDisabled: contact.participate ? true : false,
          });
        }
      });
      this.setState({
        lineOptions,
      });
    }
  }
  checkIfWhiteSpace(array) {
    const find = array.find((element) => element === " ");
    return find ? true : false;
  }
  createFollower() {
    const self = this;

    if (this.state.typeSelected === "internal" && !this.state.contact_id)
      return;
    const {
      firstname,
      lastname,
      email,
      phone,
      contact_id,
      typeOptions,
      optionSelected,
    } = this.state;

    this.setState({ disableButton: true });
    const containOnlySpace = firstname.trim() || lastname.trim() ? false : true;

    if (!(firstname && lastname && !containOnlySpace)) return;

    const hasWhiteSpace = this.checkIfWhiteSpace([
      firstname,
      lastname,
      email,
      phone,
    ]);

    if (hasWhiteSpace) {
      this.setState({ hasWhiteSpace: true }, () => {
        setTimeout(() => {
          self.setState({ hasWhiteSpace: false });
        }, 4000);
      });
    } else {
      this.props.onCreateFollower(
        lastname,
        firstname,
        email,
        phone,
        contact_id,
        typeOptions[optionSelected] ? typeOptions[optionSelected].label : ""
      );
      this.setState({
        lastname: "",
        firstname: "",
        email: "",
        phone: "",
        contact_id: null,
      });
    }
  }

  handleSelectChange(event) {
    this.setState({
      typeSelected: this.state.typeOptions[event.target.value].guestType,
      contact_id:
        event.target.value === "external" ? null : this.state.contact_id,
    });
    this.handleChange(event);
  }

  handleChange(event) {
    if (event.value) {
      const contact = this.props.line.find(
        (contact) => contact.id === event.value
      );

      this.setState({
        lastname: contact.lastname,
        firstname: contact.firstname,
        email: contact.email,
        phone: contact.phone,
        contact_id: contact.id,
      });

      this.setState({ contact_id: event.value });
    } else if (event.target.name === "typeSelected") {
      this.setState({
        lastname: "",
        firstname: "",
        email: "",
        phone: "",
        contact_id: null,
        [event.target.name]: event.target.value,
      });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  componentDidMount() {
    const { code, event } = this.props;
    const typeOptions = event.data.guestsTypes.map((guestType, key) => {
      return {
        value: key,
        guestType: guestType.type,
        label: guestType.name.find((name) => name.code === code).value,
      };
    });
    this.setState({ typeOptions });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.createFollower();
    this.props.handleFollowerDialogClose();
    this.props.checkFormComplete();
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }

    const { layout, code, event, allowAddFollower, replaceShortCode } =
      this.props;

    const { useFirstname, useLastname, useEmail, usePhone } = event.data;
    const {
      firstname,
      lastname,
      phone,
      email,
      typeSelected,
      typeOptions,
      optionSelected,
      lineOptions,
      hasWhiteSpace,
    } = this.state;

    if (!allowAddFollower) {
      return (
        <div className="row modal-inner">
          <div
            className="col-12"
            dangerouslySetInnerHTML={{
              __html: replaceShortCode(
                event.data.eventFullText.find(
                  (message) => message.code === code
                ).value
              ),
            }}
            style={{ margin: "auto 0" }}
          />
        </div>
      );
    }

    return (
      <>
        <form className="form" onSubmit={this.handleSubmit}>
          <DialogTitle id="alert-dialog-title">
            {webPageTranslations[code ? code : "FR"].label}
          </DialogTitle>
          <DialogContent sx={{ paddingInline: "1rem" }}>
            {typeOptions.length > 0 ? (
              <select
                required
                name="optionSelected"
                className="form-control browser-default custom-select"
                onChange={this.handleSelectChange}
                value={optionSelected}
              >
                <option disabled value="">
                  {webPageTranslations[code ? code : "FR"].select_option}
                </option>
                {typeOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            ) : null}
            <div
              className="row"
              style={{ backgroundColor: "#EEEEEE", padding: "1.5rem 10px" }}
            >
              {typeSelected === "external" ? (
                <>
                  {useLastname ? (
                    <div className="form-group col-md-12">
                      <label
                        style={{
                          color: layout.mainColor,
                        }}
                      >
                        {webPageTranslations[code ? code : "FR"].lastnameField}
                      </label>
                      <input
                        type="text"
                        name="lastname"
                        className="form-control"
                        style={{
                          borderBottom: "1px solid " + layout.mainColor,
                          boxShadow: "0 1px 0 0" + layout.mainColor,
                        }}
                        required
                        value={lastname}
                        onChange={this.handleChange}
                      />
                    </div>
                  ) : null}
                  {useFirstname ? (
                    <div className="form-group col-md-12">
                      <label
                        style={{
                          color: layout.mainColor,
                        }}
                      >
                        {webPageTranslations[code ? code : "FR"].firstnameField}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstname"
                        style={{
                          borderBottom: "1px solid " + layout.mainColor,
                          boxShadow: "0 1px 0 0" + layout.mainColor,
                        }}
                        required
                        value={firstname}
                        onChange={this.handleChange}
                      />
                    </div>
                  ) : null}
                  {useEmail ? (
                    <div className="form-group col-md-12">
                      <label
                        style={{
                          color: layout.mainColor,
                        }}
                      >
                        {webPageTranslations[code ? code : "FR"].email}
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        style={{
                          borderBottom: "1px solid " + layout.mainColor,
                          boxShadow: "0 1px 0 0" + layout.mainColor,
                        }}
                        required
                        value={email}
                        onChange={this.handleChange}
                      />
                    </div>
                  ) : null}
                  {usePhone ? (
                    <div className="form-group col-md-12">
                      <label
                        style={{
                          color: layout.mainColor,
                        }}
                      >
                        {webPageTranslations[code ? code : "FR"].phone}
                      </label>
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        style={{
                          borderBottom: "1px solid " + layout.mainColor,
                          boxShadow: "0 1px 0 0" + layout.mainColor,
                        }}
                        required
                        value={phone}
                        onChange={this.handleChange}
                      />
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  <div className="form-group col-md-12">
                    <Select
                      required
                      options={lineOptions}
                      placeholder={
                        webPageTranslations[code ? code : "FR"]
                          .search_by_lastname
                      }
                      onInputChange={this.handleSearch}
                      onChange={this.handleChange}
                    />
                  </div>
                </>
              )}
            </div>
          </DialogContent>
          {hasWhiteSpace ? (
            <label
              style={{
                marginLeft: "5%",
                color: layout.mainColor,
                fontWeight: "bold",
              }}
            >
              {webPageTranslations[code ? code : "FR"].white_space}
            </label>
          ) : null}
          <DialogActions>
            <button
              disabled={this.state.disableButton}
              type="submit"
              className="btn"
              style={{
                backgroundColor: layout.mainColor,
                color: layout.secondaryColor,
              }}
            >
              {webPageTranslations[code ? code : "FR"].confirm}
            </button>
            <button
              className="btn"
              style={{
                backgroundColor: layout.mainColor,
                color: layout.secondaryColor,
              }}
              onClick={this.props.handleFollowerDialogClose}
            >
              {webPageTranslations[code ? code : "FR"].cancel}
            </button>
          </DialogActions>
        </form>
      </>
    );
  }
}

export default WebPageFollowersModal;
