import React from "react";
import { Button, Modal, ModalFooter, ModalBody, MDBInput } from "mdbreact";
import { Image, CloudinaryContext } from "cloudinary-react";
import ApiService from "../../../../services/ApiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../../common/Loader";
import FileUpload from "../../../../common/FileUpload";
import { toast } from "react-toastify";

class PlacementConfigForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkFile = this.checkFile.bind(this);
    this.check = this.check.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.checkSvgContent = this.checkSvgContent.bind(this);

    this.state = {
      modal: false,
      uploadError: null,
      placement: {
        default_message: "",
        main_picture: "",
        data: { seat: [], components: [], packages: [] },
        evenement_id: this.props.event.id,
        name: this.props.name || "",
        available_seat: this.props.available_seat || 100,
        svg_content: null,
        ...this.props.placement,
      },
      filename: null,
      isLoading: false,
      inputFileKey: "",
    };
  }

  onFileUpload(id) {
    this.setState({
      placement: {
        ...this.state.placement,
        main_picture: id,
      },
    });
  }

  // Gérer le check des composants
  check(event, newComponent) {
    let componentsChecked = this.state.placement.data.components;
    if (!event.target.checked) {
      componentsChecked = componentsChecked.filter(
        (component) => component !== newComponent.id
      );
    } else {
      componentsChecked.push(newComponent.id);
    }
    this.setState({
      placement: {
        ...this.state.placement,
        data: { ...this.state.placement.data, components: componentsChecked },
      },
    });
    // console.log(this.state.placement)
  }

  // Gérer le check des packages
  checkPackage(event, newPackage) {
    const updatedPlacement = { ...this.state.placement };

    if (!updatedPlacement.data.packages) { // Ajouter la propriété "packages" si elle n'existe pas
      updatedPlacement.data.packages = [];
    }

    if (!event.target.checked) {
      updatedPlacement.data.packages = updatedPlacement.data.packages.filter(
        (pack) => pack !== newPackage.id
      );
    } else {
      updatedPlacement.data.packages.push(newPackage.id);
    }

    this.setState(
      {
        placement: updatedPlacement,
      }
    );
  }

  handleChange(e, label) {
    const name = label ? label : e.target.name;
    const value = label ? e.target.getContent() : e.target.value;

    this.setState({ placement: { ...this.state.placement, [name]: value } });
  }

  checkSvgContent(svg) {
    if (typeof svg !== "string") return null;
    const regex =
      /< *style type *= *" *text\/css *" *>|< *style *>|< *\/ *style *>/gim;
    const matches = svg.match(regex);
    if (matches) return null;
    return svg;
  }

  checkFile(file) {
    if (!(file && file[0])) return;
    const parts = file[0].name.split(".");

    const self = this;

    if (parts[1] !== "svg") {
      this.setState({
        uploadError: "Le fichier doit être au format SVG",
        filename: null,
        placement: { ...self.state.placement, svg_content: null },
        inputFileKey: Math.random().toString(36),
      });
    } else {
      const fileReader = new FileReader();
      fileReader.readAsText(file[0]);

      fileReader.onload = (function () {
        return function () {
          const svg_content = self.checkSvgContent(this.result);
          if (!svg_content) {
            self.setState({
              uploadError: "Le fichier n'est pas conforme à nos normes",
              filename: null,
              placement: { ...self.state.placement, svg_content: null },
              inputFileKey: Math.random().toString(36),
            });
            return;
          }
          self.setState({
            filename: file[0].name,
            uploadError: null,
            placement: { ...self.state.placement, svg_content },
          });
        };
      })(file);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { placement } = this.state;
    // console.log(placement);
    const self = this;
    if (placement.data && placement.data.seat) {
      placement.data.seat = placement.data.seat.filter((seat) =>
        placement.svg_content.includes(seat.seatId)
      );
    }

    const method = placement.id ? "put" : "post";
    const id = placement.id || "";
    this.setState({ isLoading: true });
    ApiService.request({ ...placement }, "placements/" + id, method, true).then(
      function (res) {
        if (res) {
          self.setState({ isLoading: false, modal: false }, () => {
            self.props.loadData();
          });
          toast.success("Enregistrement réussi");
        } else {
          toast.error("Erreur à l'enregistrement");
        }
      }
    );
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const { placement, modal, uploadError, filename, isLoading } = this.state;
    const { components } = this.props;
    const { packages } = this.props;
    // console.log(packages)

    if (isLoading || !components) {
      return <Loader />;
    }

    const { name, available_seat, data, main_picture, default_message } =
      placement;
    // console.log(data);

    return (
      <>
        {this.props.placement ? (
          <FontAwesomeIcon onClick={this.toggle} icon={faEdit} />
        ) : (
          <Button
            className="btn pink darken-1 white-text"
            onClick={this.toggle}
          >
            Nouveau placement
          </Button>
        )}
        <Modal isOpen={modal} fullHeight position="right" toggle={this.toggle}>
          <form onSubmit={(e) => this.handleSubmit(e)} className="form">
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <MDBInput
                    label="Nom"
                    type="text"
                    value={name}
                    name="name"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <MDBInput
                    label="Nombre de places"
                    type="number"
                    value={`${available_seat}`}
                    name="available_seat"
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <MDBInput
                    label="Message au checkin si l'invité n'est pas placé"
                    type="text"
                    value={default_message || ""}
                    name="default_message"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-12">
                  <label>Image principale</label>
                  <div className="row">
                    <div className="col-md-7">
                      <FileUpload
                        buttonLabel={"upload"}
                        onFileUpload={this.onFileUpload}
                      />
                    </div>
                    <div className="col-md-5">
                      {main_picture ? (
                        <CloudinaryContext cloudName="kanguroo-event">
                          <Image
                            className="img-thumbnail"
                            publicId={main_picture}
                          />
                        </CloudinaryContext>
                      ) : null}
                    </div>
                  </div>
                </div>
                <br />
                <div className="col-md-12">
                  {uploadError ? (
                    <span className="red-text">{uploadError}</span>
                  ) : null}
                  <div className="form-group">Fichier SVG</div>
                  <div style={{ margin: "0.375rem" }} className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        onChange={(e) => this.checkFile(e.target.files)}
                        className="custom-file-input"
                        key={this.state.inputFileKey || ""}
                      />
                      <label className="custom-file-label">
                        Choisir un fichier
                      </label>
                    </div>
                  </div>
                  <div className="green-text">
                    {!uploadError ? filename : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <br />
                  <label>Composants</label>
                  <span
                    style={{
                      height: "200px",
                      overflow: "auto",
                      display: "block",
                    }}
                  >
                    {components.map((component) => (
                      <MDBInput
                        label={component.data.name}
                        onClick={(e) => this.check(e, component)}
                        filled
                        checked={data.components.some(
                          (componentChecked) =>
                            componentChecked === component.id
                        )}
                        id={`id-${component.id}`}
                        key={`key-${component.id}`}
                        type="checkbox"
                      />
                    ))}
                  </span>
                </div>
                <div className="col-md-12">
                  <br />
                  <label>Packages</label>
                  <span
                    style={{
                      height: "200px",
                      overflow: "auto",
                      display: "block",
                    }}
                  >
                    {packages.map((pack) => (
                      <MDBInput
                        label={pack.name}
                        onClick={(e) => this.checkPackage(e, pack)}
                        filled
                        checked={data.packages
                          ? data.packages.some(
                            (packagesChecked) =>
                              packagesChecked === pack.id
                          )
                          : false}
                        id={`id-${pack.id}`}
                        key={`key-${pack.id}`}
                        type="checkbox"
                      />
                    ))}
                  </span>
                </div>
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                type="submit"
                disabled={uploadError ? true : false}
                className="btn pink darken-1 white-text"
              >
                Valider
              </Button>
              <Button
                className="btn pink darken-1 white-text"
                onClick={this.toggle}
              >
                Fermer
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </>
    );
  }
}

export default PlacementConfigForm;
