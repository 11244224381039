import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  MDBInput,
} from "mdbreact";

class ComponentEditor extends React.Component {
  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
    this.state = {
      modal: false,
      displayTitle:
        this.props.component.displayTitle === true ||
        this.props.component.displayTitle === false
          ? this.props.component.displayTitle
          : true,
      displayDescription:
        this.props.component.displayDescription === true ||
        this.props.component.displayDescription === false
          ? this.props.component.displayDescription
          : true,
      component: this.props.component,
    };
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  validate() {
    if (this.props.itemsSide) {
      const result = this.props.itemsSide.map((item) => {
        if (item.id === this.props.component.id) {
          item.displayTitle = this.state.displayTitle;
          item.displayDescription = this.state.displayDescription;
          return item;
        } else return item;
      });
      this.props.onValidate({ itemsSide: result });
    }

    if (this.props.itemsMain) {
      const result = this.props.itemsMain.map((item) => {
        if (item.id === this.props.component.id) {
          item.displayTitle = this.state.displayTitle;
          item.displayDescription = this.state.displayDescription;
          return item;
        } else return item;
      });
      this.props.onValidate({ itemsMain: result });
    }

    this.toggle();
  }

  render() {
    const component = this.props.composantsList
      ? this.props.composantsList.find((i) => i.id === this.props.component.id)
      : this.props.component;

    return (
      <div>
        <span style={{ cursor: "pointer" }} onClick={this.toggle}>
          {component ? component.data.name : "Composant inconnu"}
        </span>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            {component ? component.data.name : "Composant inconnu"}
          </ModalHeader>
          <ModalBody>
            <div className="form-group row">
              <MDBInput
                onClick={(e) =>
                  this.setState({ displayTitle: e.target.checked })
                }
                id={"displayTitle"}
                key={"displayTitle"}
                checked={this.state.displayTitle}
                label={"Afficher le titre"}
                filled
                type="checkbox"
              />
            </div>
            <div className="form-group row">
              <MDBInput
                onClick={(e) =>
                  this.setState({ displayDescription: e.target.checked })
                }
                id={"displayDescription"}
                checked={this.state.displayDescription}
                label={"Afficher la description"}
                filled
                type="checkbox"
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.validate}>
              VALIDER
            </Button>
            <Button color="secondary" onClick={this.toggle}>
              FERMER
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ComponentEditor;
