import React, { Component } from "react";
import ApiService from "../../../services/ApiService";
import ComposantAdvancedConfig from "./ComposantAdvancedConfig";
import ComposantForm from "./ComposantForm";
import { toast } from "react-toastify";
import { Switch, Route, NavLink } from "react-router-dom";
import {
  languagesList,
  initComponentResponsesOptions,
  quotaWarningLabel,
} from "../../../common/Translations";
import Loader from "../../../common/Loader";
import ComposantFormText from "../components/ComposantFormText";

class ComponentContainer extends Component {
  constructor(props) {
    super(props);

    this.saveComposant = this.saveComposant.bind(this);

    this.componentData = {
      name: "",
      componentType: "display",
      isFree: true,
      quotas: {
        total: 1000000, // illimité
        delegataires: [], //! how to handle if package is deleted
      },
      quotaWarning: 5,
      quotaWarningLabel: quotaWarningLabel,
      tax: 5.5,
      billingLabel: languagesList,
      priceType: "unique",
      reponse: {
        displayCheckin: false,
        tags: [],
        reponseLabel: languagesList,
        websiteLabel: languagesList,
        passportLabel: languagesList,
        reponseDescription: languagesList,
        websiteDescription: languagesList,
        passportDescription: languagesList,
        options: initComponentResponsesOptions(),
        formWidth: "12",
        htmlType: "radio",
        mapIframe: "",
        isPreFilled: false,
        role: "standard",
        displayType: "list",
        guestFieldRequired: "",
      },
    };

    this.state = {
      isLoading: false,
      componentsList: [],
      composant: {
        data: {
          ...this.componentData,
        },
        system: true,
        global: true,
      },
      componentParticipants: 0,
    };
  }

  async saveComposant(data) {
    const { composant } = this.state;
    let method = composant.id ? "put" : "post";
    let id = composant.id ? composant.id : "";
    if (data.reponse.componentsList) {
      delete data.reponse.componentsList;
    }
    const component = await ApiService.awaitRequest(
      {
        ...composant,
        data,
      },
      "components/" + id,
      method
    );

    if (component) {
      toast.success("Composant sauvegardé");

      if (method === "post") {
        window.location.replace("/admin/components/edit/" + component.id);
      } else {
        this.setState({
          composant: { ...component },
        });
      }
    } else {
      toast.error("Erreur lors de la sauvegarde");
    }
  }

  async componentDidMount() {
    const self = this;

    this.setState({ isLoading: true });

    const componentsList = await ApiService.awaitRequest(
      {},
      "components",
      "get"
    );

    let { composant, componentParticipants } = this.state;

    if (this.props.match.params.id) {
      composant = await ApiService.awaitRequest(
        this.state,
        "components/" + this.props.match.params.id,
        "get"
      );

      componentParticipants = await ApiService.awaitRequest(
        this.state,
        "components/participants/foradmin/" +
          this.props.match.params.id +
          "/" +
          this.props.match.params.eventid,
        "get"
      );
    }

    this.setState({
      isLoading: false,
      composant: {
        ...this.state.composant,
        ...composant,
        data: {
          ...self.state.composant.data,
          ...composant.data,
          reponse: {
            ...self.state.composant.data.reponse,
            ...composant.data.reponse,
          },
        },
      },
      componentParticipants,
      componentsList,
    });
  }

  render() {
    const { composant, componentsList } = this.state;
    console.log(componentsList, "list");
    console.log(composant, "composant");
    const menu = [
      {
        label: "Configuration générale",
        link: "/admin/components/edit/" + composant.id,
        className: "nav-link",
      },
      {
        label: "Configuration avancée",
        link: "/admin/components/config/" + composant.id,
        className:
          this.state.composant.data.componentType !== "logistic" &&
          this.state.composant.data.componentType !== "moment"
            ? "d-none nav-link"
            : "nav-link",
      },
      {
        label: "Configuration de support",
        link: "/admin/components/form/" + composant.id,
        className: "nav-link",
      },
    ];

    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <div className="row">
        <div className="col-md-12 mb-3 custom-nav main-content">
          <div className="row">
            {this.state.composant.id
              ? menu.map((item, index) => (
                  <div
                    key={`component-container-menu-${index}`}
                    className="col-md-2 ml-2"
                  >
                    <NavLink className={item.className} to={item.link}>
                      {item.label}
                    </NavLink>
                  </div>
                ))
              : null}
          </div>
        </div>
        <div className="col-md-12">
          <div className="col-12 offset-md-1">
            <h4>Nom du composant : {this.state.composant.data.name}</h4>
          </div>
          <Switch>
            <Route
              path="/admin/components/edit/:id?/"
              render={(props) => (
                <ComposantForm
                  {...props}
                  id={composant.id}
                  componentsList={this.props.componentsList}
                  onSave={this.saveComposant}
                  data={this.state.composant.data}
                  canActivateComponentInCheckin={true}
                  initialComponentData={this.componentData}
                />
              )}
            />
            <Route
              path="/admin/components/config/:id?"
              render={(props) => (
                <ComposantAdvancedConfig
                  {...props}
                  onSave={this.saveComposant}
                  data={this.state.composant.data}
                  eventDates={{
                    startDate: new Date(),
                    endDate: new Date(),
                  }}
                  componentParticipants={this.state.componentParticipants}
                />
              )}
            />
            <Route
              path="/admin/components/form/:id?"
              render={(props) => (
                <ComposantFormText
                  eventId={this.props.match.params.eventid}
                  onSave={this.saveComposant}
                  data={this.state.composant.data}
                />
              )}
            />
          </Switch>
        </div>
      </div>
    );
  }
}
export default ComponentContainer;
