import React from "react";
import ApiService from "../../../services/ApiService";
import Creatable from "react-select/lib/Creatable";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader";
import Duplicate from "../../../common/Duplicate";
import { languagesList } from "../../../common/Translations";
import Select from "react-select";
import PhoneNumberInput from "../components/PhoneNumberInput";

registerLocale("fr", fr);

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTag = this.handleChangeTag.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleDeleteContact = this.handleDeleteContact.bind(this);
    this._isMounted = false;

    this.state = {
      allowFormConfirm: true,
      contact: {
        gender: "",
        picture_url:
          "https://image.freepik.com/free-icon/male-user-shadow_318-34042.jpg",
        firstname: "",
        lastname: "",
        address: "",
        address_more: "",
        zip_code: "",
        city: "",
        country: "",
        mail: "",
        mail_second: "",
        phone: "",
        phone_second: "",
        secretary_name: "",
        more_details: "",
        languages: "FR",
        company_id: null,
        company_function: "",
        company_service: "",
        active: true,
        related_to: "",
        external_id: "",
        tags: [],
        category: null,
        category_id: null,
        expiration_date: null,
      },
      company: {},

      contactsOptions: [{ label: "Aucun contact", value: null }],
      tagsOptions: [],
      isLoading: true,
      originalContactTags: [],
      duplicateContact: {},
      contactGuests: null,
      phoneNumberError: false,
      phoneNumberErrorSecond: false,
    };

    this.countryDetails = React.createRef(null);
    this.countryDetailsSecond = React.createRef(null);
  }

  handleSearch(e) {
    const { contacts } = this.props;
    const emptyContact = { label: "Aucun contact", value: null };
    if (e.length < 3) {
      this.setState({ contactsOptions: [emptyContact] });
    } else {
      const contactsOptions = [emptyContact];
      contacts.forEach((contact) => {
        if (
          contact.firstname.toLowerCase().includes(e.toLowerCase()) ||
          contact.lastname.toLowerCase().includes(e.toLowerCase())
        ) {
          contactsOptions.push({
            label: contact.lastname + " " + contact.firstname,
            value: contact.id,
          });
        }
      });
      this.setState({
        contactsOptions,
      });
    }
  }

  async loadData() {
    const self = this;
    this._isMounted && this.setState({ isLoading: true });

    const tags = await ApiService.request(this.state, "tags", "get");

    const tagsOptions = tags.map((tag) => ({
      label: tag.tag_name,
      value: tag.tag_name,
      id: tag.id,
    }));

    this._isMounted && this.setState({ tagsOptions });

    if (this.props.match.params.id) {
      await ApiService.request(
        this.state,
        "contacts/" + this.props.match.params.id,
        "get"
      ).then(async function (data) {
        self._isMounted &&
          self.setState({
            contact: {
              ...data,
              category: data.category ? data.category.name : null,
              related_to: data.related_to ? data.related_to.id : null,
              tags: data.tags.map((tag) => ({
                label: tag.tag_name,
                value: tag.tag_name,
                id: tag.id,
              })),
            },
            originalContactTags: data.tags.map((tag) => ({
              label: tag.tag_name,
              value: tag.tag_name,
              id: tag.id,
            })),
          });
        await ApiService.awaitRequest(
          {},
          "rgpd/contact/guests/" + data.id,
          "get"
        ).then((data) => self.setState({ contactGuests: data }));
      });

      if (this.state.contact.company_id) {
        await ApiService.request(
          this.state,
          "contact_companies/" + this.state.contact.company_id,
          "get"
        ).then(function (data) {
          self._isMounted &&
            self.setState({
              company: { ...data },
            });
        });
      }
    }

    this._isMounted && this.setState({ isLoading: false });
  }

  async componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleDateChange(date) {
    this.setState({
      contact: { ...this.state.contact, expiration_date: date },
    });
  }

  handleChange(event) {
    const name = event.target ? event.target.name : "related_to";

    const value =
      event.value === null ? event.value : event.value || event.target.value;

    this.setState(
      {
        contact: {
          ...this.state.contact,
          [name]: value,
        },
      },
      () => {
        const duplicate = this.props.contacts.find(
          (contact) =>
            (contact.firstname
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "") ===
              this.state.contact.firstname
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "") &&
              contact.lastname
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "") ===
                this.state.contact.lastname
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")) ||
            (contact.mail === this.state.contact.mail &&
              contact.mail &&
              contact.mail.length !== 0)
        );
        if (duplicate && !this.props.match.params.id) {
          this.setState({
            duplicateContact: duplicate,
            allowFormConfirm: !this.props.contacts.some(
              (contact) => contact.mail === this.state.contact.mail
            ),
          });
        } else {
          this.setState({ allowFormConfirm: true });
        }
      }
    );
  }

  handleChangeTag(newValue) {
    const deletedTags = this.state.originalContactTags.filter(
      (e) => !newValue.find((a) => e.id === a.id)
    );

    if (this.state.contact.id) {
      deletedTags.map(async (tag) => {
        await ApiService.request(
          { tag_id: tag.id },
          "contacts/" + this.state.contact.id + "/removetag/",
          "post"
        );
        return;
      });
    }

    this.setState({
      contact: { ...this.state.contact, tags: newValue },
    });
  }

  handleChangeCompany(event) {
    this.setState({
      company: {
        ...this.state.company,
        [event.target.name]: event.target.value,
      },
    });
  }
  async handleSubmit(e) {
    e.preventDefault();
    const method = this.state.contact.id ? "put" : "post";
    const id = this.state.contact.id ? this.state.contact.id : "";
    const self = this;

    const contactTags = this.state.contact.tags;
    const category = self.state.contact.category;

    this.setState({ allowFormConfirm: false });
    const companyMethod = "post";
    if (this.state.company.name) {
      await ApiService.request(
        { ...this.state.company },
        "contact_companies/",
        companyMethod
      )
        .then(function (data) {
          self.setState({
            company: data,
          });
        })
        .catch(function () {
          toast.error("Erreur lors de l'enregistrement de la société");
        });
    } else if (this.state.company.name === "") {
      try {
        await ApiService.request(
          {},
          "contact_companies/" + this.state.company.id + "/" + id,
          "delete"
        ).then(() => {
          this.state.company.id = null;
          this.state.contact.company_id = null;
        });
      } catch (error) {
        toast.warn("Erreur lors de la modification de la société", {
          autoClose: 3000,
        });
      }
    }
    // Add or modify a category
    let categoryMethod = this.state.contact.category_id ? "put" : "post";
    const categoryURL = this.state.contact.category_id
      ? `contact_categories/${this.state.contact.category_id}`
      : "contact_categories";

    if (this.state.contact.category) {
      await ApiService.request({ name: category }, categoryURL, categoryMethod)
        .then(function (data) {
          self.setState({
            contact: {
              ...self.state.contact,
              category: data,
              category_id: data.id,
            },
          });
        })
        .catch(function () {
          toast.error("Erreur lors de l'enregistrement de la catégorie", {
            autoClose: 3000,
          });
        });
    } else if (!this.state.contact.category && this.state.contact.category_id) {
      self.setState({
        contact: {
          ...self.state.contact,
          category: null,
          category_id: null,
        },
      });
    }

    await ApiService.request(
      {
        ...this.state.contact,
        category:
          this.state.contact.category_id && this.state.contact.category
            ? this.state.contact.category
            : null,
        category_id:
          this.state.contact.category_id && this.state.contact.category
            ? this.state.contact.category_id
            : null,
        company_id: this.state.company.id ? this.state.company.id : null,
      },
      "contacts/" + id,
      method
    )
      .then(function (data) {
        const contacts = self.props.contacts.filter(
          (contact) => contact.id !== data.id
        );
        const contactId = data.id;
        contactTags.map(async (tag) => {
          if (tag.__isNew__) {
            await ApiService.request(
              { tag_name: tag.label },
              "tags/",
              "post"
            ).then(function (data) {
              ApiService.request(
                { tag_id: data.id, tag_name: tag.label },
                "contacts/" + contactId + "/addtag/",
                "post"
              );
            });
          } else {
            ApiService.request(
              { tag_id: tag.id, tag_name: tag.label },
              "contacts/" + contactId + "/addtag/",
              "post"
            );
          }
        });

        contacts.push(data);
        // console.log(data);
        self.props.updateContacts(contacts);

        toast.success("Contact sauvegardé");

        self.props.history.push("/admin/contacts");
        self.setState({ allowFormConfirm: true });
      })
      .catch(function () {
        self.setState({ allowFormConfirm: true });
        toast.error("Erreur lors de l'enregistrement du contact");
      });
    this.loadData();
  }

  formatName(name) {
    if (!name) return "";
    return name.toLowerCase().replace(/\s+/g, " ").trim().replaceAll(" ", "-");
  }

  async handleDeleteContact(e) {
    e.preventDefault();
    await ApiService.awaitRequest(
      {},
      "rgpd/contact/anonymize/" + this.state.contact.id,
      "delete"
    )
      .then(() => {
        toast.success("Contact supprimé");
        window.location.replace("/admin/contacts");
      })
      .catch((err) => {
        toast.error(
          "Impossible de supprimer le contact, essayer ultérieurement"
        );
        console.log(err);
      });
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }

    const {
      contact,
      company,
      contactsOptions,
      duplicateContact,
      allowFormConfirm,
      phoneNumberError,
      phoneNumberErrorSecond,
    } = this.state;
    const { contacts } = this.props;
    return (
      <div className="row">
        {duplicateContact.id ? <Duplicate contact={duplicateContact} /> : null}
        <div className="col-md-10 offset-md-1 main-content">
          <form onSubmit={this.handleSubmit} className="form">
            <div className="form-row col-md-10 mt-5 mx-auto">
              <h4 className="col-md-12 font-weight-bold mb-4">
                Informations générales
              </h4>
              <div className="form-group col-md-4 py-auto">
                <div className="col-md-12 px-0">
                  <label>Civilité *</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Monsieur, Madame"
                    name="gender"
                    id="contactGenderInput"
                    value={contact.gender}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="col-md-12 px-0">
                  <label className="col-md-12 p-0 mt-4">Langues</label>
                  <br />
                  <select
                    onChange={this.handleChange}
                    name="languages"
                    className="form-control browser-default custom-select"
                    value={contact.languages}
                  >
                    {languagesList.map((lang, index) => (
                      <option
                        key={`contact-form-lang-${index}`}
                        value={lang.code}
                      >
                        {lang.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group row col-md-8 justify-center mx-0">
                <div className="col-md-6">
                  <label>Nom *</label>
                  <input
                    type="text"
                    name="lastname"
                    className="form-control"
                    value={contact.lastname}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label>Prénom *</label>
                  <input
                    type="text"
                    name="firstname"
                    className="form-control"
                    value={contact.firstname}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-row col-md-12">
                  <div className="form-group col-md-12 px-0">
                    <label>Plus de détails</label>
                    <textarea
                      type="textarea"
                      name="more_details"
                      className="form-control"
                      value={contact.more_details}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row col-md-10 mt-4 d-flex flex-direction-center mx-auto">
              <h4 className="col-md-12 font-weight-bold mb-4">Coordonnées</h4>
              <div className="form-row col-md-12 mx-0">
                <div className="form-group col-md-6">
                  <label>Fonction</label>
                  <input
                    type="text"
                    name="company_function"
                    className="form-control"
                    value={contact.company_function || ""}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Service</label>
                  <input
                    type="text"
                    name="company_service"
                    className="form-control"
                    value={contact.company_service || ""}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="form-row col-md-12 mx-0">
                <div className="form-group col-md-6">
                  <label>Tel 1</label>
                  <PhoneNumberInput
                    parentPhoneNumber={contact.phone}
                    setParentPhoneNumber={(value) =>
                      this.setState({ contact: { ...contact, phone: value } })
                    }
                    setParentPhoneNumberError={(value) =>
                      this.setState({
                        phoneNumberError: value,
                      })
                    }
                    countryDetails={this.countryDetails}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Email 1</label>
                  <input
                    type="email"
                    name="mail"
                    className="form-control"
                    value={contact.mail || ""}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="form-row col-md-12 mx-0">
                <div className="form-group col-md-6">
                  <label>Tel 2</label>
                  <PhoneNumberInput
                    phoneNumber={contact.phone_second}
                    setPhoneNumber={(value) =>
                      this.setState({
                        contact: {
                          ...contact,
                          phone_second: value,
                        },
                      })
                    }
                    setParentPhoneNumberError={(value) =>
                      this.setState({
                        phoneNumberErrorSecond: value,
                      })
                    }
                    countryDetails={this.countryDetailsSecond}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Email 2</label>
                  <input
                    type="email"
                    name="mail_second"
                    className="form-control"
                    value={contact.mail_second || ""}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="form-row col-md-12 mx-0">
                <div className="form-group col-md-6">
                  <label>Nom secrétaire</label>
                  <input
                    type="text"
                    name="secretary_name"
                    className="form-control"
                    value={contact.secretary_name || ""}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="form-row col-md-12 mx-0">
                <div className="form-group col-md-6">
                  <label>Adresse</label>
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    value={contact.address || ""}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Adresse 2</label>
                  <input
                    type="text"
                    name="address_more"
                    className="form-control"
                    value={contact.address_more || ""}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="form-row col-md-12 mx-0">
                <div className="form-group col-md-4">
                  <label>Code postal</label>
                  <input
                    type="text"
                    name="zip_code"
                    className="form-control"
                    value={contact.zip_code || ""}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Ville</label>
                  <input
                    type="text"
                    name="city"
                    className="form-control"
                    value={contact.city || ""}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Pays</label>
                  <input
                    type="text"
                    name="country"
                    className="form-control"
                    value={contact.country || ""}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="form-row col-md-10 mt-4 d-flex flex-direction-center mx-auto">
              <h4 className="col-md-12 font-weight-bold mb-4">
                Informations société
              </h4>
              <div className="form-row col-md-12 mx-0">
                <div className="form-group col-md-6">
                  <label>Nom</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={company.name || ""}
                    onChange={this.handleChangeCompany}
                  />
                </div>
              </div>
              <div className="form-row col-md-12 mx-0">
                <div className="form-group col-md-6">
                  <label>Adresse</label>
                  <input
                    type="text"
                    name="address"
                    className="form-control"
                    value={company.address || ""}
                    onChange={this.handleChangeCompany}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Adresse 2</label>
                  <input
                    type="text"
                    name="address_more"
                    className="form-control"
                    value={company.address_more || ""}
                    onChange={this.handleChangeCompany}
                  />
                </div>
              </div>
              <div className="form-row col-md-12 mx-0">
                <div className="form-group col-md-4">
                  <label>Code postal</label>
                  <input
                    type="text"
                    name="zip_code"
                    className="form-control"
                    value={company.zip_code || ""}
                    onChange={this.handleChangeCompany}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Ville</label>
                  <input
                    type="text"
                    name="city"
                    className="form-control"
                    value={company.city || ""}
                    onChange={this.handleChangeCompany}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Pays</label>
                  <input
                    type="text"
                    name="country"
                    className="form-control"
                    value={company.country || ""}
                    onChange={this.handleChangeCompany}
                  />
                </div>
              </div>
            </div>

            <div className="form-row col-md-10 mt-4 d-flex flex-direction-center mx-auto">
              <h4 className="col-md-12 font-weight-bold mb-4">
                Informations de groupes
              </h4>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Tags</label>
                  <Creatable
                    placeholder={"Sélectionnez ou créez les tags"}
                    isMulti
                    value={this.state.contact.tags || []}
                    onChange={this.handleChangeTag}
                    options={this.state.tagsOptions}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Affecté à</label>
                  <Select
                    required
                    name="related_to"
                    options={contactsOptions}
                    placeholder={
                      contacts.find((item) => item.id === contact.related_to)
                        ? contacts.find(
                            (item) => item.id === contact.related_to
                          ).lastname +
                          " " +
                          contacts.find(
                            (item) => item.id === contact.related_to
                          ).firstname
                        : "Aucun contact"
                    }
                    onInputChange={this.handleSearch}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="form-group col-md-4">
                <label className="col-md-12">Date d'expiration</label>
                &nbsp;&nbsp;
                <DatePicker
                  className="form-control"
                  selected={new Date(contact.expiration_date)}
                  onChange={this.handleDateChange}
                  locale="fr"
                  dateFormat="d MMMM yyyy"
                />
              </div>
              <div className="form-group col-md-6">
                <label>ID externe</label>
                <input
                  disabled={this.props.match.params.id}
                  type="text"
                  name="external_id"
                  className="form-control"
                  value={contact.external_id || ""}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label>Contact actif</label>&nbsp;&nbsp;
                <select
                  name="active"
                  className="form-control browser-default custom-select"
                  value={contact.active}
                  onChange={this.handleChange}
                >
                  <option value={true}>Oui</option>
                  <option value={false}>Non</option>
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>Nom catégorie</label>
                <input
                  type="text"
                  name="category"
                  disabled
                  className="form-control"
                  value={contact.category || ""}
                  // onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="d-flex flex-row-reverse col-md-10 h-75 mx-auto px-0 mt-4">
              <button
                type="submit"
                disabled={
                  !allowFormConfirm ||
                  phoneNumberError ||
                  phoneNumberErrorSecond
                }
                className="btn pink darken-1 font-weight-bold white-text mx-0"
              >
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ContactForm;
