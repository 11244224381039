import React, { useEffect, useState } from "react";
import ApiService from "../services/ApiService";
import LoginForm from "../common/LoginForm";
import { clearLocalStorage, isMobile } from "../services/Utils";
import "./PlacementHome.css";
import PlacementSettings from "./PlacementSettings";
import Loader from "../common/Loader";
import PlacementPlan from "./PlacementPlan";
import PlacementMenu from "./PlacementMenu";
import { toast } from "react-toastify";

export default function PlacementHome(props) {
  const [isLogged, setIsLogged] = useState(ApiService.checkToken());
  const [isLoading, setIsLoading] = useState(false);
  const [online, setOnline] = useState(window.navigator.onLine);
  const [settingsMode, setSettingsMode] = useState(true);
  const [delegatairesList, setDelegatairesListState] = useState(null);
  const [guests, setGuestsState] = useState(null);
  const [placement, setPlacementState] = useState(null);
  const [components, setComponentsState] = useState(null);
  const [reponses, setReponsesState] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [planId, setPlanId] = useState(null);
  const doLogin = async () => {
    setIsLogged(true);
  };
  useEffect(() => {
    setOnline(online);
  }, [
    isLoading,
    settingsMode,
    online,
    guests,
    delegatairesList,
    placement,
    components,
    reponses,
  ]);

  useEffect(() => {
    if (refreshData === true) {
      loadNewData();
    }
  }, [refreshData]);
  const doLogout = () => {
    clearLocalStorage();
    setIsLogged(false);
  };
  const loadNewData = async () => {
    var guestsData;
    var componentsData;
    var placementData;
    var reponseData;
    var globalState = {
      guests,
      placement,
      components,
      reponses,
      delegatairesList,
    };
    try {
      await ApiService.request({}, "placements/" + planId, "get").then(
        function (placement) {
          placementData = placement;
          setPlacementState(placementData);
        }
      );
      await ApiService.request(
        {},
        "delegataires/byevenement/" + eventId,
        "get"
      ).then(function (delegatairesList) {
        setDelegatairesListState(delegatairesList);
      });

      await ApiService.request(
        globalState,
        "evenement_forms/byevenement/" + eventId,
        "get"
      ).then(async function (data) {
        const form = data.find(
          (form) => form.layout.documentType === "reponse"
        );
        await ApiService.request(
          {},
          "component_responses/byevenement/" + form.id,
          "get"
        ).then(function (data) {
          reponseData = data;
          setReponsesState(reponseData);
        });
      });
      await ApiService.request(
        {},
        "components/byevenement/" + eventId,
        "get"
      ).then(function (data) {
        componentsData = data.filter(
          (component) =>
            component.data.componentType === "logistic" ||
            component.data.componentType === "moment"
        );
        setComponentsState(componentsData);
      });

      await ApiService.request({}, "guests/byevenement/" + eventId, "get").then(
        function (guests) {
          guestsData = guests;
          setGuestsState(guests);
        }
      );
      if (guestsData && componentsData && placementData && reponseData) {
        filter(componentsData, guestsData, placementData, reponseData);
      }
      toast.success("Données mis à jour", 3000);
      setRefreshData(false);
    } catch (error) {
      toast.error(
        "Un problème est survenue lors de la mise à jour des données",
        5000
      );
      setRefreshData(false);
    }
  };
  async function filter(componentsArr, guestsArr, placementObj, reponseArr) {
    const componentFiltered = componentsArr
      ? componentsArr.filter((component) =>
          placementObj
            ? placementObj.data.components.some((item) => item === component.id)
            : null
        )
      : null;
    setComponentsState(componentFiltered);
    const guestsFiltered = guestsArr
      ? guestsArr.filter((guest) => {
          const reponse = reponseArr
            ? reponseArr.find((reponse) => reponse.guest_id === guest.id)
            : null;

          let willJoin = false;

          if (reponse) {
            if (reponse.responses)
              reponse.responses.forEach((componentReponse) => {
                const component = componentFiltered.find(
                  (component) => component.id === componentReponse.id
                );

                if (component) {
                  if (
                    (component.data.reponse.htmlType === "radio" &&
                      componentReponse.key !== 1) ||
                    component.data.reponse.htmlType === "checkbox"
                  ) {
                    willJoin = true;
                  }
                }
              });
          }
          return willJoin;
        })
      : null;
    setGuestsState(guestsFiltered);
  }
  return (
    <div
      className="checkin-app"
      style={{
        overflowX: "hidden",
        overflowY: isMobile ? "scroll" : "hidden",
      }}
    >
      {/* <img src={`${process.env.PUBLIC_URL}/logo-kanguroo-leftnav.png`} className="img-logo"/> */}
      {!online ? (
        <div
          className="alert alert-danger"
          style={{
            zIndex: 1000,
            textAlign: "center",
            position: "fixed",
            top: 0,
            width: "100%",
          }}
        >
          Pas de connexion internet !
        </div>
      ) : null}
      {isLoading ? (
        <Loader />
      ) : isLogged ? (
        settingsMode ? (
          <PlacementSettings
            doLogout={doLogout}
            setSettingsMode={setSettingsMode}
            setGuestsState={setGuestsState}
            setPlacementState={setPlacementState}
            setDelegatairesList={setDelegatairesListState}
            eventId={eventId}
            setEventId={setEventId}
            setPlanId={setPlanId}
            refreshData={refreshData}
          />
          
        ) : (
          <div className={isMobile ? "app-container-mobile" : "app-container"}>
            <>
            <PlacementMenu
              name={placement.name ? placement.name : null}
              displaySettings={setSettingsMode}
              delegatairesList={setDelegatairesListState}
              hotLoading={setRefreshData}
              refreshData={refreshData}
            />
            <PlacementPlan
              setIsLoadingHome={setIsLoading}
              delegataireList={delegatairesList}
              {...props}
              guests={guests}
              placement={placement}
            />{" "}
          </>
          </div>

        )
      ) : (
        <LoginForm doLogin={doLogin} btnLabel={"Se connecter"} />
      )}
    </div>
  );
}
