import React from "react";
import Loader from "../../../../common/Loader";
import ApiService from "../../../../services/ApiService";
import SvgContent from "./SvgContent";

class PlacementContainer extends React.Component {
  constructor(props) {
    super(props);

    this.addTable = this.addTable.bind(this);
    this.loadData = this.loadData.bind(this);

    this.state = {
      guests: [],
      isLoading: true,
      tables: [],
      placement: null,
      tags: [],
      components: [],
      reponses: [],
      delegatairesList: [],
      packagesList: [],
    };
  }

  addTable() {
    const tables = this.state.tables;
    tables.push({
      ...initialTable,
      y: initialTable.y + tables.length * 150,
      id: "circ" + tables.length,
    });
    console.log("tables", tables);
    this.setState(tables);
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const self = this;
    this.setState({ isLoading: true });

    await ApiService.request({}, "tags", "get").then(function (tags) {
      self.setState({ tags });
    });

    await ApiService.request(
      {},
      "delegataires/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (delegatairesList) {
      self.setState({
        delegatairesList,
      });
    });

    await ApiService.request(
      this.state,
      "evenement_forms/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(async function (data) {
      const form = data.find((form) => form.layout.documentType === "reponse");

      await ApiService.request(
        {},
        "component_responses/byevenement/" + form.id,
        "get"
      ).then(function (data) {
        self.setState({ reponses: data });
      });
    });

    await ApiService.request(
      {},
      "components/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      self.setState({
        components: data.filter(
          (component) =>
            component.data.componentType === "logistic" ||
            component.data.componentType === "moment"
        ),
      });
    });

    await ApiService.request(
      {},
      "placements/" + this.props.match.params.placementid,
      "get"
    ).then(function (placement) {
      self.setState({
        placement,
      });
    });

    await ApiService.request(
      {},
      "guests/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (guests) {
      self.setState({
        guests,
        isLoading: false,
      });
    });
    await ApiService.request(
      {},
      "packages/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      self.setState({
        packagesList: data,
      });
    });
  }
  render() {
    const {
      isLoading,
      placement,
      guests,
      tags,
      components,
      reponses,
      delegatairesList,
      packagesList,
    } = this.state;
    const { contacts } = this.props;

    if (isLoading) {
      return <Loader />;
    }

    const componentFiltered = components.filter((component) =>
      placement.data.components.some((item) => item === component.id)
    );

    // Trier les utilisateurs par packages liés au plan
    const guestsByPackages = this.state.placement.data.packages
      ? this.state.placement.data.packages.reduce((acc, packageId) => {
          const usersWithPackages = guests.filter(
            (guest) => guest.package_id === packageId
          );
          return acc.concat(usersWithPackages);
        }, [])
      : [];

    // Garder les utilisateurs avec une réponse positive
    const guestsFiltered = guestsByPackages.filter((guest) => {
      const reponse = reponses.find((reponse) => reponse.guest_id === guest.id);
      let willJoin = false;

      if (reponse) {
        if (reponse.responses && reponse.responses.length > 0)
          reponse.responses.forEach((componentReponse) => {
            const component = componentFiltered.find(
              (component) => component.id === componentReponse.id
            );

            if (component) {
              if (
                (component.data.reponse.htmlType === "radio" &&
                  componentReponse.key !== 1) ||
                component.data.reponse.htmlType === "checkbox"
              ) {
                willJoin = true;
              }
            }
          });
      }

      return willJoin;
    });
    return (
      <div className="row">
        {/* <div className="col-md-10 offset-md-1 main-content"> */}
        <div>
          {placement.svg_content ? (
            <SvgContent
              {...this.props}
              delegatairesList={delegatairesList}
              guests={guestsFiltered}
              placement={placement}
              contacts={contacts}
              tags={tags}
              components={components.filter((component) =>
                placement.data.components.some((item) => item === component.id)
              )}
              packagesList={packagesList.filter((pack) =>
                placement.data.packages.some((item) => item === pack.id)
              )}
              reponses={reponses}
            />
          ) : (
            <SvgContent
              {...this.props}
              delegatairesList={delegatairesList}
              guests={guestsFiltered}
              placement={placement}
              contacts={contacts}
              tags={tags}
              components={components.filter((component) =>
                placement.data.components.some((item) => item === component.id)
              )}
              packagesList={packagesList.filter((pack) =>
                placement.data.packages.some((item) => item === pack.id)
              )}
              reponses={reponses}
            />
          )}
        </div>
      </div>
    );
  }
}

export default PlacementContainer;

const initialTable = { x: 200, y: 100, radius: 50, fill: "blue" };
