import React from "react";
import { FormDialog } from "../../components/FormDialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CustomMuiButton } from "../../components/CustomMuiButton";
import "./Checkin.css";

export default function ModalCheckinComponent({
  modal,
  toggle,
  currentCheckinPoint,
  componentsList,
  placementsList,
  onSubmit,
}) {
  const [availableComponents, setAvailableComponents] = React.useState([]);
  const [availablePlacements, setAvailablePlacements] = React.useState([]);
  const [newCheckinPoint, setNewCheckinPoint] = React.useState({});

  React.useEffect(() => {
    setNewCheckinPoint(currentCheckinPoint
      ? currentCheckinPoint
      : { name: "", components: [], placements: [] });
  }, [currentCheckinPoint])

  React.useEffect(() => {
    // Filter selected components & placements
    const cmpList = componentsList;
    const pList = placementsList;

    if (newCheckinPoint.components) {
      const newAvailableComponents = cmpList.filter(
        (component) =>
          !newCheckinPoint.components.some(
            (selectedComponent) => selectedComponent.id === component.id
          )
      );
      setAvailableComponents(newAvailableComponents);
    } else {
      setAvailableComponents(cmpList);
    }

    if (newCheckinPoint.placements) {
      const newAvailablePlacements = pList.filter(
        (placement) =>
          !newCheckinPoint.placements.some(
            (selectedPlacement) => selectedPlacement.id === placement.id
          )
      );
      setAvailablePlacements(newAvailablePlacements);
    } else {
      setAvailablePlacements(pList);
    }
  }, [newCheckinPoint]);

  return (
    <FormDialog
      open={modal}
      handleClose={toggle}
      onSubmit={(e) => onSubmit(e, newCheckinPoint)}
      className="delegataire_quota_form"
    >
      <DialogTitle>CONFIGURER UN POINT DE CHECKIN</DialogTitle>
      <DialogContent
        sx={{
          minWidth: "870px",
          minHeight: "400px",
        }}
      >
        <>
          <div className="col-md-12 px-0 py-3">
            <h4 className="bold-titles">Nom :</h4>
            <TextField
              id="outlined-basic"
              defaultValue={
                currentCheckinPoint.name ? currentCheckinPoint.name : ""
              }
              onChange={(e) => {
                setNewCheckinPoint({
                  ...newCheckinPoint,
                  name: e.target.value,
                });
              }}
              placeholder="Nom"
              required
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#AF1242", // Couleur de bordure au survol
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#d81b60", // Couleur de bordure lorsqu'il est focalisé
                  },
                },
                "& .MuiOutlinedInput-input": {
                  ":focus": {
                    borderBottom: "none",
                    boxShadow: "none",
                  },
                },
              }}
            />
          </div>
          <div className="col-md-12 px-0 py-3">
            <h4 className="bold-titles">Sélection des composants :</h4>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={availableComponents}
              getOptionLabel={(option) => option.name}
              value={newCheckinPoint.components || []}
              onChange={(event, newComponents) => {
                setNewCheckinPoint({
                  ...newCheckinPoint,
                  components: newComponents,
                });
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Composant"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#AF1242", // Couleur de bordure au survol
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#d81b60", // Couleur de bordure lorsqu'il est focalisé
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      ":focus": {
                        borderBottom: "none",
                        boxShadow: "none",
                      },
                    },
                  }}
                />
              )}
            />
          </div>
          <div className="col-md-12 px-0 pt-3">
            <h4 className="bold-titles">Sélection des placements :</h4>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={availablePlacements}
              getOptionLabel={(option) => option.name}
              value={newCheckinPoint.placements || []}
              onChange={(event, newPlacement) => {
                setNewCheckinPoint({
                  ...newCheckinPoint,
                  placements: newPlacement,
                });
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Placement"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#AF1242", // Couleur de bordure au survol
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#d81b60", // Couleur de bordure lorsqu'il est focalisé
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      ":focus": {
                        borderBottom: "none",
                        boxShadow: "none",
                      },
                    },
                  }}
                />
              )}
            />
          </div>
        </>
      </DialogContent>
      <DialogActions>
        <CustomMuiButton
          type="button"
          onClick={toggle}
          text="Fermer"
          minHeight={40}
          textColor="#ffffff"
          buttonBgColor="#A2A2A2"
          buttonHoverBgColor="#7A7A7A"
          buttonHoverBorderColor="#0062cc"
          buttonActiveBgColor="#A2A2A2"
        />
        <CustomMuiButton
          type="submit"
          text="Valider"
          minHeight={40}
          textColor="#ffffff"
          buttonBgColor="#d81b60"
          buttonHoverBgColor="#AF1242"
          buttonHoverBorderColor="#0062cc"
          buttonActiveBgColor="#d81b60"
          disable={
            newCheckinPoint.name &&
              newCheckinPoint.components &&
              newCheckinPoint.components.length > 0
              ? false
              : true
          }
        />
      </DialogActions>
    </FormDialog>
  );
}
