import React from "react";
import ApiService from "../../../services/ApiService";
import { webPageTranslations } from "../../../common/Translations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Invoice from "../../../common/Invoice";
import { formatDate } from "../../../services/Utils";
import Loader from "../../../common/Loader";
class Invoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, invoices: [], pdfToRender: null };
    this.downLoadInvoice = this.downLoadInvoice.bind(this);
  }

  async componentDidMount() {
    const self = this;

    await ApiService.request(
      {},
      "contacts/invoices/" + this.props.contact.id,
      "get"
    ).then(function (invoices) {
      self.setState({
        invoices,
        isLoading: false,
      });
    });
  }

  downLoadInvoice(id, eventId, invoice) {
    this.setState({
      pdfToRender: (
        <Invoice
          invoice={invoice}
          guestId={id}
          contactId={id}
          eventId={eventId}
          checkEventFromAdmin={this.props.checkEventFromAdmin}
        />
      ),
    });
  }

  render() {
    const code = this.props.contact.languages;
    const contactCode = code ? code : "FR";
    const { events, guest } = this.props;
    const { invoices, pdfToRender, isLoading } = this.state;
    console.log(invoices, events);
    if (isLoading) {
      return <Loader />;
    }

    return (
      <>
        <div className="row account-sub-title">
          <div className="col-md-12 text-center">
            {webPageTranslations[contactCode].my_invoices}
          </div>
        </div>

        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div
              style={{
                textAlign: "center",
                padding: "10px",
                margin: "10px",
                backgroundColor: "white",
              }}
            >
              <table className="table table-sm table-striped">
                <thead>
                  <tr
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    <th scope="col">
                      {webPageTranslations[contactCode].event}
                    </th>
                    <th scope="col">{webPageTranslations[contactCode].date}</th>
                    <th scope="col">
                      {webPageTranslations[contactCode].total}
                    </th>
                    <th scope="col">
                      {webPageTranslations[contactCode].actions}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.map((invoice) => {
                    const eventName = events
                      ? events.find(
                          (event) => event.id === invoice.evenement_id
                        )
                        ? events.find(
                            (event) => event.id === invoice.evenement_id
                          ).data.name
                        : "Cet évenement n'existe plus"
                      : "Cet évenement n'existe plus";
                    const eventExist = events
                      ? events.find(
                          (event) => event.id === invoice.evenement_id
                        )
                        ? events.find(
                            (event) => event.id === invoice.evenement_id
                          )
                        : null
                      : null;

                    return (
                      <tr>
                        <td>{eventName}</td>
                        <td>{formatDate(invoice.created_at, true)}</td>
                        <td>{parseFloat(invoice.data.totalTTC).toFixed(2)}</td>
                        <td>
                          {eventExist ? (
                            <FontAwesomeIcon
                              onClick={() =>
                                this.downLoadInvoice(
                                  this.props.checkEventFromAdmin
                                    ? this.props.contact.id
                                    : guest.id,
                                  invoice.evenement_id,
                                  invoice
                                )
                              }
                              icon={faDownload}
                              title={"Télécharger"}
                            />
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {pdfToRender}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Invoices;
