import React, { useState } from "react";
import CGVModal from "../CGVModal";
import { webPageTranslations } from "../Translations";

const GeneralTermsComponent = (props) => {
  const [accept_policy, setAccept_policy] = useState(false);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {props.totalTTC < 0 ? (
            <p>
              {webPageTranslations[props.code ? props.code : "FR"]
                .refound_amount_message +
                " " +
                Math.abs(props.totalTTC).toFixed(2) +
                " €."}
              <br />
              {
                webPageTranslations[props.code ? props.code : "FR"]
                  .refund_message
              }{" "}
            </p>
          ) : null}
          <p>
            {
              webPageTranslations[props.code ? props.code : "FR"]
                .personal_informations
            }
          </p>{" "}
          <div>
            <div className="form-check my-4">
              <input
                onChange={(e) => {
                  setAccept_policy(!accept_policy);
                }}
                className="form-check-input"
                type="checkbox"
                defaultChecked={accept_policy}
                id="3"
              />
              <label className="form-check-label" htmlFor="3">
                {webPageTranslations[props.code ? props.code : "FR"].terms}
              </label>
              &nbsp;
              <CGVModal
                cgv={props.cgv}
                layout={props.form.layout}
                code={props.code}
                color={props.form.layout.mainColor}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <button
            onClick={props.modifyForm}
            type="submit"
            className="btn cancel-button"
          >
            {
              webPageTranslations[props.code ? props.code : "FR"]
                .cancel_registration
            }
          </button>
        </div>
        <div className="col-md-4 offset-md-4">
          <button
            disabled={props.validateOrderBtnLoading || !accept_policy}
            type="submit"
            className="btn float-right"
            style={{
              backgroundColor: props.form.layout.mainColor,
              color: props.form.layout.secondaryColor,
            }}
          >
            {webPageTranslations[props.code ? props.code : "FR"].validate_order}
          </button>
        </div>
        <div ref={props.element} id="card-element"></div>
      </div>
    </>
  );
};

export default GeneralTermsComponent;
