import React from "react";
import Multilingual from "../../../common/Multilingual";
import "./EventContainer.css";

class EventConfigEmails extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLang = this.handleLang.bind(this);
    this.state = { event: { ...this.props.event } };
  }

  handleLang(newLangSet, label) {
    this.setState({
      event: {
        ...this.state.event,
        data: {
          ...this.state.event.data,
          [label]: newLangSet,
        },
      },
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.props.onSave({ ...this.state.event });

    e.preventDefault();
  }

  handleChange(event) {
    this.setState({
      event: {
        ...this.state.event,
        [event.target.name]: event.target.value,
      },
    });
  }

  render() {
    const templatesOptions = this.props.templatesList.map((template) => ({
      label: template.name,
      value: template.id,
    }));

    const {
      invoiceTemplate,
      invoiceDelegataireTemplate,
      refundInvoiceTemplate,
      refundInvoiceDelegataireTemplate,
      registrationConfirmationTemplate,
      registrationConfirmationDelegataireTemplate,
      modificationConfirmationTemplate,
      modificationConfirmationDelegataireTemplate,
      pendingConfirmationTemplate,
      pendingConfirmationDelegataireTemplate,
      willNotJoinAndRefundTemplate,
      willNotJoinAndRefundDelegataireTemplate,
    } = this.state.event.data;

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1">
          {this.props.subMenu}
          <form onSubmit={this.handleSubmit} className="form">
            <div className="row">
              <label className="label-template-emails">
                Paramétrage des emails
              </label>
              <label className="margin-left">
                Paramétrage des emails délégataires
              </label>
            </div>
            <div className="form-row row">
              <div className="form-group col-md-12 row">
                <div className="div-margin-left">
                  <label className="label-template-emails">
                    Template utilisé pour l'envoi de factures
                  </label>
                  <Multilingual
                    lang={invoiceTemplate}
                    event={this.props.event}
                    options={templatesOptions}
                    selectLabel={"Sélectionnez un template"}
                    type="select"
                    handleLang={(e) => this.handleLang(e, "invoiceTemplate")}
                  />
                </div>
                <div className="margin-left">
                  <label>
                    Template délégataires utilisé pour l'envoi de factures{" "}
                  </label>
                  <Multilingual
                    lang={invoiceDelegataireTemplate}
                    event={this.props.event}
                    options={templatesOptions}
                    selectLabel={"Sélectionnez un template"}
                    type="select"
                    handleLang={(e) =>
                      this.handleLang(e, "invoiceDelegataireTemplate")
                    }
                  />
                </div>
              </div>
              <div className="form-group col-md-12 row">
                <div className="div-margin-left">
                  <label className="label-template-emails">
                    Template utilisé pour l'envoi des remboursement de factures
                  </label>
                  <Multilingual
                    lang={refundInvoiceTemplate}
                    event={this.props.event}
                    options={templatesOptions}
                    selectLabel={"Sélectionnez un template"}
                    type="select"
                    handleLang={(e) =>
                      this.handleLang(e, "refundInvoiceTemplate")
                    }
                  />
                </div>
                <div className="margin-left">
                  <label>
                    Template délégataires utilisé pour l'envoi des remboursement
                    de factures{" "}
                  </label>
                  <Multilingual
                    lang={refundInvoiceDelegataireTemplate}
                    event={this.props.event}
                    options={templatesOptions}
                    selectLabel={"Sélectionnez un template"}
                    type="select"
                    handleLang={(e) =>
                      this.handleLang(e, "refundInvoiceDelegataireTemplate")
                    }
                  />
                </div>
              </div>
              <div className="form-group col-md-12 row">
                <div className="div-margin-left">
                  <label className="label-template-emails">
                    Template utilisé pour la confirmation d'inscription
                  </label>
                  <Multilingual
                    lang={registrationConfirmationTemplate}
                    event={this.props.event}
                    options={templatesOptions}
                    selectLabel={"Sélectionnez un template"}
                    type="select"
                    handleLang={(e) =>
                      this.handleLang(e, "registrationConfirmationTemplate")
                    }
                  />
                </div>
                <div className="margin-left">
                  <label>
                    Template délégataires utilisé pour la confirmation
                    d'inscription
                  </label>
                  <Multilingual
                    lang={registrationConfirmationDelegataireTemplate}
                    event={this.props.event}
                    options={templatesOptions}
                    selectLabel={"Sélectionnez un template"}
                    type="select"
                    handleLang={(e) =>
                      this.handleLang(
                        e,
                        "registrationConfirmationDelegataireTemplate"
                      )
                    }
                  />
                </div>
              </div>
              <div className="form-group col-md-12 row">
                <div className="div-margin-left">
                  <label className="label-template-emails">
                    Template utilisé pour la confirmation de modification
                  </label>
                  <Multilingual
                    lang={modificationConfirmationTemplate}
                    event={this.props.event}
                    options={templatesOptions}
                    selectLabel={"Sélectionnez un template"}
                    type="select"
                    handleLang={(e) =>
                      this.handleLang(e, "modificationConfirmationTemplate")
                    }
                  />
                </div>
                <div className="margin-left">
                  <label>
                    Template délégataires utilisé pour la confirmation <br /> de
                    modification
                  </label>
                  <Multilingual
                    lang={modificationConfirmationDelegataireTemplate}
                    event={this.props.event}
                    options={templatesOptions}
                    selectLabel={"Sélectionnez un template"}
                    type="select"
                    handleLang={(e) =>
                      this.handleLang(
                        e,
                        "modificationConfirmationDelegataireTemplate"
                      )
                    }
                  />
                </div>
              </div>
              <div className="form-group col-md-12 row">
                <div className="div-margin-left">
                  <label className="label-template-emails">
                    Template utilisé pour la prise en compte en attente <br />{" "}
                    de paiement
                  </label>
                  <Multilingual
                    lang={pendingConfirmationTemplate}
                    event={this.props.event}
                    options={templatesOptions}
                    selectLabel={"Sélectionnez un template"}
                    type="select"
                    handleLang={(e) =>
                      this.handleLang(e, "pendingConfirmationTemplate")
                    }
                  />
                </div>
                <div className="margin-left">
                  <label>
                    Template délégataires utilisé pour la prise en compte en
                    attente <br /> de paiement
                  </label>
                  <Multilingual
                    lang={pendingConfirmationDelegataireTemplate}
                    event={this.props.event}
                    options={templatesOptions}
                    selectLabel={"Sélectionnez un template"}
                    type="select"
                    handleLang={(e) =>
                      this.handleLang(
                        e,
                        "pendingConfirmationDelegataireTemplate"
                      )
                    }
                  />
                </div>
              </div>
              <div className="form-group col-md-12 row">
                <div className="div-margin-left">
                  <label className="label-template-emails">
                    Template utilisé lors d'une annulation
                  </label>
                  <Multilingual
                    lang={willNotJoinAndRefundTemplate}
                    event={this.props.event}
                    options={templatesOptions}
                    selectLabel={"Sélectionnez un template"}
                    type="select"
                    handleLang={(e) =>
                      this.handleLang(e, "willNotJoinAndRefundTemplate")
                    }
                  />
                </div>
                <div className="margin-left">
                  <label>
                    Template délégataires utilisé lors d'une annulation{" "}
                  </label>
                  <Multilingual
                    lang={willNotJoinAndRefundDelegataireTemplate}
                    event={this.props.event}
                    options={templatesOptions}
                    selectLabel={"Sélectionnez un template"}
                    type="select"
                    handleLang={(e) =>
                      this.handleLang(
                        e,
                        "willNotJoinAndRefundDelegataireTemplate"
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="form-row" />
            <input
              type="submit"
              value="Enregistrer"
              className="btn pink darken-1 btn-lg float-right "
            />
          </form>
        </div>
      </div>
    );
  }
}

export default EventConfigEmails;
