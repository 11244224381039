import React from "react";
import Button from "@mui/material/Button";

export function CustomMuiButton({
  type = "button",
  onClick,
  text,
  buttonBgColor,
  textColor,
  minHeight = 60,
  buttonHoverBgColor,
  buttonHoverBorderColor,
  buttonActiveBgColor,
  disable = false,
  sx,
  ...props
}) {
  return (
    <Button
      variant="contained"
      type={type}
      onClick={onClick}
      disabled={disable}
      sx={{
        paddingInline: "1.5rem",
        minHeight: minHeight,
        width: "fit-content",
        color: textColor,
        backgroundColor: buttonBgColor,
        "&:hover": {
          backgroundColor: buttonHoverBgColor,
          borderColor: buttonHoverBorderColor,
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: "none",
          backgroundColor: buttonActiveBgColor,
        },
        "&:focus": {
          boxShadow: "none",
        },
        ...sx,
      }}
      {...props}
    >
      {text}
    </Button>
  );
}
