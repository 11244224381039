import React from "react";
import { Button } from "mdbreact";
import ReactExport from "react-export-excel";

class ExportDelegataires extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: this.props.usersList.length > 0 ? false : true,
    };
  }
  async createRow(user) {
    try {
      let cleanHtml = function (str) {
        return String(str)
          .replaceAll(/(<([^>]+)>)/gi, "")
          .replaceAll("&ntilde;", "ñ")
          .replaceAll("&Ntilde;", "Ñ")
          .replaceAll("&amp;", "&")
          .replaceAll("&Ntilde;", "Ñ")
          .replaceAll("&ntilde;", "ñ")
          .replaceAll("&Ntilde;", "Ñ")
          .replaceAll("&Agrave;", "À")
          .replaceAll("&Aacute;", "Á")
          .replaceAll("&Acirc;", "Â")
          .replaceAll("&Atilde;", "Ã")
          .replaceAll("&Auml;", "Ä")
          .replaceAll("&Aring;", "Å")
          .replaceAll("&AElig;", "Æ")
          .replaceAll("&Ccedil;", "Ç")
          .replaceAll("&Egrave;", "È")
          .replaceAll("&Eacute;", "É")
          .replaceAll("&Ecirc;", "Ê")
          .replaceAll("&Euml;", "Ë")
          .replaceAll("&Igrave;", "Ì")
          .replaceAll("&Iacute;", "Í")
          .replaceAll("&Icirc;", "Î")
          .replaceAll("&Iuml;", "Ï")
          .replaceAll("&ETH;", "Ð")
          .replaceAll("&Ntilde;", "Ñ")
          .replaceAll("&Ograve;", "Ò")
          .replaceAll("&Oacute;", "Ó")
          .replaceAll("&Ocirc;", "Ô")
          .replaceAll("&Otilde;", "Õ")
          .replaceAll("&Ouml;", "Ö")
          .replaceAll("&Oslash;", "Ø")
          .replaceAll("&Ugrave;", "Ù")
          .replaceAll("&Uacute;", "Ú")
          .replaceAll("&Ucirc;", "Û")
          .replaceAll("&Uuml;", "Ü")
          .replaceAll("&Yacute;", "Ý")
          .replaceAll("&THORN;", "Þ")
          .replaceAll("&szlig;", "ß")
          .replaceAll("&agrave;", "à")
          .replaceAll("&aacute;", "á")
          .replaceAll("&acirc;", "â")
          .replaceAll("&atilde;", "ã")
          .replaceAll("&auml;", "ä")
          .replaceAll("&aring;", "å")
          .replaceAll("&aelig;", "æ")
          .replaceAll("&ccedil;", "ç")
          .replaceAll("&egrave;", "è")
          .replaceAll("&eacute;", "é")
          .replaceAll("&ecirc;", "ê")
          .replaceAll("&euml;", "ë")
          .replaceAll("&igrave;", "ì")
          .replaceAll("&iacute;", "í")
          .replaceAll("&icirc;", "î")
          .replaceAll("&iuml;", "ï")
          .replaceAll("&eth;", "ð")
          .replaceAll("&ntilde;", "ñ")
          .replaceAll("&ograve;", "ò")
          .replaceAll("&oacute;", "ó")
          .replaceAll("&ocirc;", "ô")
          .replaceAll("&otilde;", "õ")
          .replaceAll("&ouml;", "ö")
          .replaceAll("&oslash;", "ø")
          .replaceAll("&ugrave;", "ù")
          .replaceAll("&uacute;", "ú")
          .replaceAll("&ucirc;", "û")
          .replaceAll("&uuml;", "ü")
          .replaceAll("&yacute;", "ý")
          .replaceAll("&thorn;", "þ")
          .replaceAll("&yuml;", "ÿ");
      };

      let delegataireLogo = null;
      if (user.logo) {
        delegataireLogo =
          "http://res.cloudinary.com/kanguroo-event/image/upload/" + user.logo;
      }

      let delegataireContact_informations = null;
      if (user.contact_informations) {
        delegataireContact_informations = cleanHtml(user.contact_informations);
      }

      let delegataireInviting_person = null;
      if (user.inviting_person) {
        delegataireInviting_person = cleanHtml(user.inviting_person);
      }

      const userData = [
        user.id,
        user.lastname,
        user.firstname,
        this.props.findUserRole(user.role),
        user.job,
        user.department,
        user.category,
        user.email,
        user.email_verified_at,
        user.mobile,
        user.phone,
        user.address,
        user.address_more,
        user.zip_code,
        user.city,
        user.country,
        delegataireContact_informations,
        delegataireInviting_person,
        delegataireLogo,
        user.children_entities,
        user.detect_import,
        user.import_status,
        user.is_owner,
        user.new_password_required,
        user.password,
        user.password_reset_token,
        user.remember_token,
        user.created_at,
        user.updated_at,
      ];
      return userData;
    } catch (e) {
      console.log(e);
      return;
    }
  }

  render() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

    const columns = [
      "ID utilisateur",
      "Nom",
      "Prénom",
      "Rôle",
      "Fonction",
      "Service",
      "Categorie",
      "Email",
      "Email verified_at",
      "Tel. mobile",
      "Tel. fixe",
      "Adresse",
      "Adresse plus",
      "Code postal",
      "Ville",
      "Pays",
      "Informations de contact",
      "Personne invitante",
      "Logo",
      "children_entities",
      "detect_import",
      "import_status",
      "is_owner",
      "new_password_required",
      "password",
      "password_reset_token",
      "remember_token",
      "Date création",
      "Date modification",
    ];

    const data = [];
    const subColumns = [];

    data.push(
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ].concat(subColumns)
    );

    this.props.usersList.forEach(async (user) => {
      //console.log(user);
      if (user.role === "hf6dPL9be78!") {
        data.push(await this.createRow(user));
      }
    });

    const multiDataSet = [
      {
        columns,
        data,
      },
    ];

    const { isLoading } = this.state;

    let filename_date = new Date();
    let filename =
      document.location.href +
      "_delegataires_" +
      filename_date.toLocaleString("default", { year: "numeric" }) +
      "-" +
      filename_date.toLocaleString("default", { month: "2-digit" }) +
      "-" +
      filename_date.toLocaleString("default", { day: "2-digit" });
    filename = filename.replaceAll("http://", "");
    filename = filename.replaceAll("https://", "");
    filename = filename.replaceAll("/admin/users/", "");
    filename = filename.replaceAll("/admin/users", "");

    return (
      <ExcelFile
        filename={filename}
        element={
          <div className="button-container">
            <Button disabled={isLoading} className="btn grey darken-4 ">
              Export délégataires
            </Button>
          </div>
        }
      >
        <ExcelSheet dataSet={multiDataSet} name="Export global" />
      </ExcelFile>
    );
  }
}

export default ExportDelegataires;
