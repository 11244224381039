import React, { useEffect, useState } from "react";
import ReactExport from "react-data-export";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const borders = {
  left: { style: "thin", color: { rgb: "000000" } },
  right: { style: "thin", color: { rgb: "000000" } },
  top: { style: "thin", color: { rgb: "000000" } },
  bottom: { style: "thin", color: { rgb: "000000" } },
};

function ExportPlanExcel({ nameEvent, namePlacement, rectangles, planName }) {
  const [dataSet, setDataSet] = useState([]);

  const findZone = (zonesList, name) => {
    const range = zonesList.length;
    for (let i = 0; i < range; i++) {
      if (zonesList[i].name === name) return i;
    }
    return -1;
  };

  const formatSeatDescription = (seat) => {
    try {
      return seat.props["data-tip"].replace(
        /(<(\/)br>)|(<br>)|(<br(\/)>)/gim,
        "\n"
      );
    } catch (error) {
      return "";
    }
  };

  const getSeatRow = (seat) => {
    try {
      if (seat.props.id.split("_")[3]) { // Permet d'organiser correctement les rangs si _i existe dans la key du svg'
        return Number(seat.props.id.split("_")[3].match(/\d+$/)[0]) - 1;
      } else {
        return Number(seat.props.id.split("_")[1].match(/\d+$/)[0]) - 1;
      }
    } catch (error) {
      return null;
    }
  };

  const initZoneRow = (seat) => {
    const rowNbr = getSeatRow(seat);
    if (rowNbr === null) return;

    const rows = [];
    for (let i = 0; i < rowNbr + 1; i++) {
      rows.push([]);
    }
    rows[rowNbr].push(seat);
    return rows;
  };

  const setSeatInRow = (rows, rowIndex, seat) => {
    if (rows) {
      if (rowIndex > rows.length - 1) {
        for (let i = 0; i < rowIndex + 1; i++) {
          if (!rows[i]) {
            rows.push([]);
          }
        }
      }
      rows[rowIndex].push(seat);
    }
  };

  const getDataSheet = (zones) => {
    if (!zones) return [];
    const res = zones.reduce((accumulator, zone, i) => {
      let data = {
        columns: [
          {
            title: zone.name,
            width: { wpx: 120 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f7aaaa" } },
            },
          },
        ],
        data: [
          ...zone.rows.map((row, i) => {
            const newRow = row.map((seat) => ({
              value: formatSeatDescription(seat),
              style: {
                font: { sz: "7.5", bold: true },
                fill: {
                  patternType: "solid",
                  fgColor: { rgb: seat.props.fill.split("#")[1] },
                },
                border: borders,
              },
            }));
            //* insert row number
            newRow.splice(0, 0, `R-${zone.rows.length - i}`);
            return newRow;
          }),
        ],
      };

      //* add some space
      if (i !== zones.length - 1) {
        data = [
          { ...data },

          {
            columns: [{ title: "" }],
            data: [[], [], []],
          },
        ];
      }
      return accumulator.concat(data);
    }, []);

    //* add title
    res.splice(0, 0, {
      columns: [
        {
          title: planName,
          style: {
            font: { sz: "18", bold: true },
            fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
          },
        },
      ],
      data: [[]],
    });

    return res;
  };

  const setupSeatAndZonesPositions = (zones) => {
    try {
      return zones
        .map((zone) => {
          zone.rows &&
            zone.rows.forEach((row, i) => {
              zone.rows[i] = row.reverse();
            });
          zone.rows = zone.rows.reverse();
          return zone;
        })
        .reverse();
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  // const replaceZoneName = () => {
  //   rectangles.filter((rect) => rect.type === "text");
  // }

  const handleClick = () => {
    let zones = [];
    rectangles.forEach((rect) => {
      if (rect.type === "rect") {
        const z = rect.props.id && rect.props.id.split("_")[0];
        const zoneIndex = findZone(zones, z);

        if (zoneIndex !== -1) {
          // zone already exist in list
          if (zones[zoneIndex]) {
            //* zones[zoneIndex].seats.push(rect);
            const rowIndex = getSeatRow(rect);
            if (rowIndex !== null) {
              setSeatInRow(zones[zoneIndex].rows, rowIndex, rect);
            } else {
              zones[zoneIndex].rows = initZoneRow(rect);
            }
          }
        } else {
          // zone doesn't exist in list
          zones.push({
            name: z,
            rows: initZoneRow(rect),
            maxRowLength: 0,
          });
        }
      }
    });
    // reverse plan
    zones = setupSeatAndZonesPositions(zones);

    if (zones.length > 0) {
      setDataSet(getDataSheet(zones));
    }
  };

  useEffect(() => {
    handleClick();
  }, [rectangles]);
  const date = new Date();
  return (
    <>
      {dataSet.length > 0 ? (
        <ExcelFile
        filename={`${nameEvent}_planxls_${namePlacement}_${date.getDate()}_${(date.getMonth() + 1).toString().padStart(2, '0')}_${date.getFullYear()}`}
          element={
            <div className="button-container">
              <button className="btn pink darken-1 ">Plan xls</button>
            </div>
          }
        >
          <ExcelSheet dataSet={dataSet} name="Organization" />
        </ExcelFile>
      ) : null}
    </>
  );
}

export default ExportPlanExcel;
