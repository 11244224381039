import React from "react";
import { webPageTranslations } from "../Translations";
import { MDBInput } from "mdbreact";

class PaymentMethode extends React.Component {
  render() {
    const {
      code,
      isAdmin,
      allowCheckForAdmin,
      allowCheckForUser,
      totalTTC,
      invoice,
      setPaymentModeState,
    } = this.props;

    return (
      <>
        <div
          className="row"
          style={{
            backgroundColor: "#EEEEEE",
            padding: "5px",
          }}
        >
          <div className="col-md-12">
            <h4>{webPageTranslations[code ? code : "FR"].payment_methods}</h4>
            {Number(totalTTC) > 0 ? (
              <>
                <div className="col-md-12">
                  <div className="payment-method-container">
                    <div className="payment-mode-card">
                      <MDBInput
                        id="1"
                        onClick={() => setPaymentModeState("card")}
                        checked={invoice.paymentMode === "card"}
                        label={
                          webPageTranslations[code ? code : "FR"].payment_card
                        }
                        type="radio"
                      />
                      <div className="payment-mode-icons">
                        <img
                          alt="mastercard"
                          src={process.env.PUBLIC_URL + "/mastercard.png"}
                        />
                        <img
                          alt="americanexpress"
                          src={process.env.PUBLIC_URL + "/americanexpress.png"}
                        />
                        <img
                          alt="visa"
                          src={process.env.PUBLIC_URL + "/visa.png"}
                        />
                      </div>
                    </div>

                    {(isAdmin && allowCheckForAdmin) ||
                    (!isAdmin && allowCheckForUser) ? (
                      <div className="check-payment-mode-input">
                        <MDBInput
                          id="2"
                          onClick={() => setPaymentModeState("check")}
                          checked={invoice.paymentMode === "check"}
                          label={webPageTranslations[code ? code : "FR"].check}
                          type="radio"
                        />
                        <img
                          alt="check"
                          src={process.env.PUBLIC_URL + "/check.png"}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                {invoice.paymentMode === "card" ? (
                  <div className="text-center">{this.props.children}</div>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}
export default PaymentMethode;
