import React from "react";
import { Switch, Route } from "react-router-dom";
import EventsList from "./EventsList";
import EventContainer from "./EventContainer";

class Events extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          path="/admin/events/edit/:eventid?"
          render={props => (
            <EventContainer
              {...props}
              updateContacts={this.props.updateContacts}
              contacts={this.props.contacts}
            />
          )}
        />
        <Route exact path="/admin/events" component={EventsList} />
      </Switch>
    );
  }
}

export default Events;
