// Imports des packages
import React from "react";
import { Link } from "react-router-dom";

// Import des composants librairie
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faAddressCard,
  faUsers,
  faCogs,
} from "@fortawesome/free-solid-svg-icons";

export default function HeaderConfigurationMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          color: "white",
          paddingTop: "0.43rem",
          fontWeight: 350,
          textTransform: "none",
          fontSize: "1rem",
        }}
      >
        <span style={{ marginRight: "0.5rem" }}>
          <FontAwesomeIcon icon={faCogs} color="white" />
        </span>
        &nbsp; Configuration
        <span style={{ marginLeft: "0.5rem" }}>
          {open ? (
            <FontAwesomeIcon icon={faAngleUp} color="white" />
          ) : (
            <FontAwesomeIcon icon={faAngleDown} color="white" />
          )}
        </span>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link
            className="nav-link"
            to="/admin/users"
            style={{ color: "black" }}
          >
            <FontAwesomeIcon icon={faUsers} />
            &nbsp; Utilisateurs
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link
            className="nav-link"
            to="/admin/settings"
            style={{ color: "black" }}
          >
            <FontAwesomeIcon icon={faCogs} />
            &nbsp; Workspace
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link
            className="nav-link"
            to="/admin/entities"
            style={{ color: "black" }}
          >
            <FontAwesomeIcon icon={faAddressCard} />
            &nbsp; Entités
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
}
