import React from "react";
import ApiService from "../../../services/ApiService";
import { MDBInput, Button } from "mdbreact";
import { toast } from "react-toastify";
import FileUpload from "../../../common/FileUpload";
import { Image, CloudinaryContext } from "cloudinary-react";
import Multilingual from "../../../common/Multilingual";
import { languagesList } from "../../../common/Translations";

class EntityForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.removeIcon = this.removeIcon.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.handleLang = this.handleLang.bind(this);
    this.handleInvoiceFooter = this.handleInvoiceFooter.bind(this);
    this.webHookStripeUrlApiToConfigure =
      this.webHookStripeUrlApiToConfigure.bind(this);
    this._isMounted = false;

    this.state = {
      entity: {
        data: {
          cgv: languagesList,
          stripeSecretKey: "",
          stripePublicKey: "",
          stripeWebhookKey: "",
          name: "",
          address: "",
          zipCode: "",
          city: "",
          lastname: "",
          firstname: "",
          phone: "",
          email: "",
          logo: null,
          paymentDeadline: "0",
          invoiceFooter: "",
          allowCheckForUser: false,
          allowCheckForAdmin: false,
        },
      },
      webHookStripeUrlApi: "",
      isLoading: false,
    };
  }

  handleLang(newLangSet, label) {
    this.setState({
      entity: {
        ...this.state.entity,
        data: {
          ...this.state.entity.data,
          [label]: newLangSet,
        },
      },
    });
  }

  handleInvoiceFooter(newFootValue, label) {
    this.setState({
      entity: {
        ...this.state.entity,
        data: {
          ...this.state.entity.data,
          [label]: newFootValue,
        },
      },
    });
  }

  removeIcon() {
    this.setState({
      entity: {
        ...this.state.entity,
        data: { ...this.state.entity.data, logo: null },
      },
    });
  }

  onFileUpload(id) {
    this.setState({
      entity: {
        ...this.state.entity,
        data: { ...this.state.entity.data, logo: id },
      },
    });
  }
  async componentDidMount() {
    const self = this;
    this._isMounted = true;
    if (this.props.match.params.id) {
      self._isMounted && self.setState({ ...self.state, isLoading: true });
      await ApiService.request(
        this.state,
        "children_entities/" + this.props.match.params.id,
        "get"
      ).then(function (data) {
        self._isMounted &&
          self.setState({
            isLoading: false,
            entity: {
              ...data,
              data: { ...self.state.entity.data, ...data.data },
            },
          });
      });
    }
    self.webHookStripeUrlApiToConfigure();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange(event) {
    this.setState({
      entity: {
        ...this.state.entity,
        data: {
          ...this.state.entity.data,
          [event.target.name]: event.target.value,
        },
      },
    });
  }

  handleSubmit(e) {
    let method = this.state.entity.id ? "put" : "post";
    let id = this.state.entity.id ? this.state.entity.id : "";
    const self = this;

    ApiService.request(
      { ...this.state.entity },
      "children_entities/" + id,
      method
    ).then(function () {
      toast.success("Entitée sauvegardée", {
        autoClose: 3000,
      });

      self.props.history.push("/admin/entities");
    });

    e.preventDefault();
  }

  webHookStripeUrlApiToConfigure() {
    switch (true) {
      case window.location.href.includes("epcr.app.kanguroo-event.fr"):
        this.setState({
          webHookStripeUrlApi:
            "https://epcr.api.kanguroo-event.fr/api/webhook_stripe",
        });
        break;
      case window.location.href.includes("app.participer-evenements.fr"):
      case window.location.href.includes("stellium.app.kanguroo-event.fr"):
        this.setState({
          webHookStripeUrlApi:
            "https://stellium.api.kanguroo-event.fr/api/webhook_stripe",
        });
        break;
      case window.location.href.includes("veolia.app.kanguroo-event.fr"):
        this.setState({
          webHookStripeUrlApi:
            "https://veolia.api.kanguroo-event.fr/api/webhook_stripe",
        });
        break;
      case window.location.href.includes("purevents.app.kanguroo-event.fr"):
        this.setState({
          webHookStripeUrlApi:
            "https://purevents.api.kanguroo-event.fr/api/webhook_stripe",
        });
        break;
      case window.location.href.includes("digeek.app.kanguroo-event.fr"):
        this.setState({
          webHookStripeUrlApi:
            "https://digeek.api.kanguroo-event.fr/api/webhook_stripe",
        });
        break;
      case window.location.href.includes("lnr.app.kanguroo-event.fr"):
      case window.location.href.includes("app.lnr-protocole.fr"):
        this.setState({
          webHookStripeUrlApi:
            "https://lnr.api.kanguroo-event.fr/api/webhook_stripe",
        });
        break;
      case window.location.href.includes("test.app.kanguroo-event.fr"):
        this.setState({
          webHookStripeUrlApi:
            "https://test.api.dev.kanguroo-event.fr/api/webhook_stripe",
        });
        break;
      // DEV
      case window.location.href.includes("localhost:3000"):
      case window.location.href.includes("localhost:3001"):
      case window.location.href.includes("localhost:3003"):
      case window.location.href.includes("localhost:3002"):
      case window.location.href.includes("192.168"):
        this.setState({
          webHookStripeUrlApi:
            "https://lnr.api.dev.kanguroo-event.fr/api/webhook_stripe",
        });
        break;
      case window.location.href.includes("epcr.app.dev.kanguroo-event.fr"):
        this.setState({
          webHookStripeUrlApi:
            "https://epcr.api.dev.kanguroo-event.fr/api/webhook_stripe",
        });
        break;
      case window.location.href.includes("digeek.app.dev.kanguroo-event.fr"):
        this.setState({
          webHookStripeUrlApi:
            "https://digeek.api.dev.kanguroo-event.fr/api/webhook_stripe",
        });
        break;
      case window.location.href.includes("test.app.dev.kanguroo-event.fr"):
        this.setState({
          webHookStripeUrlApi:
            "https://test.api.dev.kanguroo-event.fr/api/webhook_stripe",
        });
        break;
      case window.location.href.includes("stellium.app.dev.kanguroo-event.fr"):
        this.setState({
          webHookStripeUrlApi:
            "https://stellium.api.dev.kanguroo-event.fr/api/webhook_stripe",
        });
        break;
      case window.location.href.includes("veolia.app.dev.kanguroo-event.fr"):
        this.setState({
          webHookStripeUrlApi:
            "https://veolia.api.dev.kanguroo-event.fr/api/webhook_stripe",
        });
        break;
      case window.location.href.includes("app.dev.kanguroo-event.fr"):
      case window.location.href.includes("lnr.app.dev.kanguroo-event.fr"):
        this.setState({
          webHookStripeUrlApi:
            "https://api.dev.kanguroo-event.fr/api/webhook_stripe",
        });
        break;
      default:
        throw new Error("Cannot detect corresponding API");
    }
  }

  render() {
    const entity = this.state.entity.data;
    if (this.state.isLoading) {
      return <p>Loading ...</p>;
    }
    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <form onSubmit={this.handleSubmit} className="form">
            <div className="form-row mt-4">
              <div className="form-group col-md-6">
                <MDBInput
                  type="text"
                  name="name"
                  label="Nom de l'entité"
                  value={entity.name || ""}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="form-group col-md-6">
                <MDBInput
                  type="text"
                  name="address"
                  label="Adresse"
                  value={entity.address || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4">
                <MDBInput
                  type="text"
                  name="zipCode"
                  label="Code postal"
                  value={entity.zipCode || ""}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group col-md-8">
                <MDBInput
                  type="text"
                  name="city"
                  label="Ville"
                  value={entity.city || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <MDBInput
                  type="text"
                  name="lastname"
                  label="Nom du contact"
                  value={entity.lastname || ""}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group col-md-8">
                <MDBInput
                  type="text"
                  name="firstname"
                  label="Prénom du contact"
                  value={entity.firstname || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <MDBInput
                  type="text"
                  name="phone"
                  label="Téléphone"
                  value={entity.phone || ""}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <MDBInput
                  type="email"
                  name="email"
                  label="Email"
                  value={entity.email || ""}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <br />
            <div className="form-row">
              <div className="form-group col-md-12">
                <h3>Configuration du paiement</h3>
              </div>
              <div className="form-group col-md-6">
                <MDBInput
                  type="text"
                  name="stripeSecretKey"
                  label="Clé secrète de Stripe"
                  value={entity.stripeSecretKey || ""}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <MDBInput
                  type="text"
                  name="stripePublicKey"
                  label="Clé publique de Stripe"
                  value={entity.stripePublicKey || ""}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <MDBInput
                  type="text"
                  name="stripeWebhookKey"
                  label="Clé webhook de Stripe"
                  value={entity.stripeWebhookKey || ""}
                  onChange={this.handleChange}
                />
              </div>
              <div>
                <div>
                  <span className="font-weight-bold">{`Ajouter le point de terminaison du << Webhook >> suivant: `}</span>
                  <code
                    style={{
                      fontFamily: '"Consolas","courier new"',
                      color: "crimson",
                      backgroundColor: "#f1f1f1",
                      padding: "2px",
                      fontSize: "105%",
                    }}
                  >
                    {this.state.webHookStripeUrlApi}
                  </code>
                  <span> à vos paramètre de compte stripe</span>
                </div>
                <div className="mt-3">
                  <strong>
                    Les évènements à configurer sont les suivants:
                  </strong>
                  <p>
                    {/* TODO: Remove charge as we're not using it confirm with SEB */}
                    charge.fail
                    <br />
                    charge.pending
                    <br />
                    charge.refunded
                    <br />
                    charge.succeeded
                    <br />
                    payment_intent.created
                    <br />
                    payment_intent.succeeded
                    <br />
                  </p>
                </div>
              </div>
              <div
                style={{ marginTop: "2rem" }}
                className="form-group col-md-12 "
              >
                <h4 style={{ fontSize: "1.5rem" }}>Paiement par Chèque</h4>
                <MDBInput
                  id="1"
                  onClick={() =>
                    this.setState({
                      entity: {
                        ...this.state.entity,
                        data: {
                          ...this.state.entity.data,
                          allowCheckForAdmin: !entity.allowCheckForAdmin,
                        },
                      },
                    })
                  }
                  checked={entity.allowCheckForAdmin}
                  label={
                    "Utiliser le paiement par chèque pour les administrateurs (utilisable depuis le backoffice)"
                  }
                  type="checkbox"
                />
                <MDBInput
                  id="2"
                  onClick={() =>
                    this.setState({
                      entity: {
                        ...this.state.entity,
                        data: {
                          ...this.state.entity.data,
                          allowCheckForUser: !entity.allowCheckForUser,
                        },
                      },
                    })
                  }
                  checked={entity.allowCheckForUser}
                  label={
                    "Utiliser le paiement par chèque pour les invités (les inscriptions devront être validées manuellement à réception du règlement)"
                  }
                  type="checkbox"
                />
              </div>

              <div className="form-group col-md-12 form-row gap-5">
                <div className="col-md-12 mb-3">
                  <h4 style={{ fontSize: "1.5rem" }}>
                    Configuration des factures
                  </h4>
                </div>
                <div className="col-md-4" style={{ marginRight: "2rem" }}>
                  <label>Logo</label>
                  <FileUpload
                    buttonLabel={"upload"}
                    onFileUpload={this.onFileUpload}
                  />

                  {entity.logo ? (
                    <div>
                      <CloudinaryContext cloudName="kanguroo-event">
                        <Image
                          className="img-thumbnail"
                          publicId={entity.logo}
                        />
                      </CloudinaryContext>
                      <Button
                        className="btn pink white-text darken-1"
                        onClick={this.removeIcon}
                      >
                        Supprimer le logo
                      </Button>
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <label>Sélectionner la date d'échéance des factures</label>
                  <select
                    required
                    name="paymentDeadline"
                    className="form-control browser-default custom-select"
                    onChange={this.handleChange}
                    value={entity.paymentDeadline || ""}
                  >
                    <option value="0">Immédiat</option>
                    <option value="30">30 jours</option>
                    <option value="60">60 jours</option>
                  </select>
                  <div className="mt-3">
                    <label>Pied de page de la facture</label>
                    <textarea
                      type="text"
                      name="invoiceFooter"
                      onChange={this.handleChange}
                      className="form-control"
                      rows="3"
                      value={entity.invoiceFooter || ""}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Conditions générales de vente</label>
                <Multilingual
                  lg="12"
                  lang={entity.cgv}
                  type="textarea"
                  handleLang={(e) => this.handleLang(e, "cgv")}
                />
              </div>
            </div>

            <div className="form-row" />
            <input
              type="submit"
              value="Enregistrer"
              className="btn pink darken-1 btn-lg float-right "
            />
          </form>
        </div>
      </div>
    );
  }
}

export default EntityForm;
