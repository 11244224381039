import React from "react";
import Select from "react-select";

export default function SelectComponent({
  name,
  className,
  onChange,
  options,
  value,
  style,
  maxMenuHeight,
  defaultValue,
  defaultInputValue,
  placeHolder,
  multi = false,
}) {
  return (
    <Select
      name={name}
      className={className}
      onChange={(e) => onChange(e)}
      options={options}
      value={value}
      style={style}
      isMulti={multi}
      maxMenuHeight={maxMenuHeight}
      menuPlacement="auto"
      defaultValue={defaultValue}
      defaultInputValue={defaultInputValue}
      placeholder={placeHolder}
    />
  );
}
