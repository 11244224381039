import React from "react";
import { Container, Draggable } from "react-smooth-dnd";
import { applyDrag } from "./utils";
import FontPicker from "font-picker-react";
import { ChromePicker } from "react-color";
import MenuEditor from "./MenuEditor";
import ImgEditor from "./ImgEditor";
import MapEditor from "./MapEditor";

import Gabarit1 from "./Gabarit1";
import Gabarit2 from "./Gabarit2";
import "./FormEditor.css";
import { Button } from "mdbreact";

class FormEditor extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickColor = this.handleClickColor.bind(this);
    this.filterComponentsList = this.filterComponentsList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleLayoutChange = this.handleLayoutChange.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);

    this.state = {
      form: this.props.form,
      type_selected: "",
      composantsList: [],
      displayColorPicker: "",
      default_language: this.props.default_language,
    };
  }

  componentDidMount() {
    this.filterComponentsList("");
  }
  componentDidUpdate() {}
  handleClickColor = (e, name) => {
    e.preventDefault();
    this.setState({ displayColorPicker: name });
  };

  handleCloseColor = () => {
    this.setState({ displayColorPicker: "" });
  };
  handleDrop = (e, composantsList) => {
    var newList = applyDrag(composantsList, e);
    // newList.map((list) => {
    //   list.displayTitle = true
    // })
    this.setState({
      composantsList: newList,
    });
  };
  handleChange(event) {
    this.setState({
      form: { ...this.state.form, [event.target.name]: event.target.value },
    });
  }

  handleLayoutChange(layout) {
    // console.log("bonjour", layout);
    this.setState({
      form: {
        ...this.state.form,
        layout: { ...this.state.form.layout, ...layout },
      },
    });
  }

  filterComponentsList(filter) {
    const filteredList = this.props.composantsList.filter(
      (composant) =>
        !this.state.form.layout.itemsSide.find(
          (item) => item.id === composant.id
        ) &&
        !this.state.form.layout.itemsMain.find(
          (item) => item.id === Number(composant.id)
        )
    );

    this.setState({
      composantsList:
        filter.length > 0
          ? filteredList.filter(
              (component) => component.data.componentType === filter
            )
          : filteredList,
      type_selected: filter,
    });
  }

  saveForm() {
    this.state.form.layout.itemsMain.map((main) => {
      if (main.displayTitle === true || main.displayTitle === false) {
        main.displayTitle
          ? (main.displayTitle = true)
          : (main.displayTitle = false);
      } else {
        main.displayTitle = true;
      }
      if (
        main.displayDescription === true ||
        main.displayDescription === false
      ) {
        main.displayDescription
          ? (main.displayDescription = true)
          : (main.displayDescription = false);
      } else {
        main.displayDescription = true;
      }
      return main;
    });
    this.state.form.layout.itemsSide.map((side) => {
      side.displayTitle
        ? (side.displayTitle = true)
        : (side.displayTitle = false);
      side.displayDescription
        ? (side.displayDescription = true)
        : (side.displayDescription = false);
      return side;
    });
    this.props.onSave(this.state.form);
  }

  handleLanguageChange = (e) => {
    this.setState({
      selectedLanguage: e.target.value,
    });
  };

  // Prévisualitation du header et du footer en fonction de la langue

  // getBackgroundImageUrl = (imageType) => {
  //   const { form, selectedLanguage } = this.state;
  //   const image = form.layout[imageType]; // Utilisez la variable imageType pour accéder à headerImage ou footerImage

  //   if (!image) {
  //     return ''; // Retourne une chaîne vide si l'image est undefined
  //   }

  //   if (typeof image === 'string') {
  //     // Si l'image est une chaîne, retournez-la directement
  //     return image;
  //   }

  //   // Si l'image est un tableau d'objets, recherchez l'objet correspondant à la langue sélectionnée
  //   const languageObject = image.find(item => Object.keys(item)[0] === selectedLanguage);

  //   // Retournez l'URL associée à la langue si l'objet est trouvé, sinon retournez une chaîne vide
  //   return languageObject ? languageObject[selectedLanguage] : '';
  // };

  render() {
    const popover = {
      position: "absolute",
      zIndex: "2",
    };
    const cover = {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    };
    const { form, composantsList, type_selected, default_language } =
      this.state;
    /* console.log(
      composantsList.filter(
        (c) => c.data.componentType === type_selected || type_selected === ""
      )
    );
    console.log(form.layout); */

    return (
      <div className="col-md-10 offset-md-1 main-content">
        <div className="row">
          <div className="form-group col-md-8">
            <label>Nom de la page web</label>&nbsp;&nbsp;
            <input
              type="text"
              name="name"
              className="form-control"
              value={form.name}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="col-md-2 text-right mb-6">
            <Button color="default" onClick={this.saveForm}>
              Sauvegarder
            </Button>
          </div>
          <div className="col-md-2 text-right mb-6">
            <Button color="default" onClick={this.toggle}>
              Aperçu
            </Button>
          </div>

          <div className="col-md-3" style={{ paddingBottom: "100px" }}>
            <div className="form-row">
              <h4>TYPE DE SUPPORT</h4>
              <select
                name="documentType"
                className="form-control browser-default custom-select"
                onChange={(e) =>
                  this.handleLayoutChange({ documentType: e.target.value })
                }
                value={form.layout.documentType}
              >
                <option value="website">Communication</option>
                <option value="reponse">Réponse</option>
              </select>
              <br />
              <br />
              <h4>LISTE DES COMPOSANTS</h4>
              <div className="form-group col-md-12">
                <label>Type</label>
                <select
                  name="type_selected"
                  className="form-control browser-default custom-select"
                  onChange={(e) => this.filterComponentsList(e.target.value)}
                  value={this.state.type_selected}
                >
                  <option value="">Tous les types</option>
                  <option value="display">Diffusion d’information</option>
                  <option value="request">Demande d’information invité</option>
                  <option value="logistic">Logistique</option>
                  <option value="moment">Moment</option>
                </select>
              </div>
            </div>

            <div className="components-list">
              <Container
                dragHandleSelector=".draggable-item"
                groupName="1"
                getChildPayload={(i) => composantsList[i]}
                onDrop={(e) => this.handleDrop(e, composantsList)}
              >
                {composantsList.map((component, key) =>
                  component.data.componentType === type_selected ||
                  type_selected === "" ? (
                    <Draggable key={`form-editor-component-${key}`}>
                      <div className="draggable-item">
                        <span
                          className="column-drag-handle"
                          style={{ float: "left", padding: "0 10px" }}
                        >
                          &#x2630;
                        </span>
                        {component.data.name}
                      </div>
                    </Draggable>
                  ) : null
                )}
              </Container>
            </div>
            <h4>CONFIGURATION DU THEME</h4>
            <label>Police de la page</label>
            <FontPicker
              apiKey="AIzaSyBj6oqwNae8Hduv910YfMHkkIrp3suXRyI"
              activeFont={form.layout.fontFamily}
              onChange={(nextFont) =>
                this.handleLayoutChange({
                  fontFamily: nextFont.family,
                })
              }
            />
            <label>Couleur de fond de la page</label>
            <button
              className="btn btn-block"
              style={{
                backgroundColor: form.layout.backgroundColor,
              }}
              onClick={(e) => this.handleClickColor(e, "backgroundColor")}
            />
            {this.state.displayColorPicker === "backgroundColor" ? (
              <div style={popover}>
                <div style={cover} onClick={this.handleCloseColor} />
                <ChromePicker
                  color={form.layout.backgroundColor}
                  onChangeComplete={(color) =>
                    this.handleLayoutChange({
                      backgroundColor: color.hex,
                    })
                  }
                />
              </div>
            ) : null}

            <label>Couleur principale</label>
            <button
              className="btn btn-block"
              style={{
                backgroundColor: form.layout.mainColor,
              }}
              onClick={(e) => this.handleClickColor(e, "mainColor")}
            />
            {this.state.displayColorPicker === "mainColor" ? (
              <div style={popover}>
                <div style={cover} onClick={this.handleCloseColor} />
                <ChromePicker
                  color={form.layout.mainColor}
                  onChangeComplete={(color) =>
                    this.handleLayoutChange({
                      mainColor: color.hex,
                    })
                  }
                />
              </div>
            ) : null}

            <label>Couleur secondaire</label>
            <button
              className="btn btn-block"
              style={{
                backgroundColor: form.layout.secondaryColor,
              }}
              onClick={(e) => this.handleClickColor(e, "secondaryColor")}
            />
            {this.state.displayColorPicker === "secondaryColor" ? (
              <div style={popover}>
                <div style={cover} onClick={this.handleCloseColor} />
                <ChromePicker
                  color={form.layout.secondaryColor}
                  onChangeComplete={(color) =>
                    this.handleLayoutChange({
                      secondaryColor: color.hex,
                    })
                  }
                />
              </div>
            ) : null}

            <label>Couleur de text bloc principal</label>
            <button
              className="btn btn-block"
              style={{
                backgroundColor: form.layout.textColorMain,
              }}
              onClick={(e) => this.handleClickColor(e, "textColorMain")}
            />
            {this.state.displayColorPicker === "textColorMain" ? (
              <div style={popover}>
                <div style={cover} onClick={this.handleCloseColor} />
                <ChromePicker
                  color={form.layout.textColorMain}
                  onChangeComplete={(color) =>
                    this.handleLayoutChange({
                      textColorMain: color.hex,
                    })
                  }
                />
              </div>
            ) : null}

            <label>Couleur de texte bloc secondaire</label>
            <button
              className="btn btn-block"
              style={{
                backgroundColor: form.layout.textColorSide,
              }}
              onClick={(e) => this.handleClickColor(e, "textColorSide")}
            />
            {this.state.displayColorPicker === "textColorSide" ? (
              <div style={popover}>
                <div style={cover} onClick={this.handleCloseColor} />
                <ChromePicker
                  color={form.layout.textColorSide}
                  onChangeComplete={(color) =>
                    this.handleLayoutChange({
                      textColorSide: color.hex,
                    })
                  }
                />
              </div>
            ) : null}

            <label>Couleur des titres du bloc principal</label>
            <button
              className="btn btn-block"
              style={{
                backgroundColor: form.layout.titleColorMain,
              }}
              onClick={(e) => this.handleClickColor(e, "titleColorMain")}
            />
            {this.state.displayColorPicker === "titleColorMain" ? (
              <div style={popover}>
                <div style={cover} onClick={this.handleCloseColor} />
                <ChromePicker
                  color={form.layout.titleColorMain}
                  onChangeComplete={(color) =>
                    this.handleLayoutChange({
                      titleColorMain: color.hex,
                    })
                  }
                />
              </div>
            ) : null}

            <label>Couleur des titres du bloc secondaire</label>
            <button
              className="btn btn-block"
              style={{
                backgroundColor: form.layout.titleColorSide,
              }}
              onClick={(e) => this.handleClickColor(e, "titleColorSide")}
            />
            {this.state.displayColorPicker === "titleColorSide" ? (
              <div style={popover}>
                <div style={cover} onClick={this.handleCloseColor} />
                <ChromePicker
                  color={form.layout.titleColorSide}
                  onChangeComplete={(color) =>
                    this.handleLayoutChange({
                      titleColorSide: color.hex,
                    })
                  }
                />
              </div>
            ) : null}

            <label>Couleur de fond titres du bloc principal</label>
            <button
              className="btn btn-block"
              style={{
                backgroundColor: form.layout.titleBackgroundColorMain,
              }}
              onClick={(e) =>
                this.handleClickColor(e, "titleBackgroundColorMain")
              }
            />
            {this.state.displayColorPicker === "titleBackgroundColorMain" ? (
              <div style={popover}>
                <div style={cover} onClick={this.handleCloseColor} />
                <ChromePicker
                  color={form.layout.titleBackgroundColorMain}
                  onChangeComplete={(color) =>
                    this.handleLayoutChange({
                      titleBackgroundColorMain: color.hex,
                    })
                  }
                />
              </div>
            ) : null}

            <label>Couleur de fond titres du bloc secondaire</label>
            <button
              className="btn btn-block"
              style={{
                backgroundColor: form.layout.titleBackgroundColorSide,
              }}
              onClick={(e) =>
                this.handleClickColor(e, "titleBackgroundColorSide")
              }
            />
            {this.state.displayColorPicker === "titleBackgroundColorSide" ? (
              <div style={popover}>
                <div style={cover} onClick={this.handleCloseColor} />
                <ChromePicker
                  color={form.layout.titleBackgroundColorSide}
                  onChangeComplete={(color) =>
                    this.handleLayoutChange({
                      titleBackgroundColorSide: color.hex,
                    })
                  }
                />
              </div>
            ) : null}

            <label>Couleur de fond du bloc principal</label>
            <button
              className="btn btn-block"
              style={{
                backgroundColor: form.layout.backgroundColorMain,
              }}
              onClick={(e) => this.handleClickColor(e, "backgroundColorMain")}
            />
            {this.state.displayColorPicker === "backgroundColorMain" ? (
              <div style={popover}>
                <div style={cover} onClick={this.handleCloseColor} />
                <ChromePicker
                  color={form.layout.backgroundColorMain}
                  onChangeComplete={(color) =>
                    this.handleLayoutChange({
                      backgroundColorMain: color.hex,
                    })
                  }
                />
              </div>
            ) : null}

            <label>Couleur de fond des blocs secondaire</label>
            <button
              className="btn btn-block"
              style={{
                backgroundColor: form.layout.backgroundColorSide,
              }}
              onClick={(e) => this.handleClickColor(e, "backgroundColorSide")}
            />
            {this.state.displayColorPicker === "backgroundColorSide" ? (
              <div style={popover}>
                <div style={cover} onClick={this.handleCloseColor} />
                <ChromePicker
                  color={form.layout.backgroundColorSide}
                  onChangeComplete={(color) =>
                    this.handleLayoutChange({
                      backgroundColorSide: color.hex,
                    })
                  }
                />
              </div>
            ) : null}

            <h4>NAVIGATION</h4>
            <label>Couleur de fond</label>
            <button
              className="btn btn-block"
              style={{
                backgroundColor: form.layout.menu.style.backgroundColor,
              }}
              onClick={(e) => this.handleClickColor(e, "menuBackground")}
            />
            {this.state.displayColorPicker === "menuBackground" ? (
              <div style={popover}>
                <div style={cover} onClick={this.handleCloseColor} />
                <ChromePicker
                  color={form.layout.menu.style.backgroundColor}
                  onChangeComplete={(color) =>
                    this.handleLayoutChange({
                      menu: {
                        ...form.layout.menu,
                        style: {
                          ...form.layout.menu.style,
                          backgroundColor: color.hex,
                        },
                      },
                    })
                  }
                />
              </div>
            ) : null}
            <label>Couleur du texte</label>
            <button
              className="btn btn-block"
              style={{
                backgroundColor: form.layout.menu.style.color,
              }}
              onClick={(e) => this.handleClickColor(e, "menuColor")}
            />
            {this.state.displayColorPicker === "menuColor" ? (
              <div style={popover}>
                <div style={cover} onClick={this.handleCloseColor} />
                <ChromePicker
                  color={form.layout.menu.style.color}
                  onChangeComplete={(color) =>
                    this.handleLayoutChange({
                      menu: {
                        ...form.layout.menu,
                        style: {
                          ...form.layout.menu.style,
                          color: color.hex,
                        },
                      },
                    })
                  }
                />
              </div>
            ) : null}
          </div>
          <div className="col-md-9 reponse-container">
            <div
              style={{
                fontFamily: form.layout.fontFamily,
                background: `url(https://res.cloudinary.com/kanguroo-event/image/upload/w_960/${form.layout.backgroundImage}) no-repeat`,
                backgroundSize: "cover",
              }}
            >
              <div className="col-md-12 zone">
                <ImgEditor
                  imgUrl={
                    form.layout.backgroundImage
                      ? form.layout.backgroundImage
                      : ""
                  }
                  onUpload={this.handleLayoutChange}
                  name="backgroundImage"
                  label={"Modifier l'image de fond"}
                />
              </div>

              <div className="col-md-12 zone">
                <MenuEditor
                  menu={form.layout.menu}
                  onSaveMenu={this.handleLayoutChange}
                  composantsList={this.props.composantsList}
                />
              </div>
              <div className="row">
                <div
                  // style={{
                  //   backgroundImage: `url(https://res.cloudinary.com/kanguroo-event/image/upload/w_960/${this.getBackgroundImageUrl('headerImage')})`,
                  // }}
                  className="col-md-8 offset-md-2 zone"
                >
                  <ImgEditor
                    imgUrl={
                      form.layout.headerImage ? form.layout.headerImage : ""
                    }
                    onUpload={this.handleLayoutChange}
                    name="headerImage"
                    label={"Modifier le header"}
                    default_language={default_language}
                  />
                </div>
              </div>
              <div className="col-md-12">
                {form.layout.gabarit === "gabarit1" ? (
                  <Gabarit1
                    composantsList={this.props.composantsList}
                    itemsSide={form.layout.itemsSide}
                    itemsMain={form.layout.itemsMain}
                    onValidate={this.handleLayoutChange}
                  />
                ) : (
                  <Gabarit2
                    composantsList={this.props.composantsList}
                    itemsSide={form.layout.itemsSide}
                    itemsMain={form.layout.itemsMain}
                    onValidate={this.handleLayoutChange}
                  />
                )}
              </div>

              <div className="col-md-12 zone">
                <MapEditor
                  mapIframe={form.layout.mapIframe}
                  onUpload={this.handleLayoutChange}
                />
              </div>
              <div
                // style={{
                //   backgroundImage: `url(https://res.cloudinary.com/kanguroo-event/image/upload/w_960/${this.getBackgroundImageUrl('footerImage')})`,
                // }}
                className="col-md-8 offset-md-2 zone"
              >
                <ImgEditor
                  imgUrl={
                    form.layout.footerImage ? form.layout.footerImage : ""
                  }
                  onUpload={this.handleLayoutChange}
                  name="footerImage"
                  label={"Modifier le footer"}
                  default_language={default_language}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormEditor;
