import React from "react";
import { Link } from "react-router-dom";
import { MDBInput, Button } from "mdbreact";
import ApiService from "../../../services/ApiService";
import Loader from "../../../common/Loader";
import { ExportContacts } from "./ExportContacts";
import ImportContacts from "./ImportContacts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faUserLock } from "@fortawesome/free-solid-svg-icons";
// import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Creatable from "react-select/lib/Creatable";
import { MDBIcon } from "mdbreact";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import ImportContactTableMain from "./ImportContactTableMain";
import Pagination from "../../../common/Pagination";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { languagesList } from "../../../common/Translations";
class ContactsList extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      currentPage: 0,
      numberPerPage: 50,
      contactsList: [],
      contactsFiltered: [],
      contactsChecked: [],
      isLoading: true,
      nameFilter: "",
      langueFilter: "",
      companyFilter: "",
      emailFilter: "",
      totalPages: 0,
      action: "",
      active: true,
      related_to: "",
      tags: [],
      guests: [],
      tagsFilter: [],
      typePage: { page: "default", subPage: "" },
      open: false,
      tagOptions: [],
      activeContacts: "actifContact",
    };
    this.deleteContact = this.deleteContact.bind(this);
    this.deleteContacts = this.deleteContacts.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.checkOne = this.checkOne.bind(this);
    this.filterContacts = this.filterContacts.bind(this);
    this.filter = this.filter.bind(this);
    this.loadData = this.loadData.bind(this);
    this.changePage = this.changePage.bind(this);
    this.filterByTag = this.filterByTag.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTag = this.handleChangeTag.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.modifyContacts = this.modifyContacts.bind(this);
    this.navigate = this.navigate.bind(this);
    this.setTypePage = this.setTypePage.bind(this);
    this.closeModalView = this.closeModalView.bind(this);
    this.handleAcceptDelete = this.handleAcceptDelete.bind(this);
    this.openModalView = this.openModalView.bind(this);
    this.filterByLangages = this.filterByLangages.bind(this);
  }

  async modifyContacts() {
    const self = this;
    const { active, related_to, tags, contactsChecked } = this.state;

    const tags_ids = tags.map((tag) => tag.value);
    const contacts_ids = contactsChecked.map((contact) => contact.id);

    await ApiService.request(
      { active, related_to, tags_ids, contacts_ids },
      "contacts/update/bygroup",
      "put"
    )
      .then(function (data) {
        self.props.updateContacts(data);

        toast.success("Contacts sauvegardés", {
          autoClose: 3000,
        });
      })
      .catch(function () {
        toast.error("Erreur lors de l'enregistrement des contacts", {
          autoClose: 3000,
        });
      });
  }

  handleSearch(e) {
    if (e.length < 3) {
      this.setState({ contactsOptions: [] });
    } else {
      const contactsOptions = [];
      this.state.contactsList.forEach((contact) => {
        if (
          contact.firstname.toLowerCase().includes(e.toLowerCase()) ||
          contact.lastname.toLowerCase().includes(e.toLowerCase())
        ) {
          contactsOptions.push({
            label: contact.lastname + " " + contact.firstname,

            value: contact.id,
          });
        }
      });
      this.setState({
        contactsOptions,
      });
    }
  }
  setTypePage(typePage) {
    this.setState({ typePage });
  }
  handleChange(event) {
    const self = this;
    if ((event && event.value) || !event) {
      this.setState({
        related_to: event ? event.value : "",
      });
    } else {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name === "action") {
        switch (event.target.value) {
          case "delete":
            if (
              this.state.contactsChecked.length > 0 &&
              window.confirm("Etes vous sûr de valider cette action ?")
            ) {
              this.state.contactsChecked.map(
                async (contact) =>
                  await ApiService.awaitRequest(
                    {},
                    "rgpd/contact/anonymize/" + contact.id,
                    "delete"
                  ).then(function () {
                    const newContacts = self.props.contacts.filter(
                      (item) => contact.id !== item.id
                    );
                    self.setState({
                      contactsList: [...newContacts],
                      contactsFiltered: self.state.contactsFiltered.filter(
                        (item) => contact.id !== item.id
                      ),
                    });
                    self.props.updateContacts([...newContacts]);
                  })
              );
              this.loadData();
            }
            break;
          default:
            break;
        }
      }
    }
  }

  async handleChangeTag(tags) {
    const tagToAdd = tags.find((tag) => tag.__isNew__);

    const self = this;

    if (tagToAdd) {
      const currentsTags = tags.filter((tag) => !tag.__isNew__);

      await ApiService.request(
        { tag_name: tagToAdd.label },
        "tags/",
        "post"
      ).then(function (data) {
        currentsTags.push({
          value: data.id,
          label: data.tag_name,
        });

        self.setState({ tags: currentsTags });
      });
    } else this.setState({ tags });
  }

  filter() {
    const {
      contactsList,
      nameFilter,
      companyFilter,
      emailFilter,
      tagsFilter,
      numberPerPage,
      langueFilter,
    } = this.state;
    let contactsFiltered = contactsList;

    if (nameFilter.length > 0) {
      contactsFiltered = contactsFiltered.filter((contact) => {
        return contact.firstname
          .toLowerCase()
          .includes(nameFilter.toLowerCase()) ||
          contact.lastname.toLowerCase().includes(nameFilter.toLowerCase())
          ? contact
          : null;
      });
    }

    if (companyFilter.length > 0) {
      contactsFiltered = contactsFiltered.filter((contact) => {
        return contact.company
          ? contact.company.name
              .toLowerCase()
              .includes(companyFilter.toLowerCase())
          : null;
      });
    }

    if (emailFilter.length > 0) {
      contactsFiltered = contactsFiltered.filter((contact) => {
        if (contact.mail) {
          let res = contact.mail
            .toLowerCase()
            .includes(emailFilter.toLowerCase());
          if (!res && contact.mail_second)
            res = contact.mail_second
              .toLowerCase()
              .includes(emailFilter.toLowerCase());
          return res;
        }
        return null;
      });
    }

    if (tagsFilter.length > 0) {
      contactsFiltered = contactsFiltered.filter((contact) => {
        return contact.tags.some((tag) =>
          tagsFilter.find((tagFilter) => tagFilter.value === tag.id)
        );
      });
    }
    if (langueFilter) {
      contactsFiltered = contactsFiltered.filter((contact) => {
        return contact.id &&
          this.props.contacts.find(
            (item) =>
              item.id === contact.id && item.languages === langueFilter.code
          )
          ? contact
          : null;
      });
    }
    this.setState({
      contactsFiltered,
      totalPages: Math.ceil(contactsFiltered.length / numberPerPage),
      currentPage: 0,
    });
  }

  checkAll(event) {
    this.setState({
      contactsChecked: event.target.checked ? this.state.contactsFiltered : [],
    });
  }

  checkOne(event, newContact) {
    if (event.target.checked) {
      const contactsChecked = this.state.contactsChecked;
      contactsChecked.push(newContact);
      this.setState({
        contactsChecked,
      });
    } else {
      this.setState({
        contactsChecked: this.state.contactsChecked.filter(
          (contact) => contact.id !== newContact.id
        ),
      });
    }
  }

  filterContacts(event) {
    const self = this;
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        self.filter();
      }
    );
  }

  async loadData() {
    this._isMounted && this.setState({ isLoading: true });
    const tagOptions = this.state.tagsList.map((tag) => ({
      label: tag.tag_name,
      value: tag.id,
      color: "#000000",
    }));

    const self = this;
    await ApiService.request(
      {},
      this.state.activeContacts !== "actifContact"
        ? "contacts/list/false"
        : "contacts/list/true",
      "get"
    ).then(function (contacts) {
      const contactsList = contacts;

      self.setState({
        contactsList,
        contactsFiltered: contactsList,
        totalPages: Math.ceil(contactsList.length / self.state.numberPerPage),
      });
    });
    this.filter();
    this._isMounted &&
      this.setState({
        contactsOptions: [],
        contactsChecked: [],
        tagOptions,
        isLoading: false,
      });
  }

  click(id) {
    this.setState({ activeContacts: id }, async () => {
      this.loadData();
    });
  }

  async componentDidMount() {
    const self = this;
    this._isMounted = true;

    await ApiService.request(this.state, "tags", "get").then(function (data) {
      self._isMounted && self.setState({ tagsList: data });
    });
    this._isMounted && this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  async deleteContacts(ids) {
    const self = this;
    try {
      await ApiService.request(
        {
          ids: ids,
          report_recipient: JSON.parse(localStorage.getItem("user")).email,
        },
        "contacts/groups",
        "post"
      ).then(function () {
        toast.warn(
          `Contacts en cours de suppression, vous recevrez un email lorsque ce sera terminé.`,
          { autoClose: 2500 }
        );
        self.loadData();
      });
    } catch (error) {
      toast.warn("Erreur lors de la suppression de masse des contacts.", {
        autoClose: 2500,
      });
    }
  }
  async deleteContact(id) {
    const self = this;

    this.setState({ isLoading: true }, async () => {
      try {
        await ApiService.request({}, "contacts/" + id, "delete").then(
          function () {
            toast.success("Contact Supprimé", { autoClose: 1500 });
            self.props.updateContacts(
              self.state.contactsList.filter((contact) => contact.id !== id)
            );
            self.loadData();
            self.loadData();
          }
        );
      } catch (error) {
        let contactError = self.state.contactsList.filter(
          (contact) => contact.id === id
        );
        toast.warn(
          "Impossible de supprimer le contact" +
            (contactError === undefined
              ? " : " + contactError.lastname + contactError.firstname
              : "."),
          { autoClose: 1500 }
        );
      }
    });
  }

  filterByTag(event) {
    const self = this;
    this.setState(
      {
        tagsFilter: event ? event : "",
      },
      () => {
        self.filter();
      }
    );
  }
  filterByLangages(event) {
    const self = this;
    this.setState(
      {
        langueFilter: event ? event : "",
      },
      () => {
        self.filter();
      }
    );
  }
  changePage(page) {
    this.setState({ currentPage: page });
  }

  navigate = (e, typePage) => {
    e.preventDefault();
    this.setState({ typePage });
  };

  openModalView = () => {
    this.setState({ open: true });
  };

  handleAcceptDelete = () => {
    this.closeModalView();
    if (
      this.state.contactsChecked.length > 0 &&
      this.state.contactsChecked.length <= 100
    ) {
      this.state.contactsChecked.map(async (contact) =>
        this.deleteContact(contact.id)
      );
    } else {
      var ids = this.state.contactsChecked.map((contact) => contact.id);
      this.deleteContacts(ids);
    }
  };

  closeModalView = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      contactsList,
      contactsChecked,
      isLoading,
      nameFilter,
      companyFilter,
      emailFilter,
      tagOptions,
      tagsFilter,
      tags,
      totalPages,
      currentPage,
      contactsFiltered,
      numberPerPage,
      action,
      active,
      contactsOptions,
      langueFilter,
      related_to,
      activeContacts,
      typePage,
    } = this.state;
    if (isLoading) {
      return <Loader />;
    }
    //const { contacts } = this.props;
    return typePage.page === "import" ? (
      <ImportContactTableMain
        navigate={this.navigate}
        setTypePage={this.setTypePage}
        typePage={this.state.typePage}
      />
    ) : (
      <div>
        <div className="main-content">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className={"stats-and-actions"}>
                <div className="row">
                  <div className={"col-6"}>
                    <div className={"metrics"}>
                      <div className={"white metric-panel"} id="contact_total">
                        <div className={"metric-panel-title"}>
                          NOMBRE DE CONTACTS
                        </div>
                        <div className={"metric-panel-value"}>
                          {contactsList.length}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"col-6"}>
                    <div className={"general-actions"}>
                      <ExportContacts {...this.props} contacts={contactsFiltered} />
                      <ImportContacts
                        {...this.props}
                        navigate={this.navigate}
                      />
                      <Link
                        className="btn pink darken-1 float-right white-text button-container "
                        to="/admin/contacts/edit"
                      >
                        Nouveau contact
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* End of stats-and-actions */}

              <div className={"white filters"}>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li
                    className={
                      activeContacts === "actifContact"
                        ? "nav-item-active"
                        : "nav-item simple"
                    }
                    role="presentation"
                  >
                    <button
                      className={`nav-link ${
                        activeContacts === "actifContact" ? "active" : ""
                      }`}
                      id="actifContact"
                      data-bs-toggle="tab"
                      data-bs-target="#actifContact"
                      type="button"
                      role="tab"
                      aria-controls="actifContact"
                      aria-selected={
                        activeContacts === "actifContact" ? true : false
                      }
                      onClick={(e) => this.click(e.target.id)}
                    >
                      Actif
                    </button>
                  </li>
                  <li
                    className={
                      activeContacts === "inactifContact"
                        ? "nav-item-active"
                        : "nav-item simple"
                    }
                    role="presentation"
                  >
                    <button
                      className={`nav-link ${
                        activeContacts === "inactifContact" ? "active" : ""
                      }`}
                      id="inactifContact"
                      data-bs-toggle="tab"
                      data-bs-target="#inactifContact"
                      type="button"
                      role="tab"
                      aria-controls="inactifContact"
                      aria-selected={
                        activeContacts === "inactifContact" ? true : false
                      }
                      onClick={(e) => this.click(e.target.id)}
                    >
                      Inactif
                    </button>
                  </li>
                </ul>
                <div className="row">
                  <div className="col-lg-3">
                    <MDBInput
                      type="text"
                      value={nameFilter}
                      name="nameFilter"
                      label={"Rechercher par nom"}
                      onChange={this.filterContacts}
                    />
                  </div>
                  <div className="col-lg-3">
                    <MDBInput
                      type="text"
                      value={companyFilter}
                      name="companyFilter"
                      label={"Rechercher par société"}
                      onChange={this.filterContacts}
                    />
                  </div>
                  <div className="col-lg-3">
                    <MDBInput
                      type="text"
                      value={emailFilter}
                      name="emailFilter"
                      label={"Rechercher par email"}
                      onChange={this.filterContacts}
                    />
                  </div>
                  <div className="col-lg-3">
                    <div className="md-form">
                      <Select
                        placeholder={"Rechercher par tags"}
                        onChange={this.filterByTag}
                        options={tagOptions}
                        value={tagsFilter}
                        isMulti={true}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="md-form">
                      <Select
                        placeholder={"Filtrer par langue"}
                        onChange={this.filterByLangages}
                        options={languagesList}
                        //  className="browser-default custom-select"
                        isMulti={false}
                        value={langueFilter}
                      >
                        {/*  <option value="">Filtrer par langue</option>

                        {languagesList.map((option, i) => (
                          <option key={i} value={option.code}>
                            {option.label}
                          </option>
                        ))} */}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              {/* End of filters */}

              <div className={"table-container"}>
                <table className="table table-sm table-striped">
                  <thead>
                    <tr>
                      <th scope="col">
                        <input
                          type="checkbox"
                          style={{
                            opacity: 1,
                            pointerEvents: "auto",
                            position: "relative",
                          }}
                          onChange={this.checkAll}
                        />
                      </th>
                      <th scope="col">Nom</th>
                      <th scope="col">Langue</th>
                      <th scope="col">Société</th>
                      <th scope="col">Email</th>
                      <th scope="col">Tags</th>
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {contactsFiltered.map((contact, i) => {
                      return i < numberPerPage * (currentPage + 1) &&
                        i >= numberPerPage * currentPage ? (
                        <tr key={i}>
                          <td>
                            <input
                              type="checkbox"
                              checked={
                                contactsChecked.filter(
                                  (contactsChecked) =>
                                    contactsChecked.id === contact.id
                                ).length > 0
                              }
                              onChange={(e) => this.checkOne(e, contact)}
                              style={{ opacity: 1, pointerEvents: "auto" }}
                            />
                          </td>
                          <td>{contact.firstname + " " + contact.lastname}</td>
                          <td>{contact.languages}</td>
                          <td>
                            {" "}
                            {contact.company ? contact.company.name : null}
                          </td>
                          <td>
                            {contact.mail_second ? (
                              <div style={{ width: "fit-content" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "baseline",
                                  }}
                                >
                                  <p data-for="contact-email-tooltip" data-tip>
                                    {contact.mail}
                                  </p>
                                  <MDBIcon
                                    style={{ marginLeft: "1rem" }}
                                    icon="angle-down"
                                  />
                                </div>
                                <ReactTooltip
                                  id="contact-email-tooltip"
                                  place="bottom"
                                  effect="solid"
                                >
                                  {contact.mail_second}
                                </ReactTooltip>
                              </div>
                            ) : (
                              contact.mail
                            )}
                          </td>
                          <td>
                            {contact.tags.map((tag) => tag.tag_name + ", ")}
                          </td>
                          <td>
                            {" "}
                            <Link to={"/admin/contacts/edit/" + contact.id}>
                              <FontAwesomeIcon icon={faEdit} />
                            </Link>
                          </td>
                          <td>
                            {" "}
                            <Link to={`/account/admin/${contact.id}/team`}>
                              <FontAwesomeIcon icon={faUserLock} />
                            </Link>
                          </td>
                        </tr>
                      ) : null;
                    })}
                  </tbody>
                </table>
              </div>
              {/* End of Table */}

              <div
                className="row"
                style={{ listStyleType: "none", marginBottom: "270px" }}
              >
                <div className="row  pl-4 pr-4  pagination">
                  <Pagination
                    changePage={this.changePage}
                    currentPage={currentPage}
                    totalPages={totalPages}
                  />
                </div>
                {/* End of pagination */}
              </div>
            </div>
          </div>
          <div>
            <Dialog
              sx={{ mx: "auto", maxWidth: "md" }}
              open={this.state.open}
              onClose={this.closeModalView}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">
                {"Attention"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Êtes vous sûr de valider cette action ? La suppression de
                  contact est irréversible, nous vous recommandons d'exporter
                  vos données avant de le faire. <br /> Nombre supprimé
                  {this.state.contactsChecked.length === 1
                    ? " : 1 contact"
                    : "s : " + this.state.contactsChecked.length + " contacts"}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={this.handleAcceptDelete}>
                  Valider
                </Button>
                <Button onClick={this.closeModalView} autoFocus>
                  Annuler
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          {contactsChecked.length > 0 ? (
            <div className="row mr-0 ml-0 actions-container actions-container-fixed">
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    {contactsChecked.length} contact
                    {contactsChecked.length > 1 ? "s" : null}
                    &nbsp;sélectionné
                    {contactsChecked.length > 1 ? "s" : null}
                  </label>
                  <select
                    onChange={this.handleChange}
                    name="action"
                    className="col-sm-12 browser-default custom-select"
                    value={action}
                    required
                  >
                    <option value="" disabled>
                      Sélectionner une action groupée
                    </option>
                    <option value="modify">Modifier</option>
                    <option value="delete">Supprimer</option>
                  </select>
                </div>
              </div>
              {/* End of col-md-4 */}
              {action === "modify" ? (
                <>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Mettre à jour les tags</label>
                      <Creatable
                        color="black"
                        menuPlacement="top"
                        placeholder={"Sélectionnez ou créez les tags"}
                        isMulti
                        value={tags}
                        onChange={this.handleChangeTag}
                        options={tagOptions}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Contact actif</label>
                      <select
                        name="active"
                        className="form-control browser-default custom-select"
                        value={active}
                        onChange={this.handleChange}
                      >
                        <option value={true}>Oui</option>
                        <option value={false}>Non</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label>Affecté à</label>
                      <Select
                        required
                        isClearable={true}
                        defaultValue={related_to}
                        menuPlacement="top"
                        options={contactsOptions}
                        placeholder={"Rechercher un contact"}
                        onInputChange={this.handleSearch}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="form-group">
                      <div className="form-group">
                        <Button
                          type="submit"
                          onClick={this.modifyContacts}
                          className="btn  pink darken-1"
                        >
                          Valider
                        </Button>
                        <Button
                          className="btn  pink darken-1"
                          onClick={() => this.setState({ contactsChecked: [] })}
                        >
                          Fermer
                        </Button>
                      </div>
                    </div>
                  </div>{" "}
                  {/* End of actions-container */}
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ContactsList;
