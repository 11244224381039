import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import Multilingual from "../../../common/Multilingual";
import FileUpload from "../../../common/FileUpload";
import { MDBInput } from "mdbreact";
import ApiService from "../../../services/ApiService";
import { Button } from "mdbreact";
import { Image, CloudinaryContext } from "cloudinary-react";
import DeleteIcon from "@mui/icons-material/Delete";
import EventDeletionDialog from "./EventDeletionDialog";
import { ToastContainer, toast } from "react-toastify";
import { IconButton } from "@mui/material";
import { Checkbox, FormControlLabel } from "@mui/material";
import moment from "moment";
import LanguageContainer from "./LanguageContainer";
registerLocale("fr", fr);

class EventConfig extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlestart_dateChange = this.handlestart_dateChange.bind(this);
    this.handleend_dateChange = this.handleend_dateChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleLang = this.handleLang.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.removeIcon = this.removeIcon.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.updateEventLang = this.updateEventLang.bind(this);
    this.addDelegataireDateLimit = this.addDelegataireDateLimit.bind(this);
    this.deleteDelegataireDateLimit =
      this.deleteDelegataireDateLimit.bind(this);
    this.loadData = this.loadData.bind(this);
    this.saveDelegataire = this.saveDelegataire.bind(this);

    this.state = {
      list: [],
      item: "",
      event: { ...this.props.event },
      // mailData: {
      //   exist: true,
      //   available: [],
      //   error: false,
      //   message: "",
      //   sender_domain: "",
      // },
      delegatairesList: [],
    };
    // this.checkSenderDomainEmail = this.checkSenderDomainEmail.bind(this);
  }

  componentDidMount() {
    // this.checkSenderDomainEmail(this.state.event.mail_sender_address);
    this.loadData();
  }

  async loadData() {
    const delegatairesList = await ApiService.awaitRequest(
      {},
      "delegataires/byevenement/" + this.props.match.params.eventid,
      "get"
    );

    if (delegatairesList) {
      const newDelegatairesList = delegatairesList.map((delegataire) => {
        const newDelegataire =
          delegataire.selectedDelegataireDateLimit !== ""
            ? {
              ...delegataire,
              selectedDelegataireDateLimit: JSON.parse(
                delegataire.selectedDelegataireDateLimit
              ),
            }
            : delegataire;
        return {
          ...newDelegataire,
        };
      });
      this.setState({ delegatairesList: newDelegatairesList });
    }
  }

  onFileUpload(id) {
    this.setState({
      event: {
        ...this.state.event,
        data: { ...this.state.event.data, logo: id },
      },
    });
  }

  removeIcon() {
    this.setState({
      event: {
        ...this.state.event,
        data: { ...this.state.event.data, logo: null },
      },
    });
  }

  async deleteEvent() {
    const self = this;
    const id = this.state.event.id;

    try {
      await ApiService.request({}, "evenements/" + id, "delete").then(() => {
        toast.success("Évènement Supprimé", {
          autoClose: 3000,
        });
        self.props.history.push("/admin/events/");
      });
    } catch (error) {
      toast.warn("Impossible de supprimer cet évènement", { autoClose: 3000 });
    }
  }

  saveDelegataire(data) {
    let method = "put";
    let id = data.id;

    var newData = {
      ...data,
      selectedDelegataireDateLimit: JSON.stringify(
        data.selectedDelegataireDateLimit
      ),
    };

    //console.log(newData);

    ApiService.request(
      {
        ...newData,
        evenement_id: this.state.event.id,
      },
      "delegataires/" + id,
      method
    )
      .then(function () {
        //toast.success("Délégataire sauvegardé");
      })
      .catch(function () {
        toast.error(
          "Erreur lors de l'enregistrement des dates des délégataires"
        );
      });
  }

  handleDateChange(date, label, delegataireKey) {

    const { end_date } = this.state.event;

    if (
      label === "registerEndDate" &&
      this.state.delegatairesList.some(
        (e) =>
          e.selectedDelegataireDateLimit &&
          e.selectedDelegataireDateLimit.key === label
      )
    ) {
      const newDelegatairesList = this.state.delegatairesList.map((e) =>
        e.selectedDelegataireDateLimit &&
          e.selectedDelegataireDateLimit.key === label
          ? {
            ...e,
            selectedDelegataireDateLimit: {
              key: e.selectedDelegataireDateLimit.key,
              date_limit: date,
            },
          }
          : e
      );

      this.setState({ delegatairesList: newDelegatairesList });
    }

    if (!isNaN(delegataireKey)) {
      if (new Date(date).getTime() > new Date(end_date).getTime()) {
        alert("La date limite sélectionnée ne peut pas dépasser celle de fin de l'événement.");
      }
      else {
        if (
          this.state.delegatairesList.some(
            (e) =>
              e.selectedDelegataireDateLimit &&
              e.selectedDelegataireDateLimit.key === delegataireKey
          )
        ) {
          const newDelegatairesList = this.state.delegatairesList.map((e) =>
            e.selectedDelegataireDateLimit &&
              e.selectedDelegataireDateLimit.key === delegataireKey
              ? {
                ...e,
                selectedDelegataireDateLimit: {
                  key: e.selectedDelegataireDateLimit.key,
                  date_limit: date,
                },
              }
              : e
          );
          this.setState({ delegatairesList: newDelegatairesList });
        }
        this.setState({
          event: {
            ...this.state.event,
            data: {
              ...this.state.event.data,
              [label]: this.state.event.data.delegataireLimitDate.map((e, k) =>
                e.key === delegataireKey
                  ? { key: delegataireKey, date_limit: date }
                  : e
              ),
            },
          },
        });
      }
    } else {
      this.setState({
        event: {
          ...this.state.event,
          data: {
            ...this.state.event.data,
            [label]: date,
          },
        },
      });
    }
  }

  addDelegataireDateLimit(label) {
    var newTab = this.state.event.data.delegataireLimitDate;
    const highestKey = this.state.event.data.delegataireLimitDate.reduce(
      (acc, curr) => {
        return curr.key > acc ? curr.key : acc;
      },
      0
    );
    newTab.push({
      key: highestKey + 1,
      date_limit: new Date(),
    });
    //console.log(newTab);
    this.setState({
      event: {
        ...this.state.event,
        data: {
          ...this.state.event.data,
          [label]: newTab,
        },
      },
    });
  }

  deleteDelegataireDateLimit(label, index) {
    const keyBeingDeleted = this.state.event.data.delegataireLimitDate.filter(
      (e, k) => k === index
    )[0].key;

    if (
      this.state.delegatairesList.some(
        (e) =>
          e.selectedDelegataireDateLimit &&
          e.selectedDelegataireDateLimit.key === keyBeingDeleted
      )
    ) {
      toast.error(
        "Impossible de supprimer cette date car elle est utilisée par un délégataire.",
        { autoClose: 3000 }
      );
    } else {
      var newTab = this.state.event.data.delegataireLimitDate.filter(
        (e, k) => k !== index
      );
      //console.log(newTab);
      this.setState({
        event: {
          ...this.state.event,
          data: {
            ...this.state.event.data,
            [label]: newTab,
          },
        },
      });
    }
  }
  handleLang(newLangSet, label) {
    this.setState({
      event: {
        ...this.state.event,
        data: {
          ...this.state.event.data,
          [label]: newLangSet,
        },
      },
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.state.delegatairesList.map((data) => this.saveDelegataire(data));
    if (this.state.event.id) this.props.onSave({ ...this.state.event });
    else this.props.onSave({ ...this.state.event, component_ids: [] });

    e.preventDefault();
  }

  handleDataChange(event) {
    if (event.target.name === "checkIn" && event.target.value === "false") {
      this.setState({
        event: {
          ...this.state.event,
          data: {
            ...this.state.event.data,
            [event.target.name]: event.target.value,
            qrCode: "false",
          },
        },
      });
    } else {
      // let updatedValue = event.target.value;
      // if (updatedValue === "true" || updatedValue === "false") {
      //   updatedValue = JSON.parse(updatedValue);
      // }
      this.setState({
        event: {
          ...this.state.event,
          data: {
            ...this.state.event.data,
            [event.target.name]: event.target.value,
          },
        },
      });
    }
  }

  handleChange(event) {
    // if (event.target.name === "mail_sender_address") {
    //   this.checkSenderDomainEmail(event.target.value);
    // }
    this.setState({
      event: {
        ...this.state.event,
        [event.target.name]: event.target.value,
      },
    });
  }

  handlestart_dateChange(date) {
    let newDate = moment.utc(date);
    let start_date = moment(newDate).local();
    start_date = start_date.format();

    // let start_date = new Date(date);
    let end_date = this.state.event.end_date
      ? this.state.event.end_date
      : start_date;

    if (moment(start_date).isAfter(end_date)) {
      end_date = start_date;
    }

    this.setState({
      event: { ...this.state.event, start_date, end_date },
    });
  }

  handleend_dateChange(date) {
    let newDate = moment.utc(date);
    let end_date = moment(newDate).local();
    end_date = end_date.format();

    this.setState({
      event: {
        ...this.state.event,
        end_date,
      },
    });
  }

  updateEventLang(lang) {
    this.setState({
      event: {
        ...this.state.event,
        default_language: lang,
      },
    });
  }

  // async checkSenderDomainEmail(value) {
  //   const self = this;
  //   await ApiService.request(
  //     {
  //       senderEmail: value,
  //     },
  //     "campaigns/check/domain",
  //     "post"
  //   ).then((mailData) => {
  //     if (mailData.error) {
  //       console.log(mailData);
  //     } else if (!mailData.exist) {
  //       //toast.error("Le domaine de l'adresse email n'est pas valable");
  //     }
  //     self.setState({ mailData });
  //   });
  // }

  render() {
    const {
      name,
      url,
      audience,
      free,
      logo,
      checkIn,
      qrCode,
      reponseLabel,
      programLabel,
      passportLabel,
      LocationName,
      zipCode,
      address1,
      address2,
      country,
      city,
      marketingBeginDate,
      preRegisterDate,
      modificationLimitDate,
      registerDate,
      registerEndDate,
      marketingEndDate,
      allowModification,
      maintenanceMode,
      delegataireLimitDate,
    } = this.state.event.data;

    const {
      start_date,
      end_date,
      mail_sender_address,
      mail_sender_name,
      children_entity_id,
      delegataire_archived
    } = this.state.event;

    // const { mailData } = this.state;

    const entitiesOptions = this.props.entities.map((entity) => ({
      label: entity.data.name,
      value: entity.id,
    }));
    console.log(reponseLabel);
    return (
      <div className="row">
        <div className="col-md-10 offset-md-1">
          {this.props.subMenu}
          <form onSubmit={this.handleSubmit} className="form">
            <div className="form-row">
              <div className="form-group col-md-12">
                {this.props.event && isNaN(this.props.event.id) && (
                  <LanguageContainer
                    {...this.props}
                    event={this.props.event}
                    eventComponents={this.props.eventComponents}
                    updateEventLang={this.updateEventLang}
                    name={"createEvent"}
                  />
                )}
              </div>
              <div className="form-group col-md-6">
                <label>Entité de l'événement *</label>
                <select
                  required
                  onChange={this.handleChange}
                  name="children_entity_id"
                  className="form-control browser-default custom-select"
                  value={children_entity_id || ""}
                >
                  <option key="event-config-entity" value="">
                    Sélectionnez l'entité
                  </option>
                  {entitiesOptions.map((option, index) => (
                    <option
                      key={`event-config-entity-${index}`}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group col-md-6">
                <MDBInput
                  label="Nom de l'événement (*)"
                  name="name"
                  value={name || ""}
                  onChange={this.handleDataChange}
                  required
                />
              </div>
              <div className="form-group col-md-8">
                <label>Logo</label>
                <div className={"row"}>
                  <div className={"col-md-3 col-sm-12"}>
                    {logo ? (
                      <CloudinaryContext cloudName="kanguroo-event">
                        <Image className="img-thumbnail" publicId={logo} />
                      </CloudinaryContext>
                    ) : null}
                  </div>
                  <div className={"col-md-9 col-sm-12"}>
                    <FileUpload
                      buttonLabel={"Charger un logo"}
                      onFileUpload={this.onFileUpload}
                    />

                    {logo ? (
                      <Button
                        className="btn pink white-text darken-1 btn-lg "
                        onClick={this.removeIcon}
                      >
                        Supprimer le logo
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="form-group col-md-6">
                <MDBInput
                  name="url"
                  placeholder="https://www.evenement-exemple.com"
                  label="Nom de domaine de l'événement"
                  value={url || ""}
                  onChange={this.handleDataChange}
                />
              </div>
              <div className="form-group col-md-4">
                <label>Sélectionner le type d'évenement (*)</label>
                <select
                  required
                  name="audience"
                  className="form-control browser-default custom-select"
                  onChange={this.handleDataChange}
                  value={audience || ""}
                >
                  <option value="public">Public</option>
                  <option value="private">privé</option>
                </select>
              </div>
              <div className="form-group col-md-4">
                <label> Sélectionner le tarif</label>
                <select
                  name="free"
                  className="form-control browser-default custom-select"
                  onChange={this.handleDataChange}
                  value={free || ""}
                  required
                >
                  <option value={true}>Gratuit</option>
                  <option value={false}>Payant</option>
                </select>
              </div>

              <div className="form-group col-md-4">
                <label> Gestion de l'émargement (*)</label>
                <select
                  required
                  name="checkIn"
                  className="form-control browser-default custom-select"
                  onChange={this.handleDataChange}
                  value={checkIn || false}
                >
                  <option value={true}>Oui</option>
                  <option value={false}>Non</option>
                </select>
              </div>
              {checkIn === "true" ? (
                <div className="form-group col-md-4">
                  <label> Sélectionner l'utilisation de QR codes</label>
                  <select
                    name="qrCode"
                    className="form-control browser-default custom-select"
                    onChange={this.handleDataChange}
                    value={qrCode || false}
                    required
                  >
                    <option value={true}>Oui</option>
                    <option value={false}>Non</option>
                  </select>
                </div>
              ) : null}

              <div className="form-group col-md-4">
                <label>Nom de l'expéditeur</label>
                <input
                  type="text"
                  name="mail_sender_name"
                  className="form-control"
                  value={mail_sender_name || ""}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="form-group col-md-4">
                <label>Email d'expédition des communications</label>
                {/* {mailData.exist === false ? (
                  <>
                    <br />
                    <label style={{ color: "red" }}>Domaines valides</label>
                    <ul>
                      {mailData.available.map((domain) => {
                        return <li style={{ color: "red" }}>@{domain}</li>;
                      })}
                    </ul>
                  </>
                ) : null} */}
                <input
                  type="text"
                  name="mail_sender_address"
                  // className={`form-control ${
                  //   mailData.exist === false ? "error-input" : ""
                  // }`}
                  className="form-control"
                  value={mail_sender_address || ""}
                  onChange={this.handleChange}
                  required
                />
              </div>

              <div className="form-group col-md-4">
                <label>Inscription modifiable</label>
                <select
                  name="allowModification"
                  className="form-control browser-default custom-select"
                  onChange={this.handleDataChange}
                  value={allowModification || false}
                >
                  <option value={true}>Oui</option>
                  <option value={false}>Non</option>
                </select>
              </div>
            </div>
            <br />
            <label>Label dans la page web de réponse</label>
            <div className="form-row">
              <Multilingual
                lang={reponseLabel}
                event={this.props.event}
                type="input"
                handleLang={(e) => this.handleLang(e, "reponseLabel")}
              />
            </div>
            <br />
            <label>Label dans la page web de communication</label>
            <div className="form-row">
              <Multilingual
                lang={programLabel}
                event={this.props.event}
                type="input"
                handleLang={(e) => this.handleLang(e, "programLabel")}
              />
            </div>
            <br />
            <label>Label dans le passeport/ récapitulatif de commande</label>
            <div className="form-row">
              <Multilingual
                lang={passportLabel}
                event={this.props.event}
                type="input"
                handleLang={(e) => this.handleLang(e, "passportLabel")}
              />
            </div>

            <br />

            <label>Dates</label>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Date de début de l'événement(*)</label>
                <div className="form-group">
                  <DatePicker
                    showTimeSelect
                    selectsStart
                    timeFormat="HH:mm"
                    selected={new Date(start_date)}
                    onChange={this.handlestart_dateChange}
                    locale="fr"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy HH:mm"
                    timeCaption="Heure"
                    required={true}
                  />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label>Date de fin de l'événement(*)</label>
                <div className="form-group">
                  <DatePicker
                    showTimeSelect
                    selectsEnd
                    start_date={new Date(start_date)}
                    end_date={new Date(end_date)}
                    timeFormat="HH:mm"
                    selected={new Date(end_date)}
                    onChange={this.handleend_dateChange}
                    locale="fr"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy HH:mm"
                    timeCaption="Heure"
                    required={true}
                  />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label>Ouverture de la page de communication</label>
                <div className="form-group">
                  <DatePicker
                    showTimeSelect
                    timeFormat="HH:mm"
                    selected={new Date(marketingBeginDate)}
                    onChange={(e) =>
                      this.handleDateChange(e, "marketingBeginDate")
                    }
                    locale="fr"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy HH:mm"
                    timeCaption="Heure"
                  />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label>Ouverture des préinscriptions</label>
                <div className="form-group">
                  <DatePicker
                    showTimeSelect
                    timeFormat="HH:mm"
                    selected={new Date(preRegisterDate)}
                    onChange={(e) =>
                      this.handleDateChange(e, "preRegisterDate")
                    }
                    locale="fr"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy HH:mm"
                    timeCaption="Heure"
                  />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label>Ouverture des inscriptions</label>
                <div className="form-group">
                  <DatePicker
                    showTimeSelect
                    timeFormat="HH:mm"
                    selected={new Date(registerDate)}
                    onChange={(e) => this.handleDateChange(e, "registerDate")}
                    locale="fr"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy HH:mm"
                    timeCaption="Heure"
                  />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label>Date limite de modification</label>
                <div className="form-group">
                  <DatePicker
                    showTimeSelect
                    timeFormat="HH:mm"
                    selected={new Date(modificationLimitDate)}
                    onChange={(e) =>
                      this.handleDateChange(e, "modificationLimitDate")
                    }
                    locale="fr"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy HH:mm"
                    timeCaption="Heure"
                  />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label>Fermeture des inscriptions</label>
                <div className="form-group">
                  <DatePicker
                    showTimeSelect
                    timeFormat="HH:mm"
                    selected={new Date(registerEndDate)}
                    onChange={(e) =>
                      this.handleDateChange(e, "registerEndDate")
                    }
                    locale="fr"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy HH:mm"
                    timeCaption="Heure"
                  />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label>Fermeture de la communication</label>
                <div className="form-group">
                  <DatePicker
                    showTimeSelect
                    timeFormat="HH:mm"
                    selected={new Date(marketingEndDate)}
                    onChange={(e) =>
                      this.handleDateChange(e, "marketingEndDate")
                    }
                    locale="fr"
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy HH:mm"
                    timeCaption="Heure"
                  />
                </div>
              </div>
              {delegataireLimitDate.map((e, k) => (
                <div className="form-group col-md-6" key={k}>
                  <label>
                    Date limite de réponse des invités délégataire {k + 1}
                    <IconButton
                      disabled={k === 0}
                      onClick={() => {
                        this.deleteDelegataireDateLimit(
                          "delegataireLimitDate",
                          k
                        );
                      }}
                    >
                      <DeleteIcon
                        sx={{
                          fontSize: "1.5rem",
                          color: k === 0 ? "grey" : "#d81b60",
                        }}
                      />
                    </IconButton>
                  </label>
                  <div className="form-group">
                    <DatePicker
                      showTimeSelect
                      timeFormat="HH:mm"
                      selected={new Date(delegataireLimitDate[k].date_limit)}
                      onChange={(e) =>
                        this.handleDateChange(
                          e,
                          "delegataireLimitDate",
                          delegataireLimitDate[k].key
                        )
                      }
                      locale="fr"
                      timeIntervals={15}
                      dateFormat="dd/MM/yyyy HH:mm"
                      timeCaption="Heure"
                    />
                  </div>
                </div>
              ))}
              <div className="form-group col-md-6">
                <Button
                  className="btn pink darken-1"
                  onClick={() => {
                    this.addDelegataireDateLimit("delegataireLimitDate");
                  }}
                >
                  Ajouter une date limite aux délégataires
                </Button>
              </div>
            </div>
            <br />
            <label>Lieu de l'événement</label>
            <div className="form-row">
              <div className="form-group col-md-6">
                <MDBInput
                  name="LocationName"
                  label="Nom du lieu"
                  value={LocationName || ""}
                  onChange={this.handleDataChange}
                />
              </div>
              <div className="form-group col-md-6">
                <MDBInput
                  label="Code postal"
                  value={zipCode || ""}
                  name="zipCode"
                  onChange={this.handleDataChange}
                />
              </div>
              <div className="form-group col-md-6">
                <MDBInput
                  name="address1"
                  label="Adresse 1"
                  value={address1 || ""}
                  onChange={this.handleDataChange}
                />
              </div>
              <div className="form-group col-md-6">
                <MDBInput
                  label="Ville"
                  value={city || ""}
                  name="city"
                  onChange={this.handleDataChange}
                />
              </div>
              <div className="form-group col-md-6">
                <MDBInput
                  name="address2"
                  value={address2 || ""}
                  label="Adresse 2"
                  onChange={this.handleDataChange}
                />
              </div>
              <div className="form-group col-md-6">
                <MDBInput
                  label="Pays"
                  value={country || ""}
                  name="country"
                  onChange={this.handleDataChange}
                />
              </div>
            </div>

            <br />
            <label className="danger">Passer en mode maintenance</label>
            <div className="form-row">
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() =>
                      this.setState({
                        event: {
                          ...this.state.event,
                          data: {
                            ...this.state.event.data,
                            maintenanceMode: !maintenanceMode,
                          },
                        },
                      })
                    }
                    id={"maintenanceMode"}
                    checked={maintenanceMode}
                    sx={{
                      color: "#626262",
                      "&.Mui-checked": {
                        color: "#d81b60",
                      },
                      '&.row-checkbox-pj [type="checkbox"]:not(:checked), [type="checkbox"]:checked':
                      {
                        position: "absolute !important",
                      },
                    }}
                  />
                }
                label="Activer le mode maintenance (pages web, espace utilisateur)"
              />

            </div>

            <br />
            <label className="danger">Archiver l'événement</label>
            <div className="form-row">
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() =>
                      this.setState({
                        event: {
                          ...this.state.event,
                          delegataire_archived: !delegataire_archived
                        },
                      })
                    }
                    id={"delegataire_archived"}
                    checked={delegataire_archived}
                    sx={{
                      color: "#626262",
                      "&.Mui-checked": {
                        color: "#d81b60",
                      },
                      '&.row-checkbox-pj [type="checkbox"]:not(:checked), [type="checkbox"]:checked':
                      {
                        position: "absolute !important",
                      },
                    }}
                  />
                }
                label="Archiver l'événement (délégataires)"
              />
            </div>
            <br />
            <EventDeletionDialog
              deleteEvent={this.deleteEvent}
              eventName={name}
            />
            <input
              type="submit"
              value="Enregistrer"
              className="btn pink darken-1 btn-lg white-text float-right "
            // disabled={mailData.exist === false ? true : false}
            />
          </form>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default EventConfig;
