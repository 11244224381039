import React from "react";
import { Switch, Route } from "react-router-dom";
import ContactsTagsList from "./ContactsTagsList";
import ContactsTagsForm from "./ContactsTagsForm";

class ContactsTags extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/admin/tags_contacts" component={ContactsTagsList} />
        <Route
          path="/admin/tags_contacts/edit/:id?"
          component={ContactsTagsForm}
        />
      </Switch>
    );
  }
}

export default ContactsTags;
