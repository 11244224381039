import React from "react";
import { Switch, Route } from "react-router-dom";
import EntitiesList from "./EntitiesList";
import EntityForm from "./EntityForm";

class Entities extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/admin/entities/edit/:id?" component={EntityForm} />
        <Route path="/admin/entities" component={EntitiesList} />
      </Switch>
    );
  }
}

export default Entities;
