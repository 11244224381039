import React from "react";
import Paper from "@mui/material/Paper";
import { Box, IconButton, FormControlLabel, Checkbox } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { uniqBy, uniq } from "lodash";
import ApiService from "../../../../services/ApiService";

import "./SmsForm.css";
import { toast } from "react-toastify";
import { Segments } from "../Segments/Segments";
import { Lists } from "../Lists/Lists";
import { CustomMuiButton } from "../../components/CustomMuiButton";
import { StatBoxWithTitle } from "../../components/StatBoxWithTitle";
import Loader from "../../../../common/Loader";
import SmsNumberValidityBox from "./SmsNumberValidityBox";
import { SmsCampaignSelectedGuestsTable } from "./SmsCampaignSelectedGuestsTable";
import {
  SMS_ACTIONS,
  SMS_STATES,
  getPhoneNumberStatus,
  verifyNumber,
} from "./utils";
import { filtersInitialValues } from "../Lists/utils";
import moment from "moment";

export function SmsCampaignStepTwo({
  guests,
  setGuests,
  guestsChecked,
  setGuestsChecked,
  guestsCheckedValid,
  setGuestsCheckedValid,
  submitCampaign,
  setSegmentsIds,
  setListsIds,
  segmentsIds,
  listsIds,
  setStep,
  evenement,
  components,
  isSmsCampaignEditable,
  reLoadData,
  guestsSmsStatus,
  smsData,
  ...props
}) {
  const [segments, setSegments] = React.useState([]);
  const [lists, setLists] = React.useState([]);

  const [selectedSegments, setSelectedSegments] = React.useState([]);
  const [selectedLists, setSelectedLists] = React.useState([]);

  const [openSegment, setOpenSegment] = React.useState(false);
  const [openList, setOpenList] = React.useState(false);
  const [selectedSegmentId, setSelectedSegmentId] = React.useState(null);
  const [selectedListId, setSelectedListId] = React.useState(null);
  const [packageOptions, setPackageOptions] = React.useState([]);
  const [tagsOptions, setTagsOptions] = React.useState([]);
  const [countryOptions, setCountryOptions] = React.useState([]);
  const [componentsOptions, setComponentsOptions] = React.useState([]);
  const [eventComponents, setEventComponents] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [validNumbers, setValidNumbers] = React.useState(0);
  const [numbersToFormat, setNumbersToFormat] = React.useState(0);
  const [invalidNumbers, setInvalidNumbers] = React.useState(0);
  const [undefinedNumbers, setUndefinedNumbers] = React.useState(0);
  const [disableSendNowButton, setDisableSendNowButton] = React.useState(false);
  const [disableDraftButton, setDisableDraftButton] = React.useState(false);

  const createCampaignWithFailedSms = async () => {
    if (guestsSmsStatus && guestsSmsStatus.length) {
      try {
        const failedSms = guestsSmsStatus.filter((item) => !item.status);
        if (failedSms.length) {
          //TODO: create a list
          const guestsIds = failedSms.map((item) => item.guestId);
          const guestList = await ApiService.request(
            {
              evenement_id: evenement.id,
              title: "Liste Relance Campagne - " +  (smsData.id ? smsData.id.slice(0, 7) : "Copy"),
              guests_ids: guestsIds,
              filters_values: {
                ...filtersInitialValues,
              },
            },
            "guests_list",
            "post"
          );

          if (guestList) {
            await ApiService.request(
              {
                ...smsData,
                title: smsData.title + " - Relance",
                status: SMS_STATES.DRAFT,
                schedule_date: moment(new Date()).add(10, "minutes").format(),
                error_data: null,
                nbContact: guestsIds.length,
                guest_ids: guestsIds,
                segments_ids: [],
                guests_lists_ids: [guestList.id],
              },
              "sms/",
              "post"
            ).then((data) => {
              toast.success("Campagne SMS Créer");
              props.history.push(
                "/admin/events/edit/" +
                  smsData.evenement_id +
                  "/sms/edit/" +
                  data.id
              );
            });
          }
        }
      } catch (error) {
        console.log(error);
        toast.error("Une erreur s'est produite.");
      }
    }
  };

  const handleCampaignSendNow = () => {
    try {
      setDisableSendNowButton(true);
      submitCampaign(SMS_ACTIONS.SEND_NOW);
    } catch (error) {
      console.log(error);
    } finally {
      setDisableSendNowButton(false);
    }
  };
  const handleCampaignDraft = () => {
    try {
      setDisableDraftButton(true);
      submitCampaign(SMS_ACTIONS.DRAFT);
    } catch (error) {
      console.log(error);
    } finally {
      setDisableDraftButton(false);
    }
  };

  const handleSegmentDialogClickOpen = () => {
    setOpenSegment(true);
  };

  const handleSegmentDialogClose = () => {
    setOpenSegment(false);
    setSelectedSegmentId(null);
  };

  const handleEditSegment = (id) => {
    setSelectedSegmentId(id);
    handleSegmentDialogClickOpen();
  };

  const handleEditList = (id) => {
    setSelectedListId(id);
    handleListDialogClickOpen();
  };

  const handleRemoveSegment = async (id) => {
    if (window.confirm("Etes vous sûr de vouloir supprimer ce segment?")) {
      try {
        const res = await ApiService.awaitRequest(
          {},
          "segments/" + id,
          "delete"
        );
        if (res) {
          setSegments((prev) => {
            const newSegments = prev.filter((seg) => seg.id !== id);
            updateCheckedSegments(newSegments);
            return newSegments;
          });
          toast.success("Segment supprimé");
        }
      } catch (error) {
        console.log(error);
        toast.error("Une erreur s'est produite");
      }
    }
  };

  const handleRemoveList = async (id) => {
    if (window.confirm("Etes vous sûr de vouloir supprimer cette liste?")) {
      try {
        const res = await ApiService.awaitRequest(
          {},
          "guests_list/" + id,
          "delete"
        );
        if (res) {
          setLists((prev) => {
            const newLists = prev.filter((list) => list.id !== id);
            updateCheckedLists(newLists);
            return newLists;
          });
          toast.success("Liste supprimée");
        }
      } catch (error) {
        console.log(error);
        toast.error("Une erreur s'est produite");
      }
    }
  };

  const handleListDialogClickOpen = () => {
    setOpenList(true);
  };

  const handleListDialogClose = () => {
    setOpenList(false);
    setSelectedListId(null);
  };

  const updateCheckedSegments = (segmentsRes) => {
    const checkedSegments = segmentsIds.reduce((acc, id) => {
      const foundSegment = segmentsRes.find((seg) => seg.id === id);
      if (foundSegment) {
        acc.push(foundSegment);
      }
      return acc;
    }, []);
    setSelectedSegments(checkedSegments);
  };

  const updateCheckedLists = (listsRes) => {
    const checkedLists = listsIds.reduce((acc, id) => {
      const foundList = listsRes.find((list) => list.id === id);
      if (foundList) {
        acc.push(foundList);
      }
      return acc;
    }, []);
    setSelectedLists(checkedLists);
  };

  const handleSegmentAndListUpdate = (value, type) => {
    if (type === "segment") {
      setSegments((prev) => {
        const foundSegIndex = prev.findIndex((seg) => seg.id === value.id);

        if (foundSegIndex > -1) {
          prev[foundSegIndex] = value;
          return prev;
        }
        const newSegments = [...prev, value];
        return newSegments;
      });
      updateCheckedSegments(segments);
    } else if (type === "list") {
      setLists((prev) => {
        const foundSegIndex = prev.findIndex((seg) => seg.id === value.id);

        if (foundSegIndex > -1) {
          prev[foundSegIndex] = value;
          return prev;
        }
        const newLists = [...prev, value];
        return newLists;
      });
      updateCheckedLists(lists);
    }
  };

  const handleNextStep = () => {
    if (guestsCheckedValid.length > 0) {
      setStep(3);
      return;
    }
    toast.warning("Votre campagne ne contient aucun destinataires valident");
  };

  const handleSegmentCheckbox = (event) => {
    const checked = event.target.checked;
    const value = Number(event.target.value);

    if (checked) {
      const notFound = selectedSegments.find((seg) => seg.id === value);
      if (!notFound) {
        const segment = segments.find((seg) => seg.id === value);
        if (segment) {
          setSelectedSegments((prev) => [...prev, segment]);
          setSegmentsIds((prev) => [...prev, value]);
        }
      }
    } else {
      setSelectedSegments((prev) => prev.filter((seg) => seg.id !== value));
      setSegmentsIds((prev) => prev.filter((id) => id !== value));
    }
  };

  const handleListsCheckbox = (event) => {
    const checked = event.target.checked;
    const value = Number(event.target.value);

    if (checked) {
      const notFound = selectedLists.find((seg) => seg.id === value);
      if (!notFound) {
        const list = lists.find((seg) => seg.id === value);
        if (list) {
          setSelectedLists((prev) => [...prev, list]);
          setListsIds((prev) => [...prev, value]);
        }
      }
    } else {
      setSelectedLists((prev) => prev.filter((elem) => elem.id !== value));
      setListsIds((prev) => prev.filter((id) => id !== value));
    }
  };

  const updateGuestStates = (prevGuests, guestsFormated) => {
    const newGuests = [...prevGuests];
    guestsFormated.forEach((guestFormated) => {
      const guestIndex = prevGuests.findIndex(
        (guest) => guest.id === guestFormated.id
      );
      if (guestIndex > -1) {
        newGuests[guestIndex].mobile = guestFormated.mobile;
      }
    });

    return newGuests;
  };

  const updateGuestPhoneNumber = async (
    guestId,
    guestContactId,
    phoneNumber
  ) => {
    const guestMobileToFormat = [
      { id: guestId, mobile: phoneNumber, contact_id: guestContactId },
    ];
    await ApiService.request(
      {
        guests: guestMobileToFormat,
      },
      "guests/update/mobile",
      "put"
    )
      .then((countSucess) => {
        if (Number(countSucess)) {
          toast.success("Numéro mis à jour");
          setGuests((prevGuests) => {
            return updateGuestStates(prevGuests, guestMobileToFormat);
          });
          setGuestsChecked((prevGuests) => {
            return updateGuestStates(prevGuests, guestMobileToFormat);
          });
        } else
          toast.success(
            "Une erreur c'est produite durant la modification du numéro."
          );
      })
      .catch((e) => {
        toast.error(
          "Une erreur c'est produite lors de la modification du numéro"
        );
        console.log(e);
      });
  };

  const formatPhoneNumbers = async () => {
    //TODO: iterate on
    const guestMobileToFormat = guestsChecked.reduce((accumalator, guest) => {
      const guestNumberStatus = getPhoneNumberStatus(guest.mobile);

      if (guestNumberStatus === "toFormat") {
        const formattedPhoneNumber = verifyNumber(guest.mobile);
        return [
          ...accumalator,
          {
            id: guest.id,
            mobile: formattedPhoneNumber,
            contact_id: guest.contact_id,
          },
        ];
      }
      return accumalator;
    }, []);

    setIsLoading(true);
    await ApiService.request(
      {
        guests: guestMobileToFormat,
      },
      "guests/update/mobile",
      "put"
    )
      .then((countSucess) => {
        if (Number(countSucess) === guestMobileToFormat.length) {
          setGuests((prevGuests) => {
            return updateGuestStates(prevGuests, guestMobileToFormat);
          });
          setGuestsChecked((prevGuests) => {
            return updateGuestStates(prevGuests, guestMobileToFormat);
          });
          toast.success(`Mise à jour de ${countSucess} numéro(s)`);
        } else
          toast.success(
            `Une erreur c'est produite pour ${
              guestMobileToFormat.length - countSucess
            } numéro(s)`
          );
      })
      .catch((e) => {
        toast.error(
          `Une erreur c'est produite lors de la mise à jour des numéros`
        );
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const nameAndSocietyFilter = (field, condition, value) => {
    switch (condition) {
      case "is":
        return field === value.trim().toLowerCase();
      case "is_not":
        return field !== value.trim().toLowerCase();
      case "include":
        return field.includes(value.trim().toLowerCase());
      case "not_include":
        return !field.includes(value.trim().toLowerCase());
      case "is_empty":
        return field === "";
      case "is_not_empty":
        return field !== "";
      default:
        return true;
    }
  };

  const followerFilter = (maxInvitation, value) => {
    switch (value) {
      case "yes":
        return maxInvitation > 0;
      case "no":
        return maxInvitation <= 0;
      default:
        return true;
    }
  };

  const tagsFilter = (guestTags, value) => {
    if (guestTags && guestTags.length < 1) return false;
    for (let tag of guestTags) {
      if (!value.includes(tag.id)) return false;
    }
    return true;
  };

  const doesGuestVerifyMainSegmentConditions = (guest, mainSegmentList) => {
    for (let seg of mainSegmentList) {
      switch (seg.type) {
        case "name":
          const guestName = `${guest.firstname} ${guest.lastname}`
            .trim()
            .toLowerCase();
          if (!nameAndSocietyFilter(guestName, seg.condition, seg.value)) {
            return false;
          }
          break;

        case "society":
          let societyName =
            guest.contact && guest.contact.company
              ? guest.contact.company.name
              : "";

          societyName = societyName.trim().toLowerCase();
          if (!nameAndSocietyFilter(societyName, seg.condition, seg.value))
            return false;
          break;
        case "component_response": //!
          break;
        case "language":
          const guestLanguage =
            guest.contact && guest.contact.languages
              ? guest.contact.languages.trim().toLowerCase()
              : "";
          if (guestLanguage !== seg.value) return false;
          break;
        case "country":
          const guestCountry = guest.country
            ? guest.country.trim().toLowerCase()
            : "";
          if (guestCountry !== seg.value) return false;
          break;
        case "package":
          if (Number(guest.package_id) !== Number(seg.value)) return false;
          break;
        case "can_have_follower":
          if (!followerFilter(guest.max_invitation, seg.value)) return false;
          break;
        case "tags":
          const guestTags = guest.contact ? guest.contact.tags : [];
          if (!tagsFilter(guestTags, seg.value)) return false;
          break;
        case "invited_by":
          if (guest.invited_by !== seg.value) return false;
          break;
        default:
          break;
      }
    }
    return true;
  };

  const doesGuestVerifySecondSegmentConditions = (guest, secondSegmentList) => {
    for (let seg of secondSegmentList) {
      switch (seg.type) {
        case "name":
          const guestName = `${guest.firstname} ${guest.lastname}`
            .trim()
            .toLowerCase();
          if (nameAndSocietyFilter(guestName, seg.condition, seg.value))
            return true;
          break;

        case "society":
          let societyName =
            guest.contact && guest.contact.company
              ? guest.contact.company.name
              : "";

          societyName = societyName.trim().toLowerCase();
          if (nameAndSocietyFilter(societyName, seg.condition, seg.value))
            return true;
          break;
        case "component_response": //!
          break;
        case "language":
          const guestLanguage =
            guest.contact && guest.contact.languages
              ? guest.contact.languages.trim().toLowerCase()
              : "";
          if (guestLanguage === seg.value) return true;
          break;
        case "country":
          const guestCountry = guest.country
            ? guest.country.trim().toLowerCase()
            : "";
          if (guestCountry === seg.value) return true;
          break;
        case "package":
          if (Number(guest.package_id) === Number(seg.value)) return true;
          break;
        case "can_have_follower":
          if (followerFilter(guest.max_invitation, seg.value)) return true;
          break;
        case "tags":
          const guestTags = guest.contact ? guest.contact : [];
          if (tagsFilter(guestTags, seg.value)) return true;
          break;
        case "invited_by":
          if (guest.invited_by === seg.value) return true;
          break;
        default:
          break;
      }
    }
    return false;
  };

  const filterGuestsBySegments = () => {
    let guestsFilteredList = selectedSegments.map((segment) => {
      return guests.filter((guest) => {
        let isMainSegmentConditionsValid = true;
        let isSecondSegmentConditionsValid = true;
        if (segment.main_segment_list && segment.main_segment_list.length) {
          isMainSegmentConditionsValid = doesGuestVerifyMainSegmentConditions(
            guest,
            segment.main_segment_list
          );
        }

        if (segment.second_segment_list && segment.second_segment_list.length) {
          isSecondSegmentConditionsValid =
            doesGuestVerifySecondSegmentConditions(
              guest,
              segment.second_segment_list
            );
        }
        const res =
          isMainSegmentConditionsValid && isSecondSegmentConditionsValid;
        return res;
      });
    });

    //TODO: cat all array inside guestsFiltered and remove duplicates
    guestsFilteredList = guestsFilteredList.reduce((acc, current) => {
      return acc.concat(current);
    }, []);
    guestsFilteredList = uniqBy(guestsFilteredList, "id");

    setGuestsChecked(guestsFilteredList);
  };

  const filterGuestsByLists = () => {
    let allGuestsListsIds = selectedLists.reduce((acc, current) => {
      return acc.concat(current.guests_ids);
    }, []);

    allGuestsListsIds = uniq(allGuestsListsIds);

    let guestsFilteredList = guests.filter((guest) =>
      allGuestsListsIds.includes(guest.id)
    );

    setGuestsChecked(guestsFilteredList);
  };

  const loadData = async () => {
    try {
      setIsLoading(true);
      const segmentsRes = await ApiService.awaitRequest(
        {},
        "segments/all/" + evenement.id,
        "get"
      );

      if (segmentsRes) {
        setSegments(segmentsRes);
        updateCheckedSegments(segmentsRes);
      }

      const listsRes = await ApiService.awaitRequest(
        {},
        "guests_list/all/" + evenement.id,
        "get"
      );

      if (listsRes) {
        setLists(listsRes);
        updateCheckedLists(listsRes);
      }

      const packages = await ApiService.request(
        {},
        "packages/byevenement/" + evenement.id,
        "get"
      );
      if (packages) {
        const res = packages.map((pck) => ({
          value: pck.id,
          label: pck.name,
        }));
        setPackageOptions(res);
      }

      const tags = await ApiService.request({}, "tags", "get");
      if (tags) {
        const res = tags
          .map((tag) => ({
            label: tag.tag_name,
            value: tag.id,
          }))
          .sort((a, b) => {
            var tagA = a.label.toLowerCase(); // Ignorer les majuscules et minuscules
            var tagB = b.label.toLowerCase(); // Ignorer les majuscules et minuscules
            if (tagA < tagB) {
              return -1; // a doit être avant b dans l'ordre alphabétique
            }
            if (tagA > tagB) {
              return 1; // a doit être après b dans l'ordre alphabétique
            }
            // Les noms sont égaux
            return 0;
          });
        res.unshift({ label: "TAGS", value: "" });
        setTagsOptions(res);
      }

      // Countries
      const countries = await ApiService.request(
        {},
        "evenements/guests_countries/" + evenement.id,
        "get"
      );

      if (countries) {
        const res = countries.map((c) => ({
          value: c.country,
          label: c.country,
        }));
        res.unshift({ label: "Pays", value: "" });
        setCountryOptions(res);
      }

      //Components
      if (components) {
        const res = components
          .filter(
            (c) =>
              c.data &&
              (c.data.componentType === "moment" ||
                c.data.componentType === "logistic")
          )
          .map((c) => ({
            value: c.id,
            label: c.data ? c.data.name : `Composant id - ${c.id}`,
          }));
        res.unshift({ value: "", label: "Composants" });
        setComponentsOptions(res);
        setEventComponents(components);
      }
    } catch (error) {
      console.log(error);
      toast.error("Une erreur s'est produite");
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  React.useEffect(() => {
    if (selectedSegments.length > 0) {
      filterGuestsBySegments();
    } else if (selectedLists.length < 1) {
      setGuestsChecked([]);
    }
  }, [selectedSegments]);

  React.useEffect(() => {
    if (selectedLists.length > 0) {
      filterGuestsByLists();
    } else if (selectedSegments.length < 1) {
      setGuestsChecked([]);
    }
  }, [selectedLists]);

  React.useEffect(() => {
    let countValidNumbers = 0;
    let countNumberToFormat = 0;
    let countInvalidNumber = 0;
    let countUndefinedNumber = 0;
    const validGuestsIds = [];

    guestsChecked.forEach((guest) => {
      const numberStatus = getPhoneNumberStatus(guest.mobile);
      switch (numberStatus) {
        case "valid":
          countValidNumbers += 1;
          validGuestsIds.push(guest.id);
          break;
        case "toFormat":
          countNumberToFormat += 1;
          break;
        case "undefined":
          countUndefinedNumber += 1;
          break;
        case "invalid":
          countInvalidNumber += 1;
          break;
        default:
          countInvalidNumber += 1;
          break;
      }
    });

    setValidNumbers(countValidNumbers);
    setNumbersToFormat(countNumberToFormat);
    setUndefinedNumbers(countUndefinedNumber);
    setInvalidNumbers(countInvalidNumber);
    setGuestsCheckedValid(validGuestsIds);
  }, [guestsChecked]);

  const segmentsGuests = React.useMemo(() => {
    return guests.map((guest) => ({
      id: guest.id,
      label: guest.firstname + " " + guest.lastname,
    }));
  }, [guests]);

  const isCampainPast = !isSmsCampaignEditable();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "2rem",
        margin: "auto",
        width: "fit-content",
        height: "100%",
      }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Paper
            elevation={3}
            sx={{
              width: "100%",
              borderRadius: "0.875rem",
            }}
          >
            <Box sx={{ display: "flex", gap: "10rem", padding: "2rem 3rem" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
              >
                <div>
                  <h3 className="sms-campaing-paper-title">
                    CHOISIR UN OU PLUSIEURS SEGMENTS
                  </h3>
                  <small>
                    Selection de contacts selon des attributs définis.
                  </small>
                </div>
                <div>
                  {segments &&
                    segments.map((seg, index) => (
                      <div
                        key={`sms-step-2-segment-${index}`}
                        className="sms-step-2-segment-list"
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              inputProps={{
                                "aria-labelledby": `sms-step-2-segment-label-${index}`,
                                style: { width: "0px" },
                              }}
                              sx={{
                                color: "#626262",
                                "&.Mui-checked": {
                                  color: "#d81b60",
                                },
                              }}
                              value={seg.id}
                              onChange={handleSegmentCheckbox}
                              checked={segmentsIds.includes(seg.id)}
                              disabled={
                                isCampainPast || selectedLists.length > 0
                              }
                            />
                          }
                          label={seg.title}
                        />
                        {!isCampainPast && (
                          <div className="sms-step-2-checkbox-action-btn">
                            <IconButton
                              className="edit-btn"
                              onClick={() => handleEditSegment(seg.id)}
                            >
                              <ModeEditOutlineOutlinedIcon />
                            </IconButton>
                            <IconButton
                              className="remove-btn"
                              onClick={() => handleRemoveSegment(seg.id)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
                {!isCampainPast && (
                  <CustomMuiButton
                    type="button"
                    onClick={handleSegmentDialogClickOpen}
                    text="CRÉER UN SEGMENT"
                    minHeight={60}
                    textColor="#ffffff"
                    buttonBgColor="#d81b60"
                    buttonHoverBgColor="#a9134a"
                    buttonHoverBorderColor="#0062cc"
                    buttonActiveBgColor="#d81b60"
                  />
                )}
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
              >
                <div>
                  <h3 className="sms-campaing-paper-title">
                    CHOISIR UN OU PLUSIEURS LISTES
                  </h3>
                  <small>
                    Selection manuelle de contacts avec des filtres.
                  </small>
                </div>
                <div>
                  {lists &&
                    lists.map((list, index) => (
                      <div
                        key={`sms-step-2-list-${index}`}
                        className="sms-step-2-segment-list"
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              inputProps={{
                                "aria-labelledby": `sms-step-2-list-label-${index}`,
                                style: { width: "0px" },
                              }}
                              sx={{
                                color: "#626262",
                                "&.Mui-checked": {
                                  color: "#d81b60",
                                },
                              }}
                              value={list.id}
                              onChange={handleListsCheckbox}
                              checked={listsIds.includes(list.id)}
                              disabled={
                                isCampainPast || selectedSegments.length > 0
                              }
                            />
                          }
                          label={list.title}
                        />
                        {!isCampainPast && (
                          <div className="sms-step-2-checkbox-action-btn">
                            <IconButton
                              className="edit-btn"
                              onClick={() => handleEditList(list.id)}
                            >
                              <ModeEditOutlineOutlinedIcon />
                            </IconButton>
                            <IconButton
                              className="remove-btn"
                              onClick={() => handleRemoveList(list.id)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
                {!isCampainPast && (
                  <CustomMuiButton
                    type="button"
                    onClick={handleListDialogClickOpen}
                    text="CRÉER UNE LISTE"
                    minHeight={60}
                    textColor="#ffffff"
                    buttonBgColor="#d81b60"
                    buttonHoverBgColor="#a9134a"
                    buttonHoverBorderColor="#0062cc"
                    buttonActiveBgColor="#d81b60"
                  />
                )}
              </Box>
            </Box>
          </Paper>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: "2rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <SmsNumberValidityBox
                valid={validNumbers}
                toFormat={numbersToFormat}
                undefinedNumbers={undefinedNumbers}
                inValid={invalidNumbers}
                formatPhoneNumbers={formatPhoneNumbers}
                disableButton={isCampainPast}
              />
              <Box sx={{ minWidth: "220px" }}>
                <StatBoxWithTitle
                  title="Destinataires"
                  stat={
                    guestsChecked.length &&
                    `${guestsCheckedValid.length} / ${guestsChecked.length}`
                  }
                />
              </Box>
            </Box>

            {!isCampainPast ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <CustomMuiButton
                  type="button"
                  onClick={(e) => handleCampaignDraft()}
                  text={
                    <>
                      Enregistrer <br />
                      Brouillon
                    </>
                  }
                  minHeight={60}
                  textColor="#ffffff"
                  buttonBgColor="#A2A2A2"
                  buttonHoverBgColor="#7A7A7A"
                  buttonHoverBorderColor="#0062cc"
                  buttonActiveBgColor="#A2A2A2"
                  disable={disableDraftButton}
                />
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  {/* <CustomMuiButton
                    type="button"
                    onClick={() => null}
                    text="Envoyer un test"
                    minHeight={60}
                    textColor="#ffffff"
                    buttonBgColor="#FAA047"
                    buttonHoverBgColor="#C98039"
                    buttonHoverBorderColor="#0062cc"
                    buttonActiveBgColor="#FAA047"
                  /> */}
                  <CustomMuiButton
                    type="button"
                    onClick={handleNextStep}
                    text={
                      <>
                        Programmer <br />
                        l'envoi
                      </>
                    }
                    minHeight={60}
                    textColor="#ffffff"
                    buttonBgColor="#17A7A8"
                    buttonHoverBgColor="#107677"
                    buttonHoverBorderColor="#0062cc"
                    buttonActiveBgColor="#17A7A8"
                  />
                  <CustomMuiButton
                    type="button"
                    onClick={(e) => handleCampaignSendNow()}
                    text={
                      <>
                        Envoyer <br />
                        Immédiatement
                      </>
                    }
                    minHeight={60}
                    textColor="#ffffff"
                    buttonBgColor="#E01755"
                    buttonHoverBgColor="#AF1242"
                    buttonHoverBorderColor="#0062cc"
                    buttonActiveBgColor="#E01755"
                    disable={disableSendNowButton}
                  />
                </Box>
              </Box>
            ) : (
              <CustomMuiButton
                type="button"
                onClick={handleNextStep}
                text="Prochaine étape"
                minHeight={60}
                textColor="#ffffff"
                buttonBgColor="#E01755"
                buttonHoverBgColor="#AF1242"
                buttonHoverBorderColor="#0062cc"
                buttonActiveBgColor="#E01755"
              />
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <SmsCampaignSelectedGuestsTable
              guestsChecked={guestsChecked}
              packageOptions={packageOptions}
              getPhoneNumberStatus={getPhoneNumberStatus}
              updateGuestPhoneNumber={updateGuestPhoneNumber}
              isCampainPast={isCampainPast}
              guestsSmsStatus={guestsSmsStatus}
            />
          </Box>
          {isCampainPast && guestsSmsStatus && guestsSmsStatus.length > 0 && (
            <Box
              sx={{
                width: "100%",
              }}
            >
              <CustomMuiButton
                type="button"
                onClick={(e) => createCampaignWithFailedSms()}
                text={
                  <>
                    Créer une campagne avec
                    <br />
                    les SMS en échecs
                  </>
                }
                minHeight={60}
                textColor="#ffffff"
                buttonBgColor="#FAA047"
                buttonHoverBgColor="#C98039"
                buttonHoverBorderColor="#0062cc"
                buttonActiveBgColor="#FAA047"
              />
            </Box>
          )}
        </>
      )}
      {openSegment && (
        <Segments
          open={openSegment}
          handleDialogClose={handleSegmentDialogClose}
          eventId={evenement.id}
          segmentId={selectedSegmentId}
          updateParentSegments={handleSegmentAndListUpdate}
          guests={segmentsGuests}
          packageOptions={packageOptions}
          setPackageOptions={setPackageOptions}
          tagsOptions={tagsOptions}
          setTagsOptions={setTagsOptions}
          countryOptions={countryOptions}
          setCountryOptions={setCountryOptions}
          componentsOptions={componentsOptions}
          eventComponents={eventComponents}
        />
      )}
      {openList && (
        <Lists
          open={openList}
          handleDialogClose={handleListDialogClose}
          evenement={evenement}
          listId={selectedListId}
          updateParentLists={handleSegmentAndListUpdate}
          guests={guests}
          packageOptions={packageOptions}
          setPackageOptions={setPackageOptions}
          tagsOptions={tagsOptions}
          setTagsOptions={setTagsOptions}
          countryOptions={countryOptions}
          setCountryOptions={setCountryOptions}
          componentsOptions={componentsOptions}
          eventComponents={eventComponents}
        />
      )}
    </div>
  );
}
