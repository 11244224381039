import React from "react";
import { MDBSpinner } from "mdbreact";
import "./loader.css";

export const Loader = () => {
  return (
    <div className="text-center">
      <br />
      <br />
      <br />
      <br />
      <br />
      <MDBSpinner big red />
    </div>
  );
};

export default Loader;
