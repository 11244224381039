import React from "react";
import { Switch, Route } from "react-router-dom";
import WorkSpacesList from "./WorkSpacesList";
import WorkSpaceForm from "./WorkSpaceForm";

class WorkSpaces extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/admin/workspaces/edit/:id" component={WorkSpaceForm} />
        <Route path="/admin/workspaces" component={WorkSpacesList} />
      </Switch>
    );
  }
}

export default WorkSpaces;
