import React from "react";

import ApiService from "../../../services/ApiService";
import PackagesEditor from "./PackagesEditor";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader";
import Sortable from "../../../common/Sortable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faTimes } from "@fortawesome/free-solid-svg-icons";
import { languagesList } from "../../../common/Translations";
class PackagesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.duplicatePackage = this.duplicatePackage.bind(this);
    this.savePackage = this.savePackage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getCheckIds = this.getCheckIds.bind(this);
    this.deletePackagesConfirmation =
      this.deletePackagesConfirmation.bind(this);
    this.deletePackages = this.deletePackages.bind(this);

    this.state = {
      componentsList: [],
      ids: [],
      packagesList: [],
      guests: [],
      isLoading: true,
      action: "",
    };
  }

  async duplicatePackage(eventPackage) {
    this.setState({ isLoading: true });
    console.log(eventPackage);
    var newData = {
      ...eventPackage,
      mail_placeholder_1: JSON.stringify(eventPackage.mail_placeholder_1),
      mail_placeholder_2: JSON.stringify(eventPackage.mail_placeholder_2),
      mail_placeholder_3: JSON.stringify(eventPackage.mail_placeholder_3),
      mail_placeholder_4: JSON.stringify(eventPackage.mail_placeholder_4),
      mail_placeholder_5: JSON.stringify(eventPackage.mail_placeholder_5),
    };
    await ApiService.awaitRequest(
      {
        ...newData,
        component_ids: eventPackage.components.map((component) => component.id),
        name: eventPackage.name + " Copie",
      },
      "packages/",
      "post"
    );

    this.loadData();
  }

  async loadData() {
    this.setState({
      isLoading: true,
    });

    const componentsList = await ApiService.awaitRequest(
      {},
      "components/byevenement/" + this.props.match.params.eventid,
      "get"
    );

    const packageListPlaceholderParsed = await ApiService.awaitRequest(
      {},
      "packages/byevenement/" + this.props.match.params.eventid,
      "get"
    );
    const packagesList = packageListPlaceholderParsed.map((pack) => {
      var updatedPackage = { ...pack };
      Object.keys(pack).forEach((key) => {
        if (key.includes("mail_placeholder_")) {
          let value = pack[key];
          if (
            (typeof value === "string" &&
              !value.includes('"label":"Français"')) ||
            value === null
            // pour être sur de ne pas ré-écrire des packages qui sont bien sous le bon format
          ) {
            // Supprimer toutes les balises HTML de la valeur
            if (value === null || value.includes("null")) {
              // Ceci sert pour les packages créent avant la fonctionnalité multi-langues,
              // on supprime remplace ce qui est null par le multi-langues
              updatedPackage[key] = languagesList;
            } else if (value.includes("<")) {
              //value = value.replace(/(<([^>]+)>)/gi, ""); // to delete htmls
              // Ceci sert pour les packages créent avant la fonctionnalité multi-langues,
              // on supprime les balises htmls et on met le texte qui précédé dans les languageslist
              updatedPackage[key] = languagesList.map((lang) => {
                return {
                  ...lang,
                  value: value,
                };
              });
            }
          } else {
            updatedPackage[key] = JSON.parse(value);
          }
        }
      });
      return updatedPackage;
    });
    console.log(packagesList);
    const guests = await ApiService.awaitRequest(
      {},
      "guests/byevenement/" + this.props.match.params.eventid,
      "get"
    );
    this.setState({
      guests,
      isLoading: false,
      action: "",
      packagesList,
      componentsList,
    });
  }

  componentDidMount() {
    this.loadData();
  }

  savePackage(data) {
    let method = data.id ? "put" : "post";
    let id = data.id ? data.id : "";
    const self = this;
    var newData = {
      ...data,
      mail_placeholder_1: JSON.stringify(data.mail_placeholder_1),
      mail_placeholder_2: JSON.stringify(data.mail_placeholder_2),
      mail_placeholder_3: JSON.stringify(data.mail_placeholder_3),
      mail_placeholder_4: JSON.stringify(data.mail_placeholder_4),
      mail_placeholder_5: JSON.stringify(data.mail_placeholder_5),
    };
    ApiService.request(
      {
        ...newData,
        evenement_id: this.props.match.params.eventid,
      },
      "packages/" + id,
      method
    )
      .then(function (dataDB) {
        toast.success("Package sauvegardé", {
          autoClose: 3000,
        });
        self.loadData();
      })
      .catch(function () {
        toast.error("Erreur lors de l'enregistrement", {
          autoClose: 3000,
        });
      });
  }

  async deletePackages(ids) {
    this.setState({ isLoading: true });
    ids.forEach(async (id) => {
      await ApiService.request({}, "packages/" + id, "delete");
    });
    this.loadData();
  }

  async deletePackagesConfirmation() {
    const { guests, ids } = this.state;

    if (guests.some((guest) => ids.some((id) => id === guest.package_id))) {
      if (
        window.confirm(
          "Certains packages sélectionnés sont utilisés. Seuls les packages non utilisés seront supprimés."
        )
      )
        this.deletePackages(
          ids.filter((id) => !guests.some((guest) => guest.package_id === id))
        );
    } else {
      this.deletePackages(ids);
    }
  }

  async getCheckIds(ids) {
    this.setState({
      ids,
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { isLoading, packagesList, ids, action, componentsList } = this.state;
    if (isLoading) {
      return <Loader />;
    }

    const sortable = packagesList.map((eventPackage) => ({
      id: eventPackage.id,
      name: eventPackage.name,
      description: eventPackage.description,
      componentsNumber: eventPackage.components.length,
      actions: (
        <>
          <PackagesEditor
            onValidate={this.savePackage}
            componentsList={componentsList}
            package={eventPackage}
          />
          &nbsp;
          <FontAwesomeIcon
            onClick={() => this.duplicatePackage(eventPackage)}
            icon={faCopy}
          />
        </>
      ),
    }));

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <div className="row">
            <div className={"col-md-10"}>
              <h2>Liste des Packages</h2>
            </div>

            <div className="col-md-2">
              <PackagesEditor
                onValidate={this.savePackage}
                componentsList={componentsList}
                create={true}
              />
            </div>
          </div>
          <br />
          <Sortable
            getCheckIds={this.getCheckIds}
            checkable={true}
            names={[
              { key: "name", value: "Nom" },
              { key: "description", value: "Description" },
              { key: "componentsNumber", value: "Nombre de composants" },
              { key: "actions", value: "Actions" },
            ]}
            sortable={sortable}
          />
          {ids.length > 0 && (
            <div className="row mr-0 ml-0 actions-container actions-container-fixed justify-content-between align-items-center">
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    {ids.length} package
                    {ids.length > 1 ? "s" : null}
                    &nbsp;sélectionné
                    {ids.length > 1 ? "s" : null}
                  </label>
                  <select
                    onChange={this.handleChange}
                    name="action"
                    className="col-sm-12 browser-default custom-select"
                    value={action}
                    required
                  >
                    <option value="">Sélectionner une action groupée</option>
                    <option value="delete">Supprimer</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4 offset-md-3">
                <button
                  type="submit"
                  disabled={action !== "delete"}
                  onClick={() => this.deletePackagesConfirmation(ids)}
                  className="btn white-text pink darken-1"
                >
                  Valider
                </button>
              </div>
              <div
                className="col-md-1 align-self-start"
                style={{ maxWidth: "fit-content" }}
              >
                <FontAwesomeIcon
                  title={"Fermer"}
                  icon={faTimes}
                  onClick={() => {
                    this.getCheckIds([]);
                  }}
                  color="white"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PackagesContainer;
