import React from "react";
import { MDBInput, Button, Modal, ModalBody, ModalFooter } from "mdbreact";
import { translateComponentType } from "../../../common/Translations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Multilingual from "../../../common/Multilingual";
import { languagesList } from "../../../common/Translations";

class PackagesEditor extends React.Component {
  constructor(props) {
    super(props);

    this.filterComponentsList = this.filterComponentsList.bind(this);
    this.check = this.check.bind(this);
    this.validate = this.validate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLang = this.handleLang.bind(this);

    this.state = {
      modal: false,
      filter_selected: "",
      filter_text: "",
      componentsChecked: this.props.package
        ? this.props.package.components
        : [],
      package: this.props.package
        ? this.props.package
        : {
          name: "",
          description: "",
          mail_placeholder_1: languagesList,
          mail_placeholder_2: languagesList,
          mail_placeholder_3: languagesList,
          mail_placeholder_4: languagesList,
          mail_placeholder_5: languagesList,
          checkin_group_message: "",
          overwrite_checkin_group_message: false,
        },
    };
  }

  filterComponentsList(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  check(event, newComponent, isPackage) {
    const componentsChecked = this.state.componentsChecked;
    if (isPackage) {
      const name = event.target.name;
      const value = event.target.checked;
      this.setState({
        package: { ...this.state.package, [name]: value },
      });
      return;
    }
    if (!event.target.checked) {
      this.setState({
        componentsChecked: componentsChecked.filter(
          (component) => component.id !== newComponent.id
        ),
      });
    } else {
      const component = componentsChecked.filter(
        (component) => component.id === newComponent.id
      );
      if (component.length === 0) {
        componentsChecked.push(newComponent);
        this.setState({
          componentsChecked,
        });
      }
    }
  }

  handleChange(e, label) {
    const name = label ? label : e.target.name;
    const value = label ? e : e.target.value;

    this.setState({
      package: { ...this.state.package, [name]: value },
    });
  }

  validate() {
    const entranceComponent = this.props.componentsList.find(component => component.data.reponse.role === 'entrance').id;
    const componentsId = this.state.componentsChecked.map((component) => {
      return component.id;
    })
    if (componentsId.includes(entranceComponent)) {

      if (this.state.package.overwrite_checkin_group_message) {
        if (
          window.confirm(
            "Êtes-vous sûr de vouloir remplacer les messages du checkin ? "
          )
        ) {
          this.toggle();
          this.props.onValidate({
            ...this.state.package,
            component_ids: componentsId,
          });
        }
      } else {
        this.toggle();
        this.props.onValidate({
          ...this.state.package,
          component_ids: componentsId,
        });
      }
    } else {
      toast.info("Le composant d'entrée doit être sélectionné.", {
        autoClose: 3000,
    });    
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  handleLang(newLangSet, label) {
    this.setState({
      package: { ...this.state.package, [label]: newLangSet },
    });
  }

  render() {
    const { modal, filter_selected, componentsChecked, filter_text } =
      this.state;
    const {
      mail_placeholder_1,
      mail_placeholder_2,
      mail_placeholder_3,
      mail_placeholder_4,
      mail_placeholder_5,
    } = this.state.package;

    const { create } = this.props;
    return (
      <div className="d-inline">
        {create ? (
          <button
            className="btn pink darken-1 float-right white-text"
            onClick={this.toggle}
          >
            Nouveau package
          </button>
        ) : (
          <FontAwesomeIcon icon={faEdit} onClick={this.toggle} />
        )}
        <Modal isOpen={modal} size="xl" toggle={this.toggle}>
          <ModalBody>
            Nom du package
            <input
              className="form-control mb-2"
              type="text"
              name="name"
              value={this.state.package.name || ""}
              onChange={this.handleChange}
              placeholder="Nouveau package"
            />
            <br />
            Description
            <input
              className="form-control mb-2"
              type="text"
              name="description"
              value={this.state.package.description || ""}
              onChange={this.handleChange}
              placeholder="Description"
            />
            <br />
            Message par défaut au Checkin
            <input
              className="form-control mb-2"
              type="text"
              name="checkin_group_message"
              value={this.state.package.checkin_group_message}
              onChange={this.handleChange}
              placeholder="Message"
            />
            {/* <br />
            <MDBInput
              label={"Remplacer les messages du checkin des invités du package"}
              onClick={(e) => this.check(e, "", true)}
              filled
              checked={this.state.package.overwrite_checkin_group_message}
              id={`id-${this.state.package.id}`}
              name="overwrite_checkin_group_message"
              key={`key-${this.state.package.id}`}
              type="checkbox"
            /> */}
            <br />
            <div className="form-group col-md-12">
              <label>Champ personnalisable 1</label>
              <div className="form-row">
                <Multilingual
                  lang={mail_placeholder_1}
                  type="textarea"
                  handleLang={(e) => this.handleLang(e, "mail_placeholder_1")}
                />
              </div>
            </div>
            <div className="form-group col-md-12">
              <label>Champ personnalisable 2</label>
              <div className="form-row">
                <Multilingual
                  lang={mail_placeholder_2}
                  type="textarea"
                  handleLang={(e) => this.handleLang(e, "mail_placeholder_2")}
                />
              </div>
            </div>
            <div className="form-group col-md-12">
              <label>Champ personnalisable 3</label>
              <div className="form-row">
                <Multilingual
                  lang={mail_placeholder_3}
                  type="textarea"
                  handleLang={(e) => this.handleLang(e, "mail_placeholder_3")}
                />
              </div>
            </div>
            <div className="form-group col-md-12">
              <label>Champ personnalisable 4</label>
              <div className="form-row">
                <Multilingual
                  lang={mail_placeholder_4}
                  type="textarea"
                  handleLang={(e) => this.handleLang(e, "mail_placeholder_4")}
                />
              </div>
            </div>
            <div className="form-group col-md-12">
              <label>Champ personnalisable 5</label>
              <div className="form-row">
                <Multilingual
                  lang={mail_placeholder_5}
                  type="textarea"
                  handleLang={(e) => this.handleLang(e, "mail_placeholder_5")}
                />
              </div>
            </div>
            Filtres
            <input
              className="form-control mb-1"
              type="text"
              name="filter_text"
              onChange={this.filterComponentsList}
              placeholder="Rechercher un composant"
            />
            <select
              name="filter_selected"
              className="form-control browser-default custom-select"
              onChange={this.filterComponentsList}
              value={filter_selected}
            >
              <option value="">Tous les types</option>
              <option value="display">
                {translateComponentType("display")}
              </option>
              <option value="request">
                {" "}
                {translateComponentType("request")}
              </option>
              <option value="logistic">
                {" "}
                {translateComponentType("logistic")}
              </option>
              <option value="moment">
                {" "}
                {translateComponentType("moment")}
              </option>
            </select>
            {this.props.componentsList.map((component) =>
              (component.data.componentType === filter_selected ||
                filter_selected === "") &&
                component.data.name
                  .toLowerCase()
                  .indexOf(filter_text.toLowerCase()) > -1 ? (
                <MDBInput
                  label={
                    component.data.name +
                    " (" +
                    translateComponentType(component.data.componentType) +
                    ")"
                  }
                  onClick={(e) => this.check(e, component)}
                  filled
                  checked={
                    componentsChecked.filter(
                      (componentChecked) => componentChecked.id === component.id
                    ).length > 0
                      ? true
                      : false
                  }
                  id={`id-${component.id}`}
                  key={`key-${component.id}`}
                  type="checkbox"
                />
              ) : null
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="default" onClick={this.validate}>
              Valider
            </Button>
            <Button color="default" onClick={this.toggle}>
              Fermer
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default PackagesEditor;
