import React from "react";
import TextField from "@mui/material/TextField";
import "./Segments.css";
import { SegmentSelect } from "./SegmentSelect";
import debounce from "lodash.debounce";
import { languagesList } from "../../../../common/Translations";
import AutocompleteSelect from "./AutocompleteSelect";
import { SegmentMultipleSelectChip } from "../../components/SegmentMultipleSelectChip";
import { toast } from "react-toastify";
import { AddConditionButton } from "./AddConditionButton";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
const segmentOptions = [
  {
    label: "Type de segment",
    value: "",
  },
  {
    label: "Réponse à un composant",
    value: "component_response",
  },
  {
    label: "Nom",
    value: "name",
  },
  {
    label: "Société",
    value: "society",
  },
  {
    label: "Langue",
    value: "language",
  },
  {
    label: "Pays",
    value: "country",
  },
  {
    label: "Invité par",
    value: "invited_by",
  },
  {
    label: "Package",
    value: "package",
  },
  {
    label: "Droit accompagnant",
    value: "can_have_follower",
  },
  {
    label: "TAG",
    value: "tags",
  },
];

function SegmentsConditions({
  segmentsList,
  setSegmentsList,
  segmentType,
  segmentId,
  segmentConditions,
  setSegmentConditions,
}) {
  const conditionOptions = [
    {
      label: "Conditions",
      value: "",
    },
    {
      label: "Est",
      value: "is",
    },
    {
      label: "N'est pas",
      value: "is_not",
    },
    {
      label: "Contient",
      value: "include",
    },
    {
      label: "Ne contient pas",
      value: "not_include",
    },
    {
      label: "Est vide",
      value: "is_empty",
    },
    {
      label: "N'est pas vide",
      value: "is_not_empty",
    },
  ];

  const handleConditions = (value) => {
    setSegmentConditions(value);

    const segmentIndex = segmentsList.findIndex(
      (segment) => segment.id === segmentId && segment.type === segmentType
    );

    if (segmentIndex > -1) {
      setSegmentsList((prev) => {
        const newSegmentsList = [...prev];
        const segment = { ...prev[segmentIndex] };
        segment.condition = value;
        // For condition: include and notInclude set value to null
        switch (segment.condition) {
          case "is_empty":
          case "is_not_empty":
            segment.value = null;
            break;
          default:
            break;
        }
        newSegmentsList[segmentIndex] = segment;
        return newSegmentsList;
      });
    }
  };

  return (
    <SegmentSelect
      selectedValue={segmentConditions}
      handleSelectedValue={handleConditions}
      options={conditionOptions}
      segmentsList={segmentsList}
      id="segment-conditions"
    />
  );
}

function SegmentConditionsInput({
  value,
  setValue,
  segmentsList,
  setSegmentsList,
  segmentType,
  segmentId,
}) {
  const handleChange = React.useMemo(
    () =>
      debounce((value) => {
        const segmentIndex = segmentsList.findIndex(
          (segment) => segment.id === segmentId && segment.type === segmentType
        );

        if (segmentIndex > -1) {
          setSegmentsList((prev) => {
            const newSegmentsList = [...prev];
            const segment = { ...prev[segmentIndex] };
            segment.value = value;
            newSegmentsList[segmentIndex] = segment;
            return newSegmentsList;
          });
        }
      }, 300),
    []
  );

  React.useEffect(() => {
    return () => {
      handleChange.cancel();
    };
  }, [handleChange]);

  return (
    <TextField
      value={value}
      onChange={({ target: { value } }) => {
        setValue(value);
        handleChange(value);
      }}
      placeholder="Ecrivez la valeur..."
      size="small"
      sx={{
        m: 1,
        height: "fit-content",
        minWidth: "250px",
        backgroundColor: "#d81b60",
        borderRadius: "0.7rem",
        color: "green",
        border: "1px solid #000000 !important",
        "& .MuiOutlinedInput-input": {
          color: "#FFFFFF",
          ":focus": {
            borderRadius: "0.7rem",
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& ::placeholder": {
          color: "white",
          fontStyle: "italic",
          opacity: 1,
        },
        "& :focus": {
          borderBottom: 0,
          boxShadow: "none",
        },
      }}
      id="input-outlined-basic-segment-condition"
      variant="outlined"
    />
  );
}

function NewSegment({
  segment,
  segmentsList,
  setSegmentsList,
  segmentOptions,
  countryOptions,
  packageOptions,
  tagsOptions,
  componentsOptions,
  guests,
  eventComponents,
  activateDisabledOptions,
}) {
  const [selectedSegmentType, setSelectedSegmentType] = React.useState("");
  const [selectedName, setSelectedName] = React.useState("");
  const [selectedCompany, setSelectedCompany] = React.useState("");
  const [selectedLanguage, setSelectedLanguage] = React.useState("");
  const [selectedCountry, setSelectedCountry] = React.useState("");
  const [selectedPackage, setSelectedPackage] = React.useState("");
  const [selectedTag, setSelectedTag] = React.useState([]);
  const [selectedFollower, setSelectedFollower] = React.useState("");
  const [segmentConditions, setSegmentConditions] = React.useState("");
  const [selectedComponent, setSelectedComponent] = React.useState("");
  const [selectedInvitedByGuest, setSelectedInvitedByGuest] =
    React.useState(null);
  const [selectedComponentResponse, setSelectedComponentResponse] =
    React.useState([]);

  // const genericSelectOptions = [
  //   { value: "all", label: "Tous" },
  // ];

  const languagesOptions = languagesList.map((lang) => ({
    value: lang.code,
    label: lang.label,
  }));

  const followersOptions = [
    {
      value: "yes",
      label: "Invités avec le droit d'être accompagné",
    },
    {
      value: "no",
      label: "Invités sans le droit d'être accompagné",
    },
  ];

  React.useEffect(() => {
    if (segment) {
      setSelectedSegmentType(segment.type);
      switch (segment.type) {
        case "name":
        case "society":
          setSegmentConditions(segment.condition);
          switch (segment.condition) {
            case "is":
            case "is_not":
            case "include":
            case "not_include":
              if (segment.type === "name") setSelectedName(segment.value);
              else setSelectedCompany(segment.value);
              break;
            default:
              break;
          }
          break;
        case "language":
          setSelectedLanguage(segment.value);
          break;
        case "country":
          setSelectedCountry(segment.value);
          break;
        case "package":
          setSelectedPackage(segment.value);
          break;
        case "can_have_follower":
          setSelectedFollower(segment.value);
          break;
        case "invited_by":
          setSelectedInvitedByGuest(segment.value);
          break;
        case "tags":
          setSelectedTag(segment.value || []);
          break;
        case "component_response":
          setSelectedComponentResponse(segment.value || []);
          break;
        default:
          break;
      }
    }
  }, [segment]);

  const handleSegmentsTypes = (segmentType) => {
    setSelectedSegmentType(segmentType);

    const segmentIndex = segmentsList.findIndex((seg) => seg.id === segment.id);

    if (segmentIndex > -1) {
      setSegmentsList((prev) => {
        const newSegmentsList = [...prev];
        const segment = newSegmentsList[segmentIndex];
        if (segment.type !== segmentType) {
          newSegmentsList[segmentIndex] = {
            ...segment,
            type: segmentType,
            condition: null,
            value: null,
          };
          setSegmentConditions("");
        } else {
          newSegmentsList[segmentIndex] = {
            ...segment,
            type: segmentType,
          };
        }

        return newSegmentsList;
      });
    }
  };

  const handleSegmentsValueFields = (value) => {
    switch (segment.type) {
      case "language":
        setSelectedLanguage(value);
        break;
      case "country":
        setSelectedCountry(value);
        break;
      case "package":
        setSelectedPackage(value);
        break;
      case "can_have_follower":
        setSelectedFollower(value);
        break;
      case "invited_by":
        setSelectedInvitedByGuest(value);
        break;
      //   case "tags":
      //     setSelectedTag(value);
      //     break;
      //   case "component_response":
      //     setSelectedComponent(value);
      //     break;
      default:
        break;
    }

    const segmentFound = segmentsList.find((seg) => seg.id === segment.id);

    if (segmentFound) {
      setSegmentsList((prev) => {
        const filteredSegments = prev.filter(
          (seg) => seg.id !== segmentFound.id
        );
        const segment = { ...segmentFound };
        segment.value = value;
        return [...filteredSegments, segment];
      });
    }
  };

  const handleComponents = (value) => {
    setSelectedComponent(value);

    const segmentFound = segmentsList.find((seg) => seg.id === segment.id);

    if (segmentFound) {
      setSegmentsList((prev) => {
        const filteredSegments = prev.filter(
          (seg) => seg.id !== segmentFound.id
        );
        const segment = { ...segmentFound };
        segment.condition = value;
        segment.value = null;
        setSelectedComponentResponse([]);
        return [...filteredSegments, segment];
      });
    }
  };

  const handleComponentResponse = (value) => {
    value = typeof value === "string" ? value.split(",") : value;
    value = value.length > 0 ? value : null;

    switch (segment.type) {
      case "tags":
        setSelectedTag(value || []);
        break;
      case "component_response":
        setSelectedComponentResponse(value || []);
        break;
      default:
        break;
    }

    const segmentFound = segmentsList.find((seg) => seg.id === segment.id);

    if (segmentFound) {
      setSegmentsList((prev) => {
        const filteredSegments = prev.filter(
          (seg) => seg.id !== segmentFound.id
        );
        const segment = { ...segmentFound };
        segment.value = value;
        return [...filteredSegments, segment];
      });
    }
  };

  const getComponentResponses = () => {
    const componentFound = eventComponents.find(
      (comp) => comp.id === segment.condition
    );

    let componentResponsesOptions = [];

    if (componentFound) {
      try {
        componentFound.data.reponse.options.forEach((rep) => {
          const res = rep.value ? rep.value.split(";") : [];
          if (res.length > 0) {
            res.forEach((val) =>
              componentResponsesOptions.push({
                value: val,
                label: `${val} - (${rep.label})`,
              })
            );
          }
        });
      } catch (error) {
        console.log(error);
        return [];
      }
    }

    return componentResponsesOptions;
  };

  const getSegmentComponents = () => {
    switch (segment.type) {
      case "name":
      case "society":
        switch (segment.condition) {
          case "is":
          case "is_not":
          case "include":
          case "not_include":
            return (
              <>
                <div>
                  <SegmentsConditions
                    segmentsList={segmentsList}
                    setSegmentsList={setSegmentsList}
                    segmentType={segment.type}
                    segmentId={segment.id}
                    segmentConditions={segmentConditions}
                    setSegmentConditions={setSegmentConditions}
                  />
                </div>
                <div>
                  <SegmentConditionsInput
                    value={
                      segment.type === "name" ? selectedName : selectedCompany
                    }
                    setValue={
                      segment.type === "name"
                        ? setSelectedName
                        : setSelectedCompany
                    }
                    segmentsList={segmentsList}
                    setSegmentsList={setSegmentsList}
                    segmentType={segment.type}
                    segmentId={segment.id}
                  />
                </div>
              </>
            );
          default:
            return (
              <div key={`segment-condition-default`}>
                <SegmentsConditions
                  segmentsList={segmentsList}
                  setSegmentsList={setSegmentsList}
                  segmentType={segment.type}
                  segmentId={segment.id}
                  segmentConditions={segmentConditions}
                  setSegmentConditions={setSegmentConditions}
                />
              </div>
            );
        }
      case "language":
        return (
          <SegmentSelect
            options={languagesOptions}
            selectedValue={selectedLanguage}
            segmentsList={segmentsList}
            handleSelectedValue={handleSegmentsValueFields}
            id="segment-type-language"
          />
        );
      case "country":
        return (
          <SegmentSelect
            options={countryOptions}
            selectedValue={selectedCountry}
            segmentsList={segmentsList}
            handleSelectedValue={handleSegmentsValueFields}
            id="segment-type-country"
          />
        );
      case "package":
        return (
          <SegmentSelect
            options={packageOptions}
            selectedValue={selectedPackage}
            segmentsList={segmentsList}
            handleSelectedValue={handleSegmentsValueFields}
            id="segment-type-package"
          />
        );
      case "tags":
        return (
          <SegmentMultipleSelectChip
            options={tagsOptions}
            id={"segment-type-tags-multi-select"}
            selectedValue={selectedTag}
            handleSelectedValue={handleComponentResponse}
            bgColor="#d81b60"
            borderColor="#000000"
            chipTextColor="#ffffff"
            optionsTextColor="#000000"
            optionsBgColor="#e1dfdf"
            optionsBorder="1px solid #d81b60"
          />
        );
      case "can_have_follower":
        return (
          <SegmentSelect
            options={followersOptions}
            selectedValue={selectedFollower}
            segmentsList={segmentsList}
            handleSelectedValue={handleSegmentsValueFields}
            id="segment-type-follower"
          />
        );
      case "component_response":
        if (segment.condition) {
          const componentResponsesOptions = getComponentResponses();

          return (
            <>
              <SegmentSelect
                options={componentsOptions}
                selectedValue={selectedComponent}
                segmentsList={segmentsList}
                handleSelectedValue={handleComponents}
                id="segment-type-component"
              />

              <SegmentMultipleSelectChip
                options={componentResponsesOptions}
                id={"segment-component-multi-select-chip"}
                selectedValue={selectedComponentResponse}
                handleSelectedValue={handleComponentResponse}
                bgColor="#d81b60"
                borderColor="#000000"
                chipTextColor="#ffffff"
                optionsTextColor="#000000"
                optionsBgColor="#e1dfdf"
                optionsBorder="1px solid #d81b60"
              />
            </>
          );
        } else {
          return (
            <SegmentSelect
              options={componentsOptions}
              selectedValue={selectedComponent}
              segmentsList={segmentsList}
              handleSelectedValue={handleComponents}
              id="segment-type-component"
            />
          );
        }
      case "invited_by":
        return (
          <AutocompleteSelect
            options={guests}
            selectedValue={selectedInvitedByGuest}
            handleSelectedValue={handleSegmentsValueFields}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="segment-row-container">
      <SegmentSelect
        options={segmentOptions}
        selectedValue={selectedSegmentType}
        handleSelectedValue={handleSegmentsTypes}
        segmentsList={segmentsList}
        id="segment-types"
        activateDisabledOptions={activateDisabledOptions}
      />
      {getSegmentComponents()}
    </div>
  );
}

function SegmentContainer({
  segmentsList,
  setSegmentsList,
  packageOptions,
  tagsOptions,
  countryOptions,
  componentsOptions,
  eventComponents,
  guests,
  title,
  checkSegmentsData,
  segmentPrefix,
  activateDisabledOptions = false,
}) {
  const idCounter = React.useRef(0);

  React.useEffect(() => {
    if (segmentsList) idCounter.current = segmentsList.length;
  }, []);

  const handleRemoveSegment = (segment) => {
    setSegmentsList((prev) => {
      let index = 0;
      let res = prev.filter((seg) => seg.id !== segment.id);
      res = res.map((seg) => {
        index += 1;
        return { ...seg, id: index };
      });
      idCounter.current = index;
      return res;
    });
  };

  const handleAddNewSegment = (e) => {
    e.preventDefault();

    const isSegmentsDataValid = checkSegmentsData(segmentsList, "create", "");

    if (isSegmentsDataValid === "valid") {
      idCounter.current = idCounter.current + 1;
      setSegmentsList((prev) => [
        ...prev,
        {
          id: idCounter.current,
          type: "",
          condition: null,
          value: null,
        },
      ]);
    } else {
      toast.warning("Remplissez les champs necéssaires");
    }
  };

  return (
    <div className="segment_main">
      <h4>{title}</h4>
      <div className="main-content">
        {segmentsList &&
          segmentsList.map((segment, index) => {
            return (
              <div className="segment-row" key={segment.id}>
                {index !== 0 ? (
                  <div className="and-condition-prefix">
                    <p>{segmentPrefix}</p>
                  </div>
                ) : (
                  <div style={{ visibility: "hidden" }}>
                    <p>{segmentPrefix}</p>
                  </div>
                )}
                <NewSegment
                  segment={segment}
                  segmentsList={segmentsList}
                  segmentOptions={segmentOptions}
                  setSegmentsList={setSegmentsList}
                  countryOptions={countryOptions}
                  packageOptions={packageOptions}
                  tagsOptions={tagsOptions}
                  componentsOptions={componentsOptions}
                  guests={guests}
                  eventComponents={eventComponents}
                  activateDisabledOptions={activateDisabledOptions}
                />
                <div>
                  <IconButton
                    onClick={() => handleRemoveSegment(segment)}
                    size="1rem"
                  >
                    <DeleteIcon className="segment-remove-btn" />
                  </IconButton>
                </div>
              </div>
            );
          })}

        <div className="addConditionButton">
          <div style={{ visibility: "hidden" }}>
            <p>{segmentPrefix}</p>
          </div>
          <AddConditionButton
            onClick={handleAddNewSegment}
            text="Ajouter une condition"
            type="button"
          />
        </div>
      </div>
    </div>
  );
}

export default SegmentContainer;
