import React from "react";
import { Modal, ModalBody, ModalFooter } from "mdbreact";
import { webPageTranslations } from "./Translations";

class CGVModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle = (e) => {
    if (e) e.preventDefault();
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const { layout, code, cgv, color } = this.props;

    return (
      <span>
        <span
          className="pointer text-center"
          style={{
            color,
          }}
          onClick={(e) => this.toggle(e)}
        >
          {webPageTranslations[code].cgv}
        </span>

        <Modal
          size="lg"
          isOpen={this.state.modal}
          toggle={(e) => this.toggle(e)}
        >
          <div
            className="col-md-12"
            dangerouslySetInnerHTML={{
              __html: cgv.find((lang) => lang.code === code).value,
            }}
          />
          <ModalBody>
            <ModalFooter>
              <button
                className="btn"
                style={{
                  backgroundColor: layout.mainColor,
                  color: layout.secondaryColor,
                }}
                onClick={(e) => this.toggle(e)}
              >
                {webPageTranslations[code].close}
              </button>
            </ModalFooter>
          </ModalBody>
        </Modal>
      </span>
    );
  }
}

export default CGVModal;
