import React from "react";
import { CustomMuiButton } from "../../admin/_modules/components/CustomMuiButton";
import ReactExport from "react-export-excel";

export function ExportContactsByDelegataireByEvent({
  guests,
  componentsListForExport,
  selectedEvent,
  user,
  eventResponses,
  followers,
  formResponseLayout,
}) {

  const [isDisabled, setIsDisabled] = React.useState(guests.length > 0 ? false : true);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

  React.useEffect(() => {
    setIsDisabled(guests.length > 0 ? false : true);
  }, [guests.length])


  // Lignes
  const createRow = async (guest) => {
    let company = null;

    if (guest.contact && guest.contact.company) {
      company = guest.contact.company.name;
    }

    let guestData = [guest.lastname, guest.firstname, guest.email, company];

    const guestReponse = eventResponses.find(
      (reponse) => reponse.guest_id === Number(guest.id)
    );

    formResponseLayout.layout.itemsMain.forEach((component) => {
      const eventComponent = componentsListForExport.find(
        (eventComponent) => eventComponent.id === component.id
      );
      if (eventComponent) {
        const reponses = guestReponse
          ? guestReponse.responses && guestReponse.responses.length > 0
            ? guestReponse.responses.filter(
              (response) => response.id === component.id
            )
            : null
          : null;

        if (
          (eventComponent.data.reponse.htmlType === "radio" ||
            eventComponent.data.reponse.htmlType === "checkbox") &&
          eventComponent.data.reponse.displayType !== "agenda"
        ) {
          if (
            eventComponent.data.quotas &&
            eventComponent.data.quotas.delegataires &&
            eventComponent.data.quotas.delegataires.find(
              (del) => del.userId === user.id
            )
          ) {
            const options = eventComponent.data.reponse.options[0].value;

            if (options) {
              options.split(";").forEach((option, key) => {
                if (!reponses) {
                  guestData.push("n/c");
                } else {
                  const singleOption = reponses.find(
                    (reponse) => Number(reponse.key) === key
                  );

                  if (singleOption) {
                    guestData.push("1");
                  } else {
                    guestData.push("0");
                  }
                }
              });
            }
          }
        } else {
          if (eventComponent.data.reponse.htmlType === "text") {
            if (reponses && reponses.length > 0) {
              guestData.push(reponses[0].label);
            } else {
              guestData.push("n/c");
            }
          } else {
            guestData.push(reponses && reponses.length > 0 ? "1" : "n/c");
          }
        }
      }
    });

    return guestData;
  };


  const guestsWithFollowers = [];

  // Ajouter les accompagnant des invités
  guests.forEach((guest) => {
    guestsWithFollowers.push(guest);
    followers.forEach((follower) => {
      if (follower.invited_by && follower.invited_by === guest.id) {
        guestsWithFollowers.push(follower);
      }
    });
  });

  // Titres colonne
  let columns = ["nom", "prénom", "email", "société"];
  let subColumns = ["", "", "", ""];

  const { itemsMain } = formResponseLayout.layout;

  itemsMain.forEach((component) => {
    const eventComponent = componentsListForExport.find(
      (eventComponent) => eventComponent.id === component.id
    );

    if (eventComponent) {

      if (
        (eventComponent.data.reponse.htmlType === "radio" ||
          eventComponent.data.reponse.htmlType === "checkbox") &&
        eventComponent.data.reponse.displayType !== "agenda"
      ) {
        // Vérifier si les invités du délégataire ont accès au composant
        if (
          eventComponent.data.quotas &&
          eventComponent.data.quotas.delegataires &&
          eventComponent.data.quotas.delegataires.find(
            (del) => del.userId === user.id)
        ) {
          const options = eventComponent.data.reponse.options.find(
            (option) => option.code === "FR"
          ).value;

          if (options) {
            options.split(";").forEach((option) => {
              columns.push(eventComponent.data.name);
              subColumns.push(option);
            });
          }
        }
      } else {
        columns.push(eventComponent.data.name);
        subColumns.push("");
      }
    }
  });


  const tmpData = [];

  // Ajout des réponses des invités
  guestsWithFollowers.forEach(async (guest) => {
    tmpData.push(await createRow(guest));
  });

  // Ajouter les types de réponses aux composants
  tmpData.unshift(subColumns);

  const multiDataSet = [
    {
      columns,
      data: tmpData,
    }
  ]

  // console.log('🤓 ~ multiDataSet ~', multiDataSet);
  return (
    <>
      <ExcelFile
        filename={"export_contacts_delegataire_[" +
          user.firstname +
          "_" +
          user.lastname +
          "]_evenement_[" +
          selectedEvent.data.name +
          "]"}
        element={
          <div className="button-container">
            <CustomMuiButton
              type="button"
              text="EXPORT INVITÉS"
              minHeight={50}
              textColor="#ffffff"
              buttonBgColor="#d81b60"
              buttonHoverBgColor="#AF1242"
              buttonHoverBorderColor="#0062cc"
              buttonActiveBgColor="#d81b60"
              disable={isDisabled}
            />
          </div>
        }
      >
        <ExcelSheet dataSet={multiDataSet} name={"export délégataires"} />
      </ExcelFile>
    </>
  );
}
