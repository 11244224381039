import React from "react";
import ApiService from "../../../../services/ApiService";
import GabaritSelector from "./GabaritSelector";
import FormEditor from "./FormEditor";
import { toast } from "react-toastify";
import Loader from "../../../../common/Loader";

class FormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.chooseGabarit = this.chooseGabarit.bind(this);
    this.saveForm = this.saveForm.bind(this);

    this.state = {
      composantsList: [],
      isLoading: false,
      form: {
        evenement_id: this.props.match.params.eventid,
        name: "Nouvelle page web",

        layout: {
          documentType: "website",
          itemsMain: [],
          itemsSide: [],
          fontFamily: "Open Sans",
          backgroundColor: "#ffffff",
          mainColor: "#f49b42",
          textColorMain: "#000000",
          textColorSide: "#000000",
          secondaryColor: "#ffffff",
          titleColorMain: "#000000",
          titleColorSide: "#000000",
          titleBackgroundColorMain: "#ffffff",
          titleBackgroundColorSide: "#ffffff",
          backgroundColorMain: "#ffffff",
          backgroundColorSide: "#ffffff",
          menu: {
            items: [],
            style: {
              backgroundColor: "#ffffff",
              color: "#000000",
              title: { backgroundColor: "#ffffff", color: "#000000" },
            },
          },
        },
      },
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    const composantsList = await ApiService.awaitRequest(
      {},
      "components/byevenement/" + this.props.match.params.eventid,
      "get"
    );
    //console.log(composantsList);
    if (this.props.match.params.formid) {
      const form = await ApiService.awaitRequest(
        {},
        "evenement_forms/" + this.props.match.params.formid,
        "get"
      );
      if (form) {
        // Ceci est fait à cause d'un problème lorsqu'on duplique un évènement,
        //on duplique aussi ses composants et donc si on change le nom d'un composant,
        //ou qu'on le supprime, le formulaire qui existe dans les pages web ne se mettaient pas à jour avec les nouvelles données des composants,
        // désormais il suffit de retourner sur la page web et de la sauvegarde
        var updateItemsMain1 = composantsList.filter((e) =>
          form.layout.itemsMain.some((layout) => layout.id === e.id)
        );
        var updateItemsMain2 = form.layout.itemsMain.filter(
          (e) => !updateItemsMain1.some((layout) => layout.id === e.id)
        );
        var itemsMainStillExist = composantsList.filter((e) =>
          updateItemsMain2.some((layout) => layout.id === e.id)
        );
        var updateItemsMain = updateItemsMain1.concat(itemsMainStillExist);
        updateItemsMain = updateItemsMain.map((e) =>
          form.layout.itemsMain.filter((layout) => layout.id === e.id) &&
          form.layout.itemsMain.filter((layout) => layout.id === e.id)[0]
            ? {
                ...e,
                displayTitle: form.layout.itemsMain.filter(
                  (layout) => layout.id === e.id
                )[0].displayTitle,
                displayDescription: form.layout.itemsMain.filter(
                  (layout) => layout.id === e.id
                )[0].displayDescription,
              }
            : e
        );
        // Récupérer les ID de form.layout.itemsMain
        var itemsMainTableauIDs = form.layout.itemsMain.map((item) => item.id);
        // Trier les itemsMain tableau en utilisant les ID de form.layout.itemsMain
        updateItemsMain.sort(function (a, b) {
          var indexA = itemsMainTableauIDs.indexOf(a.id);
          var indexB = itemsMainTableauIDs.indexOf(b.id);
          return indexA - indexB;
        });

        var updateItemsSide1 = composantsList.filter((e) =>
          form.layout.itemsSide.some((layout) => layout.id === e.id)
        );
        var updateItemsSide2 = form.layout.itemsSide.filter(
          (e) => !updateItemsSide1.some((layout) => layout.id === e.id)
        );
        var itemsSideStillExist = composantsList.filter((e) =>
          updateItemsSide2.some((layout) => layout.id === e.id)
        );
        var updateItemsSide = updateItemsSide1.concat(itemsSideStillExist);
        updateItemsSide = updateItemsSide.map((e) =>
          form.layout.itemsSide.filter((layout) => layout.id === e.id) &&
          form.layout.itemsSide.filter((layout) => layout.id === e.id)[0]
            ? {
                ...e,
                displayTitle: form.layout.itemsSide.filter(
                  (layout) => layout.id === e.id
                )[0].displayTitle,
                displayDescription: form.layout.itemsSide.filter(
                  (layout) => layout.id === e.id
                )[0].displayDescription,
              }
            : e
        );
        // Récupérer les ID de form.layout.itemsSide
        var itemsSideTableauIDs = form.layout.itemsSide.map((item) => item.id);
        // Trier les itemsSide tableau en utilisant les ID de form.layout.itemsSide
        updateItemsSide.sort(function (a, b) {
          var indexA = itemsSideTableauIDs.indexOf(a.id);
          var indexB = itemsSideTableauIDs.indexOf(b.id);
          return indexA - indexB;
        });

        // console.log(form.layout);
        console.log(updateItemsMain);
        console.log(updateItemsSide);
        this.setState({
          form: {
            ...form,
            layout: {
              ...form.layout,
              itemsMain: updateItemsMain,
              itemsSide: updateItemsSide,
            },
          },
          isLoading: false,
          composantsList,
        });
      } else {
        toast.error("Erreur lors du chargement", {
          autoClose: 3000,
        });
      }
    } else {
      this.setState({ isLoading: false, composantsList });
    }
  }

  saveForm(form) {
    const self = this;
    self.setState({ isLoading: true });

    ApiService.request(
      {
        ...form,
      },
      "evenement_forms/" + form.id,
      "put"
    )
      .then(function (data) {
        toast.success("Page web sauvegardée", {
          autoClose: 3000,
        });

        if (!self.props.match.params.formid) {
          self.props.history.push(
            "/admin/events/edit/" +
              self.props.match.params.eventid +
              "/forms/edit/" +
              data.id
          );
        }
        self.setState({
          form: { ...data },
          isLoading: false,
        });
      })
      .catch(function (error) {
        toast.error("Erreur lors de l'enregistrement", {
          autoClose: 3000,
        });
      });
  }

  chooseGabarit(gabarit) {
    const self = this;

    ApiService.request(
      {
        ...self.state.form,
        layout: { ...self.state.form.layout, gabarit },
      },
      "evenement_forms/",
      "post"
    )
      .then(function (data) {
        self.setState({
          form: { ...data },
        });

        toast.success("Formulaire crée", {
          autoClose: 3000,
        });
      })
      .catch(function () {
        toast.error("Erreur lors de l'enregistrement", {
          autoClose: 3000,
        });
      });
  }

  render() {
    const { composantsList, isLoading, form } = this.state;
    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="col-md-12">
        {!form.layout.gabarit ? (
          <GabaritSelector chooseGabarit={this.chooseGabarit} />
        ) : (
          <FormEditor
            composantsList={composantsList}
            onSave={this.saveForm}
            form={form}
            default_language={this.props.default_language}
          />
        )}
      </div>
    );
  }
}

export default FormContainer;
