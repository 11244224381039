import React, { useEffect } from "react";
import ContactsList from "./ContactsList";
import "./contacts.css";

export default function ContactsContainer(props) {
  useEffect(() => {}, [props.contacts]);

  return (
    <div>
      <ContactsList
        {...props}
        updateContacts={props.updateContacts}
        contacts={props.contacts}
      />
    </div>
  );
}
