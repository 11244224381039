import React, { useState } from "react";
import { Modal } from "mdbreact";
import { toast } from "react-toastify";
import Loader from "./Loader";
import ApiService from "../services/ApiService";
import UserInformationComponent from "./payment_component/UserInformationComponent";
import GeneralTermsComponent from "./payment_component/GeneralTermsComponent";
import InvoiceDetails from "./InvoiceDetails";
import { webPageTranslations } from "./Translations";
import CheckoutPaymentInvoice from "./payment_component/CheckoutPaymentInvoice";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import uniq from "lodash/uniq";

const REFUND_INVOICE_STATUS = {
  success: "Remboursé",
  failed: "Échec",
  waiting: "En attente de validation",
};

const CheckOutForm = (props) => {
  const [checkoutPaymentInvoice, setCheckoutPaymentInvoice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentOrder, setPaymentOrder] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [validateOrderBtnLoading, setValidateOrderBtnLoading] = useState(false);
  const {
    code,
    handleChange,
    lastname,
    firstname,
    country,
    address_more,
    company,
    city,
    address,
    zip_code,
    totalTTC,
    form,
    cgv,
    modifyForm,
    invoice,
    allowCheckForAdmin,
    checkAllComponentsAvailableSpace,
    isAdmin,
    allowCheckForUser,
    sendResponses,
    sendResponses2,
    chargeMoney,
    paymentMode,
    setPaymentModeState,
    element,
    validateForm,
    show_payment_error,
    lastInvoice,
    contact,
    event,
    stripeKey,
    guestInvoices,
    loadData,
    updateEventFormMessage,
    responsesToRefund,
    willNotJoin,
    filterOldNewReponsesToRefund,
  } = props;
  console.log("🚀 ~ CheckOutForm ~ invoice:", invoice);
  const stripePromise = loadStripe(stripeKey);

  const options = {
    clientSecret,
  };

  const chargeRefund = async (
    orderData,
    amountToRefund,
    stripe_transaction_id,
    invoiceToRefundNumber
  ) => {
    const { event, code, guest } = props;

    //? Make vat negative
    orderData.data.total_vat_0 =
      orderData.data.total_vat_0 > 0
        ? -Math.abs(orderData.data.total_vat_0)
        : orderData.data.total_vat_0;
    orderData.data.total_vat_5 =
      orderData.data.total_vat_5 > 0
        ? -Math.abs(orderData.data.total_vat_5)
        : orderData.data.total_vat_5;
    orderData.data.total_vat_10 =
      orderData.data.total_vat_10 > 0
        ? -Math.abs(orderData.data.total_vat_10)
        : orderData.data.total_vat_10;
    orderData.data.total_vat_20 =
      orderData.data.total_vat_20 > 0
        ? -Math.abs(orderData.data.total_vat_20)
        : orderData.data.total_vat_20;

    let refundErrorDetails = null;
    await ApiService.awaitRequest(orderData, "contacts/order", "post").then(
      async (res) => {
        if (res) {
          const charge = await ApiService.awaitRequest(
            {
              entity_id: event.children_entity_id,
              amount: -Math.abs(Number(amountToRefund)),
              stripe_transaction_id: stripe_transaction_id,
              order_id: res.id,
            },
            "contacts/charge",
            "post"
          );

          if (charge) {
            if (charge.error_refund) {
              if (charge.error_refund.includes("has already been refunded.")) {
                show_payment_error(true);
              } else if (
                charge.error_refund.includes("is greater than charge amount")
              ) {
                //TODO: SEND REPORT
                refundErrorDetails = {
                  error_type: "stripe",
                  status: REFUND_INVOICE_STATUS.failed,
                  event_id: event.id,
                  event_name: event.data.name,
                  order_id: res.id,
                  guest: guest,
                  error_message: charge.error_refund,
                  stripe_transaction_id: stripe_transaction_id,
                  invoice_number: invoiceToRefundNumber,
                  save_response: true,
                };
                toast.error(
                  webPageTranslations[code ? code : "FR"].refund_message_error,
                  {
                    autoClose: 4000,
                  }
                );
              } else {
                //TODO: Save guest response in DB and send an email (with guest infos) to admin for manual refund
                //! HOW DO I MANAGE MULTIPLE REFUNDS WITH DIFFERENT SUCCESS STATUS
                //? GET INVOICE INFO INSIDE AN ARRAY [{invoice_data, status: failed, ...}]
                refundErrorDetails = {
                  error_type: "stripe",
                  status: REFUND_INVOICE_STATUS.failed,
                  event_id: event.id,
                  event_name: event.data.name,
                  order_id: res.id,
                  guest: guest,
                  error_message: charge.error_refund,
                  stripe_transaction_id: stripe_transaction_id,
                  invoice_number: invoiceToRefundNumber,
                  save_response: true,
                };
                // toast.error(
                //   webPageTranslations[code ? code : "FR"].refund_message_error,
                //   {
                //     autoClose: 4000,
                //   }
                // );
              }
            } else {
              refundErrorDetails = {
                error_type: "stripe",
                status: REFUND_INVOICE_STATUS.success,
                event_id: event.id,
                event_name: event.data.name,
                order_id: res.id,
                guest: guest,
                error_message: null,
                stripe_transaction_id: stripe_transaction_id,
                invoice_number: invoiceToRefundNumber,
                save_response: true,
              };
            }
          } else {
            throw new Error("Échec de la transaction.");
          }
          return refundErrorDetails;
        } else {
          throw new Error("Échec de la création de la commande");
        }
      }
    );
    return refundErrorDetails;
  };

  function findBestCombination(newProducts, totalAmountToRefund) {
    let bestCombination = [];
    let bestTotal = 0;

    function findCombination(currentCombination, currentIndex, currentTotal) {
      if (currentTotal > totalAmountToRefund) {
        return; // Exit if the current total exceeds the target amount
      }

      if (currentTotal === totalAmountToRefund) {
        bestCombination = [...currentCombination];
        bestTotal = currentTotal;
        return;
      }

      if (currentTotal > bestTotal && currentTotal < totalAmountToRefund) {
        bestCombination = [...currentCombination];
        bestTotal = currentTotal;
      }

      for (let i = currentIndex; i < newProducts.length; i++) {
        findCombination(
          [...currentCombination, newProducts[i]],
          i + 1,
          currentTotal + Number(newProducts[i].priceTTC)
        );
      }
    }

    findCombination([], 0, 0);

    return bestCombination;
  }

  const getProductsToRefund = (
    products,
    initialComponentsResponses,
    componentsResponses,
    amountToRefund
  ) => {
    let ids = [];
    if (componentsResponses && initialComponentsResponses) {
      initialComponentsResponses.forEach((initialResponse) => {
        const newUserReponse = componentsResponses.find(
          (newResponse) =>
            newResponse.reponseId === initialResponse.reponseId &&
            newResponse.guestId === initialResponse.guestId
        );

        if (newUserReponse) {
          const componentsResponsesToRefund = filterOldNewReponsesToRefund(
            initialResponse.reponses,
            newUserReponse.reponses
          );

          ids = ids.concat(componentsResponsesToRefund.map((rep) => rep.id));
        }
      });
    }

    ids = uniq(ids); //! maybe its same id but different quantity => check quantity that match the amount

    if (ids.length > 0) {
      const newProducts = products.filter((product) => {
        const isIncluded = ids.includes(product.id);
        if (isIncluded) {
          product.quantity = getProductQuantity(
            product.quantity,
            amountToRefund,
            product.priceTTC
          );
        }
        return isIncluded;
      });

      const bestCombination = findBestCombination(newProducts, amountToRefund);

      return bestCombination;
    }

    return products;
  };

  //! I don't like the way this was coded, I have the feeling that it's not generic. Sorry for the futur dev debugging this.

  const getProductQuantity = (
    productQuantity,
    amountToRefund,
    singleProductPriceTTC
  ) => {
    const productPrice = amountToRefund / productQuantity;
    if (productQuantity <= 0) return 0;
    if (productPrice < Number(singleProductPriceTTC)) {
      productQuantity = productQuantity - 1;
      //TODO: recalculate quantity
      getProductQuantity(
        productQuantity,
        amountToRefund,
        singleProductPriceTTC
      );
    }
    return productQuantity;
  };

  const refundCheck = async (initialInvoice) => {
    const { guest, setGuest } = props;

    if (!guest) throw new Error("Guest not defined or null");

    //? Make vat negative
    initialInvoice.data.total_vat_0 =
      initialInvoice.data.total_vat_0 > 0
        ? -Math.abs(initialInvoice.data.total_vat_0)
        : initialInvoice.data.total_vat_0;
    initialInvoice.data.total_vat_5 =
      initialInvoice.data.total_vat_5 > 0
        ? -Math.abs(initialInvoice.data.total_vat_5)
        : initialInvoice.data.total_vat_5;
    initialInvoice.data.total_vat_10 =
      initialInvoice.data.total_vat_10 > 0
        ? -Math.abs(initialInvoice.data.total_vat_10)
        : initialInvoice.data.total_vat_10;
    initialInvoice.data.total_vat_20 =
      initialInvoice.data.total_vat_20 > 0
        ? -Math.abs(initialInvoice.data.total_vat_20)
        : initialInvoice.data.total_vat_20;

    //?  CREATE PROFORMA REFUND INVOICE
    const proformaRefundInvoice = await ApiService.awaitRequest(
      {
        ...initialInvoice,
        data: {
          ...initialInvoice.data,
          totalHT: -Math.abs(Number(initialInvoice.data.totalHT)),
          totalTTC: -Math.abs(Number(initialInvoice.data.totalTTC)),
          refund_id: initialInvoice.id,
          refund: null,
        },
        status: "pending",
      },
      "invoices",
      "post"
    );

    const responseData = await sendResponses2(true);
    const refundOrder = await ApiService.awaitRequest(
      //? Save responses
      {
        responses_data: responseData,
      },

      `contacts/saveGuestResponses`,
      "post"
    );

    if (refundOrder && proformaRefundInvoice) {
      const checks_to_refund = [
        ...guest.checks_to_refund,
        proformaRefundInvoice.id,
      ];
      setGuest({ ...guest, checks_to_refund });

      const res = await ApiService.awaitRequest(
        {
          checks_to_refund,
        },
        `guests/${guest.id}`,
        "put"
      );

      if (!res) throw new Error("Error during guest's update"); //!

      return {
        error_type: "check",
        status: REFUND_INVOICE_STATUS.success,
        event_id: event.id,
        event_name: event.data.name,
        order_id: refundOrder,
        guest: guest,
        error_message: null,
        stripe_transaction_id: null,
        invoice_number: initialInvoice.invoice_number,
        save_response: false,
      }; //? SUCCESS
    }
    return {
      error_type: "check",
      status: REFUND_INVOICE_STATUS.failed,
      event_id: event.id,
      event_name: event.data.name,
      order_id: refundOrder,
      guest: guest,
      error_message: "Erreur lors de la demande de remboursement du check",
      stripe_transaction_id: null,
      invoice_number: initialInvoice.invoice_number,
      save_response: false,
    }; //? FAILED
  };

  const deleteProformaInvoices = (guestInvoices) => {
    try {
      if (guestInvoices && guestInvoices.length) {
        guestInvoices.forEach(async (inv) => {
          if (
            inv.payment_mode === "check" &&
            inv.invoice_number &&
            inv.invoice_number.includes("_PROFORMA")
          ) {
            await ApiService.awaitRequest({}, `invoices/${inv.id}`, "delete");
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const refundNonComingGuest = async () => {
    const {
      event,
      contact,
      componentsResponses,
      initialComponentsResponses,
      guestInvoices,
    } = props;
    console.log("🚀 ~ refundNonComingGuest ~ initialComponentsResponses:", {
      initialComponentsResponses,
      componentsResponses,
    });
    try {
      setIsLoading(true);
      let filteredGuestInvoicesByTransactionId = guestInvoices.reduce(
        (accumulator, current) => {
          const stripe_transaction_id = current.stripe_transaction_id;
          const check_transaction_id = current.check_transaction_id;
          const is_proforma = current.invoice_number.includes("_PROFORMA");

          if (!is_proforma) {
            const existingSubArray = accumulator.find(
              (subArr) =>
                (subArr[0].payment_mode === "card" &&
                  subArr[0].stripe_transaction_id === stripe_transaction_id) ||
                (subArr[0].payment_mode === "check" &&
                  subArr[0].check_transaction_id === check_transaction_id)
            );

            if (existingSubArray) {
              existingSubArray.push(current);
            } else {
              accumulator.push([current]);
            }
          }

          return accumulator;
        },
        []
      );

      const promises = [];

      for (let i = 0; i < filteredGuestInvoicesByTransactionId.length; i++) {
        const promise = new Promise(async (resolve, reject) => {
          try {
            let status = null;
            if (filteredGuestInvoicesByTransactionId[i].length > 0) {
              //? Calcul some of amountToRefund
              let amountToRefund = 0;
              let amountToRefundHT = 0;
              filteredGuestInvoicesByTransactionId[i].forEach((iv) => {
                if (
                  (iv.payment_mode === "card" && iv.stripe_transaction_id) ||
                  (iv.payment_mode === "check" && iv.status === "paid")
                )
                  if (
                    iv.data.totalTTC &&
                    typeof iv.data.totalTTC === "number"
                  ) {
                    amountToRefund += iv.data.totalTTC;
                    amountToRefundHT += iv.data.totalHT;
                  }
              });

              if (amountToRefund > 0) {
                let correspondingInvoice = filteredGuestInvoicesByTransactionId[
                  i
                ].find((iv) => iv.data.totalTTC === amountToRefund);

                //? Find an invoice that amount is greater or equal to amount to refound
                if (!correspondingInvoice) {
                  correspondingInvoice = filteredGuestInvoicesByTransactionId[
                    i
                  ].find((iv) => iv.data.totalTTC >= amountToRefund);
                }

                if (correspondingInvoice) {
                  //? if index 0 payment mode is card all other items are card too same for check
                  const invoicesPaymentMode =
                    filteredGuestInvoicesByTransactionId[i][0]
                      ? filteredGuestInvoicesByTransactionId[i][0].payment_mode
                      : "";

                  //TODO: UPDATE INVOICE'S PRODUCTS LIST
                  const products = getProductsToRefund(
                    correspondingInvoice.data.products,
                    initialComponentsResponses,
                    componentsResponses,
                    amountToRefund
                  );

                  correspondingInvoice.data.products = products;

                  if (invoicesPaymentMode === "card") {
                    const orderData = {
                      children_entity_id: event.children_entity_id,
                      evenement_id: event.id,
                      data: {
                        ...correspondingInvoice.data,
                        totalHT: -amountToRefundHT,
                        totalTTC: -amountToRefund,
                        componentsResponses: componentsResponses,
                        refund_id: null,
                        previousAmount: null,
                      },
                      responses_data: await sendResponses2(true),
                      contact_id: contact.id,
                      status: "pending",
                    };

                    //? RESET VAT BEFORE BALANCE
                    orderData.data.total_vat_0_before_balance = 0;
                    orderData.data.total_vat_5_before_balance = 0;
                    orderData.data.total_vat_10_before_balance = 0;
                    orderData.data.total_vat_20_before_balance = 0;
                    orderData.data.totalHT_before_balance = 0;
                    orderData.data.totalTTC_before_balance = 0;

                    try {
                      const refundErrorDetails = await chargeRefund(
                        orderData,
                        amountToRefund,
                        correspondingInvoice.stripe_transaction_id,
                        correspondingInvoice.invoice_number
                      );
                      if (refundErrorDetails) {
                        status = refundErrorDetails;
                        // throw new Error("Error occurred during refund");
                      } else {
                        status = null;
                      }
                    } catch (error) {
                      console.log(error);
                      status = {
                        error_type: "stripe",
                        status: REFUND_INVOICE_STATUS.failed,
                        event_id: event.id,
                        event_name: event.data.name,
                        order_id: null,
                        guest: props.guest,
                        error_message: error.message,
                        stripe_transaction_id:
                          correspondingInvoice.stripe_transaction_id,
                        invoice_number: correspondingInvoice.invoice_number,
                        save_response: false,
                      };
                    }
                  } else if (invoicesPaymentMode === "check") {
                    //! UPDATE
                    correspondingInvoice.data.totalHT = amountToRefundHT;
                    correspondingInvoice.data.totalTTC = amountToRefund;
                    correspondingInvoice.data.previousAmount = null;

                    //? RESET VAT BEFORE BALANCE
                    correspondingInvoice.data.total_vat_0_before_balance = 0;
                    correspondingInvoice.data.total_vat_5_before_balance = 0;
                    correspondingInvoice.data.total_vat_10_before_balance = 0;
                    correspondingInvoice.data.total_vat_20_before_balance = 0;
                    correspondingInvoice.data.totalHT_before_balance = 0;
                    correspondingInvoice.data.totalTTC_before_balance = 0;

                    try {
                      const refundErrorDetails = await refundCheck(
                        correspondingInvoice
                      );
                      if (refundErrorDetails) {
                        status = refundErrorDetails;
                        // throw new Error("Error occurred during refund");
                      } else {
                        status = null;
                      }
                    } catch (error) {
                      console.log(error);
                      status = {
                        error_type: "check",
                        status: REFUND_INVOICE_STATUS.failed,
                        event_id: event.id,
                        event_name: event.data.name,
                        order_id: null,
                        guest: props.guest,
                        error_message: error.message,
                        stripe_transaction_id: null,
                        invoice_number: correspondingInvoice.invoice_number,
                        save_response: false,
                      };
                    }
                  } else {
                    throw new Error("Type de paiement inconnu");
                  }
                } else
                  throw new Error(
                    "Facture correspondant au remboursement non trouvé"
                  );
              }
              // else {
              //   toast.warn("Aucune facture à rembourser.");
              // }
            }
            resolve(status);
          } catch (error) {
            reject(error);
          }
        });

        promises.push(promise);

        // Wait for the current promise to resolve or reject
        await promise;
      }

      const invoicesRefundedStatus = await Promise.all(promises);

      //TODO: FILTER invoicesRefundedStatus AND GET ERROR DETAILS
      const refundErrorDetails = invoicesRefundedStatus.filter(
        (value) => value
      );

      // refundErrorDetails.push({
      //   error_type: "stripe",
      //   status: REFUND_INVOICE_STATUS.failed,
      //   event_id: event.id,
      //   event_name: event.data.name,
      //   order_id: refundErrorDetails[0].order_id,
      //   guest: refundErrorDetails[0].guest,
      //   error_message: null,
      //   stripe_transaction_id: refundErrorDetails[0].stripe_transaction_id,
      //   invoice_number: "INV NUMBER",
      //   save_response: false,
      // });

      if (
        refundErrorDetails &&
        refundErrorDetails.length &&
        refundErrorDetails.some(
          (value) => value.status === REFUND_INVOICE_STATUS.failed
        )
      ) {
        //TODO: SEND refundErrorDetails to backend for email reporting
        toast.warning(
          "Remboursement non abouti. Nos équipes reviendront vers vous ultérieurement."
        );
        await ApiService.awaitRequest(
          { refund_error_details: refundErrorDetails },
          "contacts/failed_refund_error_report",
          "post"
        );
      } else if (filteredGuestInvoicesByTransactionId.length === 0) {
        sendResponses(true);
        deleteProformaInvoices(guestInvoices);
      } else if (invoicesRefundedStatus && invoicesRefundedStatus.length) {
        toast.success(webPageTranslations[code ? code : "FR"].refund_message, {
          autoClose: 4000,
        });
      }

      if (loadData) {
        loadData();
      } else {
        updateEventFormMessage();
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Remboursement non aboutie. Veuillez nous contacter ou ressayer ultérieurement"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getClientSecretKey = async (orderId = null) => {
    setIsLoading(true);
    const availableSpaces = await checkAllComponentsAvailableSpace();

    if (availableSpaces) {
      if (paymentMode === "card" && totalTTC !== 0) {
        if (Number(totalTTC) > 0) {
          await ApiService.awaitRequest(
            {
              entity_id: event.children_entity_id,
              client: contact.firstname + " " + contact.lastname,
              description: event.data.name,
              amount: totalTTC,
              contact_id: contact.id,
              evenement_id: event.id,
              evenement_name: event.data.name,
              children_entity_id: event.children_entity_id,
              idempotency_key:
                contact.id +
                "-" +
                totalTTC * 100 +
                "-" +
                Date.now().toString().slice(0, -2),
              order_id: orderId,
            },
            "contacts/create_payment",
            "post"
          ).then((data) => {
            if (data) {
              setClientSecret(data);
              setCheckoutPaymentInvoice(true);
              setIsLoading(false);
            } else {
              setIsLoading(false);
              toast.error(
                webPageTranslations[code ? code : "FR"].payment_error,
                {
                  autoClose: 4000,
                }
              );
            }
          });
        }
      } else if (paymentMode === "check" || totalTTC === 0) {
        //! NEVER ENTER HERE BECAUSE AT THIS STAGE PAYMENT METHOD IS ALWAYS CARD
        if (totalTTC !== 0) {
          //! TRANSFERT INTO BACKEND // DO WE REALLY NEED TO TRANSFERT THIS IN BACK // THERE IS NO WEBHOOK HERE
          const invoices = await ApiService.awaitRequest(
            {
              children_entity_id: event.children_entity_id,
              evenement_id: event.id,
              data: {
                ...props.invoice,
              },
              contact_id: contact.id,
              stripe_transaction_id: lastInvoice
                ? lastInvoice.stripe_transaction_id
                : null,
              status: totalTTC === 0 ? "paid" : "pending",
              entity_id: event.children_entity_id,
              evenement_name: event.data.name,
            },
            "invoices",
            "post"
          );

          if (invoices) {
            sendResponses(totalTTC === 0); //! STOP USING THIS
            setIsLoading(false);
          } else {
            toast.error(webPageTranslations[code ? code : "FR"].payment_error, {
              autoClose: 4000,
            });
          }
        } else {
          sendResponses(totalTTC === 0); //! STOP USING THIS
          setIsLoading(false);
        }
      }
    }
  };

  const canPartialRefundBeforeProformaValidation = (
    oldReponses,
    newReponses,
    componentEntranceId
  ) => {
    const condition1 = oldReponses.some((oldRep) =>
      newReponses.some(
        (newRep) =>
          oldRep.id === componentEntranceId &&
          newRep.id === oldRep.id &&
          oldRep.key === 1 &&
          newRep.key === 0
      )
    );

    const condition2 =
      lastInvoice &&
      lastInvoice.payment_mode === "check" &&
      lastInvoice.data.totalTTC >= totalTTC; // verify that the invoice is a check => check guest last invoice is check and totalTTC < invoiceTotalTTC

    return condition1 && condition2;
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    if (totalTTC < 0) {
      try {
        setIsLoading(true);
        setValidateOrderBtnLoading(true);
        console.log("🚀 ~ handleSubmitForm ~ responsesToRefund:", {
          responsesToRefund,
          invoice,
        });

        const promises = [];
        if (responsesToRefund && responsesToRefund.length) {
          responsesToRefund.forEach(async (refund) => {
            const promise = new Promise(async (resolve, reject) => {
              try {
                let status = null;

                if (
                  // Card
                  refund.invoice &&
                  refund.invoice.payment_mode === "card" &&
                  refund.invoice.status === "paid"
                ) {
                  const orderData = {
                    children_entity_id: event.children_entity_id,
                    evenement_id: event.id,
                    data: {
                      ...refund.invoice.data,
                      totalHT: -Math.abs(Number(refund.priceInfo.ht)),
                      totalTTC: -Math.abs(Number(refund.priceInfo.ttc)),
                      componentsResponses: props.invoice.componentsResponses,
                      refund_id: null,
                    },
                    responses_data: await sendResponses2(true),
                    contact_id: contact.id,
                    status: "pending",
                  };
                  const amountToRefund = refund.priceInfo.ttc;
                  const stripe_transaction_id =
                    refund.invoice.stripe_transaction_id;

                  if (amountToRefund > 0 && stripe_transaction_id) {
                    try {
                      const refundErrorDetails = await chargeRefund(
                        orderData,
                        amountToRefund,
                        stripe_transaction_id,
                        refund.invoice_number
                      );
                      if (refundErrorDetails) {
                        status = refundErrorDetails;
                        // throw new Error("Error occurred during refund");
                      } else {
                        status = null;
                      }
                    } catch (error) {
                      console.log(error);
                      status = {
                        error_type: "stripe",
                        status: REFUND_INVOICE_STATUS.failed,
                        event_id: event.id,
                        event_name: event.data.name,
                        order_id: null,
                        guest: props.guest,
                        error_message: error.message,
                        stripe_transaction_id: stripe_transaction_id,
                        invoice_number: refund.invoice_number,
                        save_response: false,
                      };
                    }
                  }
                } else if (
                  // Check
                  refund.invoice &&
                  refund.invoice.payment_mode === "check" &&
                  refund.invoice.status === "paid" &&
                  !refund.invoice.invoice_number.includes("_PROFORMA") &&
                  !refund.invoice.data.refund_id
                ) {
                  console.log(
                    "🚀 ~ responsesToRefund.forEach ~ refund.invoice:",
                    refund.invoice
                  );
                  try {
                    const refundErrorDetails = await refundCheck(
                      refund.invoice
                    );
                    if (refundErrorDetails) {
                      status = refundErrorDetails;
                      // throw new Error("Error occurred during refund");
                    } else {
                      status = null;
                    }
                  } catch (error) {
                    console.log(error);
                    status = {
                      error_type: "check",
                      status: REFUND_INVOICE_STATUS.failed,
                      event_id: event.id,
                      event_name: event.data.name,
                      order_id: null,
                      guest: props.guest,
                      error_message: error.message,
                      stripe_transaction_id: null,
                      invoice_number: refund.invoice_number,
                      save_response: false,
                    };
                  }
                } else {
                  // Unknown
                  throw new Error("Invoice payment mode undefined");
                }

                resolve(status);
              } catch (error) {
                reject(error);
              }
            });

            promises.push(promise);

            // Wait for the current promise to resolve or reject
            await promise;
          });
        } else {
          const promise = new Promise(async (resolve, reject) => {
            try {
              let status = null;

              const {
                componentsResponses,
                initialComponentsResponses,
                componentEntranceId,
              } = props;
              if (
                initialComponentsResponses &&
                componentsResponses &&
                initialComponentsResponses.length > 0 &&
                componentsResponses.length > 0
              ) {
                const canRefundInvoice =
                  canPartialRefundBeforeProformaValidation(
                    initialComponentsResponses[0].reponses,
                    componentsResponses[0].reponses,
                    componentEntranceId
                  );
                if (canRefundInvoice) {
                  console.log(
                    "🚀 ~ handleSubmitForm ~ canRefundInvoice:",
                    canRefundInvoice,
                    invoice
                  );

                  const newInvoice = {
                    ...lastInvoice,
                    data: { ...invoice, paymentMode: "check" },
                  };
                  try {
                    const refundErrorDetails = await refundCheck(newInvoice);
                    if (refundErrorDetails) {
                      status = refundErrorDetails;
                      // throw new Error("Error occurred during refund");
                    } else {
                      status = null;
                    }
                  } catch (error) {
                    console.log(error);
                    status = {
                      error_type: "check",
                      status: REFUND_INVOICE_STATUS.failed,
                      event_id: event.id,
                      event_name: event.data.name,
                      order_id: null,
                      guest: props.guest,
                      error_message: error.message,
                      stripe_transaction_id: null,
                      invoice_number: newInvoice.invoice_number,
                      save_response: false,
                    };
                  }
                }
              }

              resolve(status);
            } catch (error) {
              reject(error);
            }
          });
          promises.push(promise);

          // Wait for the current promise to resolve or reject
          await promise;
        }

        const invoicesRefundedStatus = await Promise.all(promises);

        const refundErrorDetails = invoicesRefundedStatus.filter(
          (value) => value
        );

        if (
          refundErrorDetails &&
          refundErrorDetails.length &&
          refundErrorDetails.some(
            (value) => value.status === REFUND_INVOICE_STATUS.failed
          )
        ) {
          //TODO: ERROR TOAST
          toast.warning(
            "Remboursement non abouti. Nos équipes reviendront vers vous ultérieurement."
          );
          await ApiService.awaitRequest(
            { refund_error_details: refundErrorDetails },
            "contacts/failed_refund_error_report",
            "post"
          );
        } else if (invoicesRefundedStatus && invoicesRefundedStatus.length) {
          toast.success(
            webPageTranslations[code ? code : "FR"].refund_message,
            {
              autoClose: 4000,
            }
          );
        }

        if (loadData) {
          loadData();
        } else {
          updateEventFormMessage();
        }
      } catch (error) {
        console.log(error);
        toast.error(
          "Remboursement non aboutie. Veuillez nous contacter ou ressayer ultérieurement"
        );
      } finally {
        setIsLoading(false);
        setValidateOrderBtnLoading(true);
      }
      return;
    }

    setIsLoading(true);
    const responses_data = await sendResponses2(true);

    const order = await ApiService.awaitRequest(
      {
        children_entity_id: event.children_entity_id,
        evenement_id: event.id,
        data: {
          ...props.invoice,
          refund_id: null,
        },
        responses_data: responses_data,
        contact_id: contact.id,
        status: "pending",
      },
      "contacts/order", //? Create new order. Order is created before payment intent
      "post"
    );

    if (order) {
      setPaymentOrder(order);
      getClientSecretKey(order.id); //? Function to create payment intent
    } else {
      toast.error(webPageTranslations[code ? code : "FR"].payment_error, {
        autoClose: 4000,
      });
      setPaymentOrder(null);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Modal isOpen={isLoading}>
          <div
            style={{
              opacity: 1,
              width: "100%",
              textAlign: "center",
            }}
          >
            <Loader />
          </div>
        </Modal>
      ) : checkoutPaymentInvoice ? (
        clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutPaymentInvoice
              code={code}
              totalTTC={totalTTC}
              form={form}
              cgv={cgv}
              modifyForm={modifyForm}
              invoice={invoice}
              allowCheckForAdmin={allowCheckForAdmin}
              isAdmin={isAdmin}
              allowCheckForUser={allowCheckForUser}
              sendResponses={sendResponses}
              sendResponses2={sendResponses2}
              chargeMoney={chargeMoney}
              paymentMode={paymentMode}
              setPaymentModeState={setPaymentModeState}
              element={element}
              validateForm={validateForm}
              show_payment_error={show_payment_error}
              lastInvoice={lastInvoice}
              event={event}
              contact={contact}
              guestInvoices={guestInvoices}
              loadData={loadData}
              updateEventFormMessage={updateEventFormMessage}
              paymentOrder={paymentOrder}
            />
          </Elements>
        )
      ) : (
        <form className="form" onSubmit={(e) => handleSubmitForm(e)}>
          <div
            style={{
              backgroundColor: form.layout.backgroundColorMain,
              padding: "20px",
            }}
            className={
              form.layout.gabarit === "gabarit2"
                ? "col-md-8 main-container"
                : "col-md-12 main-container"
            }
          >
            {willNotJoin ? (
              <div style={{ padding: "3rem 1rem 0rem 1rem" }}>
                <h3>
                  {`${
                    webPageTranslations[code ? code : "FR"]
                      .cancelMessageConfirmation +
                    " " +
                    webPageTranslations[code ? code : "FR"]
                      .validationCancelMessage
                  }`}
                </h3>
                <button
                  type="button"
                  onClick={refundNonComingGuest}
                  className="btn"
                  style={{
                    backgroundColor: form.layout.mainColor,
                    color: form.layout.secondaryColor,
                    // opacity: this.state.buttonLoading ? 1 : null,
                    // paddingBlock: this.state.buttonLoading
                    //   ? "0.5rem"
                    //   : null,
                  }}
                >
                  {webPageTranslations[code ? code : "FR"].confirm}
                </button>
              </div>
            ) : (
              <>
                <UserInformationComponent
                  lastname={lastname}
                  firstname={firstname}
                  handleChange={handleChange}
                  company={company}
                  address={address}
                  address_more={address_more}
                  code={code}
                  city={city}
                  country={country}
                  zip_code={zip_code}
                />
                <InvoiceDetails code={code} invoice={invoice} />
                <br />
                <GeneralTermsComponent
                  code={code}
                  form={form}
                  cgv={cgv}
                  modifyForm={modifyForm}
                  totalTTC={totalTTC}
                  element={element}
                  validateForm={validateForm}
                  validateOrderBtnLoading={validateOrderBtnLoading}
                />
              </>
            )}
          </div>
        </form>
      )}
    </>
  );
};

export default CheckOutForm;
