import * as React from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export function SegmentMultipleSelectChip({
  options,
  id,
  selectedValue,
  handleSelectedValue,
  bgColor,
  borderColor,
  chipTextColor,
  optionsTextColor,
  optionsBgColor,
  optionsBorder = undefined,
  placeholder = null,
}) {
  return (
    <FormControl
      sx={{ m: 1, minWidth: 120, height: "fit-content", maxWidth: 400 }}
      size="small"
    >
      <InputLabel disableAnimation shrink={false} style={{ color: "#A2A2A2" }}>
        {selectedValue && selectedValue.length ? null : placeholder}
      </InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        size="small"
        displayEmpty
        multiple
        value={selectedValue}
        onChange={({ target: { value } }) => {
          let res = value;
          if (value && value.length) {
            const lastItem = value[value.length - 1];
            res = lastItem ? value : [];
          }
          handleSelectedValue(res);
        }}
        input={
          <OutlinedInput
            id={`${id}-input`}
            sx={{
              minWidth: "250px",
              backgroundColor: bgColor,
              borderRadius: "0.7rem",
              border: `1px solid ${borderColor} !important`,
              "& .MuiSelect-select": {
                color: "#FFFFFF",
                ":focus": {
                  borderRadius: "0.7rem",
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
        }
        renderValue={(selected) => {
          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => {
                const item = options.find((opt) => opt.value === value);
                return (
                  <Chip
                    size="small"
                    key={value}
                    label={item ? item.label : value}
                    style={{ color: chipTextColor }}
                  />
                );
              })}
            </Box>
          );
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
              marginTop: "1rem",
              backgroundColor: optionsBgColor,
              border: optionsBorder,
            },
          },
        }}
      >
        {options.map((opt, index) => (
          <MenuItem
            key={`${id}-${index}`}
            value={opt.value}
            sx={{ color: optionsTextColor }}
          >
            {opt.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
