import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faArrowCircleRight,
  faArrowCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import { MDBInput } from "mdbreact";
import { dateFormat } from "../../../../services/Utils";
import PropTypes from "prop-types";
import { StatsPanel } from "./StatsPanel";
import { getPercentage } from "../utils";

const populateSingularStatsPanels = (campagne) => {
  const stats = "stats" in campagne ? campagne.stats : {};

  if (Object.keys(stats).length !== 0 && !stats.empty) {
    return (
      <div className="row-nowrap" style={{ marginLeft: -10 }}>
        <StatsPanel
          panelTitle="DÉLIVRÉS"
          panelLabel={`Soit ${
            "success" in stats ? stats.success.sent.count : 0
          } emails`}
          panelValue={`${"success" in stats ? stats.success.sent.percent : 0}%`}
        />
        <StatsPanel
          panelTitle={
            <>
              <FontAwesomeIcon icon={faCircle} color="#78CB68" size={"1x"} />{" "}
              OUVERTS
            </>
          }
          panelLabel={`Soit ${
            "success" in stats ? stats.success.opened.count : 0
          } emails`}
          panelValue={`${
            "success" in stats ? stats.success.opened.percent : 0
          }%`}
        />
        <StatsPanel
          panelTitle={
            <>
              <FontAwesomeIcon icon={faCircle} color="#d81b60" size={"1x"} />{" "}
              CLIQUÉS
            </>
          }
          panelLabel={`Soit ${
            "success" in stats ? stats.success.clicked.count : 0
          } emails`}
          panelValue={`${
            "success" in stats ? stats.success.clicked.percent : 0
          }%`}
        />
        <StatsPanel
          panelTitle={
            <>
              <FontAwesomeIcon icon={faCircle} color="#FF8900" size={"1x"} />{" "}
              ERREURS
            </>
          }
          panelLabel={`Soit ${
            "error" in stats ? stats.error.error_total.count : 0
          } emails`}
          panelValue={`${
            "error" in stats ? stats.error.error_total.percent : 0
          }%`}
        />{" "}
      </div>
    );
  }

  return null;
};

const populateSmsSingularStatsPanels = (campagne) => {
  return (
    <div className="row-nowrap" style={{ marginLeft: -10 }}>
      <StatsPanel
        panelTitle="DÉLIVRÉS"
        panelLabel={`Soit ${campagne.stats.delivered} sms`}
        panelValue={`${getPercentage(
          campagne.stats.sent,
          campagne.stats.delivered
        )}%`}
      />
      <StatsPanel
        panelTitle={
          <>
            <FontAwesomeIcon icon={faCircle} color="#78CB68" size={"1x"} />{" "}
            OUVERTS
          </>
        }
        panelLabel={`Soit ${campagne.stats.openers} sms`}
        panelValue={`${getPercentage(
          campagne.stats.delivered,
          campagne.stats.openers
        )}%`}
      />
      <StatsPanel
        panelTitle={
          <>
            <FontAwesomeIcon icon={faCircle} color="#d81b60" size={"1x"} />{" "}
            CLIQUÉS
          </>
        }
        panelLabel={`Soit ${campagne.stats.clickers} sms`}
        panelValue={`${getPercentage(
          campagne.stats.delivered,
          campagne.stats.clickers
        )}%`}
      />
      <StatsPanel
        panelTitle={
          <>
            <FontAwesomeIcon icon={faCircle} color="#FF8900" size={"1x"} />{" "}
            ERREURS
          </>
        }
        panelLabel={`Soit ${campagne.stats.errors} sms`}
        panelValue={`${getPercentage(
          campagne.stats.sent,
          campagne.stats.errors
        )}%`}
      />{" "}
    </div>
  );
};

function DashboardStatistics({
  statsTitle,
  statsType,
  searchBarLabel,
  globalCampaignStats,
  campaigns,
  globalStatsPanels,
}) {
  const [showDetails, setShowDetails] = useState(false);
  const [campagneFilterText, setCampagneFilterText] = useState("");
  const [campaignsFilter, setCampaignsFilter] = useState(campaigns);

  const filterCampaigns = (event, type) => {
    switch (type) {
      case "text":
        const text = event.target.value;
        setCampagneFilterText(text);
        filter(type, event);
        break;
      default:
        break;
    }
  };

  const filter = (type, event) => {
    let filter = [];
    switch (type) {
      case "text":
        // campaigns.map((campaign) => {
        //   const dates = campaignsFilterDates.find(
        //     (dates) => dates.campaignId === campaign.id
        //   );
        //   if (dates) {
        //     dates.date1 = new Date();
        //     dates.date2 = new Date();
        //   }
        //   return campaign;
        // });
        const text = event.target.value;
        if (text !== "") {
          const removeSpace = (text) =>
            text ? text.replace(/\s+/g, " ").trim() : "";
          filter = campaigns.filter(
            (campagn) =>
              removeSpace(campagn.title)
                .toLowerCase()
                .includes(removeSpace(text).toLowerCase()) ||
              removeSpace(
                statsType === "emails" ? campagn.subject : campagn.sender_name
              )
                .toLowerCase()
                .includes(removeSpace(text).toLowerCase())
          );
        } else {
          filter = campaigns;
        }
        break;
      default:
        filter = campaigns;
        break;
    }
    setCampaignsFilter(filter);
  };

  const openDetails = (campaignId) => {
    const find = campaignsFilter.find((campaign) => campaign.id === campaignId);
    if (find) {
      find.openDetails = !find.openDetails; //! becarefull => field defined in frontend | don't forget to create it for sms as well
      setCampaignsFilter([...campaignsFilter]);
    }
  };

  return (
    <div>
      <div className="table-container mb-4" style={{ marginLeft: -10 }}>
        <h3 className="campaign-panel-title ">{statsTitle}</h3>
        {showDetails ? (
          <MDBInput
            type="text"
            value={campagneFilterText}
            name="campagneFilterText"
            label={searchBarLabel}
            onChange={(e) => filterCampaigns(e, "text")}
          />
        ) : null}
        <div
          className={"stats-panel-label"}
          style={{ color: "black", fontWeight: "100" }}
        >
          Vous avez envoyé{" "}
          <label style={{ color: "#d81b60", fontWeight: "bold" }}>
            {"total" in globalCampaignStats ? globalCampaignStats.total : 0}
          </label>{" "}
          {statsType}
        </div>
      </div>
      <br />
      <div className="row-nowrap" style={{ marginLeft: -10 }}>
        {globalStatsPanels &&
          globalStatsPanels.map((element, index) => (
            <div key={`global-stats-panel-${index}`}>{element}</div>
          ))}
      </div>
      <div
        style={{
          justifyContent: "flex-end",
          alignItems: "flex-end",
          display: "flex",
        }}
      >
        {/* <button className="btn btnDark white-text">
      <FontAwesomeIcon icon={faDownload} color="white" size={"1x"} /> TÉLÉCHARGER LES DONNÉES
    </button> */}
        <button
          className="btn pink white-text darken-1"
          onClick={() => setShowDetails((prev) => !prev)}
        >
          VOIR PLUS DE DÉTAILS
        </button>
      </div>
      <br />

      {showDetails
        ? campaignsFilter.map((campagne, index) => {
            const schedule_date =
              "Prévue le " + dateFormat(campagne.schedule_date);
            return (
              <div key={`campaign-stats-item-${index}`}>
                <div className="table-container mb-4">
                  <div className="row-nowrap">
                    <h3 className="campaign-panel-title ">{campagne.title}</h3>
                    <div
                      style={{
                        justifyContent: "flex-end",
                        display: "flex",
                        flex: 1,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={
                          campagne.openDetails
                            ? faArrowCircleDown
                            : faArrowCircleRight
                        }
                        color="black"
                        size={"2x"}
                        onClick={() => openDetails(campagne.id)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <h3 className="campaign-panel-label">
                    {statsType === "emails"
                      ? campagne.subject
                      : statsType === "sms"
                      ? campagne.sender_name
                      : ""}
                  </h3>{" "}
                  <h3 className="campaign-panel-label-date">{schedule_date}</h3>
                </div>
                {campagne.openDetails
                  ? (statsType === "emails"
                      ? populateSingularStatsPanels(campagne)
                      : populateSmsSingularStatsPanels(campagne)) || (
                      <div className="table-container mb-4">
                        <div
                          className="row-nowrap"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 10,
                          }}
                        >
                          <h4 className="campaign-panel-title">
                            Pas de statistiques
                          </h4>
                        </div>
                      </div>
                    )
                  : null}
                <br />
              </div>
            );
          })
        : null}
    </div>
  );
}

DashboardStatistics.prototype = {
  statsTitle: PropTypes.string.isRequired,
  statsType: PropTypes.string.isRequired,
  searchBarLabel: PropTypes.string.isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  globalStatsPanels: PropTypes.arrayOf(PropTypes.element).isRequired,

  // handleNameFilter: PropTypes.func.isRequired,
  // companyFilter: PropTypes.string.isRequired,
  // handleCompanyFilter: PropTypes.func.isRequired,
  // langueFilter: PropTypes.string.isRequired,
  // handleLangueFilter: PropTypes.func.isRequired,
  // packageIdFilter: PropTypes.string.isRequired,
  // validPhoneNumberFilter: PropTypes.string,
  // handleValidPhoneNumberFilter: PropTypes.func,
  // handlePackageIdFilter: PropTypes.func.isRequired,
  // delegataireFilter: PropTypes.string.isRequired,
  // handleDelegataireFilter: PropTypes.func.isRequired,
  // reponseFilter: PropTypes.string.isRequired,
  // handleReponseFilter: PropTypes.func.isRequired,
  // documentFilter: PropTypes.string.isRequired,
  // handleDocumentFilter: PropTypes.func.isRequired,
  // tagOptions: PropTypes.arrayOf(
  //   PropTypes.shape({ label: PropTypes.string, value: PropTypes.number })
  // ).isRequired,
  // handleTagOptions: PropTypes.func.isRequired,
  // followerFilter: PropTypes.string.isRequired,
  // handlefollowerFilter: PropTypes.func.isRequired,
  // maxFollowerFilter: PropTypes.string.isRequired,
  // handleMaxFollowerFilter: PropTypes.func.isRequired,
  // packagesOptions: PropTypes.arrayOf(
  //   PropTypes.shape({ label: PropTypes.string, value: PropTypes.number })
  // ).isRequired,
  // delegatairesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  // followerMaxInvitationOptions: PropTypes.array.isRequired,
};

export { DashboardStatistics };
