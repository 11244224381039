import React from "react";
import { Button, Modal, ModalBody, ModalFooter, MDBInput } from "mdbreact";
import ApiService from "../../../services/ApiService";
import Loader from "../../../common/Loader";
import { toast } from "react-toastify";

class ImportContacts extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkFile = this.checkFile.bind(this);

    this.state = {
      modal: false,
      isLoading: false,
      formData: null,
      reportEmail: "",
      disableContactsOption: false,
      errorMessage: "",
      filename: "",
      uploading: false,
    };
  }

  componentDidMount() {
    const user = localStorage.getItem("user");
    const { email } = user ? JSON.parse(user) : null;
    if (email) {
      this.setState({ reportEmail: email });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const { formData, disableContactsOption, reportEmail } = this.state;

    formData.append("disableContactsOption", disableContactsOption);
    formData.append("report_recipient", reportEmail);

    this.setState({ uploading: true });

    const self = this;
    ApiService.request(formData, "contacts/import", "post", false)
      .then(function (data) {
        self.setState({ uploading: false, modal: false });
        toast.success(
          "Import des contacts en cours... pensez à surveiller vos emails",
          {
            autoClose: 3000,
          }
        );
      })
      .catch(function () {
        toast.error("Erreur lors de l'import des contacts", {
          autoClose: 3000,
        });
        self.setState({ uploading: false });
      });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  checkFile(file) {
    let valid = true;
    let errorMessage = "";
    if (file) {
      const parts = file[0].name.split(".");

      const self = this;

      if (parts[1] !== "csv") {
        errorMessage = "Le fichier doit être au format CSV";
        valid = false;
      } else {
        const fileReader = new FileReader();
        fileReader.readAsText(file[0]);

        fileReader.onload = (function () {
          return function (e) {
            const data = this.result;

            if (!data.includes('";"')) {
              errorMessage = 'Le séparateur doit être un ";"';
              valid = false;
            }

            const lines = data.split("external_id")[0].split(";");

            if (lines.length !== 28) {
              errorMessage =
                "le fichier doit avoir 28 colonnes. Veuillez vous réferer au fichier d'exemple";
              valid = false;
            }

            if (valid) {
              const formData = new FormData();
              formData.append("contact_file", file[0], file[0].name);
              self.setState({
                filename: file[0].name,
                errorMessage: "",
                formData,
              });
            } else {
              self.setState({ errorMessage });
            }
          };
        })(file);
      }
    }
  }

  toggle = (e) => {
    if (e) e.preventDefault();
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const {
      modal,
      filename,
      errorMessage,
      reportEmail,
      disableContactsOption,
      uploading,
      formData,
    } = this.state;

    return (
      <div className="button-container">
        <Button
          className="btn pink darken-1 "
          onClick={(e) =>
            this.props.navigate(e, { page: "import", subPage: "" })
          }
        >
          Importer des contacts
        </Button>
        <Modal isOpen={modal} size="lg" toggle={(e) => this.toggle(e)}>
          <form className="form" onSubmit={(e) => this.handleSubmit(e)}>
            <ModalBody>
              {uploading ? (
                <div className="row">
                  Votre import est cours, vous serez averti par email lorsque ce
                  dernier sera terminé.
                  <div>
                    <Loader />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-8">
                    <strong>Imports de contacts</strong>
                    <br />
                    <p>
                      Les contacts existants seront mis à jour (écrasement des
                      données existantes)
                    </p>
                    <br />
                    <div className="form-row light-grey">
                      <div className="form-group col-md-12">
                        <label>Email de réception du rapport</label>
                        <input
                          type="email"
                          name="reportEmail"
                          className="form-control"
                          value={reportEmail}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="form-group">1 - Envoyer un fichier</div>
                      <div
                        style={{ margin: "0.375rem" }}
                        className="input-group"
                      >
                        <div className="custom-file">
                          <input
                            required
                            type="file"
                            onChange={(e) => this.checkFile(e.target.files)}
                            className="custom-file-input"
                          />
                          <label className="custom-file-label">
                            Choisir un fichier
                          </label>
                        </div>
                      </div>
                      <div className="form-group">
                        <MDBInput
                          onClick={(e) =>
                            this.setState({
                              disableContactsOption: !disableContactsOption,
                            })
                          }
                          id="1"
                          label={
                            "Désactiver les contacts non présents dans le fichier d'import"
                          }
                          filled
                          checked={disableContactsOption}
                          type="checkbox"
                        />
                      </div>
                    </div>
                    {filename.length > 0 ? (
                      <div className="bg-success text-white text-center p-2">
                        <strong>Votre fichier : "{filename}" est valide</strong>
                      </div>
                    ) : null}
                    {errorMessage.length > 0 ? (
                      <div className="text-danger">
                        Votre fichier ne peut pas être importé : <br />
                        {errorMessage}
                      </div>
                    ) : (
                      <Button
                        type="submit"
                        className="btn pink darken-1 "
                        disabled={!formData}
                      >
                        Valider
                      </Button>
                    )}
                  </div>
                  <div className="col-md-4">
                    <p>
                      Votre fichier d'import doit strictement respecter les
                      conditions suivantes :{" "}
                    </p>
                    <p>- Extension CSV</p>
                    <p>
                      Le nom de votre fichier ne doit pas comporter de
                      caractères spéciaux
                    </p>
                    <p>- Utiliser les séparateurs de texte " "</p>
                    <p>- Encodage UTF-8</p>
                    <p>Séparateur de colonnes ";"</p>
                    <br />
                    <p>
                      La première ligne comporte les entêtes et ne sera pas
                      importée.
                    </p>
                    <br />
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        "/Kanguroo_Contacts_JeuxdeTests.csv"
                      }
                      download
                    >
                      Télécharger un exemple
                    </a>
                  </div>
                </div>
              )}
            </ModalBody>

            <ModalFooter>
              <button
                className="btn cancel-button"
                onClick={(e) => this.toggle(e)}
              >
                Fermer
              </button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}

export default ImportContacts;
