import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function GuestsErrorModal({ open, setModal, campaign }) {
  const [errors, setErrors] = useState([]);
  const [guestsErrors, setGuestsErrors] = useState([]);
  useEffect(() => {
    const data = JSON.parse(campaign.error_data);
    setErrors(data[campaign.id]["errors"] ? data[campaign.id]["errors"] : []);
    setGuestsErrors(
      data[campaign.id]["guests_errors"]
        ? data[campaign.id]["guests_errors"]
        : []
    );
  }, []);
  const ids = Object.keys(guestsErrors);
  return (
    <Modal show={open} onHide={setModal} size="lg">
      <Modal.Header>
        <Modal.Title>Erreurs sur la campagne</Modal.Title>
        <Modal.Title>
          <FontAwesomeIcon
            icon={faTimes}
            color={"grey"}
            onClick={setModal}
            size="xs"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                {errors ? <th scope="col"></th> : null}
                {Array.isArray(ids) && ids ? <th scope="col"></th> : null}
              </tr>
            </thead>
            <tbody>
              <tr>
                {errors ? (
                  <>
                    <td>
                      <strong>Global</strong>
                    </td>
                    <td>
                      {errors.map((error) => {
                        return (
                          <>
                            {error.message}
                            <br />
                          </>
                        );
                      })}
                    </td>
                  </>
                ) : null}
              </tr>
              <tr>
                {Array.isArray(ids) && ids ? (
                  <>
                    <td>
                      <strong>Invités</strong>
                    </td>
                    <td>
                      {ids.map((id) => {
                        return (
                          <>
                            • {"\n"}
                            {id}
                            <br />
                          </>
                        );
                      })}
                    </td>
                  </>
                ) : null}
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
}
