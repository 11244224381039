import React from "react";
import { Switch, Route } from "react-router-dom";
import PlacementsList from "./PlacementsList";
import PlacementContainer from "./PlacementContainer";

class Placements extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path="/admin/events/edit/:eventid/placement/:placementid"
          render={props => (
            <PlacementContainer
              {...props}
              event={this.props.event}
              contacts={this.props.contacts}
            />
          )}
        />
        <Route
          exact
          path="/admin/events/edit/:eventid/placement"
          render={props => (
            <PlacementsList {...props} event={this.props.event} />
          )}
        />
      </Switch>
    );
  }
}

export default Placements;
