import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SelectIconComponent = ({ icon, title }) => {
    return (
        <div>
            {icon && <FontAwesomeIcon icon={icon} />}
            &nbsp;{title}
        </div>
    );
};