import React from "react";
import PlacementStatistics from "./PlacementStatistics";

class PlacementMenu extends React.Component {
  render() {
    return (
      <div
        className="row text-center"
        style={{
          backgroundColor: "#D23466",
          height: "50px",
          position: "fixed",
          top: "0",
          width: "100%",
          zIndex: "10",
          color: "white",
          fontWeight: "bold"
        }}
      >
        <PlacementStatistics
          hotLoading={this.props.hotLoading}
          displaySettings={this.props.displaySettings}
          refreshData={this.props.refreshData}
        />
        <div
          className="col-md-12"
          style={{
            fontSize: "17px",
            paddingTop: "15px",
            paddingLeft: "10%",
            paddingRight: "10%",
            lineHeight: "17px"
          }}
        >
          {this.props.name}
        </div>
      </div>
    );
  }
}

export default PlacementMenu;
