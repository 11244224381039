import React from "react";
import { CustomTable } from "../../../../common/table_component/CustomTable";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../../common/table_component/utils";
import { Box, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { PhoneNumberDialog } from "./PhoneNumberDialog";
import { verifyNumber } from "./utils";
import { toast } from "react-toastify";

const NumbersStatusLabel = {
  valid: "Valide",
  toFormat: "À Formater",
  invalid: "Invalide",
  undefined: "Indéfini",
};

const headCells = [
  {
    id: "lastname",
    numeric: false,
    disablePadding: false,
    label: "Nom",
  },
  {
    id: "firstname",
    numeric: false,
    disablePadding: false,
    label: "Prénom",
  },
  {
    id: "number",
    numeric: false,
    disablePadding: false,
    label: "Numéro",
  },
  {
    id: "numberStatus",
    numeric: false,
    disablePadding: false,
    label: "Statut Numéro",
  },
  {
    id: "society",
    numeric: false,
    disablePadding: false,
    label: "Société",
  },
  {
    id: "mypackage",
    numeric: false,
    disablePadding: false,
    label: "Packages",
  },
  {
    id: "smsStatus",
    numeric: false,
    disablePadding: false,
    label: "Statut Sms",
  },
];

export function SmsCampaignSelectedGuestsTable({
  guestsChecked,
  packageOptions,
  getPhoneNumberStatus,
  updateGuestPhoneNumber,
  isCampainPast,
  guestsSmsStatus,
}) {
  console.log("🚀 ~ guestsSmsStatus:", guestsSmsStatus);
  const [selectedGuests, setSelectedGuests] = React.useState([]);
  const [currentGuest, setCurrentGuest] = React.useState(null);
  const [openPhoneNumberDialog, setOpenPhoneNumberDialog] =
    React.useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = React.useState("");

  const formatUniquePhoneNumber = (guestId, guestContactId, phoneNumber) => {
    const formattedPhoneNumber = verifyNumber(phoneNumber);
    if (formattedPhoneNumber) {
      updateGuestPhoneNumber(guestId, guestContactId, formattedPhoneNumber);
    } else {
      toast.error("Erreur lors de la modification");
    }
  };

  const getSmsStatus = (guestId) => {
    if (guestsSmsStatus && guestsSmsStatus.length) {
      const guestSmsStatus = guestsSmsStatus.find(
        (elem) => elem.guestId === guestId
      );

      if (guestSmsStatus && guestSmsStatus.status) {
        return <p style={{ color: "green", margin: 0 }}>Délivrer</p>;
      } else if (guestSmsStatus && !guestSmsStatus.status) {
        return <p style={{ color: "red", margin: 0 }}>Échec</p>;
      }
    }
    return "";
  };

  const createGuestsTableRows = (data) => {
    return data.map((guest) => {
      let companyName = "";
      if (guest.contact) {
        companyName = guest.contact.company ? guest.contact.company.name : "";
      }

      let guestPackage = packageOptions.find(
        (pck) => pck.value === guest.package_id
      );
      guestPackage = guestPackage ? guestPackage.label : "";

      const numberStatus = getPhoneNumberStatus(guest.mobile);

      const lastname = (
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <p className="m-0">{guest.firstname}</p>
          <IconButton
            size="small"
            onClick={() =>
              window.open(
                window.location.origin +
                  `/admin/contacts/edit/${guest.contact_id}`
              )
            }
          >
            <FontAwesomeIcon icon={faEdit} color="#636363" />
          </IconButton>
        </Box>
      );

      const number = (
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <p className={`m-0 number-validity-status ${numberStatus}`}>
            {guest.mobile ? guest.mobile : "Numéro indéfini"}
          </p>
          {isCampainPast ? null : numberStatus === "invalid" ||
            numberStatus === "undefined" ? (
            <IconButton
              size="small"
              onClick={() => {
                setOpenPhoneNumberDialog(true);
                setSelectedPhoneNumber(guest.mobile);
                setCurrentGuest(guest);
              }}
            >
              <FontAwesomeIcon icon={faEdit} color="#636363" />
            </IconButton>
          ) : numberStatus === "toFormat" ? (
            <IconButton
              size="small"
              onClick={() => {
                formatUniquePhoneNumber(
                  guest.id,
                  guest.contact_id,
                  guest.mobile
                );
              }}
            >
              <AutoFixHighIcon sx={{ color: "#636363" }} />
            </IconButton>
          ) : null}
        </Box>
      );

      return {
        id: guest.id,
        lastname: lastname,
        firstname: guest.firstname,
        number,
        society: companyName,
        mypackage: guestPackage,
        numberStatus,
        smsStatus: isCampainPast ? getSmsStatus(guest.id) : "",
      };
    });
  };

  const populateRows = (isSelected, handleClick, visibleRows) => {
    return visibleRows.map((row, index) => {
      const isItemSelected = isSelected(row.id);
      const labelId = `enhanced-table-checkbox-${index}`;
      return (
        <StyledTableRow
          key={row.id}
          hover
          onClick={(event) => handleClick(event, row.id)}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          selected={isItemSelected}
          sx={{ cursor: "pointer" }}
        >
          <StyledTableCell
            component="th"
            id={labelId}
            scope="row"
            padding="normal"
          >
            {row.lastname}
          </StyledTableCell>
          <StyledTableCell align="left">{row.firstname}</StyledTableCell>
          <StyledTableCell align="left">{row.number}</StyledTableCell>
          <StyledTableCell
            align="left"
            className={`number-validity-status ${row.numberStatus}`}
          >
            {NumbersStatusLabel[row.numberStatus]}
          </StyledTableCell>
          <StyledTableCell align="left">{row.society}</StyledTableCell>
          <StyledTableCell align="left">{row.mypackage}</StyledTableCell>
          <StyledTableCell align="left">{row.smsStatus}</StyledTableCell>
        </StyledTableRow>
      );
    });
  };

  const handlePhoneNumberDialogClose = () => {
    setOpenPhoneNumberDialog(false);
    setCurrentGuest(null);
  };
  return (
    <div>
      <CustomTable
        data={guestsChecked}
        createTableRows={createGuestsTableRows}
        selected={selectedGuests}
        setSelected={setSelectedGuests}
        populateRows={populateRows}
        headCells={headCells}
        // headCellesNoWrap={true}
        activateCheckbox={false}
      />
      <PhoneNumberDialog
        openPhoneNumberDialog={openPhoneNumberDialog}
        handlePhoneNumberDialogClose={handlePhoneNumberDialogClose}
        guestCurrentPhoneNumber={selectedPhoneNumber}
        formatUniquePhoneNumber={formatUniquePhoneNumber}
        currentGuest={currentGuest}
      />
    </div>
  );
}
