import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  MDBInput,
  ModalHeader,
} from "mdbreact";
import ApiService from "../../services/ApiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";

class QuickGuestsEditor extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.createAndInviteContact = this.createAndInviteContact.bind(this);

    this.state = {
      modal: false,
      lastname: "",
      firstname: "",
      name: "",
      company_function: "",
      gender: "",
      max_invitation: 0,
      checkin_points: [],
      isLoading: false,
    };
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  getResponseLabel() {
    try {
      return this.props.component.data.reponse.options[0].value.split(";")[0];
    } catch (error) {
      return "Oui";
    }
  }

  async createAndInviteContact(e) {
    e.preventDefault();
    try {
      if (this.props.component) {
        const {
          lastname,
          firstname,
          name,
          max_invitation,
          company_function,
          is_followers,
          gender,
          checkin_points,
        } = this.state;

        this.setState({ isLoading: true });
        const self = this;

        let company = "";

        if (name) {
          // Si "Société" n'est pas vide
          company = await ApiService.awaitRequest(
            { name, company_function },
            "contact_companies/",
            "post"
          );
        }

        let contact = "";

        if (company) {
          // Si "Société" n'est pas vide

          contact = await ApiService.awaitRequest(
            {
              created_on_checkin: true,
              gender,
              active: true,
              related_to: null,
              lastname,
              firstname,
              company_id: company.id,
            },
            "contacts/",
            "post"
          );
        } else {
          // Si "Société" est vide

          let first_name = firstname;

          // Prénom ne doit pas être vide -> "." par defaut

          if (first_name === "") {
            first_name = ".";
          }
          this.setState({ firstname: first_name });

          contact = await ApiService.awaitRequest(
            {
              created_on_checkin: true,
              gender,
              active: true,
              related_to: null,
              lastname,
              firstname: first_name,
            },
            "contacts/",
            "post"
          );
        }

        const guest = await ApiService.awaitRequest(
          {
            gender: contact.gender,
            firstname: contact.firstname,
            lastname: contact.lastname,
            created_on_checkin: true,
            evenement_id: self.props.eventId,
            package_id: 1,
            checked_in: false,
            max_invitation: max_invitation,
            contact_id: contact.id,
            is_followers,
            is_validated: true,
            participate: true,
            checkin_points,
          },
          "guests",
          "post"
        );

        if (guest) {
          const response = [
            {
              id: this.props.component.id,
              key: 0,
              label: this.getResponseLabel(),
            },
          ];

          await ApiService.awaitRequest(
            {
              guests_ids: [guest.id],
              evenement_form_id: this.props.formId,
              component_id: "",
              responses: response,
            },
            "component_responses/",
            "post"
          );
        }

        this.setState(
          { isLoading: false, modal: !this.state.modal },
          () => {
            self.props.updateGuestListAndCheckin(guest);
            toast.success("Invité crée");
          }
          // close modale
        );
      } else throw new Error("Component Entrance undefined.");
    } catch (error) {
      console.log(error);
      toast.warning(
        "Erreur lors de la création du l'invité. Veuillez réessayer ultérieurement."
      );
      this.setState({ isLoading: false, modal: !this.state.modal });
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const { lastname, firstname, gender, company_function, name, isLoading } =
      this.state;

    return (
      <div>
        <button
          className="btn cancel-button actions-container-fixed btn-block button-addguest"
          onClick={(e) => this.toggle(e)}
        >
          <FontAwesomeIcon color={"white"} size="3x" icon={faUserPlus} />
        </button>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          {isLoading ? (
            <div className="checkin-loader">
              <Loader />
            </div>
          ) : (
            <form
              onSubmit={(e) => this.createAndInviteContact(e)}
              className="form form-checkin-add-guest"
            >
              <ModalHeader toggle={this.toggle}>Ajouter un invité</ModalHeader>
              <ModalBody>
                <div className="row">
                  <div className="col-md-10">
                    <MDBInput
                      label="Civilité"
                      type="text"
                      value={gender}
                      name="gender"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-10">
                    <MDBInput
                      label="Nom (*)"
                      type="text"
                      value={lastname}
                      name="lastname"
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-10">
                    <MDBInput
                      label="Prénom"
                      type="text"
                      value={firstname}
                      name="firstname"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="col-md-10">
                    <MDBInput
                      label="Société"
                      type="text"
                      value={name}
                      name="name"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-10">
                    <MDBInput
                      label="Fonction"
                      type="text"
                      value={company_function}
                      name="company_function"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" className="btn pink darken-1 ">
                  Valider
                </Button>
                <Button
                  className="btn darken-1 dark cancel-button"
                  onClick={this.toggle}
                >
                  Fermer
                </Button>
              </ModalFooter>
            </form>
          )}
        </Modal>
      </div>
    );
  }
}

export default QuickGuestsEditor;
