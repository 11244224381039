import React from "react";
import ApiService from "../services/ApiService";
import jsPDF from "jspdf";
import Loader from "./Loader";
import { formatDate } from "../services/Utils";
import { webPageTranslations } from "./Translations";

class Invoice extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.match && this.props.match.params.accessToken) {
      localStorage.setItem(
        "invitation_token",
        this.props.match.params.accessToken
      );
    }

    this.generateInvoice = this.generateInvoice.bind(this);
    this.loadData = this.loadData.bind(this);

    this.state = {
      isLoading: false,
      contact: this.props.contact,
      eventId: this.props.eventId,
      guestId: this.props.guestId,
      event: this.props.event,
      pdf: new jsPDF(),
    };
  }

  async componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.invoice !== prevProps.invoice) {
      await this.loadData();
    }
  }

  async componentDidMount() {
    await this.loadData();
  }

  async loadData() {
    const self = this;

    this.setState({ pdf: new jsPDF() });

    this.setState({ isLoading: true });
    if (this.props.checkEventFromAdmin) {
      await ApiService.request(
        {},
        "evenements/all/" + this.props.eventId,
        "get"
      ).then(function (data) {
        self.setState({
          event: data,
        });
      });
      await ApiService.request(
        {},
        "invoices/settings/" + this.state.event.children_entity_id,
        "get"
      ).then(function (data) {
        self.setState({
          settings: data,
        });
      });

      await ApiService.request(
        {},
        "contacts/" + this.props.contactId,
        "get"
      ).then(function (data) {
        self.setState({
          contact: data,
        });
      });
      if (this.props.invoice) {
        this.setState({
          invoice: this.props.invoice,
        });
      } else {
        await ApiService.request(
          {},
          "contacts/" + this.props.contactId + "/invoice/" + this.props.eventId,
          "get"
        ).then(function (data) {
          self.setState({
            invoice: data,
          });
        });
      }
    } else {
      await ApiService.request(
        {},
        "evenements/all/" + this.props.eventId,
        "get"
      ).then(function (data) {
        self.setState({
          event: data,
        });
      });

      await ApiService.request({}, "guests/" + this.props.guestId, "get").then(
        function (data) {
          self.setState({
            guest: data,
          });
        }
      );

      await ApiService.request(
        {},
        "invoices/settings/" + this.state.event.children_entity_id,
        "get"
      ).then(function (data) {
        self.setState({
          settings: data,
        });
      });

      await ApiService.request(
        {},
        "contacts/" + this.state.guest.contact_id,
        "get"
      ).then(function (data) {
        self.setState({
          contact: data,
        });
      });
      if (this.props.invoice) {
        this.setState({
          invoice: this.props.invoice,
        });
      } else {
        await ApiService.request(
          {},
          "contacts/" +
            this.state.guest.contact_id +
            "/invoice/" +
            this.props.eventId,
          "get"
        ).then(function (data) {
          self.setState({
            invoice: data,
          });
        });
      }
    }

    const url = this.state.settings.logo
      ? "https://res.cloudinary.com/kanguroo-event/image/upload/" +
        this.state.settings.logo
      : null;

    await this.getBase64ImageFromUrl(url).then(async function (img) {
      await self.generateInvoice(img);
    });
  }

  async getBase64ImageFromUrl(imageUrl) {
    if (!imageUrl) return null;
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }

  getImageDimensions(file) {
    return new Promise(function (resolved, rejected) {
      var i = new Image();
      i.onload = function () {
        resolved({ w: i.width, h: i.height });
      };
      i.src = file;
    });
  }

  async generateInvoice(img) {
    const { invoice, settings, contact } = this.state;

    const self = this;

    const pdf = this.state.pdf;

    pdf.setFontSize(10);

    let y = 10;
    let x = 10;
    // const longString =
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,";
    const interval = 5;

    if (img) {
      const dimensions = await this.getImageDimensions(img);
      const ratio = dimensions.w / 90;
      pdf.addImage(
        img,
        "JPEG",
        x,
        y,
        dimensions.w / ratio,
        dimensions.h / ratio
      );
    }

    x = x + 100;

    pdf.rect(x, y, 90, 35);

    y = y + 5;
    x = x + 5;
    // console.log(pdf)
    let isRefund = false;
    console.log(invoice.data.products, "invoice.data.products");
    if (parseFloat(invoice.data.totalTTC) < 0) isRefund = true;
    // if (invoice.data.products) {
    //   invoice.data.products.forEach((field) => {
    //     if (field && field.label && field.label.includes("Remboursement")) {
    //       isRefund = true;
    //     }
    //   });
    // }

    pdf
      .setFont(undefined, "bold")
      .text(
        `${isRefund ? "AVOIR N°" : "Facture N°"} : ` + invoice.invoice_number,
        x,
        y
      )
      .setFont(undefined, "normal");

    // pdf.setFontType("normal");
    y = y + interval;
    const invoiceDate = new Date(invoice.created_at);

    pdf.text("Date de facture : " + formatDate(invoiceDate), x, y);
    y = y + interval;

    pdf.text(
      "Date d'échéance : " +
        formatDate(
          invoiceDate.setDate(
            invoiceDate.getDate() + Number(settings.paymentDeadline)
          ),
          false
        ),
      x,
      y
    );
    y = y + interval;

    pdf.text(
      "Mode de paiement : " +
        (invoice.data.paymentMode === "card" ? "Carte" : "Chèque"),
      x,
      y
    );
    y = y + interval;

    pdf.text("Numéro de commande : " + invoice.order_number, x, y);
    y = y + 20;
    pdf.text("Adressé à : ", x, y);
    y = y + interval;

    if (invoice.data.company && invoice.data.company.length > 0) {
      pdf.text(invoice.data.company, x, y);
      y = y + interval;
    }

    pdf.text(
      contact.gender +
        " " +
        invoice.data.lastname +
        " " +
        invoice.data.firstname,
      x,
      y
    );
    y = y + interval;

    pdf.text(invoice.data.address, x, y);
    y = y + interval;

    if (invoice.data.address_more && invoice.data.address_more.length > 0) {
      pdf.text(invoice.data.address_more, x, y);
      y = y + interval;
    }

    pdf.text(invoice.data.zip_code + " " + invoice.data.city, x, y);
    y = y + interval;

    pdf.text(invoice.data.country, x, y);
    y = y + 15;
    x = x + 35;

    pdf.text("Montants exprimés en euros", x, y);
    y = y + 5;
    x = 20;

    pdf.rect(10, y, 190, 90);
    y = y + 5;
    x = x + 10;
    pdf.text("Désignation", x, y);
    x = x + 85;
    pdf.line(x, y - 5, x, y - 5 + 90);
    x = x + 5;
    pdf.text("PU H.T", x, y);
    x = x + 15;
    pdf.line(x, y - 5, x, y - 5 + 90);
    x = x + 5;
    pdf.text("PU TTC", x, y);
    x = x + 15;
    pdf.line(x, y - 5, x, y - 5 + 90);
    x = x + 5;
    pdf.text("Qté", x, y);
    x = x + 15;
    pdf.line(x, y - 5, x, y - 5 + 90);
    x = x + 5;
    pdf.text("Total HT", x, y);
    y = y + 5;

    pdf.line(10, y, 200, y);
    y = y + 5;

    for (let product of invoice.data.products) {
      x = 20;

      if (isRefund) {
        pdf.text(this.state.event.data.name || "", x, y, {
          maxWidth: 80,
          lineHeightFactor: 1,
        });
        x = 120;

        //HT
        if (invoice.data.totalHT !== 0) {
          pdf.text(
            parseFloat(invoice.data.totalHT).toFixed(2).toString(),
            x,
            y
          );
        }
        x = x + 20;

        //TTC
        if (invoice.data.totalHT !== 0) {
          pdf.text(
            parseFloat(invoice.data.totalTTC).toFixed(2).toString(),
            x,
            y
          );
        }
        x = x + 20;

        //QUANTITY
        pdf.text("1", x, y);
        x = x + 20;

        //HT
        pdf.text(parseFloat(invoice.data.totalHT).toFixed(2).toString(), x, y);
        x = x + 20;

        y = y + 10;
        break;
      }
      // var splitText = pdf.splitTextToSize(longString, wrapWidth);
      // for (var i = 0, length = splitText.length; i < length; i++) {
      //   // loop thru each line and increase
      //   pdf.text(splitText[i] || "", x, y);
      //   line = lineHeight + line
      // }
      pdf.text(product.label || "", x, y, {
        maxWidth: 80,
        lineHeightFactor: 1,
      });
      x = 120;

      pdf.text(parseFloat(product.priceHT).toFixed(2).toString(), x, y);
      x = x + 20;
      pdf.text(parseFloat(product.priceTTC).toFixed(2).toString(), x, y);
      x = x + 20;
      pdf.text(product.quantity.toString(), x, y);
      x = x + 20;
      pdf.text(parseFloat(product.totalht).toFixed(2).toString(), x, y);
      x = x + 20;

      y = y + 10;
    }

    x = 120;
    y = 210;

    if (invoice.data.previousAmount) {
      if (
        invoice.data.total_vat_0_before_balance &&
        invoice.data.total_vat_0_before_balance !== 0
      ) {
        pdf.text("Total TVA 0%", x, y);
        const totalVAT0BeforePosition = pdf.getTextDimensions(
          invoice.data.total_vat_0_before_balance.toFixed(2).toString()
        );
        pdf.text(
          parseFloat(invoice.data.total_vat_0_before_balance)
            .toFixed(2)
            .toString(),
          190 - totalVAT0BeforePosition.w,
          y
        );
        y = y + interval;
      }

      if (
        invoice.data.total_vat_5_before_balance &&
        invoice.data.total_vat_5_before_balance !== 0
      ) {
        pdf.text("Total TVA 5%", x, y);
        const totalVAT5PositionBefore = pdf.getTextDimensions(
          invoice.data.total_vat_5_before_balance.toFixed(2).toString()
        );
        pdf.text(
          parseFloat(invoice.data.total_vat_5_before_balance)
            .toFixed(2)
            .toString(),
          190 - totalVAT5PositionBefore.w,
          y
        );
        y = y + interval;
      }

      if (
        invoice.data.total_vat_10_before_balance &&
        invoice.data.total_vat_10_before_balance !== 0
      ) {
        pdf.text("Total TVA 10%", x, y);
        const totalVAT0PositionBefore = pdf.getTextDimensions(
          invoice.data.total_vat_10_before_balance.toFixed(2).toString()
        );
        pdf.text(
          parseFloat(invoice.data.total_vat_10_before_balance)
            .toFixed(2)
            .toString(),
          190 - totalVAT0PositionBefore.w,
          y
        );
        y = y + interval;
      }

      if (
        invoice.data.total_vat_20_before_balance &&
        invoice.data.total_vat_20_before_balance !== 0
      ) {
        pdf.text("Total TVA 20%", x, y);
        const totalVAT0PositionBefore = pdf.getTextDimensions(
          invoice.data.total_vat_20_before_balance.toFixed(2).toString()
        );
        pdf.text(
          parseFloat(invoice.data.total_vat_20_before_balance)
            .toFixed(2)
            .toString(),
          190 - totalVAT0PositionBefore.w,
          y
        );
        y = y + interval;
      }

      if (invoice.data.totalHT_before_balance) {
        pdf.text("Total HT", x, y);
        const totalHTPositionBefore = pdf.getTextDimensions(
          invoice.data.totalHT_before_balance.toFixed(2).toString()
        );
        pdf.text(
          parseFloat(invoice.data.totalHT_before_balance).toFixed(2).toString(),
          190 - totalHTPositionBefore.w,
          y
        );

        y = y + interval;
      }

      if (invoice.data.totalTTC_before_balance) {
        pdf.text("Total TTC", x, y);
        const totalTTCPositionBefore = pdf.getTextDimensions(
          invoice.data.totalTTC_before_balance.toFixed(2).toString()
        );
        pdf.text(
          parseFloat(invoice.data.totalTTC_before_balance)
            .toFixed(2)
            .toString(),
          190 - totalTTCPositionBefore.w,
          y
        );

        y = y + interval;
      }
      y = y + interval;

      pdf.text(webPageTranslations["FR"].already_paid, x, y);
      const previousAmountPosition = pdf.getTextDimensions(
        invoice.data.previousAmount.toFixed(2).toString()
      );
      pdf.text(
        parseFloat(invoice.data.previousAmount).toFixed(2).toString(),
        190 - previousAmountPosition.w,
        y
      );
      y = y + interval;
      y = y + interval;
    }

    if (invoice.data.totalHT !== 0) {
      pdf.text(invoice.data.previousAmount ? "Solde HT" : "Total HT", x, y);
      const totalHTPosition = pdf.getTextDimensions(
        invoice.data.totalHT.toFixed(2).toString()
      );
      pdf.text(
        parseFloat(invoice.data.totalHT).toFixed(2).toString(),
        190 - totalHTPosition.w,
        y
      );
      y = y + interval;
    }

    if (invoice.data.total_vat_0 !== 0) {
      pdf.text("Total TVA 0%", x, y);
      const totalVAT0Position = pdf.getTextDimensions(
        invoice.data.total_vat_0.toFixed(2).toString()
      );
      pdf.text(
        parseFloat(invoice.data.total_vat_0).toFixed(2).toString(),
        190 - totalVAT0Position.w,
        y
      );
      y = y + interval;
    }

    if (invoice.data.total_vat_5 !== 0) {
      pdf.text("Total TVA 5%", x, y);
      const totalVAT5Position = pdf.getTextDimensions(
        invoice.data.total_vat_5.toFixed(2).toString()
      );
      pdf.text(
        parseFloat(invoice.data.total_vat_5).toFixed(2).toString(),
        190 - totalVAT5Position.w,
        y
      );
      y = y + interval;
    }

    if (invoice.data.total_vat_10 !== 0) {
      pdf.text("Total TVA 10%", x, y);
      const totalVAT0Position = pdf.getTextDimensions(
        invoice.data.total_vat_10.toFixed(2).toString()
      );
      pdf.text(
        parseFloat(invoice.data.total_vat_10).toFixed(2).toString(),
        190 - totalVAT0Position.w,
        y
      );
      y = y + interval;
    }

    if (invoice.data.total_vat_20 !== 0) {
      pdf.text("Total TVA 20%", x, y);
      const totalVAT0Position = pdf.getTextDimensions(
        invoice.data.total_vat_20.toFixed(2).toString()
      );
      pdf.text(
        parseFloat(invoice.data.total_vat_20).toFixed(2).toString(),
        190 - totalVAT0Position.w,
        y
      );
      y = y + interval;
    }

    pdf.text(invoice.data.previousAmount ? "Solde TTC" : "Total TTC", x, y);
    const totalTTCPosition = pdf.getTextDimensions(
      invoice.data.totalTTC.toFixed(2).toString()
    );
    pdf.text(
      parseFloat(invoice.data.totalTTC).toFixed(2).toString(),
      190 - totalTTCPosition.w,
      y
    );

    if (settings.invoiceFooter) {
      const splitText = pdf.splitTextToSize(settings.invoiceFooter, 180);
      let leftMargin = 0;
      const lineHeight = 5;
      let linePositionY =
        pdf.internal.pageSize.getHeight() - lineHeight * splitText.length;

      //? prevent line starting position fusion with above text
      if (linePositionY <= y) {
        const diff = y - linePositionY;
        linePositionY += diff + 10;
      }

      splitText.forEach((text) => {
        const lineWidth = pdf.getTextWidth(text);
        if (lineWidth > 180) {
          leftMargin = 20;
        } else {
          leftMargin = (210 - lineWidth) / 2;
        }
        // display str
        pdf.text(text, leftMargin, linePositionY);
        linePositionY = lineHeight + linePositionY;
      });
    }

    self.setState({ isLoading: false });
    pdf.save(invoice.invoice_number + ".pdf");
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }
    return null;
  }
}

export default Invoice;
