import React from "react";
import Multilingual from "../../../common/Multilingual";
import { ChromePicker } from "react-color";
import { MDBInput } from "mdbreact";
import ApiService from "../../../services/ApiService";
import { Checkbox, FormControlLabel } from "@mui/material";

class EventConfigPassports extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCloseColor = this.handleCloseColor.bind(this);
    this.handleLang = this.handleLang.bind(this);
    this.handleClickColor = this.handleClickColor.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.getValueLastInputFocus = this.getValueLastInputFocus.bind(this);
    this.handleShortCodeChange = this.handleShortCodeChange.bind(this);
    this.state = {
      displayColorPicker: false,
      event: { ...this.props.event },
      shortCode: "",
      getValueLastInputFocus: [],
      getValueLastInputFocusLabel: "",
      placementList: {},
    };

    this.listShortCode = [
      {
        id: 25,
        text: "Nom, prénom et civilité de l'invité  ",
        shortCode: "[[guest.gender]] [[guest.lastname]] [[guest.firstname]]",
      },
      {
        id: 23,
        text: "Champ personnalisé 1 du package",
        shortCode: "[[data:package.placeholder_1]]",
      },
      {
        id: 24,
        text: "Champ personnalisé 2 du package",
        shortCode: "[[data:package.placeholder_2]]",
      },
      {
        id: 25,
        text: "Champ personnalisé 3 du package",
        shortCode: "[[data:package.placeholder_3]]",
      },
      {
        id: 26,
        text: "Champ personnalisé 4 du package",
        shortCode: "[[data:package.placeholder_4]]",
      },
      {
        id: 27,
        text: "Champ personnalisé 5 du package",
        shortCode: "[[data:package.placeholder_5]]",
      },
      {
        id: 1,
        text: "Civilité de l'invité ",
        shortCode: "[[guest.gender]]",
      },
      {
        id: 3,
        text: "Nom de l'invité",
        shortCode: "[[guest.lastname]]",
      },
      {
        id: 4,
        text: "Prénom de l'invité ",
        shortCode: "[[guest.firstname]]",
      },
      {
        id: 5,
        text: "Nom de la société",
        shortCode: "[[guest.company.name]]",
      },
      {
        id: 6,
        text: "Fonction de l'invité",
        shortCode: "[[guest.function]]",
      },
    ];
  }

  componentDidMount() {
    this.fetchDataFromAPI();
  }

  async fetchDataFromAPI() {
    try {
      const response = await ApiService.request(
        {},
        `placements/byevenement/${this.state.event.id}`,
        "get"
      );
      // Créer un tableau pour stocker tous les nouveaux shortcodes
      const newItems = [];

      // Ajouter les shortcodes de type "plan" au tableau
      newItems.push(
        ...response.map((placement) => ({
          id: placement.id,
          text: `Plan : ${placement.name}`,
          shortCode: `[[plan.${placement.id}.label]]`,
        }))
      );

      // Ajouter les shortcodes de type "placement" au tableau
      newItems.push(
        ...response.map((placement) => ({
          id: placement.id,
          text: `Placement : ${placement.name}`,
          shortCode: `[[plan.${placement.id}.placement]]`,
        }))
      );

      // Zones
      newItems.push(
        ...response.map((placement) => ({
          id: placement.id,
          text: `Zone : ${placement.name}`,
          shortCode: `[[plan.${placement.id}.zone]]`,
        }))
      );

      //Rang
      newItems.push(
        ...response.map((placement) => ({
          id: placement.id,
          text: `Rang : ${placement.name}`,
          shortCode: `[[plan.${placement.id}.rank]]`,
        }))
      );

      //Place
      newItems.push(
        ...response.map((placement) => ({
          id: placement.id,
          text: `Place : ${placement.name}`,
          shortCode: `[[plan.${placement.id}.place]]`,
        }))
      );

      // Appeler la méthode pour mettre à jour la liste des shortcodes
      await this.updateListShortCode(newItems);

      // console.log(this.listShortCode);
    } catch (error) {
      console.error(
        "Une erreur s'est produite lors de la récupération des données de l'API :",
        error
      );
    }
  }

  async updateListShortCode(newItems) {
    this.listShortCode = [...this.listShortCode, ...newItems];

    // Mettez à jour l'état du composant avec la nouvelle liste de shortcodes
    this.setState({ listShortCode: this.listShortCode });
  }

  handleCloseColor = () => {
    this.setState({ displayColorPicker: false });
  };

  handleClickColor = (e, name) => {
    e.preventDefault();
    this.setState({ displayColorPicker: name });
  };

  handleLang(newLangSet, label) {
    this.setState({
      event: {
        ...this.state.event,
        data: {
          ...this.state.event.data,
          [label]: newLangSet,
        },
      },
    });
  }

  handleShortCodeChange(event) {
    event.preventDefault();
    const updatedLanguages = this.state.getValueLastInputFocus.map(
      (language) => {
        return { ...language, value: event.target.value };
      }
    );

    this.setState({
      shortCode: event.target.value,
      event: {
        ...this.state.event,
        data: {
          ...this.state.event.data,
          [this.state.getValueLastInputFocusLabel]: updatedLanguages,
        },
      },
    });
  }
  getValueLastInputFocus(value, label) {
    this.setState({
      getValueLastInputFocus: value,
      getValueLastInputFocusLabel: label,
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.props.onSave({ ...this.state.event });

    e.preventDefault();
  }

  handleDataChange(event) {
    this.setState({
      event: {
        ...this.state.event,
        data: {
          ...this.state.event.data,
          [event.target.name]: event.target.value,
        },
      },
    });
  }

  handleChange(event) {
    this.setState({
      event: {
        ...this.state.event,
        [event.target.name]: event.target.value,
      },
    });
  }

  render() {
    const popover = {
      position: "absolute",
      zIndex: "2",
    };
    const cover = {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    };

    const sizes = [
      { value: 12, label: "Petit" },
      { value: 14, label: "Moyen" },
      { value: 16, label: "Grand" },
    ];

    const { displayColorPicker, event } = this.state;

    const {
      passportName,
      backgroundImgUrl,
      passportTextlineone,
      passportTextlinetwo,
      passportTextlinethree,
      passportTextlinefour,
      passportTextlinefive,
      passportTextlinesix,
      passportBoldlineone,
      passportBoldlinetwo,
      passportBoldlinethree,
      passportBoldlinefour,
      passportBoldlinefive,
      passportBoldlinesix,
      passportColorlineone,
      passportColorlinetwo,
      passportColorlinethree,
      passportColorlinefour,
      passportColorlinefive,
      passportColorlinesix,
      passportSizelineone,
      passportSizelinetwo,
      passporSizelinethree,
      passportSizelinefour,
      passportSizelinefive,
      passportSizelinesix,
      enablePassportForGuest,
      enablePassportForDelegataireGuest,
    } = this.state.event.data;
    return (
      <div className="row">
        <div className="col-md-10 offset-md-1">
          {this.props.subMenu}
          <form onSubmit={this.handleSubmit} className="form">

            <div className="form-row mb-5">
              <div className="form-group col-md-12">
                <label className="font-weight-bold">Utiliser les passeports</label>
                <div className="col-md-12 d-flex">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() =>
                          this.setState({
                            event: {
                              ...event,
                              data: {
                                ...event.data,
                                enablePassportForGuest: !enablePassportForGuest,
                              },
                            },
                          })
                        }
                        id={"enableForGuest"}
                        checked={enablePassportForGuest}
                        sx={{
                          color: "#626262",
                          "&.Mui-checked": {
                            color: "#d81b60",
                          },
                          '&.row-checkbox-pj [type="checkbox"]:not(:checked), [type="checkbox"]:checked':
                          {
                            position: "absolute !important",
                          },
                        }}
                      />
                    }
                    label="Pour les invités"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() =>
                          this.setState({
                            event: {
                              ...event,
                              data: {
                                ...event.data,
                                enablePassportForDelegataireGuest: !enablePassportForDelegataireGuest,
                              },
                            },
                          })
                        }
                        id={"enableForGuest"}
                        checked={enablePassportForDelegataireGuest}
                        sx={{
                          color: "#626262",
                          "&.Mui-checked": {
                            color: "#d81b60",
                          },
                          '&.row-checkbox-pj [type="checkbox"]:not(:checked), [type="checkbox"]:checked':
                          {
                            position: "absolute !important",
                          },
                        }}
                      />
                    }
                    label="Pour les invités des délégataires"
                  />
                </div>
                <div className="col-md-6">
                  <small>Ces options permettent d'activer ou de désactiver le téléchargement des passeports pour les invités ou les invités des délégataires.</small>
                </div>
              </div>
            </div>

            <div className="form-row mb-5">
              <div className="form-group col-md-12">
                <label className="font-weight-bold m-0">Nom du document généré</label>
                <div className="col-md-12">
                  <MDBInput
                    className="col-md-6"
                    name="passportName"
                    value={passportName || ""}
                    onChange={this.handleDataChange}
                  />
                  <div className="col-md-6 p-0">
                    <small>Par défaut le nom du fichier sera "Passeport_NomEvenement.pdf. Il est pas nécessaire d'ajouter l'extension ".pdf" au nom du fichier généré.</small>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row mb-5">
              <div className="form-group col-md-12">
                <label className="font-weight-bold">Visuel du passeport</label>
                <Multilingual
                  lang={backgroundImgUrl}
                  event={this.props.event}
                  type="image"
                  lg="12"
                  handleLang={(e) => this.handleLang(e, "backgroundImgUrl")}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12 m-0">
                <label className="font-weight-bold">Affichage des informations de l'invité sur le document</label>
              </div>

              <div className="form-group mt-4 mb-5 col-md-12 py-3 px-4 border">
                <p className="font-weight-bold mb-1 label-title">Utiliser un champ de fusion ?</p>

                <small>Vous pouvez utiliser un champ de fusion dans les zones ci-dessous. <br />
                  Sélectionnez un champ dans la liste, il s'affectera au dernier élément du formulaire sélectionné.
                </small>
                <div className="d-flex col-md-12 mt-1 p-0">
                  <p className="d-flex align-items-center font-weight-bold my-0 mr-3 label-title">Liste des champs disponibles</p>
                  <select
                    name="passportSizelineone"
                    className="form-control browser-default custom-select col-md-4"
                    onChange={(e) => this.handleShortCodeChange(e)}
                    value={this.state.shortCode || ""}
                  >
                    <option value={""}>Sélectionner un short Code</option>
                    {this.listShortCode.map((item, index) => (
                      <option
                        key={`event-config-passports-size-line1-${index}`}
                        value={item.shortCode}
                      >
                        {item.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group col-md-12">
                <div className="row">
                  <div className="col-md-3">
                    <Multilingual
                      lg="12"
                      event={this.props.event}
                      lang={passportTextlineone}
                      getValueLastInputFocus={(e) =>
                        this.getValueLastInputFocus(e, "passportTextlineone")
                      }
                      type="input"
                      handleLang={(e) =>
                        this.handleLang(e, "passportTextlineone")
                      }
                    />
                  </div>
                  <div className="col-md-2">
                    <MDBInput
                      onClick={() =>
                        this.setState({
                          event: {
                            ...event,
                            data: {
                              ...event.data,
                              passportBoldlineone: !passportBoldlineone,
                            },
                          },
                        })
                      }
                      id={"passportBoldlineone"}
                      label={"En gras"}
                      filled
                      checked={passportBoldlineone}
                      type="checkbox"
                    />
                  </div>
                  <div className="col-md-2">
                    <select
                      name="passportSizelineone"
                      className="form-control browser-default custom-select"
                      onChange={this.handleDataChange}
                      value={passportSizelineone || ""}
                      required
                    >
                      {sizes.map((size, index) => (
                        <option
                          key={`event-config-passports-size-line1-${index}`}
                          value={size.value}
                        >
                          {size.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-1">Couleur de texte</div>
                  <div className="col-md-1">
                    <button
                      className="btn btn-block"
                      style={{
                        backgroundColor: passportColorlineone,
                      }}
                      onClick={(e) =>
                        this.handleClickColor(e, "passportColorlineone")
                      }
                    ></button>
                    {displayColorPicker === "passportColorlineone" ? (
                      <div style={popover}>
                        <div style={cover} onClick={this.handleCloseColor} />
                        <ChromePicker
                          color={passportColorlineone}
                          onChangeComplete={(color) =>
                            this.setState({
                              event: {
                                ...event,
                                data: {
                                  ...event.data,
                                  passportColorlineone: color.hex,
                                },
                              },
                            })
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <Multilingual
                      lg="12"
                      event={this.props.event}
                      lang={passportTextlinetwo}
                      type="input"
                      getValueLastInputFocus={(e) =>
                        this.getValueLastInputFocus(e, "passportTextlinetwo")
                      }
                      handleLang={(e) =>
                        this.handleLang(e, "passportTextlinetwo")
                      }
                    />
                  </div>
                  <div className="col-md-2">
                    <MDBInput
                      onClick={() =>
                        this.setState({
                          event: {
                            ...event,
                            data: {
                              ...event.data,
                              passportBoldlinetwo: !passportBoldlinetwo,
                            },
                          },
                        })
                      }
                      id={"passportBoldlinetwo"}
                      label={"En gras"}
                      filled
                      checked={passportBoldlinetwo}
                      type="checkbox"
                    />
                  </div>
                  <div className="col-md-2">
                    <select
                      name="passportSizelinetwo"
                      className="form-control browser-default custom-select"
                      onChange={this.handleDataChange}
                      value={passportSizelinetwo || ""}
                      required
                    >
                      {sizes.map((size, index) => (
                        <option
                          key={`event-config-passport-size-line2-${index}`}
                          value={size.value}
                        >
                          {size.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-1">Couleur de texte</div>
                  <div className="col-md-1">
                    <button
                      className="btn btn-block"
                      style={{
                        backgroundColor: passportColorlinetwo,
                      }}
                      onClick={(e) =>
                        this.handleClickColor(e, "passportColorlinetwo")
                      }
                    ></button>
                    {displayColorPicker === "passportColorlinetwo" ? (
                      <div style={popover}>
                        <div style={cover} onClick={this.handleCloseColor} />
                        <ChromePicker
                          color={passportColorlinetwo}
                          onChangeComplete={(color) =>
                            this.setState({
                              event: {
                                ...event,
                                data: {
                                  ...event.data,
                                  passportColorlinetwo: color.hex,
                                },
                              },
                            })
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <Multilingual
                      lg="12"
                      event={this.props.event}
                      lang={passportTextlinethree}
                      type="input"
                      getValueLastInputFocus={(e) =>
                        this.getValueLastInputFocus(e, "passportTextlinethree")
                      }
                      handleLang={(e) =>
                        this.handleLang(e, "passportTextlinethree")
                      }
                    />
                  </div>
                  <div className="col-md-2">
                    <MDBInput
                      onClick={() =>
                        this.setState({
                          event: {
                            ...event,
                            data: {
                              ...event.data,
                              passportBoldlinethree: !passportBoldlinethree,
                            },
                          },
                        })
                      }
                      id={"passportBoldlinethree"}
                      label={"En gras"}
                      filled
                      checked={passportBoldlinethree}
                      type="checkbox"
                    />
                  </div>
                  <div className="col-md-2">
                    <select
                      name="passporSizelinethree"
                      className="form-control browser-default custom-select"
                      onChange={this.handleDataChange}
                      value={passporSizelinethree || ""}
                      required
                    >
                      {sizes.map((size, index) => (
                        <option
                          key={`event-config-passport-size-line2-${index}`}
                          value={size.value}
                        >
                          {size.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-1">Couleur de texte</div>
                  <div className="col-md-1">
                    <button
                      className="btn btn-block"
                      style={{
                        backgroundColor: passportColorlinethree,
                      }}
                      onClick={(e) =>
                        this.handleClickColor(e, "passportColorlinethree")
                      }
                    ></button>
                    {displayColorPicker === "passportColorlinethree" ? (
                      <div style={popover}>
                        <div style={cover} onClick={this.handleCloseColor} />
                        <ChromePicker
                          color={passportColorlinethree}
                          onChangeComplete={(color) =>
                            this.setState({
                              event: {
                                ...event,
                                data: {
                                  ...event.data,
                                  passportColorlinethree: color.hex,
                                },
                              },
                            })
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <Multilingual
                      lg="12"
                      event={this.props.event}
                      lang={passportTextlinefour}
                      type="input"
                      getValueLastInputFocus={(e) =>
                        this.getValueLastInputFocus(e, "passportTextlinefour")
                      }
                      handleLang={(e) =>
                        this.handleLang(e, "passportTextlinefour")
                      }
                    />
                  </div>
                  <div className="col-md-2">
                    <MDBInput
                      onClick={() =>
                        this.setState({
                          event: {
                            ...event,
                            data: {
                              ...event.data,
                              passportBoldlinefour: !passportBoldlinefour,
                            },
                          },
                        })
                      }
                      id={"passportBoldlinefour"}
                      label={"En gras"}
                      filled
                      checked={passportBoldlinefour}
                      type="checkbox"
                    />
                  </div>
                  <div className="col-md-2">
                    <select
                      name="passportSizelinefour"
                      className="form-control browser-default custom-select"
                      onChange={this.handleDataChange}
                      value={passportSizelinefour || ""}
                      required
                    >
                      {sizes.map((size, index) => (
                        <option
                          key={`event-config-passport-size-line2-${index}`}
                          value={size.value}
                        >
                          {size.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-1">Couleur de texte</div>
                  <div className="col-md-1">
                    <button
                      className="btn btn-block"
                      style={{
                        backgroundColor: passportColorlinefour,
                      }}
                      onClick={(e) =>
                        this.handleClickColor(e, "passportColorlinefour")
                      }
                    ></button>
                    {displayColorPicker === "passportColorlinefour" ? (
                      <div style={popover}>
                        <div style={cover} onClick={this.handleCloseColor} />
                        <ChromePicker
                          color={passportColorlinefour}
                          onChangeComplete={(color) =>
                            this.setState({
                              event: {
                                ...event,
                                data: {
                                  ...event.data,
                                  passportColorlinefour: color.hex,
                                },
                              },
                            })
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <Multilingual
                      lg="12"
                      event={this.props.event}
                      lang={passportTextlinefive}
                      type="input"
                      getValueLastInputFocus={(e) =>
                        this.getValueLastInputFocus(e, "passportTextlinefive")
                      }
                      handleLang={(e) =>
                        this.handleLang(e, "passportTextlinefive")
                      }
                    />
                  </div>
                  <div className="col-md-2">
                    <MDBInput
                      onClick={() =>
                        this.setState({
                          event: {
                            ...event,
                            data: {
                              ...event.data,
                              passportBoldlinefive: !passportBoldlinefive,
                            },
                          },
                        })
                      }
                      id={"passportBoldlinefive"}
                      label={"En gras"}
                      filled
                      checked={passportBoldlinefive}
                      type="checkbox"
                    />
                  </div>
                  <div className="col-md-2">
                    <select
                      name="passportSizelinefive"
                      className="form-control browser-default custom-select"
                      onChange={this.handleDataChange}
                      value={passportSizelinefive || ""}
                      required
                    >
                      {sizes.map((size, index) => (
                        <option
                          key={`event-config-passport-size-line2-${index}`}
                          value={size.value}
                        >
                          {size.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-1">Couleur de texte</div>
                  <div className="col-md-1">
                    <button
                      className="btn btn-block"
                      style={{
                        backgroundColor: passportColorlinefive,
                      }}
                      onClick={(e) =>
                        this.handleClickColor(e, "passportColorlinefive")
                      }
                    ></button>
                    {displayColorPicker === "passportColorlinefive" ? (
                      <div style={popover}>
                        <div style={cover} onClick={this.handleCloseColor} />
                        <ChromePicker
                          color={passportColorlinefive}
                          onChangeComplete={(color) =>
                            this.setState({
                              event: {
                                ...event,
                                data: {
                                  ...event.data,
                                  passportColorlinefive: color.hex,
                                },
                              },
                            })
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <Multilingual
                      lg="12"
                      event={this.props.event}
                      lang={passportTextlinesix}
                      type="input"
                      getValueLastInputFocus={(e) =>
                        this.getValueLastInputFocus(e, "passportTextlinesix")
                      }
                      handleLang={(e) =>
                        this.handleLang(e, "passportTextlinesix")
                      }
                    />
                  </div>
                  <div className="col-md-2">
                    <MDBInput
                      onClick={() =>
                        this.setState({
                          event: {
                            ...event,
                            data: {
                              ...event.data,
                              passportBoldlinesix: !passportBoldlinesix,
                            },
                          },
                        })
                      }
                      id={"passportBoldlinesix"}
                      label={"En gras"}
                      filled
                      checked={passportBoldlinesix}
                      type="checkbox"
                    />
                  </div>
                  <div className="col-md-2">
                    <select
                      name="passportSizelinesix"
                      className="form-control browser-default custom-select"
                      onChange={this.handleDataChange}
                      value={passportSizelinesix || ""}
                      required
                    >
                      {sizes.map((size, index) => (
                        <option
                          key={`event-config-passport-size-line2-${index}`}
                          value={size.value}
                        >
                          {size.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-1">Couleur de texte</div>
                  <div className="col-md-1">
                    <button
                      className="btn btn-block"
                      style={{
                        backgroundColor: passportColorlinesix,
                      }}
                      onClick={(e) =>
                        this.handleClickColor(e, "passportColorlinesix")
                      }
                    ></button>
                    {displayColorPicker === "passportColorlinesix" ? (
                      <div style={popover}>
                        <div style={cover} onClick={this.handleCloseColor} />
                        <ChromePicker
                          color={passportColorlinesix}
                          onChangeComplete={(color) =>
                            this.setState({
                              event: {
                                ...event,
                                data: {
                                  ...event.data,
                                  passportColorlinesix: color.hex,
                                },
                              },
                            })
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <input
              type="submit"
              value="Enregistrer"
              className="btn pink darken-1 btn-lg float-right "
            />
          </form>
        </div >
      </div >
    );
  }
}

export default EventConfigPassports;
