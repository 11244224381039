import React, { useEffect, useState, useRef } from "react";
import { 
  // ReactSVGPanZoom,
   TOOL_NONE } from "react-svg-pan-zoom";
import {
  convertStylesStringToObject,
  isExist,
  isMobile,
  isTablet,
} from "../services/Utils";
// import { Button } from "mdbreact";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import "./PlacementHome.css";
import { isBrowser } from "react-device-detect";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCompressArrowsAlt } from "@fortawesome/free-solid-svg-icons";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export default function PlacementPlan({ setIsLoadingHome, placement, guests, delegataireList}) {
  const viewerWidth = "100%";
  var viewerHeight = 500;
  var INITIAL_VALUE = "";
  if (isMobile) {
    //Debug for A22
    //window.screen.height = 857
    /*
    if (window.screen.height > 800) {
      viewerHeight = 400;
    }
    if (800 > window.screen.height && window.screen.height > 730) {
      viewerHeight = 350;
    }
    if (730 > window.screen.height && window.screen.height > 665) {
      viewerHeight = 280;
    }
    if (665 > window.screen.height && window.screen.height > 630) {
      viewerHeight = 250;
    }
    if (630 > window.screen.height && window.screen.height > 575) {
      viewerHeight = 200;
    }
    if (575 > window.screen.height && window.screen.height > 565) {
      viewerHeight = 180;
    }
    if (565 > window.screen.height) {
      viewerHeight = 100;
    }*/
    viewerHeight = window.screen.height - 270;
    // console.log(viewerHeight, "height")
    console.log(window.screen.height, "window height");
    INITIAL_VALUE = {
      SVGHeight: viewerHeight,
      SVGMinX: 0,
      SVGMinY: 0,
      SVGWidth: "100%",
      a: 0.5131581182307068,
      b: 0,
      c: 0,
      d: 0.5131581182307068,
      e: 5.721758547800302,
      endX: null,
      endY: null,
      f: 63.86240742181953,
      focus: false,
      lastAction: "pan",
      miniatureOpen: true,
      mode: "idle",
      pinchPointDistance: null,
      prePinchMode: null,
      scaleFactorMax: null,
      scaleFactorMin: null,
      startX: null,
      startY: null,
      version: 3,
      viewerHeight: viewerHeight,
      viewerWidth: viewerWidth,
    };
  } else if (isTablet) {
    INITIAL_VALUE = {
      SVGHeight: viewerHeight,
      SVGMinX: 0,
      SVGMinY: 0,
      SVGWidth: "100%",
      a: 0.5131581182307068,
      b: 0,
      c: 0,
      d: 0.5131581182307068,
      e: 187.0420710478003,
      endX: null,
      endY: null,
      f: 62.02646992181958,
      focus: false,
      lastAction: "pan",
      miniatureOpen: true,
      mode: "idle",
      pinchPointDistance: null,
      prePinchMode: null,
      scaleFactorMax: null,
      scaleFactorMin: null,
      startX: null,
      startY: null,
      version: 3,
      viewerHeight: viewerHeight,
      viewerWidth: viewerWidth,
    };
  } else if (isBrowser) {
    INITIAL_VALUE = {
      SVGHeight: viewerHeight,
      SVGMinX: 0,
      SVGMinY: 0,
      SVGWidth: "100%",
      a: 0.5131581182307069,
      b: 0,
      c: 0,
      d: 0.5131581182307069,
      e: 484.87049132446634,
      endX: null,
      endY: null,
      f: 106.45961664394771,
      focus: false,
      lastAction: "pan",
      miniatureOpen: true,
      mode: "idle",
      pinchPointDistance: null,
      prePinchMode: null,
      scaleFactorMax: null,
      scaleFactorMin: null,
      startX: null,
      startY: null,
      version: 3,
      viewerHeight: viewerHeight,
      viewerWidth: viewerWidth,
    };
  }
  const [guestSearchedId, setGuestSearchedId] = useState(null);
  const [idsSelected, setIdsSelected] = useState(placement.data.seat);
  const [tool, setTool] = useState(TOOL_NONE);
  const [value, setValue] = useState(INITIAL_VALUE);
  const [ranger, setRanger] = useState("");
  const [place, setPlace] = useState("");
  const [zone, setZone] = useState("");
  const ViewerRef = useRef(null);
  useEffect(() => {
    setIsLoadingHome(false);
    setUpdateSeat();
  }, [guests, placement]);

  useEffect(() => {}, [zone, ranger, place]);
  //GET the X AND Y SVG where SVG CHANGE
  useEffect(() => {
    // const { a, d, e, f } = value;
    // console.log(a, d, e, f);
  }, [value]);
  const setUpdateSeat = () => {
    if (isExist(() => placement.data.seat)) {
      setIdsSelected(placement.data.seat);
    }
  };
  function handleChange(event) {
    const valueEvent = event ? event.value : null;

    const guest = idsSelected
      ? idsSelected.find((i) => i.guestId === valueEvent)
      : null;

    const html = guest ? document.getElementById(guest.seatId) : null;
    if (guest) {
      var rangerGuest;
      var placeGuest;
      var zoneGuest;
      if (guest.seatId) {
        var seatId = guest.seatId.toString();
        zoneGuest = seatId.substr(0, seatId.indexOf("_r"));
        rangerGuest = seatId.substring(
          seatId.lastIndexOf("r") + 1,
          seatId.lastIndexOf("_p")
        );
        placeGuest = seatId.split("_")[3] // Permet de supprimer le _i à l'affichage s'il existe
          ? seatId.substring(
              seatId.lastIndexOf("p") + 1,
              seatId.lastIndexOf("_")
            )
          : seatId.substring(
              seatId.lastIndexOf("p") + 1,
              seatId.lastIndexOf("")
            );
      } else {
        rangerGuest = null;
        placeGuest = null;
        zoneGuest = null;
      }
      setRanger(rangerGuest);
      setPlace(placeGuest);
      setZone(zoneGuest);
    } else {
      setRanger(null);
      setPlace(null);
      setZone(null);
    }
    console.log("guest : ", guest);
    console.log("html : ", html);
    setGuestSearchedId(valueEvent);
    setValue(html ? INITIAL_VALUE : INITIAL_VALUE);
    if (!guest && valueEvent) {
      toast.warn("Invité non placé", { theme: "colored" });
    }
    if (rangerGuest && placeGuest && zoneGuest) {
      toast.success("Invité placé", { theme: "colored" });
    }
  }

  function changeTool(nextTool) {
    setTool(nextTool);
  }

  function changeValue(nextValue) {
    setValue(nextValue);
  }

  function onClickRect(id) {
    // console.clear()
    if (id) {
      const zone = id.substr(0, id.indexOf("_r"));
      const ranger = id.substring(
        id.lastIndexOf("r") + 1,
        id.lastIndexOf("_p")
      );
      const place = id.split("_")[3] // Permet de supprimer le _i à l'affichage s'il existe
        ? id.substring(id.lastIndexOf("p") + 1, id.lastIndexOf("_"))
        : id.substring(id.lastIndexOf("p") + 1, id.lastIndexOf(""));

      if (zone) {
        setZone(zone);
      }
      if (ranger) {
        setRanger(ranger);
      }
      if (place) {
        setPlace(place);
      }
    }
  }
  const div = document.createElement("div");

  const { svg_content } = placement;
  div.innerHTML = svg_content;

  const plans = div.querySelectorAll("[id^=plan]");

  const pagesToPrint = [];

  let rectangles = [];
  let idSplitRanger = "";
  let idSplitPlace = "";
  let idSplitZone = "";

  plans.forEach((plan, keyplan) => {
    const table = Array.prototype.slice.call(plan.querySelectorAll("text"));

    const title = table.length > 0 ? table[0].textContent : plan.id;

    const planRectangles = Array.prototype.slice.call(
      plan.querySelectorAll("rect,text")
    );

    let highx = 0;
    let highy = 0;
    let lowx = 100000;
    let lowy = 100000;

    const elements = planRectangles.map((rectangle, key) => {
      const attributes = Array.prototype.slice.call(rectangle.attributes);
      if (!attributes.find((attribute) => attribute.name === "id")) {
        if (rectangle.nodeName === "rect") {
          return "";
        }
      }
      var replaceIdText =
        rectangle.nodeName === "text"
          ? rectangle.textContent.replace(" ", "_")
          : null;
      const id =
        rectangle.nodeName === "rect"
          ? attributes.find((attribute) => attribute.name === "id")
            ? attributes.find((attribute) => attribute.name === "id").nodeValue
            : ""
          : "Text_" + replaceIdText;

      const disable =
        rectangle.nodeName === "rect"
          ? attributes.some((attribute) => attribute.name === "data-disabled")
          : null;
      const seat = idsSelected
        ? idsSelected.find((item) => item.seatId === id)
        : null;

      const guest = seat
        ? guests.find((guest) => guest.id === seat.guestId)
        : null;

      const x =
        rectangle.nodeName === "rect"
          ? attributes.find((attribute) => attribute.name === "x")
            ? Number(
                attributes.find((attribute) => attribute.name === "x").nodeValue
              )
            : null
          : Number(
              attributes
                .find((attribute) => attribute.name === "transform")
                .nodeValue.match(/matrix.*\((.+)\)/)[1]
                .split(" ")[4]
            );
      const y =
        rectangle.nodeName === "rect"
          ? attributes.find((attribute) => attribute.name === "y")
            ? Number(
                attributes.find((attribute) => attribute.name === "y").nodeValue
              )
            : null
          : Number(
              attributes
                .find((attribute) => attribute.name === "transform")
                .nodeValue.match(/matrix.*\((.+)\)/)[1]
                .split(" ")[5]
            );

      var style =
        rectangle.nodeName === "rect"
          ? attributes.find((attribute) => attribute.name === "style")
            ? attributes.find((attribute) => attribute.name === "style")
                .nodeValue
            : null
          : convertStylesStringToObject(
              attributes.find((attribute) => attribute.name === "style")
                .nodeValue
            );

      const dataMessage =
        rectangle.nodeName === "rect"
          ? attributes.some((attribute) => attribute.name === "data-message")
            ? attributes.find((attribute) => attribute.name === "data-message")
                .nodeValue
            : ""
          : "";

      if (x > highx) {
        highx = x;
      }

      if (x < lowx) {
        lowx = x;
      }

      if (y > highy) {
        highy = y;
      }

      if (y < lowy) {
        lowy = y;
      }

      const width =
        rectangle.nodeName === "rect"
          ? attributes.find((attribute) => attribute.name === "width")
            ? Number(
                attributes.find((attribute) => attribute.name === "width")
                  .nodeValue
              )
            : null
          : "";
      const height =
        rectangle.nodeName === "rect"
          ? attributes.find((attribute) => attribute.name === "height")
            ? Number(
                attributes.find((attribute) => attribute.name === "height")
                  .nodeValue
              )
            : null
          : "";

          let delegataire = "";   
          let nomSociete = "";   

      if (rectangle.nodeName === "rect") {
        if (id) {
          idSplitZone = id.substr(0, id.indexOf("_r"));
          idSplitRanger = id.substring(
            id.lastIndexOf("r") + 1,
            id.lastIndexOf("_p")
          );
          idSplitPlace = id.split("_")[3] // Permet de supprimer le _i à l'affichage s'il existe
            ? id.substring(id.lastIndexOf("p") + 1, id.lastIndexOf("_"))
            : id.substring(id.lastIndexOf("p") + 1, id.lastIndexOf(""));
        }

        if (guest && guest.user_id) {
          const delegataireOption = delegataireList.find(option => option.user_id === guest.user_id);
          if (delegataireOption) {
            delegataire = "<br/> Délégataire : " + delegataireOption.user.firstname + " " + delegataireOption.user.lastname;
          }
      }
      if (guest && guest.contact) {
          if(guest.contact.company){
            nomSociete = "<br/> Société : " + guest.contact.company.name;
          }
      }
    }

      const rect =
        rectangle.nodeName === "rect" ? (
          <rect
            key={String(keyplan) + String(key)}
            data-for="tooltip"
            lastname={guest ? guest.lastname : ""}
            firstname={guest ? guest.firstname : ""}
            data-html="true"
            data-tip={
              guest
                ? `${guest.lastname} ${guest.firstname} ${delegataire} ${nomSociete} <br/> Zone : ${idSplitZone} <br/> Rangée : ${idSplitRanger} <br/> Place : ${idSplitPlace}`
                : `Zone : ${idSplitZone} <br/> Rangée : ${idSplitRanger} <br/> Place : ${idSplitPlace}`
            }
            fill={
              disable
                ? "#454546"
                : guest
                ? seat
                  ? guestSearchedId === guest.id
                    ? "#FFD700"
                    : "#dc2077"
                  : "#7A7B7E"
                : String(style.slice(5, 12)) === "#FFFFFF"
                ? "#7A7B7E"
                : String(style.slice(5, 12))
            }
            stroke={"#616264"}
            strokeWidth={"1px"}
            id={id}
            data-message={dataMessage}
            x={x}
            y={y}
            width={width}
            height={height}
            onClick={() => onClickRect(id)}
          />
        ) : (
          <text id={replaceIdText} x={x} y={y} style={style}>
            {rectangle.textContent}
          </text>
        );

      return rect;
    });

    rectangles = rectangles.concat(elements);

    pagesToPrint.push({
      elements,
      highx,
      highy,
      lowx,
      lowy,
      id: title,
    });
  });
  const minX = Math.min(...rectangles.map(rectangle => rectangle.props.x));
  const maxX = Math.max(...rectangles.map(rectangle => rectangle.props.x));

  const minY = Math.min(...rectangles.map(rectangle => rectangle.props.y));
  const maxY = Math.max(...rectangles.map(rectangle => rectangle.props.y));

  const adjustedRectangles = rectangles.map(rectangle => ({
    ...rectangle,
    props: {
      ...rectangle.props,
      y: rectangle.props.y - minY + 100
    }
  }));
  
  const differenceX = maxX - minX + 400;
  const differenceY = maxY - minY + 200;
  const svg = (
    <svg
      id="svgdocument"
      className="svg-rect"
      width={differenceX}
      height={differenceY}
      x="0px"
      y="0px"
      // style={{
      //   position: "absolute",
      //   top: "50%",
      //   left: "50%",
      //   margin: "-25px 0 0 -25px",
      // }}
    >
      {adjustedRectangles.map((rectangle) => rectangle)}
    </svg>
  );

  return (
    <SvgContainer
      guests={guests}
      handleChange={handleChange}
      initialValue={INITIAL_VALUE}
      setValue={setValue}
      viewerWidth={viewerWidth}
      viewerHeight={viewerHeight}
      tool={tool}
      changeTool={changeTool}
      value={value}
      changeValue={changeValue}
      viewerRef={ViewerRef}
      svg={svg}
      isMobile={isMobile}
      zone={zone}
      ranger={ranger}
      place={place}
    />
  );
}

const SvgContainer = ({
  guests,
  handleChange,
  initialValue,
  setValue,
  viewerWidth,
  viewerHeight,
  tool,
  changeTool,
  value,
  changeValue,
  viewerRef,
  svg,
  isMobile,
  zone,
  ranger,
  place,
}) => {
  return (
    <div id="svg-container">
      <ReactTooltip id="tooltip" getContent={(dataTip) => dataTip} />
      <div
        className="row row-placement-title"
        style={{ marginTop: 70, display: "flex", flexWrap: "wrap" }}
      >
        <div className="col-12">
          <div className="form-group placement-guest-search" style={{position:'relative', zIndex: "2", width:"100%"}}>
            <Select
              placeholder="Rechercher un invité"
              isClearable
              options={guests.map((guest) => ({
                label: guest.lastname + " " + guest.firstname,
                value: guest.id,
              }))}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* <div className="col-2 text-right"> 
           <Button
                    onClick={() => setValue(initialValue)}
                    className="btn pink darken-1 "
                    style={{ marginLeft: 50 }}
                >
                    <FontAwesomeIcon
                        icon="bullseye-pointer"
                        color="#fff"
                    />
                </Button> 
          0  
        </div> */}
      </div>

      <TransformWrapper
        initialScale={0.8}
        initialPositionX={0}
        initialPositionY={0}
      >
        {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
          <React.Fragment>
            <div className="tools" style={{ position: "absolute", zIndex: 1, margin: "10px" }}>
              <button onClick={() => zoomIn()} style={{width:"55px", height: "35px", border: "none", borderRadius:"5px", marginBottom: "5px", marginRight:"10px"}}>+</button> 
              <button onClick={() => zoomOut()} style={{width:"55px", height: "35px", border: "none", borderRadius:"5px", marginBottom: "5px", marginRight:"10px"}}>-</button> 
              <button onClick={() => resetTransform()} style={{width:"120px", height: "35px", border: "none", borderRadius:"5px", marginBottom: "5px", marginRight:"10px"}}>Réinitialiser</button> 
            </div>
            <div style={{backgroundColor: "white", justifyContent: 'center', display: "flex", position: "relative" }}>
              <TransformComponent>
                  {svg}
              </TransformComponent>
            </div>
          </React.Fragment>
        )}
      </TransformWrapper>

      {/* <ReactSVGPanZoom
        className="placement-map"
        SVGBackground={"#616264"}
        tool={tool}
        onChangeTool={(tool) => changeTool(tool)}
        value={value}
        onChangeValue={(value) => changeValue(value)}
        ref={viewerRef}
      >
        {svg}
      </ReactSVGPanZoom> */}

      <div className="placement-guest-infos">
        <div className={`row  ${!isMobile && "row-plan"}`}>
          <div className={"col-md-3"}>
            <p className={"white metric-panel text-left"}>
              ZONE
              {!isMobile && <br />}
              <span
                className="font-weight-bold ml-3"
                style={{ fontSize: "30px" }}
              >
                {zone ? zone : null}
              </span>
            </p>
          </div>
          <div className="col-child">
            <div className={"col-md-3"}>
              <p className={"white metric-panel child"}>
                RANGÉE
                {!isMobile && <br />}
                <span className="font-weight-bold" style={{ fontSize: "30px" }}>
                  {ranger ? ranger : null}
                </span>
              </p>
            </div>
            <div className={"col-md-3"}>
              <p className={"white metric-panel child"}>
                PLACE
                {!isMobile && <br />}
                <span className="font-weight-bold" style={{ fontSize: "30px" }}>
                  {place ? place : null}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
