import React from "react";
import { Button } from "mdbreact";
import Webpage from "./WebPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMap,
  faCheckSquare,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

class GuestPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: this.props.modal ? true : false,
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    //console.log(this.props.guest)
    return (
      <span>
        <FontAwesomeIcon
          title={
            this.props.documentType === "website"
              ? "Voir le programme"
              : "Voir / Modifier la réponse"
          }
          onClick={this.toggle}
          icon={this.props.documentType === "website" ? faMap : faCheckSquare}
        />
        <Dialog
          open={this.state.modal}
          onClose={this.toggle}
          aria-labelledby="guest-dialog-title"
          aria-describedby="guest-dialog-description"
          PaperProps={{ sx: { minWidth: "700px" } }}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "end" }}>
            <FontAwesomeIcon onClick={this.toggle} icon={faTimes} />
          </DialogTitle>
          <DialogContent>
            <Webpage
              isAdmin={true}
              {...this.props}
              loadData={this.props.loadData}
              guestId={this.props.guest.id}
              eventId={this.props.event.id}
              isModifiable={true}
            />
          </DialogContent>
          <DialogActions>
            <Button
              className="btn pink darken-1 white-text"
              onClick={this.toggle}
            >
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    );
  }
}

export default GuestPage;
