import React from "react";
import ApiService from "../../../services/ApiService";
import FileUpload from "../../../common/FileUpload";
import { Button, MDBInput } from "mdbreact";
import { Editor } from "@tinymce/tinymce-react";
import Loader from "../../../common/Loader";
import { uploadPicture } from "../../../services/Utils";
import { ChromePicker } from "react-color";
import { toast } from "react-toastify";

class TemplateForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.removeIcon = this.removeIcon.bind(this);
    this.handleClickColor = this.handleClickColor.bind(this);
    this.handleCloseColor = this.handleCloseColor.bind(this);
    this.handleBodyChange = this.handleBodyChange.bind(this);

    this.dataRegex = /\[\[(.*?)\]\]/gm;
    this.validDataFormatList = [
      "[[data:access_token]]",
      "[[data:contact.gender]]",
      "[[data:contact.mail]]",
      "[[data:contact.lastname]]",
      "[[data:contact.firstname]]",
      "[[data:contact.mobile]]",
      "[[data:contact.phone]]",
      "[[data:max_invitation]]",
      "[[data:contact.company_function]]",
      "[[data:contact.company_service]]",
      "[[data:contact.address]]",
      "[[data:contact.address_more]]",
      "[[data:contact.zip_code]]",
      "[[data:contact.city]]",
      "[[data:contact.country]]",
      "[[data:contact.company_name]]",
      "[[data:evenement.start_date]]",
      "[[data:evenement.end_date]]",
      "[[data:delegataire.invitation]]",
      "[[data:user.firstname]]",
      "[[data:user.lastname]]",
      "[[data:delegataire.logo]]",
      "[[data:delegataire.contact]]",
      "[[data:guest.id]]",
      "[[data:evenement.id]]",
      "[[data:package.name]]",
      "[[data:package.placeholder_1]]",
      "[[data:package.placeholder_2]]",
      "[[data:package.placeholder_3]]",
      "[[data:package.placeholder_4]]",
      "[[data:package.placeholder_5]]",
      "[[data:reponserapide]]",
    ];

    this.state = {
      template: {
        delegataire: false,
        name: "",
        description: "",
        evenement_id: this.props.match.params.eventid,
        body: "",
        image_header: "",
        image_footer: "",
        text_footer: "",
        body_background_color: "#ffffff",
        body_text_color: "#ffffff",
        content_background_color: "#ffffff",
        content_text_color: "#000000",
        add_attachments: false,
        not_send_guests_mail_attachments_empty: false,
        send_only_participant: false,
      },
      displayColorPicker: "",
      isLoading: false,
      bodyDataFormat: true,
      footerDataFormat: true,
    };
  }

  handleClickColor = (e, name) => {
    e.preventDefault();
    this.setState({ displayColorPicker: name });
  };

  handleCloseColor = () => {
    this.setState({ displayColorPicker: "" });
  };

  async componentDidMount() {
    const self = this;
    if (this.props.match.params.templateid) {
      self.setState({ ...self.state, isLoading: true });
      await ApiService.request(
        this.state,
        "mailtemplates/" + this.props.match.params.templateid,
        "get"
      ).then(function (data) {
        self.setState({
          isLoading: false,
          template: { ...self.state.template, ...data },
        });
      });
    }
  }

  handleChange(e, label) {
    const name = label ? label : e.target.name;
    const value = label ? e : e.target.value;

    this.setState({
      template: {
        ...this.state.template,
        [name]: value,
      },
    });
  }

  checkDataFormat(content) {
    const found = content.match(this.dataRegex);

    if (found) {
      for (const item of found) {
        if (!this.validDataFormatList.includes(item)) {
          return false;
        }
      }
    }
    return true;
  }

  handleBodyChange(e) {
    const isDataFormatGood = this.checkDataFormat(e);

    this.setState({
      template: {
        ...this.state.template,
        body: e,
      },
      bodyDataFormat: isDataFormatGood,
    });
  }

  handleTextFooterChange(e) {
    const isDataFormatGood = this.checkDataFormat(e);
    this.setState({
      template: {
        ...this.state.template,
        text_footer: e,
      },
      footerDataFormat: isDataFormatGood,
    });
  }

  removeIcon(file) {
    this.setState({
      template: { ...this.state.template, [file]: "" },
    });
  }

  onFileUpload(id, name) {
    const key = [name];
    this.setState({
      template: {
        ...this.state.template,
        [key]: `https://res.cloudinary.com/kanguroo-event/image/upload/w_600/${id}`,
      },
    });
  }

  async handleSubmit(e) {
    e.preventDefault();

    if (!(this.state.bodyDataFormat && this.state.footerDataFormat)) {
      let type = !this.state.bodyDataFormat
        ? !this.state.footerDataFormat
          ? "du corps et du footer"
          : "du corps du texte"
        : "du footer";
      toast.error(
        `Le contenu ${type} est invalide. Vérifier le format des données`
      );
      return;
    }

    this.setState({ isLoading: true });
    let method = this.state.template.id ? "put" : "post";
    let id = this.state.template.id ? this.state.template.id : "";
    const self = this;
    await ApiService.request(
      { ...self.state.template },
      "mailtemplates/" + id,
      method
    ).then(function (data) {
      toast.success("Template sauvegardé", {
        autoClose: 3000,
      });

      self.props.history.push(
        "/admin/events/edit/" + self.props.match.params.eventid + "/templates"
      );
    });
  }

  render() {
    const { template, isLoading, bodyDataFormat, footerDataFormat } =
      this.state;

    const popover = {
      position: "absolute",
      zIndex: "2",
    };
    const cover = {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    };

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <form onSubmit={this.handleSubmit} className="form">
            <div className="form-row">
              <div className="form-group col-md-4">
                <label>Nom</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={template.name}
                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="form-group col-md-8">
                <label>Description</label>
                <input
                  type="text"
                  name="description"
                  className="form-control"
                  value={template.description}
                  onChange={this.handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-4">
                <MDBInput
                  onClick={() =>
                    this.setState({
                      template: {
                        ...this.state.template,
                        delegataire: !template.delegataire,
                      },
                    })
                  }
                  id={"delegataire"}
                  label={"Template délégataires"}
                  filled
                  checked={template.delegataire}
                  type="checkbox"
                  className="template-checkbox"
                />
              </div>
              <div className="col-md-4">
                <MDBInput
                  onClick={() =>
                    this.setState({
                      template: {
                        ...this.state.template,
                        add_attachments: !template.add_attachments,
                      },
                    })
                  }
                  id={"addAttachments"}
                  label={"Ajouter les pieces jointes"}
                  filled
                  checked={template.add_attachments}
                  type="checkbox"
                  className="template-checkbox"
                />
              </div>
              {template.add_attachments && (
                <div className="col-md-4">
                  <MDBInput
                    onClick={() =>
                      this.setState({
                        template: {
                          ...this.state.template,
                          not_send_guests_mail_attachments_empty:
                            !template.not_send_guests_mail_attachments_empty,
                        },
                      })
                    }
                    id={"notSendGuestsMailAttachmentsEmpty"}
                    label={
                      "Ne pas envoyer l'email aux contacts n'ayant pas de document joint"
                    }
                    filled
                    checked={template.not_send_guests_mail_attachments_empty}
                    type="checkbox"
                    className="template-checkbox"
                  />
                </div>
              )}
              <div className="col-md-4">
                <MDBInput
                  onClick={() =>
                    this.setState({
                      template: {
                        ...this.state.template,
                        send_only_participant: !template.send_only_participant,
                      },
                    })
                  }
                  id={"sendOnlyParticipant"}
                  label={"Envoyer uniquement sur les participants"}
                  filled
                  checked={template.send_only_participant}
                  type="checkbox"
                  className="template-checkbox"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-3">
                <label>Couleur de fond du body</label>
                <button
                  className="btn btn-block"
                  style={{
                    backgroundColor: template.body_background_color,
                  }}
                  onClick={(e) =>
                    this.handleClickColor(e, "body_background_color")
                  }
                />
                {this.state.displayColorPicker === "body_background_color" ? (
                  <div style={popover}>
                    <div style={cover} onClick={this.handleCloseColor} />
                    <ChromePicker
                      color={template.body_background_color}
                      onChangeComplete={(color) =>
                        this.handleChange(color.hex, "body_background_color")
                      }
                    />
                  </div>
                ) : null}
              </div>
              <div className="form-group col-md-3">
                <label>Couleur de texte du body</label>
                <button
                  className="btn btn-block"
                  style={{
                    backgroundColor: template.body_text_color,
                  }}
                  onClick={(e) => this.handleClickColor(e, "body_text_color")}
                />
                {this.state.displayColorPicker === "body_text_color" ? (
                  <div style={popover}>
                    <div style={cover} onClick={this.handleCloseColor} />
                    <ChromePicker
                      color={template.body_text_color}
                      onChangeComplete={(color) =>
                        this.handleChange(color.hex, "body_text_color")
                      }
                    />
                  </div>
                ) : null}
              </div>
              <div className="form-group col-md-3">
                <label>Couleur de fond du conteneur</label>
                <button
                  className="btn btn-block"
                  style={{
                    backgroundColor: template.content_background_color,
                  }}
                  onClick={(e) =>
                    this.handleClickColor(e, "content_background_color")
                  }
                />
                {this.state.displayColorPicker ===
                "content_background_color" ? (
                  <div style={popover}>
                    <div style={cover} onClick={this.handleCloseColor} />
                    <ChromePicker
                      color={template.content_background_color}
                      onChangeComplete={(color) =>
                        this.handleChange(color.hex, "content_background_color")
                      }
                    />
                  </div>
                ) : null}
              </div>
              <div className="form-group col-md-3">
                <label>Couleur de texte du conteneur</label>
                <button
                  className="btn btn-block"
                  style={{
                    backgroundColor: template.content_text_color,
                  }}
                  onClick={(e) =>
                    this.handleClickColor(e, "content_text_color")
                  }
                />
                {this.state.displayColorPicker === "content_text_color" ? (
                  <div style={popover}>
                    <div style={cover} onClick={this.handleCloseColor} />
                    <ChromePicker
                      color={template.content_text_color}
                      onChangeComplete={(color) =>
                        this.handleChange(color.hex, "content_text_color")
                      }
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4">
                <label>
                  Image du header
                  <br />
                  <small>(Format attendu: 600px x *px)</small>
                </label>
                <FileUpload
                  name="image_header"
                  buttonLabel={"Charger une image"}
                  onFileUpload={this.onFileUpload}
                />
                {template.image_header ? (
                  <Button
                    color="default"
                    onClick={() => this.removeIcon("image_header")}
                  >
                    Supprimer le visuel
                  </Button>
                ) : null}
              </div>
              <div className="form-group col-md-8">
                <img
                  src={template.image_header}
                  className="img-fluid"
                  alt=""
                  style={{ maxWidth: 600, height: "auto" }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label
                  style={{
                    color: !bodyDataFormat ? "red" : null,
                  }}
                >
                  Corps du texte
                  {!bodyDataFormat ? (
                    <span> (*Erreur: Le format des données est invalide)</span>
                  ) : null}
                </label>
                <Editor
                  value={template.body}
                  init={{
                    images_upload_handler: uploadPicture,
                    link_list: [
                      {
                        title: "Formulaire de réponse",
                        value:
                          "/reponse/[[data:evenement.id]]/[[data:guest.id]]/[[data:access_token]]",
                      },
                      {
                        title: "Page web",
                        value:
                          "/programme/[[data:evenement.id]]/[[data:guest.id]]/[[data:access_token]]",
                      },
                      {
                        title: "Facture",
                        value:
                          "/download-invoice/[[data:evenement.id]]/[[data:guest.id]]/[[data:access_token]]",
                      },
                      {
                        title: "Passeport",
                        value:
                          "/download-passport/[[data:evenement.id]]/[[data:guest.id]]/[[data:access_token]]",
                      },
                      {
                        title: "Espace personnel",
                        value:
                          "/account/[[data:evenement.id]]/[[data:guest.id]]/[[data:access_token]]/team",
                      },
                      {
                        title: "Réponse rapide",
                        value: "/reponse/[[data:reponserapide]]",
                      },
                      {
                        title: "Profil espace personnel",
                        value:
                          "/account/[[data:evenement.id]]/[[data:guest.id]]/[[data:access_token]]/profil",
                      },
                      {
                        title: "Short Code Yes",
                        value:
                          "/reponse/[[data:evenement.id]]/[[data:guest.id]]/[[data:access_token]]/shortyes",
                      },
                      {
                        title: "Short Code No",
                        value:
                          "/reponse/[[data:evenement.id]]/[[data:guest.id]]/[[data:access_token]]/shortno",
                      },
                    ],

                    setup: function (editor) {
                      editor.ui.registry.addMenuButton("shortcodebutton", {
                        text: "Insérer un shortcode",
                        fetch: function (callback) {
                          var items = [
                            {
                              type: "menuitem",
                              text: "Civilité du contact",
                              onAction: function () {
                                editor.insertContent("[[data:contact.gender]]");
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Email du contact",
                              onAction: function () {
                                editor.insertContent("[[data:contact.mail]]");
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Nom du contact",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:contact.lastname]]"
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Prénom du contact",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:contact.firstname]]"
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Nom de l'utilisateur",
                              onAction: function () {
                                editor.insertContent("[[data:user.lastname]]");
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Prénom de l'utilisateur",
                              onAction: function () {
                                editor.insertContent("[[data:user.firstname]]");
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Tel portable du contact",
                              onAction: function () {
                                editor.insertContent("[[data:contact.mobile]]");
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Tel fixe du contact",
                              onAction: function () {
                                editor.insertContent("[[data:contact.phone]]");
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Nombre d'invitations de l'invité",
                              onAction: function () {
                                editor.insertContent("[[data:max_invitation]]");
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Fonction du contact",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:contact.company_function]]"
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Service du contact",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:contact.company_service]]"
                                );
                              },
                            },

                            {
                              type: "menuitem",
                              text: "Adresse du contact",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:contact.address]]"
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Complément d'adresse du contact",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:contact.address_more]]"
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Code postal du contact",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:contact.zip_code]]"
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Ville du contact",
                              onAction: function () {
                                editor.insertContent("[[data:contact.city]]");
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Pays du contact",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:contact.country]]"
                                );
                              },
                            },

                            {
                              type: "menuitem",
                              text: "Nom de la société",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:contact.company_name]]"
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Date de début de l'événement",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:evenement.start_date]]"
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Date de fin de l'événement",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:evenement.end_date]]"
                                );
                              },
                            },

                            {
                              type: "menuitem",
                              text: "Nom du délégataire",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:delegataire.invitation]]"
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Logo du délégataire",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:delegataire.logo]]"
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Informations de contact du délégataire",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:delegataire.contact]]"
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "QR code",
                              onAction: function () {
                                editor.insertContent(
                                  '<img src="https://qrcode.kanguroo-event.fr/?data=[[data:guest.id]];[[data:evenement.id]]"/>'
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Nom du package",
                              onAction: function () {
                                editor.insertContent("[[data:package.name]]");
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Champ personnalisé 1 du package",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:package.placeholder_1]]"
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Champ personnalisé 2 du package",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:package.placeholder_2]]"
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Champ personnalisé 3 du package",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:package.placeholder_3]]"
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Champ personnalisé 4 du package",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:package.placeholder_4]]"
                                );
                              },
                            },
                            {
                              type: "menuitem",
                              text: "Champ personnalisé 5 du package",
                              onAction: function () {
                                editor.insertContent(
                                  "[[data:package.placeholder_5]]"
                                );
                              },
                            },
                          ];
                          callback(items);
                        },
                      });
                      var max = 20;
                      editor.on("submit", function (event) {
                        var numChars =
                          editor.plugins.wordcount.body.getCharacterCount();
                        if (numChars > max) {
                          alert("Maximum " + max + " characters allowed.");
                          event.preventDefault();
                          return false;
                        }
                      });
                    },
                    default_link_target: "_blank",
                    target_list: false,
                    document_base_url: window.location.origin,
                    relative_urls: false,
                    remove_script_host: false,
                    menubar: false,
                    plugins: "link image code lists autoresize table",
                    autoresize: true,
                    paste_as_text: true,
                    max_height: 500,
                    toolbar:
                      "' undo redo blocks fontsize | fontfamily | shortcodebutton | bold italic removeformat subscript superscript code | link | forecolor underline backcolor numlist bullist image | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'",
                  }}
                  onEditorChange={(e) => this.handleBodyChange(e)}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label
                  style={{
                    color: !footerDataFormat ? "red" : null,
                  }}
                >
                  Texte du footer
                  {!footerDataFormat ? (
                    <span> (*Erreur: Le format des données est invalide)</span>
                  ) : null}
                </label>
                <Editor
                  value={template.text_footer}
                  init={{
                    images_upload_handler: uploadPicture,
                    default_link_target: "_blank",
                    target_list: false,
                    document_base_url: window.location.origin,
                    relative_urls: false,
                    remove_script_host: false,
                    menubar: false,
                    plugins: "link image code lists autoresize table",
                    autoresize: true,
                    paste_as_text: true,
                    max_height: 500,
                    toolbar:
                      "' undo redo blocks fontsize | fontfamily | shortcodebutton | bold italic removeformat subscript superscript code | link | forecolor underline backcolor numlist bullist image | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'",
                  }}
                  onEditorChange={(e) => this.handleTextFooterChange(e)}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label>
                  Image du footer
                  <br />
                  <small>(Format attendu: 600px x *px)</small>
                </label>
                <FileUpload
                  name="image_footer"
                  buttonLabel={"Charger une image"}
                  onFileUpload={this.onFileUpload}
                />
                {template.image_footer ? (
                  <Button
                    color="default"
                    onClick={() => this.removeIcon("image_footer")}
                  >
                    Supprimer le visuel
                  </Button>
                ) : null}
              </div>
              <div className="form-group col-md-8">
                <img
                  src={template.image_footer}
                  className="img-fluid"
                  alt=""
                  style={{ maxWidth: 600, height: "auto" }}
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn pink darken-1 float-right white-text"
            >
              Enregistrer
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default TemplateForm;
