import React from "react";
import PropTypes from "prop-types";

function StatsPanel({ panelTitle, panelLabel, panelValue }) {
  return (
    <div className={"white stats-panel"} id="_stats_total">
      <div className={"stats-panel-title"}>{panelTitle}</div>
      <div className={"stats-panel-label"}>{panelLabel}</div>
      <div className={"stats-panel-value"}>{panelValue}</div>
    </div>
  );
}

StatsPanel.prototype = {
  panelTitle: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.string.isRequired,
  ]),
  panelLabel: PropTypes.string.isRequired,
  panelValue: PropTypes.string.isRequired,
};

export { StatsPanel };
