import React from "react";
import ApiService from "../../../services/ApiService";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader";

class ContactsTagsForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.loadData = this.loadData.bind(this);

    this.state = {
      tag: {
        tag_name: "",
      },

      isLoading: true,
    };
  }

  async loadData() {
    const self = this;
    this.setState({ isLoading: true });

    if (this.props.match.params.id) {
      await ApiService.request(
        this.state,
        "tags/" + this.props.match.params.id,
        "get"
      ).then(function (data) {
        self.setState({
          tag: {
            ...data,
          },
        });
      });
    }

    this.setState({ isLoading: false });
  }

  async componentDidMount() {
    this.loadData();
  }

  handleChange(event) {
    this.setState({
      tag: { ...this.state.tag, [event.target.name]: event.target.value },
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    const method = this.state.tag.id ? "put" : "post";
    const id = this.state.tag.id ? this.state.tag.id : "";
    const self = this;

    await ApiService.request(
      {
        ...this.state.tag,
      },
      "tags/" + id,
      method
    )
      .then(function () {
        self.loadData();

        toast.success("Tag sauvegardé", {
          autoClose: 3000,
        });

        self.props.history.push("/admin/tags_contacts");
      })
      .catch(function (e) {
        console.log(e);
        toast.error("Erreur lors de l'enregistrement du tag", {
          autoClose: 3000,
        });
      });
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }

    const { tag } = this.state;

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <form onSubmit={this.handleSubmit} className="form">
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Nom </label>
                <input
                  type="text"
                  name="tag_name"
                  className="form-control"
                  value={tag.tag_name}
                  onChange={this.handleChange}
                  required
                />
              </div>
            </div>

            <button
              type="submit"
              className="btn pink darken-1 float-right white-text"
            >
              Enregistrer
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default ContactsTagsForm;
