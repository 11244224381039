import React from "react";
import { Switch, Route } from "react-router-dom";
import TagsList from "./TagsList";
import TagForm from "./TagForm";

class Tags extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/admin/thematiques/edit/:id?" component={TagForm} />
        <Route path="/admin/thematiques" component={TagsList} />
      </Switch>
    );
  }
}

export default Tags;
