import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  styled,
} from "@mui/material";
import { toast } from "react-toastify";
import ApiService from "../../../services/ApiService";

const MyTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#d81b60",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#d81b60",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#d81b60",
    },
  },
});

function UserDataForm() {
  // TODO: form ask firstname
  // TODO: form ask lastname
  // TODO: form ask phone
  // TODO: form ask email

  // TODO: check if email exist
  // TODO: send email to manager with user data

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [sendType, setSendType] = useState(true);
  const [deleteType, setDeleteType] = useState(false);

  const handleCheckBoxSendChange = () => {
    setSendType((prev) => !prev);
    setDeleteType((prev) => !prev);
  };
  const handleCheckBoxDeleteChange = () => {
    setDeleteType((prev) => !prev);
    setSendType((prev) => !prev);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      firstname,
      lastname,
      email,
      phoneNumber,
      message,
      requestType: sendType ? "send" : "delete",
      baseUrl: window.location.origin,
    };
    try {
      const response = await ApiService.awaitRequest(data, "rgpd/form", "post");

      if (response && response.code === 200) {
        toast.success("Votre demande sera prise en compte par nos équipes");
      } else if (response && response.code === 500) {
        toast.error(
          "Le formulaire n'a pas pu être envoyé, essayer ultérieurement"
        );
      } else throw new Error("Contact not found");
    } catch (error) {
      toast.error(
        "Nous n'avons pas pu vous identifier, vérifier bien votre email"
      );
    }
  };

  return (
    <Box sx={{ paddingTop: "1rem" }}>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <img
          alt="kanguroo logo"
          src="/logo-kanguroo.png"
          style={{ width: 350 }}
        />
      </Box>
      <Typography
        gutterBottom
        variant="h4"
        align="center"
        marginBottom="1.5rem"
        marginTop="1rem"
      >
        Récupérer mes données
      </Typography>
      <Card sx={{ maxWidth: 475, margin: "0 auto", padding: "20px 5px" }}>
        <CardContent>
          <Typography gutterBottom variant="h5">
            Informations
          </Typography>
          <Typography
            variant="body2"
            component="p"
            gutterBottom
            color="textSecondary"
          >
            Remplissez le formulaire et notre équipe vous enverra vos données
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid xs={12} sm={6} item>
                <MyTextField
                  label="Prénom"
                  placeholder="Entrez votre prénom"
                  variant="outlined"
                  fullWidth
                  required
                  inputProps={{
                    pattern: "[a-zA-Z0-9 ]{1,30}",
                    maxLength: 30,
                  }}
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  sx={{
                    marginTop: "0.7rem",
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6} item>
                <MyTextField
                  label="Nom"
                  placeholder="Entrez votre nom"
                  variant="outlined"
                  fullWidth
                  required
                  inputProps={{ pattern: "[a-zA-Z0-9 ]{1,30}", maxLength: 30 }}
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  sx={{ marginTop: "0.7rem" }}
                />
              </Grid>
              <Grid xs={12} item>
                <MyTextField
                  type="email"
                  label="Email"
                  placeholder="Entrez votre email"
                  variant="outlined"
                  fullWidth
                  required
                  inputProps={{
                    pattern: "^[w-.]+@([w-]+.)+[w-]{2,4}$",
                    maxLength: 60,
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ marginTop: "0.7rem" }}
                />
              </Grid>
              <Grid xs={12} item>
                <MyTextField
                  type="tel"
                  label="Numéro"
                  placeholder="Entrez votre numéro"
                  variant="outlined"
                  fullWidth
                  required
                  inputProps={{ pattern: "[+ 0-9]{1,18}", maxLength: 18 }}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  sx={{ marginTop: "0.7rem" }}
                />
              </Grid>
              <Grid xs={12} item>
                <MyTextField
                  label="Message (facultatif)"
                  placeholder="Écrivez votre message ici"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  inputProps={{ maxLength: 500 }}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  sx={{ marginTop: "0.7rem", marginBottom: "0.7rem" }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  label="Recevoir mes données"
                  labelPlacement="top"
                  control={
                    <Checkbox
                      checked={sendType}
                      onChange={handleCheckBoxSendChange}
                      inputProps={{
                        "aria-label": "send-checkbox",
                        style: { width: "0px" },
                      }}
                      sx={{
                        color: "#ad1357",
                        "&.Mui-checked": {
                          color: "#d71a60",
                        },
                      }}
                    />
                  }
                  sx={{
                    width: "100%",
                    marginInline: "auto",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  label="Supprimer mon compte"
                  labelPlacement="top"
                  control={
                    <Checkbox
                      checked={deleteType}
                      onChange={handleCheckBoxDeleteChange}
                      inputProps={{
                        "aria-label": "delete-checkbox",
                        style: { width: "0px" },
                      }}
                      sx={{
                        color: "#ad1357",
                        "&.Mui-checked": {
                          color: "#d71a60",
                        },
                      }}
                    />
                  }
                  sx={{
                    width: "100%",
                    marginInline: "auto",
                  }}
                />
              </Grid>
              <Grid xs={12} item>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    bgcolor: "#d81b60",
                    "&:hover": { bgcolor: "#a11448", color: "white" },
                  }}
                  // sx={{ marginTop: "0.5rem" }}
                >
                  Envoyer
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
}

export default UserDataForm;
