import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { MDBInput, Button } from "mdbreact";
import FileUpload from "./FileUpload";
import { uploadPicture } from "../services/Utils";
import ApiService from "../services/ApiService";
import { languagesList } from "./Translations";

class Multilingual extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.removeIcon = this.removeIcon.bind(this);
    this.addNewLang = this.addNewLang.bind(this);
    this.updateEvenement = this.updateEvenement.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.renderMultilingualComponent =
      this.renderMultilingualComponent.bind(this);
    this.state = {
      lang:
        this.props.lang && this.props.lang.length === languagesList.length
          ? this.props.lang
          : this.addNewLang(),
      valueLang: "",
      evenement: "",
      lastInputFocus: "",
    };
    this.myRef = React.createRef();
  }

  addNewLang() {
    var newLang = this.props.lang || [];

    languagesList.forEach((lang) =>
      this.props.lang &&
      this.props.lang.some((actualLang) => actualLang.code === lang.code)
        ? ""
        : newLang.push({ code: lang.code, label: lang.label, value: "" })
    );

    return newLang;
  }

  updateEvenement(evenement) {
    this.setState({ evenement });
    const default_language = evenement
      ? evenement.data
        ? evenement.data.default_language
        : ""
      : "";
    const langDefault = this.state.lang.find(
      (lang) => lang.code === default_language
    );
    const defaultLanguageObj = languagesList.find(
      (language) => language.code === default_language
    );

    if (!langDefault && defaultLanguageObj) {
      this.setState({ lang: [...this.state.lang, defaultLanguageObj] });
    }
  }

  async componentDidMount() {
    /*     
    const eventId = localStorage.getItem("eventId");
    console.log(this.props);
     */
    // Used for Event to handle language selection
    var evenement;
    if (this.props.event) {
      if (this.props.event.id) {
        evenement = await ApiService.awaitRequest(
          {},
          "evenements/" + this.props.event.id,
          "get"
        );
      } else {
        evenement = this.props.event;
      }
      this.updateEvenement(evenement);
    }
    // Used for componsant in Event to handle language selection
    if (this.props.eventId) {
      evenement = await ApiService.awaitRequest(
        {},
        "evenements/" + this.props.eventId,
        "get"
      );
      this.updateEvenement(evenement);
    }
  }

  onFileUpload(id, name) {
    const newlang = this.state.lang.map((item) => {
      return item.code === name
        ? {
            ...item,
            value: `https://res.cloudinary.com/kanguroo-event/image/upload/${id}`,
          }
        : item;
    });

    this.setState({
      lang: newlang,
    });

    this.props.handleLang(newlang);
  }

  removeIcon(code) {
    const newlang = this.state.lang.map((item) => {
      return item.code === code
        ? {
            ...item,
            value: null,
          }
        : item;
    });

    this.setState({
      lang: newlang,
    });

    this.props.handleLang(newlang);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.lang !== prevProps.lang) {
      this.setState({ lang: this.props.lang });
    }
  }

  handleChange(e, label) {
    const name = label ? label : e.target.name;
    const value = label ? e : e.target.value;

    const newlang = this.state.lang.map((item) => {
      return item.code === name ? { ...item, value } : item;
    });

    this.setState({
      lang: newlang,
    });

    this.props.handleLang(newlang);
  }

  handleInputFocus(e, currentRef) {
    const name = e.target.name;
    const value = e.target.value;
    const newlang = this.state.lang.map((item) => {
      return item.code === name ? { ...item, value } : item;
    });

    if (
      (document.activeElement.value || document.activeElement) &&
      this.props.getValueLastInputFocus
    ) {
      this.props.getValueLastInputFocus(newlang);
    }
    this.setState({
      lastInputFocus: document.activeElement,
    });
  }
  renderMultilingualComponent(item, key) {
    let component = null;

    switch (this.props.type) {
      case "textarea":
        component = (
          <>
            <label>{item.label}</label>
            <Editor
              value={this.state.lang[key].value || ""}
              init={{
                images_upload_handler: uploadPicture,
                link_list: [
                  {
                    title: "Formulaire de réponse",
                    value: "/[[form]]",
                  },
                ],
                document_base_url: window.location.origin,
                relative_urls: false,
                remove_script_host: false,
                menubar: false,
                plugins: "link image code lists autoresize paste table",
                default_link_target: "_blank",
                autoresize: true,
                paste_as_text: true,
                max_height: 500,
                toolbar:
                  " fontsize bold forecolor alignleft aligncenter alignright alignjustify | undo redo underline blocks italic removeformat subscript superscript code link |  backcolor numlist bullist image |  bullist numlist outdent indent | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
              }}
              onEditorChange={(e) => this.handleChange(e, item.code)}
            />
          </>
        );
        break;
      case "image":
        component = (
          <div className="row">
            <div className="col-md-4">
              <label>{item.label}</label>
              <FileUpload
                name={item.code}
                buttonLabel={"Charger une image"}
                onFileUpload={this.onFileUpload}
              />
              {item.value ? (
                <Button
                  className="btn pink white-text darken-1 btn-lg "
                  onClick={() => this.removeIcon(item.code)}
                >
                  Supprimer le visuel
                </Button>
              ) : null}
            </div>
            {item.value ? (
              <div className="col-md-2">
                <img src={item.value} className="img-fluid" alt="" />
              </div>
            ) : null}
          </div>
        );
        break;
      case "input":
        component = (
          <MDBInput
            name={item.code}
            ref={this.myRef}
            onFocus={(e) => this.handleInputFocus(e, this.myRef.current)}
            onChange={(e) => this.handleChange(e, null)}
            label={item.label}
            value={this.state.lang[key].value || ""}
          />
        );
        break;
      case "select":
        component = (
          <>
            <label>{item.label}</label>
            <select
              onChange={this.handleChange}
              name={item.code}
              className="form-control browser-default custom-select"
              value={this.state.lang[key].value || ""}
            >
              <option key="multilingual-lang" value={null}>
                {this.props.selectLabel}
              </option>
              {this.props.options.map((option, index) => (
                <option key={`multilingual-lang-${index}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </>
        );
        break;

      default:
        break;
    }

    return component;
  }

  render() {
    const { evenement } = this.state;

    const default_language = evenement
      ? evenement.data
        ? evenement.data.default_language
          ? evenement.data.default_language
          : null
        : null
      : null;
    return (
      <>
        {this.state.lang.map((item, key) => {
          return (
            <div
              className={
                this.props.lg === "12"
                  ? `${this.props.classNameMultilingual} form-group col-md-` +
                    this.props.lg
                  : `${this.props.classNameMultilingual} form-group col-md-4`
              }
              key={key}
            >
              {
                default_language &&
                typeof default_language != "string" &&
                default_language.length >= 1 // Works for Multilingual in EventContainer and other Event...
                  ? default_language.map((elt, k) =>
                      elt === item.code ? (
                        <div key={k}>
                          {this.renderMultilingualComponent(item, key)}
                        </div>
                      ) : (
                        ""
                      )
                    )
                  : this.renderMultilingualComponent(item, key) // Works for everyother Multilingual
              }
            </div>
          );
        })}
      </>
    );
  }
}

export default Multilingual;
