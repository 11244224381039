import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from "@mui/material";

function Select({ items, label, id, value, setValue, event, ...props }) {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel
          sx={{
            "&.Mui-focused": {
              color: "#d81b60",
            },
          }}
          id={`select-input-label-${id}`}
        >
          {label}
        </InputLabel>
        <MuiSelect
          disabled={!event && id === "checkin-points" ? true : false}
          labelId={`select-label-${id}`}
          id={`select-${id}`}
          value={value}
          label={label}
          onChange={(event) => setValue(event.target.value)}
          sx={{
            width: "100%",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#d81b60",
            },
          }}
          {...props}
        >
          {items && items.length > 0
            ? items.map((item, i) => (
                <MenuItem key={i} value={item.value}>
                  {item.label}
                </MenuItem>
              ))
            : null}
        </MuiSelect>
      </FormControl>
    </Box>
  );
}

export default Select;
