import React from "react";
import ApiService from "../../../services/ApiService";
import Loader from "../../../common/Loader";

class WorkSpacesList extends React.Component {
  constructor(props) {
    super(props);

    this.loadData = this.loadData.bind(this);

    this.state = { isLoading: false, workspacesList: [] };
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    this.setState({ isLoading: true });
    const workspacesList = await ApiService.awaitRequest(
      {},
      "workspace",
      "get"
    );
    console.log("workspacesList", workspacesList);
    this.setState({ workspacesList, isLoading: false });
  }

  render() {
    const { workspacesList, isLoading } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <div className="row">
            <div className="col-md-10">
              <h2>Liste des workspaces</h2>
            </div>
          </div>
          <br />
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th scope="col">Nom</th>
                <th scope="col">Prénom</th>
                <th scope="col">Email</th>
              </tr>
            </thead>
            <tbody>
              {workspacesList.map((wp) => (
                <tr>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default WorkSpacesList;
