import React from "react";
import { numberPerPage } from "../services/Utils";
import Pagination from "./Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

class Sortable extends React.Component {
  constructor(props) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
    this.changePage = this.changePage.bind(this);
    this.sortList = this.sortList.bind(this);
    this.checkRowType = this.checkRowType.bind(this);
    this.checkAll = this.checkAll.bind(this);

    this.state = {
      currentPage: 0,
      totalPages: Math.ceil(this.props.sortable.length / numberPerPage),
      sortWithData: false,
      sortType: "string",
      sortName: null,
      listSorted: this.props.sortable,
      itemsChecked: [],
    };
  }

  checkOne(event, newItem) {
    const self = this;
    let { itemsChecked } = this.state;
    if (event.target.checked) {
      itemsChecked.push(newItem);
    } else {
      itemsChecked = itemsChecked.filter((item) => item !== newItem);
    }

    this.setState({ itemsChecked }, () =>
      self.props.getCheckIds(self.state.itemsChecked)
    );
  }

  checkAll(event) {
    const self = this;

    this.setState(
      {
        itemsChecked: event.target.checked
          ? this.state.listSorted.map((listSorted) => listSorted.id)
          : [],
      },
      () => self.props.getCheckIds(self.state.itemsChecked)
    );
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.sortable !== prevProps.sortable) {
      this.setState({
        currentPage: 0,
        listSorted: this.props.sortable,
        totalPages: Math.ceil(this.props.sortable.length / numberPerPage),
      });
    }
  }

  checkRowType(name) {
    if (!this.state.listSorted || this.state.listSorted.length === 0) {
      return false;
    } else {
      const element = this.state.listSorted[0];

      for (let [key, value] of Object.entries(element)) {
        if (key === name) {
          if (value instanceof Date) {
            return { sortName: name, sortType: "date", sortWithData: false };
          } else if (
            typeof element[name] === "string" ||
            typeof element[name] === "number"
          ) {
            return { sortName: name, sortType: "string", sortWithData: false };
          } else {
            return false;
          }
        }
      }
    }
  }

  sortList(name, order) {
    const { sortType, sortWithData, sortName } = !order
      ? this.state
      : this.checkRowType(name);

    let listSorted = this.props.sortable;

    console.log("sortName", sortName);

    if (!sortWithData) {
      if (sortType === "string") {
        listSorted = this.props.sortable.slice().sort(function (a, b) {
          const avalue = isNaN(a[sortName])
            ? a[sortName].toLowerCase()
            : a[sortName];
          const bvalue = isNaN(b[sortName])
            ? b[sortName].toLowerCase()
            : b[sortName];
          if (avalue < bvalue) {
            return !order || order === "asc" ? -1 : 1;
          }
          if (avalue > bvalue) {
            return !order || order === "asc" ? 1 : -1;
          }
          return 0;
        });
      }
    }

    this.setState({ sortType, sortWithData, sortName, listSorted });
  }

  updateSortedList(listSorted, sortType, sortWithData) {
    this.setState({ listSorted, sortType, sortWithData });
  }

  changePage(page) {
    this.setState({ currentPage: page });
  }

  renderRow(object) {
    const tr = [];
    for (let [key, value] of Object.entries(object)) {
      if (key !== "id")
        tr.push(
          <td style={{ paddingLeft: 0 }} key={key}>
            {value}
          </td>
        );
    }

    return tr;
  }

  render() {
    const { names, checkable } = this.props;

    const { currentPage, totalPages, listSorted, itemsChecked } = this.state;

    return (
      <>
        <div className={"table-container mb-3"}>
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                {checkable ? (
                  <th style={{ paddingBottom: "17px" }} scope="col">
                    <input
                      type="checkbox"
                      style={{
                        opacity: 1,
                        pointerEvents: "auto",
                        position: "relative",
                      }}
                      onChange={this.checkAll}
                    />
                  </th>
                ) : null}

                {names.map((element, index) => (
                  <th
                    key={`sortable-th-${index}`}
                    scope="col"
                    className="sortable-title"
                    style={{ paddingLeft: 0, width: element.width }}
                  >
                    <div className="row">
                      <div className="sortable-title-label">
                        {element.value}
                      </div>

                      {this.checkRowType(element.key) ? (
                        <div className="sortable-title-arrow">
                          <FontAwesomeIcon
                            className="pointer pointer-asc"
                            onClick={() => this.sortList(element.key, "asc")}
                            icon={faCaretUp}
                          />

                          <FontAwesomeIcon
                            className="pointer  pointer-desc"
                            onClick={() => this.sortList(element.key, "desc")}
                            icon={faCaretDown}
                          />
                        </div>
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {listSorted.map((row, i) =>
                i < numberPerPage * (currentPage + 1) &&
                i >= numberPerPage * currentPage ? (
                  <tr key={`sorted-list-item-${i}`}>
                    {checkable ? (
                      <td>
                        <input
                          type="checkbox"
                          checked={itemsChecked.some((item) => item === row.id)}
                          onChange={(e) => this.checkOne(e, row.id)}
                          style={{ opacity: 1, pointerEvents: "auto" }}
                        />
                      </td>
                    ) : null}

                    {this.renderRow(row)}
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        </div>
        <div className="row ml-0" style={{ listStyleType: "none" }}>
          {
            <Pagination
              changePage={this.changePage}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          }
        </div>
      </>
    );
  }
}

export default Sortable;
