import React from "react";
import { Switch, Route } from "react-router-dom";
import InvoicesList from "./InvoicesList";

class ContactsTags extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/admin/invoices" component={InvoicesList} />
      </Switch>
    );
  }
}

export default ContactsTags;
