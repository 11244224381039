import React from "react";
import { Button } from "mdbreact";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify"; 

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.uploadWidget = this.uploadWidget.bind(this);

    this.state = {
      modal: false,
      id: "",
      showSuccess: false,
    };
  }
  uploadWidget() {
    const self = this;
    window.cloudinary.openUploadWidget(
      {
        cloudName: "kanguroo-event",
        uploadPreset: "bkpnuvx9",
        sources: ["local"],
        language: "fr",
        showCompletedButton: true,
        clientAllowedFormats: ["png", "gif", "jpeg", "jpg"],
        text: {
          fr: {
            queue: {
              title: "Téléchargement en cours",
              done: "Terminé",
              retry_failed: "Réessayer",
              uploading: "Téléchargement en cours...",
              error: "Erreur",
              aborted: "Annulé",
              statuses: {
                uploading: "Téléchargement en cours...",
                error: "Erreur",
                uploaded: "Terminé",
                aborted: "Annulé",
              },
            },
            or: "Ou",
            menu: {
              files: "Télécharger une image",
            },
            local: {
              browse: "Parcourir",
              main_title: "Envoi de fichiers",
              dd_title_single:
                "Glisser et déposer une image ici (JPEG, JPG, PNG uniquement)",

              drop_title_single: "Déposez l'image pour l'envoyer",
            },
          },
        },

        showAdvancedOptions: false,
        cropping: false,
        multiple: false,
        defaultSource: "local",
      },
      (err, result) => {
        if (!err) {
          if (result && result.event === "success") {
            self.setState({
              ...self.state,
              id: result.info.public_id,
              showSuccess: true,
            });
          }

          if (result.info === "hidden" && self.state.showSuccess === true) {
            toast.success("Element importé", {
              autoClose: 3000,
            });
            if (this.props.name)
              this.props.onFileUpload(self.state.id, this.props.name);
            else this.props.onFileUpload(self.state.id);
            self.setState({ ...self.state, showSuccess: false });
          }
        }
      }
    );
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    return (
      <div>
        <Button
          className="btn btn-block darken-1 pink white-text font-weight-bold"
          onClick={this.uploadWidget}
        >
          {this.props.buttonLabel}
        </Button>
        <ToastContainer />
      </div>
    );
  }
}

export default FileUpload;
