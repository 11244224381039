import React from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import ApiService from "../../services/ApiService";
import GeneralTermsComponent from "./GeneralTermsComponent";
import { toast } from "react-toastify";
import InvoiceDetails from "../InvoiceDetails";
import PaymentMethode from "../payment_component/PaymentMethodComponent";
import { webPageTranslations } from "../Translations";
const CheckoutPaymentInvoice = (props) => {
  const elements = useElements();
  const stripe = useStripe();
  const [validateOrderBtnLoading, setValidateOrderBtnLoading] =
    React.useState(false);
  const {
    code,
    totalTTC,
    form,
    cgv,
    modifyForm,
    invoice,
    allowCheckForAdmin,
    isAdmin,
    allowCheckForUser,
    sendResponses,
    paymentMode,
    setPaymentModeState,
    element,
    validateForm,
    loadData,
    updateEventFormMessage,

    //show_payment_error,
    //lastInvoice,
    contact,
    event,
    paymentOrder,
  } = props;
  const paymentElementOptions = {
    layout: "accordion",
    wallets: {
      googlePay: "never",
      applePay: "never",
      link: "never",
    },
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    try {
      setValidateOrderBtnLoading(true);
      if (paymentMode === "card" && totalTTC !== 0) {
        if (Number(totalTTC) > 0) {
          if (!stripe || !elements) {
            toast.error(webPageTranslations[code ? code : "FR"].payment_error, {
              autoClose: 6000,
            });
            return;
          }
          await stripe
            .confirmPayment({
              elements,
              redirect: "if_required",
            })
            .then(async (result) => {
              const { paymentIntent, error } = result;
              if (error) {
                toast.error(`${error.message}`, {
                  autoClose: 4000,
                });
              }
              if (paymentIntent) {
                if (!paymentIntent.status === "succeeded") {
                  toast.error(
                    webPageTranslations[code ? code : "FR"].payment_error,
                    {
                      autoClose: 4000,
                    }
                  );
                }
                switch (paymentIntent.status) {
                  case "succeeded":
                    toast.success(
                      webPageTranslations[code ? code : "FR"].payment_success,
                      {
                        autoClose: 4000,
                      }
                    );
                    break;
                  default:
                }
              }

              if (paymentIntent && paymentIntent.status === "succeeded") {
                if (loadData) {
                  loadData();
                } else {
                  updateEventFormMessage();
                }
              }
            });
        }
      } else if (paymentMode === "check" || totalTTC === 0) {
        if (totalTTC !== 0) {
          try {
            if (!paymentOrder) {
              throw new Error("Order not defined");
            }

            //? CREATE PROFORMA INVOICES
            //TODO: Create check_transaction_id
            let generate_check_transaction_id = null;

            if (totalTTC > 0) {
              generate_check_transaction_id = true;
            }

            const invoice = await ApiService.awaitRequest(
              {
                contact_id: contact.id,
                evenement_id: event.id,
                order_id: paymentOrder.id,
                totalTTC: totalTTC,
                children_entity_id: event.children_entity_id,
                generate_check_transaction_id,
              },
              "contacts/create_check_proforma_invoice",
              "post"
            );
            if (invoice) {
              if (loadData) {
                loadData();
              } else {
                updateEventFormMessage();
              }
            }
            // if (invoice) {
            //   console.log("Enter here 47 🍅", invoice);
            //   sendResponses(totalTTC === 0); //? Function to save components responses and form data
            // } else
            if (!invoice) throw new Error("Invoice not defined or null");
          } catch (error) {
            console.log(error);
            toast.error(webPageTranslations[code ? code : "FR"].payment_error, {
              autoClose: 4000,
            });
          } finally {
            // setIsLoading(false);
          }
        } else {
          console.log("Enter here 54 🍅");
          sendResponses(totalTTC === 0); //! STOP USING THIS
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setValidateOrderBtnLoading(false);
    }
  };

  return (
    <>
      <form className="form" onSubmit={(e) => handleSubmitForm(e)}>
        <div
          style={{
            backgroundColor: form.layout.backgroundColorMain,
            padding: "20px",
          }}
          className={
            form.layout.gabarit === "gabarit2"
              ? "col-md-8 main-container"
              : "col-md-12 main-container"
          }
        >
          <InvoiceDetails code={code} invoice={invoice} />
          <br />
          <PaymentMethode
            code={code}
            isAdmin={isAdmin}
            allowCheckForAdmin={allowCheckForAdmin}
            allowCheckForUser={allowCheckForUser}
            invoice={invoice}
            totalTTC={totalTTC}
            contact={contact}
            setPaymentModeState={setPaymentModeState}
          >
            <PaymentElement options={paymentElementOptions} />
          </PaymentMethode>

          <GeneralTermsComponent
            code={code}
            form={form}
            cgv={cgv}
            modifyForm={modifyForm}
            totalTTC={totalTTC}
            element={element}
            validateForm={validateForm}
            validateOrderBtnLoading={validateOrderBtnLoading}
          />
        </div>
      </form>
    </>
  );
};

export default CheckoutPaymentInvoice;
