import React from "react";
import { Link } from "react-router-dom";
import { MDBNavbar } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faCalendarAlt,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { checkRoleWithoutReplace } from "../services/Utils";
import "./Header.css";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    switch (true) {
      case window.location.href.includes("epcr.app.kanguroo-event.fr"):
      case window.location.href.includes("invitations.epcrugby.com"):
      case window.location.href.includes("epcr.app.dev.kanguroo-event.fr"):
        this.leftnavLogo = "/logo-epcr-leftnav.png";
        break;
      case window.location.href.includes("stellium.app.dev.kanguroo-event.fr"):
      case window.location.href.includes("app.participer-evenements.fr"):
      case window.location.href.includes("stellium.app.kanguroo-event.fr"):
        this.leftnavLogo = "/logo-stellium-leftnav.jpeg";
        break;
      case window.location.href.includes("digeek.app.kanguroo-event.fr"):
      case window.location.href.includes("digeek.app.dev.kanguroo-event.fr"):
        this.leftnavLogo = "/logo-kanguroo-leftnav.png";
        break;
      case window.location.href.includes("lnr.app.kanguroo-event.fr"):
      case window.location.href.includes("app.lnr-protocole.fr"):
      case window.location.href.includes("lnr.app.dev.kanguroo-event.fr"):
        this.leftnavLogo = "/logo-lnr.png";
        break;        
      default:
        this.leftnavLogo = "/logo-kanguroo-leftnav.png";
    }

  }

  render() {
    const storedLogo = localStorage.getItem("user_logo");
    const logo =
      storedLogo && storedLogo !== "null" ? storedLogo : this.props.userLogo;

    const { userId } = this.props;
    const isDelegataire = checkRoleWithoutReplace("delegataire");

    return (
      <MDBNavbar
        className="navbar navbar-expand-lg navbar-center fixed-top"
        color="black"
        dark
        expand="md"
      >
        <div style={{ width: "100%" }}>
          <div className="row">
            <div className="col-md-1">
              <img
                alt="logo"
                style={{ width: "80px" }}
                src={process.env.PUBLIC_URL + this.leftnavLogo}
              />
            </div>
            <div className="col-md-1">
              {logo !== "null" ? (
                <img
                  style={{ height: "50px", width: "auto", maxWidth: "160px" }}
                  alt="logo"
                  src={`https://res.cloudinary.com/kanguroo-event/image/upload/w_200/${logo}`}
                />
              ) : null}
            </div>
            <div
              className="col-md-2"
              style={{ marginLeft: logo !== "null" ? 300 : 0 }}
            >
              <Link
                className="nav-link white-text"
                to={userId ? "/delegataires/" + userId + "/events" : null}
              >
                <FontAwesomeIcon icon={faCalendarAlt} color="white" />
                &nbsp; MES ÉVENEMENTS
              </Link>
            </div>
            <div className="col-md-2">
              <Link
                className="nav-link white-text"
                to={userId ? "/delegataires/" + userId + "/contacts" : null}
              >
                <FontAwesomeIcon icon={faAddressBook} color="white" />
                &nbsp; MES CONTACTS
              </Link>
            </div>
            <div className="col-md-2 offset-md-1">
              {!isDelegataire ? (
                <span
                  onClick={() => window.history.back()}
                  className="nav-link white-text"
                >
                  <FontAwesomeIcon icon={faLock} color="white" />
                  &nbsp; Retour
                </span>
              ) : (
                <span
                  onClick={this.props.doLogout}
                  className="nav-link white-text"
                >
                  <FontAwesomeIcon icon={faLock} color="white" />
                  &nbsp; Déconnexion
                </span>
              )}
            </div>
          </div>
        </div>
      </MDBNavbar>
    );
  }
}

export default Header;
