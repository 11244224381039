import React from "react";
import PropTypes from "prop-types";
import "./switch.css";

function Switch({ onChange, checked, label, id }) {
  return (
    <div className="custom-control custom-switch">
      <input
        type="checkbox"
        className="custom-control-input"
        id={`customSwitchesChecked-${id}`}
        onChange={onChange}
        checked={checked}
      />
      <label
        className="custom-control-label"
        htmlFor={`customSwitchesChecked-${id}`}
      >
        {label}
      </label>
    </div>
  );
}

Switch.prototype = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.label,
  id: PropTypes.string,
};

export default Switch;
