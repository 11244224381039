import React from "react";
import { DelegataireAddQuota } from "./DelegataireAddQuota";
import uniqBy from "lodash/uniqBy";

export function DelegatairesQuotas({
  packagesList,
  components,
  selectedPackages,
  selectedComponents,
  setSelectedPackages,
  setSelectedComponents,
  delegataireUserId,
  setDeletedComponents,
  componentsQuotaData,
  allCmpsNbrGuests,
}) {
  React.useEffect(() => {
    const selectedCmp = [];
    components.forEach((cmp) => {
      if (cmp.data.quotas && cmp.data.quotas.delegataires) {
        const componentDelQuota = cmp.data.quotas.delegataires.find(
          (item) => item.userId === delegataireUserId
        );
        if (componentDelQuota) {
          selectedCmp.push({
            id: cmp.id,
            quota: componentDelQuota.quota,
            role: cmp.data.reponse.role,
          });
        }
      }
    });

    setSelectedComponents((prev) => {

      // Filtrer les composants en doublon
      const existingIds = new Set(prev.map(cmp => cmp.id));
      const uniqueSelectedCmp = selectedCmp.filter(cmp => !existingIds.has(cmp.id));

      return [...prev, ...uniqueSelectedCmp];
    });

    // Ajouter automatiquement le composant d'entrée
    const entranceComponent = components.find(cmp => cmp.data.reponse.role === 'entrance');
    const entranceAlreadySelected = selectedCmp.find((cmp) => cmp.id === entranceComponent.id);
    const entranceAlreadySelected2 = selectedComponents.find((cmp) => cmp.id === entranceComponent.id);

    if (!entranceAlreadySelected && !entranceAlreadySelected2) {
      addOptionComponent(entranceComponent.id, 'entrance');
    }


  }, []);

  // Mettre à jours les composants après la suppression d'un package
  React.useEffect(() => {
    let deletedIds = [];
    setSelectedComponents((prev) => prev.filter((cmp) => {
      if (cmp.role === 'entrance') {
        return true; // Conserver le composant d'entrée
      } else {
        const shouldKeep = getSecondSelectOptions.some((opt) => opt.value === cmp.id);
        if (!shouldKeep) {
          deletedIds.push(cmp.id);
        }
        return shouldKeep;
      }
    }));

    setDeletedComponents((prevDeletedComponents) => [...prevDeletedComponents, ...deletedIds]);
  }, [selectedPackages]);

  const getSecondSelectOptions = React.useMemo(() => {
    let options = [];
    selectedPackages.forEach((pckId) => {
      const packageFound = packagesList.find(
        (pck) => pck.value === Number(pckId)
      );
      if (packageFound) {
        packageFound.components.forEach((cmp) => {
          const componentFound = components.find(
            (component) => component.id === cmp.id
          );
          if (componentFound) {
            options.push({
              label: componentFound.data.name,
              value: componentFound.id,
              role: componentFound.data.reponse.role
            });
          }
        });
      }
    });
    if (options.length > 0) {
      options = uniqBy(options, "value");
    }

    return options;
  }, [selectedPackages]);

  const addOptionPackage = (value) => {
    setSelectedPackages((prev) => [...prev, Number(value)]);
  };

  const removeOptionPackage = (value) => {
    setSelectedPackages((prev) => prev.filter((opt) => opt !== value));
  };

  const addOptionComponent = (value, role = "") => {
    setSelectedComponents((prev) => [...prev, { id: Number(value), quota: 1, role }]);
  };

  const removeOptionComponent = (value) => {
    setSelectedComponents((prev) => prev.filter((opt) => opt.id !== value));
    setDeletedComponents((prev) => [...prev, value]);
  };

  const getComponentTotalQuota = (id) => {
    const component = components.find((cmp) => cmp.id === id);

    //TODO: get number of participation to the component

    if (component && component.data && component.data.quotas) {
      const componentNbrOfGuests = allCmpsNbrGuests
        ? allCmpsNbrGuests.find((cmp) => cmp.id === id)
        : null;
      console.log("🚀 ~ getComponentTotalQuota ~ componentQuotaData:", {
        componentNbrOfGuests,
        id,
        totalNbrOfAdminGuests: componentNbrOfGuests.totalNbrOfAdminGuests,
      }); //? why am I getting 0 instead of 1

      const totalNbrOfAdminGuests = componentNbrOfGuests
        ? componentNbrOfGuests.totalNbrOfAdminGuests
        : 0;

      const totalRemainingQuota =
        component.data.quotas.total - totalNbrOfAdminGuests || 0;
      const quotaDelegataires = component.data.quotas.delegataires || [];

      const totalDelQuota = quotaDelegataires.reduce((acc, curr) => {
        if (curr.userId !== delegataireUserId && curr.quota)
          return curr.quota + acc;
        return acc;
      }, 0);

      const res = totalRemainingQuota - totalDelQuota;
      console.log("🚀 ~ getComponentTotalQuota ~ component:", {
        component,
        totalRemainingQuota,
        totalDelQuota,
        res,
        delegataireUserId,
        totalNbrOfAdminGuests,
      });
      return res;
    }
    return 0;
  };

  const handleComponentQuota = (id, quota) => {
    setSelectedComponents((prev) => {
      const newComponents = [...prev];
      const index = newComponents.findIndex((cmp) => cmp.id === id);
      if (index > -1) {
        newComponents[index].quota = Number(quota);
      }
      console.log("🚀 ~ setSelectedComponents ~ newComponents:", newComponents);
      return newComponents;
    });
  };

  const isPackageOptionAlreadySelected = (opt) => {
    const isDisabled = selectedPackages.some((item) => item === opt.value);
    return isDisabled;
  };

  const isComponentOptionAlreadySelected = (opt) => {
    const isDisabled = selectedComponents.some((item) => item.id === opt.value);
    return isDisabled;
  };

  return (
    <div className="delegataire-quota-container">
      <div className="delegataire-quota-section">
        <h4 className="bold-titles">Associer des packages</h4>
        <DelegataireAddQuota
          selectedOptions={selectedPackages}
          options={packagesList}
          setSelectedOptions={setSelectedPackages}
          placeholder="Sélectionner un package"
          buttonText="Ajouter un package"
          addOption={addOptionPackage}
          removeOption={removeOptionPackage}
          isOptionAlreadySelected={isPackageOptionAlreadySelected}
        />
      </div>

      <div className="delegataire-quota-section">
        <div className="cmp-quota-title-container">
          <h4 className="bold-titles">Ajouter des quotas</h4>
          {/* <small>Composant moment + logistiques uniquement</small> */}
        </div>
        <DelegataireAddQuota
          selectedOptions={selectedComponents}
          options={getSecondSelectOptions}
          setSelectedOptions={setSelectedComponents}
          placeholder="Sélectionner un composant"
          buttonText="Ajouter un composant"
          addOption={addOptionComponent}
          removeOption={removeOptionComponent}
          type="component"
          handleComponentQuota={handleComponentQuota}
          getComponentTotalQuota={getComponentTotalQuota}
          isOptionAlreadySelected={isComponentOptionAlreadySelected}
          componentsQuotaData={componentsQuotaData}
        />
      </div>
    </div>
  );
}
