import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";

export function Select({
  options,
  selectedValue = undefined,
  handleSelectedValue,
  id,
  displayArrowIcon = true,
  placeholder,
  optionsContainerColor = undefined,
  optionsContainerBorderColor = undefined,
  isOptionAlreadySelected = null,
  required = false,
  optionsValuesToLowerCase = true,
  className = "",
  margin = 0,
  firstOptionItalic = true,
}) {
  const [value, setValue] = React.useState("");
  const handleChange = (e) => {
    if (selectedValue === undefined) setValue(e.target.value);
    handleSelectedValue(e.target.value);
  };

  return (
    <FormControl
      sx={{ m: margin, minWidth: 120, height: "fit-content" }}
      size="small"
      className={className}
    >
      <InputLabel disableAnimation sx={{ color: "#A2A2A2" }}>
        {selectedValue ? null : placeholder}
      </InputLabel>
      <TextField
        // labelId={`segment-select-small-label-${id}`}
        required={required}
        id={id}
        value={selectedValue === undefined ? value : selectedValue}
        onChange={(event) => handleChange(event)}
        select
        size="small"
        // placeholder="Kader"
        // label="Kader"
        SelectProps={{
          IconComponent: displayArrowIcon ? undefined : null,
          //   displayEmpty: true,
          MenuProps: {
            sx: {
              height: 250,
              marginTop: "1rem",
            },
            PaperProps: {
              sx: {
                backgroundColor: optionsContainerColor,
                border: `1px solid ${optionsContainerBorderColor}`,
              },
            },
          },
        }}
        sx={{
          minWidth: "250px",
          backgroundColor: "#ffffff",
          borderRadius: "0.7rem",
          border: "1px solid #000000 !important",
          "& .MuiSelect-select": {
            color: "rgba(0, 0, 0, 0.87)",
            ":focus": {
              borderRadius: "0.7rem",
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      >
        {options &&
          options.map((opt, i) => {
            if (optionsValuesToLowerCase) {
              opt.value =
                typeof opt.value === "string"
                  ? opt.value.toLowerCase()
                  : opt.value;
            }

            let isDisabled = false;
            if (isOptionAlreadySelected) {
              isDisabled = isOptionAlreadySelected(opt);
            }

            return i === 0 && firstOptionItalic ? (
              <MenuItem
                key={`${id}-${opt.value}-${i}`}
                value={opt.value}
                disabled={isDisabled}
              >
                <em>{opt.label}</em>
              </MenuItem>
            ) : (
              <MenuItem
                key={`${id}-${opt.value}-${i}`}
                value={opt.value}
                disabled={isDisabled}
              >
                {opt.label}
              </MenuItem>
            );
          })}
      </TextField>
    </FormControl>
  );
}
