import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  MDBInput,
  toast,
} from "mdbreact";
import ApiService from "../../../services/ApiService";
import CheckInvoiceDetails from "../../../common/CheckInvoiceDetails";
import Loader from "../../../common/Loader";

class ParticipationValidation extends React.Component {
  constructor(props) {
    super(props);
    this.validatePayment = this.validatePayment.bind(this);
    this.setSelectedItem = this.setSelectedItem.bind(this);
    this.getInvoiceType = this.getInvoiceType.bind(this);
    this.sendAttachmentsToGuest = this.sendAttachmentsToGuest.bind(this);

    this.state = {
      sendInvoice: false,
      sendPassport: false,
      sendRefundEmail: false,
      confirmationTemplateId: null,
      invoiceTemplateId: null,
      refundInvoiceTemplateId: null,
      invoice: null,
      invoicesToRefund: [],
      invoicesToValidate: [],
      isLoading: false,
      selectedItem: null,
      invoiceInCreationProcess: false,
    };
  }

  setSelectedItem(value) {
    const { invoicesToRefund, invoicesToValidate } = this.state;

    const invoices = [...invoicesToRefund, ...invoicesToValidate];
    const selectedInvoice =
      value && invoices.length > 0
        ? invoices.find((inv) => inv.id === value)
        : null;

    this.setState({
      selectedItem: value,
      invoice: selectedInvoice,
    });
  }

  async componentDidMount() {
    const { event, code, templates, invoicesList, guest } = this.props;

    const invoicesToRefund = [];
    const invoicesToValidate = [];

    invoicesList.forEach((invoice) => {
      if (
        parseInt(invoice.contact_id) === parseInt(guest.contact_id) &&
        !invoice.data.refund
      ) {
        if (!invoice.invoice_number && !this.state.invoiceInCreationProcess) {
          this.setState({ invoiceInCreationProcess: true });
        }
        if (
          invoice.payment_mode === "check" &&
          invoice.status === "pending" &&
          invoice.data &&
          invoice.data.totalTTC > 0
        ) {
          invoicesToValidate.push(invoice);
        } else if (
          invoice.payment_mode === "check" &&
          invoice.status === "pending" &&
          invoice.data &&
          invoice.data.totalTTC < 0
        ) {
          invoicesToRefund.push(invoice);
        }
      }
    });

    invoicesToRefund.sort((a, b) => a.id - b.id);
    invoicesToValidate.sort((a, b) => a.id - b.id);
    console.log(
      "🚀 ~ ParticipationValidation ~ componentDidMount ~ invoicesToValidate:",
      { invoicesToValidate, invoicesToRefund }
    );

    this.setState({
      invoicesToRefund,
      invoicesToValidate,
      confirmationTemplateId: guest.created_by_delegataire
        ? event.data.registrationConfirmationDelegataireTemplate.find(
            (template) => template.code === code
          )
          ? event.data.registrationConfirmationDelegataireTemplate.find(
              (template) => template.code === code
            ).value
          : ""
        : event.data.registrationConfirmationTemplate.find(
            (template) => template.code === code
          )
        ? event.data.registrationConfirmationTemplate.find(
            (template) => template.code === code
          ).value
        : "",
      invoiceTemplateId: guest.created_by_delegataire
        ? event.data.invoiceDelegataireTemplate.find(
            (template) => template.code === code
          )
          ? event.data.invoiceDelegataireTemplate.find(
              (template) => template.code === code
            ).value
          : ""
        : event.data.invoiceTemplate.find((template) => template.code === code)
        ? event.data.invoiceTemplate.find((template) => template.code === code)
            .value
        : "",
      refundInvoiceTemplateId: guest.created_by_delegataire
        ? event.data.refundInvoiceDelegataireTemplate.find(
            (template) => template.code === code
          )
          ? event.data.refundInvoiceDelegataireTemplate.find(
              (template) => template.code === code
            ).value
          : ""
        : event.data.refundInvoiceTemplate.find(
            (template) => template.code === code
          )
        ? event.data.refundInvoiceTemplate.find(
            (template) => template.code === code
          ).value
        : "",
      templates: templates,
    });
  }

  async validatePayment() {
    const { guest, event } = this.props;

    this.setState({ isLoading: true });

    const self = this;

    const { invoice } = this.state;

    try {
      //? CREATE CHECK INVOICES
      //TODO: GET check_transaction_id from proforma invoice
      const createdInvoice = await ApiService.awaitRequest(
        {
          children_entity_id: event.children_entity_id,
          evenement_id: event.id,
          data: { ...invoice.data },
          payment_mode: invoice.payment_mode,
          contact_id: guest.contact_id,
          stripe_transaction_id: null,
          check_transaction_id: invoice.check_transaction_id,
          status: "paid",
        },
        "invoices",
        "post"
      ).then(async (data) => {
        const checks_to_refund = guest.checks_to_refund.filter(
          (invId) => invId !== invoice.id
        );

        if (
          JSON.stringify(guest.checks_to_refund) !==
          JSON.stringify(checks_to_refund)
        ) {
          await ApiService.awaitRequest(
            {
              checks_to_refund,
            },
            `guests/${guest.id}`,
            "put"
          );
        }
        return data;
      });

      if (createdInvoice) {
        //? Use try catch because does not worth crashing all the process
        try {
          //? Delete proforma invoice after validation
          await ApiService.awaitRequest({}, `invoices/${invoice.id}`, "delete");
        } catch (error) {
          console.log(error);
        }

        await ApiService.awaitRequest(
          {
            is_validated: true,
          },
          "guests/" + guest.id,
          "put"
        );

        this.sendAttachmentsToGuest();

        this.props.toggle("checkPaymentModal");

        toast.success("Confirmation validée", {
          autoClose: 3000,
        });

        self.props.loadData();
      }
    } catch (error) {
      console.log(error);
      toast.error("Une erreur s'est produite durant la validation du chèque");
    } finally {
      this.setState({ isLoading: false, invoice: null });
    }
  }

  async sendAttachmentsToGuest() {
    const {
      sendInvoice,
      sendRefundEmail,
      sendPassport,
      invoiceTemplateId,
      refundInvoiceTemplateId,
      confirmationTemplateId,
    } = this.state;

    const { guest, templates } = this.props;

    const invoiceType = this.getInvoiceType();

    if (sendPassport && invoiceType === "order") {
      await ApiService.awaitRequest(
        {
          template_id: confirmationTemplateId,
          subject: templates.find(
            (template) => template.id === Number(confirmationTemplateId)
          )
            ? templates.find(
                (template) => template.id === Number(confirmationTemplateId)
              ).description
            : "",
          guest_ids: [guest.id],
          user_ids: null,
        },
        "mailtemplates/send",
        "post"
      );
    }

    if (sendInvoice || sendRefundEmail) {
      await ApiService.awaitRequest(
        {
          template_id: invoiceTemplateId,
          subject: templates.find(
            (template) =>
              template.id ===
              Number(
                invoiceType === "order"
                  ? invoiceTemplateId
                  : refundInvoiceTemplateId
              )
          )
            ? templates.find(
                (template) =>
                  template.id ===
                  Number(
                    invoiceType === "order"
                      ? invoiceTemplateId
                      : refundInvoiceTemplateId
                  )
              ).description
            : "",
          guest_ids: [guest.id],
          user_ids: null,
        },
        "mailtemplates/send",
        "post"
      );
    }
  }

  getInvoiceType() {
    const { invoice } = this.state;
    if (!invoice) return "";
    if (
      invoice.payment_mode === "check" &&
      invoice.status === "pending" &&
      invoice.data &&
      invoice.data.totalTTC > 0
    ) {
      return "order";
    } else if (
      invoice.payment_mode === "check" &&
      invoice.status === "pending" &&
      invoice.data &&
      invoice.data.totalTTC < 0
    ) {
      return "refund";
    }
    return "";
  }

  render() {
    const {
      sendInvoice,
      sendPassport,
      sendRefundEmail,
      invoice,
      isLoading,
      invoicesToRefund,
      invoicesToValidate,
      selectedItem,
      invoiceInCreationProcess,
    } = this.state;

    let firstname = "";
    let lastname = "";
    let address = "";
    let address_more = "";
    let zip_code = "";
    let city = "";
    let country = "";
    let company = "";

    if (invoice && invoice.data) {
      firstname = invoice.data.firstname;
      lastname = invoice.data.lastname;
      address = invoice.data.address;
      address_more = invoice.data.address_more;
      zip_code = invoice.data.zip_code;
      city = invoice.data.city;
      country = invoice.data.country;
      company = invoice.data.company;
    }

    const invoiceType = this.getInvoiceType();

    return (
      <span>
        <Modal
          size="lg"
          isOpen={this.props.modal}
          toggle={() => this.props.toggle("checkPaymentModal")}
        >
          <ModalHeader toggle={() => this.props.toggle("checkPaymentModal")}>
            Commande{" "}
            {invoicesToValidate.length
              ? invoicesToValidate[0].order_number
              : invoicesToRefund.length
              ? invoicesToRefund[0].order_number
              : ""}
          </ModalHeader>
          <ModalBody>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="row">
                <div className="col-md-8">
                  {invoiceInCreationProcess ? (
                    <p className="text-danger">
                      *Des commandes ou remboursements sont en cours de création
                      veuillez{" "}
                      <span className="font-weight-bold">
                        rafraichir la page dans 1 min
                      </span>
                    </p>
                  ) : null}
                  {this.props.isThereOrderToValidate &&
                    invoicesToValidate.map((inv, i) =>
                      inv.invoice_number ? (
                        <CheckInvoiceDetails
                          key={`invoice-details-${i}`}
                          invoiceData={inv.data}
                          code="FR"
                          type="order"
                          invoiceId={inv.id}
                          selectedItem={selectedItem}
                          setSelectedItem={this.setSelectedItem}
                        />
                      ) : null
                    )}

                  {invoicesToRefund.map((inv, i) =>
                    inv.invoice_number ? (
                      <CheckInvoiceDetails
                        key={`refund-invoice-details-${i}`}
                        invoiceData={inv.data}
                        code="FR"
                        type="refund"
                        invoiceId={inv.id}
                        selectedItem={selectedItem}
                        setSelectedItem={this.setSelectedItem}
                      />
                    ) : null
                  )}
                </div>
                {invoiceType === "order" ? (
                  <div className="col-md-4">
                    <h4>Coordonnées</h4>
                    <div>{company}</div>
                    <div>{lastname}</div>
                    <div>{firstname}</div>
                    <div>{address}</div>
                    <div>{address_more}</div>
                    <div>{zip_code}</div>
                    <div>{city}</div>
                    <div>{country}</div>
                    <br />
                    <div
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#EEEEEE",
                        padding: "10px",
                      }}
                    >
                      <p>Emails automatiques :</p>
                      <MDBInput
                        onClick={() =>
                          this.setState({ sendInvoice: !sendInvoice })
                        }
                        id="1"
                        label="Envoyer la facture"
                        filled
                        checked={sendInvoice}
                        type="checkbox"
                      />
                      <MDBInput
                        onClick={() =>
                          this.setState({ sendPassport: !sendPassport })
                        }
                        id="2"
                        label="Envoyer le(s) badge(s"
                        filled
                        checked={sendPassport}
                        type="checkbox"
                      />
                    </div>
                    <br />
                    <button
                      onClick={() => this.validatePayment()}
                      type="submit"
                      className="btn pink darken-1 btn-block white-text"
                    >
                      Confirmer le paiement
                    </button>
                    <br />
                    <button
                      onClick={() => this.props.toggle("checkPaymentModal")}
                      className="btn btn-block"
                      style={{
                        backgroundColor: "#383B42",
                        color: "white",
                      }}
                    >
                      Annuler
                    </button>
                  </div>
                ) : invoiceType === "refund" ? (
                  <div className="col-md-4">
                    <h4>Coordonnées</h4>
                    <div>{company}</div>
                    <div>{lastname}</div>
                    <div>{firstname}</div>
                    <div>{address}</div>
                    <div>{address_more}</div>
                    <div>{zip_code}</div>
                    <div>{city}</div>
                    <div>{country}</div>
                    <br />
                    <div
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#EEEEEE",
                        padding: "10px",
                      }}
                    >
                      <p>Emails automatiques :</p>
                      <MDBInput
                        onClick={() =>
                          this.setState({ sendRefundEmail: !sendRefundEmail })
                        }
                        id="refund-validation-email"
                        label="Envoyer la confirmation de remboursement"
                        filled
                        checked={sendRefundEmail}
                        type="checkbox"
                      />
                      <button
                        onClick={() => this.validatePayment()}
                        type="submit"
                        className="btn pink darken-1 btn-block white-text"
                      >
                        Confirmer le remboursement
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              onClick={() => this.props.toggle("checkPaymentModal")}
            >
              Fermer
            </Button>
          </ModalFooter>
        </Modal>
      </span>
    );
  }
}

export default ParticipationValidation;
