import React from "react";
import { Input } from "../../components/Input";
import { Select } from "../../components/Select";
import { SegmentMultipleSelectChip } from "../../components/SegmentMultipleSelectChip";
import { DoubleSelect } from "../../components/DoubleSelect";
import { languagesList } from "../../../../common/Translations";

export function GuestTableFilters({
  guests,
  selectedGuests,
  setFilteredGuests,
  packageOptions,
  tagsOptions,
  countryOptions,
  componentsOptions,
  eventComponentsResponses,
  components,
  filtersValues,
}) {
  const [nameFilter, setNameFilter] = React.useState(
    filtersValues.current.nameFilter || ""
  );
  const [companyFilter, setCompanyFilter] = React.useState(
    filtersValues.current.companyFilter || ""
  );
  const [languageFilter, setLanguageFilter] = React.useState(
    filtersValues.current.languageFilter || ""
  );
  const [countryFilter, setCountryFilter] = React.useState(
    filtersValues.current.countryFilter || ""
  );
  const [packageFilter, setPackageFilter] = React.useState(
    filtersValues.current.packageFilter || ""
  );
  const [followerFilter, setFollowerFilter] = React.useState(
    filtersValues.current.followerFilter || ""
  );
  const [documentFilter, setDocumentFilter] = React.useState(
    filtersValues.current.documentFilter || ""
  );
  const [tagsFilter, setTagsFilter] = React.useState(
    filtersValues.current.tagsFilter || []
  );
  const [responseFilter, setResponseFilter] = React.useState(
    filtersValues.current.responseFilter || ""
  );
  const [componentFilter, setComponentFilter] = React.useState(
    filtersValues.current.componentFilter || ""
  );
  const [componentResponse, setComponentResponse] = React.useState(
    filtersValues.current.componentResponse || ""
  );
  const [guestsCheckedFilter, setGuestsCheckedFilter] = React.useState("");

  const filterGuests = () => {
    let guestsFiltered = guests;

    //? FILTER BY NAME
    if (nameFilter.trim()) {
      guestsFiltered = guestsFiltered.filter((guest) => {
        const guestName = `${guest.firstname} ${guest.lastname}`
          .trim()
          .toLowerCase();
        return guestName.includes(nameFilter.trim().toLowerCase())
          ? guest
          : false;
      });
    }

    // TODO: include company inside guest => backend
    if (companyFilter.length > 0) {
      guestsFiltered = guestsFiltered.filter((guest) => {
        return (
          guest.contact &&
          guest.contact.company &&
          guest.contact.company.name
            .trim()
            .toLowerCase()
            .includes(companyFilter.trim().toLowerCase())
        );
      });
    }

    if (countryFilter) {
      guestsFiltered = guestsFiltered.filter(
        (guest) =>
          guest.country &&
          guest.country.trim().toLowerCase() ===
            countryFilter.trim().toLowerCase()
      );
    }

    if (packageFilter) {
      guestsFiltered = guestsFiltered.filter(
        (guest) => guest.package_id === Number(packageFilter)
      );
    }

    if (responseFilter) {
      switch (responseFilter) {
        case "will_join":
          guestsFiltered = guestsFiltered.filter((guest) => guest.participate);
          break;
        case "will_not_join":
          guestsFiltered = guestsFiltered.filter(
            (guest) =>
              !guest.participate &&
              eventComponentsResponses.some(
                (reponse) => reponse.guest_id === guest.id
              )
          );
          break;
        case "waiting":
          guestsFiltered = guestsFiltered.filter(
            (guest) =>
              !guest.participate &&
              !eventComponentsResponses.some(
                (reponse) => reponse.guest_id === guest.id
              )
          );
          break;
        case "pending":
          guestsFiltered = guestsFiltered.filter(
            (guest) => !guest.is_validated
          );
          break;
        default:
          break;
      }
    }

    if (documentFilter) {
      switch (documentFilter) {
        case "has_document":
          guestsFiltered = guestsFiltered.filter(
            (guest) => guest.has_documents
          );
          break;
        case "hasnt_document":
          guestsFiltered = guestsFiltered.filter(
            (guest) => !guest.has_documents
          );
          break;
        default:
          break;
      }
    }

    // TODO: include company inside guest => backend
    if (tagsFilter.length > 0) {
      guestsFiltered = guestsFiltered.filter((guest) =>
        guest.contact.tags.some((tag) => tagsFilter.includes(tag.id))
      );
    }

    if (followerFilter.length > 0) {
      switch (followerFilter) {
        case "without_follower":
          guestsFiltered = guestsFiltered.filter(
            (guest) => guest.max_invitation <= 0
          );
          break;
        case "with_follower":
          guestsFiltered = guestsFiltered.filter(
            (guest) => guest.max_invitation > 0
          );
          break;
        default:
          break;
      }
    }

    // TODO: include company inside guest => backend
    if (languageFilter) {
      guestsFiltered = guestsFiltered.filter(
        (guest) =>
          guest.contact &&
          guest.contact.languages &&
          guest.contact.languages.trim().toLowerCase() ===
            languageFilter.toLowerCase()
      );
    }

    if (componentResponse && componentFilter) {
      const res = componentResponse.split("-");
      const value = res[0];
      const optionKey = res[1];
      guestsFiltered = guestsFiltered.filter((guest) => {
        return eventComponentsResponses.some((item) => {
          return (
            item.guest_id === guest.id &&
            item.responses.some(
              (rep) =>
                Number(rep.id) === Number(componentFilter) &&
                Number(rep.key) === Number(optionKey) &&
                rep.label &&
                rep.label.toLowerCase() === value.toLowerCase()
            )
          );
        });
      });
    }

    if (guestsCheckedFilter) {
      switch (guestsCheckedFilter) {
        case "yes":
          guestsFiltered = guestsFiltered.filter((guest) =>
            selectedGuests.includes(guest.id)
          );
          break;
        case "no":
          guestsFiltered = guestsFiltered.filter(
            (guest) => !selectedGuests.includes(guest.id)
          );
          break;
        default:
          break;
      }
    }

    setFilteredGuests(guestsFiltered);
    filtersValues.current = {
      nameFilter,
      companyFilter,
      languageFilter,
      countryFilter,
      packageFilter,
      followerFilter,
      documentFilter,
      tagsFilter,
      responseFilter,
      componentFilter,
      componentResponse,
    };
  };

  const getSecondSelectOptions = () => {
    if (componentFilter) {
      let componentResponsesOptions = [];
      const component = components.find(
        (cmp) => cmp.id === Number(componentFilter)
      );

      try {
        component.data.reponse.options.forEach((rep) => {
          const res = rep.value ? rep.value.split(";") : [];
          if (res.length > 0) {
            res.forEach((val, index) =>
              componentResponsesOptions.push({
                value: `${val}-${index}`,
                label: `${val} - (${rep.label})`,
              })
            );
          }
        });
      } catch (error) {
        console.log(error);
        return [];
      }
      return componentResponsesOptions;
    }
    return [];
  };

  React.useEffect(() => {
    filterGuests();
  }, [
    nameFilter,
    companyFilter,
    languageFilter,
    countryFilter,
    packageFilter,
    followerFilter,
    documentFilter,
    tagsFilter,
    // componentFilter,
    componentResponse,
    responseFilter,
    guestsCheckedFilter,
  ]);

  const languagesOptions = languagesList.map((lang) => ({
    value: lang.code,
    label: lang.label,
  }));
  languagesOptions.unshift({ label: "Langues", value: "" });

  const followerOptions = [
    { label: "Accompagnant", value: "" },
    { label: "Avec accompagnant", value: "with_follower" },
    { label: "Sans accompagnant", value: "without_follower" },
  ];
  const responseOptions = [
    { label: "Réponse", value: "" },
    { label: "Oui", value: "will_join" },
    { label: "Non", value: "will_not_join" },
    { label: "Non repondu", value: "waiting" },
    { label: "En attente de validation", value: "pending" },
  ];
  const documentOptions = [
    { label: "Document", value: "" },
    { label: "Avec document", value: "has_document" },
    { label: "Sans document", value: "hasnt_document" },
  ];
  const guestsCheckedOptions = [
    { label: "Tous", value: "" },
    { label: "Invités sélectionnés", value: "yes" },
    { label: "Invités non sélectionnés", value: "no" },
  ];

  return (
    <div>
      <Input
        bgColor="#ffffff"
        borderColor="#000000"
        textColor="rgba(0, 0, 0, 0.87)"
        placeholder="Nom"
        id="input-outlined-basic-segment-condition"
        value={nameFilter}
        handleValue={({ target: { value } }) => setNameFilter(value)}
      />
      <Input
        bgColor="#ffffff"
        borderColor="#000000"
        textColor="rgba(0, 0, 0, 0.87)"
        placeholder="Société"
        id="input-outlined-basic-segment-condition"
        value={companyFilter}
        handleValue={({ target: { value } }) => setCompanyFilter(value)}
      />
      <Select
        selectedValue={languageFilter}
        handleSelectedValue={setLanguageFilter}
        options={languagesOptions}
        id="list-select-language"
        displayArrowIcon={true}
        placeholder="Langues"
        margin={1}
      />
      <Select
        selectedValue={countryFilter}
        handleSelectedValue={setCountryFilter}
        options={countryOptions}
        id="list-select-country"
        displayArrowIcon={true}
        placeholder="Pays"
        margin={1}
      />
      <Select
        selectedValue={packageFilter}
        handleSelectedValue={setPackageFilter}
        options={[{ label: "Package", value: "" }, ...packageOptions]}
        id="list-select-package"
        displayArrowIcon={true}
        placeholder="Packages"
        margin={1}
      />
      <Select
        selectedValue={followerFilter}
        handleSelectedValue={setFollowerFilter}
        options={followerOptions}
        id="list-select-follower"
        displayArrowIcon={true}
        placeholder="Accompagnant"
        margin={1}
      />
      <Select
        selectedValue={responseFilter}
        handleSelectedValue={setResponseFilter}
        options={responseOptions}
        id="list-select-response"
        displayArrowIcon={true}
        placeholder="Réponse"
        margin={1}
      />
      <Select
        selectedValue={documentFilter}
        handleSelectedValue={setDocumentFilter}
        options={documentOptions}
        id="list-select-document"
        displayArrowIcon={true}
        placeholder="Document OUI/NON"
        margin={1}
      />
      <SegmentMultipleSelectChip
        options={tagsOptions}
        id={"list-tags-multi-select"}
        selectedValue={tagsFilter}
        handleSelectedValue={(val) => {
          setTagsFilter(val);
        }}
        placeholder="TAGS"
        bgColor="#ffffff"
        borderColor="#000000"
        chipTextColor="#000000"
        optionsTextColor="#000000"
        optionsBgColor="#ffffff"
      />
      <Select
        selectedValue={guestsCheckedFilter}
        handleSelectedValue={setGuestsCheckedFilter}
        options={guestsCheckedOptions}
        id="list-select-guests-checked"
        displayArrowIcon={true}
        placeholder="Sélectionnés OUI/NON"
        margin={1}
      />
      <div className="d-inline-block">
        <DoubleSelect
          firstSelectValue={componentFilter}
          handleFirstSelectValue={setComponentFilter}
          secondSelectValue={componentResponse}
          handleSecondSelectValue={setComponentResponse}
          firstSelectOptions={componentsOptions}
          secondSelectOptions={getSecondSelectOptions()}
        />
      </div>
    </div>
  );
}
