import React, { useEffect, useState } from "react";
import { CircularProgress, Box } from "@mui/material";
import Settings from "./Settings";
import Emargement from "./Emargement";
import ApiService from "../../services/ApiService";
import Scan from "./Scan";
import LoginForm from "../../common/LoginForm";
import { Switch, Route } from "react-router-dom";
import { clearLocalStorage } from "../../services/Utils";
import { toast } from "react-toastify";

function CheckinMainNew() {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [isLogged, setIsLogged] = useState(ApiService.checkToken());

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const eventsData = await ApiService.awaitRequest(
        {},
        "evenements/checkin/currents",
        "get"
      );
      if (eventsData && eventsData.length > 0) {
        const eventsCheckin = eventsData.filter(
          (e) => e.data.checkIn === "true"
        );
        const sortDataWithStartDate = eventsCheckin.sort(function (a, b) {
          const dateA = a.start_date;
          const dateB = b.start_date;

          if (dateA < dateB) {
            return -1;
          }
          if (dateA > dateB) {
            return 1;
          }
          return 0;
        });

        setEvents(sortDataWithStartDate);
        if (sortDataWithStartDate.length === 1) {
          setSelectedEvent(sortDataWithStartDate[0].id);
        }
      }
    };

    try {
      if (isLogged) fetchData();
    } catch (error) {
      console.log(error);
      toast.error(
        "Une erreur s'est produite. Veuillez réessayer ultérieurement."
      );
    } finally {
      setLoading(false);
    }
  }, [isLogged]);

  const doLogout = () => {
    clearLocalStorage();

    setIsLogged(false);
  };

  const doLogin = () => {
    if (!window.location.href.includes("checkin")) {
      window.location.replace("/checkin/");
    }
    setIsLogged(true);
  };

  const getEventOptions = () => {
    return events && events.length > 0
      ? events.map((item) => ({ label: item.data.name, value: item.id }))
      : [];
  };

  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <CircularProgress size={60} color={"error"} />
      </Box>
    );

  return (
    <Box sx={{ height: "100%" }}>
      {isLogged ? (
        <Switch>
          <Route
            path="/checkin/registration/:eventId/:checkinPointId"
            render={(props) => <Emargement {...props} />}
          />
          <Route
            path="/checkin/scan/:eventId/:checkinPointId"
            render={(props) => <Scan {...props} />}
          />
          <Route
            path="/checkin"
            render={(props) => (
              <Settings
                {...props}
                events={events}
                eventsOptions={getEventOptions()}
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
                doLogout={doLogout}
              />
            )}
          />
        </Switch>
      ) : (
        <LoginForm doLogin={doLogin} />
      )}
    </Box>
  );
}

export default CheckinMainNew;
