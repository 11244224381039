import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import Multilingual from "../../../common/Multilingual";
import { toast } from "react-toastify";
import moment from "moment";
import ApiService from "../../../services/ApiService";

registerLocale("fr", fr);

class ComposantAdvancedConfig extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.formatInput = this.formatInput.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleLang = this.handleLang.bind(this);
    const { data } = this.props;
    this.IlimitedQuota = 1000000;
    this.minTime1 = null;
    this.minTime2 = null;
    this.maxTime1 = null;
    this.maxTime2 = null;
    this.state = {
      startDate: this.props.eventDates.startDate,
      endDate: this.props.eventDates.endDate,
      // startTimeDate: this.props.eventDates.startDate,
      // endTimeDate: this.props.eventDates.endDate,
      debutDate: null,
      debutDate2: null,
      componentParticipants: 0,
      ...data,
    };
  }

  handleLang(newLangSet, label) {
    this.setState({
      [label]: newLangSet,
    });
  }

  async handleSubmit(e) {
    try {
      e.preventDefault();
      const { startDate, endDate, quotas } = this.state;

      const componentParticipants = await ApiService.awaitRequest(
        this.state,
        "components/participants/foradmin/" +
          this.props.match.params.componentid +
          "/" +
          this.props.match.params.eventid,
        "get"
      );

      const toastMessageDate =
        "La date de début du composant ne peut pas être après la date de fin";
      const toastMessageHour =
        "L'heure de début du composant ne peut pas être après l'heure de fin";
      if (moment(startDate).isAfter(endDate)) {
        if (moment(startDate.getHours()).isAfter(endDate.getHours())) {
          toast.info(toastMessageHour, { autoClose: 3000 });
          return;
        }
        toast.info(toastMessageDate, { autoClose: 3000 });
        return;
      }

      if (componentParticipants >= 0) {
        const totalDelegataireQuotas =
          quotas.delegataires && quotas.delegataires.length
            ? quotas.delegataires.reduce((acc, curr) => {
                if (curr) return curr.quota + acc;
                return acc;
              }, 0)
            : 0;

        if (quotas.total < componentParticipants + totalDelegataireQuotas) {
          toast.info(
            "Quota inférieur au nombre d'invités inscrits et aux quotas délégataires."
          );
          return;
        }
        this.props.onSave({ ...this.props.data, ...this.state });
      } else throw new Error("cannot get component participants");
    } catch (error) {
      console.log(error);
      toast.error("Une erreur s'est produite durant l'enregistrement.");
    }
  }

  handleChange(e) {
    let totalTTC = this.state.totalTTC;
    let quota = this.state.quotas.total;

    if (e.target.name === "totalHT") {
      totalTTC = e.target.value * (1 + this.state.tax / 100);
    } else if (e.target.name === "tax") {
      totalTTC = (1 + e.target.value / 100) * this.state.totalHT;
    } else if (e.target.name === "quota") {
      quota =
        e.target.value.toLowerCase() === "ilimité"
          ? this.IlimitedQuota
          : Number(e.target.value);
    }

    this.setState({
      totalTTC: totalTTC ? parseFloat(totalTTC).toFixed(2) : null,
      [e.target.name]: e.target.value,
      quotas: {
        ...this.state.quotas,
        total: quota,
      },
    });
  }
  formatInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-")
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  calculateTime(date, eventStartDate, eventEndDate) {
    const minTime = new Date().setHours(0, 0, 0);
    const maxTime = new Date().setHours(23, 59, 59);
    if (date.getDate() === eventStartDate.getDate()) {
      return {
        minTime: new Date().setHours(
          eventStartDate.getHours(),
          eventStartDate.getMinutes(),
          0
        ),
        maxTime: new Date().setHours(23, 59, 0),
      };
    } else if (date.getDate() === eventEndDate.getDate()) {
      return {
        minTime: new Date().setHours(0, 0, 0),
        maxTime: new Date().setHours(
          eventEndDate.getHours(),
          eventEndDate.getMinutes(),
          0
        ),
      };
    }
    return { minTime, maxTime };
  }

  /**
   *  Il faut tester si le composant est sur 2 jours peut etre tester au moment de sauvegarder et pas au modification, ce qui permettrait de mettre l'heure quon veut est d'etre plus
   * libre ( faire une nouvelle fonction qui check a la fin lors du submit pour savoir si les dates sont valide sinon pas de check effectué )    FAIT
   *
   * Ensuite si je creer un composant le definir sur l'heure du composant creer et non le new date   FAIT
   *
   * si je vais au 14 et que je met 0h00 je peux revenir au 13 sans probleme, peut etre voir si je ne peux pas le modifier directement dans le code, exemple si je repasse au 13 il me
   * remet d'office l'heure min du composant que si c la date de debut et pareil pour la fin ( a faire dans le onchange) FAIT
   *
   * Si l'event est sur 1 jours alors il faut checker l'heure de fin FAIT
   *
   * si on change la date de fin de l'event apres avoir creer des composant afficher un message d'erreur
   *
   */

  handleStartDateChange(date, datePickerId) {
    const startDateEvent = new Date(this.props.eventDates.startDate);
    const endDateEvent = new Date(this.props.eventDates.endDate);
    const newState = {};

    //permet de prendre un compte si l'evenement fais 1 ou plusieurs jours, ce qui permet de mettre 1 ou 2 horaire pour une journée
    let minTimeS, maxTimeS;

    if (startDateEvent.getDate() === endDateEvent.getDate()) {
      minTimeS = new Date(this.props.eventDates.startDate);
      maxTimeS = new Date(this.props.eventDates.endDate);
    } else {
      const { minTime, maxTime } = this.calculateTime(
        date,
        new Date(this.props.eventDates.startDate),
        new Date(this.props.eventDates.endDate)
      );
      minTimeS = minTime;
      maxTimeS = maxTime;
    }
    const minTimeKey = datePickerId === 1 ? "minTime1" : "minTime2";
    const maxTimeKey = datePickerId === 1 ? "maxTime1" : "maxTime2";

    this[minTimeKey] = new Date(minTimeS);
    this[maxTimeKey] = new Date(maxTimeS);

    const nameDatePicker = datePickerId === 1 ? "debutDate" : "debutDate2";
    const statueDate = datePickerId === 1 ? "startDate" : "endDate";

    if (
      date.getDate() === startDateEvent.getDate() ||
      date.getDate() === endDateEvent.getDate()
    ) {
      if (date.getHours() < startDateEvent.getHours()) {
        date.setHours(startDateEvent.getHours());
        date.setMinutes(startDateEvent.getMinutes());
      }
      if (date.getHours() > endDateEvent.getHours()) {
        date.setHours(endDateEvent.getHours());
        date.setMinutes(endDateEvent.getMinutes());
      }
    }

    newState[statueDate] = date;
    newState[nameDatePicker] = 1;

    this.setState(newState);
  }

  handleEndDateChange(date, picker) {
    const { startDate, endDate } = this.state;
    const newDate = new Date(picker === 1 ? startDate : endDate);
    newDate.setHours(date.getHours(), date.getMinutes());

    const statueDate = picker === 1 ? "startDate" : "endDate";

    this.setState({
      [statueDate]: newDate,
    });
  }

  componentDidMount() {
    const { startDate, endDate } = this.state;
    this.handleStartDateChange(new Date(startDate), 1);
    this.handleStartDateChange(new Date(endDate), 2);
  }

  render() {
    const {
      isFree,
      priceType,
      tax,
      totalHT,
      totalTTC,
      startDate,
      endDate,
      locationName,
      address1,
      address2,
      zipCode,
      city,
      roomName,
      quotas,
      quotaWarning,
      quotaWarningLabel,
      billingLabel,
      debutDate,
      debutDate2,
    } = this.state;

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <br />
          <br />
          <form className="form" onSubmit={this.handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Tarif</label>
                <select
                  onChange={this.handleChange}
                  name="isFree"
                  className="form-control browser-default custom-select"
                  value={isFree}
                >
                  <option value={false}>Payant</option>
                  <option value={true}>Gratuit</option>
                </select>
              </div>
            </div>
            {isFree === "false" ? (
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Sélectionner le type de tarif payant</label>
                  <select
                    name="priceType"
                    className="form-control browser-default custom-select"
                    onChange={this.handleChange}
                    value={priceType}
                  >
                    <option value="unique">Tarif fixe</option>
                    <option value="rules">Tarif multiple</option>
                  </select>
                </div>

                {priceType === "unique" ? (
                  <>
                    <div className="form-group col-md-6">
                      <label>Taux de TVA</label>
                      <select
                        onChange={this.handleChange}
                        name="tax"
                        className="form-control browser-default custom-select"
                        value={tax}
                      >
                        <option value="" disabled>
                          Sélectionner une option
                        </option>
                        <option value="0">0%</option>
                        <option value="5.5">5,5%</option>
                        <option value="10">10%</option>
                        <option value="20">20%</option>
                      </select>
                    </div>
                    <div className="form-group col-md-3">
                      <label>Montant HT</label>
                      <input
                        required
                        onChange={this.handleChange}
                        onKeyDown={this.formatInput}
                        name="totalHT"
                        className="form-control"
                        value={totalHT}
                        type="number"
                        step="any"
                        min="0"
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <label>Montant TTC</label>
                      <input
                        required
                        onChange={this.handleChange}
                        onKeyDown={this.formatInput}
                        step="any"
                        min="0"
                        name="totalTTC"
                        className="form-control"
                        value={totalTTC}
                        type="number"
                      />
                    </div>
                  </>
                ) : null}
                <div className="form-group col-md-12">
                  <label>Label dans la facturation</label>
                  <div className="form-row">
                    <Multilingual
                      eventId={this.props.match.params.eventid}
                      lang={billingLabel}
                      type="input"
                      handleLang={(e) => this.handleLang(e, "billingLabel")}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Date de début </label>
                <DatePicker
                  className="form-control"
                  selectsStart
                  selected={new Date(startDate)}
                  onChange={(date) => {
                    this.setState({
                      debutDate: 1,
                    });
                    this.handleStartDateChange(date, 1);
                  }}
                  locale="fr"
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date(this.props.eventDates.startDate)}
                  maxDate={new Date(this.props.eventDates.endDate)}
                />
                {debutDate && (
                  <DatePicker
                    className="form-control"
                    selected={new Date(startDate)}
                    selectsEnd
                    onChange={(date) => {
                      this.handleEndDateChange(date, 1);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeCaption="Time"
                    timeIntervals={15}
                    dateFormat="HH:mm"
                    minTime={this.minTime1}
                    maxTime={this.maxTime1}
                    locale="fr"
                  />
                )}
              </div>

              <div className="form-group col-md-6">
                <label>Date de fin </label>
                <DatePicker
                  className="form-control"
                  selectsEnd
                  // startDate={startDate}
                  endDate={endDate}
                  selected={new Date(endDate)}
                  onChange={(date) => {
                    this.setState({
                      debutDate2: 2,
                    });
                    this.handleStartDateChange(date, 2);
                  }}
                  locale="fr"
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date(this.props.eventDates.startDate)}
                  maxDate={new Date(this.props.eventDates.endDate)}
                />
                {debutDate2 && (
                  <DatePicker
                    className="form-control"
                    selected={new Date(endDate)}
                    selectsEnd
                    onChange={(date) => {
                      this.handleEndDateChange(date, 2);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeCaption="Time"
                    timeIntervals={15}
                    locale="fr"
                    dateFormat="HH:mm"
                    minTime={this.minTime2}
                    maxTime={this.maxTime2}
                  />
                )}
              </div>
            </div>
            <div className="form-group">
              <label>Nom du lieu</label>
              <input
                name="locationName"
                type="text"
                value={locationName}
                className="form-control"
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label>Adresse 1</label>
              <input
                name="address1"
                type="text"
                value={address1}
                className="form-control"
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label>Adresse 2</label>
              <input
                name="address2"
                value={address2}
                type="text"
                className="form-control"
                onChange={this.handleChange}
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label>Code postal</label>
                <input
                  value={zipCode}
                  name="zipCode"
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group col-md-8">
                <label>Ville</label>
                <input
                  type="text"
                  value={city}
                  name="city"
                  className="form-control"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Salle / Espace</label>
              <input
                name="roomName"
                type="text"
                value={roomName}
                className="form-control"
                onChange={this.handleChange}
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-2">
                <label>Quota de places (*)</label>
                <input
                  value={
                    quotas.total === this.IlimitedQuota
                      ? "Ilimité"
                      : quotas.total
                  }
                  name="quota"
                  type="text"
                  className="form-control"
                  onChange={this.handleChange}
                  pattern="[0-9]+|ilimité|Ilimité"
                  required
                />
              </div>
              <div className="form-group col-md-4">
                <label>
                  Le message s'affiche sur le nombre de place est inférieur à
                </label>
                <input
                  name="quotaWarning"
                  value={quotaWarning}
                  onChange={this.handleChange}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Message par défaut</label>
                <Multilingual
                  placeholder="Il ne reste que %quota% places"
                  eventId={this.props.match.params.eventid}
                  lang={quotaWarningLabel}
                  type="textarea"
                  handleLang={(e) => this.handleLang(e, "quotaWarningLabel")}
                />
              </div>
            </div>
            <input
              type="submit"
              value="Enregistrer"
              className="btn pink darken-1 float-right white-text"
            />
          </form>
        </div>
      </div>
    );
  }
}

export default ComposantAdvancedConfig;
