import React from "react";

import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import ApiService from "../../../../services/ApiService";
import DelegatairesEditor from "./DelegatairesEditor";
import Loader from "../../../../common/Loader";
import { delegataireId, getComponentGuests } from "../../../../services/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TransacEmailContainer from "../../../../common/TransacEmailContainer";
import {
  faTrashAlt,
  faUserLock,
  faEdit,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { CustomTable } from "../../../../common/table_component/CustomTable";
import { Box, IconButton } from "@mui/material";
import { CustomMuiButton } from "../../components/CustomMuiButton";
import { toast } from "react-toastify";
import DelegataireTableFilter from "./DelegataireTableFilter";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "DÉLÉGATAIRE",
  },
  {
    id: "categories",
    numeric: false,
    disablePadding: false,
    label: "TYPE",
  },
  {
    id: "delegataireDateLimit",
    numeric: false,
    disablePadding: false,
    label: "DATE LIMITE",
  },
  {
    id: "delegataireGuestsDateLimit",
    numeric: false,
    disablePadding: false,
    label: "DATE LIMITE INVITÉS",
  },
  {
    id: "packages",
    numeric: false,
    disablePadding: false,
    label: "PACKAGES DISPOS",
  },
  {
    id: "componentsQuota",
    numeric: false,
    disablePadding: false,
    label: "COMPOSANTS QUOTAS",
  },
  {
    id: "componentsQuotaStatus",
    numeric: false,
    disablePadding: false,
    label: "",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "ACTIONS",
  },
];

const COMPONENT_STATUS_LABEL = {
  empty: "Vide",
  partial: "Partiel",
  full: "Complet",
};

const StyledTableCell = styled(TableCell)(({ theme, textcolor = "black" }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "red",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: textcolor,
    // backgroundColor: 'white'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme, backgroundcolor }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: backgroundcolor || "white",
  },
  "&:nth-of-type(even)": {
    backgroundColor: backgroundcolor || "white",
  },

  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const options = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

class DelegatairesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.checkAllRef = React.createRef(null);
    this.handleChange = this.handleChange.bind(this);
    this.deleteDelegataire = this.deleteDelegataire.bind(this);
    this.loadData = this.loadData.bind(this);
    this.getComponentsQuotaData = this.getComponentsQuotaData.bind(this);
    this.allComponentsNbrGuests = this.allComponentsNbrGuests.bind(this);
    this.getPackageName = this.getPackageName.bind(this);
    this.createDelegataireRow = this.createDelegataireRow.bind(this);
    this.populateRows = this.populateRows.bind(this);
    this.setDelegataireChecked = this.setDelegataireChecked.bind(this);
    this.setOpenDialog = this.setOpenDialog.bind(this);
    this.setFilteredDelegataires = this.setFilteredDelegataires.bind(this);
    this.state = {
      delegatairesList: [],
      packagesList: [],
      componentsList: [],
      users: [],
      guests: [],
      isLoading: true,
      event: [],
      totalQuotasDelegataire: 0,
      participateGuestDelegataire: 0,
      delegataireChecked: [],
      delegataireFiltered: [],
      eventResponses: [],
      openDialog: false,
      currentDelegataire: {},

      maxDelegataire: 50,
      delegataireUserId: [],
      action: "",
      usersOptions: [],
      categoriesOptions: [],
    };
  }

  setOpenDialog(value) {
    this.setState({ openDialog: value });
  }

  setDelegataireChecked(delChecked) {
    this.setState({ delegataireChecked: delChecked });
  }

  async loadData() {
    try {
      this.setState({
        isLoading: true,
      });

      const self = this;

      const event = await ApiService.awaitRequest(
        this.state,
        "evenements/" + this.props.match.params.eventid,
        "get"
      );

      const guests = await ApiService.awaitRequest(
        {},
        "guests/byevenement/" + this.props.match.params.eventid,
        "get"
      );

      await ApiService.awaitRequest(
        {},
        "evenement_forms/byevenement/" + this.props.match.params.eventid,
        "get"
      ).then(async function (data) {
        const form = data.find(
          (form) => form.layout.documentType === "reponse"
        );
        await ApiService.awaitRequest(
          {},
          "component_responses/byevenement/" + form.id,
          "get"
        ).then(function (data) {
          self.setState({ eventResponses: data });
        });
      });

      await ApiService.awaitRequest(
        {},
        "packages/byevenement/" + this.props.match.params.eventid,
        "get"
      ).then((data) => {
        if (data) {
          const packagesList = data.map((pck) => ({
            label: pck.name,
            value: pck.id,
            components: pck.components,
          }));
          this.setState({ packagesList });
        }
      });

      await ApiService.awaitRequest(
        {},
        "components/byevenement/" + this.props.match.params.eventid,
        "get"
      ).then((data) => {
        if (data) {
          const filteredComponents = data.filter(
            (cmp) =>
              cmp.data &&
              (cmp.data.componentType === "moment" ||
                cmp.data.componentType === "logistic") &&
              cmp.data.reponse &&
              cmp.data.reponse.role !== "invitation"
          );
          this.setState({ componentsList: filteredComponents });
        }
      });

      let categoriesOptions = await ApiService.request(
        this.state,
        "users/categoriesoptions",
        "get"
      );

      if (categoriesOptions) {
        categoriesOptions.unshift({
          label: "Filtrer par catégorie",
          value: "",
        });
      }

      const usersRaw = await ApiService.awaitRequest({}, "users/", "get");
      const users = usersRaw.filter((user) => user.role === delegataireId);
      const usersOptions = users.map((user) => ({
        value: user.id,
        label: user.lastname + " " + user.firstname,
      }));
      usersOptions.unshift({ value: "", label: "Sélectionner un délégataire" });

      const delegatairesListRaw = await ApiService.awaitRequest(
        {},
        "delegataires/byevenement/" + this.props.match.params.eventid,
        "get"
      );

      let totalQuotasDelegataire = 0;
      let participateGuestDelegataire = 0;

      if (event && guests && usersRaw && delegatairesListRaw) {
        const delegatairesList = delegatairesListRaw
          .map((delegataire) => {
            const user = users.find((user) => user.id === delegataire.user_id);
            const newDelegataire = {
              ...delegataire,
              selectedDelegataireDateLimit: JSON.parse(
                delegataire.selectedDelegataireDateLimit
              ),
              expiration_date:
                delegataire.expiration_date !== ""
                  ? JSON.parse(delegataire.expiration_date)
                  : delegataire.expiration_date,
            };

            // Calculate stat
            const cmpQuotaData = this.getComponentsQuotaData(
              delegataire.user_id
            );
            if (cmpQuotaData && cmpQuotaData.components.length > 0) {
              cmpQuotaData.components.forEach((cmp) => {
                if (cmp.role === "entrance") {
                  totalQuotasDelegataire += cmp.quota;
                  participateGuestDelegataire += cmp.numberOfGuests;
                }
              });
            }

            return {
              ...newDelegataire,
              firstname: user ? user.firstname : "Inconnu",
              lastname: user ? user.lastname : "Inconnu",
            };
          })
          .sort(function (a, b) {
            if (a.lastname.toLowerCase() < b.lastname.toLowerCase()) {
              return -1;
            }
            if (a.lastname.toLowerCase() > b.lastname.toLowerCase()) {
              return 1;
            }
            return 0;
          });

        this.setState({
          delegatairesList,
          delegataireFiltered: delegatairesList,
          guests,
          event,
          users: users,
          usersOptions,
          totalQuotasDelegataire,
          participateGuestDelegataire,
          categoriesOptions,
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Une erreur s'est produite");
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidMount() {
    const self = this;
    await ApiService.request(
      {},
      "mailtemplates/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      const templatesOptions = data.map((template) => ({
        label: template.name,
        value: template.id,
      }));

      const dataTemplatesSorted = templatesOptions.sort((a, b) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
      );
      self.setState({
        templates: data,
        templatesOptions: dataTemplatesSorted,
      });
    });

    this.loadData();
  }

  async deleteDelegataire(id, userId) {
    const componentsList = this.state.componentsList;
    const self = this;

    self.setState({ isLoading: true });

    try {
      await ApiService.request({}, "delegataires/" + id, "delete").then(
        function () {
          componentsList.forEach(async (component) => {
            const delegatairesQuotas =
              component.data && component.data.quotas
                ? component.data.quotas.delegataires
                : [];

            if (delegatairesQuotas.length > 0) {
              const delegataireQuotaIndex = delegatairesQuotas.findIndex(
                (delquota) => delquota.userId === Number(userId)
              );

              if (delegataireQuotaIndex > -1) {
                delegatairesQuotas.splice(delegataireQuotaIndex, 1);

                await ApiService.awaitRequest(
                  {
                    ...component,
                    data: {
                      ...component.data,
                      quotas: {
                        ...component.data.quotas,
                        delegataires: delegatairesQuotas,
                      },
                    },
                  },
                  "components/" + component.id,
                  "put"
                );
              }
              // let newDelegataireQuotas = delegatairesQuotas.filter(
              //   (delquota) => delquota.userId !== Number(userId)
              // );
            }
          });

          self.loadData();
          toast.success("Délégataire supprimé.");
        }
      );
    } catch (error) {
      console.log(error);
      toast.error("Une erreur s'est produite");
    }
  }

  allComponentsNbrGuests(delUserId) {
    const componentsList = this.state.componentsList;
    const eventResponses = this.state.eventResponses;

    const res = componentsList.map((cmp) => {
      const numberOfGuests = getComponentGuests(delUserId, cmp, eventResponses);

      const totalNbrOfAdminGuests = getComponentGuests(
        "total",
        cmp,
        eventResponses
      );

      return { id: cmp.id, numberOfGuests, totalNbrOfAdminGuests };
    });

    return res;
  }

  getComponentsQuotaData(delUserId) {
    const componentsList = this.state.componentsList;
    const eventResponses = this.state.eventResponses;

    const res = componentsList.reduce((acc, component) => {
      const delegatairesQuotas =
        component.data && component.data.quotas
          ? component.data.quotas.delegataires
          : [];

      const delegataireQuotaExist = delegatairesQuotas.find(
        (delquota) => delquota.userId === Number(delUserId)
      );

      if (delegataireQuotaExist) {
        return [
          ...acc,
          {
            id: component.id,
            name: component.data.name,
            quota: delegataireQuotaExist.quota,
            numberOfGuests: getComponentGuests(
              delUserId,
              component,
              eventResponses
            ),
            role: component.data.reponse ? component.data.reponse.role : "",
            startDate: component.data.startDate,
          },
        ];
      }
      return acc;
    }, []);

    res.sort(
      (a, b) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    );

    let status = "";
    if (res.length > 0) {
      let totalQuota = 0;
      let totalNumberOfGuests = 0;
      res.forEach((cmp) => {
        totalQuota += cmp.quota;
        totalNumberOfGuests += cmp.numberOfGuests;
      });

      if (totalNumberOfGuests === 0) status = COMPONENT_STATUS_LABEL.empty;
      else if (totalNumberOfGuests === totalQuota)
        status = COMPONENT_STATUS_LABEL.full;
      else status = COMPONENT_STATUS_LABEL.partial;
    }

    return { components: res, status };
  }

  createDelegataireRow(data) {
    return data.map((delegataire, index) => {
      const cmpQuotaData = this.getComponentsQuotaData(delegataire.user_id);
      const pckRes =
        delegataire.package_ids && delegataire.package_ids.length > 0
          ? delegataire.package_ids.reduce((acc, id, i) => {
              const name = this.getPackageName(id);
              if (name)
                return [
                  ...acc,
                  <p
                    key={`pck-${id}-key-${i}`}
                    className="cell-delegataire-packages"
                  >
                    {name}
                  </p>,
                ];
              return acc;
            }, [])
          : [];

      return {
        id: delegataire.id,
        name: delegataire.user ? (
          <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <p className="delegataire-table-cell-name">
              {delegataire.user.firstname + " " + delegataire.user.lastname}
            </p>
            <IconButton
              size="small"
              onClick={() =>
                window.open(
                  window.location.origin +
                    `/admin/users/edit/${delegataire.user_id}`
                )
              }
            >
              <FontAwesomeIcon icon={faEdit} color="#141414" />
            </IconButton>
          </Box>
        ) : (
          ""
        ),
        categories:
          delegataire.user && delegataire.user.categories
            ? delegataire.user.categories.map((ctg) => ctg.label)
            : [],
        delegataireDateLimit: delegataire.expiration_date
          ? new Date(delegataire.expiration_date.date_limit).toLocaleDateString(
              "fr-FR",
              options
            )
          : "",
        delegataireGuestsDateLimit: delegataire.selectedDelegataireDateLimit
          ? new Date(
              delegataire.selectedDelegataireDateLimit.date_limit
            ).toLocaleDateString("fr-FR", options)
          : "",
        packages: pckRes,
        componentsQuota:
          cmpQuotaData.components && cmpQuotaData.components.length > 0
            ? cmpQuotaData.components.map((cmp, i) => {
                return (
                  <div
                    className="cell-componant-quota"
                    key={`componant-quota-${i}`}
                  >
                    <span>{cmp.name}</span>
                    &nbsp;&nbsp;&nbsp;
                    <span>{`${cmp.numberOfGuests}/${cmp.quota}`}</span>
                  </div>
                );
              })
            : [],
        componentsQuotaStatus: cmpQuotaData.status,
        actions: (
          <>
            <IconButton
              size="small"
              disabled={
                !(cmpQuotaData.status === "Vide" || cmpQuotaData.status === "")
              }
              onClick={() => {
                if (
                  window.confirm("Etes vous sûr de supprimer cet utilisateur ?")
                )
                  this.deleteDelegataire(delegataire.id, delegataire.user_id);
              }}
            >
              <FontAwesomeIcon
                icon={faTrashAlt}
                color={
                  cmpQuotaData.status === "Vide" || cmpQuotaData.status === ""
                    ? "#141414"
                    : undefined
                }
              />
            </IconButton>
            &nbsp;&nbsp;
            <IconButton
              size="small"
              onClick={() => {
                this.setState({ currentDelegataire: delegataire }, () => {
                  this.setOpenDialog(true);
                });
              }}
            >
              <FontAwesomeIcon icon={faEdit} color="#141414" />
            </IconButton>
            &nbsp;&nbsp;
            <IconButton
              size="small"
              onClick={() =>
                window.open(
                  window.location.origin +
                    `/delegataires/${delegataire.user_id}`
                )
              }
            >
              <FontAwesomeIcon icon={faUserLock} color="#141414" />
            </IconButton>
          </>
        ),
      };
    });
  }

  getPackageName(id) {
    const packages = this.state.packagesList;
    const res = packages.find((pck) => pck.value === Number(id));
    if (res) return res.label;
    return "";
  }

  populateRows(isSelected, handleClick, visibleRows) {
    return visibleRows.map((row, index) => {
      const isItemSelected = isSelected(row.id);
      const labelId = `enhanced-table-checkbox-${index}`;

      return (
        <StyledTableRow
          key={row.id}
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          selected={isItemSelected}
          // sx={{ cursor: "pointer" }}
          className="delegataire-table-head"
        >
          <StyledTableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              onClick={(event) => handleClick(event, row.id)}
              inputProps={{
                "aria-labelledby": labelId,
                style: { width: "0px" },
              }}
              sx={{
                color: "#626262",
                "&.Mui-checked": {
                  color: "#d81b60",
                },
              }}
            />
          </StyledTableCell>
          <StyledTableCell align="left">{row.name}</StyledTableCell>
          <StyledTableCell align="left" className="tab-cell">
            {row.categories.map((ctg, i) => (
              <div
                key={`tab-categories-key-${i}${index}`}
                className="cell-category"
              >
                {ctg}
              </div>
            ))}
          </StyledTableCell>
          <StyledTableCell align="left" className="tab-cell">
            {row.delegataireDateLimit}
          </StyledTableCell>
          <StyledTableCell align="left" className="tab-cell">
            {row.delegataireGuestsDateLimit}
          </StyledTableCell>
          <StyledTableCell align="left" className="tab-cell" component="">
            {row.packages.map((name, i) => (
              <div key={`pck-key-${i}`}>{name}</div>
            ))}
          </StyledTableCell>
          <StyledTableCell align="center" className="tab-cell">
            {row.componentsQuota.length > 0 ? row.componentsQuota : "-"}
          </StyledTableCell>
          <StyledTableCell align="left" className="tab-cell">
            <span
              className={
                row.componentsQuotaStatus === "Vide"
                  ? "quota-state quota-state-empty rounded font-weight-bold py-2 px-3"
                  : row.componentsQuotaStatus === "Complet"
                  ? "quota-state quota-state-full rounded font-weight-bold text-white py-2 px-3"
                  : row.componentsQuotaStatus === "Partiel"
                  ? "quota-state quota-state-partial rounded font-weight-bold text-white py-2 px-3"
                  : ""
              }
            >
              {row.componentsQuotaStatus}
            </span>
          </StyledTableCell>
          <StyledTableCell align="center" className="tab-cell">
            {row.actions}
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }
  async handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  transformIdToUserId(listId) {
    let listeUserId = [];
    this.state.delegatairesList.forEach((delegataire) => {
      if (listId.includes(delegataire.id)) {
        listeUserId.push(delegataire.user_id);
      }
    });
    return listeUserId;
  }

  setFilteredDelegataires(newDelegataires) {
    this.setState({ delegataireFiltered: newDelegataires });
  }

  render() {
    const {
      usersOptions,
      isLoading,
      delegatairesList,
      packagesList,
      componentsList,
      totalQuotasDelegataire,
      participateGuestDelegataire,
      delegataireChecked,
      delegataireFiltered,
      openDialog,
      currentDelegataire,
      categoriesOptions,
      maxDelegataire,
      action,
      templatesOptions,
      event,
    } = this.state;
    if (isLoading) {
      return <Loader />;
    }

    const registerEndDate = {
      key: "registerEndDate",
      date_limit: event.data.registerEndDate,
    };

    const delegataireDateLimitOptions = event.data.delegataireLimitDate
      ? event.data.delegataireLimitDate.map((delegataireDate) => ({
          value: JSON.stringify(delegataireDate),
          label: new Date(delegataireDate.date_limit).toLocaleDateString(
            "fr-FR",
            options
          ),
        }))
      : [
          {
            value: "",
            label:
              "Configurer une date limite pour les délégataires dans la configuration de l'évènement",
          },
        ];

    delegataireDateLimitOptions.unshift({
      value: JSON.stringify(registerEndDate),
      label: new Date(registerEndDate.date_limit).toLocaleDateString(
        "fr-FR",
        options
      ),
    });

    return (
      <div className="">
        <div className="main-content px-5" style={{paddingBottom:'300px'}}>
          <div className="row">
            <div className={"col-md-4"}>
              <h3>Quota des délégataires</h3>
            </div>
            <div className={"col-md-5"}>
              <div className="mb-4 row container_number_guests_participant">
                <div
                  className={"white col-lg-4 metric-panel-guest"}
                  id="event_invites_total"
                >
                  <div className={"metric-panel-title"}>
                    Nbre de places délégataires
                  </div>
                  <div className={"metric-panel-value mt-"}>
                    {totalQuotasDelegataire}
                  </div>
                </div>
                <div
                  className={"white col-lg-4 metric-panel-participant"}
                  id="event_participants_total"
                >
                  <div className={"metric-panel-title"}>
                    Nbre participants délégataires
                  </div>
                  <div className={"metric-panel-value"}>
                    {participateGuestDelegataire}
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-md  text-end"}>
              <CustomMuiButton
                type="button"
                text="Ajouter un quota"
                onClick={() => {
                  this.setState({ currentDelegataire: {} }, () => {
                    this.setOpenDialog(true);
                  });
                }}
                minHeight={60}
                textColor="#ffffff"
                buttonBgColor="#d81b60"
                buttonHoverBgColor="#AF1242"
                buttonHoverBorderColor="#0062cc"
                buttonActiveBgColor="#d81b60"
              />
            </div>
          </div>
          <br />

          {delegataireChecked.length > 0 ? (
            <div className="row mr-0 ml-0 actions-container actions-container-fixed">
              <div className="col-md-12 d-flex justify-content-between align-items-center">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      {delegataireChecked.length + "\n délégataire"}
                      {delegataireChecked.length > 1 ? "s \n" : "\n"}
                      sélectionné
                      {delegataireChecked.length > 1 ? "s" : "\n"}
                    </label>
                    <select
                      onChange={this.handleChange}
                      name="action"
                      className="col-sm-12 browser-default custom-select"
                      value={action}
                      required
                    >
                      <option value="" disabled>
                        Sélectionner une action groupée
                      </option>
                      <option
                        value="email"
                        disabled={
                          delegataireChecked.length >= maxDelegataire
                            ? true
                            : false
                        }
                      >
                        Envoyer un email{" "}
                        {delegataireChecked.length >= maxDelegataire
                          ? `(max ${maxDelegataire} invités)`
                          : ""}
                      </option>
                    </select>
                  </div>
                </div>
                {action === "email" ? (
                  <TransacEmailContainer
                    templatesOptions={templatesOptions}
                    guest_ids={null}
                    user_ids={this.transformIdToUserId(delegataireChecked)}
                    eventId={this.props.event.id}
                    modal={true}
                  />
                ) : null}
                <div className="align-self-start">
                  <FontAwesomeIcon
                    title={"Fermer"}
                    icon={faTimes}
                    onClick={() => {
                      if (
                        this.checkAllRef.current &&
                        this.checkAllRef.current.checked
                      )
                        this.checkAllRef.current.checked = false;

                      this.setState({
                        delegataireChecked: [],
                        packageId: "",
                        action: "",
                      });
                    }}
                    color="white"
                  />
                </div>
              </div>
            </div>
          ) : null}
          <DelegataireTableFilter
            dateLimitOptions={delegataireDateLimitOptions}
            components={componentsList}
            categoriesOptions={categoriesOptions}
            delegataires={delegatairesList}
            setFilteredDelegataires={this.setFilteredDelegataires}
            getComponentsQuotaData={this.getComponentsQuotaData}
          />
          <CustomTable
            data={delegataireFiltered}
            createTableRows={this.createDelegataireRow}
            selected={delegataireChecked}
            setSelected={this.setDelegataireChecked}
            populateRows={this.populateRows}
            headCells={headCells}
            headCellesNoWrap={true}
            activateCheckbox={true}
          />
          {openDialog && (
            <DelegatairesEditor
              packagesList={packagesList}
              componentsList={componentsList}
              eventData={event.data}
              usersOptions={usersOptions}
              delegataireDateLimitOptions={delegataireDateLimitOptions}
              delegatairesList={delegatairesList}
              delegataire={currentDelegataire}
              loadData={this.loadData}
              openDialog={openDialog}
              setOpenDialog={this.setOpenDialog}
              getComponentsQuotaData={this.getComponentsQuotaData}
              allComponentsNbrGuests={this.allComponentsNbrGuests}
              {...this.props}
            />
          )}
        </div>
      </div>
    );
  }
}

export default DelegatairesContainer;
