import React from "react";
import { Modal, ModalBody, ModalFooter } from "mdbreact";
import { Link } from "react-router-dom";

class Duplicate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: true
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  render() {
    const { contact } = this.props;

    return (
      <div>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>
            Le contact{" "}
            <strong>
              {contact.firstname} {contact.lastname}
            </strong>{" "}
            avec l'adresse email <strong>{contact.mail}</strong> existe déjà.{" "}
            <Link
              target="_blank"
              className="pink-text"
              to={"/admin/contacts/edit/" + contact.id}
            >
              Voir la fiche du contact
            </Link>
          </ModalBody>
          <ModalFooter>
            <button
              onClick={this.toggle}
              className="btn pink darken-1 btn-lg float-right "
            >
              Fermer
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Duplicate;
