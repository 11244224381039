import React from "react";
import { ChromePicker } from "react-color";
import ApiService from "../../../services/ApiService";
import FileUpload from "../../../common/FileUpload";
import { Image, CloudinaryContext } from "cloudinary-react";
import { Button } from "mdbreact";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader";

class TagForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.removeIcon = this.removeIcon.bind(this);
    this.colorByDefault = this.colorByDefault.bind(this);

    this.state = {
      tag: {
        names: "",
        color: "#CDCDCD",
        icon_url: null,
      },
      displayColorPicker: false,
      isLoading: false,
    };
  }

  async componentDidMount() {
    const self = this;
    if (this.props.match.params.id) {
      self.setState({ ...self.state, isLoading: true });
      await ApiService.request(
        this.state,
        "component_tag/" + this.props.match.params.id,
        "get"
      ).then(function (data) {
        self.setState({
          isLoading: false,
          tag: { ...data },
        });
      });
    }
  }

  handleChange(event) {
    this.setState({
      tag: { ...this.state.tag, [event.target.name]: event.target.value },
    });
  }

  removeIcon() {
    this.setState({
      tag: { ...this.state.tag, icon_url: null },
    });
  }

  onFileUpload(id) {
    this.setState({
      tag: { ...this.state.tag, icon_url: id },
    });
  }

  handleClick = (e) => {
    e.preventDefault();
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  colorByDefault = (e) => {
    e.preventDefault();
    this.setState({
      tag: { ...this.state.tag, color: "#CDCDCD", displayColorPicker: false },
    });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleSubmit(e) {
    e.preventDefault();
    let method = this.state.tag.id ? "put" : "post";
    let id = this.state.tag.id ? this.state.tag.id : "";
    const self = this;

    ApiService.request(
      { ...self.state.tag },
      "component_tag/" + id,
      method
    ).then(function () {
      toast.success("Thématique sauvegardé", {
        autoClose: 3000,
      });
      self.props.history.push("/admin/thematiques");
    });

    e.preventDefault();
  }

  selectColor = (color, event) => {
    this.setState({
      tag: { ...this.state.tag, color: color.hex },
    });
  };

  render() {
    const { tag, displayColorPicker } = this.state;
    if (this.state.isLoading) {
      return <Loader />;
    }

    const popover = {
      position: "absolute",
      zIndex: "2",
    };
    const cover = {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    };

    return (
      <div className="row mt-5">
        <div className="col-md-8 offset-md-1 main-content mx-auto">
          <form onSubmit={this.handleSubmit} className="form">
            <h4>Informations générales</h4>
            <div className="form-row">
              <h4 className="mb-3 font-weight-bold">Informations générales</h4>
              <div className="form-group row align-items-start col-md-12">
                <div className="form-group pr-0 col-md-4">
                  <label>Nom</label>
                  <input
                    type="text"
                    name="names"
                    className="form-control py-4 input-lg mb-3"
                    value={tag.names}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group col-md-3 pl-4 pr-0 my-auto">
                  <button
                    className="btn btn-block white-text font-weight-bold "
                    style={{
                      backgroundColor: tag.color,
                    }}
                    onClick={this.handleClick}
                  >
                    Couleur
                  </button>

                  {displayColorPicker ? (
                    <div style={popover}>
                      <div style={cover} onClick={this.handleClose} />
                      <ChromePicker
                        color={tag.color}
                        onChangeComplete={this.selectColor}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-md-3 pl-4 pr-0 my-auto">
                  <button
                    className="btn pink btn-block white-text font-weight-bold px-3"
                    onClick={this.colorByDefault}
                  >
                    Retirer la couleur
                  </button>
                </div>
              </div>
            </div>
            <div className="form-row p-0 pb-4">
              <div className="form-group d-flex flex-wrap col-md-12">
                <label className="col-md-12">Pictogramme</label>
                {tag.icon_url ? (
                  <div className="col-md-4">
                    <CloudinaryContext cloudName="kanguroo-event">
                      <Image
                        className="img-thumbnail pr-1"
                        publicId={tag.icon_url}
                      />
                    </CloudinaryContext>
                  </div>
                ) : null}
                <div className="col-md-3">
                  <FileUpload
                    buttonLabel={"Charger un pictogramme"}
                    onFileUpload={this.onFileUpload}
                  />
                  <Button
                    className="btn btn-block my-4 dark-text darken-1 font-weight-bold "
                    color="black"
                    onClick={this.removeIcon}
                  >
                    Supprimer le visuel
                  </Button>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="btn pink my-5 float-right white-text font-weight-bold mr-n1"
            >
              Enregistrer
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default TagForm;
