import React from "react";
import { Switch, Route } from "react-router-dom";
import WorkSpaces from "./_modules/workspaces/WorkSpaces";
import Components from "./_modules/components/Components";
import Entities from "./_modules/entities/Entities";
import Events from "./_modules/events/Events";
import Tags from "./_modules/tags/Tags";
import Users from "./_modules/users/Users";
import ContactsTags from "./_modules/contacts_tags/ContactsTags";
import Contacts from "./_modules/contacts/Contacts";
import Invoices from "./_modules/invoices/Invoices";
import ApiService from "../services/ApiService";
import Header from "./Header";
import LoginForm from "../common/LoginForm";
import Loader from "../common/Loader";
import { checkRole, clearLocalStorage } from "../services/Utils";
import SettingsWorkspace from "./_modules/settings_workspace/SettingsWorkspace";
import RgpdView from "./_modules/rgpd/RgpdView";

export function Main() {
  const [isLogged, setIsLogged] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [contacts, setContacts] = React.useState([]);

  const doLogout = () => {
    clearLocalStorage();
    setIsLogged(false);
  };

  const loadContacts = async (isActif) => {
    setIsLoading(true);
    await ApiService.request(
      {},
      isActif !== "" && isActif !== undefined
        ? "contacts/list/" + isActif
        : "contacts/list/true",
      "get"
    )
      .then(function (contacts) {
        if (contacts.message) {
          clearLocalStorage();
          setIsLogged(false);
          setIsLoading(false);
        } else {
          setContacts(contacts);
          setIsLogged(true);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const checkUser = async () => {
    setIsLoading(true);
    const id = localStorage.getItem("user_id");
    try {
      if (id) {
        await ApiService.request({}, "users/" + id, "get").then(function (
          user
        ) {
          if (user.message) {
            clearLocalStorage();
            setIsLogged(false);
            setIsLoading(false);
          } else {
            localStorage.setItem("user", JSON.stringify(user));
            setIsLogged(true);
            setIsLoading(false);
          }
        });
      } else {
        doLogout();

        setIsLoading(false);
      }
    } catch (e) {
      doLogout();
      console.log(e);
    }
  };

  React.useEffect(() => {
    checkUser();
    if (isLogged && !checkRole("admin")) {
      return null;
    }
  }, []);

  React.useEffect(() => {
    if (isLogged && checkRole("admin")) {
      loadContacts(true);
    }
  }, [isLogged]);

  if (isLoading) {
    return <Loader />;
  }

  if (isLogged && !checkRole("admin")) {
    return null;
  }

  return (
    <div>
      {isLogged ? (
        <div>
          <Header doLogout={doLogout} />
          <Switch>
            <Route path="/admin/workspaces" component={WorkSpaces} />
            <Route path="/admin/components" component={Components} />
            <Route path="/admin/thematiques" component={Tags} />
            <Route path="/admin/tags_contacts" component={ContactsTags} />
            <Route
              path="/admin/events"
              render={() => (
                <Events updateContacts={setContacts} contacts={contacts} />
              )}
            />
            <Route
              path="/admin/contacts"
              render={() => (
                <Contacts updateContacts={setContacts} contacts={contacts} />
              )}
            />
            <Route path="/admin/entities" component={Entities} />
            <Route path="/admin/users" component={Users} />
            <Route path="/admin/invoices" component={Invoices} />
            <Route path="/admin/settings" component={SettingsWorkspace} />
            <Route path="/admin/rgpd" component={RgpdView} />
          </Switch>
        </div>
      ) : (
        <LoginForm doLogin={loadContacts} />
      )}
    </div>
  );
}
