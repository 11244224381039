import React, { Component } from "react";
import { Main } from "./admin/Main";
import AccountMain from "./account/AccountMain";
import DelegatairesMain from "./delegataires/DelegatairesMain";
import CheckinMainNew from "./checkin/new/CheckinMainNew";
import { Switch, Route, Redirect } from "react-router-dom";
import Webpage from "./common/WebPage";
import Passport from "./common/Passport";
import Invoice from "./common/Invoice";
import LostPassword from "./common/LostPassword";
import ResetPassword from "./common/ResetPassword";
import DownloadPasseportZip from "./common/DownloadPasseportZip";
import { checkVersion, clearLocalStorage, guestMode } from "./services/Utils";
import { ToastContainer } from "react-toastify";
import PlacementHome from "./placement/PlacementHome";
import $ from "jquery";
import { isIOS, osVersion } from "react-device-detect";
import WebAppMain from "./webApp/WebAppMain";
import UserDataForm from "./admin/_modules/rgpd/UserDataForm";
class App extends Component {
  componentDidMount() {
    this.detectIphoneDevice();

    if (!guestMode() && !checkVersion()) {
      clearLocalStorage();
    }
  }
  detectIphoneDevice() {
    if (isIOS) {
      if (osVersion === "12.5.4" || osVersion === "12.5.5") {
        var appleMetaTag = $("meta[name=apple-mobile-web-app-capable]");
        appleMetaTag.remove();
      }
    }
  }
  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Redirect to="/admin/events" />
          </Route>
          <Route path="/admin/" component={Main} />
          <Route path="/webapp/" component={WebAppMain} />
          <Route
            path="/checkin/"
            render={(props) => <CheckinMainNew {...props} />}
          />
          <Route path="/placement/" component={PlacementHome} />
          <Route path="/rgpd" component={UserDataForm} />
          <Route
            path="/lost-password/"
            render={(props) => <LostPassword {...props} />}
          />
          <Route
            path="/reset-password/:token"
            render={(props) => <ResetPassword {...props} />}
          />

          <Route
            path="/account/"
            render={(props) => <AccountMain {...props} />}
          />
          <Route
            path="/delegataires/"
            render={(props) => <DelegatairesMain {...props} />}
          />

          <Route
            exact
            path="/programme/:eventId/:guestId/:accessToken"
            render={(props) => (
              <Webpage
                isAdmin={false}
                {...props}
                guestId={props.match.params.guestId}
                eventId={props.match.params.eventId}
                documentType={"website"}
              />
            )}
          />
          <Route
            exact
            path="/reponse/:eventId/:guestId/:accessToken/:shortUrl?"
            render={(props) => (
              <Webpage
                {...props}
                isAdmin={false}
                guestId={props.match.params.guestId}
                eventId={props.match.params.eventId}
                documentType={"reponse"}
              />
            )}
          />
          <Route
            exact
            path="/download-passport/:eventId/:guestId/:accessToken/"
            render={(props) => (
              <Passport
                {...props}
                guestId={props.match.params.guestId}
                eventId={props.match.params.eventId}
              />
            )}
          />
          <Route
            exact
            path="/passportzip/:zipId"
            render={(props) => <DownloadPasseportZip {...props} />}
          />
          <Route
            exact
            path="/download-invoice/:eventId/:guestId/:accessToken?/"
            render={(props) => (
              <Invoice
                {...props}
                guestId={props.match.params.guestId}
                eventId={props.match.params.eventId}
              />
            )}
          />
        </Switch>
        <ToastContainer />
      </div>
    );
  }
}

export default App;
