import React from "react";
import { DelegatairesQuotas } from "./DelegatairesQuotas";
import { FormDialog } from "../../components/FormDialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { toast } from "react-toastify";
import { Select } from "../../components/Select";
import ApiService from "../../../../services/ApiService";
import "./Delegataire.css";
import { CustomMuiButton } from "../../components/CustomMuiButton";

function DelegatairesEditor({
  match,
  delegataire: delegataireData = {},
  usersOptions,
  delegataireDateLimitOptions,
  packagesList,
  componentsList,
  eventData,
  delegatairesList,
  loadData,
  openDialog,
  setOpenDialog,
  getComponentsQuotaData,
  allComponentsNbrGuests,
}) {
  const [userId, setUserId] = React.useState(
    delegataireData.user_id ? delegataireData.user_id : null
  );

  const [selectedDelegataireDateLimit, setSelectedDelegataireDateLimit] =
    React.useState(
      delegataireData.selectedDelegataireDateLimit
        ? delegataireData.selectedDelegataireDateLimit
        : {
          key: "registerEndDate",
          date_limit: eventData.registerEndDate,
        }
    );
  const [expirationDate, setExpirationDate] = React.useState(
    delegataireData.expiration_date
      ? delegataireData.expiration_date
      : {
        key: "registerEndDate",
        date_limit: eventData.registerEndDate,
      }
  );

  const [selectedPackages, setSelectedPackages] = React.useState(
    delegataireData.package_ids ? delegataireData.package_ids : []
  );

  const [selectedComponents, setSelectedComponents] = React.useState([]);
  const [deletedComponents, setDeletedComponents] = React.useState([]);

  const handleDialogClose = () => {
    setOpenDialog(false);
    // setSelectedComponents([]);
    setSelectedPackages(
      delegataireData.package_ids ? delegataireData.package_ids : []
    ); //! only update on refresh
    setDeletedComponents([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      id: delegataireData.id || "",
      user_id: userId,
      package_ids: selectedPackages,
      selectedDelegataireDateLimit: JSON.stringify(
        selectedDelegataireDateLimit
      ),
      expiration_date: JSON.stringify(expirationDate),
    };
    saveDelegataire(data);
  };

  // console.log("delegataires", delegataire)
  const registerEndDate = {
    key: "registerEndDate",
    date_limit: eventData.registerEndDate,
  };

  React.useEffect(() => {
    if (!selectedDelegataireDateLimit) {
      setSelectedDelegataireDateLimit(registerEndDate);
    }

    if (!expirationDate) {
      setExpirationDate(registerEndDate);
    }
  }, [selectedDelegataireDateLimit, expirationDate]);

  const deleteComponentQuota = (delRes) => {
    if (deletedComponents.length) {
      try {
        deletedComponents.forEach(async (id) => {
          //TODO: Check if component has guests registered?

          const componentHasGuestsRegistered = cmpQuotaData.components.find(
            (cmp) => cmp.id === id && cmp.numberOfGuests > 0
          );

          const component = componentsList.find((item) => item.id === id);
          if (component) {
            const delegatairesQuotas =
              component.data && component.data.quotas
                ? component.data.quotas.delegataires
                : [];
            let newDelegataireQuotas = delegatairesQuotas.filter(
              (delquota) => delquota.userId !== Number(delRes.user_id)
            );

            if (componentHasGuestsRegistered) {
              toast.warning(
                `Le composant: (${componentHasGuestsRegistered.name}) ne peut pas être supprimé car des invités y sont inscrits`
              );
            } else {
              await ApiService.awaitRequest(
                {
                  ...component,
                  data: {
                    ...component.data,
                    quotas: {
                      ...component.data.quotas,
                      delegataires: newDelegataireQuotas,
                    },
                  },
                },
                "components/" + id,
                "put"
              );
            }
          }
        });
      } catch (error) {
        toast.error("Une erreur s'est produite.");
        throw new Error(error);
      }
    }
  };

  const createComponentQuota = (delRes) => {
    if (selectedComponents.length) {
      try {
        selectedComponents.forEach(async (cmp) => {
          const component = componentsList.find((item) => item.id === cmp.id);

          if (component) {
            const delegatairesQuotas =
              component.data && component.data.quotas
                ? component.data.quotas.delegataires
                : [];

            let newDelegataireQuotas = delegatairesQuotas.filter(
              (delquota) => delquota.userId !== Number(delRes.user_id)
            );
            newDelegataireQuotas = [
              ...newDelegataireQuotas,
              { userId: Number(delRes.user_id), quota: cmp.quota },
            ];

            //! if it is an old component that already exists
            if (!(component.data && component.data.quotas)) {
              component.data.quotas = {
                total: Number(component.data.quota),
              };
            }

            await ApiService.awaitRequest(
              {
                ...component,
                data: {
                  ...component.data,
                  quotas: {
                    ...component.data.quotas,
                    delegataires: newDelegataireQuotas,
                  },
                },
              },
              "components/" + cmp.id,
              "put"
            );
          }
        });
      } catch (error) {
        toast.error("Une erreur s'est produite.");
        throw new Error(error);
      }
    }
  };

  const saveDelegataire = async (data) => {
    try {
      let method = data.id ? "put" : "post";
      console.log("🚀 ~ saveDelegataire ~ method:", method);
      let id = data.id ? data.id : "";

      if (data.package_ids && data.package_ids.length && method !== "put") {
        const doesDelegataireHavePackage = delegatairesList.find(
          (item) =>
            Number(item.user_id) === Number(data.user_id) &&
            data.package_ids.includes(Number(item.package_id))
        );

        if (doesDelegataireHavePackage) {
          toast.warning("Package déjà existant");
          return;
        }
      }

      const delRes = await ApiService.awaitRequest(
        {
          ...data,
          evenement_id: match.params.eventid,
        },
        "delegataires/" + id,
        method
      );

      //TODO: delete components quota

      if (delRes) {
        deleteComponentQuota(delRes);
        createComponentQuota(delRes);
        toast.success("Délégataire sauvegardé");
        loadData();
      }
    } catch (error) {
      console.log(error);
      toast.error("Erreur lors de l'enregistrement");
    } finally {
      handleDialogClose();
    }
  };

  const isOptionAlreadySelected = (option) => {
    const isDisabled = delegatairesList.some(
      (delegataire) => delegataire.user_id === option.value
    );
    return isDisabled;
  };

  const cmpQuotaData = React.useMemo(
    () => getComponentsQuotaData(delegataireData.user_id),
    [delegataireData]
  );

  const allCmpsNbrGuests = React.useMemo(
    () => allComponentsNbrGuests(delegataireData.user_id),
    [delegataireData]
  );

  return (
    <>
      <FormDialog
        open={openDialog}
        handleClose={handleDialogClose}
        onSubmit={handleSubmit}
        className="delegataire_quota_form"
      >
        <DialogTitle>AJOUTER UN DÉLÉGATAIRE</DialogTitle>
        <DialogContent
          sx={{
            minWidth: "870px",
            minHeight: "400px",
          }}
        >
          <div className="delegataire-editor-form">
            <div className="">
              <Select
                selectedValue={userId || ""}
                handleSelectedValue={setUserId}
                options={usersOptions}
                id="delegataire-select-users"
                displayArrowIcon={true}
                placeholder="Sélectionner un délégataire"
                isOptionAlreadySelected={isOptionAlreadySelected}
                required={true}
              />
            </div>

            <div className="delegataire-dates-container">
              <div className="delegataire-dates">
                <h4 className="bold-titles">
                  Date limite de réponse des invités du délégataire
                </h4>
                <Select
                  selectedValue={
                    selectedDelegataireDateLimit
                      ? JSON.stringify(selectedDelegataireDateLimit)
                      : JSON.stringify(registerEndDate)
                  }
                  handleSelectedValue={(value) =>
                    setSelectedDelegataireDateLimit(JSON.parse(value))
                  }
                  options={delegataireDateLimitOptions}
                  id="delegataire-select-response-date"
                  placeholder="Date limite de réponse aux invités"
                  required={true}
                  optionsValuesToLowerCase={false}
                  firstOptionItalic={false}
                />
              </div>
              <div className="delegataire-dates">
                <h4 className="bold-titles">
                  Date limite de réponse du délégataire
                </h4>
                <Select
                  selectedValue={
                    expirationDate
                      ? JSON.stringify(expirationDate)
                      : JSON.stringify(registerEndDate)
                  }
                  handleSelectedValue={(value) =>
                    setExpirationDate(JSON.parse(value))
                  }
                  options={delegataireDateLimitOptions}
                  id="delegataire-select-limit-date"
                  placeholder="Sélectionner une date limite à la réponse pour ce délégataire"
                  required={true}
                  optionsValuesToLowerCase={false}
                  firstOptionItalic={false}
                />
              </div>
            </div>

            <DelegatairesQuotas
              packagesList={packagesList}
              components={componentsList}
              selectedPackages={selectedPackages}
              selectedComponents={selectedComponents}
              setSelectedPackages={setSelectedPackages}
              setSelectedComponents={setSelectedComponents}
              delegataireUserId={userId}
              setDeletedComponents={setDeletedComponents}
              componentsQuotaData={cmpQuotaData}
              allCmpsNbrGuests={allCmpsNbrGuests}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <CustomMuiButton
            type="button"
            onClick={handleDialogClose}
            text="Fermer"
            minHeight={40}
            textColor="#ffffff"
            buttonBgColor="#A2A2A2"
            buttonHoverBgColor="#7A7A7A"
            buttonHoverBorderColor="#0062cc"
            buttonActiveBgColor="#A2A2A2"
          />
          <CustomMuiButton
            disable={selectedComponents.length === 0 || selectedPackages.length === 0}
            type="submit"
            text="Valider"
            minHeight={40}
            textColor="#ffffff"
            buttonBgColor="#d81b60"
            buttonHoverBgColor="#AF1242"
            buttonHoverBorderColor="#0062cc"
            buttonActiveBgColor="#d81b60"
          />
        </DialogActions>
      </FormDialog>
    </>
  );
}

export default DelegatairesEditor;
