import React from "react";
import { Switch, Route, matchPath, Redirect } from "react-router-dom";
import ApiService from "../services/ApiService";
import Header from "./Header";
import DelegatairesEvents from "./_modules/DelegatairesEvents";
import DelegatairesContactsList from "./_modules/DelegatairesContactsList";
import { toast } from "react-toastify";
import DelegatairesContactForm from "./_modules/DelegatairesContactForm";
import LoginForm from "../common/LoginForm";
import { checkRole, clearLocalStorage } from "../services/Utils";
import Loader from "../common/Loader";

class DelegatairesMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: ApiService.checkToken(),
      isLoading: false,
      guest: null,
      contact: null,
      contacts: [],
      eventId: null,
      event: null,
      match: matchPath(window.location.pathname, {
        path: "/delegataires/:userId",
        strict: false,
      }),
      matchEvent: matchPath(window.location.pathname, {
        path: "/delegataires/:userId/events",
        strict: false,
      }),
      matchContactList: matchPath(window.location.pathname, {
        path: "/delegataires/:userId/contacts",
        strict: false,
      }),
      matchContact: matchPath(window.location.pathname, {
        path: "/delegataires/:userId/contacts/edit/:id",
        strict: false,
      }),
      userId: "",
      user: "",
      tagsList: [],
    };
    this.updateContacts = this.updateContacts.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.refreshContacts = this.refreshContacts.bind(this);
    this.loadUserData = this.loadUserData.bind(this);
    this.doLogout = this.doLogout.bind(this);
    this._isMounted = false;
  }

  async componentDidMount() {
    let userId = null;
    if (
      this.state.match ||
      this.state.matchEvent ||
      this.state.matchContactList ||
      localStorage.getItem("user_id")
    ) {
      if (this.state.match) {
        userId = this.state.match.params.userId;
      } else if (this.state.matchEvent) {
        userId = this.state.matchEvent.params.userId;
      } else if (this.state.matchContactList) {
        userId = this.state.matchContactList.params.userId;
      } else if (localStorage.getItem("user_id")) {
        userId = localStorage.getItem("user_id");
      }
    }
    //console.log(userId);

    this.setState({ isLoading: true });
    this.loadUserData(userId);
    this.loadData(userId);
  }

  async loadData(userId) {
    this.setState({ isLoading: true });
    const self = this;
    if (this.state.match || this.state.matchContact) {
      await ApiService.request(this.state, "tags", "get").then(function (data) {
        self._isMounted && self.setState({ tagsList: data });
      });

      if (userId) {
        //console.log(userId);
        const contacts = await ApiService.awaitRequest(
          {},
          "contacts/byuser/" + userId,
          "get"
        );
        const user = await ApiService.awaitRequest(
          this.state,
          "users/" + userId,
          "get"
        );
        this.setState({ userId, user, contacts });
      }
    }
    this.setState({ isLoading: false });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async refreshContacts(user, contacts) {
    const { isLogged } = this.state;
    if (isLogged && user && contacts) {
      const tagOptions = this.state.tagsList.map((tag) => ({
        label: tag.tag_name,
        value: tag.id,
      }));
      toast.success("Import des contacts terminé");
      this.setState(
        {
          user,
          contacts,
          tagOptions,
        },
        () => this.updateUser(user)
      );
    }
  }

  async updateUser(user) {
    if (user) {
      const updateUser = await ApiService.awaitRequest(
        {
          detect_import: false,
          import_status: "default",
        },
        "users/forimport/" + user.id,
        "put"
      );

      this.setState({
        user: updateUser,
      });
    }
  }

  updateContacts(contacts) {
    this.setState({ contacts });
  }

  loadUserData(userId) {
    this.setState({ isLoading: true });
    this._isMounted = true;
    const match = matchPath(window.location.pathname, {
      path: "/delegataires",
      strict: false,
    });
    //console.log(userId);
    if (match && match.isExact && this._isMounted) {
      if (userId) {
        this.setState({ userId: userId });
        window.location.pathname =
          window.location.pathname + "/" + userId + "/events";
        this.loadData(userId);
      }
    }
    this.setState({ isLoading: false });
  }
  doLogout() {
    this.setState({ isLogged: false, userId: null });
    window.location.href = "/delegataires";
    clearLocalStorage();
  }
  render() {
    const { userId, user, contacts, isLoading, isLogged, tagsList } =
      this.state;
    if (isLoading) {
      return <Loader />;
    }

    if (userId) {
      if (!checkRole("delegataire") && !userId) {
        return null;
      }

      return (
        <div>
          <Header
            doLogout={this.doLogout}
            match={this.state.match}
            userId={userId}
            userLogo={user ? user.logo : null}
          />
          <Switch>
            <Route exact path={"/delegataires/" + userId}>
              <Redirect to={"/delegataires/" + userId + "/events/"} />
            </Route>
            <Route
              exact
              path={"/delegataires/" + userId + "/events/"}
              render={(props) => (
                <DelegatairesEvents
                  {...props}
                  userId={userId}
                  contacts={contacts}
                  isLogged={isLogged}
                  tagsList={tagsList}
                />
              )}
            />
            <Route
              exact
              path={"/delegataires/" + userId + "/contacts/"}
              render={(props) => (
                <DelegatairesContactsList
                  {...props}
                  userId={userId}
                  contacts={contacts}
                  user={user}
                  refreshContacts={this.refreshContacts}
                  updateContacts={this.updateContacts}
                  tagsList={tagsList}
                />
              )}
            />
            <Route
              path={"/delegataires/" + userId + "/contacts/edit/:id?"}
              render={(props) => (
                <DelegatairesContactForm
                  {...props}
                  updateContacts={this.updateContacts}
                  contacts={contacts}
                />
              )}
            />
          </Switch>
        </div>
      );
    } else {
      return <LoginForm doLogin={this.loadUserData} />;
    }
  }
}

export default DelegatairesMain;
