import React from "react";
import ApiService from "../services/ApiService";
import Loader from "./Loader";
import { toast } from "react-toastify";
import { MDBBtn } from "mdbreact";

const DownloadPasseportZip = (props) => {
  const isLogged = React.useState(ApiService.checkToken());
  const [isLoading, setIsLoading] = React.useState(true);

  if (isLogged) {
    const getZipArchive = async () => {
      try {
        const name = await ApiService.awaitRequest(
          {},
          `passeports_zip/${
            props.match.params.zipId
          }/generate-name/user/${localStorage.getItem("user_id")}`,
          "get",
          "",
          "",
          ""
        );

        const zip = await ApiService.awaitRequest(
          {},
          `passeports_zip/${
            props.match.params.zipId
          }/download/user/${localStorage.getItem("user_id")}`,
          "get",
          "",
          "",
          "blob"
        );

        const url = window.URL.createObjectURL(
          new Blob([zip], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name.passportName}.zip`);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);

        setIsLoading(false);
        toast.success("Archive téléchargée");
      } catch (error) {
        console.log("error");
      }
    };

    if (isLoading) {
      getZipArchive();
    }

    return (
      <div>
        {isLoading ? (
          <div>
            <Loader />
            <p className="text-center font-weight-bold mt-2">
              Téléchargement en cours...
            </p>
          </div>
        ) : (
          <div className="d-flex flex-column align-items-center">
            <p className="text-center font-weight-bold mt-2">
              Document téléchargé
            </p>
            <MDBBtn
              onClick={() => setIsLoading(true)}
              rounded
              className="z-depth-1a pink darken-1"
            >
              Re-télécharger le document
            </MDBBtn>
          </div>
        )}
      </div>
    );
  } else {
    window.location.replace("/");
  }
};

export default DownloadPasseportZip;
