import React from "react";
import ApiService from "../services/ApiService";
import Loader from "./Loader";
import { toast } from "react-toastify";
import { MDBBtn } from "mdbreact";

const Passeport = (props) => {

  const [isLoading, setIsLoading] = React.useState(true);


  if (props.match && props.match.params.accessToken) {
    localStorage.setItem(
      "invitation_token",
      props.match.params.accessToken
    );
  }

  const downLoadPassport = async (guestId, eventId) => {
    try {

      const event = await ApiService.request(
        {},
        "evenements/" + eventId,
        "get"
      )

      const guest = await ApiService.request(
        {},
        "guests/" + guestId,
        "get"
      )


      const zip = await ApiService.awaitRequest(
        {},
        `guest/${guestId}/passeport/byevenement/${eventId}`,
        "get",
        "",
        "",
        "blob"
      );

      const url = window.URL.createObjectURL(
        new Blob([zip], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${event.data.passportName}_${guest.lastname}_${guest.firstname}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      toast.success("Document téléchargé");
    } catch (error) {
      toast.error("Erreur lors du téléchargement");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    downLoadPassport(props.guestId, props.eventId);
  }

  return (
    <div>
      {isLoading ? (
        <div>
          <Loader />
          <p className="text-center font-weight-bold mt-2">
            Téléchargement en cours...
          </p>
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center">
          <p className="text-center font-weight-bold mt-2">
            Document téléchargé
          </p>
          <MDBBtn
            onClick={() => setIsLoading(true)}
            rounded
            className="z-depth-1a pink darken-1"
          >
            Re-télécharger le document
          </MDBBtn>
        </div>
      )}
    </div>
  );
};

export default Passeport;