import React from "react";
import { Button } from "mdbreact";
import ReactExport from "react-export-excel";

class ExportGuests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: this.props.contacts.length > 0 ? false : true,
    };
  }

  async createRow(guest, form) {
    try {
      const { contacts, reponses, event, delegatairesList, guests } =
        this.props;

      const optionsDate = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };

      // Récupérer id de l'invitant
      const relatedGuestId = guest.invited_by ? guest.invited_by.id : null;

      // Récupérer les données de l'invitant
      const relatedGuest = relatedGuestId
        ? guests.find((guest) => guest.id === relatedGuestId)
        : null;

      // Récupérer le mail de l'invitant
      const relatedGuestEmail = relatedGuest ? relatedGuest.email : "";

      // Récupérer les données de l'invitant dans la liste des contacts
      const relatedGuestInContact = relatedGuest
        ? contacts.find((contact) => contact.id === relatedGuest.contact_id)
        : null;

      // Récupérer le contact associé à l'invitant
      const relatedGuestContact = relatedGuestInContact
        ? contacts.find(
            (contact) => contact.id === relatedGuestInContact.related_to
          )
        : null;

      // Récupérer le mail du contact associé à l'invitant
      const relatedGuestContactEmail = relatedGuestContact
        ? relatedGuestContact.mail
          ? relatedGuestContact.mail
          : ""
        : "";

      const contact = contacts.find(
        (contact) => contact.id === guest.contact_id
      );

      const related = contact
        ? contacts.find((c) => c.id === contact.related_to)
        : "";

      const relatedEmail = related ? (related.mail ? related.mail : "") : "";
      const guestReponse = reponses.find(
        (reponse) => reponse.guest_id === Number(guest.id)
      );

      let switched = false;

      const begin = new Date("2019-05-13T00:00:01").getTime();
      const end = new Date("2019-05-17T23:59:59").getTime();

      if (guestReponse && guestReponse.created_at && guest.invited_by) {
        const temp = guestReponse.created_at.split(" ");
        const registrationDate = new Date(
          temp[0] + "T" + temp[1] + "Z"
        ).getTime();

        if (registrationDate > begin && registrationDate < end) {
          switched = true;
        }
      }

      const guestType = event.data.guestsTypes.find((type) =>
        type.name.find((language) => language.value === guest.type)
      );

      const mainType = guestType
        ? guestType.type === "internal"
          ? "Interne"
          : "Externe"
        : null;

      const subType = guestType
        ? guestType.name.find((language) => language.value === guest.type).value
        : null;

      const guestPackage = this.props.packagesList.find(
        (item) => Number(guest.package_id) === item.id
      );
      const delegataire = guest.user_id
        ? delegatairesList.find(
            (delegataire) => delegataire.user_id === guest.user_id
          )
        : null;

      const serverDateCreated =
        guestReponse && guestReponse.created_at
          ? new Date(guestReponse.created_at)
          : null;

      const serverDateUpdated =
        guestReponse && guestReponse.updated_at
          ? new Date(guestReponse.updated_at)
          : null;

      const guestData = [
        guest.gender,
        switched ? guest.lastname : guest.firstname,
        switched ? guest.firstname : guest.lastname,
        guest.address,
        guest.address,
        guest.zip_code,
        guest.city,
        guest.country,
        contact ? contact.external_id : null,
        guest.email,
        contact ? contact.mail_second : null,
        contact ? contact.phone : null,
        contact ? contact.phone_second : null,
        contact ? contact.secretary_name : null,
        contact ? contact.languages : null,
        contact ? (contact.company_id ? contact.company.name : null) : null,
        contact ? (contact.company_id ? contact.company.address : null) : null,
        contact ? (contact.company_id ? contact.company.zip_code : null) : null,
        contact ? (contact.company_id ? contact.company.city : null) : null,
        contact ? (contact.company_id ? contact.company.country : null) : null,
        contact ? contact.company_function : null,
        contact ? contact.service : null,
        contact ? (contact.active ? "1" : "0") : null,
        contact ? contact.expiration_date : null,
        contact ? contact.more_details : null,
        contact ? (contact.category ? contact.category.name : null) : null,
        contact
          ? contact.tags
              .map((tag) => tag.tag_name + "; ")
              .join()
              .replaceAll(",", "")
          : null,

        relatedEmail
          ? relatedEmail
          : relatedGuestContactEmail
          ? relatedGuestContactEmail
          : relatedGuestEmail,
        guest.invited_by ? guest.invited_by.email : null,

        guest.invited_by
          ? guest.invited_by.lastname + " " + guest.invited_by.firstname
          : null,

        delegataire
          ? "délégataire " +
            delegataire.user.lastname +
            " " +
            delegataire.user.firstname
          : null,
        guest.invited_by ? mainType : null,
        guest.invited_by ? subType : null,

        guest.created_on_checkin ? "" : guestPackage ? guestPackage.name : null,
        guestPackage
          ? guestPackage.checkin_group_message
            ? guestPackage.checkin_group_message
            : ""
          : "",

        guest.participate
          ? guest.participate === true
            ? "Oui"
            : "Non"
          : "Non",
        guest.created_on_checkin ? "Oui" : "Non",
        serverDateCreated
          ? serverDateCreated.toLocaleDateString("fr-FR", optionsDate)
          : "",
        serverDateUpdated
          ? serverDateUpdated.toLocaleDateString("fr-FR", optionsDate)
          : "",
        guest.check_in_message ? guest.check_in_message : null,
      ];

      form.layout.itemsMain.forEach((component) => {
        const eventComponent = this.props.components.find(
          (eventComponent) => eventComponent.id === component.id
        );
        if (eventComponent) {
          const reponses = guestReponse
            ? guestReponse.responses && guestReponse.responses.length > 0
              ? guestReponse.responses.filter(
                  (response) => response.id === component.id
                )
              : null
            : null;

          if (
            (eventComponent.data.reponse.htmlType === "radio" ||
              eventComponent.data.reponse.htmlType === "checkbox") &&
            eventComponent.data.reponse.displayType !== "agenda"
          ) {
            const options = eventComponent.data.reponse.options[0].value;

            if (options) {
              options.split(";").forEach((option, key) => {
                if (!reponses) {
                  guestData.push("n/c");
                } else {
                  const singleOption = reponses.find(
                    (reponse) => Number(reponse.key) === key
                  );

                  if (singleOption) {
                    guestData.push("1");
                  } else {
                    guestData.push("0");
                  }
                }
              });
            }
          } else {
            if (eventComponent.data.reponse.htmlType === "text") {
              if (reponses && reponses.length > 0) {
                guestData.push(reponses[0].label);
              } else {
                guestData.push("n/c");
              }
            } else {
              guestData.push(reponses && reponses.length > 0 ? "1" : "n/c");
            }
          }
        }
      });

      event.checkin_points.forEach((eventCheckinPoint) => {
        const guestCheckinPoint = guest.checkin_points.find(
          (checkinPoint) => checkinPoint.id === eventCheckinPoint.id
        );
        if (guestCheckinPoint) {
          guestData.push(guestCheckinPoint.nbrOfCheck > 0 ? "1" : "0");
        } else {
          guestData.push("0");
        }
      });

      return guestData;
    } catch (e) {
      console.log(e);
      return;
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.contacts !== prevProps.contacts &&
      this.props.contacts.length !== 0
    ) {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

    const { form, event } = this.props;
    const columns = [
      "Civilité",
      "Prénom",
      "Nom",
      "Adresse (numéro et rue)",
      "Adresse (Complément)",
      "Code postal",
      "Ville",
      "Pays",
      "ID externe",
      "Email",
      "Email secondaire",
      "Téléphone 1",
      "Téléphone 2",
      "Nom et prénom secrétaire",
      "Langue",
      "Société (Raison Sociale)",
      "Adress société (numéro et rue)",
      "Code postal société",
      "Ville société",
      "Pays société",
      "Fonction",
      "Service",
      "Actif",
      "Date d'expiration",
      "Notes",
      "Catégorie",
      "Tags",
      "related_to",
      "Accompagnant de (email)",
      "Accompagnant de",
      "Délégataire associé",
      "Type d'accompagnant",
      "Sous type d'accompagnant",
      "Package",
      "message checkin package",
      "Participe",
      "Crée au checkin",
      "Répondu le",
      "Modifié le",
      "Message",
    ];

    const data = [];
    const subColumns = [];

    form.layout.itemsMain.forEach((component) => {
      const eventComponent = this.props.components.find(
        (eventComponent) => eventComponent.id === component.id
      );

      if (eventComponent) {
        if (
          (eventComponent.data.reponse.htmlType === "radio" ||
            eventComponent.data.reponse.htmlType === "checkbox") &&
          eventComponent.data.reponse.displayType !== "agenda"
        ) {
          const options = eventComponent.data.reponse.options.find(
            (option) => option.code === "FR"
          ).value;
          if (options) {
            options.split(";").forEach((option) => {
              columns.push(eventComponent.data.name);
              subColumns.push(option);
            });
          }
        } else {
          columns.push(eventComponent.data.name);
          subColumns.push("");
        }
      }
    });

    if (event) {
      event.checkin_points.forEach((eventCheckinPoint) => {
        columns.push(eventCheckinPoint.name);
        subColumns.push("");
      });
    }

    // Si vous avez ajouter une colonne ajouter un ""  tableau sur ce tableau en bas pour que les données de subColumns reste à leurs places
    data.push(
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ].concat(subColumns)
    );

    const guests = this.props.guests.filter((guest) => !guest.invited_by);
    const followers = this.props.guests.filter((guest) => guest.invited_by);
    const listGuest = [];
    guests.forEach((guest) => {
      listGuest.push(guest);
      const guestFollowers = followers.filter(
        (follower) => follower.invited_by.id === guest.id
      );
      listGuest.push(...guestFollowers);
    });
    listGuest.forEach(async (guest) => {
      data.push(await this.createRow(guest, form));
    });

    const multiDataSet = [
      {
        columns,
        data,
      },
    ];

    const { isLoading } = this.state;

    return (
      <ExcelFile
        element={
          <div className="button-container">
            <Button disabled={isLoading} className="btn grey darken-4 ">
              Exporter les données
            </Button>
          </div>
        }
      >
        <ExcelSheet dataSet={multiDataSet} name="Export global" />
      </ExcelFile>
    );
  }
}

export default ExportGuests;
