import React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

import "./SmsForm.css";

function SmsNumberValidityBox({
  valid,
  toFormat,
  undefinedNumbers,
  inValid,
  formatPhoneNumbers,
  disableButton,
}) {
  return (
    <Paper
      elevation={0}
      sx={{
        bgcolor: "#ffffff",
        padding: "1rem 2rem 1rem 1rem",
        borderRadius: "0.875rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "space-between",
        width: "100%",
        maxWidth: "270px",
      }}
    >
      <p className="number-validity-title">Analyse des numéros: </p>
      <div>
        <p>
          <span className="number-validity-status valid">
            Numéros valides:{"  "}
          </span>{" "}
          <span className="number-validity-status-value">{valid}</span>
        </p>
        <p>
          <span className="number-validity-status toFormat">
            Numéros à formater:{"  "}
          </span>
          <span className="number-validity-status-value">{toFormat}</span>
        </p>
        <p>
          <span className="number-validity-status invalid">
            Numéros invalides:{"  "}
          </span>
          <span className="number-validity-status-value">{inValid}</span>
        </p>
        <p>
          <span className="number-validity-status undefined">
            Invités sans numéro:{"  "}
          </span>
          <span className="number-validity-status-value">
            {undefinedNumbers}
          </span>
        </p>
        {toFormat > 0 && !disableButton && (
          <Button
            variant="contained"
            type="button"
            onClick={formatPhoneNumbers}
            sx={{
              paddingInline: "1rem",
              minHeight: 30,
              width: "fit-content",
              color: "#ffffff",
              backgroundColor: "#800080",
              "&:hover": {
                backgroundColor: "#600060",
                borderColor: "#0062cc",
                boxShadow: "none",
              },
              "&:active": {
                boxShadow: "none",
                backgroundColor: "#800080",
              },
              "&:focus": {
                boxShadow: "none",
              },
            }}
            endIcon={<AutoFixHighIcon />}
          >
            Formater
          </Button>
        )}
      </div>
    </Paper>
  );
}

export default SmsNumberValidityBox;
