import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import ApiService from "../../../../services/ApiService";
import Loader from "../../../../common/Loader";
import { StepsList } from "./StepsList";
import "./SmsForm.css";
import { SmsCampaignStepOne } from "./SmsCampaignStepOne";
import { SmsCampaignStepTwo } from "./SmsCampaignStepTwo";
import { SmsCampaignStepThree } from "./SmsCampaignStepThree";
import { SMS_ACTIONS, SMS_STATES, isObjectEmpty } from "./utils";

const STOP_SMS_TEXT = "\nSTOP SMS";

export const SmsForm = ({
  match,
  contacts,
  evenement,
  components,
  ...props
}) => {
  const coeff = 1000 * 60 * 5;
  const date = new Date();
  const today = new Date(Math.round(date.getTime() / coeff) * coeff);

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [smsData, setSmsData] = useState({});
  const [guests, setGuests] = useState([]);
  const [guestsChecked, setGuestsChecked] = useState([]);
  const [guestsCheckedValid, setGuestsCheckedValid] = useState([]);

  const [campaignTitle, setCampaignTitle] = useState("");
  const [senderName, setSenderName] = useState("");
  const [scheduleDate, setScheduleDate] = useState(today);
  const [draft, setDraft] = useState(false);
  const [stopSMS, setStopSMS] = useState(true);
  const [message, setMessage] = useState("");
  const [segmentsIds, setSegmentsIds] = React.useState([]);
  const [listsIds, setListsIds] = React.useState([]);

  const isSmsCampaignEditable = () => {
    if (!isObjectEmpty(smsData)) {
      if (
        smsData.status === SMS_STATES.SENT ||
        smsData.status === SMS_STATES.SEND_NOW ||
        smsData.status === SMS_STATES.IN_PROGRESS ||
        smsData.status === SMS_STATES.ERROR
      ) {
        return false;
      }
    }
    return true;
  };

  const getStatusSms = () => {
    if (!isObjectEmpty(smsData)) {
      if (
        smsData.status === SMS_STATES.SENT ||
        smsData.status === SMS_STATES.SEND_NOW ||
        smsData.status === SMS_STATES.IN_PROGRESS ||
        smsData.status === SMS_STATES.ERROR
      ) {
        return smsData.status;
      }
    }
    return draft ? SMS_STATES.DRAFT : SMS_STATES.WAITING;
  };

  useEffect(() => {
    loadData();
  }, []);

  const removeStopSmsFromMessage = (msg) => {
    if (msg) {
      const regexPattern = new RegExp(`${STOP_SMS_TEXT}$`, "g");
      return msg.replace(regexPattern, "");
    }
    return "";
  };

  const loadData = async () => {
    setIsLoading(true);
    try {
      await ApiService.awaitRequest(
        {},
        "guests/byevenement/guestsfiltered/" + match.params.eventid,
        "get"
      ).then(function (data) {
        setGuests(data);
      });

      if (match.params.smsid) {
        await ApiService.request({}, "sms/" + match.params.smsid, "get").then(
          function (data) {
            //TODO: update all states

            if (data) {
              setSmsData(data);
              setCampaignTitle(data.title);
              setSenderName(data.sender_name);
              setScheduleDate(new Date(data.schedule_date));
              setDraft(data.status === SMS_STATES.DRAFT);
              setMessage(removeStopSmsFromMessage(data.message));
              setStopSMS(data.stop_sms);
              setGuestsChecked(data.guests);
              setSegmentsIds(data.segments_ids);
              setListsIds(data.guests_lists_ids);
            }
          }
        );
      }
    } catch (error) {
      console.log(error);
      toast.error("Une erreur s'est produite");
    } finally {
      setIsLoading(false);
    }
  };

  const shortcodesOptions = [
    { value: "[[data:firstname]]", label: "Prénom" },
    { value: "[[data:lastname]]", label: "Nom de famille" },
    { value: "[[data.company_name]]", label: "Nom de l'entreprise" },
    { value: "[[data:mail]]", label: "Adresse e-mail" },
    { value: "[[data:gender]]", label: "Genre" },
    { value: "[[data:mobile]]", label: "Numéro de téléphone mobile" },
    { value: "[[data:phone]]", label: "Numéro de téléphone" },
    { value: "[[data:address]]", label: "Adresse" },
    { value: "[[data:address_more]]", label: "Complément d'adresse" },
    { value: "[[data:zip_code]]", label: "Code postal" },
    { value: "[[data:city]]", label: "Ville" },
    { value: "[[data:country]]", label: "Pays" },
    { value: "[[data:package.name]]", label: "Nom du package" },
    { value: "[[data:max_invitation]]", label: "Nombre maximum d'invitations" },
    {
      value: "[[data:event.start_date]]",
      label: "Date de début de l'événement",
    },
    { value: "[[data:event.end_date]]", label: "Date de fin de l'événement" },
    { value: "[[data:event.name]]", label: "Nom de l'événement" },
    { value: "[[data:event.place]]", label: "Lieu de l'événement" },
    { value: "[[data:passport]]", label: "Passeport de l'invité" },
    { value: "[[response:shortyes]]", label: "Short link Yes" },
    { value: "[[response:shortno]]", label: "Short link No" },
  ];

  const submitCampaign = async (action) => {
    try {
      const method = smsData.id ? "put" : "post";
      const id = smsData.id ? smsData.id : "";

      const guest_ids = [...guestsCheckedValid];

      if (guest_ids.length === 0 && action !== SMS_ACTIONS.DRAFT) {
        toast.error("Sélectionner au moins un segment ou une liste");
        return;
      }

      setIsLoading(true);
      const updatedMessage = stopSMS ? message + STOP_SMS_TEXT : message;

      let status = getStatusSms();
      let schedule_date = moment(new Date(scheduleDate)).format();

      switch (action) {
        case SMS_ACTIONS.DRAFT:
          status = SMS_STATES.DRAFT;
          break;
        case SMS_ACTIONS.TESTING:
          // status = SMS_STATES.TESTING;
          break;
        case SMS_ACTIONS.SEND_NOW:
          schedule_date = moment(new Date()).add(4, "minutes").format();
          status = SMS_STATES.SEND_NOW;
          break;
        case SMS_ACTIONS.SCHEDULE:
          status = SMS_STATES.WAITING;
          const timeDiff = moment(scheduleDate).diff(
            moment(new Date()),
            "minutes"
          );

          if (timeDiff < 0) {
            toast.warning(
              "Sélectionnez une heure future pour la programmation.\nImpossible de planifier une campagne dans le passé."
            );
            return;
          } else if (timeDiff <= 5) {
            toast.warning(
              "Choisissez une heure de programmation au moins 5 minutes après l'heure actuelle."
            );
            return;
          }
          break;
        default:
          break;
      }

      await ApiService.request(
        {
          evenement_id: smsData.eventid || match.params.eventid,
          title: campaignTitle,
          message: updatedMessage,
          sender_name: senderName,
          schedule_date,
          stop_sms: stopSMS,
          status,
          error_data: smsData.error_data || null,
          nbContact: guest_ids.length,
          guest_ids,
          segments_ids: segmentsIds,
          guests_lists_ids: listsIds,
          //TODO: field => testNumber
          //TODO: add new state => test
        },
        "sms/" + id,
        method
      ).then((data) => {
        toast.success("Campagne SMS Sauvegardée");
        props.history.push(
          "/admin/events/edit/" + match.params.eventid + "/sms/"
        );
      });
    } catch (error) {
      console.log(error);
      toast.error("Echec lors de la sauvegarde");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="row">
      <div className="col-md-10 offset-md-1 main-content">
        <h2 className="font-weight-bold">
          Campagne SMS {">"} Nouvelle campagne
        </h2>
        <StepsList step={step} setStep={setStep} />
        <div>
          {step === 1 ? (
            <SmsCampaignStepOne
              campaignTitle={campaignTitle}
              setCampaignTitle={setCampaignTitle}
              senderName={senderName}
              setSenderName={setSenderName}
              message={message}
              setMessage={setMessage}
              stopSMS={stopSMS}
              setStopSMS={setStopSMS}
              isSmsCampaignEditable={isSmsCampaignEditable}
              shortcodesOptions={shortcodesOptions}
              setStep={setStep}
              submitCampaign={submitCampaign}
            />
          ) : step === 2 ? (
            <SmsCampaignStepTwo
              guests={guests}
              setGuests={setGuests}
              guestsChecked={guestsChecked}
              setGuestsChecked={setGuestsChecked}
              guestsCheckedValid={guestsCheckedValid}
              setGuestsCheckedValid={setGuestsCheckedValid}
              eventId={match.params.eventid}
              submitCampaign={submitCampaign}
              setSegmentsIds={setSegmentsIds}
              setListsIds={setListsIds}
              segmentsIds={segmentsIds}
              listsIds={listsIds}
              setStep={setStep}
              evenement={evenement}
              components={components}
              isSmsCampaignEditable={isSmsCampaignEditable}
              reLoadData={loadData}
              guestsSmsStatus={smsData.guests_sms_status || []}
              smsData={smsData}
              {...props}
            />
          ) : step === 3 ? (
            <SmsCampaignStepThree
              scheduleDate={scheduleDate}
              setScheduleDate={setScheduleDate}
              submitCampaign={submitCampaign}
              isSmsCampaignEditable={isSmsCampaignEditable}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SmsForm;
