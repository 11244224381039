import React from "react";
import { Button } from "mdbreact";
import ReactExport from "react-export-excel";

class ExportInvoices extends React.Component {
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.contacts !== prevProps.contacts &&
      this.props.contacts.length !== 0
    ) {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

    const columns = [
      "N° Facture",
      "ID Stripe",
      "Evenement",
      "Date de la facture",
      "N° commande",
      "Raison sociale",
      "Contact commande",
      "Contact facturation",
      "Montant HT",

      "TVA 5,5%",
      "TVA 10%",
      "TVA 20%",
      "Montant TTC",
      "Mode réglement (CB-CHQ)",
    ];

    const data = [];

    this.props.invoices
      .filter((invoice) => invoice.invoice_number.slice(-8) !== "PROFORMA")
      .forEach((invoice) => {
        const totalHT = invoice.data.totalHT
          ? Number(invoice.data.totalHT).toFixed(2).toString()
          : 0.0;
        const totalTTC = invoice.data.totalTTC
          ? invoice.data.totalTTC.toFixed(2).toString()
          : 0.0;
        const total_vat_5 = invoice.data.total_vat_5
          ? invoice.data.total_vat_5.toFixed(2).toString()
          : 0.0;
        const total_vat_10 = invoice.data.total_vat_10
          ? invoice.data.total_vat_10.toFixed(2).toString()
          : 0.0;
        const total_vat_20 = invoice.data.total_vat_20
          ? invoice.data.total_vat_20.toFixed(2).toString()
          : 0.0;
        data.push([
          invoice.invoice_number,
          invoice.data.refund_id || invoice.stripe_transaction_id || "",
          this.props.events.find((event) => event.id === invoice.evenement_id)
            ? this.props.events.find(
                (event) => event.id === invoice.evenement_id
              ).data.name
            : "Inconnu",
          invoice.created_at,
          invoice.order_number,
          invoice.data.company ? invoice.data.company : "",
          invoice.contact
            ? invoice.contact.lastname + " " + invoice.contact.firstname
            : "Anonyme",
          invoice.data.lastname +
            " " +
            (invoice.data.firsname ? invoice.data.firsname : ""),
          totalHT !== 0 ? totalHT.replace(".", ",") : totalHT,

          total_vat_5 !== 0 ? total_vat_5.replace(".", ",") : total_vat_5,
          total_vat_10 !== 0 ? total_vat_10.replace(".", ",") : total_vat_10,
          total_vat_20 !== 0 ? total_vat_20.replace(".", ",") : total_vat_20,
          totalTTC !== 0 ? totalTTC.replace(".", ",") : totalTTC,
          invoice.data.paymentMode === "card"
            ? "Carte"
            : invoice.data.paymentMode === "check"
            ? "Chèque"
            : "Accompte",
          /* !invoice.stripe_transaction_id
          ? "Accompte"
          : invoice.data.paymentMode === "card"
          ? "Carte"
          : "Chèque",*/
        ]);
      });

    const multiDataSet = [
      {
        columns,
        data,
      },
    ];

    const date = new Date();

    return (
      <ExcelFile
        filename={`${
          window.location.hostname
        }_exportfactures_${date.getDate()}_${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}_${date.getFullYear()}`}
        element={
          <Button className="btn pink darken-1 ">Exporter les factures</Button>
        }
      >
        <ExcelSheet dataSet={multiDataSet} name="Export global" />
      </ExcelFile>
    );
  }
}

export default ExportInvoices;
