import React from "react";
import "./WebPage.css";
import {
  webPageTranslations,
  formattedDays,
  formattedMonths,
} from "./Translations";
import { Modal } from "mdbreact";
import CheckOutForm from "./CheckOutForm";
import { toast } from "react-toastify";
import Loader from "./Loader";

class WebpageCheckout extends React.Component {
  constructor(props) {
    super(props);
    const { contact, componentsResponses } = this.props;
    this.state = {
      activeGuestKey: 0,
      accept_policy: false,
      showPaymentProcessingMessage: false,
      invoice: {
        //? Invoice's data
        firstname: contact.firstname,
        lastname: contact.lastname,
        address: contact.address,
        address_more: contact.address_more,
        zip_code: contact.zip_code,
        city: contact.city,
        country: contact.country,
        company: contact.company_id ? contact.company.name : "",
        products: [],
        total_vat_0: 0,
        total_vat_5: 0,
        total_vat_10: 0,
        total_vat_20: 0,
        totalHT: 0,
        totalTTC: 0,
        total_vat_0_before_balance: 0,
        total_vat_5_before_balance: 0,
        total_vat_10_before_balance: 0,
        total_vat_20_before_balance: 0,
        totalHT_before_balance: 0,
        totalTTC_before_balance: 0,
        paymentMode: "card",
        componentsResponses: componentsResponses,
        previousAmount: null,
        refund_id: null,
      },
      showError: false,
      stripe: null,
      cardElement: null,
      isLoading: false,
      responsesToRefund: [],
      willNotJoin: false,
    };

    this.showprogram = this.showprogram.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.calculatePrices = this.calculatePrices.bind(this);
    this.getRemovedAndNewGuestsResponses =
      this.getRemovedAndNewGuestsResponses.bind(this);
    this.calculateItem = this.calculateItem.bind(this);
    this.updateStripe = this.updateStripe.bind(this);
    this.switchUser = this.switchUser.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.show_payment_error = this.show_payment_error.bind(this);
    this.setPaymentModeState = this.setPaymentModeState.bind(this);
    this.getReponseInvoice = this.getReponseInvoice.bind(this);
    this.updateInvoicesToRefund = this.updateInvoicesToRefund.bind(this);
    //! this.removeIdenticalObjects = this.removeIdenticalObjects.bind(this);
    //! this.isEqual = this.isEqual.bind(this);
    this.filterOldNewReponsesToRefund =
      this.filterOldNewReponsesToRefund.bind(this);
    this.isItPossibleToAddNewFollowers =
      this.isItPossibleToAddNewFollowers.bind(this);
    this.element = React.createRef();
  }

  switchUser(e, activeGuestKey) {
    e.preventDefault();
    this.setState({ activeGuestKey });
  }

  validateForm() {
    const emptyStripe = [
      ...document.getElementsByClassName("StripeElement--empty"),
    ];

    const invalidStripe = [
      ...document.getElementsByClassName("StripeElement--invalid"),
    ];

    const { invoice, accept_policy } = this.state;

    return !(
      (invoice.paymentMode === "check" ||
        (emptyStripe.length === 0 && invalidStripe.length === 0)) &&
      accept_policy
    );
  }

  show_payment_error(refundError, pending) {
    if (refundError) {
      this.setState({
        isLoading: false,
        showPaymentProcessingMessage: false,
      });
      toast.error(webPageTranslations[this.props.code].refund_already_message, {
        autoClose: 4000,
      });
    } else if (pending) {
      this.setState({
        isLoading: false,
        showPaymentProcessingMessage: false,
      });
      toast.error(webPageTranslations[this.props.code].refund_pending, {
        autoClose: 4000,
      });
    } else {
      this.setState({
        isLoading: false,
        showPaymentProcessingMessage: false,
      });
      toast.error(webPageTranslations[this.props.code].payment_error, {
        autoClose: 4000,
      });
    }
  }

  updateStripe(name, value) {
    this.setState({ [name]: value });
  }

  handleChange(event) {
    this.setState({
      invoice: {
        ...this.state.invoice,
        [event.target.name]: event.target.value,
      },
    });
  }

  setPaymentModeState(state) {
    this.setState({
      invoice: {
        ...this.state.invoice,
        paymentMode: state,
      },
    });
  }

  calculateItem(eventComponent, userKey, userReponse, invoiceItems) {
    let invoiceItem = null;
    const { code, event, eventResponses, guest } = this.props;

    const keyGuest = event.data.guestsTypes.findIndex(
      (type) =>
        type.name.find((lang) => lang.code === code).value === userReponse.type
    );

    const guestsType = event.data.guestsTypes.find(
      (type) =>
        type.name.find((lang) => lang.code === code).value === userReponse.type
    );
    if (eventComponent.data.priceType === "unique") {
      invoiceItem = {
        vat: eventComponent.data.tax,
        ht: eventComponent.data.totalHT,
        ttc: eventComponent.data.totalTTC,
        id: eventComponent.id,
        keyGuest,
        label: eventComponent.data.billingLabel.find(
          (lang) => lang.code === code
        ).value,
      };
    } else {
      let rule = null;
      if (userKey === 0) {
        const participantsNumber =
          eventResponses.filter((eventReponse) =>
            eventReponse.guest_id !== guest.id &&
            eventReponse &&
            eventReponse.responses
              ? eventReponse.responses.some(
                  (componentReponse) =>
                    componentReponse.id === eventComponent.id &&
                    (eventComponent.data.reponse.htmlType === "checkbox" ||
                      (eventComponent.data.reponse.htmlType === "options" &&
                        componentReponse.key === 0))
                )
              : null
          ).length + 1;

        rule = event.data.guestPriceRules.find(
          (rule) =>
            Number(rule.componentId) === eventComponent.id &&
            participantsNumber >= Number(rule.lowRange) &&
            participantsNumber <= Number(rule.highRange)
        );
      } else {
        const countItemsRule =
          invoiceItems.filter((item) =>
            event.data.dontUseSubTypePriceRule
              ? item.id === eventComponent.id &&
                item.keyGuest !== -1 &&
                (guestsType.type === item.type ||
                  item.type === "externalOrInternal")
              : item.keyGuest &&
                item.keyGuest === keyGuest &&
                item.id === eventComponent.id
          ).length + 1;

        rule = event.data.followerpriceRules.find(
          (rule) =>
            Number(rule.componentId) === eventComponent.id &&
            keyGuest !== -1 &&
            (event.data.dontUseSubTypePriceRule
              ? rule.followerType === guestsType.type ||
                rule.followerType === "externalOrInternal"
              : rule.followerSubType !== "" &&
                rule.followerSubType !== null &&
                rule.followerSubType !== "undefined" &&
                Number(rule.followerSubType) === keyGuest) &&
            countItemsRule >= Number(rule.lowRange) &&
            countItemsRule <= Number(rule.highRange)
        );
      }

      const vat = rule ? rule.tax : 0;
      const ttc = rule ? rule.priceTTC : 0;
      const ht = rule ? rule.priceHT : 0;

      invoiceItem = {
        vat,
        ht,
        ttc,
        id: eventComponent.id,
        keyGuest: userKey !== 0 ? keyGuest : -1,
        type: rule ? rule.followerType : null,
        label:
          eventComponent.data.billingLabel.find((lang) => lang.code === code)
            .value +
          " " +
          (guestsType
            ? guestsType.name.find((lang) => lang.code === code).value
            : ""),
      };
    }
    return invoiceItem;
  }

  componentDidMount() {
    const { componentsResponses, setComponentsResponses, componentEntranceId } =
      this.props;

    const willNotJoin = componentsResponses[0].reponses.some(
      (reponse) => reponse.id === componentEntranceId && reponse.key === 1
    );

    if (willNotJoin) {
      const newComponentsResponses = componentsResponses.map((userResp) => {
        const newResponses = userResp.reponses.map((rep) => {
          if (rep.id !== componentEntranceId && rep.key === 0) rep.key = 1;
          return rep;
        });
        userResp.reponses = newResponses;
        return userResp;
      });

      setComponentsResponses(newComponentsResponses);
    }

    this.setState({ willNotJoin });
    this.calculatePrices();
    this.getRemovedAndNewGuestsResponses();
  }

  // removeIdenticalObjects(array1, array2) {
  //   const filteredArray1 = array1.filter(
  //     (obj1) => !array2.some((obj2) => this.isEqual(obj1, obj2))
  //   );
  //   const filteredArray2 = array2.filter(
  //     (obj2) => !array1.some((obj1) => this.isEqual(obj2, obj1))
  //   );

  //   return [...filteredArray1, ...filteredArray2];
  // }

  filterOldNewReponsesToRefund(oldReponses, newReponses) {
    console.log(
      "🚀 ~ WebpageCheckout ~ filterOldNewReponsesToRefund ~ oldReponses:",
      { oldReponses, newReponses }
    );
    const filteredReponses = oldReponses.filter((oldRep) =>
      newReponses.find(
        (newRep) =>
          newRep.id === oldRep.id && oldRep.key !== 1 && newRep.key === 1 //! WHAT HAPPEN IF THERE IS MORE THEN 2 KEYS? BETTER TO COMPARE TO KEY 0
      )
    );

    return filteredReponses;
  }

  isEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  getReponseInvoice(guestId, reponseId, componentId, componentResponseKey) {
    const conditionOne = this.props.guestInvoices.find(
      (invoice) =>
        invoice.invoice_number &&
        !invoice.invoice_number.includes("_PROFORMA") &&
        invoice.data.componentsResponses.some((rep) => {
          if (
            rep.guestId === guestId &&
            rep.reponseId === reponseId &&
            rep.reponses &&
            rep.reponses.some(
              (r) =>
                r.id === componentId && Number(r.key) === componentResponseKey
            )

            //! WHAT HAPPEN IF NEW INVOICE BUT SAME GUEST_ID AND SAME RESPONSE_ID. (How we determine what invoice to return)
            //! WHY NOT COMPARE BY PRICE TTC TOO?
          ) {
            return true;
          }
          return false;
        })
    );

    return conditionOne ? JSON.parse(JSON.stringify(conditionOne)) : null;
  }

  getRemovedAndNewGuestsResponses() {
    let removedGuestsResponses = [];
    let newGuestsResponses = [];

    const { eventComponents, componentsResponses, initialComponentsResponses } =
      this.props;
    initialComponentsResponses.forEach((userReponse, userKey) => {
      const found = componentsResponses.find(
        (resp) =>
          resp.reponseId === userReponse.reponseId &&
          resp.guestId === userReponse.guestId
      );

      if (!found) {
        let component = null;
        let componentResponseKey = null;
        userReponse.reponses.forEach((reponse) => {
          if (reponse) {
            const res = eventComponents.find(
              (eventComponent) =>
                Number(reponse.id) === Number(eventComponent.id) &&
                eventComponent.data.isFree === "false" &&
                (eventComponent.data.reponse.htmlType === "checkbox" ||
                  (eventComponent.data.reponse.htmlType === "radio" &&
                    Number(reponse.key) !== 1))
            );
            if (res) {
              component = res;
              componentResponseKey = Number(reponse.key);
              return;
            }
          }
        });
        if (component) {
          const priceInfo = this.calculateItem(
            component,
            userKey,
            userReponse,
            [] //! STOP USING THIS
          );

          const invoiceRelatedToReponse = this.getReponseInvoice(
            userReponse.guestId,
            userReponse.reponseId,
            priceInfo.id,
            componentResponseKey
          );
          console.log(
            "🚀 ~ WebpageCheckout ~ initialComponentsResponses.forEach ~ priceInfo3:",
            { priceInfo, userReponse, invoice: invoiceRelatedToReponse }
          );

          removedGuestsResponses.push({
            userReponse,
            invoice: invoiceRelatedToReponse,
          });
        }
      }
    });

    console.log(
      "🚀 ~ WebpageCheckout ~ getRemovedAndNewGuestsResponses ~ componentsResponses:",
      { componentsResponses, initialComponentsResponses }
    );

    componentsResponses.forEach((userReponse) => {
      const found = initialComponentsResponses.find(
        (resp) =>
          resp.reponseId === userReponse.reponseId &&
          resp.guestId === userReponse.guestId
      );

      if (!found) newGuestsResponses.push({ userReponse });
    });

    this.props.updateNewAndRemovedGuestsResponsesList(
      removedGuestsResponses,
      newGuestsResponses,
      this.state.invoice.totalTTC
    );
  }

  updateInvoicesToRefund(responsesToRefund) {
    const { invoice } = this.state;

    if (responsesToRefund && responsesToRefund.length) {
      const invoicesToRefund = responsesToRefund.map((item) => {
        if (item.invoice && item.invoice.data && item.invoice.data.products) {
          const productToRefund = item.invoice.data.products.find(
            (product) =>
              product.id === item.priceInfo.id &&
              product.priceHT === item.priceInfo.ht &&
              product.priceTTC === item.priceInfo.ttc &&
              product.vat === item.priceInfo.vat &&
              product.keyGuest === item.priceInfo.keyGuest
          );

          const newProductToRefund = invoice.products.find(
            (product) =>
              product.id === item.priceInfo.id &&
              product.priceHT === item.priceInfo.ht &&
              product.priceTTC === item.priceInfo.ttc &&
              product.vat === item.priceInfo.vat &&
              product.keyGuest === item.priceInfo.keyGuest
          );

          if (productToRefund) {
            productToRefund.quantity = newProductToRefund
              ? productToRefund.quantity - newProductToRefund.quantity
              : productToRefund.quantity;
            productToRefund.totalht =
              productToRefund.quantity * Number(productToRefund.priceHT);
            item.invoice.data.products = [productToRefund];
          }

          //TODO: update total variables info

          item.invoice.data.totalHT = 0;
          item.invoice.data.totalTTC = 0;
          item.invoice.data.total_vat_0 = 0;
          item.invoice.data.total_vat_5 = 0;
          item.invoice.data.total_vat_10 = 0;
          item.invoice.data.total_vat_20 = 0;

          item.invoice.data.previousAmount = null;
          // item.invoice.data.total_vat_0_before_balance = 0;
          // item.invoice.data.total_vat_5_before_balance = 0;
          // item.invoice.data.total_vat_10_before_balance = 0;
          // item.invoice.data.total_vat_20_before_balance = 0;

          // item.invoice.data.previousAmount = this.state.invoice.previousAmount;
          item.invoice.data.totalHT_before_balance =
            this.state.invoice.totalHT_before_balance;
          item.invoice.data.totalTTC_before_balance =
            this.state.invoice.totalTTC_before_balance;

          // item.invoice.data.total_vat_0_before_balance =
          //   this.state.invoice.total_vat_0;
          // item.invoice.data.total_vat_5_before_balance =
          //   this.state.invoice.total_vat_5;
          // item.invoice.data.total_vat_10_before_balance =
          //   this.state.invoice.total_vat_10;
          // item.invoice.data.total_vat_20_before_balance =
          //   this.state.invoice.total_vat_20;

          item.invoice.data.products.forEach((product) => {
            item.invoice.data.totalHT =
              parseFloat(item.invoice.data.totalHT) +
              parseFloat(product.priceHT);
            item.invoice.data.totalTTC =
              parseFloat(item.invoice.data.totalTTC) +
              parseFloat(product.priceTTC);
            switch (product.vat.toString()) {
              case "0":
                item.invoice.data.total_vat_0 =
                  parseFloat(item.invoice.data.total_vat_0) +
                  parseFloat(product.priceTTC) -
                  parseFloat(product.priceHT);
                break;
              case "5.5":
                item.invoice.data.total_vat_5 =
                  parseFloat(item.invoice.data.total_vat_5) +
                  parseFloat(product.priceTTC) -
                  parseFloat(product.priceHT);
                break;
              case "10":
                item.invoice.data.total_vat_10 =
                  parseFloat(item.invoice.data.total_vat_10) +
                  parseFloat(product.priceTTC) -
                  parseFloat(product.priceHT);
                break;
              case "20":
                item.invoice.data.total_vat_20 =
                  parseFloat(item.invoice.data.total_vat_20) +
                  parseFloat(product.priceTTC) -
                  parseFloat(product.priceHT);

                break;
              default:
                break;
            }
          });
        }

        return item;
      });

      this.setState({
        responsesToRefund: invoicesToRefund,
      });
    }
  }

  //! check
  calculatePrices() {
    const { eventComponents, componentsResponses, initialComponentsResponses } =
      this.props;
    console.log(
      "🚀 ~ WebpageCheckout ~ calculatePrices ~ componentsResponses:",
      { initialComponentsResponses, componentsResponses }
    );

    let responsesToRefund = [];
    // TODO: Iterate over initial initialComponentsResponses and retrieve responses that have been updated to false
    initialComponentsResponses.forEach((userReponse, userKey) => {
      const newUserReponse = componentsResponses.find(
        (resp) =>
          resp.reponseId === userReponse.reponseId &&
          resp.guestId === userReponse.guestId
      );
      console.log(
        "🚀 ~ WebpageCheckout ~ initialComponentsResponses.forEach ~ newUserReponse:",
        newUserReponse
      );

      if (newUserReponse) {
        if (userReponse.reponses && newUserReponse.reponses) {
          const res = this.filterOldNewReponsesToRefund(
            userReponse.reponses,
            newUserReponse.reponses
          );
          console.log(
            "🚀 ~ WebpageCheckout ~ initialComponentsResponses.forEach ~ res:",
            res
          );

          if (res && res.length) {
            res.forEach((reponse) => {
              if (reponse) {
                const component = eventComponents.find(
                  (eventComponent) =>
                    Number(reponse.id) === Number(eventComponent.id) &&
                    eventComponent.data.isFree === "false" &&
                    (eventComponent.data.reponse.htmlType === "checkbox" ||
                      (eventComponent.data.reponse.htmlType === "radio" &&
                        Number(reponse.key) !== 1))
                );
                if (component) {
                  const componentResponseKey = Number(reponse.key);
                  const priceInfo = this.calculateItem(
                    component,
                    userKey,
                    userReponse,
                    [] //! STOP USING THIS
                  );

                  const invoiceRelatedToReponse = this.getReponseInvoice(
                    userReponse.guestId,
                    userReponse.reponseId,
                    priceInfo.id,
                    componentResponseKey
                  );

                  console.log(
                    "🚀 ~ WebpageCheckout ~ initialComponentsResponses.forEach ~ priceInfo1:",
                    { priceInfo, userReponse, invoice: invoiceRelatedToReponse }
                  );

                  if (invoiceRelatedToReponse) {
                    responsesToRefund.push({
                      guestId: userReponse.guestId,
                      reponseId: userReponse.reponseId,
                      priceInfo,
                      invoice: invoiceRelatedToReponse,
                    });
                  }
                }
              }
            });
          }
        }
      } else {
        userReponse.reponses.forEach((reponse) => {
          if (reponse) {
            const component = eventComponents.find(
              (eventComponent) =>
                Number(reponse.id) === Number(eventComponent.id) &&
                eventComponent.data.isFree === "false" &&
                (eventComponent.data.reponse.htmlType === "checkbox" ||
                  (eventComponent.data.reponse.htmlType === "radio" &&
                    Number(reponse.key) !== 1))
            );
            if (component) {
              const componentResponseKey = Number(reponse.key);
              const priceInfo = this.calculateItem(
                component,
                userKey,
                userReponse,
                [] //! STOP USING THIS // E-COMMERCE COMBINATION AND SHITS
              );
              const invoiceRelatedToReponse = this.getReponseInvoice(
                userReponse.guestId,
                userReponse.reponseId,
                priceInfo.id,
                componentResponseKey
              );

              console.log(
                "🚀 ~ WebpageCheckout ~ initialComponentsResponses.forEach ~ priceInfo2:",
                { priceInfo, userReponse, invoice: invoiceRelatedToReponse }
              );

              if (invoiceRelatedToReponse) {
                responsesToRefund.push({
                  guestId: userReponse.guestId,
                  reponseId: userReponse.reponseId,
                  priceInfo,
                  invoice: invoiceRelatedToReponse,
                });
              }
            }
          }
        });
      }
    });

    console.log(
      "🚀 ~ file: WebPageCheckout.js:282 ~ WebpageCheckout ~ calculatePrices ~ responsesToRefund:",
      responsesToRefund
    );

    let invoiceItems = []; //!
    componentsResponses.forEach((userReponse, userKey) => {
      userReponse.reponses.forEach((reponse) => {
        const component = eventComponents.find(
          (eventComponent) =>
            Number(reponse.id) === Number(eventComponent.id) &&
            eventComponent.data.isFree === "false" &&
            (eventComponent.data.reponse.htmlType === "checkbox" ||
              (eventComponent.data.reponse.htmlType === "radio" &&
                (eventComponent.data.reponse.isPrefilled ||
                  Number(reponse.key) !== 1)))
        );

        if (component) {
          //? Calculate the price of guest validated responses
          invoiceItems.push(
            this.calculateItem(component, userKey, userReponse, invoiceItems)
          );
        }
      });
    });
    console.log(
      "🚀 ~ file: WebPageCheckout.js:272 ~ WebpageCheckout ~ userReponse.reponses.forEach ~ invoiceItems:",
      invoiceItems
    );

    let {
      total_vat_0,
      total_vat_5,
      total_vat_10,
      total_vat_20,
      totalHT,
      totalTTC, //8
      total_vat_0_before_balance,
      total_vat_5_before_balance,
      total_vat_10_before_balance,
      total_vat_20_before_balance,
      totalHT_before_balance,
      totalTTC_before_balance,
    } = this.state.invoice;

    invoiceItems.forEach((item) => {
      totalHT = parseFloat(totalHT) + parseFloat(item.ht);
      totalTTC = parseFloat(totalTTC) + parseFloat(item.ttc);
      switch (item.vat.toString()) {
        case "0":
          total_vat_0 =
            parseFloat(total_vat_0) +
            parseFloat(item.ttc) -
            parseFloat(item.ht);
          break;
        case "5.5":
          total_vat_5 =
            parseFloat(total_vat_5) +
            parseFloat(item.ttc) -
            parseFloat(item.ht);
          break;
        case "10":
          total_vat_10 =
            parseFloat(total_vat_10) +
            parseFloat(item.ttc) -
            parseFloat(item.ht);
          break;
        case "20":
          total_vat_20 =
            parseFloat(total_vat_20) +
            parseFloat(item.ttc) -
            parseFloat(item.ht);

          break;
        default:
          break;
      }
    });

    console.log("🚀 ~ WebpageCheckout ~ invoiceItems.forEach ~ invoiceItems:", {
      invoiceItems,
      totalTTC,
    });

    const products = this.state.invoice.products;
    this.props.eventComponents.forEach((component) => {
      const invoiceItem = invoiceItems.filter(
        (item) => item.id === component.id
      );
      if (invoiceItem.length > 0) {
        if (component.data.priceType === "unique") {
          products.push({
            id: component.id,
            quantity: invoiceItem.length,
            vat: invoiceItem[0].vat,
            priceHT: invoiceItem[0].ht,
            priceTTC: invoiceItem[0].ttc,
            label: invoiceItem[0].label,
            totalht: invoiceItem[0].ht * invoiceItem.length,
            keyGuest: invoiceItem[0].keyGuest,
          });
        } else {
          invoiceItem.forEach((productItem) => {
            const productkey = products.findIndex(
              (product) =>
                productItem.id === product.id &&
                product.keyGuest === productItem.keyGuest &&
                productItem.ht === product.priceHT
            );

            if (productkey === -1) {
              products.push({
                id: productItem.id,
                quantity: 1,
                vat: productItem.vat,
                priceHT: productItem.ht,
                priceTTC: productItem.ttc,
                label: productItem.label,
                totalht: productItem.ht,
                keyGuest: invoiceItem[0].keyGuest,
              });
            } else {
              products[productkey].quantity = products[productkey].quantity + 1;
              products[productkey].totalht =
                products[productkey].priceHT + productItem.ht;
            }
          });
        }
      }
    });

    total_vat_0_before_balance = total_vat_0;
    total_vat_5_before_balance = total_vat_5;
    total_vat_10_before_balance = total_vat_10;
    total_vat_20_before_balance = total_vat_20;
    totalHT_before_balance = totalHT;
    totalTTC_before_balance = totalTTC;

    let previousAmount = null;

    if (this.props.lastInvoice) {
      total_vat_0 =
        total_vat_0 - this.props.lastInvoice.data.total_vat_0_before_balance;
      total_vat_5 =
        total_vat_5 - this.props.lastInvoice.data.total_vat_5_before_balance;
      total_vat_10 =
        total_vat_10 - this.props.lastInvoice.data.total_vat_10_before_balance;
      total_vat_20 =
        total_vat_20 - this.props.lastInvoice.data.total_vat_20_before_balance;
      totalTTC = totalTTC - this.props.lastInvoice.data.totalTTC_before_balance;
      totalHT = totalHT - this.props.lastInvoice.data.totalHT_before_balance;
      previousAmount = this.props.lastInvoice.data.totalTTC_before_balance;
    }

    this.setState(
      {
        invoice: {
          ...this.state.invoice,
          products,
          total_vat_0,
          total_vat_5,
          total_vat_10,
          total_vat_20,
          totalHT,
          totalTTC,
          total_vat_0_before_balance,
          total_vat_5_before_balance,
          total_vat_10_before_balance,
          total_vat_20_before_balance,
          totalHT_before_balance,
          totalTTC_before_balance,
          previousAmount,
        },
      },
      () => this.updateInvoicesToRefund(responsesToRefund)
    );
  }

  showprogram(key) {
    const { code, eventComponents, componentsResponses } = this.props;
    const programArray = [];

    //console.log("eventComponents", eventComponents);

    const sortedComponents = eventComponents
      .filter(
        (item) => item.data.startDate && item.data.componentType === "moment"
      )
      .slice()
      .sort(function (a, b) {
        return (
          new Date(b.data.startDate).getTime() -
          new Date(a.data.startDate).getTime()
        );
      })
      .reverse();

    //console.log("sortedComponents", sortedComponents);

    const eventComponentWithReponses = sortedComponents.map((component) => {
      const guestComponentReponse = componentsResponses[key].reponses.find(
        (componentReponse) => {
          if (Number(componentReponse.id) === Number(component.id)) {
            if (component.data.reponse.htmlType === "radio") {
              if (Number(componentReponse.key === 0)) {
                return componentReponse;
              } else {
                return false;
              }
            } else {
              return componentReponse;
            }
          } else {
            return false;
          }
        }
      );
      return {
        ...component,
        reponses: guestComponentReponse ? { ...guestComponentReponse } : null,
      };
    });

    let dateLabel = "";

    eventComponentWithReponses.forEach((eventComponentWithReponse) => {
      if (eventComponentWithReponse.reponses) {
        const label = eventComponentWithReponse.data.reponse.passportLabel.find(
          (label) => label.code === code
        ).value;

        const description =
          eventComponentWithReponse.data.reponse.passportDescription.find(
            (description) => description.code === code
          ).value;

        if (eventComponentWithReponse.data.startDate) {
          const dateToDisplay = new Date(
            eventComponentWithReponse.data.startDate
          );

          const tempDateLabel =
            formattedDays.find((lang) => lang.lang === code).labels[
              dateToDisplay.getDay()
            ] +
            " " +
            dateToDisplay.getDate() +
            " " +
            formattedMonths.find((lang) => lang.lang === code).labels[
              dateToDisplay.getMonth()
            ];

          if (tempDateLabel !== dateLabel && (label || description)) {
            programArray.push({ text: tempDateLabel, dateLabel: true });
            dateLabel = tempDateLabel;
          }
        }

        if (label || description) {
          //console.log("eventComponentWithReponse", eventComponentWithReponse);
          //console.log("startDate", eventComponentWithReponse.data.startDate);
        }
        if (label) {
          programArray.push({ text: label, dateLabel: false });
        }

        if (description) {
          programArray.push({ text: description, dateLabel: false });
        }
      }
    });

    return programArray;
  }

  isItPossibleToAddNewFollowers() {
    const { totalTTC } = this;
    const { removedGuestsResponses, newGuestsResponses } = this.props;
    if (
      removedGuestsResponses &&
      newGuestsResponses &&
      removedGuestsResponses.length > 0 &&
      newGuestsResponses.length > 0 &&
      removedGuestsResponses.length < newGuestsResponses.length &&
      totalTTC !== 0
    ) {
      return false;
    }
    return true;
  }

  render() {
    const {
      code,
      form,
      componentsResponses,
      initialComponentsResponses,
      cgv,
      contact,
      lastInvoice,
      event,
      guestInvoices,
      loadData,
      updateEventFormMessage,
    } = this.props;

    const {
      activeGuestKey,
      invoice,
      isLoading,
      showPaymentProcessingMessage,
      responsesToRefund,
      willNotJoin,
    } = this.state;

    const {
      firstname,
      lastname,
      address,
      address_more,
      zip_code,
      city,
      country,
      company,
      paymentMode,
      totalTTC,
    } = invoice;

    const program = this.showprogram(activeGuestKey);

    return (
      <div
        id="checkouform"
        className="row"
        style={{ margin: "auto", width: "100%" }}
      >
        <Modal isOpen={isLoading}>
          <div
            style={{
              opacity: 1,
              width: "100%",
              textAlign: "center",
            }}
          >
            {showPaymentProcessingMessage ? (
              <div className="col-md-12">
                {webPageTranslations[code ? code : "FR"].payment_processing}
              </div>
            ) : null}

            <Loader />
          </div>
        </Modal>
        {!willNotJoin && (
          <div
            style={{
              backgroundColor: form.layout.backgroundColorMain,
              padding: "20px",
            }}
            className={
              form.layout.gabarit === "gabarit2"
                ? "col-md-8 main-container"
                : "col-md-12 main-container"
            }
          >
            <div className="row">
              <h4 style={{ fontWeight: "bold" }}>
                {webPageTranslations[code ? code : "FR"].summary_title}
              </h4>
            </div>
            <div className="row">
              {componentsResponses.map((userReponse, key) => (
                <button
                  className="btn"
                  key={key}
                  onClick={(e) => this.switchUser(e, key)}
                  style={{
                    backgroundColor:
                      key === this.state.activeGuestKey
                        ? form.layout.mainColor
                        : "#EEEEEE",
                    color:
                      key === this.state.activeGuestKey
                        ? form.layout.secondaryColor
                        : "black",
                  }}
                >
                  {userReponse.firstname} {userReponse.lastname}
                </button>
              ))}
            </div>
            <div
              className="row"
              style={{
                backgroundColor: "#EEEEEE",
              }}
            >
              {program.map((item, key) => (
                <div
                  key={key}
                  className="col-md-12"
                  style={{
                    color: item.dateLabel ? form.layout.mainColor : "#000000",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                />
              ))}
            </div>
            <div className="row ">
              <div className="col-md-12 ">
                <button
                  className="btn float-right"
                  onClick={this.props.modifyForm}
                  style={{
                    backgroundColor: form.layout.mainColor,

                    color: form.layout.secondaryColor,
                  }}
                >
                  {webPageTranslations[code ? code : "FR"].modify}
                </button>{" "}
              </div>
            </div>
            {!this.isItPossibleToAddNewFollowers() ? (
              <div className="row ">
                <div className="col-md-12 ">
                  <strong>
                    <span className="text-danger">*</span>
                    Il n'est pas possible dans la même commande de remplacer et
                    d'ajouter un accompagnant. Merci de modifier vos
                    accompagnants en conséquence.
                  </strong>
                </div>
              </div>
            ) : null}
          </div>
        )}
        {!this.isItPossibleToAddNewFollowers() ? null : (
          <CheckOutForm
            {...this.props}
            componentsResponses={componentsResponses}
            initialComponentsResponses={initialComponentsResponses}
            contact={contact}
            lastInvoice={lastInvoice}
            event={event}
            invoice={invoice}
            code={code}
            cvg={cgv}
            form={form}
            modifyForm={this.props.modifyForm}
            totalTTC={totalTTC}
            lastname={lastname}
            firstname={firstname}
            handleChange={this.handleChange}
            company={company}
            address={address}
            address_more={address_more}
            city={city}
            country={country}
            zip_code={zip_code}
            validateForm={this.validateForm}
            sendResponses={this.props.sendResponses}
            sendResponses2={this.props.sendResponses2}
            chargeMoney={this.props.chargeMoney}
            paymentMode={paymentMode}
            setPaymentModeState={this.setPaymentModeState}
            element={this.element}
            guestInvoices={guestInvoices}
            show_payment_error={this.show_payment_error}
            stripeKey={this.props.stripeKey}
            loadData={loadData}
            updateEventFormMessage={updateEventFormMessage}
            responsesToRefund={responsesToRefund}
            willNotJoin={willNotJoin}
            refundCheck={this.props.refundCheck}
            setGuest={this.props.setGuest}
            filterOldNewReponsesToRefund={this.filterOldNewReponsesToRefund}
            componentEntranceId={this.props.componentEntranceId}
          />
        )}
      </div>
    );
  }
}
export default WebpageCheckout;
