import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import ApiService from "../../../services/ApiService";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "../../../services/Utils";
import { Button, MDBInput } from "mdbreact";
import { toast } from "react-toastify";
registerLocale("fr", fr);

class ComponentsRules extends React.Component {
  constructor(props) {
    super(props);

    this.handleComponentRuleSubmit = this.handleComponentRuleSubmit.bind(this);
    this.handleTagRuleSubmit = this.handleTagRuleSubmit.bind(this);
    this.handleTimeRuleSubmit = this.handleTimeRuleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeComponent2 = this.handleChangeComponent2.bind(this);

    this.deleteComponentRule = this.deleteComponentRule.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.renderComponentName = this.renderComponentName.bind(this);
    this.showOptionRules = this.showOptionRules.bind(this);
    this.saveRules = this.saveRules.bind(this);

    const { componentsRules, tagsRules, timeRules, singleComponentSelectRule } =
      this.props.event.component_rules;
    const componentsList = this.props.composantsList.sort(function (a, b) {
      const nameA = a.data.name.toLowerCase(),
        nameB = b.data.name.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    const componentsInputList = componentsList.filter(
      (component) =>
        (component.data.reponse.htmlType === "radio" &&
          component.data.reponse.options[0].value) ||
        component.data.reponse.htmlType === "checkbox" ||
        component.data.reponse.displayType === "agenda"
    );
    this.state = {
      component1: "",
      component2: "",
      rules: ["displayed", "disabled", "required", "is_selected"],
      componentRule: "displayed",
      match: "is_selected",
      matchKey: "",
      tag: "",
      componentsRules,
      singleComponentSelectRule,
      tagsRules,
      timeRules,
      tagsList: [],
      limit: "",
      startDate: new Date(this.props.event.start_date),
      endDate: new Date(this.props.event.end_date),
      timeLimit: "",
      matchOptions: null,
      componentName: "",
      componentOption: "displayed",
      componentName2: "",
      componentMatch: "",
      componentsInputListState: componentsInputList,
      componentsListState: componentsList,
    };
  }

  saveRules() {
    const { tagsRules, timeRules, componentsRules, singleComponentSelectRule } =
      this.state;
    const event = this.props.event;

    this.props.onSave({
      ...event,
      component_rules: {
        tagsRules,
        timeRules,
        componentsRules,
        singleComponentSelectRule,
      },
    });
  }
  async componentDidMount() {
    const self = this;
    await ApiService.request(this.state, "component_tag", "get").then(function (
      data
    ) {
      self.setState({ tagsList: data });
    });
  }

  translate(code) {
    const values = [
      { value: "displayed", label: "s'affiche si" },
      { value: "hidden", label: "se cache si" },
      { value: "disabled", label: "est désactivé si" },
      { value: "enabled", label: "est activé si" },
      { value: "required", label: "est obligatoire" },
      // { value: "enabled", label: "est sélectionné si" },
      { value: "default", label: "est sélectionné par défaut" },
      { value: "is_selected", label: "est sélectionné si" },
      { value: "equal", label: "est égal à" },
      { value: "", label: null },
    ];

    const item = values.find((item) => item.value === code);

    return item.label;
  }

  renderComponentName(id) {
    const component = this.props.composantsList.find(
      (component) => component.id === Number(id)
    );

    return component ? component.data.name : "";
  }

  handleStartDateChange(date) {
    let startDate = new Date(date);
    let endDate = new Date(this.state.endDate);

    if (startDate >= endDate) {
      endDate = startDate;
    }

    this.setState({
      startDate,
      endDate,
    });
  }

  handleEndDateChange(date) {
    const endDate = new Date(date);
    const startDate = new Date(this.state.startDate);

    if (startDate <= endDate) {
      this.setState({
        endDate,
      });
    }
  }

  handleChangeComponent2(event) {
    const component = this.props.composantsList.find(
      (component) => component.id === Number(event.target.value)
    );
    this.setState({
      match: component
        ? component.data.reponse.htmlType === "checkbox" ||
          component.data.reponse.displayType === "agenda"
          ? "is_selected"
          : "equal"
        : "is_selected",
      [event.target.name]: event.target.value,
      matchOptions: component
        ? component.data.reponse.htmlType === "radio" &&
          component.data.reponse.options[0].value
          ? component.data.reponse.options[0].value
              .split(";")
              .map((option, key) => {
                return { value: key, label: option };
              })
          : null
        : null,
      matchKey: "",
      componentName2: event.target.value,
    });
  }

  handleChange(event, type) {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value,
    });
    switch (type) {
      case "1":
        this.setState({ componentName: event.target.value });
        break;
      case "2":
        this.setState({ componentOption: event.target.value });
        break;
      case "3":
        this.setState({ componentMatch: event.target.value });
        break;
      default:
        console.log("aucun select à modif");
        break;
    }
  }
  showOptionRules(rules) {
    return rules.map((rule, index) => {
      return (
        <option key={`rules-container-rule-${index}`} value={rule}>
          {this.translate(rule)}
        </option>
      );
    });
  }
  shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }

    return true;
  }
  handleComponentRuleSubmit(e) {
    e.preventDefault();
    const {
      component1,
      component2,
      componentRule,
      matchKey,
      componentName,
      componentOption,
      componentName2,
      match,
      componentMatch,
    } = this.state;

    const newRules = this.state.componentsRules;
    const checkObj = {
      component1: componentName ? componentName : null,
      componentRule: componentOption,
      component2: componentName2 ? componentName2 : null,
      match: match,
      matchKey: componentMatch ? componentMatch : null,
    };
    var resultCompare = newRules.some((rule) => {
      var compare = this.shallowEqual(rule, checkObj);
      if (
        Number(rule.component1) === Number(componentName) &&
        rule.componentRule === componentOption &&
        componentOption === "required"
      ) {
        return true;
      }
      if (compare) {
        return true;
      }
      return false;
    });
    if (resultCompare) {
      toast.error("La règle existe déja", {
        autoClose: 3000,
      });
    } else {
      newRules.push({
        component1,
        component2,
        componentRule,
        match,
        matchKey,
      });
      this.setState({
        componentsRules: newRules,
      });
      toast.success("Règle ajouté", {
        autoClose: 3000,
      });
    }
  }
  componentDidUpdate() {}
  deleteComponentRule(index) {
    const newcomponentsRules = this.state.componentsRules;
    newcomponentsRules.splice(index, 1);
    this.setState({
      componentsRules: newcomponentsRules,
    });
  }

  handleTagRuleSubmit(e) {
    e.preventDefault();

    const { tag, limit } = this.state;

    const newRules = this.state.tagsRules;

    if (tag === "") {
      toast.error("Veuillez  sélectionner une thématique", {
        autoClose: 3000,
      });
      return;
    }

    const objectFind = newRules.find((rule) => rule.tag === Number(tag));

    if (objectFind) {
      toast.error("La règle existe déja", {
        autoClose: 3000,
      });
      return;
    }

    newRules.push({
      tag: Number(tag),
      limit,
    });

    this.setState({
      tagsRules: newRules,
    });
  }

  deleteTagRule(index) {
    const newTagsRules = this.state.tagsRules;
    newTagsRules.splice(index, 1);
    this.setState({
      tagsRules: newTagsRules,
    });
  }

  handleTimeRuleSubmit(e) {
    e.preventDefault();

    const { startDate, endDate, timeLimit } = this.state;

    const newRules = this.state.timeRules;
    newRules.push({
      startDate,
      endDate,
      timeLimit,
    });

    this.setState({
      timeRules: newRules,
    });
  }

  deleteTimeRule(index) {
    const newTimeRules = this.state.timeRules;
    newTimeRules.splice(index, 1);
    this.setState({
      timeRules: newTimeRules,
    });
  }

  render() {
    const {
      component1,
      component2,
      componentRule,
      match,
      matchOptions,
      matchKey,
      componentsRules,
      tag,
      tagsList,
      limit,
      tagsRules,
      timeRules,
      startDate,
      endDate,
      timeLimit,
      singleComponentSelectRule,
      rules,
    } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-md-12  offset-md-8">
            <Button color="default" onClick={this.saveRules}>
              Enregistrer les règles
            </Button>
          </div>
          <div className="col-md-12 offset-md-1">
            <h2>Règles communes</h2>
          </div>
          <div className="col-md-12 mt-3 mb-3 offset-md-1">
            <MDBInput
              onClick={() =>
                this.setState({
                  singleComponentSelectRule: !singleComponentSelectRule,
                })
              }
              id={"singleComponentSelectRule"}
              label={"Empecher la sélection de composants simultanés"}
              filled
              checked={singleComponentSelectRule}
              type="checkbox"
            />
          </div>
          <div className="col-md-12 offset-md-1">
            <h2>Règles entre composants</h2>
          </div>

          <div className="col-md-10 offset-md-1 main-content">
            <form
              onSubmit={this.handleComponentRuleSubmit}
              className="form-inline"
            >
              <select
                value={component1 || ""}
                name="component1"
                onChange={(e) => this.handleChange(e, "1")}
                className="form-control browser-default custom-select"
              >
                <option value="">Sélectionner un composant</option>
                {this.state.componentsListState.map((composant, key) => (
                  <option key={key} value={composant.id}>
                    {composant.data.name}
                  </option>
                ))}
              </select>
              &nbsp;&nbsp;&nbsp;
              <select
                value={componentRule || ""}
                name="componentRule"
                onChange={(e) => this.handleChange(e, "2")}
                className="form-control browser-default custom-select"
              >
                {this.showOptionRules(rules)}
              </select>
              &nbsp;&nbsp;&nbsp;
              <select
                value={component2 || ""}
                name="component2"
                onChange={this.handleChangeComponent2}
                className="form-control browser-default custom-select"
              >
                <option value="">Sélectionner un composant</option>
                {this.state.componentsInputListState.map((composant, key) => (
                  <option key={key} value={composant.id}>
                    {composant.data.name}
                  </option>
                ))}
              </select>
              &nbsp;&nbsp;&nbsp;
              {this.translate(match)}
              &nbsp;&nbsp;&nbsp;
              {matchOptions ? (
                <select
                  required
                  value={matchKey || ""}
                  name="matchKey"
                  onChange={(e) => this.handleChange(e, "3")}
                  className="form-control browser-default custom-select"
                >
                  <option value="">Sélectionner une valeur</option>
                  {matchOptions.map((option, key) => (
                    <option key={key} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              ) : null}
              &nbsp;&nbsp;&nbsp;
              <input
                type="submit"
                value="Ajouter"
                className="btn pink darken-1 float-right white-text  mb-2"
              />
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 offset-md-1">
            <br />
            <br />
            <h3>Liste des règles entre composants</h3>
            <br />
          </div>
          <div className="col-md-10 offset-md-1 main-content">
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  <th scope="col">Règle</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {componentsRules.map((rule, i) => (
                  <tr key={i}>
                    <td>
                      {this.renderComponentName(rule.component1)}&nbsp;
                      {this.translate(rule.componentRule)}&nbsp;
                      {this.renderComponentName(rule.component2)}
                      &nbsp;
                      {rule.component2 ? (
                        <>
                          &nbsp;
                          {this.translate(rule.match)}
                          &nbsp;
                          {this.state.componentsInputListState.find(
                            (component) =>
                              component.id === Number(rule.component2) &&
                              rule.match === "equal"
                          )
                            ? this.state.componentsInputListState
                                .find(
                                  (component) =>
                                    component.id === Number(rule.component2)
                                )
                                .data.reponse.options[0].value.split(";")[
                                Number(rule.matchKey)
                              ]
                            : null}
                        </>
                      ) : null}
                    </td>
                    <td style={{ width: "25%" }}>
                      {!rule.isFixed && (
                        <button
                          className="btn pink darken-1 float-right white-text"
                          onClick={() => this.deleteComponentRule(i)}
                        >
                          Supprimer
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 offset-md-1">
            <br />
            <br />
            <h2>Règles des thématiques</h2>
            <br />
          </div>
          <div className="col-md-10 offset-md-1 main-content">
            <form onSubmit={this.handleTagRuleSubmit} className="form-inline">
              <select
                value={tag || ""}
                name="tag"
                onChange={this.handleChange}
                className="form-control browser-default custom-select"
              >
                <option value="">Sélectionner une thématique</option>
                {tagsList.map((tag, key) => (
                  <option value={tag.id} key={key}>
                    {tag.names}
                  </option>
                ))}
              </select>
              &nbsp;&nbsp;&nbsp;est limité à &nbsp;&nbsp;&nbsp;
              <input
                name="limit"
                className="form-control"
                value={limit || ""}
                onChange={this.handleChange}
              />
              &nbsp;&nbsp;&nbsp;
              <input
                type="submit"
                value="Valider"
                className="btn pink darken-1 float-right white-text  mb-2"
              />
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 offset-md-1">
            <br />
            <br />
            <h3>Liste des règles des thématiques</h3>
            <br />
          </div>
          <div className="col-md-10 offset-md-1 main-content">
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  <th scope="col">Règle</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {tagsRules.map((rule, i) => (
                  <tr key={i}>
                    <td>
                      {tagsList.find((tag) => tag.id === rule.tag)
                        ? tagsList.find((tag) => tag.id === rule.tag).names
                        : "inconnu"}
                      &nbsp;est limité à &nbsp;
                      {rule.limit}
                    </td>
                    <td style={{ width: "25%" }}>
                      <button
                        className="btn pink darken-1 float-right white-text"
                        onClick={() => this.deleteTagRule(i)}
                      >
                        Supprimer
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 offset-md-1">
            <br />
            <br />
            <h2>Règles de limites</h2>
            <br />
          </div>
          <div className="col-md-10 offset-md-1 main-content">
            <form onSubmit={this.handleTimeRuleSubmit} className="form-inline">
              <label>
                <b>Début de tranche : </b>
              </label>
              &nbsp;&nbsp;&nbsp;
              <DatePicker
                className="form-control"
                showTimeSelect
                selectsStart
                timeFormat="HH:mm"
                selected={startDate}
                onChange={this.handleStartDateChange}
                locale="fr"
                timeIntervals={15}
                dateFormat="dd/MM/yyyy HH:mm"
                timeCaption="Heure"
                minDate={new Date(this.props.event.start_date)}
                maxDate={new Date(this.props.event.end_date)}
                minTime={new Date(this.props.event.start_date)}
                maxTime={new Date(this.props.event.end_date)}
              />
              &nbsp;&nbsp;&nbsp;
              <label>
                <b>Fin de tranche : </b>
              </label>
              &nbsp;&nbsp;&nbsp;
              <DatePicker
                className="form-control"
                showTimeSelect
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                timeFormat="HH:mm"
                selected={endDate}
                onChange={this.handleEndDateChange}
                locale="fr"
                timeIntervals={15}
                dateFormat="dd/MM/yyyy HH:mm"
                timeCaption="Heure"
                minDate={new Date(this.props.event.start_date)}
                maxDate={new Date(this.props.event.end_date)}
                minTime={new Date(this.props.event.start_date)}
                maxTime={new Date(this.props.event.end_date)}
              />
              &nbsp;&nbsp;&nbsp;
              <label>
                <b>Nbre sélectionnable : </b>
              </label>
              &nbsp;&nbsp;&nbsp;
              <input
                name="timeLimit"
                className="form-control"
                value={timeLimit || ""}
                onChange={this.handleChange}
              />
              &nbsp;&nbsp;&nbsp;
              <input
                type="submit"
                value="Valider"
                className="btn pink darken-1 float-right white-text  mb-2"
              />
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 offset-md-1">
            <br />
            <br />
            <h3>Liste des règles de limites</h3>
            <br />
          </div>
          <div className="col-md-10 offset-md-1 main-content">
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  <th scope="col">Règle</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {timeRules.map((rule, i) => (
                  <tr key={i}>
                    <td>
                      {rule.timeLimit} composant
                      {rule.timeLimit !== "1" ? "s" : null} sélectionnable
                      {rule.timeLimit !== "1" ? "s" : null} entre le{" "}
                      {formatDate(rule.startDate, true)} et le{" "}
                      {formatDate(rule.endDate, true)}
                    </td>
                    <td style={{ width: "25%" }}>
                      <button
                        className="btn pink darken-1 float-right white-text"
                        onClick={() => this.deleteTimeRule(i)}
                      >
                        Supprimer
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default ComponentsRules;
