import { parsePhoneNumber } from "libphonenumber-js";

function verifyNumberFunc(number, countryCode) {
  const phoneNumber = parsePhoneNumber(number, countryCode);

    if (phoneNumber) {
      const isValid = phoneNumber.isValid();

      if (isValid) {
        let formattedNumber = phoneNumber.formatInternational();
        formattedNumber = formattedNumber.replace(/\s+/g, ""); // Remove spaces
        if (number !== formattedNumber) {
          return formattedNumber;
        }
        return number;
      }
    }
    return null;
}

// verify and format phone number
export function verifyNumber(number, countryCode = undefined) {
  try {
    if (typeof number !== "string") return "undefined";
    return verifyNumberFunc(number, countryCode);
  } catch (error) {
    console.log("Invalid Number Format. Trying to convert to French number")
    try {
      return verifyNumberFunc(number, "FR");
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}

function isInvalidFrenchNumber(number) {
  const phoneNumber = parsePhoneNumber(number, "FR");

    if (phoneNumber) {
      const isValid = phoneNumber.isValid();

      if (isValid) {
        let formattedNumber = phoneNumber.formatInternational();
        formattedNumber = formattedNumber.replace(/\s+/g, ""); // Remove spaces
        if (number !== formattedNumber) {
          return "toFormat";
        }
        return "toFormat";
      }
    }
    return "invalid";
}

export function getPhoneNumberStatus(number) {
  try {
    if (typeof number !== "string") return "undefined";
    const phoneNumber = parsePhoneNumber(number);

    if (phoneNumber) {
      const isValid = phoneNumber.isValid();

      if (isValid) {
        let formattedNumber = phoneNumber.formatInternational();
        formattedNumber = formattedNumber.replace(/\s+/g, ""); // Remove spaces
        if (number !== formattedNumber) {
          return "toFormat";
        }
        return "valid";
      }
    }
    return "invalid";
  } catch (error) {
    if (error.message === "INVALID_COUNTRY") {
      try {
        return isInvalidFrenchNumber(number);
      } catch (error) {
        return "invalid";
      }
    }
    console.log(error);
    return "invalid";
  }
}

export function removeDialCodeFromNumber(phoneNumber, dialCode) {
  let parsedNumber = phoneNumber.startsWith(dialCode);
  if (parsedNumber) {
    const res = phoneNumber.slice(dialCode.length);
    return res;
  }
  return null;
}

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const SMS_STATES = {
  DRAFT: "draft",
  SENT: "sent",
  IN_PROGRESS: "in_progress",
  ERROR: "error",
  WAITING: "waiting",
  SEND_NOW: "send_now",
};

export const SMS_ACTIONS = {
  DRAFT: "draft",
  TESTING: "test",
  SCHEDULE: "schedule",
  SEND_NOW: "send_now",
};
