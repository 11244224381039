import React from "react";
import {webPageTranslations} from "../Translations";
import { MDBInput} from "mdbreact";
const UserInformationComponent=(props)=>{
    const {code,handleChange,lastname,firstname,country,address_more,company,city,address,zip_code}=props

    return(
        <div className="row">
        <div className="row">
          <div className="col-md-4">
            <MDBInput
              label={
                webPageTranslations[code ? code : "FR"].lastname + " (*)"
              }
              type="text"
              value={lastname}
              name="lastname"
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-4">
            <MDBInput
              label={
                webPageTranslations[code ? code : "FR"].firstname + " (*)"
              }
              type="text"
              value={firstname}
              name="firstname"
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-4">
            <MDBInput
              label={webPageTranslations[code ? code : "FR"].company}
              type="text"
              value={company}
              name="company"
              onChange={handleChange}
            />
          </div>
          <div className="col-md-4">
            <MDBInput
              label={
                webPageTranslations[code ? code : "FR"].address + " (*)"
              }
              type="text"
              value={address}
              name="address"
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6">
                <MDBInput
                  label={
                    webPageTranslations[code ? code : "FR"].address_more
                  }
                  type="text"
                  value={address_more}
                  name="address_more"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <MDBInput
              label={
                webPageTranslations[code ? code : "FR"].zip_code + " (*)"
              }
              type="text"
              value={zip_code}
              name="zip_code"
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-4">
            <MDBInput
              label={
                webPageTranslations[code ? code : "FR"].city + " (*)"
              }
              type="text"
              value={city}
              name="city"
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-4">
            <MDBInput
              label={
                webPageTranslations[code ? code : "FR"].country + " (*)"
              }
              type="text"
              value={country}
              name="country"
              onChange={handleChange}
              required
            />
          </div>
        </div>
      </div>
    )
}
export default UserInformationComponent