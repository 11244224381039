import React from "react";
import { clearLocalStorage, locationPush } from "../services/Utils";
import { useState } from "react";
import ApiService from "../services/ApiService";
import LoginForm from "../common/LoginForm";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { MDBCard } from "mdbreact";
import { MDBCardBody } from "mdbreact";
import { MDBBtn } from "mdbreact";

export default function WebAppMain() {
  const [isLogged, setLogged] = useState(ApiService.checkToken());
  function doLogin() {
    setLogged(true);
  }
  function doLogout() {
    clearLocalStorage();
    setLogged(false);
  }
  function setPage(page) {
    switch (page) {
      case "placement":
        locationPush("placement");
        break;
      case "checkin":
        locationPush("checkin");
        break;
      default:
        locationPush("webapp");
        break;
    }
  }
  return isLogged ? (
    <MDBContainer className="mt-5">
      <MDBRow center>
        <MDBCol md="6">
          <MDBCard>
            <MDBCardBody className="mx-4">
              <div className="text-center">
                <h3 className="dark-grey-text mb-5">
                  <strong>Configuration </strong>
                  <br />
                  <strong>du</strong>
                  <br />
                  <strong>Checkin/Placement</strong>
                </h3>
              </div>

              <div className="text-center mb-3">
                <MDBBtn
                  onClick={() => setPage("checkin")}
                  rounded
                  type="submit"
                  className="btn-block z-depth-1a pink darken-1"
                >
                  Checkin
                </MDBBtn>
                <br />

                <MDBBtn
                  onClick={() => setPage("placement")}
                  rounded
                  type="submit"
                  className="btn-block z-depth-1a pink darken-1"
                >
                  Placement
                </MDBBtn>
              </div>
            </MDBCardBody>
            <button onClick={doLogout} className="btn cancel-button">
              Se déconnecter
            </button>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  ) : (
    <LoginForm doLogin={doLogin} btnLabel={"Se connecter"} />
  );
}
