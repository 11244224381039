import React from "react";
import { Link } from "react-router-dom";
import ReactExport from "react-export-excel";
import ApiService from "../../../services/ApiService";
import ImportComponents from "./ImportComponents";
import Loader from "../../../common/Loader";
import Sortable from "../../../common/Sortable";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faCheck,
  faCopy,
  faTimes,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import {
  componentsTypesTranslation,
  componentsRolesTranslation,
} from "../../../common/Translations";
import { formatDate, filterElements } from "../../../services/Utils";
import { MDBInput } from "mdbreact";

class ComponentsList extends React.Component {
  constructor(props) {
    super(props);
    this.deleteComposants = this.deleteComposants.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.duplicateComponent = this.duplicateComponent.bind(this);
    this.loadData = this.loadData.bind(this);
    this.importComponents = this.importComponents.bind(this);
    this.filter = this.filter.bind(this);
    this.getCheckIds = this.getCheckIds.bind(this);
    this.createRows = this.createRows.bind(this);
    this.IlimitedQuota = 1000000;
    this.counter = React.createRef(0);

    this.state = {
      ids: [],
      guests: [],
      isLoading: false,
      componentsSystem: [],
      componentsList: [],
      packagesList: [],
      eventResponses: [],
      componentsListFiltered: [],
      nameFilter: "",
      typeFilter: "",
      action: "",
      guestCreatedOnCheckin: 0,
      guestsWhoResponses: [],
    };
  }

  async duplicateComponent(composant) {
    this.setState({ isLoading: true });

    // Empêcher la copie d'un composant d'entrée dans l'interface event
    if (
      composant.data.reponse.role !== "entrance" ||
      !this.props.match.params.eventid
    ) {
      if (
        composant.data &&
        (composant.data.componentType === "moment" ||
          composant.data.componentType === "logistic")
      ) {
        composant.data.quotas = { ...composant.data.quotas, delegataires: [] };
      }

      const component = await ApiService.awaitRequest(
        {
          ...composant,
          data: { ...composant.data, name: composant.data.name + " Copie" },
        },
        "components/",
        "post"
      );
      if (this.props.match.params.eventid) {
        const ids = [];
        this.state.componentsList.map((component) => ids.push(component.id));
        ids.push(component.id);
        this.props.onSave({ component_ids: ids });
      } else this.loadData();
    } else {
      this.loadData();
    }
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async loadData() {
    const self = this;
    self.setState({ isLoading: true });

    if (this.props.match.params.eventid) {
      const eventsDetails = await ApiService.awaitRequest(
        {},
        "evenements/details/" + this.props.match.params.eventid,
        "get"
      );
      await ApiService.request(
        {},
        "guests/byevenement/" + this.props.match.params.eventid,
        "get"
      ).then((res) => {
        self.setState({
          guestCreatedOnCheckin: res
            ? res.filter((guest) =>
              guest.participate
                ? guest.participate === true &&
                guest.created_on_checkin === true
                : ""
            )
            : 0,
        });
      });

      const packagesList = await ApiService.awaitRequest(
        {},
        "packages/byevenement/" + this.props.match.params.eventid,
        "get"
      );

      this.setState({
        ids: [],
        packagesList,
        guests: eventsDetails.guests,
        guestsWhoResponses: eventsDetails.guestsWhoResponded,
        componentsList: eventsDetails.components,
        componentsListFiltered: eventsDetails.components,
        eventResponses: eventsDetails.component_responses,
        isLoading: false,
        action: "",
      });
    } else {
      await ApiService.request({}, "components", "get").then(function (data) {
        self.setState({
          ids: [],
          componentsList: data,
          componentsListFiltered: data,
          isLoading: false,
          action: "",
        });
      });
    }
  }

  async componentDidMount() {
    this.loadData();
  }

  importComponents(importedComponents) {
    const self = this;
    const ids = [];

    this.state.componentsList.forEach((component) => ids.push(component.id));
    const updateDateOfComponent = importedComponents.map((component) => {
      return {
        ...component,
        data: {
          ...component.data,
          endDate: this.props.event.end_date,
          startDate: this.props.event.start_date,
        },
      };
    });

    this.setState({ isLoading: true }, async () => {
      for (const component of updateDateOfComponent) {
        const createdComponent = await ApiService.awaitRequest(
          {
            data: component.data,
            system: false,
            global: false,
          },
          "components/",
          "post"
        );

        ids.push(createdComponent.id);
      }

      self.props.onSave({ ...self.props.event, component_ids: ids });
    });
  }

  async getCheckIds(ids) {
    this.setState({
      ids,
    });
  }

  async deleteComposants(ids, guestsWhoResponded) {
    const self = this;
    self.setState({ isLoading: true });

    let guestsWhoRespondedByComponents = [];

    ids.forEach(async (id) => {
      // Compter le nombre de réponse associées au composant
      guestsWhoRespondedByComponents = guestsWhoResponded.reduce(
        (acc, guest) => {
          if (
            guest.responses &&
            guest.responses.length > 0 &&
            guest.responses.some(
              (componentReponse) =>
                componentReponse.id === id &&
                (
                  (componentReponse.key !== null) ||
                  (componentReponse.key === null && componentReponse.label !== null)
                )
            )

          ) {
            this.counter.current = this.counter.current + 1;
            return [...acc, guest];
          }
          return acc;
        },
        []
      ).length

      if (guestsWhoRespondedByComponents > 0) {
        const cmpName = self.state.componentsListFiltered.find((cmp) => cmp.id === id).data.name;
        toast.info(
          () => (
            <div>
              Impossible de supprimer le composant <strong>{cmpName}</strong>, des réponses lui sont associées.
            </div>
          )
        );
      } else {
        await ApiService.awaitRequest({}, "components/" + id, "delete");
        toast.success("Composant supprimé.");
      }
    })

    // self.props.loadDataEventContainer();
    self.loadData();
  }

  filter() {
    const { componentsList, nameFilter, typeFilter } = this.state;

    let componentsListFiltered = componentsList;

    if (nameFilter.length > 0) {
      componentsListFiltered = componentsListFiltered.filter((invoice) =>
        invoice.data.name.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }

    if (typeFilter.length > 0) {
      componentsListFiltered = componentsListFiltered.filter(
        (component) => component.data.componentType === typeFilter
      );
    }

    this.setState({
      componentsListFiltered,
    });
  }

  createRows(component, listGuestWillParticipateByComponents, eventResponses) {
    let mainArray = [];

    const responseComponent = eventResponses
      // eslint-disable-next-line array-callback-return
      .map((usersResponses) => {
        if (
          usersResponses &&
          usersResponses.responses &&
          usersResponses.responses.length > 0
        ) {
          const componentReponse = usersResponses.responses.find(
            (componentReponse) =>
              componentReponse.id === component.id && componentReponse.key !== 1
          );

          if (componentReponse) {
            return {
              label: componentReponse.label,
              guest_id: usersResponses.guest_id,
            };
          }
        }
      })
      .filter((res) => res);

    if (listGuestWillParticipateByComponents.length > 0) {
      listGuestWillParticipateByComponents.forEach((res) => {
        const guestLabel = responseComponent.find(
          (item) => item.guest_id === res.id
        );

        mainArray.push([
          res.gender,
          res.firstname,
          res.lastname,
          guestLabel ? guestLabel.label : "",
        ]);
      });
    } else {
      return (mainArray = [["", "", "", "", ""]]);
    }

    return mainArray;
  }

  render() {
    const {
      isLoading,
      componentsListFiltered,
      nameFilter,
      typeFilter,
      packagesList,
      eventResponses,
      guests,
      guestsWhoResponses,
      ids,
      action,
    } = this.state;
    if (isLoading) {
      return <Loader />;
    }

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

    const names = [
      { key: "name", value: "Nom" },
      { key: "role", value: "Rôle" },
      { key: "type", value: "Type" },
      { key: "free", value: "€" },
    ];

    if (packagesList.length > 0) {
      names.push({ key: "used", value: "Utilisé" });
      names.push({ key: "guests", value: "Invités" });
      names.push({ key: "participants", value: "Participants" });
    }

    names.push(
      { key: "quota", value: "Places" },
      { key: "date", value: "Date" },
      { key: "actions", value: "Actions" }
    );

    const guestCreatedOnCheckin = guests
      ? guests.filter((guest) =>
        guest.participate
          ? guest.participate === true && guest.created_on_checkin === true
          : false
      )
      : 0;

    const sortable = componentsListFiltered.map((composant) => {
      const result = {};
      let listGuestWillParticipateByComponents = [];
      result.id = composant.id;
      result.name = composant.data.name;
      result.role =
        componentsRolesTranslation["FR"][composant.data.reponse.role];
      result.type =
        componentsTypesTranslation["FR"][composant.data.componentType];
      result.free =
        composant.data.isFree === "false" ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          ""
        );
      if (packagesList.length > 0) {
        const used = packagesList.find((items) =>
          items.components.some((component) => component.id === composant.id)
        );
        result.used = used ? <FontAwesomeIcon icon={faCheck} /> : "";
        let totalGuests = 0;
        guests.forEach((guest) => {
          const guestPackage = packagesList.find(
            (items) =>
              items.components.some(
                (component) => component.id === composant.id
              ) && guest.package_id === items.id
          );
          if (guestPackage) {
            totalGuests++;
          }
        });

        const checkIfPackageEntranceExist = packagesList.find(
          (items) =>
            items.components.some(
              (component) => component.id === composant.id
            ) &&
            componentsRolesTranslation["FR"][composant.data.reponse.role] ===
            "Entrée"
        );

        result.guests = totalGuests;

        listGuestWillParticipateByComponents = guestsWhoResponses.reduce(
          (acc, guest) => {
            if (
              guest.participate &&
              guest.responses &&
              guest.responses.length > 0 &&
              guest.responses.some(
                (componentReponse) =>
                  componentReponse.id === composant.id &&
                  componentReponse.key !== 1 &&
                  componentReponse.key !== null
              )
            ) {
              this.counter.current = this.counter.current + 1;
              return [...acc, guest];
            }
            return acc;
          },
          []
        );

        result.participants = listGuestWillParticipateByComponents.length;

        this.counter.current = 0;
        if (checkIfPackageEntranceExist) {
          result.guests = result.guests + guestCreatedOnCheckin.length;
          // result.participants = guestWillParticipate.length;
          // listGuestWillParticipateByComponents = guestWillParticipate;
        }
      }
      result.quota =
        composant.data.componentType === "logistic" ||
          composant.data.componentType === "moment"
          ? composant.data.quotas &&
            composant.data.quotas.total === this.IlimitedQuota
            ? "Illimité"
            : composant.data.quotas
              ? composant.data.quotas.total
              : ""
          : "";

      result.date = formatDate(composant.data.startDate, true);

      // Export des données des participants par composant
      const columns = ["Civilité", "Prénom", "Nom", "Réponse reçue"];
      const data = this.createRows(
        composant,
        listGuestWillParticipateByComponents,
        eventResponses
      );
      const multiDataSet = [
        {
          columns,
          data,
        },
      ];
      // Modification nom de fichier généré
      const localDate = new Date().toLocaleDateString("fr-FR");
      const localTime = new Date().toLocaleTimeString("fr-FR");
      const excelFileName =
        "Export-" +
        composant.data.name +
        " le " +
        localDate +
        " à " +
        localTime;

      result.actions = (
        <>
          <Link
            to={
              this.props.match.params.eventid
                ? "/admin/events/edit/" +
                this.props.match.params.eventid +
                "/components/edit/" +
                composant.id
                : "/admin/components/edit/" + composant.id
            }
          >
            <FontAwesomeIcon icon={faEdit} />
          </Link>
          &nbsp; &nbsp;
          {composant.data.reponse.role !== "entrance" ||
            !this.props.match.params.eventid ? (
            <FontAwesomeIcon
              onClick={() => this.duplicateComponent(composant)}
              icon={faCopy}
            />
          ) : (
            ""
          )}
          &nbsp; &nbsp;
          {composant.data.componentType === "logistic" ||
            composant.data.componentType === "moment" ? (
            <ExcelFile
              filename={excelFileName}
              element={<FontAwesomeIcon icon={faFileExport} />}
            >
              <ExcelSheet
                dataSet={multiDataSet}
                name="Export des données par composant"
              />
            </ExcelFile>
          ) : (
            ""
          )}
        </>
      );

      return { ...result };
    });

    return (
      <div className="row">
        <div className="col-md-11 offset-md-1 main-content">
          <div className="row">
            {this.props.match.params.eventid ? (
              <div className="col-md-2 offset-md-8">
                <ImportComponents
                  onValidate={this.importComponents}
                  componentsList={this.state.componentsList}
                />
              </div>
            ) : null}
            <div
              className={
                this.props.match.params.eventid
                  ? "col-md-2"
                  : "col-md-2 offset-md-10"
              }
            >
              <Link
                className="btn pink darken-1 white-text "
                to={
                  this.props.match.params.eventid
                    ? "/admin/events/edit/" +
                    this.props.match.params.eventid +
                    "/components/edit/"
                    : "/admin/components/edit"
                }
              >
                Nouveau Composant
              </Link>
            </div>
          </div>
          <div className={"white filters"}>
            <div className="row">
              <div className="col-md-3">
                <MDBInput
                  type="text"
                  value={nameFilter}
                  name="nameFilter"
                  label={"Rechercher par nom (4 lettres minimum)"}
                  onChange={(e) => filterElements(e, "nameFilter", this, false)}
                />
              </div>
              <div className="col-md-2">
                <div className="md-form">
                  <select
                    required
                    onChange={(e) =>
                      filterElements(e, "typeFilter", this, true)
                    }
                    name="typeFilter"
                    className="form-control browser-default custom-select"
                    value={typeFilter}
                  >
                    <option value={""}>Filtrer par type</option>
                    <option value="display">
                      {componentsTypesTranslation["FR"].display}
                    </option>
                    <option value="request">
                      {componentsTypesTranslation["FR"].request}
                    </option>
                    <option value="logistic">
                      {componentsTypesTranslation["FR"].logistic}
                    </option>
                    <option value="moment">
                      {componentsTypesTranslation["FR"].moment}
                    </option>
                    <option value="map">
                      {componentsTypesTranslation["FR"].map}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <br />
          <div className="component-table">
            <Sortable
              getCheckIds={this.getCheckIds}
              checkable={true}
              names={names}
              sortable={sortable}
            />
          </div>
          {ids.length > 0 ? (
            <div className="row mr-0 ml-0 actions-container actions-container-fixed justify-content-between align-items-center">
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    {ids.length} composant
                    {ids.length > 1 ? "s" : null}
                    &nbsp;sélectionné
                    {ids.length > 1 ? "s" : null}
                  </label>
                  <select
                    onChange={this.handleChange}
                    name="action"
                    className="col-sm-12 browser-default custom-select"
                    value={action}
                    required
                  >
                    <option value="">Sélectionner une action groupée</option>
                    <option value="delete">Supprimer</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4 offset-md-3">
                <button
                  type="submit"
                  disabled={action !== "delete"}
                  onClick={() => this.deleteComposants(ids, guestsWhoResponses)}
                  className="btn white-text pink darken-1"
                >
                  Valider
                </button>
              </div>
              <div
                className="col-md-1 align-self-start"
                style={{ maxWidth: "fit-content" }}
              >
                <FontAwesomeIcon
                  title={"Fermer"}
                  icon={faTimes}
                  onClick={() => {
                    this.getCheckIds([]);
                  }}
                  color="white"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ComponentsList;
