import React from "react";
import { Button } from "mdbreact";
import ReactExport from "react-export-excel";

class ExportGuestsSimple extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: this.props.contacts.length > 0 ? false : true,
      event: [],
      placementsList: [],
      nameEvent: this.props.nameEvent,
      namePlacement: this.props.namePlacement,
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  createRow(guest) {
    const { contacts, data } = this.props;
    const contact = contacts.find((contact) => contact.id === guest.contact_id);
    const seat = data.seat.find((seat) => seat.guestId === guest.id);
    const idSplitZone = seat.seatId.substr(0, seat.seatId.indexOf("_r"));
    const idSplitRanger = seat.seatId.substring(
      seat.seatId.lastIndexOf("r") + 1,
      seat.seatId.lastIndexOf("_p")
    );
    const idSplitPlace = seat.seatId.split("_")[3] // Permet de supprimer le _i à l'affichage s'il existe
      ? seat.seatId.substring(
          seat.seatId.lastIndexOf("p") + 1,
          seat.seatId.lastIndexOf("_")
        )
      : seat.seatId.substring(
          seat.seatId.lastIndexOf("p") + 1,
          seat.seatId.lastIndexOf("")
        );

    const invitedBy = guest.is_followers
      ? guest.invited_by
        ? guest.invited_by.id
        : ""
      : "";

    const guestData = [
      contact ? (contact.company ? contact.company.name : "") : "",
      guest.lastname,
      guest.firstname,
      idSplitZone,
      idSplitRanger,
      idSplitPlace,
      guest.id,
      invitedBy,
    ];

    return guestData;
  }

  render() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const { nameEvent, namePlacement } = this.state;
    console.log(this.state);
    const columns = [
      "Société",
      "Nom",
      "Prénom",
      "Zone",
      "Rang",
      "Place",
      "guest ID",
      "Invité par",
    ];

    const data = [];

    this.props.guests.forEach((guest) => {
      data.push(this.createRow(guest));
    });

    const multiDataSet = [
      {
        columns,
        data,
      },
    ];

    const { isLoading } = this.state;
    const date = new Date();

    return (
      <ExcelFile
        filename={`${nameEvent}_listeplan_${namePlacement}_${date.getDate()}_${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}_${date.getFullYear()}`}
        element={
          <div className="button-container">
            <Button disabled={isLoading} className="btn pink darken-1">
              LISTE
            </Button>
          </div>
        }
      >
        <ExcelSheet dataSet={multiDataSet} name="Export placement" />
      </ExcelFile>
    );
  }
}

export default ExportGuestsSimple;
