import React from "react";
import { MDBPageItem, MDBPageNav } from "mdbreact";

export const Pagination = (props) => {
  const pagination = [];

  for (let i = 0; i < props.totalPages; i++) {
    if (
      (i >= props.currentPage && props.currentPage - i >= -2) ||
      (i <= props.currentPage && props.currentPage - i <= 2) ||
      i + 1 === props.totalPages ||
      i === 0
    )
      pagination.push(
        <MDBPageItem
          key={`pagination-item-${i}`}
          active={props.currentPage === i}
          onClick={() => props.changePage(i)}
        >
          <MDBPageNav>{i + 1}</MDBPageNav>
        </MDBPageItem>
      );
  }

  return (
    <div className="row  pl-4 pr-4  pagination">
      {pagination.map((page) => page)}
    </div>
  );
};

export default Pagination;
