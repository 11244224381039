import React from "react";
import ApiService from "../../../../services/ApiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../../../common/Loader";
import { Link } from "react-router-dom";
import { faTrashAlt, faPencilRuler } from "@fortawesome/free-solid-svg-icons";
import PlacementConfigForm from "./PlacementConfigForm";

class PlacementsList extends React.Component {
  constructor(props) {
    super(props);
    this.deletePlacement = this.deletePlacement.bind(this);
    this.loadData = this.loadData.bind(this);

    this.state = {
      placementsList: [],
      components: [],
      packages: [],
      isLoading: true,
    };
  }
  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const self = this;

    await ApiService.request(
      {},
      "components/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      self.setState({
        components: data.filter(
          (component) =>
            component.data.componentType === "logistic" ||
            component.data.componentType === "moment"
        ),
      });
    });

    await ApiService.request(
      {},
      "placements/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      self.setState({
        placementsList: data,
      });
    });

    await ApiService.request(
      {},
      "packages/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      self.setState({
        packages: data,
        isLoading: false,
      });
    });
  }

  async deletePlacement(id) {
    const self = this;
    await ApiService.request({}, "placements/" + id, "delete").then(
      function () {
        self.loadData();
      }
    );
  }

  render() {
    const { placementsList, isLoading, components, packages } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <div className="row">
            <div className={"col-md-10"}>
              <h2>Liste des placements</h2>
            </div>

            <div className="col-md-2">
              <PlacementConfigForm
                {...this.props}
                components={components}
                packages={packages}
                loadData={this.loadData}
                event={this.props.event}
              />
            </div>
          </div>
          <br />
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th scope="col">Nom</th>
                <th scope="col">Nombre de places</th>
                <th scope="col">Composants</th>
                <th scope="col">Packages</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {placementsList.map((placement) => (
                <tr key={placement.id}>
                  <td>{placement.name}</td>
                  <td>{placement.available_seat}</td>
                  <td>
                    {placement.data.components.map((id, index) => {
                      const component = components.find(
                        (component) => component.id === id
                      );
                      if (component)
                        return (
                          <p key={`key-composant-name-${id}`}>
                            {component.data.name}
                          </p>
                        );
                      return null;
                    })}
                  </td>
                  <td>
                    {placement.data.packages
                      ? placement.data.packages.map((id, index) => {
                          const pack = packages.find((pack) => pack.id === id);
                          if (pack)
                            return (
                              <p key={`key-composant-name-${pack.id}`}>
                                {pack.name}
                              </p>
                            );
                          return null;
                        })
                      : null}
                    {/* fhehfze */}
                  </td>
                  <td>
                    &nbsp;&nbsp;&nbsp;
                    <PlacementConfigForm
                      {...this.props}
                      components={components}
                      packages={packages}
                      loadData={this.loadData}
                      event={this.props.event}
                      placement={placement}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <Link
                      to={
                        "/admin/events/edit/" +
                        this.props.event.id +
                        "/placement/" +
                        placement.id
                      }
                    >
                      <FontAwesomeIcon icon={faPencilRuler} />
                    </Link>
                    &nbsp;&nbsp;&nbsp;
                    <span
                      className="pointer"
                      onClick={() => {
                        if (
                          window.confirm(
                            `Etes vous sûr de supprimer le placement '${
                              placement ? placement.name : null
                            }' ?`
                          )
                        )
                          this.deletePlacement(placement.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default PlacementsList;
