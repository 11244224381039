import React from "react";

import ApiService from "../../../../services/ApiService";
import Loader from "../../../../common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@mui/material";
import { CustomMuiButton } from "../../components/CustomMuiButton";
import ModalCheckinComponent from "./ModalCheckinComponent";
import CheckinTableFilter from "./CheckinTableFilter";
import { CustomTable } from "../../../../common/table_component/CustomTable";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import CheckinActions from "./CheckinActions";

const CheckinComponentMain = (props) => {
  const [componentsList, setComponentsList] = React.useState([]);
  const [placementsList, setPlacementList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const [checked, setChecked] = React.useState([]);
  const [currentCheckinPoint, setCurrentCheckinPoint] = React.useState({});
  const [checkinPoints, setCheckinPoints] = React.useState([]);
  const [filteredCheckinPoints, setFilteredCheckinPoints] = React.useState(
    props.checkinPoints
  );

  // Récupérer la liste des composants et des placements
  const fetchData = async () => {
    try {
      const components = await ApiService.awaitRequest(
        {},
        "components/byevenement/" + props.match.params.eventid,
        "get"
      );

      const componentsList = components
        .filter((component) => {
          return component.data
            ? component.data.componentType === "logistic" ||
                component.data.componentType === "moment"
            : "";
        })
        .map((component) => ({
          id: component.id,
          name: component.data.name,
        }));

      const placements = await ApiService.awaitRequest(
        {},
        "placements/byevenement/" + props.match.params.eventid,
        "get"
      );

      const placementsList = placements.map((place) => ({
        id: place.id,
        name: place.name,
      }));

      setComponentsList(componentsList);
      setPlacementList(placementsList);
      setCheckinPoints(props.checkinPoints);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch data", error);
    }
  };

  const toggle = () => {
    setModal((prevModal) => !prevModal);
    setCurrentCheckinPoint({ name: "", components: [], placements: [] });
  };

  const editCheckin = (checkin) => {
    setCurrentCheckinPoint(checkin);
    setModal((prevModal) => !prevModal);
  };

  const onSubmit = async (e, newCheckin) => {
    e.preventDefault();
    setIsLoading(true);

    // Si l'on modifie un point de checkin
    if (newCheckin.id) {
      const updatedCheckinPoints = checkinPoints.map((check) =>
        check.id === newCheckin.id ? { ...check, ...newCheckin } : check
      );

      const eventCopy = {
        ...props.event,
        checkin_points: updatedCheckinPoints,
      };

      await props.onSave(eventCopy, "Point de checkin sauvegardé.");
    } else {
      // Si l'on veut créer un point de checkin
      try {
        await ApiService.awaitRequest(
          newCheckin,
          "evenements/checkinPoint/" + props.match.params.eventid,
          "post"
        );
        props.loadEvent();
        toast.success("Point de checkin ajouté.");
      } catch (error) {
        console.error("Failed to fetch data", error);
        toast.success("Une erreur est survenue.");
      }
    }
  };

  const deleteCheckin = async (ids) => {
    const updatedCheckinPoints = checkinPoints.filter(
      (check) => !ids.includes(check.id)
    );
    const eventCopy = { ...props.event, checkin_points: updatedCheckinPoints };
    await props.onSave(eventCopy, "Point de checkin supprimé.");
  }

  React.useEffect(() => {
    fetchData();
  }, [props.event]);

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "NOM",
    },
    {
      id: "components",
      numeric: false,
      disablePadding: false,
      label: "COMPOSANT",
    },
    {
      id: "placements",
      numeric: false,
      disablePadding: false,
      label: "PLACEMENTS",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "ACTIONS",
    },
  ];

  const StyledTableCell = styled(TableCell)(
    ({ theme, textcolor = "black" }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: "red",
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: textcolor,
        // backgroundColor: 'white'
      },
    })
  );

  const StyledTableRow = styled(TableRow)(({ theme, backgroundcolor }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: backgroundcolor || "white",
    },
    "&:nth-of-type(even)": {
      backgroundColor: backgroundcolor || "white",
    },

    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const createGuestRow = (data) => {
    return data.map((checkin, index) => {
      const associatedComponents = checkin.components.map((cmp) =>
        componentsList.find((cmpList) => cmp.id === cmpList.id)
      );

      const associatedComponentsText = associatedComponents.map((comp, key) => {
        return (
          <p className="m-0" key={key}>
            {comp.name}
          </p>
        );
      });

      const associatedPlacements = checkin.placements.map((place) =>
        placementsList.find((pList) => place.id === pList.id)
      );

      const associatedPlacementsText = associatedPlacements.map((p, key) => {
        return (
          <p className="m-0" key={key}>
            {p.name}
          </p>
        );
      });

      const actions = (
        <>
          <IconButton
            size="small"
            onClick={() => {
              if (
                window.confirm(
                  "Des invités ont peut-être été validés sur ce point de check-in. Êtes-vous sûr de vouloir le supprimer ?"
                )
              )
                deleteCheckin([checkin.id]);
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} color="#141414" />
          </IconButton>
          &nbsp;&nbsp;
          <IconButton
            size="small"
            onClick={() => {
              editCheckin(checkin);
            }}
          >
            <FontAwesomeIcon icon={faEdit} color="#141414" />
          </IconButton>
        </>
      );

      return {
        id: checkin.id,
        name: checkin.name,
        associatedComponentsText,
        associatedPlacementsText,
        actions,
      };
    });
  };

  const populateRows = (isSelected, handleClick, visibleRows) => {
    return visibleRows.map((row, index) => {
      const isItemSelected = isSelected(row.id);
      const labelId = `enhanced-table-checkbox-${index}`;

      return (
        <React.Fragment key={row.id}>
          <StyledTableRow
            backgroundcolor={row.backgroundColor}
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
          >
            <StyledTableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={isItemSelected}
                onClick={(event) => handleClick(event, row.id)}
                inputProps={{
                  "aria-labelledby": labelId,
                  style: { width: "0px" },
                }}
                sx={{
                  color: "#626262",
                  "&.Mui-checked": {
                    color: "#d81b60",
                  },
                }}
              />
            </StyledTableCell>
            <StyledTableCell component="th" id={labelId} scope="row">
              {row.name}
            </StyledTableCell>

            <StyledTableCell align="left">
              {row.associatedComponentsText}
            </StyledTableCell>
            <StyledTableCell align="left">
              {row.associatedPlacementsText}
            </StyledTableCell>
            <StyledTableCell align="left">{row.actions}</StyledTableCell>
          </StyledTableRow>
        </React.Fragment>
      );
    });
  };

  return !isLoading ? (
    <div className="px-5">
      <div className="d-flex justify-content-between my-5">
        <div className="col-md-4 p-0">
          <h3>Points de checkin</h3>
        </div>
        <div className="col-md-4 p-0">
          <CustomMuiButton
            type="button"
            text="Ajouter un checkin"
            onClick={() => toggle()}
            minHeight={60}
            textColor="#ffffff"
            buttonBgColor="#d81b60"
            buttonHoverBgColor="#AF1242"
            buttonHoverBorderColor="#0062cc"
            buttonActiveBgColor="#d81b60"
          />
        </div>
      </div>
      <CheckinTableFilter
        checkinPoints={checkinPoints}
        componentsList={componentsList}
        placementsList={placementsList}
        setFilteredCheckinPoints={setFilteredCheckinPoints}
      />
      <CustomTable
        data={filteredCheckinPoints}
        createTableRows={createGuestRow}
        selected={checked}
        setSelected={setChecked}
        populateRows={populateRows}
        headCells={headCells}
        activateCheckbox={true}
      />

        <ModalCheckinComponent
          modal={modal}
          toggle={toggle}
          currentCheckinPoint={currentCheckinPoint}
          componentsList={componentsList}
          placementsList={placementsList}
          onSubmit={onSubmit}
        />

        <CheckinActions
          checked={checked}
          setChecked={setChecked}
          deleteCheckin={deleteCheckin}
        />

      </div>
    ) : (
      <Loader />
    )
};

export default CheckinComponentMain;
