import React from "react";
import PhoneInput from "react-phone-input-2";
// import { mydebounce, useDebounce } from "../../../services/Utils";
import { removeDialCodeFromNumber, verifyNumber } from "../events/Sms/utils";

// function preprocessPhoneNumber(phoneNumber, defaultCountryCode) {
//   if (phoneNumber && !phoneNumber.startsWith("+")) {
//     return `${defaultCountryCode}${phoneNumber}`;
//   }
//   return phoneNumber;
// }

function PhoneNumberInput({
  parentPhoneNumber,
  setParentPhoneNumber,
  countryDetails,
  setParentPhoneNumberError,
}) {
  const [phoneNumber, setPhoneNumber] = React.useState(parentPhoneNumber || "");
  const [phoneNumberError, setPhoneNumberError] = React.useState(false);
  const checkPhoneNumberFormat = (number, country) => {
    if (!country) {
      setPhoneNumberError(true);
      setParentPhoneNumberError(true);
      return;
    }

    let parsedNumber = removeDialCodeFromNumber(number, country.dialCode);
    if (parsedNumber) {
      const numberStatus = verifyNumber(
        parsedNumber,
        country.countryCode.toUpperCase()
      );

      if (numberStatus) {
        //Valid
        setPhoneNumberError(false);
        setParentPhoneNumberError(false);
        return;
      } else {
        //Invalid
        setPhoneNumberError(true);
        setParentPhoneNumberError(true);
        return;
      }
    }
    //Invalid
    if (parsedNumber === "" || number === "") {
      setPhoneNumberError(false);
      setParentPhoneNumberError(false);
    } else {
      setPhoneNumberError(true);
      setParentPhoneNumberError(true);
    }
  };

  React.useEffect(() => {
    const parsedNumber = verifyNumber(phoneNumber);

    if (parsedNumber) {
      setPhoneNumber(parsedNumber)
      return;
    }
    if (phoneNumber) {
      checkPhoneNumberFormat(phoneNumber, countryDetails.current);
    }

    if (!parsedNumber) {
      if (typeof phoneNumber === "string" && phoneNumber[0] === "0") {
        setPhoneNumber(phoneNumber.slice(1));
      }
    }
  }, []);

  return (
    <div>
      <PhoneInput
        country={"fr"}
        value={phoneNumber}
        onChange={(phone, data) => {
          console.log("🚀 ~ phone:", phone);
          setPhoneNumber("+" + phone);
          setParentPhoneNumber("+" + phone);
          countryDetails.current = data;
          checkPhoneNumberFormat(phone, data);
        }}
        placeholder="Entrez votre numéro"
        searchPlaceholder="rechercher..."
        dropdownStyle={{
          zIndex: 100,
        }}
        inputProps={{
          name: "phone",
          required: true,
        }}
        preferredCountries={["fr", "gb", "ie", "de"]}
        preserveOrder={["preferredCountries"]}
        autoFormat={true}
        enableSearch={true}
        countryCodeEditable={false}
        // disableInitialCountryGuess={true}
        // disableCountryGuess={true}
      />
      {phoneNumberError && (
        <small style={{ color: "red", opacity: "0.7" }}>
          *Veuillez entrer votre numéro au bon format
        </small>
      )}
    </div>
  );
}

export default PhoneNumberInput;
