import React from "react";
import {
  MDBInput,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "mdbreact";

import ApiService from "../../../services/ApiService";

import { translateComponentType } from "../../../common/Translations";

class ImportComponents extends React.Component {
  constructor(props) {
    super(props);
    this.filterComponentsList = this.filterComponentsList.bind(this);
    this.check = this.check.bind(this);
    this.validate = this.validate.bind(this);
    this._isMounted = false;

    this.state = {
      modal: false,
      filter_selected: "",
      filter_text: "",
      componentsChecked: [],
      componentsList: [],
      hasEntranceComponent: false,
    };
  }

  async componentDidMount() {
    const self = this;
    this._isMounted = true;
    await ApiService.request({}, "components", "get").then(function (data) {
      self._isMounted &&
        self.setState({
          componentsList: data.filter((component) => component.system === true),
          isLoading: false,
        });
    });

    const existingEntranceComponent = this.props.componentsList.find(
      (component) => component.data.reponse.role === "entrance"
    );
    this._isMounted &&
      self.setState({
        hasEntranceComponent: existingEntranceComponent ? true : false,
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  filterComponentsList(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  check(event, newComponent) {
    const componentsChecked = this.state.componentsChecked;

    if (!event.target.checked) {
      this.setState({
        componentsChecked: componentsChecked.filter(
          (component) => component.id !== newComponent.id
        ),
      });
    } else {
      const component = componentsChecked.filter(
        (component) => component.id === newComponent.id
      );
      if (component.length === 0) {
        componentsChecked.push(newComponent);
        this.setState({
          componentsChecked,
        });
      }
    }
  }

  validate() {
    this.props.onValidate(this.state.componentsChecked);
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  disabledComponentEntrance = (component) => {
    if (this.state.hasEntranceComponent) {
      if (component.data.reponse.role === "entrance") {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };
  render() {
    return (
      <div>
        <Button
          className="btn pink darken-1  white-text "
          onClick={this.toggle}
          disabled={this.state.componentsList.length === 0}
        >
          Importer un composant
        </Button>
        <Modal
          isOpen={this.state.modal}
          fullHeight
          position="right"
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle}> Importer un composant</ModalHeader>
          <ModalBody>
            <input
              className="form-control mb-1"
              type="text"
              name="filter_text"
              onChange={this.filterComponentsList}
              placeholder="Rechercher un composant"
            />

            <select
              name="filter_selected"
              className="form-control browser-default custom-select"
              onChange={this.filterComponentsList}
              value={this.state.filter_selected}
            >
              <option value="">Tous les types</option>
              <option value="display">
                {translateComponentType("display")}
              </option>
              <option value="request">
                {" "}
                {translateComponentType("request")}
              </option>
              <option value="logistic">
                {" "}
                {translateComponentType("logistic")}
              </option>
              <option value="moment">
                {" "}
                {translateComponentType("moment")}
              </option>
            </select>

            {this.state.componentsList.map((component) =>
              (component.data.componentType === this.state.filter_selected ||
                this.state.filter_selected === "") &&
              component.data.name
                .toLowerCase()
                .indexOf(this.state.filter_text.toLowerCase()) > -1 ? (
                <MDBInput
                  label={
                    component.data.name +
                    " (" +
                    translateComponentType(component.data.componentType) +
                    ")"
                  }
                  onClick={(e) => this.check(e, component)}
                  filled
                  id={`id-${component.id}`}
                  key={`key-${component.id}`}
                  type="checkbox"
                  disabled={this.disabledComponentEntrance(component)}
                />
              ) : null
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn pink darken-1 btn-lg white-text "
              onClick={this.validate}
            >
              Valider
            </Button>
            <Button
              className="btn pink darken-1 btn-lg white-text "
              onClick={this.toggle}
            >
              Fermer
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ImportComponents;
