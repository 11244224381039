import React from "react";
import ApiService from "../../../services/ApiService";
import { webPageTranslations } from "../../../common/Translations";
// import ExportGuestsSimple from "../../../common/ExportGuestsSimple";
import { findLabelAndColor } from "../../../services/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleDown,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../common/Loader";
import ExportLine from "./ExportLine";
import UploadFileModal from "../../../admin/_modules/events/UploadFileModal";
import { Button } from "mdbreact";

class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      line: [],
      guests: [],
      eventIdSelected: this.props.eventId ? this.props.eventId : "",
      eventResponses: [],
      subContactsToShow: [this.props.contact.id],
      lengthsParticipate: 0,
      lengthsTotal: 0,
      guestByEvent: "",
      followersGuest: [],
    };
    this.handleChange = this.handleChange.bind(this);

    this.loadData = this.loadData.bind(this);
    this.renderContactLine = this.renderContactLine.bind(this);
    this.getParticipateLength = this.getParticipateLength.bind(this);
    this.openModelUploadFile = this.openModelUploadFile.bind(this);
  }
  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.eventId !== this.props.eventId ||
      prevProps.events !== this.props.events
    ) {
      this.setState({ eventIdSelected: this.props.eventId });
      this.loadData();
    }
  }

  async loadData() {
    const self = this;
    const { contact } = this.props;
    const eventId = this.state.eventIdSelected;

    this.setState({ isLoading: true });
    if (eventId) {
      await ApiService.request(
        {},
        "evenement_forms/byevenement/" + eventId,
        "get"
      ).then(async function (data) {
        const form = data.find(
          (form) =>
            form.evenement_id === Number(eventId) &&
            form.layout.documentType === "reponse"
        );

        await ApiService.request(
          {},
          "component_responses/byevenement/" + form.id,
          "get"
        ).then(function (data) {
          self.setState({
            eventResponses: data,
          });
        });
      });

      await ApiService.request({}, "guests/byevenement/" + eventId, "get").then(
        function (data) {
          const currentGuest = data.find(
            (guest) => guest.contact_id === contact.id
          );
          const followers = data.filter((guest) => guest.is_followers);
          self.setState({
            guests: data,
            guestByEvent: currentGuest,
            followersGuest: followers,
          });
        }
      );

      await ApiService.request(
        {},
        "contacts/line/" + eventId + "/0",
        "get"
      ).then(function (lines) {
        const line = lines.filter((element) => element.active === true);
        self.setState({
          line: self.findRelatedContacts(self.props.contact.id, line),
          isLoading: false,
        });
      });
    } else {
      self.setState({
        line: [],
        guests: [],
        eventResponses: [],
        subContactsToShow: [this.props.contact.id],
        isLoading: false,
      });
    }
    this.getParticipateLength();
  }

  findRelatedContacts(id, contacts) {
    const contactsFiltered = [];
    contacts.forEach((contact) => {
      if (contact.related_to === id) {
        contactsFiltered.push(contact);
        contactsFiltered.push(
          ...this.findRelatedContacts(contact.id, contacts)
        );
      }
    });
    return contactsFiltered;
  }

  async handleChange(event) {
    const eventId = event.target.value;
    const self = this;
    this.setState(
      {
        [event.target.name]: eventId,
      },
      () => self.loadData()
    );
  }

  showHideSubLine(contactId) {
    const idToShow = this.state.subContactsToShow.find(
      (id) => id === contactId
    );
    if (!idToShow) {
      const subContactsToShow = this.state.subContactsToShow;

      subContactsToShow.push(contactId);

      this.setState({
        subContactsToShow,
      });
    } else {
      this.setState({
        subContactsToShow: this.state.subContactsToShow.filter(
          (id) => id !== contactId
        ),
      });
    }
  }

  lineByContactId(headContact) {
    return this.state.line.filter(
      (contact) => contact.related_to === headContact.id
    );
  }

  getParticipateLength() {
    const lineParticipate = this.state.line.filter(
      (li) => li.participate === true && li.active === true
    );
    this.setState({
      lengthsTotal: this.state.line.filter((li) => li.active === true).length,
      lengthsParticipate: lineParticipate.length,
    });
  }

  renderContactLine(lineHead) {
    let paddingleft = 0;
    if (lineHead.id !== this.props.contact.id) {
      let currentContact = lineHead;

      do {
        /*eslint-disable */
        const parent =
          this.props.contact.id === currentContact.related_to
            ? this.props.contact
            : this.state.line.find(
                (contact) => contact.id === currentContact.related_to
              );
        /*eslint-enable */

        currentContact = parent;

        paddingleft = paddingleft + 25;
      } while (currentContact.id !== this.props.contact.id);
    }
    const returnLines = this.lineByContactId(lineHead).map((lineContact) => {
      const currentGuest = this.state.guests.find(
        (guest) => guest.contact_id === lineContact.id
      );
      const followers = currentGuest
        ? this.state.guests.filter((guest) =>
            guest.invited_by ? guest.invited_by.id === currentGuest.id : false
          )
        : [];

      return (
        <>
          <tr>
            <td style={{ paddingLeft: paddingleft + "px" }}>
              {lineContact.lastname + " " + lineContact.firstname}
            </td>
            <td>
              {this.lineByContactId(lineContact).length}&nbsp;
              {this.lineByContactId(lineContact).length > 0 ? (
                <FontAwesomeIcon
                  onClick={() => this.showHideSubLine(lineContact.id)}
                  icon={
                    this.state.subContactsToShow.find(
                      (id) => id === lineContact.id
                    )
                      ? faChevronCircleDown
                      : faChevronCircleRight
                  }
                />
              ) : null}
            </td>
            <td
              style={{
                backgroundColor: findLabelAndColor(
                  lineContact,
                  this.props.contact.languages,
                  this.state.eventResponses
                ).backgroundColor,
              }}
            >
              {
                findLabelAndColor(
                  lineContact,
                  this.props.contact.languages,
                  this.state.eventResponses
                ).label
              }
            </td>
            <td>{followers.length}</td>
          </tr>
          {this.state.subContactsToShow.find((id) => id === lineContact.id)
            ? this.renderContactLine(lineContact)
            : null}
        </>
      );
    });

    return returnLines;
  }
  async openModelUploadFile() {
    const open = !this.state.openModal;
    this.setState({ openModal: open });
  }
  render() {
    const {
      isLoading,
      eventIdSelected,
      line,
      guests,
      eventResponses,
      lengthsParticipate,
      lengthsTotal,
      openModal,
      guestByEvent,
      followersGuest,
    } = this.state;
    const { events, contact } = this.props;
    const code = contact.languages;
    const contactCode = code ? code : "FR";
    if (isLoading) {
      return <Loader />;
    }

    return (
      <>
        <div className="row account-sub-title">
          <div className="col-md-12 text-center">
            {webPageTranslations[contactCode].my_team}
          </div>
        </div>

        <br />
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="form-row">
              <div className="form-group col-md-6" style={{ marginTop: 10 }}>
                <select
                  required
                  onChange={this.handleChange}
                  name="eventIdSelected"
                  className="form-control browser-default custom-select"
                  value={eventIdSelected}
                >
                  <option key="team-event-selection" value="">
                    {webPageTranslations[contactCode].select_event}
                  </option>
                  {events.map((option, index) => (
                    <option
                      key={`team-event-selection-${index}`}
                      value={option.id}
                    >
                      {option.data.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="rowFlex">
                {eventIdSelected ? (
                  <div className="subs">
                    Inscrits : {lengthsParticipate} / {lengthsTotal}
                  </div>
                ) : null}
                <div className="col-md-2 offset-md-2">
                  {eventIdSelected ? (
                    <ExportLine
                      eventResponses={eventResponses}
                      code={contactCode}
                      line={line}
                      guests={guests}
                      contact={contact}
                      checkEventFromAdmin={this.props.checkEventFromAdmin}
                    />
                  ) : null}
                </div>
                <div className="col-md-2 offset-md-2">
                  {eventIdSelected ? (
                    <Button
                      className="btn pink darken-1 "
                      onClick={() => this.openModelUploadFile()}
                    >
                      {"Documents"}
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
            <br />
            {openModal ? (
              <UploadFileModal
                open={openModal}
                setModal={this.openModelUploadFile}
                guest={guestByEvent}
                followers={followersGuest.filter(
                  (follower) =>
                    follower.invited_by.contact_id === guestByEvent.contact_id
                )}
                isAccountGuest
              />
            ) : null}
            <div className="form-row">
              {events.some(
                (event) =>
                  event.id === Number(eventIdSelected) &&
                  event.data.maintenanceMode
              ) ? (
                <div className="col-12">
                  {webPageTranslations[contactCode].maintenance_mode}
                </div>
              ) : (
                <table className="table table-sm table-striped">
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "white",
                      }}
                    >
                      <th scope="col">
                        {webPageTranslations[contactCode].team_member_name}
                      </th>
                      <th scope="col">
                        {webPageTranslations[contactCode].team_members}
                      </th>
                      <th scope="col">
                        {webPageTranslations[contactCode].will_join}
                      </th>
                      <th scope="col">
                        {webPageTranslations[contactCode].guests_number}
                      </th>
                    </tr>
                  </thead>
                  <tbody>{this.renderContactLine(contact)}</tbody>
                </table>
              )}
            </div>
          </div>

          <br />
        </div>
      </>
    );
  }
}

export default Events;
