import React from "react";
import {
  Box,
  Paper,
  InputAdornment,
  TextField,
  IconButton,
  MenuItem,
  Grid,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

function FilterGuests({
  query,
  setQuery,
  setQueryLoading,
  joinFilter,
  setJoinFilter,
  guests,
  isGuestChecked,
  checkinPointId,
}) {
  return (
    <Box>
      <Paper
        sx={{
          padding: "0.5rem 1.5rem 1.5rem 1.5rem",
          borderRadius: 0,
          backgroundColor: "#F2F2F2",
        }}
      >
        <Grid container spacing={2} alignItems="end">
          <Grid item xs={12} sm={6}>
            <TextField
              id="search-guest-field"
              label="Rechercher"
              placeholder="rechercher..."
              variant="standard"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                setQueryLoading(true);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setQuery("")}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#D23466",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            display="flex"
            alignItems="center"
            gap="1rem"
          >
            <Typography fontWeight={500}>Participants</Typography>
            <TextField
              id="standard-select-currency"
              select
              value={joinFilter}
              onChange={(e) => setJoinFilter(e.target.value)}
              variant="standard"
              sx={{
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#D23466",
                },
              }}
            >
              <MenuItem value="all">
                Attendus (
                {guests
                  ? guests.filter((guest) => guest.participate).length
                  : 0}
                )
              </MenuItem>
              <MenuItem value="checkedin">
                Venus (
                {
                  //TODO: UPDATE
                  guests.filter((guest) =>
                    isGuestChecked(guest, checkinPointId)
                  ).length
                }
                )
              </MenuItem>
              <MenuItem value="pending">
                Non venus (
                {
                  //TODO: UPDATE
                  guests.filter(
                    (guest) => !isGuestChecked(guest, checkinPointId)
                  ).length
                }
                )
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default FilterGuests;
