import React, { useEffect, useState } from "react";
import ApiService from "../../../services/ApiService";
import { Button, CircularProgress, Typography } from "@mui/material";
import JSZip from "jszip";
import { generateInvoice } from "./generateInvoicePdf";
import { saveAs } from "file-saver";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import Loader from "../../../common/Loader";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function RgpdView() {
  const [invoices, setInvoices] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);

  useEffect(() => {
    const getInactiveContact = async () => {
      try {
        const invoicesData = await ApiService.request(
          {},
          "rgpd/contacts/invoices",
          "get"
        );
        if (invoicesData) {
          setInvoices(invoicesData);
          setRows(generateTableRow(invoicesData));
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    getInactiveContact();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const formatName = (name) => {
    if (!name) return "";
    return name.toLowerCase().replace(/\s+/g, " ").trim().replaceAll(" ", "-");
  };

  const getInvoices = async (contact, zip) => {
    if (contact.length === invoices.length) {
      const promRes = await Promise.all(
        contact.map(async (item) => {
          if (item.invoices.length > 0) {
            if (item.invoices.length > 0) {
              const invoiceFolder = zip.folder(
                formatName(`${item.contact.firstname}-${item.contact.lastname}`)
              );

              await Promise.all(
                item.invoices.map(async (invoice, i) => {
                  const pdf = await generateInvoice(invoice, item.contact);
                  const invoiceName =
                    invoice.invoice_number ||
                    `${item.contact.firstname}-${item.contact.lastname}-${i}`;
                  if (pdf) {
                    invoiceFolder.file(
                      formatName(invoiceName + ".pdf"),
                      pdf.output("blob")
                    );
                  }
                })
              );
            }
          }
        })
      );
      return promRes;
    } else {
      if (contact.invoices.length > 0) {
        const invoiceFolder = zip.folder(
          formatName(`${contact.contact.firstname}-${contact.contact.lastname}`)
        );

        await Promise.all(
          contact.invoices.map(async (invoice, i) => {
            const pdf = await generateInvoice(invoice, contact.contact);
            const invoiceName =
              invoice.invoice_number ||
              `${contact.contact.firstname}-${contact.contact.lastname}-${i}`;
            if (pdf) {
              invoiceFolder.file(
                formatName(invoiceName + ".pdf"),
                pdf.output("blob")
              );
            }
          })
        );
      }
    }
  };

  const generateTableRow = (data) => {
    if (!data) return [];
    return data.map((invoice) => {
      return {
        firstname: invoice.contact.firstname,
        lastname: invoice.contact.lastname,
        companyname:
          invoice.contact.company !== null ? invoice.contact.company.name : "",
        contact: invoice.contact,
        nbrOfInvoice: invoice.invoices.length,
        invoices: invoice.invoices,
        deletionDate: invoice.deletionDate,
        link: `/admin/contacts/edit/${invoice.contact.id}`,
      };
    });
  };

  const exportInvoices = async (contact = invoices) => {
    const zip = new JSZip();
    const myDate = new Date();
    const isAllContacts = contact.length === invoices.length;
    const currentDate = myDate.toLocaleDateString("fr-FR").replaceAll("/", "-");
    if (isAllContacts) {
      setIsInvoiceLoading(true);
    }

    try {
      if (
        (isAllContacts && invoices.length > 0) ||
        (!isAllContacts && contact.nbrOfInvoice > 0)
      ) {
        getInvoices(contact, zip).then(() => {
          zip.generateAsync({ type: "blob" }).then((content) => {
            isAllContacts
              ? saveAs(content, `factures-contacts-${currentDate}.zip`)
              : saveAs(
                  content,
                  `factures-${contact.firstname}-${contact.lastname}-${currentDate}.zip`
                );
            if (isAllContacts) {
              setIsInvoiceLoading(false);
            }
          });
        });
      } else {
        if (isAllContacts) {
          setIsInvoiceLoading(false);
        }
      }
    } catch (error) {
      if (isAllContacts) {
        setIsInvoiceLoading(false);
      }
      console.log(error);
    }
  };

  const getContacts = () =>
    invoices.map((invoice) => ({
      contact: invoice.contact,
      guests: invoice.guests,
    }));

  if (isLoading) return <Loader />;

  if (invoices.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography variant="h4" sx={{ margin: "auto" }}>
          Aucun contact inactif
        </Typography>
      </Box>
    );
  } else {
    return (
      <div>
        <div className="breadcrumb">
          <div className="col-md-12 offset-md-2">RGPD</div>
        </div>
        <div className="container">
          <div className="flex-column">
            <div style={{ alignSelf: "end" }}>
              <Button
                sx={{
                  mr: "1.5rem",
                  bgcolor: "#d81b60",
                  "&:hover": { bgcolor: "#a11448", color: "white" },
                }}
                variant="contained"
                href={
                  "data:text/json;charset=utf-8," +
                  encodeURIComponent(JSON.stringify(getContacts()))
                }
                download="info-contacts.json"
                disabled={invoices.length > 0 ? false : true}
              >
                Exporter tous les contacts (JSON)
              </Button>
              <Button
                variant="contained"
                onClick={() => exportInvoices()}
                disabled={
                  invoices.length > 0 ? false : true && isInvoiceLoading
                }
                sx={{
                  minWidth: isInvoiceLoading ? "120px" : null,
                  bgcolor: "#d81b60",
                  "&:hover": { bgcolor: "#a11448", color: "white" },
                }}
              >
                {isInvoiceLoading ? (
                  <CircularProgress sx={{ color: "#d81b60" }} size={26} />
                ) : (
                  "Exporter toutes les factures (PDF)"
                )}
              </Button>
            </div>
            <div>
              <p> Liste des contacts à supprimer </p>
            </div>
            <Paper sx={{ overflow: "hidden" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 400 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Nom</TableCell>
                      <TableCell align="center">Société</TableCell>
                      <TableCell align="center">Date de suppression</TableCell>
                      {/* <TableCell align="center">Info</TableCell> */}
                      <TableCell align="center">Nombre de factures</TableCell>
                      <TableCell align="center">
                        Export des données de contact (JSON)
                      </TableCell>
                      <TableCell align="center">
                        Export des factures (PDF)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row" align="center">
                            {`${row.firstname} ${row.lastname}`}
                          </TableCell>
                          <TableCell align="center">
                            {row.companyname}
                          </TableCell>
                          <TableCell align="center">
                            {row.deletionDate}
                          </TableCell>
                          <TableCell align="center">
                            {row.nbrOfInvoice}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              color="inherit"
                              sx={{
                                "&:hover": { color: "inherit" },
                              }}
                              variant="contained"
                              href={
                                "data:text/json;charset=utf-8," +
                                encodeURIComponent(JSON.stringify(row.contact))
                              }
                              download={`contact-${row.firstname}-${row.lastname}.json`}
                            >
                              <FontAwesomeIcon icon={faDownload} />
                            </Button>
                          </TableCell>

                          <TableCell align="center">
                            <Button
                              color="inherit"
                              variant="contained"
                              onClick={() => exportInvoices(row)}
                            >
                              <FontAwesomeIcon icon={faDownload} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                sx={{
                  ".MuiTablePagination-displayedRows": {
                    m: 0,
                  },
                  ".MuiTablePagination-selectLabel": {
                    m: 0,
                  },
                }}
                rowsPerPageOptions={[10, 25, 100]}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Contact par page : "
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}–${to} sur ${
                    count !== -1 ? count : `plus que ${to}`
                  }`;
                }}
              />
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

export default RgpdView;
