import React from "react";
import { Link } from "react-router-dom";
import ApiService from "../../../../services/ApiService";
import { toast } from "react-toastify";
import Loader from "../../../../common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

class FormsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: this.props.formsList };
    this.loadData = this.loadData.bind(this);
    this.deleteForm = this.deleteForm.bind(this);
    this.state = { formList: [], isLoading: true };
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    const self = this;

    await ApiService.request(
      {},
      "evenement_forms/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      self.setState({
        formList: data,
        isLoading: false,
      });
    });
  }

  async deleteForm(id) {
    const self = this;
    await ApiService.request({}, "evenement_forms/" + id, "delete")
      .then(function () {
        self.loadData();
      })
      .catch(function () {
        toast.error("Erreur lors de la suppression", {
          autoClose: 3000,
        });
      });
  }

  render() {
    const { formList, isLoading } = this.state;
    if (isLoading) {
      return <Loader />;
    }
    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <div className="row">
            <div className="col-md-10">
              <h2>Liste des pages web</h2>
            </div>
            <div className="col-md-2">
              <Link
                className="btn pink darken-1 float-right white-text"
                to={
                  "/admin/events/edit/" +
                  this.props.match.params.eventid +
                  "/forms/edit/"
                }
              >
                Nouvelle page web
              </Link>
            </div>
          </div>
          <br />
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th scope="col">Nom</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {formList.map((form) => (
                <tr key={form.id}>
                  <td>{form.name}</td>

                  <td style={{ width: "25%" }}>
                    <span
                      className="pointer"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Etes vous sûr de supprimer cette page ?"
                          )
                        )
                          this.deleteForm(form.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </span>
                    &nbsp;&nbsp;&nbsp;
                    <Link
                      to={
                        "/admin/events/edit/" +
                        this.props.match.params.eventid +
                        "/forms/edit/" +
                        form.id
                      }
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default FormsList;
