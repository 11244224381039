import React from "react";
import TextField from "@mui/material/TextField";

export function Input({
  value,
  handleValue,
  bgColor,
  borderColor,
  textColor,
  id,
  ...props
}) {
  return (
    <TextField
      value={value}
      onChange={handleValue}
      size="small"
      sx={{
        m: 1,
        height: "fit-content",
        minWidth: "250px",
        backgroundColor: bgColor,
        borderRadius: "0.7rem",
        color: "green",
        border: `1px solid ${borderColor} !important`,
        "& .MuiOutlinedInput-input": {
          color: textColor,
          ":focus": {
            borderRadius: "0.7rem",
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        // "& ::placeholder": {
        //   color: "#000000",
        //   fontStyle: "italic",
        //   opacity: 1,
        // },
        "& :focus": {
          borderBottom: 0,
          boxShadow: "none",
        },
      }}
      variant="outlined"
      id={id}
      {...props}
    />
  );
}
