import React from "react";
import { FormDialog } from "../../components/FormDialog";
import PhoneInput from "react-phone-input-2";
import { CustomMuiButton } from "../../components/CustomMuiButton";
import { removeDialCodeFromNumber, verifyNumber } from "./utils";
import PhoneNumberInput from "../../components/PhoneNumberInput";

import "react-phone-input-2/lib/semantic-ui.css";
// import "react-phone-input-2/lib/style.css";
// import "react-phone-input-2/lib/high-res.css";

export function PhoneNumberDialog({
  openPhoneNumberDialog,
  guestCurrentPhoneNumber,
  formatUniquePhoneNumber,
  currentGuest,
  handlePhoneNumberDialogClose,
}) {
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [phoneNumberError, setPhoneNumberError] = React.useState(false);
  const countryDetails = React.useRef(null);

  const resetStates = () => {
    setPhoneNumber("");
    setPhoneNumberError(false);
    countryDetails.current = null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!countryDetails.current) {
      setPhoneNumberError(true);
      return;
    }

    let parsedNumber = removeDialCodeFromNumber(
      phoneNumber,
      "+" + countryDetails.current.dialCode
    );

    if (parsedNumber) {
      const newPhoneNumber = verifyNumber(
        parsedNumber,
        countryDetails.current.countryCode.toUpperCase()
      );

      if (newPhoneNumber) {
        //Valid
        setPhoneNumberError(false);
        formatUniquePhoneNumber(
          currentGuest.id,
          currentGuest.contact_id,
          newPhoneNumber
        );
        handlePhoneNumberDialogClose();
        resetStates();
        return;
      } else {
        //Invalid
        setPhoneNumberError(true);
        return;
      }
    }
    //Invalid
    setPhoneNumberError(true);
  };

  return (
    <FormDialog
      open={openPhoneNumberDialog}
      onSubmit={handleSubmit}
      handleClose={() => {
        handlePhoneNumberDialogClose();
        resetStates();
      }}
      style={{
        padding: "3rem",
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        overflow: "hidden",
      }}
    >
      <h3 style={{ fontWeight: 500 }}>Modification de numéro</h3>
      <div
        style={{
          padding: "0rem 6rem",
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
        }}
      >
        <div>
          <label
            htmlFor="initialPhoneNumber"
            style={{
              fontSize: "0.875rem",
              opacity: "0.7",
              marginBottom: "0.25rem",
            }}
          >
            Ancien numéro
          </label>
          <PhoneInput
            // disableCountryCode={true}
            disableDropdown
            // disableInitialCountryGuess
            disabled
            value={guestCurrentPhoneNumber ? guestCurrentPhoneNumber : ""}
            placeholder="Numéro indéfini"
          />
        </div>
        <div>
          <label
            htmlFor="initialPhoneNumber"
            style={{
              fontSize: "0.875rem",
              opacity: "0.7",
              marginBottom: "0.25rem",
            }}
          >
            Nouveau numéro
          </label>
          <PhoneNumberInput
            parentPhoneNumber={phoneNumber}
            setParentPhoneNumber={setPhoneNumber}
            setParentPhoneNumberError={setPhoneNumberError}
            countryDetails={countryDetails}
          />
        </div>
      </div>

      <div
        style={{
          padding: "0rem 6rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <CustomMuiButton
          type="submit"
          text="Confirmer"
          minHeight={40}
          textColor="#ffffff"
          buttonBgColor="#d81b60"
          buttonHoverBgColor="#AF1242"
          buttonHoverBorderColor="#0062cc"
          buttonActiveBgColor="#d81b60"
          disable={phoneNumberError || !phoneNumber || phoneNumber.length < 5}
        />
      </div>
    </FormDialog>
  );
}
