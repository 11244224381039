import React from "react";
import { Link } from "react-router-dom";
import ApiService from "../../../services/ApiService";
import { Image, CloudinaryContext } from "cloudinary-react";

class TagsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
  }

  componentDidMount() {
    const self = this;

    ApiService.request(this.state, "component_tag", "get").then(function (
      data
    ) {
      self.setState({ data: data });
    });
  }

  deleteTag(index, id) {
    const list = this.state.data;
    list.splice(index, 1);

    const self = this;
    ApiService.request({}, "component_tag/" + id, "delete").then(function (
      data
    ) {
      self.setState({ data: list });
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <div className="row">
            <div className="col-md-10">
              <h2>Liste des thématiques</h2>
            </div>
            <div className="col-md-2">
              <Link
                className="btn pink darken-1 float-right white-text"
                to="/admin/thematiques/edit"
              >
                Nouvelle thématique
              </Link>
            </div>
          </div>
          <br />
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th scope="col">Nom</th>
                <th scope="col">Couleur</th>
                <th scope="col">Picto</th>
              </tr>
            </thead>
            <tbody>
              {this.state.data.map((tag, i) => (
                <tr key={i}>
                  <td>{tag.names}</td>
                  <td>
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: tag.color,
                      }}
                    />
                  </td>

                  <td>
                    {
                      <CloudinaryContext cloudName="kanguroo-event">
                        <Image
                          className="img-thumbnail"
                          style={{ width: "100px" }}
                          publicId={tag.icon_url}
                        />
                      </CloudinaryContext>
                    }
                  </td>
                  <td>
                    <button
                      className="btn mt-1 pink darken-1 float-right white-text"
                      style={{ width: "100%", maxWidth: "150px" }}
                      onClick={() => {
                        if (
                          window.confirm(
                            "Etes vous sûr de supprimer cette thématique ?"
                          )
                        )
                          this.deleteTag(i, tag.id);
                      }}
                    >
                      supprimer
                    </button>
                    <Link
                      className="btn mt-1 pink darken-1 float-right white-text"
                      to={"/admin/thematiques/edit/" + tag.id}
                      style={{
                        width: "100%",
                        maxWidth: "150px",
                        margin: "0.375rem",
                      }}
                    >
                      Modifier
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default TagsList;
