import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Slide,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import PollIcon from "@mui/icons-material/Poll";

import { getCheckinData } from "../utils";
import { toast } from "react-toastify";

function StatisticsDialog({
  open,
  handleOpen,
  statsData,
  eventId,
  event,
  checkinPointId,
  formId,
  setGuests,
  setEvent,
  setPlacements,
  setIsLoading,
  setFormId,
  setCheckinPoint,
  isFirstRender,
}) {
  const [buttonLoading, setButtonLoading] = React.useState(false);

  const refreshGuests = async () => {
    try {
      setButtonLoading(true);
      if (isFirstRender) {
        isFirstRender.current = true;
        await getCheckinData(
          eventId,
          event,
          checkinPointId,
          formId,
          isFirstRender,
          setEvent,
          setGuests,
          setPlacements,
          setIsLoading,
          setFormId,
          setCheckinPoint
        );
        toast.success("Données mises à jour");
        handleOpen(false);
      } else throw new Error("isFirstRender => undefined");

      console.log("🤘 update 2");
    } catch (error) {
      console.log(error);
      toast.error(
        "Une erreur s'est produite. Veuillez relancer l'application."
      );
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={() => handleOpen(false)}
        aria-labelledby="alert-dialog-statistic"
        aria-describedby="alert-dialog-description-statistic"
        fullScreen
        PaperProps={{ style: { backgroundImage: 'url("/bg-checkin.png")' } }}
      >
        <DialogTitle>
          <Slide direction="down" in={open} mountOnEnter unmountOnExit>
            <IconButton onClick={() => handleOpen(false)}>
              <CloseRoundedIcon sx={{ fontSize: "2rem", color: "white" }} />
            </IconButton>
          </Slide>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Paper
              onClick={() => window.location.replace("/checkin")}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "20px",
                padding: "0.7rem 0px",
                gap: "0.5rem",
                maxWidth: "600px",
                margin: "auto",
                cursor: "pointer",
                "&:hover": {
                  color: "#D23466",
                },
              }}
            >
              <SettingsIcon sx={{ fontSize: "1.6rem" }} />
              <Typography fontWeight={600} fontSize="1.2rem">
                CONFIGURATION
              </Typography>
            </Paper>
            <Paper
              sx={{
                marginTop: "1.5rem",
                marginInline: "auto",
                paddingBlock: "0.8rem",
                maxWidth: "600px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.5rem",
                }}
              >
                <PollIcon sx={{ fontSize: "1.6rem" }} />
                <Typography fontWeight={600} fontSize="1.2rem">
                  STATISTIQUES
                </Typography>
              </Box>
              <Box sx={{ marginBlock: "0.5rem", paddingTop: "0.3rem" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography fontWeight={600}>Participants</Typography>
                  <Typography color="#777777">{statsData.all}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography fontWeight={600}>Présents</Typography>
                  <Typography color="#777777">{statsData.checkedin}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography fontWeight={600}>Absents</Typography>
                  <Typography color="#777777">{statsData.pending}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography fontWeight={600}>Refusés</Typography>
                  <Typography color="#777777">{statsData.refused}</Typography>
                </Box>
              </Box>
            </Paper>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {buttonLoading ? (
                <CircularProgress
                  sx={{ marginTop: "1rem", color: "#454545" }}
                />
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#454545",
                    fontWeight: "bold",
                    borderRadius: "5px",
                    marginTop: "1rem",
                    color: "white",
                    ":hover": {
                      backgroundColor: "#454545",
                      opacity: 0.9,
                    },
                  }}
                  disabled={buttonLoading}
                  onClick={(e) => {
                    e.preventDefault();
                    refreshGuests();
                  }}
                >
                  Mettre à jour les données
                </Button>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default StatisticsDialog;
