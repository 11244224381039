import React from "react";
import { useState, useEffect } from "react";
import Loader from "../../../common/Loader";
import { generateListLanguage } from "../../../common/Translations";
import SelectComponent from "../../../inputComponent/SelectComponent";
import ApiService from "../../../services/ApiService";
import "./forms/MenuEditor.css";

export default function LanguageContainer({
  event,
  updateEventLang,
  name = "",
}) {
  const [list, setList] = useState([]);
  const [listSelected, setListSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {}, [list]);

  const saveEvent = async (e) => {
    const allLanguages = list.slice(1).map((elt) => elt.value);
    const value = e.map((elt) => elt.value);
    /*  console.log(e);
    console.log(value, "voir la"); */
    const { data } = event;
    if (e.some((elt) => elt.label === "Toutes les langues")) {
      setListSelected(list.slice(1));
      data.default_language = allLanguages;
    } else {
      data.default_language = value;
      setListSelected(e);
    }

    if (name === "createEvent") {
      if (e.some((elt) => elt.label === "Toutes les langues")) {
        updateEventLang(allLanguages);
      } else {
        updateEventLang(value);
      }
    } else {
      await ApiService.request(
        {
          data,
        },
        "evenements/" + event.id,
        "put"
      );
    }
  };
  const getData = () => {
    setIsLoading(true);
    const { default_language } = event.data;
    const array = generateListLanguage();
    setList(array);
    if (default_language === "") {
      setListSelected(array.slice(1));
    } else {
      if (Array.isArray(default_language)) {
        setListSelected(
          array.filter((elt) =>
            default_language.some((defaultL) => defaultL === elt.value)
          )
        );
      } else {
        setListSelected(array.filter((elt) => elt.value === default_language));
      }
    }
    setIsLoading(false);
  };
  if (isLoading) {
    return <Loader />;
  }
  console.log(event)
  return (
    <div className="row">
      <div className="col-md-10 offset-md-1 main-content">
        <div className="row">
          <div className={"col-md-10"}>
            <h2>Langue de l'évenement</h2>
          </div>

          <div style={{ width: "30%", margin: 20, marginTop: 20 }}>
            <SelectComponent
              name="languageId"
              multi={true}
              className="browser-default select-component"
              onChange={saveEvent}
              options={list}
              value={listSelected}
              placeHolder={"Langues"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
