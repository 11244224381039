import React from "react";
import {
  Box,
  Button,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import Select from "./components/Select";

function Settings({
  events,
  eventsOptions,
  selectedEvent,
  setSelectedEvent,
  doLogout,
}) {
  const [selectedCheckinPoint, setSelectedCheckinPoint] = React.useState("");
  const [checkinPointsOptions, setCheckinPointsOptions] = React.useState([]);

  React.useEffect(() => {
    if (selectedEvent) {
      const event = events.find((item) => item.id === selectedEvent);
      if (event.checkin_points.length === 1)
        setSelectedCheckinPoint(event.checkin_points[0].id);
      setCheckinPointsOptions(getSelectedEventCheckinPoints());
    }
  }, [selectedEvent]);

  const getSelectedEventCheckinPoints = () => {
    const event = events.find((item) => item.id === selectedEvent);

    const checkinPoints =
      event && event.checkin_points.length > 0
        ? event.checkin_points.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        : [];

    if (checkinPoints.length === 0) setSelectedCheckinPoint("");

    return checkinPoints;
  };

  if (!events) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress sx={{ color: "#D23466" }} />
      </Box>
    );
  }

  return (
    <Box sx={{ paddingInline: "1rem" }}>
      <Paper
        sx={{
          maxWidth: 600,
          margin: "auto",
          marginTop: "4rem",
          padding: "1.5rem 1.5rem 1rem 1.5rem",
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          color="#4F4F4F"
          component="div"
          textAlign="center"
        >
          Configuration du Checkin
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.2rem",
            alignItems: "center",
            width: "100%",
            marginBlock: "2rem 1.5rem",
          }}
        >
          <Box sx={{ width: "80%" }}>
            <Select
              items={eventsOptions}
              label="Évènement"
              id="checkin-event"
              value={selectedEvent}
              setValue={setSelectedEvent}
            />
          </Box>
          <Box sx={{ width: "80%" }}>
            <Select
              disable="true"
              items={checkinPointsOptions}
              label="Points de checkin"
              id="checkin-points"
              value={selectedCheckinPoint}
              setValue={setSelectedCheckinPoint}
              event={selectedEvent}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() =>
              selectedEvent &&
              selectedCheckinPoint &&
              window.location.replace(
                `/checkin/registration/${selectedEvent}/${selectedCheckinPoint}`
              )
            }
            variant="contained"
            sx={{
              backgroundColor: "#d81b60",
              fontWeight: "bold",
              borderRadius: "20px",
              width: "80%",
              ":hover": {
                backgroundColor: "#d81b60",
                opacity: 0.9,
              },
            }}
          >
            EMARGER
          </Button>
          <Button
            onClick={() =>
              selectedEvent &&
              selectedCheckinPoint &&
              window.location.replace(
                `/checkin/scan/${selectedEvent}/${selectedCheckinPoint}`
              )
            }
            variant="contained"
            sx={{
              backgroundColor: "#d81b60",
              fontWeight: "bold",
              borderRadius: "20px",
              width: "80%",
              ":hover": {
                backgroundColor: "#d81b60",
                opacity: 0.9,
              },
            }}
          >
            SCANNER
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#454545",
              fontWeight: "bold",
              borderRadius: "5px",
              marginTop: "1rem",
              width: "100%",
              ":hover": {
                backgroundColor: "#454545",
                opacity: 0.9,
              },
            }}
            size="large"
            onClick={() => doLogout()}
          >
            SE DÉCONNECTER
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default Settings;
