import React, { useState } from "react";
import { Box, AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import QrCodeIcon from "@mui/icons-material/QrCode";
import StatisticsDialog from "../dialogs/StatisticsDialog";
import FormatListNumberedRtlOutlinedIcon from "@mui/icons-material/FormatListNumberedRtlOutlined";

function Header({
  checkinPointName,
  eventId,
  event,
  checkinPointId,
  formId,
  guests,
  setGuests,
  isGuestChecked,
  mode,
  setEvent,
  setPlacements,
  setIsLoading,
  setFormId,
  setCheckinPoint,
  isFirstRender,
}) {
  const [openStatsDialog, setOpenStatsDialog] = useState(false);

  const capitalizeStr = (str) => {
    if (!str) return "";
    if (str.length < 1) return str.toUpperCase();

    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  //TODO: UPDATE
  const getStatsData = () => {
    let checkedin = 0;
    let pending = 0;
    guests.forEach((guest) => {
      const checkedGuest = isGuestChecked(guest, checkinPointId);
      if (checkedGuest) checkedin++;
      else pending++;
    });

    const refused = guests.filter((guest) => {
      const refusedGuest = !isGuestChecked(guest, checkinPointId);
      return refusedGuest;
    }).length;

    return { all: guests.length, checkedin, pending, refused };
  };

  return (
    <Box sx={{ position: "inherit" }}>
      <StatisticsDialog
        open={openStatsDialog}
        handleOpen={setOpenStatsDialog}
        statsData={getStatsData()}
        eventId={eventId}
        event={event}
        checkinPointId={checkinPointId}
        formId={formId}
        setGuests={setGuests}
        setEvent={setEvent}
        setPlacements={setPlacements}
        setIsLoading={setIsLoading}
        setFormId={setFormId}
        setCheckinPoint={setCheckinPoint}
        isFirstRender={isFirstRender}
      />
      <Box sx={{ flexGrow: 1, position: "inherit" }}>
        <AppBar
          sx={{
            top: 0,
            position: "inherit",
            boxShadow: mode === "REGISTRATION" ? "none" : null,
            backgroundColor: "#D23466",
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <IconButton
              edge="start"
              aria-label="checkin-header"
              sx={{ mr: 2, fontSize: "2rem", color: "white" }}
              onClick={() => setOpenStatsDialog(true)}
            >
              <MenuIcon fontSize="1.5rem" />
            </IconButton>
            <Box sx={{ flexGrow: 1 }}>
              <Box
                sx={{
                  margin: "auto",
                  width: "fit-content",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" component="div" textAlign="center">
                  {capitalizeStr(event ? event.data.name : "")}
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  textAlign="center"
                  gutterBottom
                >
                  {capitalizeStr(checkinPointName)}
                </Typography>
              </Box>
            </Box>
            {mode === "SCAN" ? (
              <IconButton
                sx={{ color: "white", fontSize: "2rem" }}
                onClick={
                  () =>
                    window.location.replace(
                      `/checkin/registration/${eventId}/${checkinPointId}`
                    ) //TODO: UPDATE
                }
              >
                <FormatListNumberedRtlOutlinedIcon fontSize="1.5rem" />
              </IconButton>
            ) : (
              <IconButton
                sx={{ color: "white", fontSize: "2rem" }}
                onClick={
                  () =>
                    window.location.replace(
                      `/checkin/scan/${eventId}/${checkinPointId}`
                    ) //TODO: UPDATE
                }
              >
                <QrCodeIcon fontSize="1.5rem" />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </Box>
  );
}

export default Header;
