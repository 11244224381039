import React, { useState, useEffect } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import * as XLSX from "xlsx/xlsx.mjs";
import { isOdd } from "../../../services/Utils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SelectComponentImport from "../../../inputComponent/SelectComponentImport";
import { numbersToImport } from "../../../config";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export default function ImportContactTable({
  rows,
  setRows,
  linesToShow,
  lines,
  firstLine,
  setFirstLine,
  typePage,
  setTypePage,
  data,
  setData,
  numbersRowsToShow,
  setNumbersRowsToShow,
  validate,
  setValidate,
  selects,
  setSelects,
  delegataire,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasErrorMsg, setHasErrorMsg] = useState("");
  const [removeInInput, setRemoveInInput] = useState(true);
  const [selectChange, setSelectChange] = useState(false);

  useEffect(() => {}, [rows, typePage, data]);

  const theme = createTheme({
    palette: {
      grey: {
        main: "#373b44",
        contrastText: "#fff",
      },
      pink: {
        main: "#D81A60",
        contrastText: "#fff",
      },
    },
  });
  const fileTypes = [
    "csv",
    "vnd.openxmlformats-officedocument.spreadsheetml.sheet", //xlsx
    "vnd.ms-excel", // xls
  ];
  const checkExtension = (fileImport) => {
    if (fileTypes.length) {
      const verifType = fileTypes.find(
        (type) =>
          `application/${type}` === fileImport.type ||
          `text/${type}` === fileImport.type
      );
      return verifType ? true : false;
    }
    return true;
  };
  function sheet2Array(sheet, extension) {
    var result = [];
    var row;
    var rowNum;
    var colNum;
    var range = XLSX.utils.decode_range(sheet["!ref"]);

    for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      row = [];
      for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
        var nextCell = sheet[XLSX.utils.encode_cell({ r: rowNum, c: colNum })];
        if (typeof nextCell === "undefined") {
          row.push("");
        } else {
          if (extension === "text/csv" || extension === "application/csv") {
            const value = nextCell.w
              ? nextCell.w
              : nextCell.v
              ? nextCell.v
              : "";
            // const valueDecode = value ? decodeURIComponent(escape(value)) : ""
            row.push(value);
          } else {
            const value = nextCell.w
              ? nextCell.w
              : nextCell.v
              ? nextCell.v
              : "";
            row.push(value);
          }
        }
      }
      result.push(row);
    }
    return result;
  }
  function get_header_row(sheet, extension) {
    var headers = [];
    var range = XLSX.utils.decode_range(sheet["!ref"]);
    let colNum,
      rowNum = range.s.r; /* start in the first row */
    /* walk every column in the range */
    for (colNum = range.s.c; colNum <= range.e.c; ++colNum) {
      let cell =
        sheet[
          XLSX.utils.encode_cell({ c: colNum, r: rowNum })
        ]; /* find the cell in the first row */
      let hdr = "UNDEFINED : " + colNum; // <-- replace with your desired default
      if (cell && cell.t) {
        hdr = XLSX.utils.format_cell(cell);
      }
      headers.push(hdr);
    }
    const newHead = [];
    headers.map((head, idx) => {
      return newHead.push({ key: idx, name: head });
    });
    return newHead;
  }
  function processExcel(data, extension) {
    const workbook = XLSX.read(data, { type: "binary", codepage: 65001 });

    const firstSheet = workbook.SheetNames[0];
    const firstRow = get_header_row(workbook.Sheets[firstSheet], extension);
    const rows = sheet2Array(workbook.Sheets[firstSheet], extension);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    setFirstLine(firstRow);
    setRows(rows);
    return rows ? true : false;
  }
  const verifFile = (file) => {
    let name = file.name;
    if (typeof FileReader !== "undefined") {
      const reader = new FileReader();
      if (reader.readAsBinaryString) {
        reader.onload = (e) => {
          const result = processExcel(reader.result, file.type);
          if (result) {
            setFileName(name);
            setTimeout(() => {
              setTypePage({ page: "import", subPage: "config" });
            }, 1000);
          }
        };
        reader.readAsBinaryString(file);
      }
    } else {
      toast.error("Le navigateur ne supporte pas l'HTML5");
    }
  };
  const handleChange = (fileImport) => {
    setIsLoading(true);
    if (fileImport) {
      try {
        const extension = checkExtension(fileImport);
        if (!fileImport.type) {
          setIsLoading(false);
          setHasError(true);
          setHasErrorMsg("");
          setRemoveInInput(null);
          return;
        }
        setHasError(false);
        setHasErrorMsg("");
        if (extension) {
          verifFile(fileImport);
        } else {
          setIsLoading(false);
          setHasError(true);
          setHasErrorMsg("Le format n'est pas accepté");
          setRemoveInInput(null);
        }
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      setHasError(true);
      setRemoveInInput(null);
    }
  };

  const ErrorComponent = () => {
    return (
      <div
        style={{
          cursor: "pointer",
        }}
      >
        <label>{hasErrorMsg}</label>
        <label></label>
        <label></label>
        <label></label>
        <label></label>{" "}
      </div>
    );
  };

  const Area = () => {
    return hasError ? (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: 10,
            width: isLoading ? "100%" : "auto",
            cursor: "pointer",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="areaDropError"
        >
          {isLoading ? (
            <ThemeProvider theme={theme}>
              <LinearProgress className="dragLoader" color="grey" />
            </ThemeProvider>
          ) : (
            <ErrorComponent />
          )}
        </div>
      </>
    ) : (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: 10,
            width: isLoading ? "100%" : "auto",
            cursor: "pointer",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="areaDrop"
        >
          {isLoading ? (
            <ThemeProvider theme={theme}>
              <LinearProgress className="dragLoader" color="grey" />
            </ThemeProvider>
          ) : (
            <>
              {fileName ? (
                <>
                  {" "}
                  <label></label>
                  <label></label>
                  <label style={{ color: "#D81A60", fontSize: 14 }}>
                    {fileName}
                  </label>
                  <label></label>
                  <label></label>
                </>
              ) : (
                <>
                  <label className="dragDropLabelEmpty">
                    Cliquez ici pour télécharger
                  </label>
                  <label className="dragDropLabelEmpty">
                    votre fichier ou glissez & déposez
                  </label>
                  <label className="dragDropLabelEmpty">
                    Formats acceptés: fichiers excel(xls,xlsx,csv)
                  </label>{" "}
                </>
              )}{" "}
            </>
          )}
        </div>
      </>
    );
  };
  function displayRowCell(row) {
    const cells = Object.values(row);
    if (cells && cells.length > 0) {
      return cells.map((cell, idx) => {
        return (
          <div className="thConfigImportTable" style={null} key={idx}>
            <label className="labelCell">{cell}</label>
          </div>
        );
      });
    }
  }
  return (
    <div>
      <div className="p-2">
        {typePage.page === "import" && typePage.subPage === "config" ? (
          <>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              className="titlePageImport flex flex-row"
            >
              Import de Contacts
              <FormControl style={{ marginLeft: 30 }}>
                <Select
                  labelId=""
                  className="selectImportNumbers flex align-center"
                  value={numbersRowsToShow}
                  onChange={(e) => setNumbersRowsToShow(e.target.value)}
                  displayEmpty
                  disabled={false}
                >
                  <MenuItem value="" className="selectItemEmpty">
                    Nombres de lignes à afficher
                  </MenuItem>
                  {numbersToImport
                    ? numbersToImport.map((number, key) => {
                        return (
                          <MenuItem
                            key={key}
                            value={number}
                            className="selectItem"
                          >
                            {number}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
              </FormControl>
            </Typography>
            <div
              className="flex flex-row"
              style={{ justifyContent: "flex-end", marginBottom: 20 }}
            >
              <div className="p-2">
                {validate.mail ? (
                  <CheckIcon style={{ color: "#D81A60" }} />
                ) : (
                  <CloseIcon style={{ color: "black" }} />
                )}{" "}
                <strong>Mail</strong>
              </div>
              <div className="p-2">
                {validate.lastname ? (
                  <CheckIcon style={{ color: "#D81A60" }} />
                ) : (
                  <CloseIcon style={{ color: "black" }} />
                )}{" "}
                <strong>Lastname</strong>
              </div>
            </div>
            <TableContainer component={Paper} className="op_table">
              {lines.length > 0 ? (
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  className="table_simple"
                >
                  <TableHead>
                    <TableRow>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="thConfigImportTableSelect"
                          style={{ minWidth: 100 }}
                        >
                          Ligne
                        </div>
                        {firstLine.map((column, idx) => {
                          return (
                            <div
                              className="thConfigImportTableSelect"
                              style={{ minWidth: 100 }}
                              key={idx}
                            >
                              <SelectComponentImport
                                array={selects}
                                rows={lines}
                                column={column}
                                selectChange={selectChange}
                                columnKey={column.key}
                                emptySelect="Choisir"
                                typePage={typePage}
                                setSelects={setSelects}
                                setSelectChange={setSelectChange}
                                setData={setData}
                                data={data}
                                validate={validate}
                                setValidate={setValidate}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      {linesToShow.length > 0
                        ? linesToShow.map((row, idx) => {
                            const odd = isOdd(idx);
                            const isOddColor = "#e6e6e6";
                            return (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                style={
                                  !odd ? { backgroundColor: isOddColor } : null
                                }
                                key={idx}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className="thConfigImportTable"
                                    style={null}
                                  >
                                    <label className="labelCell">
                                      {idx + 1}
                                    </label>
                                  </div>
                                  {displayRowCell(row)}
                                </div>
                              </TableRow>
                            );
                          })
                        : null}
                    </>
                  </TableBody>
                </Table>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: 650,
                    height: 400,
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    component="h5"
                    className="titlePageImport2"
                  >
                    Aucune données
                  </Typography>
                </div>
              )}
            </TableContainer>
          </>
        ) : (
          <>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              className="titlePageImport flex flex-row"
            >
              Import de Contacts
              {"\n"}
              <Typography
                variant="body2"
                gutterBottom
                component="div"
                className="titlePageImport flex align-center"
                style={{ marginTop: 5, marginLeft: 10 }}
              >
                <a
                  href={
                    delegataire
                      ? process.env.PUBLIC_URL +
                        "/Kanguroo_Example_Import_Contacts_Delegataire.csv"
                      : process.env.PUBLIC_URL +
                        "/Kanguroo_Example_Import_Contacts.csv"
                  }
                  download
                >
                  Télécharger un exemple
                </a>
              </Typography>
            </Typography>
            {delegataire && (
              <>
                <Typography
                  variant="inherit"
                  gutterBottom
                  component="div"
                  className="noteImport flex flex-row"
                >
                  Note : la première ligne de votre fichier doit contenir le nom
                  de vos cololonnes et ne sera pas importée.
                </Typography>
                <br />
              </>
            )}

            <FileUploader
              onSelect={handleChange}
              onDrop={handleChange}
              name="file"
              onSizeError={() => {
                return (
                  setHasError(true), setHasErrorMsg("Le fichier est trop lourd")
                );
              }}
              classes={
                isLoading
                  ? "dragDropFileLoader"
                  : hasError
                  ? "dragdropFileError"
                  : "dragdropFile"
              }
              children={<Area />}
              maxSize={50}
              fileOrFiles={removeInInput}
            />
          </>
        )}
      </div>
      <div className="p-2">
        <Alert severity="warning">
          <AlertTitle>Informations</AlertTitle>
          <ul>
            <li>
              Contrôler bien votre fichier et que le format de vos emails est
              valide, pas de &lt;&gt; ou d'espace
            </li>
            <li>
              2 contacts ne peuvent pas avoir le même email, dans le cas
              contraire un seul contact sera correctement importé
            </li>
            <li>
              Vérifiez bien le rapport d'import qui vous est envoyé par mail
            </li>
          </ul>
        </Alert>
      </div>
    </div>
  );
}
