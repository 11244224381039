import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MessageIcon from "@mui/icons-material/Message";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { getGuestPlacement } from "../utils";

function DetailDialog({
  open,
  handleOpen,
  guest,
  reponse,
  scanError,
  placements,
  components,
}) {
  const [guestPlacements, setGuestPlacements] = useState([]);
  const [componentsMsg, setComponentsMsg] = useState([]);

  useEffect(() => {
    if (placements && open) {
      const res = getGuestPlacement(placements, guest);
      setGuestPlacements(res);
    }
    if (open) {
      generateComponentsMsg();
    }
  }, [open]);

  const getGuestGenderAndName = () => {
    return guest
      ? guest.contact
        ? `${guest.contact.gender} ${guest.firstname} ${guest.lastname}`
        : `${guest.firstname} ${guest.lastname}`
      : "";
  };

  const generateComponentsMsg = () => {
    const componentsCheckinMessage = [];
    if (reponse) {
      if (reponse.responses) {
        const componentsCheckin = reponse.responses.reduce(
          (accumulator, componentReponse) => {
            const component = components.find(
              (comp) => comp.id === componentReponse.id
            );
            if (component && component.data) {
              return [
                ...accumulator,
                {
                  componentReponseLabel: componentReponse.label,
                  component: component,
                },
              ];
            }
            return accumulator;
          },
          []
        );

        componentsCheckin.sort(({ component: comp1 }, { component: comp2 }) =>
          comp1.data.reponse.role === "entrance"
            ? -1
            : comp2.data.reponse.role === "entrance"
            ? +1
            : comp1.data.componentType === "moment"
            ? -1
            : comp2.data.componentType === "moment"
            ? +1
            : 0
        );

        componentsCheckin.forEach(
          ({ componentReponseLabel, component }, index) => {
            if (component.data.reponse.displayCheckin) {
              componentsCheckinMessage.push(
                <Typography key={`component-checkin-message-${index}`}>
                  {component.data.name} : {componentReponseLabel}
                </Typography>
              );
            }
          }
        );
      }
    }

    setComponentsMsg(componentsCheckinMessage);
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleOpen(false)}
      aria-labelledby="alert-dialog-detail"
      aria-describedby="alert-dialog-description-detail"
      fullScreen
    >
      <DialogContent
        sx={{
          padding: 0,
          display: "flex",
          flexDirection: "column",
          opacity: "1 !important",
          backgroundColor: "#d81b60",
          backgroundImage: 'url("/bg-checkin.png")',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <AppBar sx={{ position: "relative", bgcolor: "white", color: "black" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {scanError ? (
              <Typography
                sx={{
                  marginInline: "auto",
                  fontWeight: 600,
                  color: "red",
                  fontSize: "1.2rem",
                }}
              >
                Accès Refusé
              </Typography>
            ) : (
              <Typography
                sx={{
                  marginInline: "auto",
                  fontWeight: 600,
                  fontSize: "1.2rem",
                }}
              >
                Informations Invité
              </Typography>
            )}
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Card sx={{ margin: "auto", minWidth: "300px" }}>
            {scanError ? (
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <WarningOutlinedIcon
                  sx={{ color: "red", fontSize: "3.5rem" }}
                />
                <Typography
                  fontSize="1.1rem"
                  fontWeight={500}
                  marginTop="0.5rem"
                >
                  Accès refusé. Le QR code n'est pas valide
                </Typography>
              </CardContent>
            ) : (
              <>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                      {guest && guest.firstname
                        ? guest.firstname[0].toUpperCase()
                        : "A"}
                    </Avatar>
                  }
                  title={getGuestGenderAndName()}
                  subheader={
                    guest && guest.contact && guest.contact.company
                      ? guest.contact.company.name
                      : ""
                  }
                />
                <CardContent>
                  <Stepper activeStep={-1} orientation="vertical">
                    <Step active>
                      <StepLabel
                        icon={<MessageIcon sx={{ color: "#9E9E9E" }} />}
                      >
                        Message
                      </StepLabel>
                      <StepContent>
                        <Typography>
                          {guest &&
                          guest.package &&
                          guest.package.checkin_group_message
                            ? guest.package.checkin_group_message
                            : ""}
                        </Typography>
                        <Typography>
                          {guest && guest.check_in_message
                            ? guest.check_in_message
                            : ""}
                        </Typography>
                        {componentsMsg}
                      </StepContent>
                    </Step>
                    {guestPlacements && guestPlacements.length > 0
                      ? guestPlacements.map((gPlacement, index) => {
                          if (gPlacement.plan) {
                            return (
                              <Step active key={index}>
                                <StepLabel
                                  icon={
                                    <AirlineSeatReclineNormalIcon
                                      sx={{ color: "#9E9E9E" }}
                                    />
                                  }
                                >
                                  Placement
                                  {guestPlacements.length > 1
                                    ? ` ${index + 1}`
                                    : ""}
                                </StepLabel>
                                <StepContent>
                                  {gPlacement ? (
                                    <ul>
                                      <li>
                                        <Typography>
                                          Plan :{gPlacement.plan || ""}
                                        </Typography>
                                      </li>
                                      <li>
                                        <Typography>
                                          Zone :{gPlacement.zone || ""}
                                        </Typography>
                                      </li>
                                      <li>
                                        <Typography>
                                          Rang :{gPlacement.rank || ""}
                                        </Typography>
                                      </li>
                                      <li>
                                        <Typography>
                                          Place :{gPlacement.place || ""}
                                        </Typography>
                                      </li>
                                    </ul>
                                  ) : (
                                    <Typography>Place non attribuée</Typography>
                                  )}
                                </StepContent>
                              </Step>
                            );
                          }
                          return null;
                        })
                      : null}
                  </Stepper>
                </CardContent>
              </>
            )}

            <CardActions>
              <Button
                sx={{
                  background:
                    "linear-gradient(90deg, rgb(214, 43, 90), rgb(143, 42, 86))",
                  color: "white",
                  width: "85%",
                  paddingInline: "1rem",
                  borderRadius: "17px",
                  margin: "auto",
                  marginBlock: "0.5rem",
                }}
                onClick={() => handleOpen(false)}
              >
                Fermer
              </Button>
            </CardActions>
          </Card>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default DetailDialog;
