import React from "react";
import ApiService from "../../../services/ApiService";
import Loader from "../../../common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faUser,
  faCheck,
  faUsers,
  faQuestion,
  faPercent,
  faArrowCircleRight,
  faBan,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { DashboardStatistics } from "./DashboardStatistics/DashboardStatistics";
import { StatsPanel } from "./DashboardStatistics/StatsPanel";
import { getPercentage } from "./utils";
import { toast } from "react-toastify";
import { hasGuestBeenChecked } from "../../../checkin/new/utils";

registerLocale("fr", fr);

class DashBoardContainer extends React.Component {
  constructor(props) {
    super(props);

    this.loadData = this.loadData.bind(this);
    this.populateGlobalStatsPanels = this.populateGlobalStatsPanels.bind(this);
    this.populateSmsGlobalStatsPanels =
      this.populateSmsGlobalStatsPanels.bind(this);

    this.state = {
      packages: [],
      guests: [],
      responses: [],
      isLoading: true,
      form: null,
      participants: 0,
      componentEntrance: null,
      participatesCame: [],
      participatesNotCame: [],
      participatesNotResponse: [],
      campaigns: [],
      globalStats: {},
      smsStatsData: {},
      guestCreatedOnCheckin: 0,
    };
  }

  async loadData() {
    try {
      const self = this;
      const { participatesCame, participatesNotCame, participatesNotResponse } =
        this.state;
      this.setState({ isLoading: true });
      const globalCampaignStats = await ApiService.request(
        this.state,
        "campaigns/global/stats/" + this.props.match.params.eventid,
        "get"
      ).then(function (data) {
        return data ? data : {};
      });

      const smsStatsData = await ApiService.awaitRequest(
        {},
        "sms/global/stats/" + this.props.match.params.eventid,
        "get"
      ).then((data) => {
        return data ? data : {};
      });

      const campagnes = await ApiService.request(
        this.state,
        "campaigns/byevenement/" + this.props.match.params.eventid,
        "get"
      ).then(function (data) {
        return data ? data : [];
      });

      if (campagnes.length > 0) {
        await campagnes.map(async (campaign) => {
          await ApiService.request(
            this.state,
            "campaigns/stats/" + campaign.id,
            "get"
          ).then(function (data) {
            campaign.stats = data ? data : {};
          });
          campaign.openDetails = false;
          return campaign;
        });
        const campaigns = await campagnes.filter((camp) => !camp.draft);
        if (campaigns) {
          this.setState({ campaigns });
        }
      }
      await ApiService.request(
        this.state,
        "evenement_forms/byevenement/" + this.props.match.params.eventid,
        "get"
      ).then(function (data) {
        self.setState({
          form: data.find((form) => form.layout.documentType === "reponse"),
        });
      });
      await ApiService.request(
        {},
        "guests/byevenement/" + this.props.match.params.eventid,
        "get"
      ).then((res) => {
        self.setState({
          guestCreatedOnCheckin: res
            ? res.filter((guest) =>
                guest.participate ? guest.participate === true : ""
              ).length
            : 0,
        });
      });
      if (this.state.form) {
        await ApiService.request(
          {},
          "component_responses/byevenement/" + this.state.form.id,
          "get"
        ).then(function (data) {
          const componentEntrance = self.props.eventComponents.find(
            (component) => component.data.reponse.role === "entrance"
          );
          self.setState({
            responses: data,
            participants: self.state.guestCreatedOnCheckin,
            componentEntrance,
          });
        });
      }

      await ApiService.request(
        {},
        "packages/byevenement/" + this.props.match.params.eventid,
        "get"
      ).then(function (data) {
        self.setState({
          packages: data,
        });
      });

      await ApiService.request(
        {},
        "guests/byevenement/" + this.props.match.params.eventid,
        "get"
      ).then(function (data) {
        data.forEach((guest) => {
          const hasRep =
            self.state &&
            self.state.responses &&
            self.state.responses.length > 0
              ? self.state.responses.find(
                  (guestResponse) => guestResponse.guest_id === guest.id
                )
              : null;
          if (!hasRep) {
            participatesNotResponse.push(guest);
          }
        });
        data.forEach((guest) => {
          if (guest.participate) {
            if (hasGuestBeenChecked(guest)) {
              participatesCame.push(guest);
            } else {
              participatesNotCame.push(guest);
            }
          }
        });

        self.setState({
          guests: data,
          participatesNotCame,
          participatesCame,
          participatesNotResponse,
          globalCampaignStats,
          smsStatsData,
        });
      });
    } catch (error) {
      console.log(error);
      toast.error("Une erreur s'est produite");
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidMount() {
    this.loadData();
  }

  populateGlobalStatsPanels() {
    const { globalCampaignStats } = this.state;

    return [
      <StatsPanel
        panelTitle="DÉLIVRÉS"
        panelLabel={`Soit ${
          "success" in globalCampaignStats
            ? globalCampaignStats.success.sent.count
            : 0
        } emails`}
        panelValue={`${
          "success" in globalCampaignStats
            ? globalCampaignStats.success.sent.percent
            : 0
        }%`}
      />,
      <StatsPanel
        panelTitle={
          <>
            <FontAwesomeIcon icon={faCircle} color="#78CB68" size={"1x"} />{" "}
            OUVERTS
          </>
        }
        panelLabel={`Soit ${
          "success" in globalCampaignStats
            ? globalCampaignStats.success.opened.count
            : 0
        } emails`}
        panelValue={`${
          "success" in globalCampaignStats
            ? globalCampaignStats.success.opened.percent
            : 0
        }%`}
      />,
      <StatsPanel
        panelTitle={
          <>
            <FontAwesomeIcon icon={faCircle} color="#d81b60" size={"1x"} />{" "}
            CLIQUÉS
          </>
        }
        panelLabel={`Soit ${
          "success" in globalCampaignStats
            ? globalCampaignStats.success.clicked.count
            : 0
        } emails`}
        panelValue={`${
          "success" in globalCampaignStats
            ? globalCampaignStats.success.clicked.percent
            : 0
        }%`}
      />,
      <StatsPanel
        panelTitle={
          <>
            <FontAwesomeIcon icon={faCircle} color="#FF8900" size={"1x"} />{" "}
            ERREURS
          </>
        }
        panelLabel={`Soit ${
          "error" in globalCampaignStats
            ? globalCampaignStats.error.error_total.count
            : 0
        } emails`}
        panelValue={`${
          "error" in globalCampaignStats
            ? globalCampaignStats.error.error_total.percent
            : 0
        }%`}
      />,
    ];
  }

  populateSmsGlobalStatsPanels() {
    const { smsStatsData } = this.state;

    if (smsStatsData && smsStatsData.globalStats) {
      return [
        <StatsPanel
          panelTitle="DÉLIVRÉS"
          panelLabel={`Soit ${smsStatsData.globalStats.delivered} sms`}
          panelValue={`${getPercentage(
            smsStatsData.globalStats.sent,
            smsStatsData.globalStats.delivered
          )}%`}
        />,
        <StatsPanel
          panelTitle={
            <>
              <FontAwesomeIcon icon={faCircle} color="#78CB68" size={"1x"} />{" "}
              OUVERTS
            </>
          }
          panelLabel={`Soit ${smsStatsData.globalStats.openers} sms`}
          panelValue={`${getPercentage(
            smsStatsData.globalStats.delivered,
            smsStatsData.globalStats.openers
          )}%`}
        />,
        <StatsPanel
          panelTitle={
            <>
              <FontAwesomeIcon icon={faCircle} color="#d81b60" size={"1x"} />{" "}
              CLIQUÉS
            </>
          }
          panelLabel={`Soit ${smsStatsData.globalStats.clickers} sms`}
          panelValue={`${getPercentage(
            smsStatsData.globalStats.delivered,
            smsStatsData.globalStats.clickers
          )}%`}
        />,
        <StatsPanel
          panelTitle={
            <>
              <FontAwesomeIcon icon={faCircle} color="#FF8900" size={"1x"} />{" "}
              ERREURS
            </>
          }
          panelLabel={`Soit ${smsStatsData.globalStats.errors} sms`}
          panelValue={`${getPercentage(
            smsStatsData.globalStats.sent,
            smsStatsData.globalStats.errors
          )}%`}
        />,
      ];
    }
    return [];
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }

    const {
      packages,
      guests,
      responses,
      componentEntrance,
      participants,
      participatesCame,
      participatesNotCame,
      participatesNotResponse,
      globalCampaignStats,
      campaigns,
      smsStatsData,
    } = this.state;

    const componentEntranceQuota = componentEntrance
      ? componentEntrance.data && componentEntrance.data.quotas
        ? componentEntrance.data.quotas.total
        : componentEntrance.data.quota
      : 0; //!

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          {!componentEntrance ? (
            <div className="row">
              AU moins un des éléments suivants n'a pas été créé :<br />
              Composant d'entrée
              <br />
              Package
              <br />
              Formulaire d'inscription
            </div>
          ) : (
            <>
              {/* STATS */}
              <div className="row stats-and-actions">
                <div className="metrics">
                  <div className="row-nowrap">
                    <div className={"white metric-panel"} id="_composants">
                      <div className={"metric-panel-title"}>
                        {" "}
                        <FontAwesomeIcon
                          icon={faCog}
                          color="#D1D1D1"
                          size={"1x"}
                        />{" "}
                        COMPOSANTS
                      </div>
                      <div className={"metric-panel-value"}>
                        {this.props.eventComponents.length}
                      </div>
                    </div>

                    <div className={"white metric-panel"} id="_composants">
                      <div className={"metric-panel-title"}>
                        {" "}
                        <FontAwesomeIcon
                          icon={faCircle}
                          color="#E3BD1C"
                          size={"1x"}
                        />{" "}
                        PACKAGES
                      </div>
                      <div className={"metric-panel-value"}>
                        {packages.length}
                      </div>
                    </div>
                  </div>
                  <div className="row-nowrap">
                    <div className={"white metric-panel"} id="_invite_total">
                      <div className={"metric-panel-title"}>
                        <FontAwesomeIcon
                          icon={faUser}
                          color="#d81b60"
                          size={"1x"}
                        />{" "}
                        NOMBRE D'INVITES
                      </div>
                      <div className={"metric-panel-value"}>
                        {guests.length}
                      </div>
                    </div>

                    <div className={"white metric-panel"} id="_invite_total">
                      <div className={"metric-panel-title"}>
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="#B7EAEA"
                          size={"1x"}
                        />{" "}
                        ONT REPONDU OUI
                      </div>
                      <div className={"metric-panel-value"}>{participants}</div>
                    </div>

                    <div className={"white metric-panel"} id="_invite_total">
                      <div className={"metric-panel-title"}>
                        <FontAwesomeIcon
                          icon={faTimes}
                          color="#F9C98A"
                          size={"1x"}
                        />{" "}
                        ONT REPONDU NON
                      </div>
                      <div className={"metric-panel-value"}>
                        {
                          guests.filter(
                            (guest) =>
                              !guest.participate &&
                              !guest.created_by_delegataire &&
                              responses.some(
                                (reponse) => reponse.guest_id === guest.id
                              )
                          ).length
                        }
                      </div>
                    </div>

                    <div className={"white metric-panel"} id="_invite_total">
                      <div className={"metric-panel-title"}>
                        <FontAwesomeIcon
                          icon={faQuestion}
                          color="#D1D1D1"
                          size={"1x"}
                        />{" "}
                        SANS RÉPONSE
                      </div>
                      <div className={"metric-panel-value"}>
                        {participatesNotResponse.length}
                      </div>
                    </div>
                  </div>
                  <div className="row-nowrap">
                    <div
                      className={"white metric-panel"}
                      id="_participant_total"
                    >
                      <div className={"metric-panel-title"}>
                        <FontAwesomeIcon
                          icon={faUsers}
                          color="black"
                          size={"1x"}
                        />{" "}
                        NOMBRE DE PARTICIPANTS
                      </div>
                      <div className={"metric-panel-value"}>{participants}</div>
                    </div>

                    <div
                      className={"white metric-panel"}
                      id="_participant_total"
                    >
                      <div className={"metric-panel-title"}>
                        <FontAwesomeIcon
                          icon={faPercent}
                          color="#3F95F8"
                          size={"1x"}
                        />{" "}
                        TAUX DE REMPLISSAGE
                      </div>
                      <div className={"metric-panel-value"}>
                        {Math.round(
                          (responses.filter((guestResponse) =>
                            guestResponse &&
                            guestResponse.responses &&
                            guestResponse.responses.length > 0
                              ? guestResponse.responses.some(
                                  (response) =>
                                    response.id === componentEntrance.id &&
                                    response.key === 0
                                )
                              : null
                          ).length *
                            100) /
                            componentEntranceQuota
                        )}
                        %
                      </div>
                    </div>

                    <div className="row-nowrap">
                      <div className={"white metric-panel"} id="_invite_total">
                        <div className={"metric-panel-title"}>
                          <FontAwesomeIcon
                            icon={faMailBulk}
                            color="black"
                            size={"1x"}
                          />{" "}
                          CAMPAGNES
                        </div>
                        <div className={"metric-panel-value"}>
                          {campaigns.length}
                        </div>
                      </div>
                    </div>
                    <div className="row-nowrap">
                      <div
                        className={"white metric-panel"}
                        id="_sms_campaign_total"
                      >
                        <div className={"metric-panel-title"}>
                          <FontAwesomeIcon
                            icon={faMailBulk}
                            color="black"
                            size={"1x"}
                          />{" "}
                          CAMPAGNES SMS
                        </div>
                        <div className={"metric-panel-value"}>
                          {smsStatsData.globalStats
                            ? smsStatsData.globalStats.campaignsNbr
                            : 0}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row-nowrap">
                    <div className={"white metric-panel"} id="_composants">
                      <div className={"metric-panel-title"}>
                        <FontAwesomeIcon
                          icon={faArrowCircleRight}
                          color="#7FCE70"
                          size={"1x"}
                        />{" "}
                        VENUS
                      </div>
                      <div className={"metric-panel-value"}>
                        {participatesCame.length}
                      </div>
                    </div>

                    <div className={"white metric-panel"} id="_composants">
                      <div className={"metric-panel-title"}>
                        <FontAwesomeIcon
                          icon={faBan}
                          color="#FF5757"
                          size={"1x"}
                        />{" "}
                        PAS VENUS
                      </div>
                      <div className={"metric-panel-value"}>
                        {participatesNotCame.length}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              {/* END OF STATS */}

              <DashboardStatistics
                statsTitle="Emails statistiques des campagnes"
                statsType="emails"
                searchBarLabel="Filtrer par nom ou sujet de la campagne"
                globalCampaignStats={globalCampaignStats}
                campaigns={campaigns}
                globalStatsPanels={this.populateGlobalStatsPanels()}
              />
              <DashboardStatistics
                statsTitle="Sms statistiques des campagnes"
                statsType="sms"
                searchBarLabel="Filtrer par nom ou sujet de la campagne"
                globalCampaignStats={smsStatsData.globalStats}
                campaigns={smsStatsData.statsList}
                globalStatsPanels={this.populateSmsGlobalStatsPanels()}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default DashBoardContainer;
