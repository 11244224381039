import React, { Component } from "react";
import ApiService from "../../../services/ApiService";
import ComposantAdvancedConfig from "../components/ComposantAdvancedConfig";
import ComposantForm from "../components/ComposantForm";
import { Switch, Route, NavLink } from "react-router-dom";
import {
  languagesList,
  initComponentResponsesOptions,
  quotaWarningLabel,
} from "../../../common/Translations";
import Loader from "../../../common/Loader";
import ComposantFormText from "../components/ComposantFormText";
import { toast } from "react-toastify";

class ComponentContainer extends Component {
  constructor(props) {
    super(props);

    this.saveComposant = this.saveComposant.bind(this);

    this.componentData = {
      name: "",
      componentType: "display",
      isFree: true,
      tax: 5.5,
      priceType: "unique",
      quotaWarning: 5,
      quotaWarningLabel: quotaWarningLabel,
      billingLabel: languagesList,
      quotas: {
        total: 1000000, // illimité
        delegataires: [], //! how to handle if package is deleted
      },
      reponse: {
        displayCheckin: false,
        tags: [],
        reponseLabel: languagesList,
        websiteLabel: languagesList,
        passportLabel: languagesList,
        reponseDescription: languagesList,
        websiteDescription: languagesList,
        passportDescription: languagesList,
        options: initComponentResponsesOptions(),
        formWidth: "12",
        htmlType: "radio",
        mapIframe: "",
        isPreFilled: false,
        role: "standard",
        displayType: "list",
        guestFieldRequired: "",
      },
      startDate: new Date(this.props.event.start_date),
      endDate: new Date(this.props.event.end_date),
    };

    this.state = {
      isLoading: false,
      composant: {
        data: {
          ...this.componentData,
        },
        system: false,
        global: false,
      },
      componentParticipants: 0,
    };
  }

  async saveComposant(data) {
    const { composant } = this.state;
    const { event, componentsList, onSave, history } = this.props;
    let method = composant.id ? "put" : "post";
    let id = composant.id ? composant.id : "";
    if (data.reponse.componentsList) {
      delete data.reponse.componentsList;
    }
    const component = await ApiService.awaitRequest(
      {
        ...composant,
        data,
      },
      "components/" + id,
      method
    );
    //console.log(component, "ici ?");
    if (component) {
      toast.success("Composant sauvegardé", {
        autoClose: 3000,
      });

      this.setState({
        composant: { ...component },
      });

      if (method === "post") {
        this.setState({
          isLoading: true,
        });

        const ids = [];
        componentsList.map((component) => ids.push(component.id));
        ids.push(component.id);

        onSave({
          ...event,
          component_ids: ids,
        });
        history.push(
          "/admin/events/edit/" + event.id + "/components/edit/" + component.id
        );
      } else {
        const requiredRule = event.component_rules.componentsRules.find(
          (rule) =>
            Number(rule.component1) === component.id &&
            rule.componentRule === "required"
        );

        if (component.data.reponse.role === "entrance" && !requiredRule) {
          const componentsRules = event.component_rules.componentsRules;

          componentsRules.push({
            component1: component.id,
            componentRule: "required",
            isFixed: true,
          });

          onSave({
            ...event,
            component_rules: { ...event.component_rules, componentsRules },
          });
        }
      }
    } else {
      toast.success("Erreur lors de l'enregistrement", {
        autoClose: 3000,
      });
    }
  }

  async componentDidMount() {
    const self = this;
    if (this.props.match.params.componentid) {
      self.setState({ isLoading: true });
      await ApiService.request(
        this.state,
        "components/" + this.props.match.params.componentid,
        "get"
      ).then(function (data) {
        self.setState({
          isLoading: false,
          composant: {
            ...self.state.composant,
            ...data,
            data: {
              ...self.state.composant.data,
              ...data.data,
              reponse: {
                ...self.state.composant.data.reponse,
                ...data.data.reponse,
              },
            },
          },
        });
      });

      const componentParticipants = await ApiService.awaitRequest(
        this.state,
        "components/participants/foradmin/" +
          this.props.match.params.componentid +
          "/" +
          this.props.match.params.eventid,
        "get"
      );

      if (componentParticipants) {
        this.setState({ componentParticipants });
      }
    }
  }

  render() {
    const { composant } = this.state;
    const menu = [
      {
        label: "Configuration générale",
        link:
          "/admin/events/edit/" +
          this.props.match.params.eventid +
          "/components/edit/" +
          composant.id,
        className: "nav-link",
      },
      {
        label: "Configuration avancée",
        link:
          "/admin/events/edit/" +
          this.props.match.params.eventid +
          "/components/edit/" +
          composant.id +
          "/workflow",
        className:
          this.state.composant.data.componentType !== "logistic" &&
          this.state.composant.data.componentType !== "moment"
            ? "d-none nav-link"
            : "nav-link",
      },
      {
        label: "Configuration de support",
        link:
          "/admin/events/edit/" +
          this.props.match.params.eventid +
          "/components/edit/" +
          composant.id +
          "/form",
        className: "nav-link",
      },
    ];

    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <div className="row">
        <div className="col-md-12 mb-3 custom-nav">
          <div className="row">
            {this.state.composant.id
              ? menu.map((item, index) => (
                  <div
                    key={`event-component-container-menu-${index}`}
                    className="col-md-2 ml-2"
                  >
                    <NavLink className={item.className} to={item.link}>
                      {item.label}
                    </NavLink>
                  </div>
                ))
              : null}
          </div>
        </div>
        <div className="col-md-12">
          <h4>Nom du composant : {this.state.composant.data.name}</h4>
          <Switch>
            <Route
              exact
              path="/admin/events/edit/:eventid?/components/edit/:componentid?/"
              render={(props) => (
                <>
                  {/* <ComposantMainConfig
                    {...props}
                    onSave={this.saveComposant}
                    data={this.state.composant.data}
                    canActivateComponentInCheckin={true}
                  /> */}
                  <ComposantForm
                    {...props}
                    id={composant.id}
                    componentsList={this.props.componentsList}
                    onSave={this.saveComposant}
                    data={this.state.composant.data}
                    canActivateComponentInCheckin={true}
                    initialComponentData={this.componentData}
                    // eventDate={{startDate: , endDate:this.props.event.endDate}}
                  />
                </>
              )}
            />
            <Route
              exact
              path="/admin/events/edit/:eventid?/components/edit/:componentid/workflow"
              render={(props) => (
                <ComposantAdvancedConfig
                  {...props}
                  onSave={this.saveComposant}
                  data={this.state.composant.data}
                  eventDates={{
                    startDate: this.props.event.start_date
                      ? this.props.event.start_date
                      : new Date(),
                    endDate: this.props.event.end_date
                      ? this.props.event.end_date
                      : new Date(),
                  }}
                  componentParticipants={this.state.componentParticipants}
                />
              )}
            />
            <Route
              exact
              path="/admin/events/edit/:eventid?/components/edit/:componentid/form"
              render={(props) => (
                <ComposantFormText
                  eventId={this.props.match.params.eventid}
                  onSave={this.saveComposant}
                  data={this.state.composant.data}
                />
              )}
            />
          </Switch>
        </div>
      </div>
    );
  }
}
export default ComponentContainer;
