import React from "react";
import { Link } from "react-router-dom";
import ApiService from "../../../services/ApiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../../common/Loader";
import {
  faEdit,
  faTrashAlt,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
//import { faClone } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { MDBSpinner } from "mdbreact";
import {
  colorByStatus,
  dateFormat,
  getStatusCampaign,
  sortedByDates,
  isEventPaymentConfigOk,
} from "../../../services/Utils";
import GuestsErrorModal from "../../../common/GuestsErrorModal";

class CampaignsList extends React.Component {
  constructor(props) {
    super(props);
    this.deleteCampaign = this.deleteCampaign.bind(this);
    this.duplicateCampaign = this.duplicateCampaign.bind(this);
    this.loadData = this.loadData.bind(this);
    this.openErrorModal = this.openErrorModal.bind(this);
    this.state = {
      campaignsList: [],
      isLoading: true,
      duplicateCampaignLoading: false,
      errorModal: false,
      campaignModal: "",
      invoiceSetting: [],
      event: [],
    };
  }

  async loadData() {
    const self = this;
    await ApiService.awaitRequest(
      this.state,
      "evenements/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      // console.log(data, "console.log")
      self.setState({
        event: data,
      });
    });

    await ApiService.request(
      {},
      "campaigns/byevenement/" + this.props.match.params.eventid,
      "get"
    ).then(function (data) {
      self.setState({
        campaignsList: data,
        isLoading: false,
      });
    });

    if (this.state.event.data.free === "false") {
      await ApiService.request(
        {},
        "invoices/settings/" + this.state.event.children_entity_id,
        "get"
      ).then(function (data) {
        self.setState({
          invoiceSetting: data,
        });
      });
    }
  }

  componentDidMount() {
    this.loadData();
  }

  async deleteCampaign(id) {
    const self = this;
    self.setState({ isLoading: true });

    await ApiService.request({}, "campaigns/" + id, "delete").then(function () {
      self.loadData();
    });
  }

  async duplicateCampaign(id) {
    const self = this;
    this.setState({ duplicateCampaignLoading: true });
    try {
      await ApiService.request({}, "campaigns/duplicate/" + id, "post").then(
        function (copy) {
          if (copy) {
            self.setState({
              campaignsList: [copy, ...self.state.campaignsList],
            });
            toast.success("Campagne dupliqué", {
              autoClose: 3000,
            });
          } else {
            toast.error("Erreur dans la duplication", {
              autoClose: 3000,
            });
          }
        }
      );
    } catch (e) {
      console.log(e);
      toast.error("Erreur dans la duplication", {
        autoClose: 3000,
      });
    }
    this.setState({ duplicateCampaignLoading: false });
  }
  openErrorModal = (campaign) => {
    const { errorModal } = this.state;
    this.setState({
      errorModal: !errorModal,
      campaignModal: campaign ? (!errorModal ? campaign : "") : "",
    });
  };

  render() {
    const filters = sortedByDates(
      [...this.state.campaignsList],
      "schedule_date"
    );
    const sortedCampaigns =
      filters.length > 0 ? filters : this.state.campaignsList;
    const { errorModal, campaignModal } = this.state;
    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <div className="row">
        {!isEventPaymentConfigOk(
          this.state.event.data.free,
          this.state.invoiceSetting.stripePublicKey,
          this.state.invoiceSetting.stripeSecretKey,
          this.props.components
        ) ? (
          `L'évènement est payant, vous devez mettre en place une ou des solutions de paiements pour l'Entité de l'évènement pour accèder aux campagnes`
        ) : (
          <div className="col-md-10 offset-md-1 main-content">
            <div className="row">
              <div className={"col-md-10"}>
                <h2>Liste des campagnes</h2>
              </div>

              <div className="col-md-2">
                <Link
                  className="btn pink darken-1 float-right white-text"
                  to={
                    "/admin/events/edit/" +
                    this.props.match.params.eventid +
                    "/campaigns/edit/"
                  }
                >
                  Nouvelle campagne
                </Link>
              </div>
            </div>
            {this.state.duplicateCampaignLoading ? (
              <>
                <br />
                <div className="text-center">
                  <br />
                  <MDBSpinner big red />
                </div>
              </>
            ) : null}
            <br />
            <table className="table table-sm table-striped">
              {errorModal && (
                <GuestsErrorModal
                  open={errorModal}
                  setModal={this.openErrorModal}
                  campaign={campaignModal}
                />
              )}
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Nom</th>
                  <th scope="col">Date d'envoi</th>
                  <th scope="col">Statut</th>
                  <th scope="col">Nb de Contacts</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {sortedCampaigns.map((campaign) => {
                  const status = getStatusCampaign(campaign);
                  const nbContact = campaign.nbContact;
                  const { error_data } = campaign;
                  return (
                    <tr key={campaign.id}>
                      <td>{campaign.id}</td>
                      <td>{campaign.title}</td>
                      <td>{dateFormat(campaign.schedule_date)}</td>
                      <td style={{ color: colorByStatus(status.key) }}>
                        {status.label}
                      </td>
                      <td>{nbContact}</td>
                      <td>
                        &nbsp;&nbsp;&nbsp;
                        <Link
                          to={
                            "/admin/events/edit/" +
                            this.props.match.params.eventid +
                            "/campaigns/edit/" +
                            campaign.id
                          }
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Link>
                        {/* &nbsp;&nbsp;&nbsp;
                      <Link to={"#"}>
                        <FontAwesomeIcon
                          icon={faClone}
                          onClick={() => this.duplicateCampaign(campaign.id)}
                        />
                      </Link> */}
                        {error_data && (
                          <>
                            &nbsp;&nbsp;&nbsp;
                            <Link to={"#"}>
                              <FontAwesomeIcon
                                icon={faExclamationCircle}
                                color={"#d81b60"}
                                onClick={() => this.openErrorModal(campaign)}
                              />
                            </Link>
                          </>
                        )}
                        &nbsp;&nbsp;&nbsp;
                        <span
                          className="pointer"
                          onClick={() => {
                            if (
                              window.confirm(
                                "Etes vous sûr de supprimer cette campagne ?"
                              )
                            )
                              this.deleteCampaign(campaign.id);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default CampaignsList;
