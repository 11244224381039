import React, { useState } from "react";
import Multilingual from "../../../common/Multilingual";
import Switch from "../../../common/Switch";

function ComposantFormText({ data, onSave, eventId }) {
  const [displayCheckin, setDisplayCheckin] = useState(
    data.reponse.displayCheckin || false
  );
  const [reponseLabel, setReponseLabel] = useState(data.reponse.reponseLabel);
  const [websiteLabel, setWebsiteLabel] = useState(data.reponse.websiteLabel);
  const [passportLabel, setPassportLabel] = useState(
    data.reponse.passportLabel
  );
  const [reponseDescription, setReponseDescription] = useState(
    data.reponse.reponseDescription
  );
  const [websiteDescription, setWebsiteDescription] = useState(
    data.reponse.websiteDescription
  );
  const [passportDescription, setPassportDescription] = useState(
    data.reponse.passportDescription
  );
  const [displayInPassport, setDisplayInPassport] = useState(
    data.reponse.displayInPassport || false
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    // const { componentsList, ...reponse } = this.state;
    onSave({
      ...data,
      reponse: {
        ...data.reponse,
        displayCheckin,
        reponseLabel,
        websiteLabel,
        passportLabel,
        reponseDescription,
        websiteDescription,
        passportDescription,
        displayInPassport,
      },
    });
  };
  return (
    <div className="row">
      <form
        className="form col-md-10 offset-md-1 main-content"
        onSubmit={handleSubmit}
      >
        <br />
        <br />
        <div className="form-row">
          <div className="form-group col-md-12">
            <Switch
              // onClick={() => setDisplayCheckin((prev) => !prev)}
              onChange={(e) => setDisplayCheckin(e.target.checked)}
              id="display-component-response-in-checkin"
              label="Afficher la réponse au checkin"
              filled
              checked={displayCheckin}
              // type="checkbox"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <Switch
              onChange={(e) => setDisplayInPassport(e.target.checked)}
              checked={displayInPassport}
              id="display-component-in-passport"
              label="Afficher dans le passport ?"
            />
            {displayInPassport && (
              <small style={{ color: "red" }}>
                *Attention ce composant s'affichera dans le passeport, même sans
                réponse des invités.
              </small>
            )}
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-12">
            <label>label dans l'interface de réponse</label>
            <Multilingual
              eventId={eventId}
              lang={reponseLabel}
              type="input"
              handleLang={(e) => setReponseLabel(e)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label>label dans l'interface de communication</label>
            <Multilingual
              eventId={eventId}
              lang={websiteLabel}
              type="input"
              handleLang={(e) => setWebsiteLabel(e)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label>label dans le passeport</label>
            <Multilingual
              eventId={eventId}
              lang={passportLabel}
              type="textarea"
              handleLang={(e) => setPassportLabel(e)}
            />
          </div>
        </div>
        <div className="form-group">
          <label>Descriptif dans l'interface de réponse</label>
          <Multilingual
            eventId={eventId}
            lang={reponseDescription}
            type="textarea"
            handleLang={(e) => setReponseDescription(e)}
          />
        </div>
        <div className="form-group">
          <label>Descriptif dans l'interface de communication</label>
          <Multilingual
            eventId={eventId}
            lang={websiteDescription}
            type="textarea"
            handleLang={(e) => setWebsiteDescription(e)}
          />
        </div>
        <div className="form-group">
          <label>Descriptif dans le passeport</label>
          <Multilingual
            eventId={eventId}
            lang={passportDescription}
            type="textarea"
            handleLang={(e) => setPassportDescription(e)}
          />
        </div>
        <input
          type="submit"
          value="Enregistrer"
          className="btn pink darken-1 float-right white-text"
        />
      </form>
    </div>
  );
}

export default ComposantFormText;
