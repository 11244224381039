import React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

function LinearLoader({ width }) {
  return (
    <Box sx={{ width, margin: "auto" }}>
      <LinearProgress
        style={{ backgroundColor: "rgb(216 27 96 / 14%)" }}
        sx={{
          "& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#d81b60",
          },
        }}
      />
    </Box>
  );
}

export default LinearLoader;
