import React from "react";
import { Link } from "react-router-dom";
import ApiService from "../../../services/ApiService";
import Loader from "../../../common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

class EntitiesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { entities: [], isLoading: true };
    this.loadData = this.loadData.bind(this);
    this.deleteEntity = this.deleteEntity.bind(this);
  }

  loadData() {
    const self = this;
    ApiService.request({}, "children_entities", "get").then(function (data) {
      self.setState({ entities: data, isLoading: false });
    });
  }

  componentDidMount() {
    this.loadData();
  }

  deleteEntity(id) {
    const self = this;
    ApiService.request({}, "children_entities/" + id, "delete").then(
      function () {
        self.loadData();
      }
    );
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }

    const { entities } = this.state;

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <div className="row">
            <div className="col-md-10">
              <h2>Liste des entités</h2>
            </div>
            <div className="col-md-2">
              <Link
                className="btn pink darken-1 float-right white-text"
                to="/admin/entities/edit"
              >
                Nouvelle entité
              </Link>
            </div>
          </div>
          <br />
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th scope="col">Nom</th>
              </tr>
            </thead>
            <tbody>
              {entities.map((entity, i) => (
                <tr key={i}>
                  <td>{entity.data.name}</td>

                  <td>
                    &nbsp;&nbsp;&nbsp;
                    <Link to={"/admin/entities/edit/" + entity.id}>
                      <FontAwesomeIcon icon={faEdit} />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default EntitiesList;
