import { toast } from "react-toastify";
import ApiService from "../../services/ApiService";
import { formatDate } from "../../services/Utils";

const filterPlacements = (placements, checkinPoint) => {
  if (checkinPoint && checkinPoint.placements) {
    return placements.filter((placement) =>
      checkinPoint.placements.find((plcmt) => plcmt.id === placement.id)
    );
  }
  return [];
};

const getCheckinPointComponentIds = (checkinPointsList, checkinPointId) => {
  let ids = [];
  if (checkinPointsList && checkinPointsList.length > 0 && checkinPointId) {
    const checkinPoint = getCheckinPoint(checkinPointsList, checkinPointId);
    if (checkinPoint && checkinPoint.components) {
      ids = checkinPoint.components.map((component) => component.id);
    }
  }
  return ids;
};

const getCheckinPoint = (checkinPointsList, checkinPointId) => {
  const checkinPoint = checkinPointsList.find(
    (checkinPt) => checkinPt.id === checkinPointId
  );

  return checkinPoint;
};

export const getCheckinData = async (
  eventId,
  event,
  checkinPointId,
  formId,
  isFirstRender,
  setEvent,
  setGuests,
  setPlacements,
  setIsLoading,
  setFormId,
  setCheckinPoint
) => {
  try {
    let tmpEvent = event;
    let tmpFormId = formId;
    if (isFirstRender && isFirstRender.current) {
      const checkinDetails = await ApiService.awaitRequest(
        {},
        "evenements/checkin/details/" + eventId + "/" + checkinPointId,
        "get"
      );

      if (checkinDetails) {
        const checkinPoint =
          checkinDetails.event && checkinDetails.event.checkin_points
            ? checkinDetails.event.checkin_points.find(
              (checkinPoint) => checkinPoint.id === checkinPointId
            )
            : null;

        if (
          checkinDetails.placements &&
          checkinDetails.placements.length > 0 &&
          checkinPoint
        ) {
          const placements = filterPlacements(
            checkinDetails.placements,
            checkinPoint
          );
          setPlacements(placements);
        }

        setEvent(checkinDetails.event);
        setFormId(checkinDetails.form_id);
        tmpEvent = checkinDetails.event;
        tmpFormId = checkinDetails.form_id;
        isFirstRender.current = false;
      }
    }

    if (tmpEvent && tmpFormId) {
      const checkinPoint = getCheckinPoint(
        tmpEvent.checkin_points,
        checkinPointId
      );

      setCheckinPoint(checkinPoint);

      const componentIds = getCheckinPointComponentIds(
        tmpEvent.checkin_points,
        checkinPointId
      );

      if (componentIds && componentIds.length > 0) {
        const guests = await ApiService.awaitRequest(
          {
            componentIds,
          },
          "evenements/checkin/refresh_guests/" + tmpFormId,
          "post"
        );

        setGuests(guests);
      }
    }
  } catch (error) {
    console.log(error);
    toast.error("Une erreur s'est produite durant la mise à jour des invités.");
  } finally {
    setIsLoading(false);
  }
};

export const isGuestChecked = (guest, checkinPointId) => {
  return (
    guest &&
    guest.checkin_points &&
    guest.checkin_points.some(
      (checkinPoint) =>
        checkinPoint.id === checkinPointId && checkinPoint.status
    )
  );
};

//TODO: DELETE FROM CODEBASE
export const buildCheckInData = (
  originalCheckInData,
  status,
  result,
  componentId
) => {
  const now = new Date();

  let checkinData = originalCheckInData ? originalCheckInData : [];

  if (status) {
    checkinData.push({
      id: componentId,
      result,
      time: formatDate(now, true),
    });
  } else {
    const items = checkinData.filter((data) => data.id === componentId);

    const newItems = items.slice(0, -1);

    const itemsWitoutComponents = checkinData.filter(
      (data) => data.id !== componentId
    );

    checkinData = newItems.concat(itemsWitoutComponents);
  }

  return checkinData;
};

export const getGuestPlacement = (placements, guest) => {
  let res = [];

  if (placements) {
    placements.forEach((placement) => {
      const seat = placement.data.seat.find(
        (seat) => seat.guestId === guest.id
      );
      if (seat) {
        const temp = seat.seatId.split("_r");
        const rangplace = temp[temp.length - 1].split("_p");
        let place = rangplace[1];
        place = place && place.split("_")[1] ? place.split("_")[0] : place;
        let rank = rangplace[0].slice(0);
        res.push({
          plan: placement.name,
          zone: temp[0],
          rank,
          place,
        });
      }
    });
  } else {
    res = [];
  }

  return res;
};

export const getGuestCheckinPoint = (guest, checkinPointId) => {
  if (guest && guest.checkin_points && guest.checkin_points.length > 0) {
    return guest.checkin_points.find(
      (checkinPoint) => checkinPoint.id === checkinPointId
    );
  }
  return null;
};

export const getGuestNbrOfCheck = (guest, checkinPointId) => {
  const checkinPoint = getGuestCheckinPoint(guest, checkinPointId);

  if (checkinPoint) {
    return checkinPoint.nbrOfCheck;
  }
  return 0;
};

export const hasGuestBeenChecked = (guest) => {
  if (guest && guest.checkin_points && guest.checkin_points.length > 0) {
    return guest.checkin_points.some(
      (checkinPoint) => checkinPoint.nbrOfCheck > 0
    );
  }
  return false;
};

const getCheckinPointStatus = (checkinPoints, checkinPointId, hasBeenChecked) => {

  if (!checkinPoints) {
    return false;
  }

  if (checkinPoints && checkinPoints.length <= 0) {
    console.log("ici");
    return hasBeenChecked;
  }

  const findPoint = checkinPoints.find((check) => {
    return check.id === checkinPointId
  })
  if (findPoint) {
    // Vérifier lors de l'ajout si le quota dépasse 0
    if (hasBeenChecked && findPoint.nbrOfCheck + 1 > 0) {
      return hasBeenChecked;
    }
    // Vérifier lors de l'annulation si le quota atteint 0
    if (!hasBeenChecked && findPoint.nbrOfCheck - 1 <= 0) {
      return hasBeenChecked;
    }
    return findPoint.nbrOfCheck > 0;
  } else {
    return hasBeenChecked;

  }
}

export const updateGuestsCheckinPoints = (
  checkinPoints,
  checkinPointId,
  hasBeenChecked
) => {
  const status = getCheckinPointStatus(checkinPoints, checkinPointId, hasBeenChecked);
  const newCheckinPoints = [...checkinPoints];
  const now = new Date();
  const checkinPoint = {
    id: checkinPointId,
    status,
    nbrOfCheck: 1,
    time: formatDate(now, true),
  };

  if (checkinPoints && checkinPointId) {
    if (checkinPoints.length === 0) {
      newCheckinPoints.push(checkinPoint);
      return newCheckinPoints;
    }

    const checkinPointIndex = newCheckinPoints.findIndex(
      (checkinPt) => checkinPt.id === checkinPointId
    );

    if (checkinPointIndex > -1) {
      //? update current checkin point
      const nbrOfCheck = hasBeenChecked
        ? newCheckinPoints[checkinPointIndex].nbrOfCheck + 1
        : newCheckinPoints[checkinPointIndex].nbrOfCheck <= 0
          ? 0
          : newCheckinPoints[checkinPointIndex].nbrOfCheck - 1;

      checkinPoint.nbrOfCheck = nbrOfCheck;

      newCheckinPoints[checkinPointIndex] = checkinPoint;
    } else {
      //? create new checkin point
      newCheckinPoints.push(checkinPoint);
    }
  }
  return newCheckinPoints;
};

export const updateGuestCheckinData = async (
  guest,
  status,
  checkinPointId,
  setGuests,
  setSelectedGuest,
  handleDetailsDialogOpen
) => {
  try {
    const guestCheckinPoints = await ApiService.awaitRequest(
      {},
      "guests/" + guest.id + "/checkinPoints",
      "get"
    );

    if (guestCheckinPoints) {
      const newCheckinPoints = updateGuestsCheckinPoints(
        guestCheckinPoints,
        checkinPointId,
        status,
      );

      ApiService.awaitRequest(
        {
          checkin_points: newCheckinPoints,
        },
        "guests/checkin/" + guest.id,
        "put"
      );
    }

    //! WHY NOT ADD A LOADER WAITING FOR GUEST TO BE UPDATED THEN OPEN THE DETAILSDIALOG INSTEAD OF CREATING A FAKE RESPONSE WITHOUT KNOWING IF REQUEST WILL SUCCCEED OR NOT
    if (window.navigator.onLine) {
      const newCheckinPoints = updateGuestsCheckinPoints(
        guest.checkin_points,
        checkinPointId,
        status
      );

      const updatedGuest = { ...guest, checkin_points: newCheckinPoints };
      setGuests((prev) => {
        const newGuests = prev.map((gst) =>
          gst.id === updatedGuest.id ? updatedGuest : gst
        );
        return newGuests;
      });
      setSelectedGuest(updatedGuest);
      if (status) handleDetailsDialogOpen(true);
    } else {
      toast.error("Pas de connexion internet", 10000);
    }
  } catch (error) {
    console.log(error);
    toast.error("Erreur lors du checkin");
  }
};
