import React from "react";
import ApiService from "../services/ApiService";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBBtn,
} from "mdbreact";
import { toast } from "react-toastify";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = { disableSubmitButton: false };
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ disableSubmitButton: true });
    const self = this;
    ApiService.request(
      { password: this.state.password, token: this.props.match.params.token },
      "users/resetpassword",
      "post"
    )
      .then(function () {
        toast.success("Mot de passe mis à jour", {
          autoClose: 2500,
          onClose: () => {
            window.location.replace(window.location.origin);
          },
        });
      })
      .catch(function () {
        self.setState({ disableSubmitButton: false });
        toast.error("Erreur lors du renouvellement de mot de passe", {
          autoClose: 3000,
        });
      });
  }

  render() {
    return (
      <MDBContainer className="mt-5">
        <MDBRow center>
          <MDBCol md="6">
            <MDBCard>
              <form onSubmit={(e) => this.handleSubmit(e)}>
                <MDBCardBody className="mx-4">
                  <div className="text-center">
                    <h3 className="dark-grey-text mb-5">
                      <strong>Modifier le mot de passe</strong>
                    </h3>
                  </div>
                  <MDBInput
                    minLength={5}
                    onChange={this.handleChange}
                    name="password"
                    value={this.state.password}
                    label="Nouveau mot de passe"
                    group
                    type="password"
                    validate
                    error="wrong"
                    success="right"
                    required
                  />

                  <div className="text-center mb-3">
                    <MDBBtn
                      rounded
                      type="submit"
                      className="btn-block z-depth-1a pink darken-1"
                      disabled={this.state.disableSubmitButton}
                    >
                      Valider
                    </MDBBtn>
                  </div>
                </MDBCardBody>
              </form>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default ResetPassword;
