import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import "./GuestsContainer.css";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { MDBInput } from "mdbreact";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import ApiService from "../../../services/ApiService";
import filesize from "filesize";
import { MDBSpinner } from "mdbreact";
import SmallLoader from "../../../common/SmallLoader";
import moment from "moment";
import { toast } from "react-toastify";
// import { capitalizeFirstLetter } from '../../../services/Utils';
import debounce from "lodash.debounce";

export default function UploadFileModal({
  open,
  setModal,
  guest,
  followers,
  isAccountGuest,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [documentInDownload, setDocumentInDownload] = useState({
    id: "",
    isDownloading: false,
  });
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [openAddFileInputs, setOpenAddFileInputs] = useState(false);
  const [fileToDelete, setFileToDelete] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("billet");
  const [fileObject, setFileObject] = useState({});
  const [guestAssociated, setGuestAssociated] = useState("");
  const [totalSize, setTotalSize] = useState(0);
  const [isSelected, setIsSelected] = useState(false);
  const [sizeReached, setSizeReached] = useState(false);
  const [load, setLoad] = useState(false);
  const [disabled, setDisable] = useState(false);
  const fileRef = useRef();
  const spaceAvailable = 15;
  useEffect(() => {
    loadDocuments();
  }, []);

  useEffect(() => {
    getTotalSize();
  }, [documents]);

  const loadDocuments = async () => {
    setIsLoading(true);
    if (guest) {
      await ApiService.request({}, "documents/byguest/" + guest.id, "get").then(
        function (data) {
          if (data) {
            setDocuments(data);
          }
        }
      );
    }
    setIsLoading(false);
  };
  const getTotalSize = () => {
    const arraySize = [];
    documents.forEach((element) => {
      arraySize.push(Number(element.size));
    });
    const sum = arraySize.reduce((a, b) => a + b, 0);
    const total = Number(sum.toFixed(2));
    setTotalSize(total);
  };

  const checkFile = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      setIsSelected(false);
      return;
    }
    const path = fileRef.current
      ? fileRef.current.value
        ? fileRef.current.value
        : ""
      : "";
    const fileNamePath = path ? path.split(/(\\|\/)/g).pop() : "";
    const file = files[0];
    const roundedSize = filesize(file.size, {
      output: "array",
      exponent: 2,
    })[0];
    if (roundedSize + totalSize >= spaceAvailable) {
      setSizeReached(true);
      return;
    }
    const name = fileNamePath ? fileNamePath : file.name;
    const newFile = new File([file], name, { type: file.type });
    const obj = {
      size: roundedSize,
      format: file.type,
      file: newFile,
    };
    const fileWithoutExtension = name.split(".").slice(0, -1).join(".");
    setSizeReached(false);
    setIsSelected(true);
    setFileObject(obj);
    setFileName(fileWithoutExtension);
  };

  const handleAddingFile = (e) => {
    e.preventDefault();
    setLoad(true);
    setDisable(true);
    addFile();
    setTimeout(() => {
      setLoad(false);
      setDisable(false);
    }, 2000);
  };

  const addFile = debounce(async () => {
    if (fileObject.file) {
      const formData = new FormData();
      formData.append("name", fileName);
      formData.append("document", fileObject.file);
      formData.append("type", fileType);
      formData.append("size", String(fileObject.size));
      formData.append("format", fileObject.format);
      if (guest) {
        formData.append("guestId", guest.id);
      }
      if (guestAssociated) {
        const associated = followers.find(
          (follower) => follower.id === guestAssociated
        );
        if (associated) {
          formData.append("associatedId", associated.id);
        }
      }
      await ApiService.awaitRequest(formData, "documents", "post", true)
        .then((res) => {
          if (!res) {
            toast.error("Impossible d'ajouter le fichier");
          } else {
            updateDocuments(res, false);
            setFileName("");
            setIsSelected(false);
            setFileObject({});
            setGuestAssociated("");
            setOpenAddFileInputs(false);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error("Impossible d'ajouter le fichier");
        });
    }
  }, 1500);
  const deleteFile = async () => {
    if (fileToDelete) {
      const res = await ApiService.awaitRequest(
        {},
        "documents/" + fileToDelete.id,
        "delete"
      );
      res && updateDocuments(fileToDelete, true);
    }
    setDeleteModal(false);
    setFileToDelete("");
  };
  const openToDelete = (file) => {
    setDeleteModal(true);
    setFileToDelete(file);
  };

  const abort = () => {
    setDeleteModal(false);
    setFileToDelete("");
  };

  const download = async (doc, index) => {
    setDocumentInDownload({ id: doc.id, isDownloading: true });
    try {
      const res = await ApiService.awaitRequest(
        {},
        "documents/download/" + doc.id,
        "get",
        "",
        "application/x-www-form-urlencoded",
        "arraybuffer"
      );
      if (res) {
        let blob = new Blob([res], { type: doc.format });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = doc.file_name;
        link.click();
      }
      updateDownloadTime(doc, index);
    } catch (e) {
      console.log(e);
    }
    setDocumentInDownload({ id: doc.id, isDownloading: false });
  };

  const updateDocuments = (document, remove) => {
    if (document) {
      if (remove) {
        const newDocuments = documents.filter((doc) => doc.id !== document.id);
        setDocuments(newDocuments);
      } else {
        setDocuments([...documents, document]);
      }
    }
  };

  const updateDownloadTime = async (doc, idx) => {
    const downloadTime = JSON.parse(doc.download_time);
    let arrayDate = [];
    if (downloadTime) {
      arrayDate = [
        ...downloadTime,
        { date: moment().format("DD/MM/YYYY"), hour: moment().format("H:m") },
      ];
    } else {
      arrayDate = [
        { date: moment().format("DD/MM/YYYY"), hour: moment().format("H:m") },
      ];
    }
    const res = await ApiService.awaitRequest(
      { download_time: JSON.stringify(arrayDate) },
      "documents/" + doc.id,
      "put"
    );
    if (res) {
      documents[idx] = res;
      setDocuments(documents);
    }
  };

  return isAccountGuest ? (
    <ModalAccountGuest
      open={open}
      setModal={setModal}
      isLoading={isLoading}
      documents={documents}
      documentInDownload={documentInDownload}
      download={download}
    />
  ) : (
    <ModalAdmin
      open={open}
      setModal={setModal}
      isLoading={isLoading}
      guest={guest}
      documents={documents}
      documentInDownload={documentInDownload}
      download={download}
      openDeleteModal={openDeleteModal}
      setDeleteModal={setDeleteModal}
      fileToDelete={fileToDelete}
      abort={abort}
      deleteFile={deleteFile}
      totalSize={totalSize}
      spaceAvailable={spaceAvailable}
      setOpenAddFileInputs={setOpenAddFileInputs}
      openAddFileInputs={openAddFileInputs}
      handleAddingFile={handleAddingFile}
      fileName={fileName}
      setFileName={setFileName}
      fileType={fileType}
      setFileType={setFileType}
      checkFile={checkFile}
      isSelected={isSelected}
      fileObject={fileObject}
      guestAssociated={guestAssociated}
      setGuestAssociated={setGuestAssociated}
      followers={followers}
      openToDelete={openToDelete}
      sizeReached={sizeReached}
      disabled={disabled}
      load={load}
      fileRef={fileRef}
    />
  );
}
const ModalAccountGuest = ({
  open,
  setModal,
  isLoading,
  documents,
  documentInDownload,
  download,
}) => {
  return (
    <div>
      <Modal
        show={open}
        onHide={setModal}
        size="lg"
        className="modalUploadFile"
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
          <Modal.Title>
            <FontAwesomeIcon
              icon={faTimes}
              color={"grey"}
              onClick={setModal}
              size="xs"
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Footer bsPrefix="footer-modal-file">
          <div className={"table-container"}>
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  <th scope="col" className="nameColumn">
                    Nom du document
                  </th>
                  <th scope="col">Type</th>
                  <th scope="col">Nom du fichier</th>
                  <th scope="col">Poids (en mo)</th>
                  <th scope="col">Associé à</th>
                </tr>
              </thead>
              {isLoading ? (
                <div className="loaderUploadFile guest">
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <MDBSpinner big red />
                  </div>
                </div>
              ) : (
                documents.map((doc) => {
                  const docRelated = doc.related;
                  const getType = {
                    info: "Information",
                    billet: "Billet",
                  };
                  const isDownloading =
                    doc.id === documentInDownload.id
                      ? documentInDownload.isDownloading
                      : null;
                  return (
                    <tbody>
                      <>
                        <td>{doc.name}</td>
                        <td>{getType[doc.type]}</td>
                        <td style={{ display: "flex", flexDirection: "row" }}>
                          <FontAwesomeIcon
                            icon={faDownload}
                            size="1x"
                            style={{ cursor: "pointer" }}
                            onClick={() => download(doc)}
                          />
                          &nbsp; &nbsp;
                          {isDownloading && (
                            <>
                              <SmallLoader /> &nbsp; &nbsp;
                            </>
                          )}
                          {doc.file_name}
                        </td>
                        <td>{doc.size}mo</td>
                        <td>
                          {docRelated &&
                            docRelated.lastname + " " + docRelated.firstname}
                        </td>
                      </>
                    </tbody>
                  );
                })
              )}
            </table>
          </div>
          <div className="div-btn-footer">
            <Button variant="dark" onClick={setModal}>
              Fermer
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
const ModalAdmin = ({
  open,
  setModal,
  isLoading,
  guest,
  documents,
  documentInDownload,
  download,
  openDeleteModal,
  setDeleteModal,
  fileToDelete,
  abort,
  deleteFile,
  totalSize,
  spaceAvailable,
  setOpenAddFileInputs,
  openAddFileInputs,
  handleAddingFile,
  fileName,
  setFileName,
  fileType,
  setFileType,
  checkFile,
  isSelected,
  fileObject,
  guestAssociated,
  setGuestAssociated,
  followers,
  openToDelete,
  sizeReached,
  disabled,
  load,
  fileRef,
}) => {
  useEffect(() => {}, [documents, load, disabled]);

  return (
    <div>
      {openDeleteModal ? (
        <Modal
          show={openDeleteModal}
          onHide={setDeleteModal}
          size="sm"
          style={{ top: "10%" }}
          className="custom-modal-width"
        >
          <Modal.Body style={{ margin: 0 }}>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p style={{ marginBottom: 0 }}>Êtes-vous sur de vouloir</p>
              <p style={{ marginBottom: 0 }}>supprimer le document</p>
              <p style={{ fontWeight: "bold" }}>{fileToDelete.name}</p>
            </div>
            <div
              className="row"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <Button className="btn grey" variant="" onClick={abort}>
                Annuler
              </Button>
              <Button
                className="btn pink"
                variant=""
                onClick={deleteFile} /*bsPrefix="delete-modal-btn"*/
              >
                Supprimer
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
      <Modal
        show={open}
        onHide={setModal}
        size="lg"
        className="modalUploadFile"
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
          <Modal.Title>
            <FontAwesomeIcon
              icon={faTimes}
              color={"grey"}
              onClick={setModal}
              size="xs"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row no-wrap modal-file-body">
            <div className="column">
              <label className="label-title" style={{ marginBottom: 0 }}>
                Documents de
                {guest
                  ? guest.lastname.toUpperCase() + " " + guest.firstname
                  : " guest"}
              </label>
              <div>
                <p className="label-subtitle" style={{ marginBottom: 0 }}>
                  Cliquez sur le + pour ajouter un document à cet invité
                </p>
                <p className="label-subtitle">
                  Ces documents seront mis à disposition dans l'espace de votre
                  invité ainsi que dans les pièces jointes des emails.
                </p>
              </div>
            </div>
            <div className={"metric-upload-file"} id="_space">
              <div className="div-space">
                <div className={"metric-upload-file-title "}>
                  ESPACE DISPONIBLE
                </div>
                <div className={"metric-upload-file-label"}>
                  {totalSize}/{spaceAvailable}MO
                </div>
              </div>
            </div>
            <div className="div-button-add-file">
              <button
                className="pink white-text button-add-file"
                onClick={() => setOpenAddFileInputs(!openAddFileInputs)}
              >
                <FontAwesomeIcon icon={faPlus} color="white" size="xs" />
              </button>
            </div>
          </div>
        </Modal.Body>
        {openAddFileInputs ? (
          <Modal.Footer bsPrefix="footer-modal-file">
            <div style={{ margin: 20 }}>
              <div>
                <form
                  onSubmit={handleAddingFile}
                  className="div-modal-add-file row"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginLeft: 20,
                    }}
                  >
                    <div
                      className="input-group"
                      style={{ marginTop: sizeReached && 20 }}
                    >
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input upload"
                          id="inputGroupFile01"
                          aria-describedby="inputGroupFileAddon01"
                          onChange={checkFile}
                          accept=".pdf,.jpg,.png"
                          required
                          ref={fileRef}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="inputGroupFile01"
                        >
                          {isSelected
                            ? fileObject.file.name
                            : "Choisir un fichier"}
                        </label>
                      </div>
                    </div>
                    {sizeReached && (
                      <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <span style={{ color: "#d81b60", fontWeight: "bold" }}>
                          L'espace disponible a été atteint
                        </span>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      marginLeft: 20,
                    }}
                  >
                    <MDBInput
                      className="input-add-modal-file"
                      type="text"
                      value={fileName}
                      name="fileName"
                      label={"Nom du document*"}
                      labelClass="labelInputFileUpload"
                      onChange={(e) => setFileName(e.target.value)}
                      required
                    />
                  </div>
                  <div
                    style={{
                      marginLeft: 20,
                      marginTop: 2,
                    }}
                  >
                    <FormControl
                      variant="standard"
                      required
                      sx={{ m: 1, minWidth: 120 }}
                      className="formSelectUpload"
                    >
                      <InputLabel
                        id="demo-simple-select-required-label"
                        className="labelFileUpload"
                      >
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={fileType}
                        label="Type *"
                        required
                        onChange={(e) => setFileType(e.target.value)}
                        className="selectFileUpload"
                      >
                        <MenuItem value="">
                          <em>Type</em>
                        </MenuItem>
                        <MenuItem value={"billet"}>Billet</MenuItem>
                        <MenuItem value={"info"}>Information</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      marginTop: 2,
                      marginLeft: 20,
                    }}
                  >
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                      className="formSelectUpload"
                    >
                      <InputLabel
                        id="demo-simple-select-standard-label"
                        className="labelFileUpload"
                      >
                        Associé à
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={guestAssociated}
                        label="Associé à"
                        onChange={(e) => setGuestAssociated(e.target.value)}
                        className="selectFileUpload"
                      >
                        <MenuItem value="">
                          <em>Associé à</em>
                        </MenuItem>
                        {followers
                          ? followers.map((follower) => {
                              return (
                                <MenuItem value={follower.id}>
                                  {follower.lastname + " " + follower.firstname}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="centerBtn">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {load && <SmallLoader />}
                      <Button
                        type="submit"
                        size="sm"
                        className="btn btnAddFile pink"
                        variant=""
                        /*bsPrefix="delete-modal-btn"*/ disabled={disabled}
                      >
                        Valider
                      </Button>
                    </div>
                  </div>
                  <div className="centerBtn">
                    <Button
                      onClick={() => setOpenAddFileInputs(false)}
                      size="sm"
                      className="btn btnAbortAddFile grey"
                      variant=""
                    >
                      Annuler
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Footer>
        ) : null}
        <Modal.Footer bsPrefix="footer-modal-file">
          <div className={"table-container"}>
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  <th scope="col" className="nameColumn">
                    Nom du document
                  </th>
                  <th scope="col">Type</th>
                  <th scope="col">Nom du fichier</th>
                  <th scope="col">Poids (en mo)</th>
                  <th scope="col">Associé à</th>
                  <th scope="col">Téléchargement</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              {isLoading ? (
                <div className="loaderUploadFile">
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <MDBSpinner big red />
                  </div>
                </div>
              ) : (
                documents.map((doc, index) => {
                  const docRelated = doc.related;
                  const getType = {
                    info: "Information",
                    billet: "Billet",
                  };
                  const isDownloading =
                    doc.id === documentInDownload.id
                      ? documentInDownload.isDownloading
                      : null;
                  const downloadTime = JSON.parse(doc.download_time);
                  return (
                    <tbody>
                      <>
                        <td>{doc.name}</td>
                        <td>{getType[doc.type]}</td>
                        <td style={{ display: "flex", flexDirection: "row" }}>
                          <FontAwesomeIcon
                            icon={faDownload}
                            size="1x"
                            style={{ cursor: "pointer" }}
                            onClick={() => download(doc, index)}
                          />
                          &nbsp; &nbsp;
                          {isDownloading && (
                            <>
                              <SmallLoader /> &nbsp; &nbsp;
                            </>
                          )}
                          {doc.file_name}
                        </td>
                        <td>{Number(doc.size)}mo</td>
                        <td>
                          {docRelated &&
                            docRelated.lastname + " " + docRelated.firstname}
                        </td>
                        <td>
                          {Array.isArray(downloadTime) && downloadTime
                            ? downloadTime.length
                            : 0}
                        </td>
                        <td>
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            size="1x"
                            style={{ cursor: "pointer" }}
                            onClick={() => openToDelete(doc)}
                          />
                        </td>
                      </>
                    </tbody>
                  );
                })
              )}
            </table>
          </div>
          <div className="div-btn-footer">
            <Button variant="dark" onClick={setModal}>
              Fermer
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
