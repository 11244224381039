import React from "react";
import { languagesList } from "../../../common/Translations";

class WorkSpaceForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {};
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <form onSubmit={this.handleSubmit} className="form">
            <h4>Informations générales</h4>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Nom du client</label>
                <input
                  name="name"
                  value={this.state.name}
                  className="form-control"
                  type="text"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Adresse</label>
              <input
                name="address"
                onChange={this.handleChange}
                value={this.state.address}
                type="text"
                className="form-control"
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label>Code postal</label>
                <input
                  name="zipCode"
                  onChange={this.handleChange}
                  value={this.state.zipCode}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-8">
                <label>Ville</label>
                <input
                  name="city"
                  onChange={this.handleChange}
                  value={this.state.city}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label>Nom du contact</label>
                <input
                  name="lastname"
                  onChange={this.handleChange}
                  value={this.state.lastname}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-8">
                <label>Prénom du contact</label>
                <input
                  name="firstname"
                  onChange={this.handleChange}
                  value={this.state.firstname}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Téléphone</label>
                <input
                  name="phone"
                  onChange={this.handleChange}
                  value={this.state.phone}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-6">
                <label>Email</label>
                <input
                  name="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Nom de domaine</label>
                <input
                  name="url"
                  onChange={this.handleChange}
                  value={this.state.url}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label>Langue de l'interface</label>
                <select class="form-control form-control-lg">
                  {
                    languagesList.map((language) => {
                      return <option>{language.code}</option>
                    })
                  }
                </select>
              </div>
            </div>
            <h4>Formules</h4>
            <div class="form-check">
              <input
                name="ecommerce"
                onChange={this.handleChange}
                value={this.state.ecommerce}
                class="form-check-input"
                type="checkbox"
              />
              <label class="form-check-label">Option E-commerce</label>
            </div>
            <div class="form-check">
              <input
                name="multilingual"
                onChange={this.handleChange}
                value={this.state.multilingual}
                class="form-check-input"
                type="checkbox"
              />
              <label class="form-check-label">Option Multilingue</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name="checkin"
                onChange={this.handleChange}
                value={this.state.checkin}
              />
              <label class="form-check-label">Option Emargement</label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name="placement"
                onChange={this.handleChange}
                value={this.state.placement}
              />
              <label class="form-check-label">Option placement</label>
            </div>
            <br />
            <div class="form-row">
              <div class="form-group col-md-4">
                <label>Licence du </label>
                <input class="form-control" placeholder="10/10/2018" />
              </div>
              <div class="form-group col-md-4">
                <label>au </label>
                <input class="form-control" placeholder="10/12/2018" />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-8">
                <label>Limite d'événements crées : </label>
                <input
                  name="limit"
                  onChange={this.handleChange}
                  value={this.state.limit}
                  class="form-control"
                  placeholder="1"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-8">
                <label>Limite d'invitations envoyées : </label>
                <input
                  name="invitationLimit"
                  onChange={this.handleChange}
                  value={this.state.invitationLimit}
                  class="form-control"
                  placeholder="1"
                />
              </div>
            </div>

            <button
              type="submit"
              className="btn pink darken-1 float-right white-text"
            >
              Enregistrer
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default WorkSpaceForm;
