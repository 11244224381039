import React from "react";
import { Container, Draggable } from "react-smooth-dnd";
import { applyDrag } from "./utils";
import ComponentEditor from "./ComponentEditor";

import "./FormEditor";

class Gabarit1 extends React.Component {
  constructor(props) {
    super(props);
    this.handleDrop = this.handleDrop.bind(this);
    this.state = { ...this.props };
  }
  handleDrop(type, e) {
    switch (type) {
      case 'main':
        this.setState(
          { itemsMain: applyDrag(this.state.itemsMain, e) },
          () => this.props.onValidate({ itemsMain: this.state.itemsMain })
        );
        break;
      case 'side':
        this.setState(
          { itemsSide: applyDrag(this.state.itemsSide, e) },
          () => this.props.onValidate({ itemsSide: this.state.itemsSide })
        );
        break;
      default:
        this.setState(
          { itemsMain: applyDrag(this.state.itemsMain, e) },
          () => this.props.onValidate({ itemsMain: this.state.itemsMain })
        );
        break;
    }

  }
  render() {
    return (
      <div className="main-block">
        <div className="col-md-12 zone components-list">
          <Container
            dragHandleSelector=".column-drag-handle"
            groupName="1"
            getChildPayload={i => this.state.itemsMain[i]}
            onDrop={(e) => this.handleDrop("main", e)}
          >
            {this.state.itemsMain.map((p, key) => {
              return (
                <Draggable key={key}>
                  <div className="draggable-item">
                    <span
                      className="column-drag-handle"
                      style={{ float: "left", padding: "0 10px" }}
                    >
                      &#x2630;
                    </span>
                    <ComponentEditor
                      composantsList={this.props.composantsList}
                      component={p}
                      onValidate={this.props.onValidate}
                      itemsMain={this.props.itemsMain}
                    />
                  </div>
                </Draggable>
              );
            })}
          </Container>
        </div>
        <div className="col-md-8 offset-md-2 zone main-block components-list">
          <Container
            dragHandleSelector=".draggable-item"
            groupName="1"
            getChildPayload={i => this.state.itemsSide[i]}
            onDrop={(e) => this.handleDrop("side", e)}
          >
            {this.state.itemsSide.map((p, key) => {
              return (
                <Draggable key={key}>
                  <div className="draggable-item">
                    <span
                      className="column-drag-handle"
                      style={{ float: "left", padding: "0 10px" }}
                    >
                      &#x2630;
                    </span>
                    <ComponentEditor
                      component={p}
                      onValidate={this.props.onValidate}
                      itemsSide={this.state.itemsSide}
                    />
                  </div>
                </Draggable>
              );
            })}
          </Container>
        </div>
      </div>
    );
  }
}

export default Gabarit1;
