import React from "react";
import { Link } from "react-router-dom";
import ApiService from "../../../services/ApiService";
import Loader from "../../../common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrashAlt,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { MDBInput } from "mdbreact";
import { toast } from "react-toastify";
import Pagination from "../../../common/Pagination";
class ContactsTagsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      tagsFiltered: [],
      contacts: [],
      isLoading: true,
      nameFilter: "",
      usingFilter: "",
      totalPages: 0,
      currentPage: 0,
      numberPerPage: 15,
    };
    this.filter = this.filter.bind(this);
    this.filterTags = this.filterTags.bind(this);
    this.checkTagUsing = this.checkTagUsing.bind(this);
    this.displayIconTagList = this.displayIconTagList.bind(this);
    this.noUSingTag = this.noUSingTag.bind(this);
    this.changePage = this.changePage.bind(this);
    this.sortByOrderAlphatic = this.sortByOrderAlphatic.bind(this);
  }

  async loadData() {
    const self = this;
    this.setState({ isLoading: true });
    ApiService.request({}, "tags", "get").then(function (data) {
      const tags = data;
      console.log(tags, "tags");
      self.setState({ tagsFiltered: self.sortByOrderAlphatic(data), tags });
    });
    await ApiService.request({}, "contacts/list/true", "get").then(function (
      contacts
    ) {
      self.setState({ contacts: contacts });
    });

    self.setState({
      isLoading: false,
      totalPages: Math.ceil(this.state.tags.length / self.state.numberPerPage),
    });
  }

  componentDidMount() {
    this.loadData();
  }
  sortByOrderAlphatic(array) {
    return array.sort(function (a, b) {
      var tagA = a.tag_name.toUpperCase(); // Ignorer les majuscules et minuscules
      var tagB = b.tag_name.toUpperCase(); // Ignorer les majuscules et minuscules
      if (tagA < tagB) {
        return -1; // a doit être avant b dans l'ordre alphabétique
      }
      if (tagA > tagB) {
        return 1; // a doit être après b dans l'ordre alphabétique
      }
      // Les noms sont égaux
      return 0;
    });
  }
  async deleteTag(id) {
    const self = this;
    if (this.checkTagUsing(id, this.state.contacts).length !== 0) {
      toast.error(
        "le tag selectionné est utilisé vous ne pouvez pas le supprimer",
        {
          autoClose: 5000,
        }
      );
    } else {
      if (window.confirm("Etes vous sûr de valider cette action ?")) {
        await ApiService.request({}, "tags/" + id, "delete").then(function () {
          self.loadData();
        });
      }
    }
  }
  // check if un tag exist avant de le supprimer
  checkTagUsing(id, contact) {
    let UsingTag = [];
    for (let index = 0; index < contact.length; index++) {
      if (contact[index].tags.length !== 0) {
        for (let i = 0; i < contact[index].tags.length; i++) {
          UsingTag.push(contact[index].tags[i]);
        }
      }
    }
    // save unique tag in array of tag
    UsingTag = [
      ...new Map(UsingTag.map((item) => [item["id"], item])).values(),
    ];
    // get the tag to delete
    let existingTag = UsingTag.filter((tag) => tag.id === id);
    if (!id) {
      return (UsingTag = [
        ...new Map(UsingTag.map((item) => [item["id"], item])).values(),
      ]);
    }
    return existingTag;
  }
  // displays the icon according to the tag that is used or not
  displayIconTagList(tagId) {
    const contacts = this.checkTagUsing(null, this.state.contacts);

    return contacts.some((tag) => {
      return tag.id === tagId ? true : false;
    });
  }
  // get all no using tag
  noUSingTag(usingTag, allTag) {
    let result = allTag.filter(function (array_el) {
      return (
        usingTag.filter(function (anotherOne_el) {
          return anotherOne_el.id === array_el.id;
        }).length === 0
      );
    });
    return result;
  }
  filter() {
    const { tags, numberPerPage } = this.state;
    let tagsFiltered = tags;

    if (this.state.nameFilter.length > 0) {
      tagsFiltered = tagsFiltered.filter((tag) => {
        return tag.tag_name
          .toLowerCase()
          .includes(this.state.nameFilter.toLowerCase())
          ? tag
          : null;
      });
    }

    if (this.state.usingFilter.length > 0) {
      const tagsValue = tags;
      console.log(
        this.noUSingTag(
          this.checkTagUsing(null, this.state.contacts),
          tagsValue
        )
      );
      switch (this.state.usingFilter) {
        case "non":
          tagsFiltered = this.noUSingTag(
            this.checkTagUsing(null, this.state.contacts),
            tags
          );
          break;
        case "oui":
          tagsFiltered = this.checkTagUsing(null, this.state.contacts);
          break;
        default:
          break;
      }
    }
    this.setState({
      tagsFiltered,
      totalPages: Math.ceil(tagsFiltered.length / numberPerPage),
      currentPage: 0,
    });
  }
  filterTags(event) {
    const self = this;
    const nameFilter = [event.target.name][0];
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        const nameFilterlength = self.state.nameFilter.length;

        const dontFilter =
          nameFilter === "nameFilter" &&
          nameFilterlength < 3 &&
          nameFilterlength > 0;

        if (!dontFilter) {
          self.filter();
        }
      }
    );
  }
  changePage(page) {
    this.setState({ currentPage: page });
  }
  render() {
    const {
      tagsFiltered,
      isLoading,
      nameFilter,
      usingFilter,
      totalPages,
      currentPage,
      numberPerPage,
    } = this.state;
    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <div className="row">
            <div className="col-md-10">
              <h2>Liste des tags</h2>
            </div>

            <div className="col-md-2">
              <Link
                className="btn pink darken-1 float-right white-text"
                to="/admin/tags_contacts/edit"
              >
                Nouveau tag
              </Link>
            </div>
          </div>
          <br />
          <div className={"white filters"}>
            <div className="row">
              <div className="col-md-3">
                <MDBInput
                  type="text"
                  label="Filtrer par nom"
                  value={nameFilter}
                  onChange={this.filterTags}
                  name="nameFilter"
                />
              </div>
              <div className="col-md-3">
                <div className="md-form">
                  <select
                    onChange={this.filterTags}
                    name="usingFilter"
                    className=" browser-default custom-select"
                    value={usingFilter}
                  >
                    <option value={""}>Filtrer par utilisation</option>
                    <option value={"oui"}>OUI</option>
                    <option value={"non"}>NON</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th scope="col">Nom</th>
                <th scope="col">Utilisé</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {tagsFiltered.map((tag, i) => {
                return i < numberPerPage * (currentPage + 1) &&
                  i >= numberPerPage * currentPage ? (
                  <tr key={tag.id}>
                    <td>{tag.tag_name}</td>
                    <td>
                      {this.displayIconTagList(tag.id) ? (
                        <FontAwesomeIcon icon={faCheck} />
                      ) : (
                        <FontAwesomeIcon icon={faTimes} />
                      )}
                    </td>

                    <td>
                      &nbsp;&nbsp;&nbsp;
                      <Link to={"/admin/contacts_tags/edit/" + tag.id}>
                        <FontAwesomeIcon icon={faEdit} />
                      </Link>
                      &nbsp;&nbsp;&nbsp;
                      <span
                        className="pointer"
                        onClick={() => this.deleteTag(tag.id)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </span>
                    </td>
                  </tr>
                ) : null;
              })}
            </tbody>
          </table>
          <Pagination
            changePage={this.changePage}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </div>
        {/* End of Table */}
      </div>
    );
  }
}

export default ContactsTagsList;
