import React from "react";
import { Button } from "mdbreact";
import ReactExport from "react-export-excel";
import { findLabelAndColor } from "../../../services/Utils";
import { webPageTranslations } from "../../../common/Translations";
import { formatDate } from "../../../services/Utils";

class ExportLine extends React.Component {
  render() {
    const { code } = this.props;
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const contactCode = code ? code : "FR";
    const columns = [
      "Nom",
      "Prénom",
      "Téléphone",
      "Adresse mail",
      "Manager",
      "Galaxie",
      "Invité par",
      "A répondu oui",
      "A répondu non",
      "N'a pas répondu",
    ];

    const data = [];
    const lines = this.props.line.filter((li) => li.active === true);
    const linesParticipate = lines.filter((li) => li.participate === true);
    const self = this;
    const sortByManager = lines.sort(function (a, b) {
      const managerA =
        self.props.contact.id === a.related_to
          ? self.props.contact
          : lines.find((manager) => manager.id === a.related_to);
      const managerB =
        self.props.contact.id === b.related_to
          ? self.props.contact
          : lines.find((manager) => manager.id === b.related_to);
      const stringA = managerA
        ? managerA.lastname + " " + managerA.firstname
        : "";
      const stringB = managerB
        ? managerB.lastname + " " + managerB.firstname
        : "";

      return stringA.localeCompare(stringB);
    });

    sortByManager.forEach((contact) => {
      const manager =
        this.props.contact.id === contact.related_to
          ? this.props.contact
          : lines.find((manager) => manager.id === contact.related_to);

      const currentGuest = this.props.guests
        ? this.props.guests.find((guest) => guest.contact_id === contact.id)
        : null;
      const findLabel = findLabelAndColor(
        contact,
        contactCode,
        this.props.eventResponses
      ).label;

      const followers = currentGuest
        ? this.props.guests.filter((guest) =>
            guest.invited_by ? guest.invited_by.id === currentGuest.id : false
          )
        : [];

      data.push([
        contact ? (contact.lastname ? contact.lastname : "") : "",
        contact ? (contact.firstname ? contact.firstname : "") : "",
        currentGuest
          ? currentGuest.phone
            ? currentGuest.phone
            : currentGuest.mobile
            ? currentGuest.mobile
            : ""
          : this.props.checkEventFromAdmin
          ? contact.phone
          : "",
        currentGuest
          ? currentGuest.email
          : this.props.checkEventFromAdmin
          ? contact.mail
          : "",
        manager ? manager.lastname + " " + manager.firstname : "",
        contact ? contact.more_details : "",
        "",
        findLabel
          ? findLabel === "Oui" || findLabel === "Yes"
            ? "1"
            : "0"
          : "",
        findLabel
          ? findLabel === "Non" || findLabel === "No"
            ? "1"
            : "0"
          : "",
        findLabel
          ? findLabel === "Non répondu" || findLabel === "Not answered"
            ? "1"
            : "0"
          : "",
      ]);
      followers.map((follower) => {
        const array = [
          follower.lastname,
          follower.firstname,
          "",
          "",
          "",
          "",
          follower.invited_by
            ? follower.invited_by.lastname + " " + follower.invited_by.firstname
            : "",
          "",
          "",
          "",
        ];
        data.push(array);
        return follower;
      });
    });
    const empty = ["", "", "", "", "", "", "", "", "", ""];
    const numbersParticipate = [
      "",
      "",
      "",
      "",
      "",
      "",
      "Inscrits",
      linesParticipate.length,
      "/",
      lines.length,
    ];
    data.push(empty, empty, empty, empty, empty, numbersParticipate);
    const multiDataSet = [
      {
        columns,
        data,
      },
    ];

    const today = new Date();
    const title = "Export_lignee_" + formatDate(today, false).replace("-", "");

    return (
      <ExcelFile
        filename={title}
        element={
          <Button className="btn pink darken-1 ">
            {webPageTranslations[contactCode].export}
          </Button>
        }
      >
        <ExcelSheet dataSet={multiDataSet} name="Export global" />
      </ExcelFile>
    );
  }
}

export default ExportLine;
