import React from "react";
import MessageIcon from "@mui/icons-material/Message";
import PeopleIcon from "@mui/icons-material/People";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

export const StepsList = ({ step, setStep }) => {
  const lineStyle = {
    position: "absolute",
    top: "45%",
    left: "0%",
    width: "100%",
    borderBottom: "2px solid #d81b60",
    zIndex: -1,
  };

  const handleStep = (val) => {
    if (step > val) setStep(val);
  };

  return (
    <div className="d-flex justify-content-around align-items-center position-relative pt-4 mb-5">
      <div
        className="d-flex flex-column align-items-center"
        style={{
          cursor: step >= 1 ? "pointer" : "",
        }}
        onClick={() => handleStep(1)}
      >
        <div className="rounded-circle p-3 step-circle step-list-icon-container">
          <MessageIcon
            style={{
              color: step >= 1 ? "#d81b60" : "#A2A2A2",
              width: "1.875rem",
              height: "1.875rem",
            }}
          />
        </div>
        <p
          className="font-weight-bold"
          style={{
            color: step >= 1 ? "#d81b60" : "#A2A2A2",
          }}
        >
          Message
        </p>
      </div>
      <div
        className="d-flex flex-column align-items-center"
        style={{
          cursor: step >= 2 ? "pointer" : "",
        }}
        onClick={() => handleStep(2)}
      >
        <div className="rounded-circle p-3 step-circle step-list-icon-container">
          <PeopleIcon
            style={{
              color: step >= 2 ? "#d81b60" : "#A2A2A2",
              width: "1.875rem",
              height: "1.875rem",
            }}
          />
        </div>
        <p
          className="font-weight-bold"
          style={{
            color: step >= 2 ? "#d81b60" : "#A2A2A2",
          }}
        >
          Destinataires
        </p>
      </div>
      <div
        className="d-flex flex-column align-items-center"
        style={{
          cursor: step >= 3 ? "pointer" : "",
        }}
        onClick={() => handleStep(3)}
      >
        <div className="rounded-circle p-3 step-circle step-list-icon-container">
          <AccessTimeIcon
            style={{
              color: step >= 3 ? "#d81b60" : "#A2A2A2",
              width: "1.875rem",
              height: "1.875rem",
            }}
          />
        </div>
        <p
          className="font-weight-bold"
          style={{
            color: step >= 3 ? "#d81b60" : "#A2A2A2",
          }}
        >
          Programmation
        </p>
      </div>
      <div style={lineStyle}></div>
    </div>
  );
};
