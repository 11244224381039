import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "mdbreact";
import { replaceData } from "../services/Utils";

export default function EmailPreviewModal({
  open,
  keyModal,
  setModal,
  templates,
  packages,
  user,
  event,
  guest,
  contact,
}) {
  const userPreview = guest ? guest : user ? user : "";
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [template, setTemplate] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {}, [selectedTemplate]);

  useEffect(() => {
    //show first template at loading of component
    const firstTemplate = templates ? templates[0] : "";
    setSelectedTemplate(String(firstTemplate ? firstTemplate.id : ""));
    setTemplate(firstTemplate);
    if (firstTemplate) {
      formatMessage(firstTemplate);
    }
  }, []);

  const changeTemplate = (value) => {
    const obj = value ? templates.find((t) => t.id === Number(value)) : "";
    setSelectedTemplate(value);
    setTemplate(obj);
    formatMessage(obj);
  };
  const formatMessage = (temp) => {
    if (temp) {
      const msg = replaceData(temp, event, packages, guest, contact, user);
      setMessage(msg);
    } else {
      setMessage("");
    }
  };

  return (
    <div className={"emailPreviewModal"}>
      <Modal size="lg" isOpen={open} toggle={() => setModal(keyModal)}>
        <ModalBody>
          <div className="row ml-0 mr-0 emailPreview">
            <div className="col-md-9" style={{ textAlign: "left" }}>
              <div style={{ marginTop: 10 }}>
                <select
                  onChange={(e) => changeTemplate(e.target.value)}
                  name="selectedTemplate"
                  className="browser-default custom-select"
                  value={selectedTemplate}
                  required
                >
                  <option value="">Sélectionner le template d'aperçu</option>
                  {templates
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((template, key) => (
                      <option value={template.id} key={key}>
                        {template.name}
                      </option>
                    ))}
                </select>
              </div>
              <br />
              <h2>APERÇU DE L'EMAIL </h2>
              {userPreview && event && (
                <>
                  <b>De : </b>
                  {userPreview ? userPreview.lastname : ""}{" "}
                  {"<" + (event ? event.mail_sender_address : "") + ">"}
                  <br />
                  <b>Répondre à : </b>
                  {userPreview ? userPreview.email : ""}
                </>
              )}
            </div>
            <div className="mb-5 col-md-3">
              <Button
                onClick={() => setModal(keyModal)}
                className="btn pink darken-1 "
              >
                FERMER
              </Button>
            </div>
            <div className="emailPreview-container">
              <div
                className="col-md-12"
                dangerouslySetInnerHTML={{
                  __html:
                    template && template.image_header
                      ? '<img src="' + template.image_header + '"/>'
                      : "",
                }}
              />

              <div
                className="preview-container"
                dangerouslySetInnerHTML={{
                  __html: message,
                }}
              />
              <div
                className="preview-container"
                dangerouslySetInnerHTML={{
                  __html: template && template.text_footer,
                }}
              />
              <div
                className="col-md-12"
                dangerouslySetInnerHTML={{
                  __html:
                    template && template.image_footer
                      ? '<img src="' + template.image_footer + '"/>'
                      : "",
                }}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
