import React from "react";
import TextField from "@mui/material/TextField";
import ApiService from "../../../../services/ApiService";
import { toast } from "react-toastify";
import LinearLoader from "../../components/LinearLoader";
import SegmentContainer from "./SegmentContainer";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { FormDialog } from "../../components/FormDialog";
import CloseIcon from "@mui/icons-material/Close";
import "./Segments.css";
import { CustomMuiButton } from "../../components/CustomMuiButton";

export function Segments({
  eventId,
  open,
  handleDialogClose,
  updateParentSegments,
  guests,
  segmentId,
  packageOptions,
  tagsOptions,
  countryOptions,
  componentsOptions,
  eventComponents,
}) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [segmentTitle, setSegmentTitle] = React.useState("");
  const [mainSegmentsList, setMainSegmentsList] = React.useState([]);
  const [secondSegmentsList, setSecondSegmentsList] = React.useState([]);
  const [disableSaveButton, setDisableSaveButton] = React.useState(false);

  const loadData = async () => {
    try {
      setIsLoading(true);
      if (segmentId) {
        // TODO: get segment from backend
        const res = await ApiService.request(
          {},
          "segments/" + segmentId,
          "get"
        );
        if (res) {
          setSegmentTitle(res.title);
          setMainSegmentsList(res.main_segment_list);
          setSecondSegmentsList(res.second_segment_list);
        }
      }
    } catch (error) {
      console.log(error);
      handleDialogClose();
      toast.error("Une erreur s'est produite.");
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    loadData();
  }, [segmentId]);

  const checkSegmentsData = (segmentsList, action, type) => {
    if (!segmentsList || !segmentsList.length) {
      if (action === "submit-form") return "empty";
    }

    console.log();
    for (const seg of segmentsList) {
      switch (seg.type) {
        case "name":
        case "society":
          switch (seg.condition) {
            case "is_empty":
            case "is_not_empty":
              break;
            default:
              if (!(seg.condition && seg.value)) {
                return "incomplet";
              }
              break;
          }
          break;
        case "component_response":
          if (!(seg.condition && seg.value)) {
            return "incomplet";
          }
          break;

        case "language":
        case "country":
        case "package":
        case "can_have_follower":
        case "tags":
        case "invited_by":
          if (!seg.value) {
            return "incomplet";
          }
          break;
        default:
          return "incomplet";
      }
    }
    return "valid";
  };

  const saveSegment = async (event) => {
    event.preventDefault();

    try {
      setDisableSaveButton(true);
      const mainSegmentStatus = checkSegmentsData(
        mainSegmentsList,
        "submit-form",
        "main"
      );
      const secondSegmentStatus = checkSegmentsData(
        secondSegmentsList,
        "submit-form",
        "second"
      );

      if (
        !(
          (mainSegmentStatus === "empty" && secondSegmentStatus === "empty") ||
          mainSegmentStatus === "incomplet" ||
          secondSegmentStatus === "incomplet"
        )
      ) {
        if (segmentId) {
          //! Setup backend function to update
          const res = await ApiService.request(
            {
              evenement_id: eventId,
              title: segmentTitle,
              main_segment_list: mainSegmentsList,
              second_segment_list: secondSegmentsList,
            },
            "segments/" + segmentId,
            "put"
          );
          updateParentSegments(res, "segment");
          toast.success("Segment sauvegardé");
        } else {
          const res = await ApiService.request(
            {
              evenement_id: eventId,
              title: segmentTitle,
              main_segment_list: mainSegmentsList,
              second_segment_list: secondSegmentsList,
            },
            "segments/",
            "post"
          );
          updateParentSegments(res, "segment");
          toast.success("Segment crée");
        }
        handleDialogClose();
      } else {
        toast.warning("Remplissez les champs necéssaires");
      }
    } catch (error) {
      console.log(error);
      toast.error("Une erreur s'est produite");
      handleDialogClose();
    } finally {
      setDisableSaveButton(false);
    }
  };

  return (
    <div className="segment_wrapper">
      <FormDialog
        open={open}
        handleClose={handleDialogClose}
        onSubmit={saveSegment}
        className="segment_form"
      >
        <DialogContent
          sx={{
            minWidth: "870px",
            minHeight: "400px",
          }}
        >
          {isLoading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "inherit",
              }}
            >
              <LinearLoader width={"35%"} />
            </div>
          ) : (
            <>
              <div className="segment_header">
                <div className="segment_name">
                  <h4>Nom du Segment</h4>
                  <div>
                    <TextField
                      id="outlined-basic-segment-title"
                      // label="Nom"
                      variant="outlined"
                      required
                      size="small"
                      value={segmentTitle}
                      onChange={({ target: { value } }) =>
                        setSegmentTitle(value)
                      }
                      sx={{
                        maxWidth: "450px",
                        minWidth: "300px",
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          color: "#000000",
                          borderRadius: "0.7rem",
                          "& input:focus": {
                            borderBottom: 0,
                            boxShadow: "none",
                          },
                        },
                        "& .MuiOutlinedInput-root.Mui-disabled": {
                          ":hover": {
                            boxShadow: "none",
                          },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #000000 !important",
                        },
                        "& .MuiFormLabel-root": {
                          color: "#000000 !important",
                          fontSize: "0.9em",
                        },
                      }}
                    />
                    <p className="helperText">
                      Choisissez un nom explicatif et différencié.
                    </p>
                  </div>
                </div>
                <IconButton onClick={handleDialogClose}>
                  <CloseIcon className="segment-dialog-close-btn" />
                </IconButton>
              </div>

              <SegmentContainer
                segmentsList={mainSegmentsList}
                setSegmentsList={setMainSegmentsList}
                packageOptions={packageOptions}
                tagsOptions={tagsOptions}
                countryOptions={countryOptions}
                componentsOptions={componentsOptions}
                eventComponents={eventComponents}
                guests={guests}
                title={
                  "Segmenter les contacts vérifiant TOUTES ces conditions :"
                }
                checkSegmentsData={checkSegmentsData}
                segmentPrefix="ET"
                activateDisabledOptions={true}
              />

              <SegmentContainer
                segmentsList={secondSegmentsList}
                setSegmentsList={setSecondSegmentsList}
                packageOptions={packageOptions}
                tagsOptions={tagsOptions}
                countryOptions={countryOptions}
                componentsOptions={componentsOptions}
                eventComponents={eventComponents}
                guests={guests}
                title={"ET Vérifient AU MOINS UNE de ces conditions"}
                checkSegmentsData={checkSegmentsData}
                segmentPrefix="OU"
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <CustomMuiButton
            type="submit"
            text="Enregistrer"
            minHeight={undefined}
            textColor="#ffffff"
            buttonBgColor="#E01755"
            buttonHoverBgColor="#AF1242"
            buttonHoverBorderColor="#0062cc"
            buttonActiveBgColor="#E01755"
            disable={disableSaveButton}
          />
        </DialogActions>
      </FormDialog>
    </div>
  );
}
