import React from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

export function AddConditionButton({
  type = "button",
  onClick,
  text,
  ...props
}) {
  return (
    <Button
      variant="contained"
      type={type}
      onClick={onClick}
      startIcon={<AddIcon />}
      sx={{
        m: 1,
        padding: "0.5rem 2.5rem 0.5rem 1rem",
        width: "fit-content",
        color: "#000000",
        backgroundColor: "#ffffff",
        border: "1px solid #000000",
        borderRadius: "0.7rem",
        "&:hover": {
          backgroundColor: "#ffffff",
          borderColor: "#000000",
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: "none",
          backgroundColor: "#ffffff",
        },
        "&:focus": {
          boxShadow: "none",
        },
      }}
      {...props}
    >
      {text}
    </Button>
  );
}
