import React from "react";
import { Switch, Route } from "react-router-dom";
import UsersList from "./UsersList";
import UserForm from "./UserForm";

class Users extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/admin/users/edit/:id?" component={UserForm} />
        <Route path="/admin/users" component={UsersList} />
      </Switch>
    );
  }
}

export default Users;
