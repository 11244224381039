import React from "react";
import { Button, Modal, ModalBody, ModalFooter, MDBInput } from "mdbreact";
import ApiService from "../../services/ApiService";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";
import Pagination from "../../common/Pagination";
import "../DelegataireMain.css";
import { CustomMuiButton } from "../../admin/_modules/components/CustomMuiButton";

class InviteDelegataireContacts extends React.Component {
  constructor(props) {
    super(props);

    this.filterByText = this.filterByText.bind(this);
    this.filterByTag = this.filterByTag.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.checkOne = this.checkOne.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.filter = this.filter.bind(this);
    this.sendInvitations = this.sendInvitations.bind(this);
    this.changePage = this.changePage.bind(this);

    this.state = {
      numberPerPage: 20,
      currentPage: 0,
      totalPages: Math.ceil(this.props.contacts.length / 20),
      contactsChecked: [],
      modal: false,
      nameFilter: "",
      companyFilter: "",
      emailFilter: "",
      tagsFilter: "",
      contacts: this.props.contacts,
      contactsFiltered: this.props.contacts,
      companiesList: [],
      packageId:
        this.props.packagesOptions.length === 1
          ? this.props.packagesOptions[0].value
          : "",
      invitation_package_id: "",
      max_invitation: 0,
      is_followers: false,
      isLoading: this.props.contacts.length > 0 ? false : true,
    };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.contacts !== prevProps.contacts &&
      this.props.contacts.length !== 0
    ) {
      this.setState({
        isloading: false,
        totalPages: Math.ceil(this.props.contacts.length / 20),
        contacts: this.props.contacts,
        contactsFiltered: this.props.contacts,
      });
    }
  }

  changePage(page) {
    this.setState({ currentPage: page });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  filterByText(event) {
    const self = this;
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        self.filter();
      }
    );
  }

  filterByTag(event) {
    const self = this;

    this.setState(
      {
        tagsFilter: event ? event : "",
      },
      () => {
        self.filter();
      }
    );
  }

  filter() {
    let contactsFiltered = this.props.contacts;

    if (this.state.nameFilter.length > 0) {
      contactsFiltered = contactsFiltered.filter((contact) => {
        return contact.firstname
          .toLowerCase()
          .includes(this.state.nameFilter.toLowerCase()) ||
          contact.lastname
            .toLowerCase()
            .includes(this.state.nameFilter.toLowerCase())
          ? contact
          : null;
      });
    }

    if (this.state.companyFilter.length > 0) {
      contactsFiltered = contactsFiltered.filter((contact) => {
        return contact.company
          ? contact.company.name
              .toLowerCase()
              .includes(this.state.companyFilter.toLowerCase())
          : null;
      });
    }
    if (this.state.emailFilter.length > 0) {
      contactsFiltered = contactsFiltered.filter((contact) => {
        if (contact.mail) {
          let res = contact.mail
            .toLowerCase()
            .includes(this.state.emailFilter.toLowerCase());
          if (!res && contact.mail_second)
            res = contact.mail_second
              .toLowerCase()
              .includes(this.state.emailFilter.toLowerCase());
          return res;
        }
        return null;
      });
    }
    this.setState({
      contactsFiltered,
      totalPages: Math.ceil(contactsFiltered.length / 20),
      currentPage: 0,
    });
  }

  async sendInvitations() {
    const {
      contactsChecked,
      packageId,
      max_invitation,
      invitation_package_id,
    } = this.state;
    const { userId } = this.props;
    const self = this;

    this.setState({ isloading: true });

    const result = await ApiService.awaitRequest(
      {
        evenement_id: this.props.eventId,
        package_id: packageId,
        checked_in: false,
        max_invitation: max_invitation,
        contact_ids: contactsChecked.map((contact) => contact.id),
        invitation_package_id: max_invitation > 0 ? invitation_package_id : "",
        is_validated: true,
        created_on_checkin: false,
        created_by_delegataire: true,
        user_id: userId,
        maxGuestsBeforeToUseJobs: 2300,
      },
      "guests/create/bygroup",
      "post"
    );

    this.setState({ isloading: false }, () => {
      if (result) {
        toast.success("Ajout des contacts en cours...");
        self.props.loadData();
      } else {
        toast.error("Erreur lors de l'invitation des contacts");
      }
    });
  }

  checkOne(event, newContact) {
    if (event.target.checked) {
      const contactsChecked = this.state.contactsChecked;
      contactsChecked.push(newContact);
      this.setState({
        contactsChecked,
      });
    } else {
      this.setState({
        contactsChecked: this.state.contactsChecked.filter(
          (contact) => contact.id !== newContact.id
        ),
      });
    }
  }

  checkAll(event) {
    if (event.target.checked) {
      this.setState({
        contactsChecked: this.state.contactsFiltered,
      });
    } else {
      this.setState({
        contactsChecked: [],
      });
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const {
      contactsFiltered,
      companyFilter,
      nameFilter,
      emailFilter,
      contactsChecked,
      packageId,
      max_invitation,
      invitation_package_id,
      currentPage,
      isloading,
    } = this.state;

    const { packagesOptions } = this.props;

    return (
      <div className="button-container modal-delegataire">
        <CustomMuiButton
          type="button"
          text="INVITER DES CONTACTS"
          onClick={this.toggle}
          minHeight={50}
          textColor="#ffffff"
          buttonBgColor="#d81b60"
          buttonHoverBgColor="#AF1242"
          buttonHoverBorderColor="#0062cc"
          buttonActiveBgColor="#d81b60"
          sx={{ marginInline: "1rem" }}
        />
        <Modal
          size="lg"
          isOpen={this.state.modal}
          toggle={this.toggle}
          style={{ overflowY: "auto" }}
        >
          <ModalBody>
            {!isloading ? (
              <>
                <div className="col-md-12 font-weight-bold">
                  1 - Sélectionnez les contacts à inviter
                </div>

                <div className="col-md-12">
                  <div className="row" style={{ backgroundColor: "#EEEEEE" }}>
                    <div className="col-md-4">
                      <MDBInput
                        type="text"
                        value={nameFilter}
                        name="nameFilter"
                        label={"Rechercher par nom"}
                        onChange={this.filterByText}
                      />
                    </div>
                    <div className="col-md-4">
                      <MDBInput
                        type="text"
                        value={companyFilter}
                        name="companyFilter"
                        label={"Rechercher par société"}
                        onChange={this.filterByText}
                      />
                    </div>
                    <div className="col-md-4">
                      <MDBInput
                        type="text"
                        value={emailFilter}
                        name="emailFilter"
                        label={"Rechercher par email"}
                        onChange={this.filterByText}
                      />
                    </div>
                  </div>
                  <table className="table table-sm table-striped">
                    <thead>
                      <tr>
                        <th scope="col">
                          <input
                            type="checkbox"
                            style={{ opacity: 1, pointerEvents: "auto" }}
                            onChange={this.checkAll}
                          />
                        </th>
                        <th scope="col">Nom</th>
                        <th scope="col">Langue</th>
                        <th scope="col">Société</th>
                        <th scope="col">Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contactsFiltered.map((contact, i) =>
                        i < 20 * (currentPage + 1) && i >= 20 * currentPage ? (
                          <tr key={i}>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  this.state.contactsChecked.filter(
                                    (contactChecked) =>
                                      contactChecked.id === contact.id
                                  ).length > 0
                                }
                                onChange={(e) => this.checkOne(e, contact)}
                                style={{ opacity: 1, pointerEvents: "auto" }}
                              />
                            </td>
                            <td>
                              {contact.firstname + " " + contact.lastname}
                            </td>
                            <td>{contact.languages}</td>
                            <td>
                              {contact.company ? contact.company.name : null}
                            </td>
                            <td>{contact.mail}</td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  </table>
                  <div className="row" style={{ listStyleType: "none" }}>
                    <div className="row  pl-4 pr-4  pagination">
                      <Pagination
                        changePage={this.changePage}
                        currentPage={currentPage}
                        totalPages={this.state.totalPages}
                      />
                    </div>
                  </div>
                  {contactsChecked.length > 0 ? (
                    <div className="row mr-0 ml-0 actions-container">
                      <div className="col-md-4">
                        <div className="form-group">
                          <div className="font-weight-bold">
                            2- Paramétrage de l'invitation
                          </div>

                          <label>
                            {contactsChecked.length} contact
                            {contactsChecked.length > 1 ? "s" : null}{" "}
                            sélectionné
                            {contactsChecked.length > 1 ? "s" : null}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <select
                            onChange={this.handleChange}
                            name="packageId"
                            className="col-sm-12 browser-default custom-select"
                            value={packageId}
                            required
                          >
                            <option value={null}>Package invité</option>
                            {packagesOptions.map((option) => (
                              <option value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <MDBInput
                            label={"Nombre d'invitations"}
                            name="max_invitation"
                            value={max_invitation}
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        {max_invitation > 0 && (
                          <div className="form-group">
                            <select
                              onChange={this.handleChange}
                              name="invitation_package_id"
                              className="col-sm-12 browser-default custom-select"
                              value={invitation_package_id}
                              required
                            >
                              <option value="">Package accompagnant</option>
                              {packagesOptions.map((option) => (
                                <option value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}

                        <div className="form-group">
                          <Button
                            className="btn btn-block  pink darken-1"
                            disabled={
                              contactsChecked.length === 0 ||
                              packageId.length === 0 ||
                              max_invitation.length === 0
                            }
                            onClick={this.sendInvitations}
                          >
                            Valider
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </>
            ) : (
              <Loader />
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn btn-block  pink darken-1"
              onClick={this.toggle}
            >
              Fermer
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default InviteDelegataireContacts;
