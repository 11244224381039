export const errorWorkspaceLabelDbArray = [
  "contactExpire",
  "contactInactive",
  "contactNotInvited",
  "eventArchived"
];

export const selectForImportContact = [
  {
    name: "Gender",
    value: "gender",
    disabled: false
  },
  {
    name: "Firstname",
    value: "firstname",
    disabled: false
  },
  {
    name: "Lastname",
    value: "lastname",
    disabled: false
  },
  {
    name: "Address",
    value: "address",
    disabled: false
  },
  {
    name: "Address_more",
    value: "address_more",
    disabled: false
  },
  {
    name: "Zip_code",
    value: "zip_code",
    disabled: false
  },
  {
    name: "City",
    value: "city",
    disabled: false
  },
  {
    name: "Country",
    value: "country",
    disabled: false
  },
  {
    name: "Mail",
    value: "mail",
    disabled: false
  },
  {
    name: "Mail_second",
    value: "mail_second",
    disabled: false
  },
  {
    name: "Phone",
    value: "phone",
    disabled: false
  },
  {
    name: "Phone_second",
    value: "phone_second",
    disabled: false
  },
  {
    name: "Secretary_name",
    value: "secretary_name",
    disabled: false
  },
  {
    name: "Languages",
    value: "languages",
    disabled: false
  },
  {
    name: "Company_name",
    value: "company_name",
    disabled: false
  },
  {
    name: "Company_adress",
    value: "company_adress",
    disabled: false
  },
  {
    name: "Company_zip_code",
    value: "company_zip_code",
    disabled: false
  },
  {
    name: "Company_city",
    value: "company_city",
    disabled: false
  },
  {
    name: "Company_country",
    value: "company_country",
    disabled: false
  },
  {
    name: "Company_function",
    value: "company_function",
    disabled: false
  },
  {
    name: "Company_service",
    value: "company_service",
    disabled: false
  },
  {
    name: "Active",
    value: "active",
    disabled: false
  },
  {
    name: "Expiration_date",
    value: "expiration_date",
    disabled: false
  },
  {
    name: "More_details",
    value: "more_details",
    disabled: false
  },
  {
    name: "Category_name",
    value: "category_name",
    disabled: false
  },
  {
    name: "Affected_to",
    value: "affected_to",
    disabled: false
  },
  {
    name: "Tags",
    value: "tags",
    disabled: false
  },
  {
    name: "External_id",
    value: "external_id",
    disabled: false
  },
];

const numbers = () => {
  const array = [];
  for (let index = 0; index <= 20; index++) {
    array.push(index)
  }
  return array;
}

export const numbersToImport = numbers();