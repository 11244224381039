import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { /*faTimes,*/ faCog /*, faBars*/ } from "@fortawesome/free-solid-svg-icons";
import { MDBBtn /*, Modal, ModalBody*/ } from "mdbreact";
import "./PlacementStatistics.css";
import "./PlacementLoader.css";
import { MDBSpinner } from "mdbreact";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";


function PlacementStatistics(props, { guests }) {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => { }, [props.refreshData]);
  return (

    <span className={"statistics"}>
      
      <IconButton
        aria-label="checkin-header"
        sx={{ fontSize: "2rem", color: "white", backgroundColor: "#D23466", position: "fixed", zIndex: "20" }}
        onClick={toggle}
      >
        <MenuIcon fontSize="1.5rem" />
      </IconButton>
      
      {/*
      <FontAwesomeIcon
        style={{
          position: "fixed",
          zIndex: "20",
          left: "5px",
          padding: "5px",
        }}
        size="2x"
        onClick={toggle}
        icon={faBars}
      />
      */}

      <div className={"modal-full-screen"}>

        <Dialog
          open={modal}
          onClose={() => toggle}
          fullScreen
          PaperProps={{ style: { background: "#d23466" } }}
        >
          <DialogTitle>
            <Slide direction="down" in={modal} mountOnEnter unmountOnExit>
              <IconButton onClick={toggle}>
                <CloseRoundedIcon sx={{ fontSize: "2rem", color: "white" }} />
              </IconButton>
            </Slide>
          </DialogTitle>
          <DialogContent>
            <div className="row text-center">
                <div className="col-10 offset-1">
                  <MDBBtn
                    onClick={() => props.displaySettings(true)}
                    rounded
                    style={{
                      margin: "0 auto",
                      width: "100%",
                          fontWeight: "bold",
                      fontSize: "18px",
                    }}
                    color="white"
                    className="app-pink"
                  >
                    <FontAwesomeIcon
                      icon={faCog}
                      color={"#d81b60"}
                      style={{ marginRight: "10px" }}
                    />
                    Retour à la selection du plan
                  </MDBBtn>
                  <br />
                  <br />
                  <button
                    onClick={() => props.hotLoading(true)}
                    className="btn cancel-button btn-datarefresh"
                  >
                    Mettre à jour les données
                  </button>
                  <div>
                    {props.refreshData ? (
                      <>
                        <br /> <MDBSpinner big className="spinner-black-only" />
                      </>
                    ) : null}
                  </div>
                  {/* <button
                    onClick={() => window.location.reload(true)}
                    className="btn cancel-button btn-datarefresh"
                  >
                    Recharger la page
                  </button> */}
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/*
        <Modal isOpen={modal} toggle={toggle}>
          <ModalBody>
            <div
              style={{
                backgroundColor: "transparent",
                paddingTop: "100px",
                height: "100%",
              }}
            >
              <div className="row text-center">
                <div className="col-10 offset-1">
                  <FontAwesomeIcon
                    onClick={toggle}
                    style={{ position: "fixed", left: "10px", top: "10px" }}
                    size="2x"
                    icon={faTimes}
                    color={"white"}
                  />
                  <MDBBtn
                    onClick={() => props.displaySettings(true)}
                    rounded
                    style={{
                      margin: "0 auto",
                      width: "100%",
                         fontWeight: "bold",
                      fontSize: "18px",
                    }}
                    color="white"
                    className="app-pink"
                  >
                    <FontAwesomeIcon
                      icon={faCog}
                      color={"#d81b60"}
                      style={{ marginRight: "10px" }}
                    />
                    Retour à la selection du plan
                  </MDBBtn>
                  <br />
                  <br />

                  <button
                    onClick={() => props.hotLoading(true)}
                    className="btn cancel-button btn-datarefresh"
                  >
                    Mettre à jour les données
                  </button>
                  <div>
                    {props.refreshData ? (
                      <>
                        <br /> <MDBSpinner big className="spinner-black-only" />
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        */}
      </div>
    </span>

  );
}

export default PlacementStatistics;
