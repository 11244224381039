import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Button, MDBInput } from "mdbreact";
import PriceRulesEditor from "./PriceRulesEditor";
import { webPageTranslations } from "../../../common/Translations";

class EventGuestsTypes extends React.Component {
  constructor(props) {
    super(props);

    this.savepriceRules = this.savepriceRules.bind(this);
    this.addGuestPriceRule = this.addGuestPriceRule.bind(this);
    this.addFollowerPriceRule = this.addFollowerPriceRule.bind(this);
    this.deleteGuestPriceRuleitem = this.deleteGuestPriceRuleitem.bind(this);
    this.deleteFollowerPriceRuleitem =
      this.deleteFollowerPriceRuleitem.bind(this);

    this.state = {
      dontUseSubTypePriceRule: this.props.event.data.dontUseSubTypePriceRule,
      guestPriceRules: this.props.event.data.guestPriceRules.filter(
        (guestPriceRule) =>
          this.props.eventComponents.some(
            (eventComponent) =>
              eventComponent.id === Number(guestPriceRule.componentId)
          )
      ),
      followerpriceRules: this.props.event.data.followerpriceRules.filter(
        (followerPriceRule) =>
          this.props.eventComponents.some(
            (eventComponent) =>
              eventComponent.id === Number(followerPriceRule.componentId)
          )
      ),
    };
  }

  addGuestPriceRule(rule) {
    this.setState({
      guestPriceRules: [...this.state.guestPriceRules, { ...rule }],
    });
  }

  addFollowerPriceRule(rule) {
    this.setState({
      followerpriceRules: [...this.state.followerpriceRules, { ...rule }],
    });
  }

  deleteGuestPriceRuleitem = (key) => {
    const guestPriceRules = this.state.guestPriceRules.slice(0);
    guestPriceRules.splice(key, 1);
    this.setState({ guestPriceRules });
  };

  deleteFollowerPriceRuleitem = (key) => {
    const followerpriceRules = this.state.followerpriceRules.slice(0);
    followerpriceRules.splice(key, 1);
    this.setState({ followerpriceRules });
  };

  savepriceRules() {
    const event = this.props.event;
    const { guestPriceRules, followerpriceRules, dontUseSubTypePriceRule } =
      this.state;
    this.props.onSave({
      ...event,
      data: {
        ...event.data,
        dontUseSubTypePriceRule,
        guestPriceRules: guestPriceRules.filter((guestPriceRule) =>
          this.props.eventComponents.some(
            (eventComponent) =>
              eventComponent.id === Number(guestPriceRule.componentId)
          )
        ),
        followerpriceRules: followerpriceRules.filter((followerPriceRule) =>
          this.props.eventComponents.some(
            (eventComponent) =>
              eventComponent.id === Number(followerPriceRule.componentId)
          )
        ),
      },
    });
  }

  render() {
    const { guestPriceRules, followerpriceRules, dontUseSubTypePriceRule } =
      this.state;

    return (
      <div className="row">
        <div className="col-md-10 offset-md-1 main-content">
          <div className="row">
            <div className="col-md-12">
              <Button
                className="btn pink darken-1 float-right"
                onClick={() => this.savepriceRules()}
              >
                Sauvegarder
              </Button>
            </div>
          </div>
          <div className="font-weight-bold mb-2 ">Tarifs invités</div>
          <div className="table-container mb-4">
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  <th scope="col">Composant</th>
                  <th scope="col">Tranche de contact invité</th>
                  <th scope="col">Prix HT</th>
                  <th scope="col">Prix TTC</th>
                  <th scope="col">TVA (%)</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {guestPriceRules.map((priceRule, i) => (
                  <tr key={i}>
                    <td>
                      {
                        this.props.eventComponents.find(
                          (component) =>
                            component.id === Number(priceRule.componentId)
                        ).data.name
                      }
                    </td>
                    <td>
                      {priceRule.lowRange} à {priceRule.highRange}
                    </td>
                    <td>{priceRule.priceHT}</td>
                    <td>{priceRule.priceTTC}</td>
                    <td>{priceRule.tax}</td>
                    <td>
                      <span
                        className="pointer"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Etes vous sûr de supprimer cette règle ?"
                            )
                          )
                            this.deleteGuestPriceRuleitem(i);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="col-md-12">
              <PriceRulesEditor
                mode="guest"
                onValidate={this.savePackage}
                eventComponents={this.props.eventComponents}
                buttonLabel={"Modifier"}
                event={this.props.event}
                addGuestPriceRule={this.addGuestPriceRule}
              />
            </div>
          </div>
          <div className="font-weight-bold mb-2 ">Tarifs accompagnants</div>
          <MDBInput
            onClick={(e) =>
              this.setState({
                dontUseSubTypePriceRule: !dontUseSubTypePriceRule,
              })
            }
            id="3"
            label='Ne pas utiliser la comptabilisation des accompagnants par type'
            filled
            checked={dontUseSubTypePriceRule}
            type="checkbox"
          />
          <div className="table-container">
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  <th scope="col">État</th>
                  <th scope="col">Composant</th>
                  <th scope="col">Tranche</th>
                  <th scope="col">Type</th>
                  <th scope="col">Prix HT</th>
                  <th scope="col">Prix TTC</th>
                  <th scope="col">TVA (%)</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {followerpriceRules.map((priceRule, i) => (
                  <tr key={i}>
                    <td
                      className={
                        (priceRule.followerType === "externalOrInternal" &&
                          dontUseSubTypePriceRule) ||
                        priceRule.followerType === "external" ||
                        priceRule.followerType === "internal"
                          ? "green-text"
                          : "red-text"
                      }
                    >
                      {(priceRule.followerType === "externalOrInternal" &&
                        dontUseSubTypePriceRule) ||
                      priceRule.followerType === "external" ||
                      priceRule.followerType === "internal"
                        ? "Actif"
                        : "Inactif"}
                    </td>

                    <td>
                      {
                        this.props.eventComponents.find(
                          (component) =>
                            component.id === Number(priceRule.componentId)
                        ).data.name
                      }
                    </td>
                    <td>
                      {priceRule.lowRange} à {priceRule.highRange}
                    </td>
                    <td>
                      {webPageTranslations["FR"][priceRule.followerType]}
                      {this.props.event.data.guestsTypes.find(
                        (type, key) => key === Number(priceRule.followerSubType)
                      ) && priceRule.followerSubType
                        ? "/ " +
                          this.props.event.data.guestsTypes.find(
                            (type, key) =>
                              key === Number(priceRule.followerSubType)
                          ).name[0].value
                        : null}
                    </td>
                    <td>{priceRule.priceHT}</td>
                    <td>{priceRule.priceTTC}</td>
                    <td>{priceRule.tax}</td>
                    <td>
                      <span
                        className="pointer"
                        onClick={() => {
                          if (
                            window.confirm(
                              "Etes vous sûr de supprimer cette règle ?"
                            )
                          )
                            this.deleteFollowerPriceRuleitem(i);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="col-md-12">
              <PriceRulesEditor
                dontUseSubTypePriceRule={dontUseSubTypePriceRule}
                mode="follower"
                onValidate={this.savePackage}
                eventComponents={this.props.eventComponents}
                buttonLabel={"Modifier"}
                event={this.props.event}
                addFollowerPriceRule={this.addFollowerPriceRule}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventGuestsTypes;
