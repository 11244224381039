import React, { useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Select } from "../../components/Select";
import { Input } from "../../components/Input";
import "./Delegataire.css";
import { uniqBy } from "lodash";

const options = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

function DelegataireTableFilter({
  dateLimitOptions,
  components = [],
  categoriesOptions,
  delegataires,
  setFilteredDelegataires,
  getComponentsQuotaData,
}) {
  const [nameFilter, setNameFilter] = React.useState("");
  const [componentFilter, setComponentFilter] = React.useState("");
  const [categoryFilter, setCategoryFilter] = React.useState("");
  const [dateLimitFilter, setDateLimitFilter] = React.useState("");
  const [delegataireComponents, setDelegataireComponents] = React.useState([]);
  const [statusFilter, setStatusFilter] = React.useState("");
  const uniqueDateLimitOptions = uniqBy(
    dateLimitOptions.map((dateLimit) => ({
      label: dateLimit.label,
      value: dateLimit.label,
    })),
    "value"
  );

  useEffect(() => {
    setDelegataireComponents(
      delegataires.map((delegataire) => {
        const cmpQuotaData = getComponentsQuotaData(delegataire.user_id);
        return {
          id: delegataire.id,
          components: cmpQuotaData.components,
          status: cmpQuotaData.status,
        };
      })
    );
  }, [delegataires]);

  const componentsOptions = components.map((cmp) => ({
    label: cmp.data.name,
    value: cmp.id,
  }));

  componentsOptions.unshift({
    value: "",
    label: "Filtrer par composant",
  });

  const statusOptions = [
    { label: "Filtrer par statut", value: "" },
    { label: "Vide", value: "Vide" },
    { label: "Partiel", value: "Partiel" },
    { label: "Complet", value: "Complet" },
  ];

  const filterDelegataires = () => {
    let delegatairesFiltered = delegataires;

    //? FILTER BY NAME
    if (nameFilter.trim()) {
      delegatairesFiltered = delegatairesFiltered.filter((delegataire) => {
        const delegataireName =
          `${delegataire.firstname} ${delegataire.lastname}`
            .trim()
            .toLowerCase();
        return delegataireName.includes(nameFilter.trim().toLowerCase())
          ? delegataire
          : false;
      });
    }

    if (categoryFilter) {
      delegatairesFiltered = delegatairesFiltered.filter(
        (delegataire) =>
          delegataire.user &&
          delegataire.user.categories &&
          delegataire.user.categories.find(
            (cat) => cat.value === categoryFilter
          )
      );
    }

    if (dateLimitFilter) {
      delegatairesFiltered = delegatairesFiltered.filter(
        (delegataire) =>
          delegataire.expiration_date &&
          new Date(delegataire.expiration_date.date_limit).toLocaleDateString(
            "fr-FR",
            options
          ) === dateLimitFilter
      );
    }

    if (componentFilter) {
      delegatairesFiltered = delegatairesFiltered.filter((delegataire) => {
        const delegataireWithComponent = delegataireComponents.find(
          (delCmp) => delCmp.id === delegataire.id
        );
        if (delegataireWithComponent) {
          if (
            delegataireWithComponent.components &&
            delegataireWithComponent.components.length
          ) {
            return delegataireWithComponent.components.some(
              (cmp) => Number(cmp.id) === Number(componentFilter)
            );
          }
        }
        return false;
      });
    }

    if (statusFilter) {
      delegatairesFiltered = delegatairesFiltered.filter((delegataire) => {
        const delegataireWithComponent = delegataireComponents.find(
          (delCmp) => delCmp.id === delegataire.id
        );
        if (
          delegataireWithComponent &&
          delegataireWithComponent.status === statusFilter
        ) {
          return true;
        }
        return false;
      });
    }

    setFilteredDelegataires(delegatairesFiltered);
  };

  React.useEffect(() => {
    filterDelegataires();
  }, [
    nameFilter,
    componentFilter,
    categoryFilter,
    dateLimitFilter,
    statusFilter,
  ]);

  return (
    <div className="white filters delegataire-table-filter">
      <div className="del-filter-items">
        <Input
          bgColor="#ffffff"
          borderColor="#000000"
          textColor="rgba(0, 0, 0, 0.87)"
          placeholder="Nom"
          id="input-outlined-basic-delegataire-filter"
          value={nameFilter}
          handleValue={({ target: { value } }) => setNameFilter(value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          className="del-filter-inputs"
        />
      </div>
      <div className="del-filter-items">
        <Select
          selectedValue={categoryFilter || ""}
          handleSelectedValue={setCategoryFilter}
          options={categoriesOptions}
          id="filter-delegataire-select-users"
          displayArrowIcon={true}
          placeholder="FILTRER PAR TYPE"
          className="del-filter-inputs"
          margin={1}
        />
      </div>
      <div className="del-filter-items">
        <Select
          selectedValue={dateLimitFilter}
          handleSelectedValue={(value) => {
            setDateLimitFilter(value);
          }}
          options={[
            {
              value: "",
              label: "Filtrer par date limite",
            },
            ...uniqueDateLimitOptions,
          ]}
          id="filter-delegataire-select-datelimit"
          placeholder="FILTRER PAR DATE LIMITE"
          optionsValuesToLowerCase={false}
          className="del-filter-inputs"
          margin={1}
        />
      </div>
      <div className="del-filter-items">
        <Select
          selectedValue={componentFilter || ""}
          handleSelectedValue={setComponentFilter}
          options={componentsOptions}
          id="filter-delegataire-select-component"
          displayArrowIcon={true}
          placeholder="FILTRER PAR COMPOSANT"
          className="del-filter-inputs"
          margin={1}
        />
      </div>
      <div className="del-filter-items">
        <Select
          selectedValue={statusFilter}
          handleSelectedValue={setStatusFilter}
          options={statusOptions}
          id="filter-delegataire-select-status"
          displayArrowIcon={true}
          placeholder="FILTRER PAR STATUT"
          className="del-filter-inputs"
          margin={1}
          optionsValuesToLowerCase={false}
        />
      </div>
    </div>
  );
}

export default DelegataireTableFilter;
