import React, { useEffect, useState } from "react";
import {
  Box,
  CardContent,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Switch,
  FormControlLabel,
  styled,
  alpha,
} from "@mui/material";
import { Card } from "mdbreact";
import { languagesList } from "../../../common/Translations";
import ApiService from "../../../services/ApiService";
import { toast } from "react-toastify";
import Loader from "../../../common/Loader";

const MyTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#d81b60",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#d81b60",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#d81b60",
    },
  },
});

const MySwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#d81b60",
    "&:hover": {
      backgroundColor: alpha("#d81b60", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#d81b60",
  },
}));

function Profil({ contact }) {
  const [isLoading, setIsLoading] = useState(true);
  const [gender, setGender] = useState(contact.gender);
  const [firstname, setFirstname] = useState(contact.firstname);
  const [lastname, setLastname] = useState(contact.lastname);
  const [languages, setLanguages] = useState(contact.languages);
  const [companyFunction, setCompanyFunction] = useState(
    contact.company_function
  );
  const [companyService, setCompanyService] = useState(contact.company_service);
  const [phone, setPhone] = useState(contact.phone);
  const [mail, setMail] = useState(contact.mail);
  const [phoneSecond, setPhoneSecond] = useState(contact.phone_second);
  const [mailSecond, setMailSecond] = useState(contact.mail_second);
  const [secretaryName, setSecretaryName] = useState(contact.secretary_name);
  const [address, setAddress] = useState(contact.address);
  const [addressMore, setAddressMore] = useState(contact.address_more);
  const [zipCode, setZipCode] = useState(contact.zip_code);
  const [city, setCity] = useState(contact.city);
  const [country, setCountry] = useState(contact.country);
  const [mailEnable, setMailEnable] = useState(contact.mail_enable);
  const [mailSecondEnable, setMailSecondEnable] = useState(
    contact.mail_second_enable
  );

  // company
  const [companyName, setCompanyName] = useState("");
  const [companyAdresse, setCompanyAdresse] = useState("");
  const [companyAdresseMore, setCompanyAdresseMore] = useState("");
  const [companyZipCode, setCompanyZipCode] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyCountry, setCompanyCountry] = useState("");
  const [company, setCompany] = useState(null);

  useEffect(() => {
    const getContactCompany = async () => {
      await ApiService.awaitRequest(
        {},
        "contact_companies/" + contact.company_id,
        "get"
      ).then(function (data) {
        if (!data) {
          setIsLoading(false);
          return;
        }
        setCompany({ ...data });
        setCompanyName(data.name);
        setCompanyAdresse(data.address);
        setCompanyAdresseMore(data.address_more);
        setCompanyZipCode(data.zip_code);
        setCompanyCity(data.city);
        setCompanyCountry(data.country);
        setIsLoading(false);
      });
    };
    if (contact.company_id) {
      getContactCompany();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const contactData = {
      ...contact,
      gender,
      firstname,
      lastname,
      languages,
      company_function: companyFunction,
      company_service: companyService,
      phone,
      phone_second: phoneSecond,
      mail,
      mail_second: mailSecond,
      secretary_name: secretaryName,
      address,
      address_more: addressMore,
      zip_code: zipCode,
      city,
      country,
      mail_enable: mailEnable,
      mail_second_enable: mailSecondEnable,
    };
    const companyData = {
      ...company,
      name: companyName,
      address: companyAdresse,
      address_more: companyAdresseMore,
      zip_code: companyZipCode,
      city: companyCity,
      country: companyCountry,
    };

    try {
      const contactRes = await ApiService.awaitRequest(
        contactData,
        "contacts/" + contact.id,
        "put"
      );
      const companyRes = await ApiService.awaitRequest(
        companyData,
        "contact_companies/" + contact.company_id,
        "put"
      );
      if (contactRes && companyRes) toast.success("Informations misent à jour");
    } catch (error) {
      console.log(error);
      toast.error("Échec, essayer ultérieurement");
    }
  };

  if (isLoading) return <Loader />;

  return (
    <Box sx={{ padding: "90px 1rem 2rem 1rem" }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ width: "100%" }}>
              <Card
                style={{
                  maxWidth: "850px",
                  margin: "0 auto",
                  padding: "10px 5px 20px 5px",
                }}
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    textAlign="center"
                    fontWeight={500}
                    sx={{ marginBottom: "1rem" }}
                  >
                    Informations Générales
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <MyTextField
                        label="Prénom"
                        placeholder="Entrez votre prénom"
                        variant="outlined"
                        fullWidth
                        required
                        inputProps={{
                          pattern: "[A-Za-zÀ-ÖØ-öø-ÿ0-9 ]{1,30}",
                          maxLength: 30,
                        }}
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                        sx={{ marginTop: "0.7rem" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MyTextField
                        label="Nom"
                        placeholder="Entrez votre nom"
                        variant="outlined"
                        fullWidth
                        required
                        inputProps={{
                          pattern: "[A-Za-zÀ-ÖØ-öø-ÿ0-9 ]{1,30}",
                          maxLength: 30,
                        }}
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                        sx={{ marginTop: "0.7rem" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MyTextField
                        label="Civilité"
                        placeholder="Entrez votre état civile"
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          pattern: "[a-zA-Z0-9 ]{1,30}",
                          maxLength: 30,
                        }}
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        sx={{ marginTop: "0.7rem" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth sx={{ marginTop: "0.7rem" }}>
                        <InputLabel id="languages-select-label">
                          Langues
                        </InputLabel>
                        <Select
                          labelId="languages-select-label"
                          id="languages-select"
                          value={languages}
                          label="Langues"
                          onChange={(e) => setLanguages(e.target.value)}
                        >
                          {languagesList.map((lang, i) => (
                            <MenuItem key={i} value={lang.code}>
                              {lang.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Card
              style={{
                maxWidth: "850px",
                margin: "0 auto",
                padding: "10px 5px 20px 5px",
              }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  textAlign="center"
                  fontWeight={500}
                  sx={{ marginBottom: "1rem" }}
                >
                  Coordonnées
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <MyTextField
                      label="Fonction"
                      placeholder="Entrez votre fonction"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        pattern: "[A-Za-zÀ-ÖØ-öø-ÿ0-9 ]{1,60}",
                        maxLength: 60,
                      }}
                      value={companyFunction}
                      onChange={(e) => setCompanyFunction(e.target.value)}
                      sx={{ marginTop: "0.7rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MyTextField
                      label="Service"
                      placeholder="Entrez votre service"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        pattern: "[A-Za-zÀ-ÖØ-öø-ÿ0-9 ]{1,60}",
                        maxLength: 60,
                      }}
                      value={companyService}
                      onChange={(e) => setCompanyService(e.target.value)}
                      sx={{ marginTop: "0.7rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MyTextField
                      type="tel"
                      label="Tel 1"
                      placeholder="Entrez votre numéro"
                      variant="outlined"
                      fullWidth
                      required
                      inputProps={{ pattern: "[+ 0-9]{1,18}", maxLength: 18 }}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      sx={{ marginTop: "0.7rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MyTextField
                      type="tel"
                      label="Tel 2"
                      placeholder="Entrez votre numéro"
                      variant="outlined"
                      fullWidth
                      required
                      inputProps={{ pattern: "[+ 0-9]{1,18}", maxLength: 18 }}
                      value={phoneSecond}
                      onChange={(e) => setPhoneSecond(e.target.value)}
                      sx={{ marginTop: "0.7rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} display="flex">
                    <MyTextField
                      type="email"
                      label="Email 1"
                      placeholder="Entrez votre email"
                      variant="outlined"
                      fullWidth
                      required
                      inputProps={{
                        pattern: "^[w-.]+@([w-]+.)+[w-]{2,4}$",
                        maxLength: 60,
                      }}
                      value={mail}
                      onChange={(e) => setMail(e.target.value)}
                      sx={{ marginTop: "0.7rem" }}
                    />
                    <FormControlLabel
                      label="Actif"
                      labelPlacement="top"
                      sx={{ paddingTop: "0.7rem" }}
                      control={
                        <MySwitch
                          checked={mailEnable}
                          onChange={() => setMailEnable((prev) => !prev)}
                          inputProps={{
                            "aria-label": "mail_enable",
                            style: { width: "0px", backgroundColor: "yellow" },
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} display="flex">
                    <MyTextField
                      type="email"
                      label="Email 2"
                      placeholder="Entrez votre email secondaire"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        pattern: "^[w-.]+@([w-]+.)+[w-]{2,4}$",
                        maxLength: 60,
                      }}
                      value={mailSecond}
                      onChange={(e) => setMailSecond(e.target.value)}
                      sx={{ marginTop: "0.7rem" }}
                    />
                    <FormControlLabel
                      label="Actif"
                      labelPlacement="top"
                      sx={{ paddingTop: "0.7rem" }}
                      control={
                        <MySwitch
                          checked={mailSecondEnable}
                          onChange={() => setMailSecondEnable((prev) => !prev)}
                          inputProps={{
                            "aria-label": "mail_second_enable",
                            style: { width: "0px" },
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MyTextField
                      label="Nom secrétaire"
                      placeholder="Entrez le nom"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        pattern: "[A-Za-zÀ-ÖØ-öø-ÿ0-9 ]{1,60}",
                        maxLength: 60,
                      }}
                      value={secretaryName}
                      onChange={(e) => setSecretaryName(e.target.value)}
                      sx={{ marginTop: "0.7rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MyTextField
                      label="Adresse"
                      placeholder="Entrez votre adresse"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        maxLength: 100,
                      }}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      sx={{ marginTop: "0.7rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MyTextField
                      label="Adresse 2"
                      placeholder="Entrez votre adresse"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        maxLength: 100,
                      }}
                      value={addressMore}
                      onChange={(e) => setAddressMore(e.target.value)}
                      sx={{ marginTop: "0.7rem" }}
                    />
                  </Grid>
                  <Grid item container spacing={1} xs={12} sm={6}>
                    <Grid item xs={12} sm={3}>
                      <MyTextField
                        label="CP"
                        placeholder="Code postale"
                        type="number"
                        variant="outlined"
                        fullWidth
                        inputProps={{ pattern: "[+ 0-9]{2,18}", maxLength: 8 }}
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                        sx={{ marginTop: "0.7rem" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <MyTextField
                        label="Ville"
                        placeholder="Ville"
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          pattern: "[A-Za-zÀ-ÖØ-öø-ÿ0-9 ]{1,60}",
                          maxLength: 60,
                        }}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        sx={{ marginTop: "0.7rem" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <MyTextField
                        label="Pays"
                        placeholder="Pays"
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          pattern: "[A-Za-zÀ-ÖØ-öø-ÿ0-9 ]{1,60}",
                          maxLength: 60,
                        }}
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        sx={{ marginTop: "0.7rem" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              style={{
                maxWidth: "850px",
                margin: "0 auto",
                padding: "10px 5px 20px 5px",
              }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  textAlign="center"
                  fontWeight={500}
                  sx={{ marginBottom: "1rem" }}
                >
                  Informations Société
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <MyTextField
                      label="Nom"
                      placeholder="Entrez le nom de la société"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        pattern: "[A-Za-zÀ-ÖØ-öø-ÿ0-9 ]{1,60}",
                        maxLength: 60,
                      }}
                      value={companyName || ""}
                      onChange={(e) => setCompanyName(e.target.value)}
                      sx={{ marginTop: "0.7rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MyTextField
                      label="Adresse 1"
                      placeholder="Entrez l'adresse"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        maxLength: 100,
                      }}
                      value={companyAdresse || ""}
                      onChange={(e) => setCompanyAdresse(e.target.value)}
                      sx={{ marginTop: "0.7rem" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MyTextField
                      label="Adresse 2"
                      placeholder="Entrez l'adresse"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        maxLength: 100,
                      }}
                      value={companyAdresseMore || ""}
                      onChange={(e) => setCompanyAdresseMore(e.target.value)}
                      sx={{ marginTop: "0.7rem" }}
                    />
                  </Grid>
                  <Grid item container spacing={1} xs={12} sm={6}>
                    <Grid item xs={12} sm={3}>
                      <MyTextField
                        label="CP"
                        placeholder="Code postale"
                        type="number"
                        variant="outlined"
                        fullWidth
                        inputProps={{ pattern: "[+ 0-9]{2,18}", maxLength: 8 }}
                        value={companyZipCode || ""}
                        onChange={(e) => setCompanyZipCode(e.target.value)}
                        sx={{ marginTop: "0.7rem" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <MyTextField
                        label="Ville"
                        placeholder="Ville"
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          pattern: "[A-Za-zÀ-ÖØ-öø-ÿ0-9 ]{1,60}",
                          maxLength: 60,
                        }}
                        value={companyCity || ""}
                        onChange={(e) => setCompanyCity(e.target.value)}
                        sx={{ marginTop: "0.7rem" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <MyTextField
                        label="Pays"
                        placeholder="Pays"
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          pattern: "[A-Za-zÀ-ÖØ-öø-ÿ0-9 ]{1,60}",
                          maxLength: 60,
                        }}
                        value={companyCountry || ""}
                        onChange={(e) => setCompanyCountry(e.target.value)}
                        sx={{ marginTop: "0.7rem" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            xs={12}
            item
            display="flex"
            justifyContent="end"
            style={{
              maxWidth: "850px",
              margin: "0 auto",
              padding: "20px 5px",
            }}
            gap="1rem"
          >
            <Button
              type="submit"
              sx={{
                maxWidth: "200px",
                bgcolor: "#d81b60",
                "&:hover": { bgcolor: "#a11448", color: "white" },
              }}
              size="medium"
              variant="contained"
              color="primary"
              fullWidth
            >
              Enregistrer
            </Button>
            <Button
              sx={{
                maxWidth: "200px",
                bgcolor: "#d81b60",
                "&:hover": { bgcolor: "#a11448", color: "white" },
              }}
              size="medium"
              variant="contained"
              color="primary"
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                window.location.replace("/rgpd");
              }}
            >
              Gérer mes données
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default Profil;
